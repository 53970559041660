const polish = {
  "accounting.bankAccount.account.label": "Konto",
  "accounting.bankAccount.account.placeholder": "Konto",
  "accounting.bankAccount.account.title": "Wybierz konto",
  "accounting.bankAccount.transactions.assignInvoices.account.error": "Nie można załadować kategorii dokumentów",
  "accounting.bankAccount.transactions.assignInvoices.account.placeholder": "Kategoria dokumentu",
  "accounting.bankAccount.transactions.assignInvoices.filters.bankAccountTransactions.dateRange.required": "Wybierz datę rozpoczęcia i zakończenia.",
  "accounting.bankAccount.transactions.assignInvoices.filters.bankAccountTransactions.title": "Transakcje bankowe",
  "accounting.bankAccount.transactions.assignInvoices.filters.records.recordTypes.title": "Symbol dokumentu",
  "accounting.bankAccount.transactions.assignInvoices.filters.records.title": "Wpływy",
  "accounting.bankAccount.transactions.assignInvoices.records.empty": "Nie znaleziono dowodów",
  "accounting.bankAccount.transactions.assignInvoices.records.pagination.next": "Następny dokument",
  "accounting.bankAccount.transactions.assignInvoices.records.pagination.previous": "Poprzedni dokument",
  "accounting.bankAccount.transactions.assignInvoices.subtitle": "Prosimy o przypisanie paragonu do transakcji bankowej.",
  "accounting.bankAccount.transactions.assignInvoices.title": "Przydział voucherów",
  "accounting.bankAccount.transactions.table.action.assignOpenInvoices": "Przypisywanie otwartych kuponów",
  "accounting.bankAccount.transactions.table.action.downloadBankStatement": "Eksport do księgowania wyciągów bankowych",
  "accounting.bankAccount.transactions.table.action.downloadBuerf": "Eksport do importu rezerwacji w NTCS",
  "accounting.bankAccount.transactions.table.emptyState.title": "Nie znaleziono żadnych transakcji",
  "accounting.bankAccount.transactions.table.label.amount": "Kwota",
  "accounting.bankAccount.transactions.table.label.assignInvoice": "Przypisywanie dokumentu",
  "accounting.bankAccount.transactions.table.label.date": "Data rezerwacji",
  "accounting.bankAccount.transactions.table.label.invoice": "Pokwitowanie",
  "accounting.bankAccount.transactions.table.label.isPrivate": "Prywatny",
  "accounting.bankAccount.transactions.table.label.recipient": "Odbiornik",
  "accounting.bankAccount.transactions.table.label.text": "Tekst rezerwacji",
  "accounting.bankAccount.transactions.table.label.valueDate": "Data waluty",
  "accounting.bankAccount.transactions.table.menu.deleteAssignedInvoice": "Usuń łącze do dokumentu",
  "accounting.bankAccount.transactions.table.menu.markAsPrivate": "Oznacz jako transakcję prywatną",
  "accounting.bankAccount.transactions.table.menu.removePrivate": "Anulowanie oznaczenia prywatnego",
  "accounting.bankAccount.transactions.table.search.count": "Transakcje: <b>{count}</b>",
  "accounting.bankAccount.transactions.table.search.placeholder": "Wyszukiwanie transakcji",
  "accounting.bankAccount.transactions.table.uploadInvoice.progress.title": "Potwierdzenie zostało przesłane",
  "accounting.bankAccount.transactions.table.uploadInvoice.subsidiaryAndRecordType.title": "Wybierz kod firmy i symbol dokumentu",
  "accounting.bankAccount.transactions.title": "Transakcje",
  "accounting.bankConnection.delete.message": "Czy naprawdę chcesz usunąć konto bankowe {nazwa}?",
  "accounting.bankConnection.delete.title": "Usuń dane bankowe",
  "accounting.bankConnections.accountHolder": "Właściciel:",
  "accounting.bankConnections.accountType": "Typ konta:",
  "accounting.bankConnections.accountType.Bausparen": "Oszczędności w kasie mieszkaniowej",
  "accounting.bankConnections.accountType.Checking": "Rachunek bieżący",
  "accounting.bankConnections.accountType.CreditCard": "Karta kredytowa",
  "accounting.bankConnections.accountType.Loan": "Konto pożyczki",
  "accounting.bankConnections.accountType.Membership": "Udziały spółdzielcze",
  "accounting.bankConnections.accountType.Savings": "Konto oszczędnościowe",
  "accounting.bankConnections.accountType.Security": "Rachunek papierów wartościowych",
  "accounting.bankConnections.addBankConnection": "Dodaj dane bankowe",
  "accounting.bankConnections.balance": "Stan konta:",
  "accounting.bankConnections.emptyState.description": "Dodaj nowy rachunek bankowy.",
  "accounting.bankConnections.emptyState.title": "Brak danych bankowych",
  "accounting.bankConnections.expired": "Wygasła",
  "accounting.bankConnections.expiredAt": "Wygasła w dniu {data}",
  "accounting.bankConnections.expiresAt": "Wygasa w dniu {data}",
  "accounting.bankConnections.iban": "IBAN:",
  "accounting.bankConnections.lastSuccessfulUpdate": "za {data}",
  "accounting.bankConnections.pending": "Wybitne",
  "accounting.bankConnections.refreshBankConnection": "Odnowienie rachunku bankowego",
  "accounting.bankConnections.refreshPending": "Bieżące odnowienia",
  "accounting.bankConnections.refreshPending.info": "Odnowienie danych bankowych odbywa się w osobnym oknie. Jeśli okno zostało zamknięte przed zakończeniem aktualizacji, należy ponownie kliknąć \"Odnów dane bankowe\".",
  "accounting.bankConnections.refreshPending.label": "Proces odnowienia w toku",
  "accounting.bankConnections.upToDate": "Potwierdzone",
  "accounting.details.edit.header": "Edytuj dokument",
  "accounting.details.updatedAt": "ostatnio zmieniony dnia",
  "accounting.details.upload.files": "Pliki",
  "accounting.details.upload.skip": "Pomiń wszystko",
  "accounting.finished.reports": "Raporty",
  "accounting.table.label.totalGrossAmount": "Kwota faktury",
  "addFolderDialog.button.text.create": "Utwórz stronę",
  "addFolderDialog.folderName": "Nazwa folderu",
  "addFolderDialog.info": "Przejdź do Sharepointa, aby zarządzać członkami.",
  "addFolderDialog.title": "Utwórz folder",
  "advisor.uploads.uploadReports.title": "Przekazywanie sprawozdań",
  "analysis.powerBI.reports.table.label.description": "",
  "analysis.powerBI.reports.table.label.name": "",
  "analysis.powerBI.reports.table.search.count": "",
  "analysis.powerBI.reports.table.search.placeholder": "",
  "analysis.powerBI.reports.title": "",
  "analysis.powerBI.report.title": "",
  "appNotificationDialog.button.accept": "Zrozumiałem",
  "appNotificationDialog.title.maintenance": "Prace konserwacyjne",
  "appNotificationDialog.title.newFeature": "Nowe funkcje",
  "appNotificationDialog.title.update": "",
  "branchType.allocationGroup": "Obszar osiedlenia",
  "branchType.costCenter": "Kosztorysant",
  "branchType.department": "Dział",
  "branchType.financialAccountancyCircle": "Koło FIBU",
  "branchType.other": "Inne",
  "branchType.wageCompany": "Firma płacowa",
  "branchType.workplace": "Miejsce pracy",
  "button.activateMfa": "Aktywuj teraz",
  "button.answer": "ODPOWIEDŹ",
  "button.archive": "Archiwum",
  "button.assign": "Przyporządkuj",
  "button.back_to_login": "Powrót do logowania",
  "button.closeTicket": "Oznacz jako wykonane",
  "button.configure": "KONFIGUROWANIE",
  "button.continueInBackground": "Kontynuacja w tle",
  "button.continue_without_microsoft": "Kontynuuj bez konta Microsoft",
  "button.copy": "Kopiowanie/przenoszenie",
  "button.deactivateMfa": "Dezaktywuj teraz",
  "button.enter": "WEJDŹ",
  "button.markAsPrivate": "Oznaczenie jako poufne",
  "button.moreInfo": "WIĘCEJ INFORMACJI",
  "button.move": "Przenieś się tutaj",
  "button.nextStep": "następny krok",
  "button.paste": "Skopiuj tutaj",
  "button.refresh": "Odnów",
  "button.release": "Udostępnij",
  "button.submitArchiveMultiple": "{count} Wiadomości archiwalne",
  "button.submitArchiveSingle": "{count} Wiadomość archiwalna",
  "button.toOverview": "Do przeglądu",
  "button.transfer": "Transmituj",
  "button.unlock": "Odblokuj stronę",
  "button.upload": "Prześlij plik",
  "captcha.invalid": "",
  "captcha.placeholderText": "",
  "chatInput.deleteLink": "Usuń",
  "chatInput.editLink": "Edytuj",
  "chatInput.enterLink": "Link",
  "chatInput.openLink": "Otwarty adres URL",
  "chatInput.saveLink": "Zapisz",
  "common.abacus": "Abacus",
  "common.accounting": "Księgowość",
  "common.accounting.period": "Okres rezerwacji",
  "common.accounting.recordType": "Symbol dokumentu",
  "common.accounting.subsidiary": "Kod firmy",
  "common.add": "Dodaj",
  "common.albanian": "Shqiptare",
  "common.all": "Wszystkie",
  "common.amount": "Numer",
  "common.anyDateRange": "Dowolny okres",
  "common.apply": "Przejąć władzę",
  "common.archive": "Archiwum",
  "common.back": "Powrót",
  "common.bankConnection": "Dane bankowe",
  "common.bankConnections": "Dane bankowe",
  "common.bankContact": "Dane bankowe",
  "common.bic": "BIC",
  "common.bmd": "BMD",
  "common.bulgarian": "Български",
  "common.cancel": "Anuluj",
  "common.cancelCaps": "CANCEL",
  "common.chat": "Czat",
  "common.clientNumber": "Numer klienta",
  "common.cockpit": "Kokpit",
  "common.companies": "Firma",
  "common.company": "Firma",
  "common.companyNumber": "Numer przedsiębiorstwa",
  "common.companyOverview": "Przegląd firmy",
  "common.companyRegisterNumber": "Numer rejestru handlowego",
  "common.continue": "Więcej",
  "common.country": "Kraj",
  "common.croatian": "Hrvatski",
  "common.czech": "Česky",
  "common.dateformat": "DD.MM.RRRR",
  "common.dateValidation": "Proszę wpisać prawidłową datę",
  "common.defaultSignature": "Twój TPA",
  "common.delete": "Usuń",
  "common.done": "Gotowe",
  "common.download": "Pobierz",
  "common.downloadAll": "",
  "common.dueDate": "Termin:",
  "common.dueUntil": " najpóźniej do dnia {dueDate}.",
  "common.emailAddress": "Adres e-mail",
  "common.english": "English",
  "common.errorOccurred": "Wystąpił błąd",
  "common.events": "Wydarzenia",
  "common.files": "Pliki",
  "common.finished": "Zakończono",
  "common.folder": "Folder",
  "common.from": "Od",
  "common.fullName": "{firstName} {lastName}",
  "common.generalData": "Dane ogólne",
  "common.generalDocuments": "Akta ogólne",
  "common.german": "Deutsch",
  "common.global": "W całej firmie",
  "common.hr": "Lista płac",
  "common.hr.period": "Okres rozliczeniowy",
  "common.hrPreRegistrations": "Personel - zapisy wstępne",
  "common.hr.short": "Personel",
  "common.hr.subsidiary": "Obszar osiedlenia",
  "common.hungarian": "Magyar",
  "common.invite": "Zaproś",
  "common.kpi": "Fakty i liczby",
  "common.kpi.long": "Fakty i liczby",
  "common.later": "Później",
  "common.logout": "Wyloguj się",
  "common.me": "I",
  "common.members": "Członkowie",
  "common.moreInformation": "dowiedzieć się więcej",
  "common.mr": "Pan {imię}",
  "common.mrs": "Pani {imię}",
  "common.name": "Nazwa",
  "common.no": "Nie",
  "common.none": "brak",
  "common.noSearchResults": "Nie znaleziono żadnych wyników",
  "common.notAvailable": "N/A",
  "common.notSpecified": "n.d.",
  "common.numberValidation": "Proszę wpisać prawidłowy numer",
  "common.ok": "Ok",
  "common.optional": "(opcjonalnie)",
  "common.orderBy": "Sortuj według",
  "common.partial": "częściowo",
  "common.people": "Ludzie",
  "common.polish": "Polski",
  "common.profile": "Profil",
  "common.project": "Projekt",
  "common.projects": "Projekty",
  "common.readingTimeMinutes": "Czas czytania: {czas} min.",
  "common.readingTimeMinutes.short": "{Czas} Min.",
  "common.recipient": "Odbiornik",
  "common.refresh": "Odśwież",
  "common.releasedAt": "wydany w dniu {data}",
  "common.releasedAtBy": "wydany w dniu {data} przez {nazwa}",
  "common.releaseRequired.plural": "{count} Wymagane zwolnienia",
  "common.releaseRequired.singular": "Wymagane jest zwolnienie",
  "common.remainingItems": "+{count} więcej",
  "common.remainingItemsUnknown": "Załaduj więcej",
  "common.retry": "Spróbuj ponownie",
  "common.romanian": "Românesc",
  "common.russian": "Русский",
  "common.save": "Zapisz",
  "common.sendCaps": "WYŚLIJ",
  "common.serbian": "Српски",
  "common.settings": "Ustawienia",
  "common.showAll": "Pokaż wszystko",
  "common.slovak": "Slovenská",
  "common.slovene": "Slovenski",
  "common.taskTitle": "Tytuł",
  "common.taxNumber": "Numer podatkowy",
  "common.termsOfUse": "Warunki użytkowania",
  "common.ticket": "Konsultacje",
  "common.timePeriod": "Okres",
  "common.timePeriod.olderThan1Month": "Starszy niż jeden miesiąc",
  "common.timePeriod.olderThan1Week": "Starsze niż jeden tydzień",
  "common.timePeriod.olderThan1Year": "Starszy niż jeden rok",
  "common.timePeriod.olderThan6Months": "Starszy niż 6 miesięcy",
  "common.to": "Do",
  "common.tpaFirstName": "TPA",
  "common.tpaLastName": "Pracownicy",
  "common.unknown": "nieznany",
  "common.upcomingTermsOfUse": "Przyszłe warunki użytkowania",
  "common.upload": "Przesyłanie",
  "common.uploadFiles": "Prześlij pliki",
  "common.user": "Użytkownicy",
  "common.vatNumber": "UID",
  "common.yes": "Tak",
  "commuterAllowance.IndividuellePendlerpauschale": "Indywidualny dodatek na dojazdy do pracy",
  "commuterAllowance.KeinePendlerpauschale": "Brak dodatku na dojazdy do pracy",
  "commuterAllowance.MehrAls20kmKeinBus": "Ponad 20 km, bez autobusu",
  "commuterAllowance.MehrAls40kmBus": "Ponad 40 km, autobus",
  "commuterAllowance.MehrAls40kmKeinBus": "Ponad 40 km, bez autobusu",
  "commuterAllowance.MehrAls60kmBus": "Ponad 60 km, autobus",
  "commuterAllowance.MehrAls60kmKeinBus": "Ponad 60 km, bez autobusu",
  "commuterAllowance.Mindestens20kmBus": "Co najmniej 20 km, autobus",
  "commuterAllowance.Mindestens2kmKeinBus": "Co najmniej 2 km, bez autobusu",
  "companiesUserReleaseInfoActionsBar.message": "Masz {liczba} nowych użytkowników do udostępnienia",
  "companiesUserReleaseInfoActionsBar.submit": "Przeglądaj i udostępniaj",
  "companiesUserRelease.permissions.navbar.back": "Udostępnianie i zapraszanie użytkowników / przypisywanie ról",
  "companiesUserRelease.title": "Udostępnianie i zapraszanie użytkowników",
  "companyImages.cover.error": "Obrazek na okładce nie może być załadowany",
  "companyImages.cover.title": "Zdjęcie na okładce",
  "companyImages.delete.message": "Czy naprawdę chcesz usunąć to zdjęcie?",
  "companyImages.delete.title": "Usuń obraz",
  "companyImages.logo.error": "Logo firmy nie może zostać załadowane",
  "companyImages.logo.title": "Logo firmy",
  "companyImages.title": "Zdjęcie na okładce i logo firmy (opcjonalnie)",
  "companyList.remainingCompanies": "+{remainingCompanies} Więcej",
  "company.optionalSideBarMenuItems.maxLength.name": "Nazwa może zawierać tylko 15 znaków",
  "company.optionalSideBarMenuItems.nameAlreadyinUse": "Opcjonalna pozycja menu już dostępna",
  "company.optionalSideBarMenuItems.name.label": "Nazwa",
  "company.optionalSideBarMenuItems.required.name": "Nazwa musi być określona",
  "company.optionalSideBarMenuItems.required.url": "Musi być podany adres URL",
  "company.optionalSideBarMenuItems.title": "Opcjonalne pozycje menu firmy",
  "company.optionalSideBarMenuItems.tooltip.removeItem": "Usuń pozycję menu",
  "company.optionalSideBarMenuItems.urlAlreadyinUse": "Link dla opcjonalnej pozycji menu już istnieje",
  "company.optionalSideBarMenuItems.url.label": "URL",
  "company.optionalSideBarMenuItems.urlNotValid": "Proszę wpisać prawidłowy adres URL (przykład: https://www.tpa-connect.app )",
  "companyOverview.emptyState.message": "Prosimy o kontakt z doradcą",
  "companyOverview.emptyState.title": "Brak aktywnego przedsiębiorstwa",
  "companyOverview.emptyState.tpa.message": "Nie masz obecnie aktywnych klientów",
  "companyOverview.emptyState.tpa.title": "Brak aktywnych klientów",
  "companyOverview.unlockCountry.tooltip": "Odblokuj nowe kraje{br}{br}Odblokuj międzynarodowe projekty z <b>{kraj}</b>, aby korzystać z funkcji projektów tej firmy i wyświetlać wszystkie zapytania.",
  "companyStatus.accountingSetupDone": "0/2 zrobione",
  "companyStatus.active": "aktywny",
  "companyStatus.hrSetupDone": "1/2 wykonane",
  "companyStatus.inactive": "bezczynny",
  "companyStatus.userSetupDone": "2/2 wykonane",
  "config.accounting.accountingByCustomer": "Przekazywanie przez klientów danych księgowych za pośrednictwem TPA Connect",
  "config.accounting.delete.subsidiaries.last.message": "Kod firmy {name} nie może zostać usunięty. Musi istnieć co najmniej jeden kod spółki.",
  "config.accounting.delete.subsidiaries.message": "Czy naprawdę chcesz usunąć kod firmy {nazwa}?",
  "config.accounting.delete.subsidiaries.title": "Usuń kod firmy",
  "config.accounting.description": "Określenie rodzaju i zakresu obszaru księgowości.",
  "config.accounting.description.customer": "Twój doradca TPA skonfigurował ten obszar dla Ciebie. Jeśli chcą Państwo dokonać zmian, prosimy o kontakt z doradcą TPA w dowolnym momencie.",
  "config.accounting.due.accounting.reports.select.title": "Dni od przekazania rozliczenia",
  "config.accounting.dueDates.title": "Przekazywanie dokumentów księgowych",
  "config.accounting.due.reportRelease.select.subtitle": "Na przykład 4 dni po przekazaniu raportów przez TPA. Oznacza to 12 dzień następnego miesiąca (jeżeli przekazanie raportów przez TPA nastąpiło 8 dnia następnego miesiąca).",
  "config.accounting.due.reportRelease.select.title": "Dni po przekazaniu sprawozdań",
  "config.accounting.due.reportRelease.select.value": "{dzień} Dni od przekazania sprawozdań",
  "config.accounting.due.reportRelease.title": "Udostępnianie raportów przez klientów",
  "config.accounting.due.reports.select.subtitle": "Na przykład 3 dni po przekazaniu księgowości. Czyli 8 dnia następnego miesiąca (jeśli przekazanie księgowania nastąpiło 5 dnia następnego miesiąca).",
  "config.accounting.due.reports.select.value": "{day} Dni od przekazania rachunków",
  "config.accounting.due.reports.title": "Przekazywanie sprawozdań przez TPA",
  "config.accounting.due.transfer.select.subtitle": "Na przykład, 5 dnia następnego miesiąca.",
  "config.accounting.due.transfer.select.title": "Przekazanie napędu do",
  "config.accounting.due.transfer.select.value": "{dzień} Dni po zakończeniu okresu",
  "config.accounting.due.transfer.title": "Przekazywanie rachunkowości przez klientów",
  "config.accounting.globalReports.description": "Klient posiada raporty ogólnofirmowe",
  "config.accounting.globalReports.title": "Raporty dla całej firmy",
  "config.accounting.hasAccounting": "Klient prowadzi księgowość",
  "config.accounting.hasBankAccountTransactionInvoices": "Przypisywanie dokumentów do transakcji bankowych",
  "config.accounting.hasCockpitKpi": "Fakty i liczby w kokpicie",
  "config.accounting.hasKpi": "Fakty i liczby",
  "config.accounting.hasResults": "Oceny",
  "config.accounting.incaseoflaw.description": "Klient posiada funkcję \"In Case of Law",
  "config.accounting.incaseoflaw.label": "W przypadku prawa",
  "config.accounting.incaseoflaw.title": "W przypadku prawa",
  "config.accounting.month.description": "Obejmuje okres jednego miesiąca",
  "config.accounting.month.title": "Księgowość miesięczna",
  "config.accounting.quarter.description": "Obejmuje okres 3 miesięcy",
  "config.accounting.quarter.title": "Księgowość na kwartał",
  "config.accounting.recordTypes": "Wpływy z księgowości",
  "config.accounting.recordTypes.ocrDisabled": "Automatyczne rozpoznawanie dokumentów (OCR) wyłączone",
  "config.accounting.recordTypes.ocrEnabled": "Włączone automatyczne rozpoznawanie dokumentów (OCR)",
  "config.accounting.requireTwoReportReleases": "Wymagane zatwierdzanie raportów przez dwóch użytkowników (zasada podwójnej kontroli)",
  "config.accounting.subsidiaries.add": "Dodaj kod firmy",
  "config.accounting.subsidiaries.title": "Definiowanie kodów firmowych",
  "config.accounting.subsidiaries.title.customer": "Kody przedsiębiorstw",
  "config.accounting.subsidiary.maxReached": "Maksymalna liczba osiągniętych kodów firmowych",
  "config.accounting.subsidiary.name": "Nazwa kodowa przedsiębiorstwa",
  "config.accounting.week.description": "Obejmuje okres 5 dni roboczych i jest podzielony na tygodnie kalendarzowe",
  "config.accounting.week.title": "Rozliczanie tygodniowe",
  "config.bulk.button.configure": "Konfiguracja",
  "config.bulk.data.description": "Obraz okładki i logo firmy można przesłać tutaj.",
  "config.bulk.data.title": "Dane podstawowe",
  "config.bulk.finish.description": "Firmy są w trakcie konfiguracji. Może to potrwać kilka minut, nie zamykaj okna przeglądarki.",
  "config.bulk.finish.start": "Uruchomienie",
  "config.bulk.finish.title": "Zakończenie konfiguracji",
  "config.companies": "Konfiguracja firmy",
  "config.companies.buttonConfig": "Skonfiguruj firmę",
  "config.companies.count": "Firma {count}",
  "config.companies.description": "W tym kroku określa się procesy specyficzne dla firmy. Są to m.in. wygląd wizualny w TPA Connect, rodzaj i zakres księgowości oraz listy płac, a także terminy.",
  "config.companyData.cockpit": "Kokpit",
  "config.companyData.description": "Tutaj możesz załadować zdjęcie okładki i logo firmy oraz ustawić kryteria rezerwacji.",
  "config.companyData.title": "Dane podstawowe",
  "config.companyOverview.inactive": "Przegląd firmy / Nieaktywna",
  "config.customMenuPoints.deleteCustomMenuPoint.confirmLabel": "Tak, usuń",
  "config.customMenuPoints.deleteCustomMenuPoint.message": "Czy naprawdę chcesz usunąć tę pozycję menu?",
  "config.customMenuPoints.deleteCustomMenuPoint.title": "Usuń pozycję menu",
  "config.customMenuPoints.noMenuPointsAvailable": "Brak dostępnych pozycji w menu",
  "config.employees.assignRole": "Przydzielanie roli",
  "config.employees.emptyState.message": "Obecnie nie ma aktywnych pracowników",
  "config.employees.emptyState.title": "Brak aktywnych pracowników",
  "config.employees.table.missingEmail": "Brak adresu e-mail",
  "config.employees.table.missingEmail.tooltip": "Adres e-mail musi być wprowadzony do BMD przez konsultanta TPA",
  "config.features.alreadyToggledToday.disabled": "Dodatek został już dziś dezaktywowany",
  "config.features.alreadyToggledToday.enabled": "Dodatek został już dziś aktywowany",
  "config.features.confirm.message.disabled": "Jeśli aktywujesz dodatek, płatny dodatek będzie dostępny natychmiast, a opłata będzie naliczana od dzisiaj. Dodatek można ponownie dezaktywować jutro.",
  "config.features.confirm.message.enabled": "Płatny dodatek zostanie dezaktywowany na koniec dnia. Opłata za dzisiejszy dzień zostanie naliczona. Dodatek można ponownie aktywować od jutra.",
  "config.features.confirm.title.disabled": "Aktywuj dodatek",
  "config.features.confirm.title.enabled": "Dezaktywacja dodatku",
  "config.finishConfiguration": "Zakończenie konfiguracji",
  "config.finishConfiguration.cockpit": "Do kokpitu",
  "config.finishConfiguration.continue": "Utwórz inną firmę",
  "config.finishConfiguration.emailNotification": "Powiadamianie użytkowników przez e-mail",
  "config.finishConfiguration.message": "Firma {company} została pomyślnie skonfigurowana. Można go teraz wykorzystać do wymiany pomiędzy TPA a klientem.",
  "config.finishSetup": "EXIT SETUP",
  "config.hr.chat.description": "Pracownicy najemni mają prawo do kontaktu z doradcami TPA.",
  "config.hr.chat.title": "Czat",
  "config.hr.delete.subsidiaries.last.message": "Nie można usunąć obszaru rozliczeniowego {nazwa}. Musi istnieć co najmniej jeden obszar rozliczeniowy.",
  "config.hr.delete.subsidiaries.message": "Czy na pewno chcesz usunąć obszar księgowy {nazwa}?",
  "config.hr.delete.subsidiaries.title": "Usuń obszar listy płac",
  "config.hr.description": "Określenie charakteru i zakresu obszaru płac.",
  "config.hr.dueDates.reminder": "Przypomnienie o terminach",
  "config.hr.dueDates.title": "Terminy rozliczania listy płac",
  "config.hr.due.relativeDueDates": "Dostarczenie dokumentów w następnym miesiącu",
  "config.hr.due.reports.select.subtitle": "Na przykład 3 dni po przesłaniu listy płac. Oznacza to 8 dzień następnego miesiąca (jeśli transmisja księgowania listy płac miała miejsce 5 dnia następnego miesiąca).",
  "config.hr.due.reports.select.title": "Dni od przekazania listy płac",
  "config.hr.due.reports.select.value": "{dzień} Dni od przekazania oświadczenia personelu",
  "config.hr.due.select.value": "{dzień}. miesiąca",
  "config.hr.due.transfer.select.subtitle": "Na przykład, 5 dnia miesiąca.",
  "config.hr.due.transfer.select.value.relativeDueDates": "{day} Dni po zakończeniu miesiąca",
  "config.hr.due.transfer.title": "Przekazywanie list płac przez klientów",
  "config.hr.hasHr": "Klient posiada listę płac",
  "config.hr.hasResults": "Analizy",
  "config.hr.recordTypes": "Dokumenty płacowe",
  "config.hr.releaseSteps.oneStep.description": "Wszystkie miesięczne oceny są przekazywane przez TPA do klienta w jednym kroku. Klient musi wydać wszystkie niezbędne dokumenty w określonym terminie.",
  "config.hr.releaseSteps.oneStep.title": "w jednym kroku",
  "config.hr.releaseSteps.title": "Etapy uwolnień",
  "config.hr.releaseSteps.twoSteps.description": "Przekazywanie i udostępnianie oceny miesięcznej odbywa się w dwóch etapach. Klient może udostępnić rozliczenia przed udostępnieniem wszystkich innych ocen miesięcznych.",
  "config.hr.releaseSteps.twoSteps.title": "w dwóch etapach",
  "config.hr.requireTwoReportReleases": "Wymagane zatwierdzanie raportów przez dwóch użytkowników (zasada podwójnej kontroli)",
  "config.hr.sendPayroll.description": "Składanie oświadczeń o zarobkach drogą elektroniczną",
  "config.hr.sendPayroll.title": "Elektroniczny rejestr zarobków",
  "config.hr.sendPayroll.tooltip": "W momencie wydania odcinka wypłaty każdy pracownik automatycznie otrzymuje dostęp do aplikacji TPA Connect, za pomocą której może przeglądać własne dane dotyczące zarobków, jak również elektroniczne akta osobowe.",
  "config.hr.subsidiaries.add": "Dodaj obszar listy płac",
  "config.hr.subsidiaries.info": "Po wybraniu pierwszego obszaru księgowego można wybrać tylko kolejne tego samego typu (np. moduł kosztów). Aby wybrać nowy typ (np. koło FIBU), należy usunąć wszystkie obszary księgowe oprócz jednego.",
  "config.hr.subsidiaries.title": "Określenie obszarów płacowych",
  "config.hr.subsidiaries.title.customer": "Obwody księgowe",
  "config.hr.subsidiary.maxReached": "Osiągnięto maksymalną liczbę obwodów księgowych",
  "config.hr.subsidiary.name": "Nazwa obszaru rozliczeniowego",
  "config.hr.upload.description": "Pracownicy najemni mogą przesyłać dokumenty do swoich akt osobowych",
  "config.hr.upload.title": "Przesyłanie dokumentów do akt osobowych",
  "config.hr.warning.noBranch": "Nie można aktywować listy płac. Proszę założyć firmę płacową w NTCS.",
  "config.moduleTypeDetails.asp.helpInstructions": "Instrukcje dla klientów ASP",
  "config.moduleTypeDetails.asp.helpInstructions.open": "Do instrukcji",
  "config.moduleTypeDetails.label.database": "Baza danych",
  "config.moduleTypeDetails.label.dataSource": "Źródło danych",
  "config.moduleTypeDetails.label.url": "URL",
  "config.noDueDate": "Brak terminu",
  "config.optionalMenuPoints.success.title": "Pozycje menu zostały pomyślnie zapisane",
  "config.overview": "Przegląd konfiguracji",
  "config.permission.change": "zmieniono na \"{pozwolenie}\"",
  "config.permissions.accounting.globalReports": "Raporty księgowe dla całej firmy",
  "config.permissions.deleted": "(usunięty)",
  "config.permissions.description": "Wybierz obszary, do których użytkownik ma prawo dostępu. Dla każdego kodu firmy można wprowadzić inne ustawienia.",
  "config.permissions.employees": "Pracownicy",
  "config.permissions.existingUser.navbar.back": "Przegląd konfiguracji / Zarządzanie użytkownikami / Przydzielanie roli",
  "config.permissions.existingUser.navbar.backToUser": "Przegląd konfiguracji / Administracja użytkownikami / Przydzielanie ról / {employeeName}",
  "config.permissions.global": "W całej firmie",
  "config.permissions.internationalProjects.info": "Projekty Uprawnienia nie mogą być cofnięte: Członkowie projektów międzynarodowych otrzymują je automatycznie.",
  "config.permissions.navbar.back": "Przegląd konfiguracji / Zarządzanie użytkownikami / Wybór osoby kontaktowej / Przydzielanie roli",
  "config.permissions.navbar.backToUser": "Przegląd konfiguracji / Administracja użytkownikami / Wybierz osobę kontaktową / Przypisz rolę / {employeeName}",
  "config.permissions.noPermissionSelected.message": "Wybierz co najmniej jedno upoważnienie.",
  "config.permissions.noPermissionSelected.title": "Nie wybrano zezwolenia",
  "config.permissions.title": "Dostosuj uprawnienia",
  "config.roles.change": "Rola zmieniona z {oldRole} na {newRole}",
  "config.roles.customizePermissions": "Dostosuj uprawnienia",
  "config.roles.description": "Wybierz rolę z predefiniowanym zestawem uprawnień. W razie potrzeby można dostosować uprawnienia w kolejnym kroku.",
  "config.roles.multipleCompaniesDialog.message": "Użytkownik {name} istnieje w następujących firmach. Czy chcesz wprowadzić zmiany autoryzacji we wszystkich firmach?",
  "config.roles.multipleCompaniesDialog.title": "Zastosowanie zmian w kilku firmach",
  "config.roles.multipleCompaniesDialog.warning": "Nie można załadować firm. Zmiany są stosowane tylko do bieżącej firmy.",
  "config.roles.navbar.back": "Przegląd konfiguracji / Zarządzanie użytkownikami / Wybór osób kontaktowych",
  "config.roles.navbar.heading": "{employeeCount} Wybrano użytkownika",
  "config.roles.option.copy.description": "Podaj nazwę użytkownika, który ma przejąć jego rolę i uprawnienia. W razie potrzeby możesz dostosować uprawnienia w następnym kroku.",
  "config.roles.option.copy.label": "Przejęcie roli od innego użytkownika",
  "config.roles.option.copy.placeholder": "Nazwa użytkownika",
  "config.roles.releaseInformation": "Aby edytować ustawienia użytkownika <b>{userName}</b>, <b>superadmin</b> musi zatwierdzić/odrzucić zmiany.",
  "config.roles.releaseInformation.info.message": "Jeśli ktokolwiek (poza superadminem) dokona zmian w ustawieniach użytkownika, zmiany te muszą zostać zwolnione lub odrzucone przez superadmina. Do tego czasu ustawienia użytkownika są zablokowane i nie można ich edytować.",
  "config.roles.releaseInformation.info.title": "Dlaczego ustawienia użytkownika nie mogą być edytowane?",
  "config.roles.releaseInformation.superAdmin": "{name} wprowadził zmiany w <b>{userName}</b>. Aby kontynuować, należy zatwierdzić zmiany wprowadzone przez użytkownika.",
  "config.roles.sidebar.title": "Wybrani użytkownicy",
  "config.roles.submit": "Zaproponuj użytkownika",
  "config.roles.submit.existingUser": "Zaproponuj zmiany",
  "config.roles.submit.superAdmin": "Zaproś użytkownika",
  "config.roles.title": "Przydzielanie roli",
  "config.roles.viewPermissions": "Wyświetlanie uprawnień",
  "config.terms.allSigneesAcceptedWarningDialog.confirmLabel": "OK, zapisz.",
  "config.terms.allSigneesAcceptedWarningDialog.message": "Uwaga: Ponieważ wszystkie dodane osoby już się podpisały, po zapisaniu firma zostanie aktywowana.",
  "config.terms.allSigneesAcceptedWarningDialog.title": "Zapisz warunki użytkowania",
  "config.terms.alreadySigned": "Regulamin ich firmy został już podpisany.",
  "config.terms.button.revokeActivation": "Cofnięcie aktywacji",
  "config.terms.changeDocumentVersionDialog.confirmLabel": "Tak, zmień dokument",
  "config.terms.changeDocumentVersionDialog.message": "Jeśli kontynuujesz, wszystkie dodane już adresy e-mail muszą ponownie zaakceptować warunki użytkowania.",
  "config.terms.changeDocumentVersionDialog.title": "Dokument zmiany",
  "config.terms.deleteEmailDialog.confirmLabel": "Tak, usuń adres e-mail",
  "config.terms.deleteEmailDialog.message": "Czy naprawdę chcesz usunąć adres e-mail?",
  "config.terms.deleteEmailDialog.title": "Usuń adres e-mail",
  "config.terms.documentVersion": "Wersja dokumentu",
  "config.terms.emailAddressesInfo": "Dodaj adresy e-mail wybranych osób, które mają zostać poproszone o podpisanie odpowiednich warunków użytkowania. Dodawaj tylko adresy e-mail autoryzowanych sygnatariuszy! Warunki użytkowania są wysyłane na poniższe adresy e-mail dopiero po sfinalizowaniu konfiguracji firmy i użytkownika.",
  "config.terms.emailAddressesTitle": "Adresy e-mail",
  "config.terms.emailAlreadyUsed": "Ten adres e-mail został już dodany",
  "config.terms.invalidEmail": "Nieprawidłowy adres e-mail",
  "config.terms.noDocumentsAvailable": "Obecnie nie ma dostępnych dokumentów",
  "config.terms.noSigneesAvailable": "Nie ma jeszcze żadnych sygnatariuszy",
  "config.termsOfUse.navbar.back": "Przegląd konfiguracji / dane główne",
  "config.terms.removeEmail": "Usuń adres e-mail",
  "config.terms.resendEmail.header": "Wysłany e-mail",
  "config.terms.resendEmail.message": "Email do akceptacji warunków użytkowania został wysłany ponownie",
  "config.terms.resendMail": "Ponowne wysłanie wiadomości e-mail",
  "config.terms.revokeActivationDialog.confirmLabel": "tak, aktywacja cofnięta",
  "config.terms.revokeActivationDialog.message": "Czy na pewno chcesz cofnąć aktywację firmy? Wszystkie zarejestrowane osoby muszą ponownie podpisać warunki użytkowania, aby ponownie aktywować firmę.",
  "config.terms.revokeActivationDialog.title": "Cofnięcie aktywacji",
  "config.terms.signedVersion": "wersja podpisana:",
  "config.terms.skip.button": "skip",
  "config.terms.skip.dialog.message": "Użytkownicy po stronie klienta mogą zalogować się dopiero po zaakceptowaniu warunków użytkowania. Do tego czasu firma jest dostępna tylko do celów wewnętrznych TPA w aplikacji Connect.",
  "config.terms.skip.dialog.title": "Informacje",
  "config.terms.success.header": "Zapisano pomyślnie",
  "config.terms.termsOfUseInfo": "Wybierz odpowiednią wersję dokumentu",
  "config.terms.termsOfUseTitle": "Warunki użytkowania",
  "config.users": "Zarządzanie użytkownikami",
  "config.users.active.title": "Aktywni użytkownicy",
  "config.users.assignRole": "Przydzielanie roli",
  "config.users.buttonConfig": "Zarządzaj użytkownikami",
  "config.users.changeUsername": "Nowa poczta elektroniczna",
  "config.users.changeUsername.tooltip": "Nowy adres e-mail użytkownika to {newUsername}. Klikając na przycisk, użytkownik jest proszony o potwierdzenie nowego adresu e-mail.",
  "config.users.description": "Przypisanie użytkowników lub grup użytkowników do poszczególnych procesów. Ty decydujesz, kto i w jakim zakresie ma dostęp do poszczególnych obszarów.",
  "config.users.edit": "Zmiana",
  "config.users.emptyState.message": "Obecnie nie ma aktywnych użytkowników{br}Aby stworzyć superadmina, proszę użyć BMD NTCS.",
  "config.users.emptyState.title": "Brak aktywnych użytkowników",
  "config.users.inviteAgain": "Zaproś ponownie",
  "config.users.navBar.back": "Przegląd konfiguracji / zarządzanie użytkownikami",
  "config.users.new": "Nowy",
  "config.users.newUsers": "Utwórz nowego użytkownika",
  "config.users.newUsers.description": "Wybierz osoby kontaktowe, które chcesz utworzyć jako użytkowników TPA Connect. W kolejnym kroku można przypisać rolę do wybranych osób i w razie potrzeby dostosować predefiniowane uprawnienia.",
  "config.users.newUsers.title": "Wybierz osobę kontaktową",
  "config.users.reject": "Spadek",
  "config.users.release": "Udostępnij",
  "config.users.releaseAndInvite": "Udostępnij i zaproś",
  "config.users.table.invalidEmail": "Niepoprawny adres e-mail",
  "config.users.table.invalidEmail.tooltip": "Adres e-mail musi zostać skorygowany przez konsultanta TPA w BMD",
  "config.users.waiting.title": "Czekając na wydanie",
  "cookies.acceptAll": "Zezwalaj na wszystkie pliki cookie",
  "cookies.acceptFunctional": "Używaj tylko niezbędnych plików cookie",
  "cookies.cookieList": "Deklaracja dotycząca plików cookie",
  "cookies.text": "Używamy plików cookie do optymalizacji naszych usług. Więcej szczegółów na ten temat można znaleźć w {link}.",
  "country.al": "Albania",
  "country.at": "Austria",
  "country.bg": "Bułgaria",
  "country.cz": "Republika Czeska",
  "country.hr": "Chorwacja",
  "country.hu": "Węgry",
  "country.me": "Czarnogóra",
  "country.pl": "Polska",
  "country.ro": "Rumunia",
  "country.rs": "Serbia",
  "country.si": "Słowenia",
  "country.sk": "Słowacja",
  "deleteDialog.message.plural": "Czy naprawdę chcesz usunąć dokumenty <b>{dokumenty}</b>? {br}{br}Tej czynności nie można cofnąć.",
  "deleteDialog.message.singular": "Czy naprawdę chcesz usunąć dokument <b>{dokumenty}</b>? {br}{br}Tej czynności nie można cofnąć.",
  "deleteDialog.title": "Usunąć dokumenty?",
  "deleteUser.message": "Czy na pewno chcesz usunąć użytkownika <b>{name}</b>?",
  "deleteUser.multipleCompanies.confirmLabel": "Usunięcie użytkownika we wszystkich firmach",
  "deleteUser.multipleCompanies.message": "Użytkownik jest obecny w następujących firmach:",
  "deleteUser.title": "Usuń użytkownika",
  "deleteUser.warning.superadminskipped": "Uwaga! Firmy, w których użytkownicy mają rolę Superadmin, są pomijane podczas usuwania.",
  "dialog.archive.checkbox_pdf": "Zapisz plik PDF lokalnie",
  "dialog.archive.subject": "Przedmiot:",
  "dialog.archive.success.text": "Teraz można uzyskać dostęp do zarchiwizowanych wiadomości w folderze {bmd}.",
  "dialog.archive.success.title": "Wiadomości pomyślnie zarchiwizowane!",
  "dialog.archive.text": "Wybierz firmę, dla której chcesz zapisać wybrane komunikaty w BMD.",
  "dialog.archive.title_multiple": "{count} Wiadomości archiwalne",
  "dialog.archive.title_single": "1 Wiadomość archiwalna",
  "dialog.assignTicket.header": "Przypisz zapytanie ofertowe",
  "dialog.assignTicket.message": "Wybierz osobę, której chcesz przydzielić konsultację.",
  "dialog.button.understood": "Zrozumiano",
  "dialog.closeTicket.success.header": "Konsultacje przeprowadzone",
  "dialog.companyConfig.success.header": "Konfiguracja firmy zapisana pomyślnie.",
  "dialog.companyConfig.success.message": "Obszary dla {nazwa} są już gotowe.",
  "dialog.eldaTransfer.andNext": " i dalej",
  "dialog.eldaTransfer.downloadLater": "Pobierz później",
  "dialog.eldaTransfer.success.header": "Wniosek został pozytywnie złożony",
  "dialog.loginError.multiFactorCodeWrong.title": "Wprowadzony kod jest niestety nieprawidłowy",
  "dialog.loginError.subtitle": "Proszę spróbować ponownie.",
  "dialog.loginError.title": "Nieznany adres e-mail lub błędne hasło",
  "dialog.noPhoneNumberWarning.message": "Przynajmniej jeden z wybranych kontaktów nie ma ustawionego numeru telefonu. Bez numeru telefonu uwierzytelnianie dwuskładnikowe nie jest dostępne, a zaproszenie może nie być bezpieczne.",
  "dialog.noPhoneNumberWarning.title": "Brak ustawionego numeru telefonu",
  "dialog.passwordExpired.message": "Twoje hasło wygasło i wymaga zmiany. Wkrótce otrzymasz wiadomość e-mail z prośbą o zmianę hasła. W razie jakichkolwiek pytań prosimy o kontakt z TPA pod numerem {telephoneNumber}. Dziękujemy bardzo!",
  "dialog.passwordExpired.title": "Hasło wygasło",
  "dialog.password_reset.success.header": "Hasło zostało zmienione pomyślnie",
  "dialog.registration.success.button": "Zaloguj się teraz",
  "dialog.registration.success.header": "Udało Ci się zarejestrować!",
  "dialog.registration.success.message": "Zaloguj się do Microsoft, aby korzystać z TPA Connect.",
  "dialog.transferReports.success.message": "Twój klient będzie teraz przeglądał pliki i kontaktował się z Tobą w przypadku jakichkolwiek pytań.",
  "dialog.transfer.success.header": "Pliki przesłane pomyślnie",
  "dialog.userConfig.success.header": "Ustawienia użytkownika zapisane",
  "dialog.user_not_found.button": "Zrozumiano",
  "dialog.user_not_found.subtitle": "Skontaktuj się z administratorem systemu lub zaloguj się za pomocą swojego konta TPA Connect.",
  "dialog.user_not_found.title": "Pod tym adresem e-mail nie jest znane żadne konto użytkownika",
  "documentUpload.error.amount": "<b>{failedUploads}</b> z {uploads} plików nie mogło zostać przesłane:",
  "documentUpload.error.button.text.continue": "Kontynuuj bez tych plików",
  "documentUpload.error.button.text.retry": "Spróbuj ponownie",
  "documentUpload.error.message": "Błąd przesyłania plików",
  "documentUpload.error.rejection.amount": "<b>{failedUploads}</b> plików {uploads} nie można przesłać:",
  "documentUpload.error.tooBigImages.amount": "<b>{failedUploads}</b> Obrazy są większe niż maksymalny rozmiar {maxImageDimension} pikseli. Zostaną one automatycznie zmniejszone. Kontynuować?",
  "documentUpload.error.tooBigImages.amount.singular": "Obraz jest większy niż maks. {maxImageDimension} pikseli. Zostanie on automatycznie pomniejszony. Kontynuować?",
  "documentUpload.error.tooBigImages.button.text.continue": "Redukcja i kontynuacja",
  "documentUpload.error.tooBigImages.pdf": "Obrazy w pliku PDF są większe niż maksymalnie {maxImageDimension} pikseli. Proszę zmniejszyć rozmiar zdjęć.",
  "documentUpload.error.tooManyFiles.plural": "Można przesłać maksymalnie <b>{count}</b> plików:",
  "documentUpload.error.tooManyFiles.singular": "Można przesłać maksymalnie <b>jeden</b> plik:",
  "documentUpload.max.size": "Maksymalny rozmiar dokumentu {maxUploadSizeMb} Mb",
  "documentUpload.tooBigImages.message": "Zbyt duże obrazy",
  "documentUpload.type.file.formats": "Obsługiwane formaty",
  "dropzone.button.label.search": "poszukaj go.",
  "dropzone.image.text": "Po prostu przeciągnij obraz do tego obszaru lub",
  "dropzone.info.redo": "Prześlij ponownie",
  "dropzone.info.text": "Po prostu przeciągnij i upuść pliki do tego obszaru lub",
  "dueDate.answerAt": "Odpowiedź do dnia {data}",
  "dueDate.closedAt": "zakończono w dniu {data}",
  "dueDate.sentAt": "wysłany w dniu {data}",
  "dueDate.sentAtWithTime": "wysłane w dniu {data} o godzinie {godzina}",
  "dueDate.transferredAt": "przekazany w dniu {data}",
  "dueDate.until": "do {data}",
  "employmentInsuranceType.apprentice": "Praktykant",
  "employmentInsuranceType.fullTime": "w pełni ubezpieczony",
  "employmentInsuranceType.independentContractor": "wolny DN",
  "employmentInsuranceType.marginalIndependentContractor": "Niewielki wolny DN",
  "employmentInsuranceType.marginally": "marginalnie",
  "employmentRelationship.employee": "Pracownik",
  "employmentRelationship.worker": "Pracownicy",
  "error.accounting.postSubsidiary": "Nie można utworzyć kodu firmy",
  "error.accounting.subsidiaryDoesNotExist": "Nie znaleziono kodu firmy",
  "error.activate": "Błąd aktywacji",
  "error.addBankAccountTransactionInvoices": "Nie można było powiązać transakcji i dokumentów",
  "error.addBankConnection": "Nie można dodać danych bankowych",
  "error.addCompanyToUserFavorites": "Firma nie może zostać dodana do ulubionych",
  "error.addFolderBadRequest": "Folder nie mógł zostać utworzony. Upewnij się, że nie użyłeś żadnych niedozwolonych znaków specjalnych: \" * : < > ? / \\ |",
  "error.archiveDisabledForCompany": "Archiwizacja nie jest możliwa w tej firmie",
  "error.archiveFailed": "Błąd podczas archiwizacji",
  "error.archiveProjectFailed": "Projekt nie mógł zostać zarchiwizowany",
  "error.avatarUploadFailed": "Nie można przesłać zdjęcia profilowego. Upewnij się, że format pliku to JPEG lub PNG, a jego rozmiar to maksymalnie {maxSize}MB.",
  "error.avatarUploadOnlyOneImage": "Możesz przesłać tylko jedno zdjęcie",
  "error.changeUsernameEmail": "Nie można było wysłać wiadomości e-mail dotyczącej zmiany nazwy użytkownika",
  "error.changeUsername.newUsernameConflict": "Nowa wiadomość e-mail nie pasuje do zapisanej wiadomości e-mail.",
  "error.changeUsername.newUsernameDuplicate": "E-mail już w użyciu.",
  "error.changeUsername.noNewUsername": "Nie został przypisany nowy adres e-mail.",
  "error.changeUsername.tokenExpired": "Ten link wygasł.",
  "error.changeUsername.tokenNotFound": "Link nieważny, czy nowy adres e-mail został już potwierdzony?",
  "error.chatForbidden": "Face to Face nie jest aktywowana dla Ciebie w tej firmie.",
  "error.chatNoCompany": "Wiadomość bez kontekstu firmowego.",
  "error.chatNoRecipient": "Wiadomość nie ma odbiorcy.",
  "error.chatSearch": "Wyszukiwanie nie powiodło się. Proszę spróbować ponownie.",
  "error.completeSign": "Podpis elektroniczny nie mógł być wypełniony",
  "error.config.accounting.duplicate": "Wprowadzono kilka kodów firm o tej samej nazwie",
  "error.config.accounting.noSubsidiary": "Proszę zdefiniować przynajmniej jeden kod firmy, aby kontynuować.",
  "error.config.activateCompanyFailed": "Klient ten nie został jeszcze technicznie przygotowany do korzystania z TPA Connect. Prosimy o kontakt z działem IT w celu utworzenia odpowiedniego użytkownika.",
  "error.config.corruptSubsidiary": "Kod firmy bez identyfikatora",
  "error.config.hr.duplicate": "Określono kilka obszarów listy płac o tej samej nazwie",
  "error.config.hr.noSubsidiary": "Proszę ustawić przynajmniej jeden obszar księgowy, aby kontynuować.",
  "error.copy.alreadyExists.file": "Błąd podczas kopiowania: Plik o tej nazwie już istnieje",
  "error.copy.alreadyExists.folder": "Błąd podczas kopiowania: Folder o tej nazwie już istnieje",
  "error.copy.alreadyExists.unknown": "Błąd podczas kopiowania: Folder lub plik o tej nazwie już istnieje",
  "error.create": "Błąd podczas tworzenia",
  "error.create.alreadyExists.project": "Projekt o tej nazwie już istnieje",
  "error.createEmployee": "Pracownik nie może być utworzony",
  "error.delete": "Błąd podczas usuwania",
  "error.deleteBankAccountTransactionInvoices": "Nie można usunąć linku",
  "error.deleteBankConnection": "Nie można usunąć danych bankowych",
  "error.delete.fileLocked": "Plik jest obecnie edytowany i dlatego nie może zostać usunięty.",
  "error.deleteUser": "Użytkownik nie mógł zostać usunięty. Proszę spróbować ponownie w późniejszym terminie.",
  "error.deregisterEmployee": "Pracownik nie może zostać wylogowany",
  "error.documentForbidden": "Nie masz dostępu do dokumentu",
  "error.documentInProgress": "Dokument jest wysyłany do TPA i nie może być pobrany. Spróbuj ponownie wkrótce.",
  "error.documentNotFound": "Nie znaleziono dokumentu",
  "error.documentsInProgress": "Dokumenty są wysyłane do TPA i nie można ich pobrać. Spróbuj ponownie wkrótce.",
  "error.download": "Błąd pobierania",
  "error.downloadTermsOfUseDocument": "Podczas pobierania warunków użytkowania wystąpił błąd",
  "error.download.transferInProgress": "Dokument jest przesyłany. Spróbuj ponownie nieco później.",
  "error.dueDate.minDate": "Termin nie może być przeszły",
  "error.duplicateName": "Nazwa już nadana",
  "error.duplicateUsername": "Nowo przydzielony adres e-mail jest już używany",
  "error.edit": "Błąd podczas edycji",
  "error.employeeWithoutEmail": "Pracownik nie ma prawidłowego adresu e-mail",
  "error.folderIsEmpty": "Folder jest pusty i dlatego nie można go pobrać",
  "error.foldersAreEmpty": "Wszystkie wybrane foldery są puste i dlatego nie można ich pobrać.",
  "error.forbidden": "Brak zezwolenia",
  "error.general": "Przepraszamy, wystąpił błąd. Prosimy spróbować ponownie później.",
  "error.getSign": "Podczas ładowania wymaganych informacji dla podpisu elektronicznego wystąpił błąd",
  "error.hr.noSubsidiarySelected": "Nie wybrano obszaru listy płac",
  "error.hr.postSubsidiary": "Nie można utworzyć obszaru listy płac",
  "error.hr.subsidiaryDoesNotExist": "Nie znaleziono obszaru listy płac",
  "error.imageCrop": "Błędy w przetwarzaniu obrazu",
  "error.imageTooBig": "Zbyt duży obraz",
  "error.invalidCharacters": "Dane wejściowe zawierają nieprawidłowe znaki",
  "error.invalidCompanyId": "Nieprawidłowy identyfikator firmy",
  "error.invalid_credentials": "Nieprawidłowy identyfikator użytkownika lub hasło",
  "error.invalidDateFormat": "Nieprawidłowy format daty",
  "error.invalidEmployee": "Dane pracownika niekompletne lub nieprawidłowe",
  "error.invalidPeriodId": "Nieważny okres dla tej firmy",
  "error.invalidRolePermissions": "Uprawnienia do ról są nieprawidłowe",
  "error.invalidSsn": "Nieprawidłowy numer ubezpieczenia społecznego",
  "error.invalidTicket": "Nieważny bilet",
  "error.invalidWebhookParams": "Nieprawidłowe parametry webhook.",
  "error.invitationNoCompany": "Zaproszenie bez identyfikatora firmy",
  "error.linkExpired": "Ten link wygasł. Proszę poprosić o nowy.",
  "error.linkInvalid": "Ten link jest nieprawidłowy.",
  "error.loadAccount": "Nie można załadować konta",
  "error.loadAccountListing": "Nie można załadować listy sald",
  "error.loadAccounts": "Nie można załadować kont",
  "error.loadAccountTransactions": "Nie można załadować rezerwacji",
  "error.loadArchiveDocuments": "Nie wszystkie dokumenty archiwalne mogły zostać załadowane",
  "error.loadBadges": "Błąd podczas ładowania powiadomień",
  "error.loadBalanceSheet": "Nie można załadować salda",
  "error.loadBankAccountTransactions": "Nie można załadować transakcji",
  "error.loadBankConnections": "Nie można załadować danych bankowych",
  "error.loadBranches": "Oddziały nie mogą być załadowane",
  "error.loadCashAccounting": "Nie można załadować rachunku przychodów i wydatków",
  "error.loadCompany": "Firma nie może być załadowana",
  "error.loadCompanyFeatures": "Nie można załadować dodatków",
  "error.loadContacts": "Nie można załadować kontaktów",
  "error.loadCostCenters": "Centra kosztów nie mogą zostać załadowane",
  "error.loadCountries": "Nie można załadować danych kraju",
  "error.loadCustomerListing": "Nie można załadować listy dłużników",
  "error.loadDestinations": "Nie można było załadować miejsc zajętych. Proszę spróbować ponownie później",
  "error.loadDocument": "Dokument nie może zostać załadowany",
  "error.loadDocuments": "Dokumenty nie mogły zostać załadowane",
  "error.loadDraft": "Wydanie nie może być załadowane",
  "error.loadEmployee": "Pracownik nie może być załadowany",
  "error.loadEmployeeDocuments": "Pliki nie mogły zostać załadowane",
  "error.loadEmployees": "Pracownicy nie mogli być zapraszani",
  "error.loadEvents": "Zdarzenia nie mogły zostać załadowane, spróbuj ponownie później",
  "error.loadFeatureFlags": "Nie można załadować flag funkcji",
  "error.loadFeedCountries": "Kraje nie mogą być załadowane.",
  "error.loadFinancialAccountancies": "",
  "error.loadFormData": "Dane formularza nie mogły zostać załadowane",
  "error.loadFunders": "Jednostka kosztowa nie może być załadowana",
  "error.loadGeneralDocumentsTimeout": "Raporty są tymczasowo niedostępne. Spróbuj ponownie później.",
  "error.loading": "Błąd ładowania",
  "error.loadInsuranceCarrierBalance": "Błąd podczas ładowania salda ubezpieczenia zdrowotnego",
  "error.loadInsuranceCarriers": "Błąd podczas ładowania kas chorych",
  "error.loadInvitationResults": "Błąd podczas ładowania zmian wprowadzonych przez użytkownika",
  "error.loadKpiConfigurations": "Nie można załadować konfiguracji faktów i liczb",
  "error.loadKpis": "Fakty i liczby nie mogą być załadowane, spróbuj ponownie później",
  "error.loadMessages": "Komunikaty nie mogą być załadowane",
  "error.loadModuleTypeDetails": "Błąd ładowania szczegółów modułu",
  "error.loadNews": "Komunikaty nie mogły zostać załadowane, spróbuj ponownie później",
  "error.loadNews.noSource": "Kanał wiadomości jest obecnie niedostępny.",
  "error.loadNotifications": "Nie można załadować ustawień powiadomień",
  "error.loadOptionalMenuItems": "Nie można załadować opcjonalnych elementów menu",
  "error.loadPayrollAccount": "Nie można załadować konta listy płac",
  "error.loadPayrollAccountDetails": "Nie można załadować danych konta listy płac",
  "error.loadPendingMicrosoftActions": "Nie można załadować informacji o zaproszeniach i odświeżaniu",
  "error.loadPeriod": "Błąd podczas ładowania okresu",
  "error.loadPeriodData": "Błąd podczas ładowania danych okresu",
  "error.loadPermissions": "Nie można załadować uprawnień",
  "error.loadPersonnelFile": "Nie można załadować pliku z danymi osobowymi",
  "error.loadPersonnelFile.notStaff": "Użytkownik nie jest osobą zarabiającą",
  "error.loadPowerBIReportEmbedToken": "",
  "error.loadPowerBIReports": "",
  "error.loadPreRegistration": "Nagranie wstępne nie może zostać załadowane.",
  "error.loadProfile": "Profil nie może być załadowany",
  "error.loadProfitAndLoss": "Nie można załadować G&V",
  "error.loadProjects": "Projekty nie mogą być załadowane",
  "error.loadProjectsCompanies": "Nie można załadować firm dla projektów",
  "error.loadPromotions": "Elementy nie mogły zostać załadowane, spróbuj ponownie później",
  "error.loadPublications": "Publikacje nie mogły zostać załadowane, spróbuj ponownie później",
  "error.loadRecord": "Dokument nie może zostać załadowany",
  "error.loadRecords": "Pokwitowania nie mogą być załadowane",
  "error.loadRecordTypes": "Błąd podczas ładowania typów rezerwacji",
  "error.loadReports": "Raporty nie mogą zostać załadowane",
  "error.loadResponsibleUsers": "Doradcy nie mogą być załadowani",
  "error.loadRolePermissions": "Błąd podczas ładowania uprawnień do roli",
  "error.loadSubsidiaries": "Błąd podczas ładowania kodów firmowych",
  "error.loadSubsidiary": "Błąd podczas ładowania kodu firmy",
  "error.loadTeam": "Członkowie zespołu nie mogą zostać załadowani",
  "error.loadTermsofUse": "Podczas ładowania warunków użytkowania wystąpił błąd",
  "error.loadTicketDetails": "Konsultacje nie mogą być załadowane",
  "error.loadTickets": "Zapytania nie mogą być załadowane",
  "error.loadUserCompanySettings": "Błąd podczas ładowania ustawień użytkownika",
  "error.loadUsers": "Nie można załadować użytkowników",
  "error.loadVendorListing": "Nie można załadować listy sprzedawców",
  "error.loadWaitingUsers": "Błąd podczas ładowania użytkowników do zwolnienia",
  "error.loginAccountSetup": "Jesteś tu po raz pierwszy? Przygotowujemy Twoje konto użytkownika. Spróbuj ponownie za kilka minut.",
  "error.logout": "Błąd wylogowania",
  "error.markAsDone": "Jako zakończone można oznaczyć tylko te zapytania, które zostały utworzone przez użytkownika",
  "error.markBankAccountTransactionAsPrivate": "Transakcja nie mogła zostać oznaczona jako prywatna",
  "error.maxCharacters": "Maksymalna dopuszczalna liczba znaków {count}",
  "error.mfaActivation.codeNotMatched": "Wprowadzony kod jest nieprawidłowy",
  "error.mfaCodeAlreadySent": "Kod został już wysłany, proszę poczekać co najmniej 20 sekund przed zażądaniem kolejnego.",
  "error.mfaCodeWrong": "Przepraszamy, wprowadzony kod jest nieprawidłowy. Proszę spróbować ponownie.",
  "error.mfaSettingChange": "Nie można aktywować lub dezaktywować funkcji 2FA, ponieważ zażądano już kodu do aktywacji. Proszę odczekać co najmniej 20 sekund przed ponowną próbą.",
  "error.mfaSettingChange.codeAlreadySent": "Poproszono już o nowy kod. Proszę odczekać co najmniej 20 sekund przed ponowną próbą.",
  "error.mfaSettingChange.codeNotMatched": "Wprowadzony kod jest nieprawidłowy",
  "error.microsoftLogin": "Wystąpił problem podczas komunikacji z firmą Microsoft. Proszę spróbować zalogować się ponownie.",
  "error.missingDocument": "Brak dokumentu",
  "error.missingEmail": "Brakujący adres e-mail",
  "error.missingEmployee": "Brak pracownika",
  "error.missingParams": "Brakujące parametry",
  "error.move": "Błąd przesunięcia",
  "error.move.alreadyExists.unknown": "Błąd podczas przenoszenia: Folder lub plik o tej nazwie już istnieje",
  "error.msTokenExpired": "Twój MS Token stracił ważność. Proszę go odnowić.",
  "error.multipleArchives": "Kilka archiwów klientów dostępnych dla tej firmy",
  "error.noArchive": "Brak archiwum klientów dla tej firmy",
  "error.noCompanySelected": "Nie wybrano żadnego przedsiębiorstwa",
  "error.noDocumentSelected": "Proszę wybrać dokument, aby zapoznać się z warunkami użytkowania",
  "error.noDotAtEnd": "Ostatni znak nie może być kropką",
  "error.noFinancialAccountancies": "Firma nie posiada FIBU",
  "error.noMsAccountAvailable": "Użytkownik nie może zostać zaproszony. Oczekuje się, że konto Microsoft istnieje już dla pracowników TPA.",
  "error.noRecordTypes": "Nie znaleziono żadnych kategorii dokumentów",
  "error.noSignees": "Proszę dodać adresy e-mail",
  "error.noSubsidiaries.accounting": "Nie znaleziono kodów firmowych",
  "error.noSubsidiaries.hr": "Nie znaleziono obszarów księgowych",
  "error.noSubsidiaryWithEmployeeRead": "Brak koła płac z prawem do odczytu dla pracowników.",
  "error.notAdvisor": "Brak zezwoleń konsultantów",
  "error.notAllowed": "Działanie nie może być realizowane",
  "error.notificationCenter.load": "Nie można załadować powiadomień",
  "error.notificationCenter.markAllAsRead": "Powiadomienia nie mogły zostać oznaczone jako przeczytane",
  "error.notificationCenter.markAsRead": "Powiadomienie nie mogło zostać oznaczone jako przeczytane",
  "error.oauthApi": "Wystąpił problem z uwierzytelnieniem. Proszę spróbować ponownie.",
  "error.oauthCompany": "Brak wybranej firmy. Proszę wybrać firmę i spróbować ponownie.",
  "error.oauthUrl": "Wystąpił problem z wywołaniem Faktów i liczb. Proszę spróbować ponownie.",
  "error.overlappingSignature": "Nakładający się podpis. Wybierz nową pozycję",
  "error.passwordReset.tokenExpired": "Ten link do resetowania hasła wygasł",
  "error.passwordReset.tokenNotFound": "Link resetowania nieprawidłowego hasła",
  "error.payAccountTransaction": "",
  "error.payTaxAccount": "Błędy podczas płacenia zobowiązania podatkowego",
  "error.payUnpaidAccount": "Błąd podczas płacenia faktury TPA",
  "error.periodClosed": "Okres już zakończony",
  "error.permission.corruptRoles": "Nieprawidłowe role",
  "error.permission.onlyOneRoleAllowed": "Można ustawić tylko jedną rolkę",
  "error.postTicket": "Konsultacje nie mogły zostać utworzone.",
  "error.projectForbidden": "Nie masz dostępu do projektów",
  "error.projectIsEmpty": "Projekt jest pusty i dlatego nie można go pobrać",
  "error.projectPermissions": "Nie można załadować uprawnień do projektu",
  "error.projects.cantMove": "Pliki nie mogły zostać przeniesione. Proszę spróbować ponownie później.",
  "error.projects.cantPaste": "Pliki nie mogły zostać wstawione. Proszę spróbować ponownie później.",
  "error.projects.openBookmarkUrlFailed": "Adres URL nie mógł zostać otwarty",
  "error.qes.missingSignaturePositions": "Prosimy o dodanie wymaganych pozycji podpisu",
  "error.recordForbidden": "Nie masz dostępu do vouchera",
  "error.recordWithNotAllowedCompany": "Brak autoryzacji dla firmy, do której przypisany jest dokument.",
  "error.refreshBankConnection": "Nie można było odnowić rachunku bankowego",
  "error.releaseEmployeeDocument": "Błąd zwolnienia",
  "error.releaseReport": "Błąd zwolnienia",
  "error.removeBankAccountTransactionPrivateMark": "Nie można usunąć zaznaczenia",
  "error.removeCompanyFromUserFavorites": "Firmy nie można usunąć z ulubionych",
  "error.rename.alreadyExists.file": "Błąd podczas zmiany nazwy: Plik o tej nazwie już istnieje",
  "error.rename.alreadyExists.folder": "Błąd podczas zmiany nazwy: Folder o tej nazwie już istnieje",
  "error.requiredField": "Wymagane dane wejściowe",
  "error.resendTermsOfUseEmail": "Podczas ponownego wysyłania wiadomości e-mail wystąpił błąd",
  "error.save": "Błąd podczas zapisywania",
  "error.saveKpiConfigurations": "Fakty i liczby Konfiguracja nie mogła zostać zapisana",
  "error.saveOptionalMenuItems": "Nie można było załadować opcjonalnych elementów menu. Proszę spróbować ponownie później",
  "error.saveTermsOfUse": "Podczas zapisywania warunków użytkowania wystąpił błąd. Proszę spróbować ponownie później",
  "error.send": "Błąd podczas wysyłania.",
  "error.setDocumentOrSignees": "Podczas zapisywania dokumentu lub osób do podpisu wystąpił błąd",
  "error.settings": "Błąd podczas zmiany ustawień",
  "error.signFileTooLarge": "Plik jest zbyt duży i nie może zostać podpisany elektronicznie.",
  "error.signReadOnlyDocument": "Dokument jest tylko do odczytu i dlatego nie może być podpisany",
  "error.signTermsOfUse": "Wystąpił błąd podczas akceptacji warunków użytkowania. Proszę spróbować ponownie później",
  "error.subsidiaryWithoutRecordTypes": "{podmiot zależny} nie ma aktywnych symboli dokumentów",
  "error.termsOfUseDuplicateFile": "Ten sam plik o innej nazwie już istnieje",
  "error.termsOfUseFolderNotFound": "Nie utworzono jeszcze folderu z warunkami użytkowania",
  "error.timeout": "Obecnie proces ten trwa nieco dłużej. Prosimy spróbować ponownie później.",
  "error.toggleCompanyFeature": "Błąd podczas aktywacji/dezaktywacji dodatku",
  "error.tokenRefresh": "Błąd uwierzytelniania",
  "error.tooManyCharacters": "Maksymalna ilość znaków {maxCharacters} dozwolona w wiadomości.",
  "error.transfer": "Błąd transferu",
  "error.transferEmployee": "Pracownik nie mógł zostać przeniesiony",
  "error.transferEmployeeDirect": "Przekazanie do kasy chorych nie powiodło się. Prosimy o kontakt z doradcą.",
  "error.transferEmployee.validation.general": "",
  "error.transferEmployee.validation.general.zipcode": "",
  "error.unknown": "Nieznany błąd ({statusCode})",
  "error.unsupportedDocumentType": "Ten typ dokumentu nie jest obsługiwany",
  "error.updateBankAccountAccountNumber": "Konto nie mogło zostać zapisane.",
  "error.upgradeToMicrosoft": "Aktualizacja nie mogła zostać przeprowadzona. Proszę sprawdzić swoje hasło.",
  "error.upload": "Błąd przesyłania",
  "error.userDeactivated": "Ten użytkownik Microsoft został dezaktywowany.",
  "error.userNotRegistered": "Użytkownik nie jest zarejestrowany",
  "error.userUnknown": "Nieznany użytkownik",
  "externalLinkDialog.content": "",
  "externalLinkDialog.title": "",
  "failedUserDialog.amount": "<b>{failedUserCount}</b> z {userCount} użytkowników nie mogło zostać utworzone/modyfikowane:",
  "failedUserDialog.button.text.continue": "Kontynuuj niemniej jednak",
  "failedUserDialog.button.text.retry": "Spróbuj ponownie",
  "failedUserDialog.error.emailAlreadyExists": "Adres e-mail, który jest już używany",
  "failedUserDialog.error.pendingPermissionChanges": "Już czekam na premierę",
  "failedUserDialog.message": "Błąd przy tworzeniu/zmienianiu",
  "features.default.prices.from": "",
  "features.default.prices.fromFlat": "",
  "features.default.prices.fromTo": "",
  "features.default.prices.fromToFlat": "",
  "filterDialog.apply": "Zastosuj filtr",
  "filterDialog.category.advanced": "Filtry zaawansowane",
  "filterDialog.category.assigned": "Przydzielony obszar",
  "filterDialog.header": "Opcje filtrowania",
  "filterDialog.required": "Wybierz wartość",
  "filterDialog.reset": "FILTR RESET",
  "finishProjectDialog.text": "Czy na pewno chcesz zakończyć projekt <b>{projectName}</b>?",
  "finishProjectDialog.title": "Projekt gotowy",
  "footer.copyright": "Wszelkie prawa zastrzeżone.",
  "footer.disclaimer": "Zastrzeżenie",
  "footer.generalBusinessTerms": "AGB",
  "footer.legalInformation": "Nadruk",
  "footer.privacy": "Ochrona danych",
  "helpcenter.ideas": "Pomysły lub życzenia",
  "helpcenter.problems": "Zgłaszanie problemów",
  "helpcenter.problems.email": "Adres e-mail",
  "helpcenter.problems.send": "Zgłoś problem",
  "helpcenter.problems.upload.failed": "Problem nie mógł zostać przekazany",
  "helpcenter.problems.upload.success": "Problem został pomyślnie przesłany",
  "helpcenter.problems.upload.tooManyRequests": "",
  "helpcenter.problems.whathappened": "Co się stało zamiast tego?",
  "helpcenter.problems.whatshouldhappen": "Co powinno się wydarzyć?",
  "helpcenter.problems.whatwasdone": "Co próbowano zrobić?",
  "helpCenter.title": "Centrum pomocy",
  "helpcenter.videos": "Pomoc wideo",
  "helpcenter.videos.tpaonly": "Pomoc wideo dla pracowników TPA",
  "hr.casualEmployment": "Zatrudniani w zależności od przypadku",
  "hr.disclaimer.additionalData": "Do bezpośredniej transmisji potrzebujemy jeszcze kilku dodatkowych danych. Proszę je wypełnić.",
  "hr.disclaimer.employmentDate": "Data zatrudnienia",
  "hr.disclaimer.subtitle": "Czy chcesz wysłać dane do TPA czy bezpośrednio do kasy chorych?",
  "hr.disclaimer.title": "Transmisja",
  "hr.disclaimer.transfer.direct": "Przekazanie danych następuje bezpośrednio do austriackiej kasy chorych",
  "hr.disclaimer.transfer.direct.subtitle": "Zaznaczamy, że TPA sprawdzi dane dopiero w najbliższych dniach, najwcześniej wtedy, gdy urząd będzie otwarty.",
  "hr.disclaimer.transfer.tpa": "Transmisja danych odbywa się do TPA",
  "hr.disclaimer.transfer.tpa.subtitle": "Zwracamy uwagę, że rejestracja pracownika w Austriackiej Kasie Chorych nastąpi dopiero po przetworzeniu danych przez TPA.",
  "hr.documents.backLabel": "Personel/Pliki",
  "hr.documents.emptyState.header": " przechwytywanie",
  "hr.documents.emptyState.message": "Zacznij przesyłać swoje pliki już teraz.",
  "hr.documents.list.backLabel": "Personel/ Dokumenty",
  "hr.documents.nonWorkingTimes": "Czas poza spektaklem",
  "hr.documents.nonWorkingTimesUpload": "Czas niewykonywania wysyłania plików",
  "hr.documents.upload.navHeader": "{documentType}",
  "hr.documents.upload.navLabel": "Personel/Dokumenty",
  "hr.employee.employment": ". Stosunek pracy",
  "hr.employee.employmentRelationship": "Stosunek pracy",
  "hr.employee.jobDescription": "Stanowisko",
  "hr.employee.payslip.upload.date": "Okres",
  "hr.employee.payslip.upload.title": "Prześlij odcinek wypłaty",
  "hr.employee.payslip.upload.type": "Typ odcinka wypłaty",
  "hr.employee.payslip.upload.type.monthly": "Miesięczny odcinek wypłaty",
  "hr.employee.payslip.upload.type.yearly": "Roczny odcinek wypłaty",
  "hr.employee.reasonForLeaving": "Powód odejścia",
  "hr.employee.workplaceState": "Stan miejsca pracy",
  "hr.finished.analysis": "Oceny miesięczne",
  "hr.finished.billing": "Wyciągi miesięczne",
  "hr.finished.globalAnalysis": "Ocena w skali całego przedsiębiorstwa",
  "hr.finished.globalBilling": "Księgowość w całej firmie",
  "hr.insuranceCarrier": "Przewoźnik ubezpieczeniowy",
  "hr.overview.continuousActions": "Funkcje ciągłe",
  "hr.recordTypes.names.benefitsInKindCar": "Świadczenia rzeczowe samochód",
  "hr.recordTypes.names.contractAdjustmentsAndSpecialPayments": "Korekty umowy i płatności specjalne",
  "hr.recordTypes.names.mealDeductions": "Odliczenia za posiłki",
  "hr.recordTypes.names.nonWorkingTimes": "Czasy niewykonania",
  "hr.recordTypes.names.overtimeAndBonusPayments": "Płatności za nadgodziny i premie",
  "hr.recordTypes.names.supplementaryDocuments": "Dokumenty uzupełniające",
  "hr.recordTypes.names.timeRecords": "Zapisy czasu",
  "hr.recordTypes.names.travelExpenseReport": "Raport kosztów podróży",
  "hr.tickets.preRegistration.NavBar": "Zapytanie o {preRegistration}",
  "hr.tickets.preRegistration.NavBarLabel": "Personel / Pracownicy / Zapytania",
  "hr.tickets.preRegistration.toOverview": "DO AKT OSOBOWYCH",
  "imageCrop.description": "Przeciągnij, aby przesunąć obraz",
  "imageCrop.title": "Obraz położenia",
  "imageCrop.zoom": "Zoom",
  "language.german": "Niemiecki",
  "maritalStatus.divorced": "rozwiedziony",
  "maritalStatus.livingInPartnership": "Życie w partnerstwie",
  "maritalStatus.married": "Małżeństwo/rejestrowany związek partnerski",
  "maritalStatus.separated": "Mieszkać osobno",
  "maritalStatus.unknown": "nieznany",
  "maritalStatus.unmarried": "pojedynczy",
  "maritalStatus.widowed": "owdowiały",
  "menu.answer": "Odpowiedź",
  "menu.assign": "Przyporządkuj",
  "menu.cancelRelease": "Wycofać zwolnienie",
  "menu.delete": "Usuń",
  "menu.details": "Szczegóły",
  "menu.download": "Pobierz",
  "menu.draft": "Wniosek o zatwierdzenie",
  "menu.edit": "Edytuj",
  "menu.editRelease": "Ponowne żądanie zwolnienia",
  "menu.finish": "Zamek",
  "menu.markAsDone": "Oznacz jako wykonane",
  "menu.move": "Przesuń",
  "menu.openInBrowser": "Otwórz w przeglądarce",
  "menu.openInSharepoint": "Otwórz w Sharepoint",
  "menu.rename": "Zmień nazwę",
  "menu.requestRelease": "Zwolnienie z żądania",
  "menu.showReleases": "Wydawnictwa pokazowe",
  "menu.view": "Widok",
  "mfa.activation.dialog.code.required": "Proszę wpisać kod",
  "mfa.activation.dialog.description": "Wpisz kod, który wysłaliśmy na Twój numer telefonu, aby aktywować uwierzytelnianie dwuskładnikowe.",
  "mfa.activation.dialog.title": "Aktywuj 2FA",
  "mfa.activation.success.dialog.title": "2FA został pomyślnie aktywowany",
  "mfa.changeSetting.dialog.code.field.label": "Kod",
  "mfa.changeSetting.dialog.resendCode": "Wyślij ponownie kod",
  "mfa.codeResend.success.dialog.title": "Kod został wysłany ponownie",
  "mfa.deactivation.dialog.description": "Wpisz kod, który wysłaliśmy na Twój numer telefonu, aby wyłączyć uwierzytelnianie dwuskładnikowe.",
  "mfa.deactivation.dialog.title": "Dezaktywacja 2FA",
  "mfa.deactivation.success.dialog.title": "2FA został pomyślnie dezaktywowany",
  "mfa.validation.code.length": "Kod składa się z 6 cyfr",
  "mfa.validation.code.required": "Kod musi być wprowadzony",
  "mfa.validation.code.type": "Dozwolone są tylko liczby",
  "microsoft_oauth.button.login.title": "Zaloguj się do Microsoft",
  "microsoft_oauth.button.send_code.title": "Wyślij kod",
  "microsoft_oauth.button.upgrade.title": "Przeprowadzić modernizację",
  "missingEmailDialog.description": "Adres e-mail <b>{name}</b> nie znajduje się w pliku. Proszę podać poprawny adres e-mail, aby móc wysłać zaproszenie.",
  "missingEmailDialog.title": "Brak adresu e-mail",
  "missingEmail.email.error": "Proszę wpisać poprawny adres e-mail",
  "missingEmail.email.label": "Wpisz adres e-mail",
  "module.finished.globalReports": "Raporty dla całej firmy",
  "modules.delete.report.message": "Czy na pewno chcesz usunąć raport {name}?",
  "modules.delete.report.title": "Usuń raport",
  "navbar.week": "KW {tydzień} {Rok}",
  "newUsername.checkbox.error": "Proszę potwierdzić swój nowy adres e-mail",
  "notificationCenter.emptyState": "Brak powiadomień",
  "notificationCenter.filter.accounting": "Księgowość",
  "notificationCenter.filter.appNotification:maintenance": "Komunikaty dotyczące konserwacji",
  "notificationCenter.filter.appNotification:newFeature": "Nowe funkcje",
  "notificationCenter.filter.appNotification:update": "",
  "notificationCenter.filter.companySpecific": "Specyficzne dla firmy",
  "notificationCenter.filter.employeeDocument": "Dokumenty pracownicze",
  "notificationCenter.filter.file": "Dokumenty",
  "notificationCenter.filter.general": "Ogólne",
  "notificationCenter.filter.generalDocument": "Pliki ogólne",
  "notificationCenter.filter.hr": "Księgowość płac",
  "notificationCenter.filter.invoice": "Wpływy",
  "notificationCenter.filter.login": "Logowanie",
  "notificationCenter.filter.module": "Moduł",
  "notificationCenter.filter.notificationType": "Typ powiadomienia",
  "notificationCenter.filter.other": "Inne",
  "notificationCenter.filter.payslip": "odcinek wypłaty",
  "notificationCenter.filter.project": "Projekty",
  "notificationCenter.filter.report": "Raporty",
  "notificationCenter.filter.ticket": "Zapytanie",
  "notificationCenter.filter.unread": "Tylko nieprzeczytane",
  "notificationCenter.markAllAsRead": "Oznacz wszystkie jako przeczytane",
  "notificationCenter.title": "Centrum powiadomień",
  "openEmployeeDocumentReleasesDialog.releaseMessage.multi": "Masz <b>{liczba} nowych dokumentów</b> w aktach osobowych, które wymagają zatwierdzenia.",
  "openEmployeeDocumentReleasesDialog.releaseMessage.single": "W aktach osobowych znajduje się <b>nowy dokument</b>, który wymaga zatwierdzenia.",
  "openEmployeeDocumentReleasesDialog.submit": "Przeglądaj i udostępniaj",
  "openEmployeeDocumentReleasesDialog.title": "Udostępnianie dokumentów",
  "paymentDialog.button.pay": "",
  "paymentDialog.field.amount": "",
  "paymentDialog.field.amount.maxError": "",
  "paymentDialog.field.amount.minError": "",
  "paymentDialog.field.amount.typeError": "",
  "paymentDialog.field.iban": "",
  "paymentDialog.field.iban.invalidError": "",
  "paymentDialog.field.purpose": "",
  "paymentDialog.title": "",
  "pdfView.error.caption": "Nie można załadować dokumentu, spróbuj ponownie",
  "pendingUserCreationDialog.message": "Tworzenie użytkowników w Sharepoint może zająć do 30 minut. Prosimy o cierpliwość do czasu utworzenia uprawnień.",
  "pendingUserCreationDialog.title": "Użytkownicy są tworzeni",
  "permission.accounting.banking": "Dane bankowe",
  "permission.accounting.global.reports": "Raporty księgowe dla całej firmy",
  "permission.accounting.global.results": "Oceny księgowe w skali całego przedsiębiorstwa",
  "permission.accounting.incaseoflaw": "W przypadku prawa",
  "permission.accounting.records": "Ogłoszenie o księgowości",
  "permission.accounting.reports": "Sprawozdania rachunkowe",
  "permission.accountTransaction.payment": "",
  "permission.expenses.cockpit": "Wyświetlanie faktów i liczb w kokpicie",
  "permission.expenses.kpi": "Korzystanie z modułu faktów i liczb",
  "permission.hr.employeePayrollAccount": "Wyświetlanie konta płacowego pracownika",
  "permission.hr.employerPayrollAccount": "Konto płacowe pracodawcy",
  "permission.hr.employerPayrollAccount.details": "Wyświetl szczegóły typu wynagrodzenia",
  "permission.hr.global.reports": "Raporty księgowe dotyczące płac w całej firmie",
  "permission.hr.global.results": "Analizy księgowości płacowej w całej firmie",
  "permission.hr.personnel": "Pracownicy",
  "permission.hr.records": "Ogłoszenie ugody personalnej",
  "permission.hr.reports": "Raporty księgowe z listy płac",
  "permission.option.canAccess": "może mieć dostęp",
  "permission.option.canEdit": "może edytować",
  "permission.option.canEdit.all": "może edytować/odblokować wszystko",
  "permission.option.canEdit.none": "nie można niczego edytować/odblokować",
  "permission.option.canEdit.some": "Może częściowo edytować/wydawać",
  "permission.option.canPay": "może zapłacić",
  "permission.option.canRead": "można zobaczyć",
  "permission.option.canRead.all": "może zobaczyć wszystko",
  "permission.option.canRead.none": "nie może nic zobaczyć",
  "permission.option.canRead.some": "częściowo widzi",
  "permission.option.canRelease": "może zwolnić",
  "permission.option.canUpdate": "może edytować",
  "permission.option.canUpload": "może przesłać",
  "permission.option.mixed": "mieszane",
  "permission.option.none": "brak zezwolenia",
  "permission.projects": "Korzystanie z modułu projektów",
  "permission.settings.company": "Zobacz ustawienia firmy",
  "permission.settings.features": "Modyfikacja dodatków",
  "permission.settings.images": "Zmiana zdjęcia okładki i logo",
  "permission.settings.user": "Zaproponuj zmiany w uprawnieniach użytkowników",
  "permission.taxAccount.payment": "Zapłacić dług podatkowy",
  "permission.unpaidAccount.payment": "Zapłata zaległych faktur",
  "project.invitation": "Zaproszenie na projekt",
  "projectItemPermission.owner": "Pełny dostęp",
  "projectItemPermission.owner.description": "Wszystkie prawa do edycji folderów i plików. Edytuj i uzupełniaj projekt oraz zarządzaj członkami i uprawnieniami.",
  "projectItemPermission.read": "Czytaj",
  "projectItemPermission.read.description": "Przeglądanie folderów i plików oraz pobieranie plików",
  "projectItemPermission.readNoDownload": "Tylko do odczytu",
  "projectItemPermission.write": "Edytuj",
  "projectItemPermission.write.description": "Przeglądanie, tworzenie i zmiana nazw folderów. Przeglądanie, przesyłanie, edytowanie i usuwanie plików.",
  "projects.activate": "RESTORE",
  "projects.active.emptystate.buttonlabel": "Utwórz projekt",
  "projects.active.emptystate.message": "Nie ma jeszcze żadnego projektu dla tej firmy",
  "projects.addCompanies": "Dodaj firmę",
  "projects.addCompanies.tooltip": "Utwórz projekt w kilku firmach",
  "projects.addFolder": "Utwórz folder",
  "projects.addMembers": "Dodaj członków",
  "projects.addMembers.bulk.success.title": "Członkowie dodani pomyślnie",
  "projects.bulkDeleteDialog.message": "Czy na pewno chcesz usunąć wybrane pliki i foldery?",
  "projects.bulkDeleteDialog.title": "Usuwanie plików i folderów",
  "projects.cancelRequestedReleaseConfirmationDialog.confirm": "Wycofaj się",
  "projects.cancelRequestedReleaseConfirmationDialog.message": "Czy na pewno chcesz wycofać otwarty udział do <b>{fileName}</b>?",
  "projects.cancelRequestedReleaseConfirmationDialog.title": "Wycofać istniejące zwolnienie?",
  "projects.closed.emptystate.message": "Dla tej firmy nie ma jeszcze ukończonego projektu",
  "projects.companies.emptyState.title": "Brak dostępnych firm",
  "projects.companies.submit": "Dodaj",
  "projects.copySuccessDialog.message": "Wybrane pliki zostały skopiowane do schowka",
  "projects.copySuccessDialog.title": "Skopiowane do schowka",
  "projects.create.bulk.backToProjects": "Powrót do projektów",
  "projects.create.bulk.description": "Projekty są właśnie tworzone. Może to potrwać kilka minut, nie zamykaj okna przeglądarki.",
  "projects.create.bulk.start": "Uruchomienie",
  "projects.deleted.emptystate.message": "Nie ma jeszcze usuniętego projektu dla tej firmy",
  "projects.deleteDialog.message.file": "Czy na pewno chcesz usunąć plik <b>{name}</b>?",
  "projects.deleteDialog.message.folder": "Czy na pewno chcesz usunąć folder <b>{name}</b>?",
  "projects.deleteDialog.message.project": "Czy na pewno chcesz usunąć projekt <b>{name}</b>?",
  "projects.deleteDialog.title.file": "Usuń plik",
  "projects.deleteDialog.title.folder": "Usuń folder",
  "projects.deleteDialog.title.project": "Usuń projekt",
  "projects.emptystate.title": "Brak dostępnych projektów",
  "projects.externalId": "Numer referencyjny:",
  "projects.finish.successDialog.title": "Projekt zakończony sukcesem",
  "projects.folder.emptystate.message": "Teraz zacznij wypełniać folder.",
  "projects.folder.emptystate.title": "Tworzenie i przesyłanie treści",
  "projects.importUsers.add": "Dodaj",
  "projects.importUsers.replace": "Wymiana",
  "projects.importUsers.switch": "Importowanie członków z innego projektu",
  "projects.international": "Projekt międzynarodowy",
  "projects.international.activate": "Aby przekształcić projekt w projekt międzynarodowy, należy włączyć tę funkcję.",
  "projects.international.confirmButton": "Konwertuj projekt",
  "projects.international.disabled.refresh": "Twój międzynarodowy login MS wygasł, proszę go odnowić. Po tym możesz zmienić \"{projekt}\" na projekt międzynarodowy.",
  "projects.international.disabled.unlock": "Aby przekształcić projekt w międzynarodowy, musisz włączyć tę funkcjonalność w przeglądzie projektów.",
  "projects.international.info.message": "Przekształcenie projektu w projekt międzynarodowy umożliwia zaproszenie do projektu pracowników TPA z innych krajów, aby mogli współpracować z klientem ponad granicami. W tym celu wszystkie pliki i dokumenty przechowywane w ramach projektu w programie Microsoft SharePoint są przenoszone między różnymi dzierżawcami firmy Microsoft, a przy okazji przebudowywane są wszystkie uprawnienia użytkowników do pracy nad projektem. W procesie tym mogą zostać utracone wszelkie drobiazgowe uprawnienia. Dlatego zaleca się sprawdzenie opcji dostępu po przekształceniu w projekt międzynarodowy. Tego kroku nie można cofnąć.",
  "projects.international.info.subtitle": "Należy zwrócić uwagę na następujące kwestie:",
  "projects.international.invite.message": "Zostałeś dodany do nowych projektów międzynarodowych. Aktywuj raz następujące kraje, aby móc oglądać projekty z tych krajów.",
  "projects.international.invite.title": "Odblokuj nowe kraje",
  "projects.international.migrating": "Projekt międzynarodowy (konwersja w toku)",
  "projects.international.migrating.message": "Projekt ten jest obecnie przekształcany w projekt międzynarodowy. Edycja będzie możliwa dopiero po zakończeniu konwersji.",
  "projects.international.plural": "Projekty międzynarodowe",
  "projects.international.switch": "Przekształć ten projekt w projekt międzynarodowy",
  "projects.international.undoWarning.message": "Czy na pewno chcesz przekształcić ten projekt w projekt międzynarodowy? Wszystkie dane projektu zostaną skopiowane do programu TPA AT sharepoint. Tej czynności nie można cofnąć po utworzeniu projektu.",
  "projects.international.undoWarning.message.at": "Czy na pewno chcesz przekształcić ten projekt w projekt międzynarodowy? Tej czynności nie można cofnąć po utworzeniu projektu.",
  "projects.international.undoWarning.title": "Tej czynności nie można cofnąć.",
  "projects.invite.international": "Zostałeś dodany do projektów międzynarodowych. Odblokuj je teraz.",
  "projects.invite.message": "Zostałeś dodany do projektów z krajów {numCountries}. Odblokuj je teraz.",
  "projects.invite.message.singular": "Zostałeś dodany do projektów z {kraju}. Odblokuj je teraz.",
  "projects.members.assignPermission": "Przydzielanie uprawnień",
  "projects.membersIncluded.multi": "W tym {imiona} i {nazwa}.",
  "projects.membersIncluded.single": "W tym {name}.",
  "projects.moveConfirmationDialog.message": "Przeniesione pliki są usuwane z oryginalnego folderu!",
  "projects.moveConfirmationDialog.title": "Przenieść pliki?",
  "projects.moveSuccessDialog.message": "Pliki zostały przeniesione pomyślnie",
  "projects.moveSuccessDialog.title": "Pliki przeniesione",
  "projects.navbar.activeProjects": "Aktywny",
  "projects.navbar.deletedProjects": "Usunięto",
  "projects.navbar.fileUpload": "Przesyłanie plików",
  "projects.navbar.finishedProjects": "Zakończono",
  "projects.navbar.heading": "Projekty",
  "projects.navbar.notConfiguredProjects": "Nie skonfigurowany",
  "projects.navbar.overview": "Przegląd{ścieżka}",
  "projects.navbar.project.fileTree": "Struktura folderów",
  "projects.navbar.project.heading": "Projekt: {projectName}",
  "projects.navbar.project.releases": "Zwolnienia",
  "projects.navbar.releases.heading": "Udziały dla {fileName}",
  "projects.navbar.ticket.heading": "Zapytanie dotyczące {fileName}",
  "projects.new": "Nowy projekt",
  "projects.new.createNewProject": "Utwórz projekt",
  "projects.new.externalId": "Numer referencyjny",
  "projects.new.name": "Nazwa projektu",
  "projects.new.permissionContextMenu.remove": "Usuń",
  "projects.new.permissions.text": "Dodawanie nowych członków i przypisywanie im praw użytkownika.",
  "projects.new.title": "Nazwa projektu",
  "projects.new.userSearch.placeholder": "Zaproś członka...",
  "projects.notConfigured.emptystate.message": "Nie ma jeszcze żadnego projektu do skonfigurowania dla tej firmy",
  "projects.openReleases.plural": "Otwarte wydania",
  "projects.openReleases.singular": "Otwarte wydanie",
  "projects.pasteProgressDialog.title": "Pliki są wstawiane",
  "projects.pasteSuccessDialog.message": "Pliki zostały pomyślnie wstawione",
  "projects.pasteSuccessDialog.title": "Wstawione pliki",
  "projects.refreshDialog.message": "Wygasły loginy Microsoft dla następujących krajów. Prosimy o ich odnowienie.",
  "projects.refreshDialog.title": "Wygasły loginy Microsoft",
  "projects.refresh.message": "Loginy Microsoft dla krajów {numCountries} straciły ważność. Prosimy o ich odnowienie.",
  "projects.refresh.message.singular": "Twój login Microsoft dla {kraj} stracił ważność. Prosimy o jego przedłużenie.",
  "projects.releaseConfirmationDialog.message": "Proszę zwrócić uwagę na następujący komentarz od {name}:",
  "projects.releaseConfirmationDialog.title": "Udostępnij dokument",
  "projects.releases.emptystate.message": "Obecnie nie masz żadnych wydań.",
  "projects.releases.emptystate.title": "Brak dostępnych wydań",
  "projects.releases.reject": "Spadek",
  "projects.releases.status.canceled": "wycofano",
  "projects.releases.status.canceledFrom": "wycofane przez {nazwa}",
  "projects.releases.status.canceled.tooltip": "wycofane w dniu {performedAt} przez {name}",
  "projects.releases.status.open": "Czekając na wydanie",
  "projects.releases.status.openFrom": "oczekiwanie na wydanie {nazwa}",
  "projects.releases.status.open.tooltip": "oczekuje na zwolnienie (termin: {dueDate}) {name}",
  "projects.releases.status.rejected": "Odrzucony",
  "projects.releases.status.rejectedFrom": "odrzucony przez {name}",
  "projects.releases.status.rejected.tooltip": "odrzucony w dniu {performedAt} przez {name}",
  "projects.releases.status.released": "wydany",
  "projects.releases.status.releasedFrom": "wydany przez {name}",
  "projects.releases.status.released.tooltip": "wydany w dniu {performedAt} przez {name}",
  "projects.releaseSuccessDialog.message": "<b>{name}</b> przejrzy teraz pliki i skontaktuje się z Tobą w przypadku jakichkolwiek pytań.",
  "projects.releaseSuccessDialog.multi.message": "Przydzielone osoby będą teraz przeglądać akta i kontaktować się z Państwem w przypadku ewentualnych pytań.",
  "projects.releaseSuccessDialog.title": "Zwolnienie zostało pomyślnie zażądane",
  "projects.requestReleaseConfirmationDialog.confirm": "Wycofaj i odtwórz",
  "projects.requestReleaseConfirmationDialog.message": "Istnieje już otwarty udział dla <b>{fileName}</b>. Aby utworzyć nowy udział, istniejący udział musi zostać wycofany.",
  "projects.requestReleaseConfirmationDialog.multi.message": "W Twoim wyborze są już otwarte wydania. Aby utworzyć nowe akcje, należy wycofać istniejące akcje.",
  "projects.requestReleaseConfirmationDialog.multi.title": "Wycofać istniejące wydania?",
  "projects.requestReleaseConfirmationDialog.title": "Wycofać istniejące zwolnienie?",
  "projects.restoreDialog.message.project": "Czy na pewno chcesz aktywować projekt <b>{name}</b>?",
  "projects.restoreDialog.title.project": "Aktywuj projekt",
  "projects.statusClosed": "Zakończono w dniu",
  "projects.statusClosed.personalized": "Uzupełniony przez {nazwa} dnia",
  "projects.statusDeleted": "Usunięto dnia",
  "projects.statusDeleted.personalized": "Usunięta przez {name} w dniu",
  "projects.statusInProgress": "Ostatnio zmodyfikowano dnia",
  "projects.statusInProgress.personalized": "Ostatnio zmieniony przez {name}, dnia",
  "projects.tickets.plural": "Pytania",
  "projects.tickets.singular": "Konsultacje",
  "records.details.upload.header": "Rekord {recordType}",
  "recordType.statusClosed": "Przekazywane na",
  "recordType.statusInProgress": "Edytowane w dniu",
  "recordType.statusOpen": "Nieedytowane",
  "renameDialog.button.text.rename": "Zmień nazwę",
  "renameDialog.label.document": "Oznaczenie",
  "renameDialog.label.file": "Nazwa pliku",
  "renameDialog.label.folder": "Nazwa folderu",
  "renameDialog.title.document": "Zmień nazwę dokumentu",
  "renameDialog.title.file": "Zmień nazwę pliku",
  "renameDialog.title.folder": "Zmień nazwę folderu",
  "renameProjectItemDialog.button.text.rename": "Zmień nazwę",
  "renameProjectItemDialog.itemName.file": "Nazwa pliku",
  "renameProjectItemDialog.itemName.folder": "Nazwa folderu",
  "renameProjectItemDialog.title.file": "Zmień nazwę pliku",
  "renameProjectItemDialog.title.folder": "Zmień nazwę folderu",
  "renameRecordDialog.button.text.rename": "Zmień nazwę",
  "renameRecordDialog.name": "Oznaczenie",
  "renameRecordDialog.title": "Zmień nazwę dokumentu",
  "requestReleaseProjectItemDialog.button.text.request": "Pytania",
  "requestReleaseProjectItemDialog.info": "Kiedy potrzebuję podpisu kwalifikowanego?",
  "requestReleaseProjectItemDialog.label.comment": "Komentarz",
  "requestReleaseProjectItemDialog.label.dueDate": "Termin:",
  "requestReleaseProjectItemDialog.label.requiresQes": "Wydanie z podpisem elektronicznym (tylko w przypadku plików PDF)",
  "requestReleaseProjectItemDialog.placeholder.searchForUsers": "Podaj nazwę...",
  "requestReleaseProjectItemDialog.qes.collective.subtitle": "Wszystkie osoby muszą zwolnić",
  "requestReleaseProjectItemDialog.qes.collective.title": "Wydanie zbiorcze",
  "requestReleaseProjectItemDialog.qes.single.subtitle": "Tylko jedna osoba musi zwolnić",
  "requestReleaseProjectItemDialog.qes.single.title": "Pojedyncze wydanie",
  "requestReleaseProjectItemDialog.searchForUsers.error": "Proszę wybrać nazwę z listy",
  "requestReleaseProjectItemDialog.text": "Wybierz osoby, którym chcesz przypisać zwolnienie do wymienionych dokumentów i wprowadź termin.",
  "requestReleaseProjectItemDialog.title.addResponsibleUser": "Dodaj kolejną osobę",
  "requestReleaseProjectItemDialog.title.create": "Zwolnienie z żądania",
  "requestReleaseProjectItemDialog.title.edit": "Edycja wydania",
  "results.accountSheet.accountDetails.pay": "",
  "results.accountSheet.accountTransaction.lastCompletedPayment": "",
  "results.accountSheet.accountTransaction.lastCompletedPaymentWithAmount": "",
  "results.accountSheet.accountTransaction.lastCompletedPaymentWithAmount.detailed": "",
  "results.accountSheet.backLabel.accountSheet": "Arkusz konta {accountNr} \"{name}\"",
  "results.accountSheet.backLabel.results.accountListing": "Lista sald",
  "results.accountSheet.backLabel.results.balanceSheet": "Bilans",
  "results.accountSheet.backLabel.results.cashAccounting": "Rachunek dochodów i wydatków",
  "results.accountSheet.backLabel.results.customerListing": "Lista dłużników",
  "results.accountSheet.backLabel.results.profitAndLoss": "G&V",
  "results.accountSheet.backLabel.results.vendorListing": "Lista zobowiązań",
  "results.accountSheet.columns.amount": "Kwota",
  "results.accountSheet.columns.documentDate": "Data dokumentu",
  "results.accountSheet.columns.documentNumber": "Nr dokumentu.",
  "results.accountSheet.columns.documents": "Wpływy",
  "results.accountSheet.columns.offsettingAccounts": "Konta Contra",
  "results.accountSheet.columns.openItemAmount": "Kwota otwarta",
  "results.accountSheet.columns.postingSymbol": "BS",
  "results.accountSheet.columns.postingText": "Tekst posta",
  "results.accountSheet.details.costCenter": "Centrum kosztów",
  "results.accountSheet.details.discount": "Rabat gotówkowy",
  "results.accountSheet.details.externalDocumentNumber": "Dokument zewnętrzny nr.",
  "results.accountSheet.details.foreignCurrency": "Waluta obca / kurs wymiany",
  "results.accountSheet.details.period": "Okres",
  "results.accountSheet.details.serialNumber": "Dziennik nr.",
  "results.accountSheet.details.tax": "Podatek",
  "results.accountSheet.details.taxCode.Ausfuhrlieferungen": "Dostawy eksportowe",
  "results.accountSheet.details.taxCode.BauleistungenMitVSt": "Roboty budowlane z VAT",
  "results.accountSheet.details.taxCode.BauleistungenOhneVSt": "Roboty budowlane bez VAT",
  "results.accountSheet.details.taxCode.EUSt": "EUSt",
  "results.accountSheet.details.taxCode.igEMitVSt": "igE z VAT",
  "results.accountSheet.details.taxCode.igEOhneVst": "igE bez VAT",
  "results.accountSheet.details.taxCode.igLieferung": "dostawa ig",
  "results.accountSheet.details.taxCode.Kleinunternehmer": "Mała firma",
  "results.accountSheet.details.taxCode.RCMitVSt": "RC z VSt",
  "results.accountSheet.details.taxCode.RCOhneVSt": "RC bez VAT",
  "results.accountSheet.details.taxCode.sonstigeLeistungenEuZm": "Inne usługi UE (z zastrzeżeniem ZM)",
  "results.accountSheet.details.taxCode.TeilabzugsfaehigeVSt": "VAT podlegający częściowemu odliczeniu",
  "results.accountSheet.details.taxCode.Ust": "VAT",
  "results.accountSheet.details.taxCode.VSt": "VSt",
  "results.accountSheet.details.taxPercent": "Procent podatku",
  "results.accountSheet.details.vatPeriod": "Okres UVA",
  "results.accountSheet.documents.downloadAll": "Pobierz wszystkie pliki",
  "results.accountSheet.filters.amount": "Kwota",
  "results.accountSheet.filters.documentDate": "Data dokumentu",
  "results.accountSheet.filters.postingSymbol": "Symbol rezerwacji",
  "results.accountSheet.noResults.message": "Nie znaleziono żadnych pozycji dla wybranego konta",
  "results.accountSheet.noResults.open.message": "Nie znaleziono otwartych pozycji dla wybranego konta",
  "results.accountSheet.noResults.open.title": "Nie znaleziono otwartych elementów",
  "results.accountSheet.noResults.title": "Nie znaleziono żadnych postów",
  "results.accountSheet.offsettingAccounts.more": "{count} więcej",
  "results.accountSheet.paid": "opłacony",
  "results.accountSheet.search.count": "Rezerwacje: <b>{liczba}</b>",
  "results.accountSheet.search.placeholder": "Wyszukiwanie tekstu rezerwacji...",
  "results.accountSheet.sum.title": "Σ Razem",
  "results.accountSheet.tabs.all": "Wszystkie przedmioty",
  "results.accountSheet.tabs.open": "Tylko otwarte przedmioty",
  "results.accountSheet.title": "Arkusz konta dla konta {accountNr} \"{name}\"",
  "results.columns.changeAbsolute": "Abw.",
  "results.columns.changePercentage": "odchylenie (%)",
  "results.columns.credit": "Mieć",
  "results.columns.debit": "Cel",
  "results.common.accounts": "Konta",
  "results.common.quickExpand.expandAccounts": "Wszystkie (w tym konta)",
  "results.common.quickExpand.expandNthDepth": "Poziom {głębokość}",
  "results.common.quickExpand.expandRoot": "Główny poziom podziału",
  "results.common.quickExpand.expandRows": "Rozkładane linie:",
  "results.disclaimer": "Ważna uwaga",
  "results.disclaimerMessage": "W przypadku okresów, za które roczne sprawozdania finansowe nie zostały jeszcze sfinalizowane, przedstawione analizy opierają się na danych z aktualnego wykazu sald na dany dzień sprawozdawczy. Analizy zostały przygotowane na podstawie dostarczonych nam dokumentów bez ich istotnego przeglądu i dostarczają klientowi dodatkowych informacji na temat bieżącego rozwoju działalności w odpowiednim czasie. W szczególności, rozliczenia międzyokresowe, zmiany stanu rezerw, zmiany stanu zapasów, wycena należności oraz amortyzacja zostały uwzględnione tylko w latach obrotowych, za które roczne sprawozdania finansowe nie zostały jeszcze zakończone, w zakresie, w jakim zostało to uzgodnione z klientem. Powoduje to różnice pomiędzy przedstawionymi analizami a ostatecznym rocznym sprawozdaniem finansowym.\n\nW związku z tym nie ponosimy odpowiedzialności za dokładność i kompletność analiz wobec klienta lub osób trzecich. We wszystkich innych aspektach do naszych zadań i obowiązków, w tym w odniesieniu do osób trzecich, mają zastosowanie Ogólne warunki umów dla zawodów księgowych (AAB).",
  "results.navbar.buttons.accountListing": "Lista sald",
  "results.navbar.buttons.balanceSheet": "Bilans",
  "results.navbar.buttons.cashAccounting": "Rachunek dochodów i wydatków",
  "results.navbar.buttons.customerListing": "Lista dłużników",
  "results.navbar.buttons.payrollAccount": "Konto listy płac",
  "results.navbar.buttons.profitAndLoss": "G&V",
  "results.navbar.buttons.vendorListing": "Lista zobowiązań",
  "results.noFinancialAccountancies.message": "Obecnie nie są dostępne żadne oceny.",
  "results.noFinancialAccountancies.title": "Brak ocen",
  "results.payrollAccountSheet.dataTable.month": "miesiąc",
  "results.payrollAccountSheet.dataTable.number": "Nie.",
  "results.payrollAccountSheet.dataTable.payrollType": "Rodzaj wynagrodzenia",
  "results.payrollAccountSheet.dataTable.total": "Łącznie",
  "results.payrollAccountSheet.dataTable.totalPreviousYear": "Łącznie w poprzednim roku",
  "results.payrollAccountSheet.noPermission.message": "Nie masz uprawnień do przeglądania konta listy płac.",
  "results.payrollAccountSheet.noPermission.title": "Brak zezwolenia",
  "results.payrollAccountSheet.noResults.details": "To konto listy płac nie zawiera żadnych szczegółów",
  "results.payrollAccountSheet.noResultsYear.message": "Nie znaleziono danych dla wybranego roku.",
  "results.payrollAccountSheet.noResultsYear.title": "Nie znaleziono danych",
  "results.settings.accountsVisibility.hideAccounts": "Ukryj konta",
  "results.settings.accountsVisibility.showAccounts": "Pokaż konta",
  "results.settings.orderBy.accountNameAsc": "Nazwa konta rosnąco",
  "results.settings.orderBy.accountNameDesc": "Nazwa konta malejąco",
  "results.settings.orderBy.accountNrAsc": "Rosnący numer konta",
  "results.settings.orderBy.accountNrDesc": "Malejący numer konta",
  "results.settings.orderBy.amountAsc": "Kwota rosnąco",
  "results.settings.orderBy.amountDesc": "Kwota malejąco",
  "results.settings.orderBy.title": "Sortuj według: {orderBy}",
  "results.settings.previousYearRanges.fullYear": "Pokaż sumę z poprzedniego roku",
  "results.settings.previousYearRanges.partialYear": "Uwzględnia poprzedni rok tylko do {previousYearRangeUntil}.",
  "results.settings.variant.compactView": "Widok kompaktowy",
  "results.settings.variant.currentAndPastYear": "Aktualności i poprzedni rok",
  "results.settings.variant.currentAndPastYearAbsoluteChange": "Bieżący i poprzedni rok ze zmianą bezwzględną",
  "results.settings.variant.currentAndPastYearPercentageChange": "Bieżący i poprzedni rok ze zmianą procentową",
  "results.settings.variant.currentYear": "Bieżący rok",
  "results.settings.variant.detailView": "Widok szczegółowy",
  "results.title": "Oceny",
  "role.accountingAssistent": "Asystent ds. księgowości",
  "role.accountingAssistent.description": "Dostęp do rachunkowości bez praw do zwolnienia",
  "role.accountingManagement": "Zarządzanie finansami",
  "role.accountingManagement.description": "Dostęp do rachunkowości, w tym prawa do zwolnienia",
  "role.admin": "Administracja / Zarządzanie",
  "role.admin.description": "Wszystkie prawa dostępu do wszystkich obszarów",
  "role.assistent": "Pomoc",
  "role.assistent.description": "Dostęp do księgowości i płac bez uprawnień do zwolnienia{br}Możliwość sugerowania zmiany użytkowników",
  "role.custom": "Własna",
  "role.custom.description": "Prawa dostępu definiowane przez użytkownika",
  "role.custom.jobTitle": "Pracownicy",
  "role.custom.jobTitle.female": "Personel",
  "role.hrAssistent": "Asystent ds. płac",
  "role.hrAssistent.description": "Dostęp do księgowości płacowej bez uprawnień do zwolnienia",
  "role.hrManagement": "Zarządzanie zasobami ludzkimi",
  "role.hrManagement.description": "Dostęp do rachunkowości płacowej, w tym prawa do zwolnienia",
  "role.internationalProjects": "Personel projektu",
  "role.internationalProjects.female": "Personel projektu",
  "role.legalAdvisor": "Radca prawny:w",
  "role.noRole": "brak części",
  "role.noRole.description": "Brak praw dostępu do TPA Connect",
  "role.staff": "Pracownik",
  "role.staff.description": "Otrzymuje elektroniczne zestawienia zarobków",
  "role.staff.female": "Pracownik",
  "role.superAdmin": "Superadmin",
  "role.superAdmin.description": "Dostęp do wszystkich obszarów, w tym zarządzanie użytkownikami",
  "role.tpaAccounting": "Księgowość",
  "role.tpaAccounting.description": "Dostęp konsultanta do księgowości",
  "role.tpaAdvisor": "Doradca",
  "role.tpaAdvisor.description": "Dostęp konsultantów do wszystkich obszarów",
  "role.tpaAdvisor.female": "Doradca",
  "role.tpaAnnualAccounts": "Roczne sprawozdanie finansowe",
  "role.tpaAnnualAccounts.description": "Odpowiedzialny za roczne sprawozdania finansowe",
  "role.tpaAuditor": "Audyt",
  "role.tpaBusinessConsultant": "Doradztwo w zakresie zarządzania",
  "role.tpaBusinessConsultant.description": "Odpowiedzialny za doradztwo w zakresie zarządzania",
  "role.tpaChat": "Kontakt",
  "role.tpaChatSecret": "Kontakt",
  "role.tpaHr": "Lista płac",
  "role.tpaHr.description": "Dostęp konsultanta do księgowości płacowej",
  "role.tpaOther": "Personel projektu",
  "role.tpaOther.female": "Pracownik projektu",
  "role.tpaPartner": "Partner",
  "role.tpaPartner.description": "Dostęp konsultantów do wszystkich obszarów",
  "role.tpaPartner.female": "Partner",
  "role.tpaPayrollAccountant": "Lista płac",
  "role.tpaReporting": "Raportowanie",
  "role.tpaReporting.description": "Odpowiedzialny za raportowanie",
  "role.tpaSpecialAdvisor": "Konsultacje specjalne",
  "role.tpaSpecialAdvisor.description": "Odpowiedzialny za specjalne doradztwo",
  "role.tpaTaxAccountant": "Doradztwo podatkowe",
  "role.tpaTaxAccountant.description": "Odpowiedzialny za doradztwo podatkowe",
  "screen.accounting.navbar.bankConnections": "Dane bankowe",
  "screen.accounting.navbar.heading": "Księgowość",
  "screen.accounting.navbar.overview": "Przegląd",
  "screen.accounting.navbar.records": "Wpływy",
  "screen.accounting.overview.bankConnection": "{salutacja}, {br} {br} Jeden lub więcej z Państwa rachunków bankowych wygaśnie w tym okresie rozliczeniowym. Prosimy o ich odnowienie.{br} {br} Z poważaniem{br} {podpis}",
  "screen.accounting.overview.bankConnection.expired": "{salutacja}, {br} {br}Jedna lub więcej Pana/Pani danych bankowych straciło ważność. Prosimy o ich odnowienie.{br} {br} Z poważaniem{br} {podpis}",
  "screen.accounting.overview.bankConnection.expired.title": "Dane bankowe straciły ważność",
  "screen.accounting.overview.bankConnections.refreshBankConnections": "Odnowienie rachunków bankowych",
  "screen.accounting.overview.bankConnection.title": "Rachunki bankowe tracą ważność",
  "screen.accounting.overview.card.analysis": "Raporty zwolnienia dla",
  "screen.accounting.overview.card.globalAnalysis": "Publikowanie raportów ogólnofirmowych",
  "screen.accounting.overview.card.overview": "Ogłoszenie księgowe dla",
  "screen.accounting.overview.finished.download": "Pobierz wszystkie pliki",
  "screen.accounting.overview.finished.message": "{salutation}, {br} {br}Dziękuję za sprawdzenie i zatwierdzenie rachunków. Poniżej zamieszczamy Państwa sprawozdania.{br} {br}Z poważaniem, {br}{signature}",
  "screen.accounting.overview.reports": "{salutacja}, {br} {br}W załączeniu przesyłam następujące dokumenty za {okres}. Dokumenty zostały przygotowane na podstawie dostarczonych przez Państwa informacji oraz przedłożonych dokumentów. W przypadku stwierdzenia jakichkolwiek nieścisłości lub niekompletności w dokumentach, prosimy o poinformowanie nas o tym, abyśmy mogli wprowadzić odpowiednie poprawki. {br} {br}Jeśli mają Państwo jakiekolwiek pytania, prosimy o kontakt.{br} {br}Z poważaniem, {br}{signature}",
  "screen.accounting.overview.ticketsWelcome.inProgress": "{salutation}, {br} {br}Dziękuję za przekazanie bonów towarowych. W trakcie prowadzenia księgowości pojawiły się pewne pytania:",
  "screen.accounting.overview.welcome.closed": "{salutation}, {br} {br}Dziękuję za złożenie wszystkich istotnych dokumentów potwierdzających. Opracujemy je zgodnie z ustalonymi terminami i skontaktujemy się z Państwem w przypadku jakichkolwiek pytań.",
  "screen.accounting.overview.welcome.inProgress": "{{br} {br}Prosimy o przesłanie dokumentów rezerwacyjnych za {period}{dueDate}. Prosimy o przesłanie nam wszystkich niezbędnych dokumentów. {br} {br}Pozdrawiam, {br}{podpis}",
  "screen.accounting.records.banner.selectedAll": "Wybierane są wszystkie {count} pliki w \"{recordType}\".",
  "screen.accounting.records.banner.selectedAll.transferFailed": "Wybierane są wszystkie {count} nieudane pliki w \"{recordType}\".",
  "screen.accounting.records.banner.selectedCount": "Wszystkie {count} pliki na tej stronie są zaznaczone",
  "screen.accounting.records.banner.totalCount": "Wybierz wszystkie {count} pliki w \"{recordType}\".",
  "screen.accounting.records.banner.totalCount.transferFailed": "Wybierz wszystkie {count} nieudanych plików w \"{recordType}\".",
  "screen.accounting.records.banner.unselect": "Anuluj wybór",
  "screen.accounting.records.documents": "Pliki:",
  "screen.accounting.records.empty": "Plik jest pusty",
  "screen.accounting.records.filter.transferFailed": "Transfer nie powiódł się",
  "screen.accounting.records.format.error": "Sprawdź format",
  "screen.accounting.records.move.dialog.button.text": "Przesuń",
  "screen.accounting.records.move.multiple.dialog.title": "Przenoszenie dokumentów",
  "screen.accounting.records.move.single.dialog.title": "Przenieś dokument",
  "screen.accounting.records.navbar.tab.list": "Wpływy ({liczba})",
  "screen.accounting.records.navbar.tab.tickets": "Zapytania ({count})",
  "screen.accounting.records.person.responsible": "Odpowiedzialność",
  "screen.accounting.records.serverUpload.error": "Plik nie mógł zostać przesłany na serwer",
  "screen.accounting.records.serverUpload.typeError": "Format niedopuszczalny",
  "screen.accounting.records.sizeAndFormat.error": "Sprawdź rozmiar i format pliku",
  "screen.accounting.records.size.error": "Sprawdź rozmiar pliku",
  "screen.accounting.records.tickets": "| Zapytania: {numTickets}",
  "screen.accounting.records.transfer.button.text": "Przekazać do TPA",
  "screen.accounting.records.transfer.dialog.text": "Czy chcesz przenieść <b>wszystkie pliki księgowe</b> za okres {periodStart} - {periodEnd} do TPA w celu zaksięgowania?",
  "screen.accounting.records.transfer.dialog.textRecordType": "Czy chcesz przenieść <b>wszystkie pliki \"{name}\"</b> za okres {periodStart} - {periodEnd} do TPA w celu zaksięgowania?",
  "screen.accounting.records.transfer.dialog.title": "Księgowanie transmisji",
  "screen.accounting.records.transfer.retryDialog.button.text": "Spróbuj jeszcze raz.",
  "screen.accounting.records.transfer.retryDialog.file": "Błąd w transmisji {failedFile}",
  "screen.accounting.records.transfer.retryDialog.text": "Ups. Coś tu poszło nie tak. Proszę spróbować ponownie.",
  "screen.accounting.records.transfer.retryDialog.title": "Awaria skrzyni biegów",
  "screen.accounting.records.transfer.successDialog.text": "Państwa doradca TPA dokona teraz przeglądu danych i skontaktuje się z Państwem w przypadku jakichkolwiek pytań.",
  "screen.accounting.records.transfer.successDialog.title": "Dane zostały pomyślnie przesłane",
  "screen.accounting.records.types.header": "Księgowość",
  "screen.accounting.records.unknown.error": "Nieznany błąd",
  "screen.accounting.records.upload.button": "Prześlij paragony",
  "screen.accounting.records.upload.navbar.back": "Księgowość / Vouchery",
  "screen.accounting.recordTypes.title": "Wybierz rodzaj bonów",
  "screen.advisor.tabbar.active": "Aktywny",
  "screen.advisor.tabbar.notConfigured": "Nie skonfigurowany",
  "screen.advisor.upload.approve.info": "Kiedy potrzebuję podpisu kwalifikowanego?",
  "screen.advisor.uploads.upload.approve": "Wydanie dla klienta",
  "screen.advisor.uploads.upload.approve.option.click": "Wydanie za kliknięcie",
  "screen.advisor.uploads.upload.approve.option.none": "Brak zatwierdzenia przez klienta",
  "screen.advisor.uploads.upload.approve.option.qes": "Zwolnienie przy pomocy podpisu elektronicznego",
  "screen.advisor.uploads.upload.buttonText": "Transmituj",
  "screen.advisor.uploads.upload.category": "Kategoria",
  "screen.advisor.uploads.upload.category.validation": "Proszę wybrać kategorię",
  "screen.advisor.uploads.upload.comment": "Tekst transmisji",
  "screen.advisor.uploads.upload.comment.downloadError": "Błąd ładowania komentarza",
  "screen.advisor.uploads.upload.dueDate": "W dniu",
  "screen.advisor.uploads.upload.dueDate.validation": "Proszę podać termin płatności",
  "screen.advisor.uploads.upload.global": "W całej firmie",
  "screen.advisor.uploads.upload.module": "Obszar",
  "screen.advisor.uploads.upload.module.validation": "Proszę wybrać obszar",
  "screen.advisor.uploads.upload.navBack": "Przesyłki",
  "screen.advisor.uploads.upload.navHeader": "Prześlij pliki: {kategoria}",
  "screen.advisor.uploads.upload.notifyCustomer": "Powiadomić klientów",
  "screen.advisor.uploads.upload.subsidiary.validation": "Proszę wybrać kod firmy",
  "screen.advisor.uploads.upload.visibleToEmployee": "Zwolnienie dla MA",
  "screen.auth.forgot_password_confirm.subtitle.section_1": "Jeśli znaleźliśmy użytkownika o adresie e-mail {email}, wkrótce otrzymasz e-mail z linkiem do zresetowania hasła.",
  "screen.auth.forgot_password_confirm.subtitle.section_2": "Nie dostałeś żadnej poczty? Sprawdź folder spamu lub skontaktuj się z administratorem systemu.",
  "screen.auth.forgot_password_confirm.title": "Wkrótce otrzymasz wiadomość e-mail",
  "screen.auth.forgot_password_email.form.button": "Wyślij link do zresetowania",
  "screen.auth.forgot_password_email.form.label.email": "Identyfikator użytkownika / Adres e-mail",
  "screen.auth.forgot_password_email.subtitle": "Wpisz swój adres e-mail, a my wyślemy Ci wiadomość z linkiem do zresetowania hasła.",
  "screen.auth.forgot_password_email.title": "Resetowanie hasła",
  "screen.auth.forgot_password_email.validation_error": "Proszę wpisać swój adres e-mail",
  "screen.auth.forgot_password_reset.error.password_not_set": "Proszę wprowadzić hasło",
  "screen.auth.forgot_password_reset.error.passwords_do_not_match": "Hasła nie są zgodne",
  "screen.auth.forgot_password_reset.error.password_weak": "Twoje hasło jest nadal za słabe.",
  "screen.auth.forgot_password_reset.form.button": "Zmiana hasła",
  "screen.auth.forgot_password_reset.form.button.mfa": "Wyślij kod",
  "screen.auth.forgot_password_reset.form.button.mfa.resend_code": "Wyślij ponownie kod",
  "screen.auth.forgot_password_reset.form.mfa": "Kod",
  "screen.auth.forgot_password_reset.form.password": "Hasło",
  "screen.auth.forgot_password_reset.form.repeat_password": "Powtórzenie hasła",
  "screen.auth.forgot_password_reset.subtitle": "Ustaw nowe hasło dla swojego konta TPA Connect.",
  "screen.auth.forgot_password_reset.subtitle.mfa": "Wpisz kod, który wysłaliśmy na Twój numer telefonu SMS-em.",
  "screen.auth.forgot_password_reset.title": "Zmiana hasła",
  "screen.auth.forgot_password_reset.title.mfa": "Potwierdź zmianę hasła za pomocą uwierzytelniania dwuskładnikowego",
  "screen.auth.registration.form.button": "Rejestr",
  "screen.auth.registration.form.button.mfa": "Wyślij kod",
  "screen.auth.registration.form.button.mfa.resendCode": "Wyślij ponownie kod",
  "screen.auth.registration.form.label.mfa": "Kod",
  "screen.auth.registration.form.label.password": "Hasło",
  "screen.auth.registration.form.label.repeat_password": "Powtórzenie hasła",
  "screen.auth.registration.form.section_1": "Użyj co najmniej 8 znaków.",
  "screen.auth.registration.form.section_2": "Możesz użyć wielkich i małych liter, cyfr i znaków specjalnych, aby wzmocnić swoje hasło.",
  "screen.auth.registration.form.section_3": "Pomyśl o mnemotechnice, która pomoże Ci zapamiętać Twoje hasło.",
  "screen.auth.registration.mfa.subtitle": "Wpisz kod, który wysłaliśmy na Twój numer telefonu SMS-em.",
  "screen.auth.registration.subtitle": "Ustaw hasło dla swojego konta TPA Connect.",
  "screen.auth.registration.title": "Ustawianie i rejestracja hasła",
  "screen.auth.welcome.subtitle": "Połącz swoje konto TPA Connect z kontem Microsoft, aby w pełni korzystać ze wszystkich funkcji. Możesz również połączyć swoje konto w późniejszym terminie.",
  "screen.auth.welcome.subtitle.msOnly": "Połącz swoje konto TPA Connect z kontem Microsoft, aby w pełni korzystać ze wszystkich funkcji.",
  "screen.auth.welcome.title": "Witamy w TPA Connect!",
  "screen.changeUsername.checkbox": "Niniejszym potwierdzam mój nowy adres e-mail",
  "screen.changeUsername.message": "Komputerowe serce aplikacji TPA Connect zauważyło, że Twój adres e-mail zmienił się na <b>{newUsername}</b>. Abyśmy mieli absolutną pewność, że zmiana ta jest prawidłowa, koniecznie należy potwierdzić ją jednym kliknięciem.",
  "screen.changeUsername.title": "Nowy adres e-mail",
  "screen.cockpit.allEvents": "Wydarzenia: Wszystkie wydarzenia",
  "screen.cockpit.allNews": "Aktualności: Wszystkie artykuły",
  "screen.cockpit.dueDates.export": "Terminy wywozu",
  "screen.cockpit.dueDates.navbar.heading": "Terminy otwarte",
  "screen.cockpit.dueDates.projects.text": "Zwolnienie dla {documentName}",
  "screen.cockpit.dueDates.records.accounting.text": "Ujawnienie informacji rachunkowych dla {nazwa spółki zależnej} - {okres}",
  "screen.cockpit.dueDates.records.hr.text": "Ujawnienie danych o wynagrodzeniach dla {subsidiaryName} - {period}",
  "screen.cockpit.dueDates.reports.global.text": "Wydanie raportu dla \"raportów ogólnozakładowych\" - {okres}",
  "screen.cockpit.dueDates.reports.subsidiary.text": "Publikacja raportu dla {subsidiaryName} - {period}",
  "screen.cockpit.dueDates.reportUpload.bmd.message": "Proszę użyć BMD NTCS do dostarczenia raportów",
  "screen.cockpit.dueDates.reportUpload.bmd.title": "Przesyłanie raportu",
  "screen.cockpit.dueDates.reportUpload.global.text": "Wgranie raportu dla \"raportów ogólnozakładowych\" - {okres}",
  "screen.cockpit.dueDates.reportUpload.subsidiary.text": "Reportupload dla {subsidiaryName} - {period}",
  "screen.cockpit.dueDates.tickets.global.text": "Termin zapoznania się z dokumentem {documentName}",
  "screen.cockpit.dueDates.tickets.subsidiary.text": "Termin na zapoznanie się z dokumentem {documentName} dla {subsidiaryName}",
  "screen.cockpit.emptyState.message": "Wróć później",
  "screen.cockpit.emptyState.title": "W tej chwili nie ma żadnych wiadomości",
  "screen.cockpit.eventDetail": "Szczegóły wydarzenia",
  "screen.cockpit.eventDetails.heading": "Szczegóły i rejestracja",
  "screen.cockpit.eventDetails.lecturer.email": "E: {email}",
  "screen.cockpit.eventDetails.lecturers": "Prezenter",
  "screen.cockpit.eventDetails.lecturer.telephone": "T: {telefon}",
  "screen.cockpit.eventDetails.register": "Zarejestruj się teraz",
  "screen.cockpit.event.moreInformation": "INFORMACJE I REJESTRACJA",
  "screen.cockpit.kpis.add": "Dodaj kluczową postać",
  "screen.cockpit.kpis.chart.legend": "w € 1000",
  "screen.cockpit.kpis.configurationDialog.insuranceCarrierBalance.label": "Ubezpieczenie zdrowotne",
  "screen.cockpit.kpis.configurationDialog.metric.label": "KPI",
  "screen.cockpit.kpis.configurationDialog.months.label": "miesiące",
  "screen.cockpit.kpis.configurationDialog.title": "Konfiguracja widżetu KPI",
  "screen.cockpit.kpis.ebit.chart.name": "Rozwój EBIT",
  "screen.cockpit.kpis.ebit.kpi.name": "Dotychczasowy EBIT",
  "screen.cockpit.kpis.ebit.kpi.tooltip": "Pokazuje wartość EBIT wszystkich miesięcy już w pełni zaksięgowanych od początku roku obrotowego.",
  "screen.cockpit.kpis.empty": "Twój kod firmy \"{nazwa}\" jest obecnie niedostępny.",
  "screen.cockpit.kpis.insuranceCarrier.balance.kpi.name": "Bilans ubezpieczenia zdrowotnego",
  "screen.cockpit.kpis.noneConfigured": "Dodaj kluczową postać",
  "screen.cockpit.kpis.revenue.chart.name": "Tendencja obrotów",
  "screen.cockpit.kpis.revenue.kpi.name": "Dotychczasowe obroty",
  "screen.cockpit.kpis.revenue.kpi.tooltip": "Pokazuje obrót ze wszystkich już w pełni zarezerwowanych miesięcy od początku roku obrotowego.",
  "screen.cockpit.kpis.save": "Zastosuj zmiany",
  "screen.cockpit.kpis.svsAccount.kpi.info": "",
  "screen.cockpit.kpis.svsAccount.kpi.label": "",
  "screen.cockpit.kpis.svsAccount.kpi.name": "",
  "screen.cockpit.kpis.taxAccount.kpi.arrears": "Backlog",
  "screen.cockpit.kpis.taxAccount.kpi.credit": "Nota kredytowa",
  "screen.cockpit.kpis.taxAccount.kpi.label": "Stan na dzień {data}",
  "screen.cockpit.kpis.taxAccount.kpi.lastCompletedPayment": "[kwota] ostatnio zapłacona w dniu {data}",
  "screen.cockpit.kpis.taxAccount.kpi.name": "Bilansowy rachunek podatkowy",
  "screen.cockpit.kpis.unpaidAccount.kpi.lastCompletedPayment": "Już zapłacone w dniu {data}",
  "screen.cockpit.kpis.unpaidAccounts.kpi.caption": "{invoicesCount} Faktury",
  "screen.cockpit.kpis.unpaidAccounts.kpi.label": "Stan na dzień {data}",
  "screen.cockpit.kpis.unpaidAccounts.kpi.name": "Zaległe faktury w TPA",
  "screen.cockpit.navbar.heading": "Kokpit",
  "screen.cockpit.overview.kpis.title": "Fakty i liczby",
  "screen.cockpit.overview.newestPublications": "Ostatnie publikacje",
  "screen.cockpit.overview.notifications.dueDates": "Terminy otwarte",
  "screen.cockpit.overview.notifications.messages": "Wiadomości",
  "screen.cockpit.overview.notifications.tickets": "Pytania",
  "screen.cockpit.overview.notifications.title": "Powiadomienia",
  "screen.cockpit.overview.promotions.title": "Wiadomości",
  "screen.cockpit.overview.relevantNews.title": "Istotne wiadomości",
  "screen.cockpit.overview.relevantPublications.description": "Mogą Państwo zamówić drukowaną publikację TPA, która zostanie przesłana w ciągu kilku dni. Lub: Mogą Państwo pobrać wersję elektroniczną (PDF) żądanej publikacji TPA.",
  "screen.cockpit.overview.relevantPublications.title": "Istotne publikacje",
  "screen.cockpit.promotion": "Wiadomości: Artykuł",
  "screen.cockpit.promotion.additionalNews": "Wiadomości",
  "screen.cockpit.relevantNews": "Aktualności: Artykuł",
  "screen.cockpit.relevantNews.additionalNews": "Więcej wiadomości",
  "screen.cockpit.tickets.navbar.heading": "Zapytania otwarte",
  "screen.hr.additionaldata.addBonus": "Dodaj kolejny dodatek",
  "screen.hr.additionaldata.additionaldata": "Informacje uzupełniające",
  "screen.hr.additionaldata.additionalInformation": "Dalsze informacje",
  "screen.hr.additionaldata.amount": "Kwota w {waluta} na miesiąc",
  "screen.hr.additionaldata.amount_error": "Proszę wpisać kwotę",
  "screen.hr.additionaldata.bankDetails": "Dane bankowe",
  "screen.hr.additionaldata.bic": "BIC",
  "screen.hr.additionaldata.bic.validation": "Nieważny BIC",
  "screen.hr.additionaldata.commuterAllowance": "Dodatek na dojazdy do pracy",
  "screen.hr.additionaldata.costCenter": "Centrum kosztów",
  "screen.hr.additionaldata.costCenterNote": "Uwaga dotycząca centrum kosztów",
  "screen.hr.additionaldata.description": "Poniższe informacje można również wypełnić w momencie rozpoczęcia świadczenia usługi, jeżeli ma to zastosowanie.",
  "screen.hr.additionaldata.designator": "Zasiłek",
  "screen.hr.additionaldata.designator_error": "Proszę podać wysokość dodatku",
  "screen.hr.additionaldata.emailAddress": "Adres e-mail",
  "screen.hr.additionaldata.email_error": "Proszę wpisać poprawny adres e-mail",
  "screen.hr.additionaldata.familyBonusPlusAmount": "Fabo Plus Kwota w EUR",
  "screen.hr.additionaldata.familyBonusPlusAmount_error": "Wprowadź co najmniej 0",
  "screen.hr.additionaldata.funder": "Jednostka kosztowa",
  "screen.hr.additionaldata.gross": "Brutto",
  "screen.hr.additionaldata.hours_error": "Proszę wpisać liczbę z przedziału od {minHours} do {maxHours}.",
  "screen.hr.additionaldata.iban": "IBAN",
  "screen.hr.additionaldata.iban_error": "Nieprawidłowy IBAN",
  "screen.hr.additionaldata.jobDescription": "Stanowisko",
  "screen.hr.additionaldata.kilometersForCommuterEuro": "Kilometry dla euro na dojazdy do pracy",
  "screen.hr.additionaldata.kilometersForCommuterEuro_error": "Wprowadź co najmniej 0",
  "screen.hr.additionaldata.kilometersForCommuterEuro_typeerror": "Kilometry mogą być podawane wyłącznie w liczbach całkowitych",
  "screen.hr.additionaldata.maritalStatus": "Stan cywilny",
  "screen.hr.additionaldata.monthlySalary": "Wynagrodzenie w {waluta} na miesiąc",
  "screen.hr.additionaldata.monthlySalary_error": "Wprowadź co najmniej 0",
  "screen.hr.additionaldata.net": "Netto",
  "screen.hr.additionaldata.nextPage": "Przejdź do dokumentów",
  "screen.hr.additionaldata.number_error": "Proszę wpisać numer",
  "screen.hr.additionaldata.per": "na",
  "screen.hr.additionaldata.personaldata": "Dane osobowe",
  "screen.hr.additionaldata.religiousConfession": "Wyznanie religijne",
  "screen.hr.additionaldata.salarayAndPosition": "Wynagrodzenie i stanowisko",
  "screen.hr.additionaldata.saveChanges": "Zapisz zmiany",
  "screen.hr.additionaldata.soleEarnerStatus": "Jedyny zarabiający",
  "screen.hr.additionaldata.titelAndAcademicDegrees": "Tytuły i stopnie naukowe",
  "screen.hr.additionaldata.titlePrefix": "Przedrostek",
  "screen.hr.additionaldata.titleSuffix": "Poniżej",
  "screen.hr.additionaldata.wageAgreementClassification": "Klasyfikacja układ zbiorowy pracy",
  "screen.hr.additionaldata.weeklyWorkDays": "Liczba dni roboczych w tygodniu",
  "screen.hr.additionaldata.weeklyWorkDays_error": "Wybierz prawidłową wartość",
  "screen.hr.additionaldata.workingHours": "Liczba godzin",
  "screen.hr.additionaldata.workingHoursInterval": "Okres",
  "screen.hr.additionaldata.workingTimes": "Godziny pracy",
  "screen.hr.cancelRegistration.dataSaved": "W przypadku anulowania wprowadzone dane zostaną zachowane. Rejestracja może być kontynuowana w późniejszym terminie.",
  "screen.hr.cancelRegistration.header": "Anuluj rejestrację",
  "screen.hr.cancelRegistration.question1": "Czy na pewno chcesz anulować proces rejestracji? Dotychczas wprowadzone dane można wykorzystać do",
  "screen.hr.cancelRegistration.question2": " i złożyć go u swojego doradcy podatkowego TPA w celu rejestracji.",
  "screen.hr.deregistration.data.navback": "Personel / Pracownicy / Zarejestrowani / {firstname} {lastname}",
  "screen.hr.deregistration.documents.navback": "Personel / Pracownicy / Zarejestrowani / {imię} {nazwisko} {pseudonim} / Dane dotyczące wyrejestrowania",
  "screen.hr.deregistration.navheader": "{firstname} {lastname} log out",
  "screen.hr.deregistration.validation.deregistrationDate": "Proszę wpisać datę wyjazdu",
  "screen.hr.deregistration.validation.deregistrationDateMin": "Data wyjścia musi być większa niż data wejścia",
  "screen.hr.deregistration.validation.deregistrationReason": "Proszę podać powód odejścia",
  "screen.hr.deregistration.validation.remainingHolidays": "Proszę wskazać pozostały urlop",
  "screen.hr.deregistration.validation.remainingOvertime": "Proszę wskazać wolne godziny nadliczbowe",
  "screen.hr.documents.citizenship": "Zaświadczenie o obywatelstwie",
  "screen.hr.documents.contract": "Umowa o świadczenie usług",
  "screen.hr.documents.description": "Załaduj dodatkowe dokumenty, takie jak: umowa o pracę, dowód osobisty, potwierdzenie obywatelstwa, formularz rejestracyjny lub inne dokumenty",
  "screen.hr.documents.emptystate.buttonlabel": "Prześlij dokumenty",
  "screen.hr.documents.emptystate.message": "Dla tego pracownika nie są dostępne żadne dokumenty",
  "screen.hr.documents.emptystate.title": "Brak dostępnych dokumentów",
  "screen.hr.documents.enterManually": "Ręczne wprowadzanie danych",
  "screen.hr.documents.idcard": "Dowód osobisty",
  "screen.hr.documents.logTime": "Rekordowe czasy",
  "screen.hr.documents.needsRelease.notReleased": "Czekając na wydanie",
  "screen.hr.documents.needsRelease.released": "wydany",
  "screen.hr.documents.needsRelease.released.tooltip": "wydany w dniu {releasedAt} przez {name}",
  "screen.hr.documents.nextButton": "Do podsumowania",
  "screen.hr.documents.nonworkingtimes.emptystate.message": "Rozpocznij przesyłanie plików teraz lub wprowadź czasy niewykonania ręcznie.",
  "screen.hr.documents.others": "Inne",
  "screen.hr.documents.registrationslip": "Wiadomośćsheet",
  "screen.hr.documents.uploadDocuments": "Prześlij dokumenty",
  "screen.hr.edit.flow.navback": "Personel / Pracownicy / TODO: Status / {firstName} {lastName}",
  "screen.hr.edit.flow.navheader": "{firstName} {lastName} edit",
  "screen.hr.employee.current.all": "Wszystkie",
  "screen.hr.employee.current.anyDateOfJoining": "Każda data wejścia",
  "screen.hr.employee.current.anyDateOfJoiningExiting": "Każda data wejścia/wyjścia",
  "screen.hr.employee.current.cancellation": "Wyrejestrowania",
  "screen.hr.employee.current.changeUsername": "Nowy e-mail",
  "screen.hr.employee.current.changeUsername.successDialog.message": "<b>{userName}</b> otrzymuje prośbę o potwierdzenie nowo podanego adresu e-mail.",
  "screen.hr.employee.current.changeUsername.successDialog.title": "Wiadomość e-mail dotycząca zmiany adresu e-mail została wysłana pomyślnie.",
  "screen.hr.employee.current.changeUsername.tooltip": "Nowy adres e-mail pracownika to {newUsername}. Po kliknięciu przycisku pracownik zostanie poproszony o potwierdzenie nowego adresu e-mail.",
  "screen.hr.employee.current.dataChange": "Zmiany danych głównych",
  "screen.hr.employee.current.notTransferred": "Nie przekazano",
  "screen.hr.employee.current.registration": "Rejestracje",
  "screen.hr.employee.deregistration.data.date": "Data wyjścia",
  "screen.hr.employee.deregistration.data.header": "Dane dotyczące wylogowania",
  "screen.hr.employee.deregistration.data.reason": "Powód odejścia",
  "screen.hr.employee.deregistration.data.remainingHolidays": "Pozostały urlop w dniach",
  "screen.hr.employee.deregistration.data.remainingOvertime": "Otwarte nadgodziny",
  "screen.hr.employee.deregistration.data.subtitle": "Jeśli są one dostępne, prześlij dokumenty wyjścia w celu ostatecznego rozliczenia.",
  "screen.hr.employee.deregistration.documents.submitButton": "Wyrejestrowanie przekazu",
  "screen.hr.employee.deregistration.stepper.data": "Dane dotyczące wylogowania",
  "screen.hr.employee.deregistration.stepper.documents": "Dokumenty wyjścia",
  "screen.hr.employee.details.addFiles": "Dodaj dokumenty",
  "screen.hr.employee.details.currentEmployment": "Aktualny stosunek pracy",
  "screen.hr.employee.details.deregisterEmployee": "Wyrejestrowanie pracownika",
  "screen.hr.employee.details.employments": "Stosunki pracy",
  "screen.hr.employee.details.fileProvidedBy": "dostarczony przez {nazwa}",
  "screen.hr.employee.details.fileReleased": "Widoczny dla pracowników",
  "screen.hr.employee.details.linkedFiles": "Pliki połączone",
  "screen.hr.employee.details.navBack": "Personel / {list}",
  "screen.hr.employee.details.pastEmployments": "Poprzednie zatrudnienie",
  "screen.hr.employee.details.payrollAccount.navBack": "Personel / Bieżące DN / {pełne imię i nazwisko}",
  "screen.hr.employee.details.personnelFile": "Akta osobowe",
  "screen.hr.employee.details.personnelNumber": "Numer personalny:",
  "screen.hr.employee.details.records": "Dowód zarobków",
  "screen.hr.employee.details.reregisterEmployee": "Ponowne zarejestrowanie pracownika",
  "screen.hr.employee.details.showAll": "Pokaż wszystko",
  "screen.hr.employee.details.showEmployeePayrollAccount": "Wyświetl konto listy płac",
  "screen.hr.employee.details.status": "Status",
  "screen.hr.employee.details.weeklyWorkDays": "{weeklyWorkDays} dni w tygodniu",
  "screen.hr.employee.details.workingHours": "{workingHours} godzin w {workingHoursInterval};",
  "screen.hr.employee.documents.all": "Wszystkie",
  "screen.hr.employee.documents.annualPayslips": "Roczny odcinek wynagrodzenia",
  "screen.hr.employee.documents.anyDate": "Dowolny okres",
  "screen.hr.employee.documents.backTargetDescription": "Personel / Pracownicy / {status} / {firstName} {lastName}",
  "screen.hr.employee.documents.certificateOfCitizenship": "Dowód obywatelstwa",
  "screen.hr.employee.documents.certificatesOfRegistration": "Potwierdzenia rejestracji",
  "screen.hr.employee.documents.contracts": "Umowa o świadczenie usług",
  "screen.hr.employee.documents.deregisterDocument": "Dokumenty dotyczące wyrejestrowania",
  "screen.hr.employee.documents.employeeDocuments": "Pliki od {firstName} {lastName}",
  "screen.hr.employee.documents.employmentPermit": "Pozwolenie na pracę",
  "screen.hr.employee.documents.generalDocuments": "Inne",
  "screen.hr.employee.documents.identityCard": "Dokument tożsamości",
  "screen.hr.employee.documents.payslips": "Dowód zarobków",
  "screen.hr.employee.emptystate.buttonlabel": "Rejestrować pracowników",
  "screen.hr.employee.emptystate.message": "Zacznij rejestrować nowych pracowników już teraz",
  "screen.hr.employee.emptystate.title": "Zarejestruj pracowników",
  "screen.hr.employee.former.anyDateOfLeaving": "Każda data wyjścia",
  "screen.hr.employee.registration.stepper.additional": "Informacje uzupełniające",
  "screen.hr.employee.registration.stepper.documents": "Prześlij dokumenty",
  "screen.hr.employee.registration.stepper.personal": "Dane osobowe",
  "screen.hr.employee.registration.stepper.status": "Krok {activeStep} z {totalSteps}.",
  "screen.hr.employee.registration.stepper.summary": "Streszczenie",
  "screen.hr.employees.current.registerEmployee": "Zarejestruj pracownika",
  "screen.hr.employees.current.uploadPayslip": "Prześlij odcinek wypłaty",
  "screen.hr.employees.documents.recordType.title": "Pliki przesłane do pliku personelu w {data}",
  "screen.hr.employees.documents.title": "Pliki w aktach osobowych",
  "screen.hr.navbar.documents": "Pliki",
  "screen.hr.navbar.employees": "Pracownicy",
  "screen.hr.navbar.heading": "Personel",
  "screen.hr.navbar.overview": "Przegląd",
  "screen.hr.navbar.tabbar.current": "Bieżący DN",
  "screen.hr.navbar.tabbar.documents": "Pliki",
  "screen.hr.navbar.tabbar.employees": "Pracownicy",
  "screen.hr.navbar.tabbar.former": "Emerytowany DN",
  "screen.hr.navbar.tabbar.inprogress": "W trakcie realizacji",
  "screen.hr.navbar.tabbar.preRegistartionTickets": "Zapytania ({preRegistrationTicketCount})",
  "screen.hr.overview.card.analysis": "Udostępnienie miesięcznych ocen dla",
  "screen.hr.overview.card.billing": "Udostępnianie miesięcznych zestawień dla",
  "screen.hr.overview.card.globalAnalysis": "Publikacja ocen ogólnofirmowych",
  "screen.hr.overview.card.globalBilling": "Ujawnienie rozliczeń w całej firmie",
  "screen.hr.overview.card.overview": "Ujawnienie miesięcznych danych osobowych dla",
  "screen.hr.overview.card.registration": "Rejestracja/wyrejestrowanie pracowników",
  "screen.hr.overview.finished.message": "{salutation}, {br} {br}Dziękuję za sprawdzenie i zatwierdzenie listy płac. Poniżej zamieszczamy Państwa sprawozdania.{br} {br}Z poważaniem, {br}{signature}",
  "screen.hr.overview.registration": "{salutation}, {br} {br}proszę powiadomić o wszelkich zmianach użytkowników usług, abyśmy mogli przeprowadzić rejestrację i wyrejestrowanie.{br} {br}Z poważaniem,{br} {signature}",
  "screen.hr.overview.registration.button": "Rejestracja/wyrejestrowanie pracowników",
  "screen.hr.overview.ticketsWelcome.inProgress": "{salutation}, {br} {br}Dziękuję za podanie danych osobowych. W trakcie przetwarzania danych pojawiły się pewne pytania:",
  "screen.hr.overview.welcome.closed": "{salutacja}, {br} {br}Dziękuję za przekazanie wszystkich istotnych danych kadrowych. Będziemy je przetwarzać zgodnie z ustalonymi terminami i kontaktować się z Państwem w przypadku jakichkolwiek pytań.",
  "screen.hr.overview.welcome.inProgress": "{salutation}, {br} {br}Proszę o dostarczenie mi wszystkich niezbędnych danych kadrowych dla listy płac {period}{dueDate}. {br}{br}Z poważaniem,{br}{podpis}",
  "screen.hr.personaldata.address": "Ulica i numer domu",
  "screen.hr.personaldata.addressAddition": "Przyrostek adresu",
  "screen.hr.personaldata.addressHead": "Adres",
  "screen.hr.personaldata.area.validation": "Proszę podać lokalizację",
  "screen.hr.personaldata.birthdate.validation": "Proszę wpisać datę urodzenia",
  "screen.hr.personaldata.citizenship": "Obywatelstwo",
  "screen.hr.personaldata.citizenship.validation": "Proszę podać obywatelstwo",
  "screen.hr.personaldata.city": "Lokalizacja",
  "screen.hr.personaldata.country": "Kraj",
  "screen.hr.personaldata.country.validation": "Proszę wybrać kraj",
  "screen.hr.personaldata.dateOfBirth": "Data urodzenia",
  "screen.hr.personaldata.dateOfJoining": "Data wejścia",
  "screen.hr.personaldata.description": "Poniższe informacje są obowiązkowe przy rejestracji przed rozpoczęciem świadczenia usług.",
  "screen.hr.personaldata.diverse": "Nurkowie",
  "screen.hr.personaldata.employmentInsuranceType": "Grupa ubezpieczeniowa",
  "screen.hr.personaldata.employmentRelationship": "Stosunek pracy",
  "screen.hr.personaldata.employmenttypeheader": "Rodzaj zatrudnienia",
  "screen.hr.personaldata.employmenttypeinformationbody": "Wszystkie miesięczne dochody brutto powyżej progu niskiego dochodu są uznawane za obowiązkowo w pełni ubezpieczone.",
  "screen.hr.personaldata.employmenttypeinformationhead": "W pełni ubezpieczony / marginalnie ubezpieczony:",
  "screen.hr.personaldata.employmenttype.validation": "Proszę wybrać stosunek pracy",
  "screen.hr.personaldata.entrydate.invalid": "Nieprawidłowa data wprowadzenia",
  "screen.hr.personaldata.entrydate.validation": "Proszę podać datę wejścia",
  "screen.hr.personaldata.female": "Kobieta",
  "screen.hr.personaldata.firstname": "Imię",
  "screen.hr.personaldata.firstname.validation": "Proszę wpisać imię",
  "screen.hr.personaldata.fullTime": "W pełni ubezpieczony",
  "screen.hr.personaldata.houseNumber": "Numer domu",
  "screen.hr.personaldata.houseNumber.validation": "Proszę podać numer domu",
  "screen.hr.personaldata.insurancedata": "Dane ubezpieczeniowe",
  "screen.hr.personaldata.insurancedatainformationbody": "Jeśli nie ma numeru SI, proszę pozostawić pole numeru ubezpieczenia społecznego puste.",
  "screen.hr.personaldata.insurancedatainformationhead": "Wskazówka:",
  "screen.hr.personaldata.insurancetype.validation": "Proszę wybrać ubezpieczenie",
  "screen.hr.personaldata.lastname": "Nazwisko",
  "screen.hr.personaldata.lastname.validation": "Proszę wpisać nazwisko",
  "screen.hr.personaldata.male": "Mężczyzna",
  "screen.hr.personaldata.marginally": "Niewielkie",
  "screen.hr.personaldata.navback": "Personel / Pracownicy",
  "screen.hr.personaldata.navheader": "Zarejestruj pracowników",
  "screen.hr.personaldata.nextpage": "W nawiązaniu do informacji uzupełniających",
  "screen.hr.personaldata.notSpecified": "Nie określono",
  "screen.hr.personaldata.personaldata": "Dane osobowe",
  "screen.hr.personaldata.personalinformation": "Dane osobowe",
  "screen.hr.personaldata.previouslyEmployed": "Czy ta osoba była kiedykolwiek zatrudniona w ciągu ostatnich 12 miesięcy?",
  "screen.hr.personaldata.socialsecuritynumber.validation": "Proszę podać numer ubezpieczenia społecznego",
  "screen.hr.personaldata.ssn": "Numer ubezpieczenia społecznego",
  "screen.hr.personaldata.state.validation": "Proszę podać stan federalny",
  "screen.hr.personaldata.streetnr.validation": "Proszę wpisać adres",
  "screen.hr.personaldata.workplaceState": "Stan miejsca pracy",
  "screen.hr.personaldata.zipCode": "POSTCODE",
  "screen.hr.personaldata.zipcode.length.validation": "",
  "screen.hr.personaldata.zipcode.validation": "Proszę wpisać kod pocztowy",
  "screen.hr.records.move.multiple.dialog.title": "Przenoszenie plików",
  "screen.hr.records.move.single.dialog.title": "Przenieść plik",
  "screen.hr.records.navbar.tab.list": "Pliki ({count})",
  "screen.hr.records.navbar.tab.tickets": "Zapytania ({count})",
  "screen.hr.records.transfer.dialog.text": "Czy chcesz przekazać <b>wszystkie pliki płacowe i informacje</b> za okres {periodStart} - {periodEnd} do TPA w celu zaksięgowania?",
  "screen.hr.records.transfer.dialog.textRecordType": "Czy chcesz przenieść <b>wszystkie pliki i informacje z \"{name}\"</b> za okres {periodStart} - {periodEnd} do TPA w celu zaksięgowania?",
  "screen.hr.records.transfer.dialog.title": "Przesyłanie plików",
  "screen.hr.records.types.header": "Personel",
  "screen.hr.records.upload.button": "Prześlij pliki",
  "screen.hr.records.upload.navbar.back": "Personel / Akta",
  "screen.hr.recordTypes.title": "Wybierz rodzaj dokumentów",
  "screen.hr.registration.flow.navback": "Krok do tyłu",
  "screen.hr.registration.flow.navheader": "{firstName} {lastName} zaloguj się",
  "screen.hr.summary.additionalinformation": "Informacje uzupełniające",
  "screen.hr.summary.bonuses": "Zasiłki",
  "screen.hr.summary.changeData": "Dodaj do listy",
  "screen.hr.summary.completeAdditionalData": "Dostarczyli Państwo wszystkie informacje uzupełniające.",
  "screen.hr.summary.completePersonalData": "Wprowadziłeś wszystkie informacje wymagane do rejestracji w regionalnej kasie chorych.",
  "screen.hr.summary.day": "Dzień",
  "screen.hr.summary.daysPerWeek": "Dni w tygodniu",
  "screen.hr.summary.description": "Sprawdź, czy podane informacje są poprawne, aby w następnym kroku przekazać je do TPA.",
  "screen.hr.summary.dialogQuestion1": "Czy chcesz",
  "screen.hr.summary.dialogQuestion2": " i wysłać do swojego doradcy podatkowego TPA w celu rejestracji?",
  "screen.hr.summary.documents": "Pliki",
  "screen.hr.summary.gender": "Płeć",
  "screen.hr.summary.grossSalary": "Wynagrodzenie brutto w {waluta}",
  "screen.hr.summary.hours": "Godziny",
  "screen.hr.summary.incompleteAdditionalData": "Nie wprowadziłeś jeszcze wszystkich informacji dodatkowych. Należy pamiętać, że niektóre z dodatkowych danych mogą być wymagane do czasu pierwszej wypłaty wynagrodzenia. Możesz je dodać w każdej chwili.",
  "screen.hr.summary.month": "Miesiąc",
  "screen.hr.summary.name": "{firstName} {lastName}",
  "screen.hr.summary.netSalary": "Wynagrodzenie netto w {waluta}",
  "screen.hr.summary.noPreregistration": "Żadne dane podstawowe nie zostały zmienione. Transmisja danych nie jest konieczna.",
  "screen.hr.summary.openDialog": "Złóż wniosek",
  "screen.hr.summary.summary": "Streszczenie",
  "screen.hr.summary.transferDataChange": "Przesyłanie zmiany danych podstawowych",
  "screen.hr.summary.transferEmployeeDialog.success.subtitle": " został utworzony i przekazany do rejestracji doradcy podatkowemu TPA.",
  "screen.hr.summary.transferEmployeeDialog.success.subtitle.elda": "{name} został utworzony. Protokół ELDA można obejrzeć i pobrać poniżej. Alternatywnie protokół można pobrać z akt osobowych pracownika.",
  "screen.hr.summary.week": "Tydzień",
  "screen.login.button.forgot_password": "ZAPOMNIAŁEŚ HASŁA?",
  "screen.login.button.resendCode": "Wyślij ponownie kod",
  "screen.login.form.divider": "lub",
  "screen.login.form.multiFactorAuth.label": "Kod",
  "screen.login.form.multiFactorAuth.validation_error": "Proszę wpisać kod.",
  "screen.login.form.password.label": "Hasło",
  "screen.login.form.password.validation_error": "Proszę wprowadzić hasło",
  "screen.login.form.submit": "Zaloguj się",
  "screen.login.form.title": "Zaloguj się",
  "screen.login.form.username.label": "Identyfikator użytkownika / Adres e-mail",
  "screen.login.form.username.validation_error": "Proszę wpisać swój identyfikator użytkownika",
  "screen.login.multiFactorAuth": "Wpisz kod, który wysłaliśmy na Twój numer telefonu za pomocą SMS-a.",
  "screen.login.welcome": "Witamy na naszej stronie! Proszę wpisać swój adres e-mail.",
  "screen.module.overview.report.empty.message": "Nie przesłano jeszcze żadnych raportów",
  "screen.module.overview.report.empty.title": "Brak dostępnych raportów",
  "screen.module.overview.report.upload": "Prześlij raport",
  "screen.moduleReportUpload.comment": "Tekst transmisji",
  "screen.moduleReportUpload.title": "Przesyłanie raportów",
  "screen.not_found.button.text": "Start z kokpitu",
  "screen.not_found.header": "404 Błąd",
  "screen.not_found.message": "Jesteśmy naprawdę dobrzy w liczbach, ale chcieliśmy Wam tego oszczędzić.",
  "screen.overview.salutation.female": "Szanowna Pani {lastName}",
  "screen.overview.salutation.general": "Szanowni Państwo",
  "screen.overview.salutation.male": "Szanowny Panie {lastName}",
  "screen.overview.salutation.notSpecified": "Dzień dobry {firstName} {lastName}",
  "screen.records.upload.header": "Przesyłanie plików {recordType}",
  "screen.records.upload.message": "Teraz zacznij wgrywać pierwsze pliki.",
  "screen.records.upload.title": "Prześlij {recordType}",
  "screen.server_error.button.text": "Do kokpitu",
  "screen.server_error.header": "505 Błąd",
  "screen.server_error.message": "Jesteśmy naprawdę dobrzy w liczbach, ale chcieliśmy Wam tego oszczędzić.",
  "screen.termsNotAccepted.button": "Powrót do przeglądu przedsiębiorstwa",
  "screen.termsNotAccepted.buttonAcceptNow": "Zaakceptuj teraz",
  "screen.termsNotAccepted.header": "Akceptacja warunków użytkowania w toku",
  "screen.termsNotAccepted.message": "Warunki użytkowania Twojej firmy nie zostały jeszcze zaakceptowane. W razie jakichkolwiek pytań prosimy o kontakt z doradcą TPA.",
  "screen.termsOfUse.accept": "Podpisać",
  "screen.termsOfUse.alreadySignedInfo": "Warunki użytkowania zostały już zaakceptowane lub ich akceptacja nie jest już konieczna.",
  "screen.termsOfUse.backToLogin": "Powrót do logowania",
  "screen.termsOfUse.checkbox": "Niniejszym akceptuję warunki użytkowania",
  "screen.termsOfUse.success.sign": "Warunki użytkowania zaakceptowane z powodzeniem",
  "screen.termsOfUse.title": "Zaakceptuj warunki użytkowania",
  "screen.termsOfUseWithCompanyNames.title": "Zaakceptuj warunki użytkowania dla [policz] firm",
  "screen.termsOfUseWithCompanyName.title": "Zaakceptuj warunki użytkowania",
  "screen.tickets.navbar.tabbar.closed": "Gotowe",
  "screen.tickets.navbar.tabbar.open": "Otwórz",
  "screen.tickets.navbar.tabbar.reported": "Dostarczony przeze mnie",
  "screen.upgradeToMicrosoft.error.azureUserAlreadyExists": "Ten użytkownik Microsoft jest już zarejestrowany pod adresem e-mail {existingUsername}.",
  "screen.upgradeToMicrosoft.feature.projects.text": "Proszę uaktualnić swoje konto, aby uzyskać dostęp do funkcji \"Projekty\".",
  "screen.upgradeToMicrosoft.form.code.description": "Wpisz kod, który wysłaliśmy Ci SMS-em, aby przystąpić do aktualizacji Microsoft.",
  "screen.upgradeToMicrosoft.form.code.label": "Kod",
  "screen.upgradeToMicrosoft.form.password.label": "Hasło",
  "screen.upgradeToMicrosoft.form.password.validation_error": "Proszę wpisać hasło",
  "screen.upgradeToMicrosoft.needCompany": "Powiązanie z kontem MS można wykonać tylko po wybraniu firmy.",
  "screen.upgradeToMicrosoft.resendButton.label": "Wyślij ponownie kod",
  "screen.upgradeToMicrosoft.title": "Połącz konto Microsoft",
  "search.caption.numCompanies": "Firma: <b>{count}</b>",
  "search.caption.numDespositedPersons": "Osoby zdeponowane: <b>{liczba}</b>",
  "search.caption.numDocuments": "Pliki: <b>{count}</b>",
  "search.caption.numPersons": "Osoby: <b>{liczba}</b>",
  "search.caption.numProjects": "Projekty: <b>{count}</b>",
  "search.caption.numResults": "Wyniki: <b>{count}</b>",
  "search.caption.numSelected": "Wybrane wpisy: <b>{count}</b>",
  "search.caption.numTickets": "Zapytania: <b>{count}</b>",
  "search.caption.numUploads": "Upload: <b>{count}</b>",
  "search.caption.numUsers": "Użytkownik: <b>{count}</b>",
  "search.placeholder.companies": "Firmy poszukują ...",
  "search.placeholder.projects": "szukaj projektu ...",
  "search.placeholder.search": "Szukaj ...",
  "search.placeholder.searchForActiveUsers": "Wyszukaj aktywnych użytkowników według nazwy...",
  "search.placeholder.searchForEmployees": "szukaj nazwisk...",
  "search.placeholder.searchForEmployeesPersonalNumber": "szukajcie nazwisk lub numerów personalnych...",
  "search.placeholder.searchForFileName": "Wyszukaj nazwę pliku...",
  "search.placeholder.searchForFiles": "Wyszukaj nazwy plików...",
  "search.placeholder.searchForUsers": "szukaj nazwisk...",
  "search.placeholder.searchForUsersFileName": "Wyszukiwanie według nazwy lub nazwy pliku...",
  "selectQesPosition.message": "Wybierz pozycję dla podpisu, a następnie kliknij przycisk {button}.",
  "selectQesPosition.message.collective": "Dla każdego użytkownika wybierz pozycję dla jego podpisu, a następnie kliknij przycisk Dalej.",
  "selectQesPosition.title": "Wybierz pozycję podpisu",
  "settings.navbar.company": "Firma",
  "settings.navbar.companyData": "Dane podstawowe",
  "settings.navbar.features": "Dodatki",
  "settings.navbar.general": "Ogólne",
  "settings.navbar.optionalSideBarItems": "Opcjonalne pozycje menu",
  "settings.navbar.userManagement": "Zarządzanie użytkownikami",
  "settings.notifications.activateAll": "AKTYWUJ WSZYSTKO",
  "settings.notifications.assignments": "Alokacje",
  "settings.notifications.deactivateAll": "DEZAKTYWUJ WSZYSTKO",
  "settings.notifications.deadlineExceeded": "Niedotrzymany termin",
  "settings.notifications.deadlineReminder": "Przypomnienie o terminie",
  "settings.notifications.generalDocumentUpload": "Prześlij pliki ogólne",
  "settings.notifications.label": "Powiadomienia e-mail, gdy:",
  "settings.notifications.messages": "Wiadomości",
  "settings.notifications.modules.accounting": "Księgowość",
  "settings.notifications.modules.hr": "Personel",
  "settings.notifications.modules.other": "Inne obszary",
  "settings.notifications.project.items.uploaded": "Przesyłanie projektów",
  "settings.notifications.recordProvision": "Upload klienta",
  "settings.notifications.release": "Zwolnienia",
  "settings.notifications.remindAfterHours.label": "Pamięć po {godzinach}h",
  "settings.notifications.reportProvision": "Postanowienie o sprawozdaniu",
  "settings.notifications.tickets": "Pytania",
  "settings.notifications.title": "Powiadomienia",
  "settings.personalData.email": "Adres e-mail",
  "settings.personalData.language": "Język",
  "settings.personalData.location": "Lokalizacja",
  "settings.personalData.microsoftAccount": "Konto Microsoft",
  "settings.personalData.microsoftUpgrade.info": "Konto Microsoft:{br}{br}Możesz połączyć swoje konto TPA Connect z kontem Microsoft. Dzięki temu będziesz mógł korzystać ze wszystkich funkcji TPA Connect.{br}{br}Po połączeniu konta z Microsoft, będziesz musiał zalogować się używając swojego konta Microsoft zamiast adresu e-mail.",
  "settings.personalData.microsoftUpgrade.label": "UPGRADE",
  "settings.personalData.phoneNumber": "Numer telefonu",
  "settings.personalData.role": "Rolka",
  "settings.personalData.title": "Dane osobowe",
  "settings.personalData.username": "Nazwa użytkownika",
  "settings.userManagement.permissions.existingUser.navbar.back": "Zarządzanie użytkownikami / Przydzielanie roli",
  "settings.userManagement.permissions.existingUser.navbar.backToUser": "Administracja użytkownikami / Przydzielanie ról / {employeeName}",
  "settings.userManagement.permissions.navbar.back": "Zarządzanie użytkownikami / Wybierz osobę kontaktową / Przypisz rolę",
  "settings.userManagement.permissions.navbar.backToUser": "Administracja użytkownikami / Wybierz osobę kontaktową / Przypisz rolę / {employeeName}",
  "settings.userManagement.roles.navbar.back": "Administracja użytkownikami / Wybierz osobę kontaktową",
  "sidebar.advisor.employees": "Lista pracowników",
  "sidebar.advisor.inbox": "Skrytka pocztowa",
  "sidebar.advisor.tickets": "Pytania",
  "sidebar.advisor.uploads": "Przesyłki",
  "sidebar.customerView": "Widok klienta",
  "sidebar.faceToFace.button": "Kontakt",
  "sidebar.faceToFace.title": "Twarzą w twarz z",
  "sidebar.list.accounting": "Księgowość",
  "sidebar.list.analysis": "",
  "sidebar.list.cockpit": "Kokpit",
  "sidebar.list.documents": "Raporty",
  "sidebar.list.faceToFace": "Twarzą w twarz",
  "sidebar.list.hr": "Personel",
  "sidebar.list.kpi": "Fakty i liczby",
  "sidebar.list.personnelFile": "Akta osobowe",
  "sidebar.list.projects": "Projekty",
  "sidebar.list.results": "Oceny",
  "sidebar.list.settings": "Ustawienia",
  "sidebar.list.support": "Wsparcie",
  "sidebar.list.tickets": "Pytania",
  "signDialog.noQesEnabled.message": "Niniejszym potwierdzam wydanie dokumentu.",
  "signDialog.noQesRequired.button.click": "Wydanie za kliknięcie",
  "signDialog.noQesRequired.button.qes": "Podpis elektroniczny",
  "signDialog.noQesRequired.message": "W przypadku zatwierdzania można wybrać zatwierdzenie przez kliknięcie lub przez podpis elektroniczny.",
  "signDialog.qesblocked.message": "Podpisanie się podpisem elektronicznym nie jest obecnie możliwe, ponieważ w tym czasie procedura podpisu została już rozpoczęta przez inną osobę. Proszę spróbować ponownie za około 15 minut.",
  "signDialog.qesblocked.title": "Wydanie nie jest obecnie możliwe",
  "signDialog.qesRequired.confirm": "OK, podpisz elektronicznie",
  "signDialog.qesRequired.message": "Do wydania został poproszony o podpis elektroniczny. Zostaniesz teraz skierowany do podpisu elektronicznego.",
  "signDialog.title": "Zwolnienie",
  "soleEarnerStatus.Alleinerzieher": "Samotny rodzic",
  "soleEarnerStatus.Alleinverdiener": "Jedyny zarabiający",
  "soleEarnerStatus.Nein": "Nie",
  "staff.documents.navbar.heading": "Moje pliki",
  "staff.personnelFile.navbar.heading": "Witaj {name}!",
  "stepper.optional": "opcjonalnie",
  "stepper.progress": "Progress",
  "support.admins.title": "Administratorzy",
  "support.filter.option.activeChats": "Tylko aktywne czaty",
  "support.filter.option.allChats": "Wszystkie czaty",
  "support.filter.option.inactiveChats": "Tylko nieaktywne czaty",
  "support.filter.setting.showInactiveColleagues": "Pokaż nieaktywnych pracowników",
  "support.inactiveEmployee": "Były pracownik",
  "support.partners.title": "Partnerzy",
  "support.privateChat.dialog.markAsPrivate.confirm": "Oznaczenie jako poufne",
  "support.privateChat.dialog.markAsPrivate.message": "Jeśli czat został oznaczony jako poufny, <b>żadna inna osoba</b> nie może przeglądać czatu i odpowiadać na wiadomości pod Twoją nieobecność.{br}{br}Twój klient <b>nie</b> zostanie poinformowany</b> o tym ustawieniu.",
  "support.privateChat.dialog.markAsPrivate.title": "Oznaczyć czat jako poufny?",
  "support.privateChat.dialog.markAsPublic.confirm": "Zrzeczenie się poufności",
  "support.privateChat.dialog.markAsPublic.message": "Gdy czat nie jest już oznaczony jako poufny, <b>inne osoby</b> mogą przeglądać czat i odpowiadać na wiadomości pod Twoją nieobecność.{br}{br}Twój klient nie jest <b>informowany</b> o tym ustawieniu.",
  "support.privateChat.dialog.markAsPublic.title": "Nie można już oznaczać czatu jako poufnego?",
  "support.privateChat.info": "Czat jest oznaczony jako <b>poufny</b> i nie może być oglądany przez innych.",
  "support.privateChat.info.markAsPublic": "PODNIEŚĆ DO GÓRY",
  "support.privateChat.info.mobile": "Czat jest <b>poufny</b>.",
  "support.privateChat.placeholder": "Wprowadź wiadomość ...",
  "support.responsible.title": "Członkowie zespołu",
  "support.search.navbar.title": "Wyniki wyszukiwania",
  "support.substitute.author": "{substituteName} (zastępuje {authorName})",
  "support.substitute.back": "WRACAM DO MOJEGO CZATU",
  "support.substitute.back.mobile": "MÓJ CZAT",
  "support.substitute.warning": "<b>Uwaga:</b> Znajdujesz się na czacie <b>{name}</b>.",
  "support.substitute.warning.mobile": "Czat prowadzony przez <b>{name}</b>",
  "support.yourContacts": "Państwa osoby kontaktowe",
  "svsAccount.transactions.table.contextMenu.download": "",
  "svsAccount.transactions.table.contextMenu.show": "",
  "svsAccount.transactions.table.filters.quarter": "",
  "svsAccount.transactions.table.label.amount": "",
  "svsAccount.transactions.table.label.quarter": "",
  "svsAccount.transactions.table.label.text": "",
  "svsAccount.transactions.table.search.count": "",
  "svsAccount.transactions.table.search.placeholder": "",
  "svsAccount.transactions.title": "",
  "table.button.release": "RELEASE",
  "table.employees.dateOfJoining": "Data wejścia",
  "table.employees.dateOfJoiningExiting": "Data wejścia/wyjścia",
  "table.employees.dateOfLeaving": "Data wyjścia",
  "table.employees.dateOfTransmitting": "Przekazywane na",
  "table.employees.documents.date": "Data",
  "table.employees.documents.document": "Plik",
  "table.employees.documents.documentName": "Oznaczenie",
  "table.employees.documents.downloadDocuments": "Pliki do pobrania",
  "table.employees.documents.uploadedBy": "Załadowane przez",
  "table.employees.employee": "Pracownik",
  "table.employees.jobDescription": "Stanowisko",
  "table.employees.missingDetails": "Dane brakujące",
  "table.employees.personalId": "Numer Personelu",
  "table.employees.reasonForLeaving": "Powód odejścia",
  "table.employees.status": "Status",
  "table.employees.status.active": "Aktywny",
  "table.employees.status.inactive": "Nieaktywny",
  "table.employees.status.inDataChange": "Zmiana danych podstawowych",
  "table.employees.status.inDataChangeNotTransferred": "Poprawka (projekt)",
  "table.employees.status.inDeregistration": "Przy wyrejestrowywaniu",
  "table.employees.status.inDeregistrationNotTransferred": "Wyrejestrowanie (projekt)",
  "table.employees.status.inRegistration": "W rejestracji",
  "table.employees.status.inRegistrationNotTransferred": "Wniosek (projekt)",
  "table.label.changes": "Zmiany",
  "table.label.changeUsername": "Wyślij e-mail w celu zmiany nazw użytkowników",
  "table.label.comment": "Komentarz",
  "table.label.companyName": "Nazwa firmy",
  "table.label.contactPerson": "Kontakt",
  "table.label.contactPersonId": "Numer osobisty",
  "table.label.costCenter": "Centrum kosztów",
  "table.label.createdAt": "Data wysłania",
  "table.label.createdAt.variant": "Utworzony w dniu",
  "table.label.createdBy": "Stworzony przez",
  "table.label.date": "Data",
  "table.label.dateOfJoining": "Data wejścia",
  "table.label.dateOfJoiningExiting": "Data wejścia/wyjścia",
  "table.label.dateOfLeaving": "Data wyjścia",
  "table.label.dateOfTransmitting": "Przekazywane na",
  "table.label.deleteUser": "Usuń użytkownika",
  "table.label.document": "Plik",
  "table.label.documentName": "Oznaczenie",
  "table.label.documentType": "Plik",
  "table.label.downloadDocuments": "Pliki do pobrania",
  "table.label.dueDate": "Termin:",
  "table.label.dueDateForDraft": "Termin wydania",
  "table.label.email": "Adres e-mail",
  "table.label.employee": "Pracownik",
  "table.label.file": "Plik",
  "table.label.fileName": "Oznaczenie",
  "table.label.folder": "Folder",
  "table.label.holiday": "Wakacje",
  "table.label.info": "Info",
  "table.label.jobDescription": "Stanowisko",
  "table.label.lastAuthenticatedAt": "Ostatnie logowanie",
  "table.label.lastAuthenticatedAt.registered.tooltip": "Zarejestrowany użytkownik",
  "table.label.lastAuthenticatedAt.unregistered.tooltip": "Użytkownik niezarejestrowany",
  "table.label.lastCreated": "Ostatnio utworzony",
  "table.label.members": "Członkowie",
  "table.label.message": "Wiadomość",
  "table.label.messages": "Wiadomości",
  "table.label.missingDetails": "Dane brakujące",
  "table.label.modulePermissions": "Zezwolenia na poziomie obszaru",
  "table.label.modules": "Obszary",
  "table.label.needsRelease": "Wymagane zwolnienie",
  "table.label.openReleases": "Otwarte wydania",
  "table.label.otherAbsence": "Inna nieobecność",
  "table.label.personalId": "Numer Personelu",
  "table.label.personalNumber": "Numer Personelu",
  "table.label.phoneNumber": "Numer telefonu",
  "table.label.reasonForLeaving": "Powód odejścia",
  "table.label.recordedBy": "Przechwycone z",
  "table.label.release": "Zwolnienia",
  "table.label.released": "Przesłane",
  "table.label.releasedAt": "Przekazywane na",
  "table.label.releaseRequestedBy": "Publikacja dostarczona przez",
  "table.label.role": "Rolka",
  "table.label.sentBy": "Wysłane z",
  "table.label.sickLeave": "Zwolnienie lekarskie",
  "table.label.status": "Status",
  "table.label.teamMessages": "Wiadomości o zespole",
  "table.label.ticket": "Konsultacje",
  "table.label.ticketBy": "Pytanie zadane przez",
  "table.label.ticketDescription": "Oznaczenie",
  "table.label.ticketDueDate": "Termin:",
  "table.label.tickets": "Pytania",
  "table.label.transferStatus": "Transfer",
  "table.label.transmittedAt": "Przekazywane na",
  "table.label.type": "Sztuka",
  "table.label.updatedAt": "Ostatnia edycja",
  "table.label.updatedAt.variant": "Ostatnio zmodyfikowany",
  "table.label.uploadedBy": "Załadowane przez",
  "table.label.username": "Nazwa użytkownika",
  "table.noResults.message": "Twoje wyszukiwanie nie przyniosło żadnych rezultatów.",
  "table.noResults.title": "Brak wyników wyszukiwania",
  "taxAccount.pay.button": "Zapłacić dług podatkowy",
  "taxAccount.transactions.table.filters.date": "Data",
  "taxAccount.transactions.table.filters.tax": "Podatek",
  "taxAccount.transactions.table.label.amount": "Kwota",
  "taxAccount.transactions.table.label.date": "Data",
  "taxAccount.transactions.table.label.due": "Dojrzałość",
  "taxAccount.transactions.table.label.taxPeriod": "Okres",
  "taxAccount.transactions.table.label.text": "Oznaczenie",
  "taxAccount.transactions.table.search.count": "Transakcje: <b>{count}</b>",
  "taxAccount.transactions.table.search.placeholder": "Wyszukiwanie transakcji",
  "taxAccount.transactions.title": "Saldo konta podatkowego: [wartość]",
  "termsOfUse.checkbox.error": "Proszę zaakceptować warunki użytkowania",
  "termsOfUse.upcoming.navbar.button": "Akceptuj",
  "termsOfUse.upcoming.navbar.message": "Warunki korzystania z aplikacji TPA Connect ulegną zmianie z dniem {data}.",
  "ticketList.emptystate.title": "Brak dostępnych zapytań",
  "unpaidAccounts.invoices.pay": "Wynagrodzenie",
  "unpaidAccounts.invoices.table.filters.date": "Data dokumentu",
  "unpaidAccounts.invoices.table.label.documentDate": "Data dokumentu",
  "unpaidAccounts.invoices.table.label.documentNumber": "Numer dokumentu",
  "unpaidAccounts.invoices.table.label.documents": "",
  "unpaidAccounts.invoices.table.label.documentText": "Tekst dokumentu",
  "unpaidAccounts.invoices.table.label.netAmount": "Kwota netto",
  "unpaidAccounts.invoices.table.label.opAmount": "Kwota otwarta",
  "unpaidAccounts.invoices.table.label.receiptDate": "Data dokumentu",
  "unpaidAccounts.invoices.table.label.receiptNumber": "Numer dokumentu",
  "unpaidAccounts.invoices.table.label.receiptText": "Tekst dokumentu",
  "unpaidAccounts.invoices.table.search.count": "Faktury: <b>{liczba}</b>",
  "unpaidAccounts.invoices.table.search.placeholder": "Wyszukiwanie faktur",
  "unpaidAccounts.invoices.title": "Zaległe faktury w TPA: [wartość]",
  "upload.category.analysis": "Sprawozdanie: Ocena miesięczna",
  "upload.category.annualReports": "Roczne sprawozdanie finansowe",
  "upload.category.articles": "Opracowania",
  "upload.category.billing": "Sprawozdanie: Zestawienie miesięczne",
  "upload.category.certificateOfCitizenship": "Dowód obywatelstwa",
  "upload.category.certificateOfRegistration": "Potwierdzenie rejestracji",
  "upload.category.contractOfEmployment": "Umowa",
  "upload.category.contracts": "Kontrakty",
  "upload.category.correspondence": "Korespondencja",
  "upload.category.dataChangeConfirmation": "Zmiana danych podstawowych",
  "upload.category.deregisterDocument": "Dokumenty wyrejestrowujące",
  "upload.category.deregistrationConfirmation": "Wyrejestrowanie pracownika",
  "upload.category.employmentPermit": "Pozwolenie na pracę",
  "upload.category.identityCard": "Dokument tożsamości",
  "upload.category.information": "Informacje",
  "upload.category.notices": "Ogłoszenia",
  "upload.category.other": "Inne",
  "upload.category.payslip": "odcinek wypłaty",
  "upload.category.registrationConfirmation": "Rejestracja pracowników",
  "upload.category.report": "Raporty",
  "upload.category.taxOffice": "Urząd skarbowy",
  "upload.category.taxReturn": "Zeznania podatkowe",
  "userManagement.successDialog.changeUsername.message": "<b>{userName}</b> otrzymuje prośbę o potwierdzenie nowego adresu e-mail.",
  "userManagement.successDialog.deleteUser.single.message": "<b>{userName}</b> został pomyślnie usunięty.",
  "userManagement.successDialog.deleteUser.title": "Użytkownik został pomyślnie usunięty.",
  "userManagement.successDialog.existingUser.superAdmin.multi.message": "Ustawienia dla <b>{count} użytkowników</b> zostały przyjęte.",
  "userManagement.successDialog.existingUser.superAdmin.single.message": "Ustawienia dla <b>{userName}</b> zostały przyjęte.",
  "userManagement.successDialog.existingUser.superAdmin.title": "Ustawienia użytkownika zostały pomyślnie zapisane",
  "userManagement.successDialog.existingUser.title": "Ustawienia użytkownika zaproponowane pomyślnie",
  "userManagement.successDialog.message": "Twoja propozycja musi teraz zostać zatwierdzona przez <b>superadmina</b>.",
  "userManagement.successDialog.newUser.superAdmin.multi.message": "<b>{count}użytkownicy</b> otrzymują zaproszenie przez e-mail.",
  "userManagement.successDialog.newUser.superAdmin.single.message": "<b>{userName}</b> otrzymuje zaproszenie pocztą elektroniczną.",
  "userManagement.successDialog.newUser.superAdmin.title": "Użytkownik pomyślnie zaproszony",
  "userManagement.successDialog.newUser.title": "Użytkownik pomyślnie zasugerował",
  "userManagement.successDialog.rejectUser.title": "Użytkownik pomyślnie odrzucony",
  "userManagement.successDialog.releaseExistingUser.title": "Użytkownik pomyślnie zwolniony",
  "userManagement.successDialog.releaseNewUser.title": "Użytkownik pomyślnie zwolniony i zaproszony",
  "userManagement.successDialog.sendUsernameChangeEmail.title": "E-mail dotyczący zmiany nazwy użytkownika został wysłany pomyślnie.",
  "userProfile.createBmdTodos": "Tworzenie zadań w BMD dla zapytań",
  "userProfile.deleteAccount.message": "Czy na pewno chcesz usunąć swoje konto? Tego działania nie można cofnąć!",
  "userProfile.deleteAccount.title": "Usuń konto",
  "userProfile.multiFactorAuth": "Włącz uwierzytelnianie dwuskładnikowe za pomocą numeru telefonu",
  "userProfile.navbar.heading": "Profil",
  "userProfile.upgrade.navbar.heading": "Aktualizacja",
  "userProfile.userInterests.title": "Zainteresowania",
  "userReleaseInfoAdminDialog.releaseInfo.multi": "Proszę zwolnić nowych użytkowników, aby ich zaprosić.",
  "userReleaseInfoAdminDialog.releaseInfo.single": "Proszę zatwierdzić nowego użytkownika, aby go zaprosić.",
  "userReleaseInfoAdminDialog.releaseMessage.multi": "{userName} zgłosił <b>{count} nowych użytkowników</b>.",
  "userReleaseInfoAdminDialog.releaseMessage.single": "{userName} przesłał <b>{count} do nowego użytkownika</b>.",
  "userReleaseInfoAdminDialog.submit": "Przeglądaj i udostępniaj",
  "userReleaseInfoAdminDialog.title": "Udostępnianie i zapraszanie użytkowników",
  "userReleaseInfoDialog.cancel": "Do administracji użytkownika",
  "userReleaseInfoDialog.from": "z",
  "userReleaseInfoDialog.message": "Twoje propozycje zmian dla następujących użytkowników zostały zatwierdzone/odrzucone:",
  "userReleaseInfoDialog.rejected": "Odrzucony",
  "userReleaseInfoDialog.released": "wydany",
  "userReleaseInfoDialog.title": "Zmiana użytkownika",
  "viewer.unsupported.message": "{nazwa} nie może być obecnie wyświetlana.",
  "webhook.employee.noSearch": "Wymagane wprowadzenie danych do wyszukiwania",
  "webhook.employee.placeholder": "Imię i nazwisko pracownika",
  "webhook.noCompanies": "Brak zezwoleń dla przedsiębiorstw",
  "webhook.tickets.button": "Utwórz zapytanie",
  "webhook.tickets.info": "Przypisz dokument do obszaru i wybierz kategorię",
  "webhook.tickets.navbar": "Konsultacje w sprawie {dokumentu}",
  "webhook.tickets.taskTitle": "Zadanie dla {nazwa}",
  "webhook.tickets.title": "Utwórz zapytanie",
  "webhook.uploads.navbar": "Przesyłanie plików",
  "welcomeCard.accounting.addMoreRecords.button": "Wprowadź kolejne dokumenty",
  "welcomeCard.accounting.addRecords.button": "Wprowadź dokumenty",
  "welcomeCard.hr.addMoreRecords.button": "Przesyłanie większej ilości plików",
  "welcomeCard.hr.addRecords.button": "Przesyłanie plików",
  "texterify_timestamp": "2024-11-21T10:06:37Z"
} as const;

export { polish };
