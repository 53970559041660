import { Button } from "@material-ui/core";
import { observer } from "mobx-react";
import * as React from "react";
import { Redirect, useParams } from "react-router-dom";
import { t } from "../../../i18n/util";
import { companiesStore } from "../../../stores/CompaniesStore";
import { generalStore } from "../../../stores/GeneralStore";
import { useHideSideBar } from "../../../stores/SideBarStore";
import { pushRoute, withParamsAndQuery } from "../../app/router/history";
import { useEmployee } from "../../hooks/useEmployee";
import { useEmployeeDocuments } from "../../hooks/useEmployeeDocuments";
import { useQueryParams } from "../../hooks/useQueryParams";
import { DocumentsSummary } from "../../ui/DocumentsSummary";
import { MobileContext } from "../../util/MobileContext";
import { customColors } from "../../util/Theme";
import { AddAdditionalDataDialog, checkAdditionalData } from "../HrDialogs";
import { HrEmployeeAdditionalDataOverview } from "../HrEmployeeAdditionalDataOverview";
import { HrEmployeePersonalDataOverview } from "../HrEmployeePersonalDataOverview";
import { HrRegistrationSite } from "../HrRegistrationSite";
import { hrStore } from "../HrStore";
import { useDisclaimer } from "../hooks/useDisclaimer";
import { HrRoutes } from "../router/HrRoutes";

export const HrEmployeesRegistrationSummarySite = observer(function HrEmployeesRegistrationSummarySite() {
    useHideSideBar();

    const query = useQueryParams<{ isEdit: boolean; isPreRegistration: boolean }>();
    const { isEdit, isPreRegistration } = query;
    const { employeeId } = useParams<{ employeeId: string }>();
    const { employee } = useEmployee(employeeId, !!isPreRegistration);
    const documents = useEmployeeDocuments({}, !!isPreRegistration, employee ? employee.id : "");
    const [openIncompleteAdditionalData, setOpenIncompleteAdditionalData] = React.useState(false);
    const isMobile = React.useContext(MobileContext);
    const companyId = companiesStore.selectedCompanyId;
    const subsidiaryId = employee?.subsidiaryId ?? hrStore.selectedSubsidiaryId;

    const handleTransmitClickOpen = () => {
        if (employee) {
            if (isEdit || checkAdditionalData(employee)) {
                disclaimer.open();
            } else {
                setOpenIncompleteAdditionalData(true);
            }
        }
    };

    const handleClose = () => {
        setOpenIncompleteAdditionalData(false);
    };

    const disclaimer = useDisclaimer(employee);

    if (!employeeId || !companyId || !subsidiaryId) {
        return <Redirect to={HrRoutes.EMPLOYEES.ROOT} />;
    }

    if (!employee) {
        return null;
    }

    const documentsSummary =
        !documents?.documents || documents.documents.length === 0 ? null : (
            <DocumentsSummary
                companyId={companyId}
                subsidiaryId={subsidiaryId}
                employeeId={employeeId}
                isPreRegistration={!!isPreRegistration}
                documents={documents.documents}
                onEdit={
                    isEdit // During edit -> don't allow doc upload in flow
                        ? undefined
                        : () => {
                              pushRoute(HrRoutes.EMPLOYEES.REGISTRATION_DOCUMENTS, { params: { employeeId }, query });
                          }
                }
            />
        );

    let cancelTarget = undefined;
    if (isEdit && employee.status !== "inDataChangeNotTransferred") {
        cancelTarget = withParamsAndQuery(HrRoutes.EMPLOYEES.DETAILS, { employeeId }, { isPreRegistration });
    } else if (isPreRegistration) {
        cancelTarget = HrRoutes.EMPLOYEES.IN_PROGRESS;
    }

    let backTarget = withParamsAndQuery(HrRoutes.EMPLOYEES.REGISTRATION_DOCUMENTS, { employeeId }, query);
    if (isEdit) {
        // During edit -> hide document upload from flow
        backTarget = withParamsAndQuery(HrRoutes.EMPLOYEES.REGISTRATION_ADDITIONAL_DATA, { employeeId }, query);
    }

    return (
        <HrRegistrationSite
            employee={employee}
            rightBarContent={documentsSummary}
            fullWidth
            cancelDialog={!isEdit}
            backTarget={backTarget}
            cancelTarget={cancelTarget}
            query={query}
        >
            <h1 style={{ marginBottom: 16 }}>{t("screen.hr.summary.summary")}</h1>
            <p className="body2" style={{ marginBottom: 32, color: customColors.body1Dark }}>
                {t("screen.hr.summary.description")}
            </p>

            {isMobile && documentsSummary && (
                <div style={{ padding: 32, marginBottom: 16, backgroundColor: customColors.white }}>
                    {documentsSummary}
                </div>
            )}

            <HrEmployeePersonalDataOverview
                employee={employee}
                onEdit={() => {
                    pushRoute(HrRoutes.EMPLOYEES.REGISTRATION_PERSONAL_DATA, {
                        query: { employeeId, ...query },
                    });
                }}
            />
            <HrEmployeeAdditionalDataOverview
                employee={employee}
                onEdit={() => {
                    if (employeeId) {
                        pushRoute(HrRoutes.EMPLOYEES.REGISTRATION_ADDITIONAL_DATA, {
                            params: { employeeId },
                            query,
                        });
                    }
                }}
            />

            <div style={{ textAlign: "right", margin: "40px 0 39px 0" }}>
                {!isPreRegistration && (
                    <p
                        className="body2"
                        style={{
                            textAlign: "left",
                            color: customColors.error,
                            marginBottom: 16,
                        }}
                    >
                        {t("screen.hr.summary.noPreregistration")}
                    </p>
                )}
                <Button
                    variant="contained"
                    color="primary"
                    style={{ padding: "12px 33px" }}
                    onClick={handleTransmitClickOpen}
                    data-id="summary_submit"
                    disabled={!isPreRegistration || generalStore.isLoading}
                >
                    {t(isEdit ? "screen.hr.summary.transferDataChange" : "screen.hr.summary.openDialog")}
                </Button>
            </div>
            <AddAdditionalDataDialog
                open={openIncompleteAdditionalData}
                onClose={handleClose}
                onTransferEmployee={() => {
                    handleClose();
                    disclaimer.open();
                }}
                employee={employee}
            />
            {disclaimer.component}
        </HrRegistrationSite>
    );
});
