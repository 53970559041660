import { IconButton, TableBody } from "@material-ui/core";
import compact from "lodash/compact";
import intersection from "lodash/intersection";
import isEmpty from "lodash/isEmpty";
import { observer } from "mobx-react";
import * as React from "react";
import { useParams } from "react-router";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { EmployeeDocument, EmployeeDocumentType } from "../../../network/APITypes";
import { authStore } from "../../../stores/AuthStore";
import { companiesStore } from "../../../stores/CompaniesStore";
import { generalStore } from "../../../stores/GeneralStore";
import { useTableStore } from "../../../stores/TableStore";
import { ViewerFile, viewerStore } from "../../../stores/ViewerStore";
import { IEmployeeRouteParams } from "../../../types/models";
import { formatDate, getFormattedISODateOnlyRange } from "../../../util/date";
import { hasFileExtension } from "../../../util/files";
import { employeeStatusToList } from "../../../util/helpers";
import { getFullName } from "../../../util/user";
import { Routes } from "../../app/router/Routes";
import { pushRoute, withParamsAndQuery } from "../../app/router/history";
import { useDeleteEmployeeDocument } from "../../hooks/useDeleteEmployeeDocument";
import { useDocumentSign } from "../../hooks/useDocumentSign";
import { useEmployee } from "../../hooks/useEmployee";
import { useQueryParams } from "../../hooks/useQueryParams";
import { useTableFilters } from "../../hooks/useTableFilters";
import { BulkDeleteButton, BulkDownloadButton } from "../../ui/BulkActionButton";
import { CenteredContent } from "../../ui/CenteredContent";
import { ContextMenu, ContextMenuItem, useContextMenu } from "../../ui/ContextMenu";
import { EmptyState } from "../../ui/EmptyState";
import { NavBarBack } from "../../ui/NavBarBack";
import {
    OptionalTooltip,
    TableLabel,
    TableRowButton,
    TpaTable,
    TpaTableCell,
    TpaTableCellMinWidth,
    TpaTableCellMinWidthNoWrap,
    TpaTableCheckbox,
    TpaTableContainer,
    TpaTableRow,
} from "../../ui/Primitives";
import { SiteContent } from "../../ui/SiteContent";
import { ITableHeaderConfig, TableHeader } from "../../ui/TableHeader";
import { TableSearchBarWithAction } from "../../ui/TableSearchBar";
import { DATE_FILTER_TYPE, ITableFilterCategory, ITableFilters } from "../../ui/filter/types";
import { FileIcon } from "../../util/FileIcon";
import { Icon } from "../../util/Icon";
import { MobileContext } from "../../util/MobileContext";
import { hrStore } from "../HrStore";
import { HrRoutes } from "../router/HrRoutes";

// <staffDocuments> = true means, we are showing our own documents in our personnel file section
export const HrEmployeesDocumentsSite = observer(function HrEmployeesDocumentsSite({
    staffDocuments,
}: {
    staffDocuments?: boolean;
}) {
    const { employeeId } = useParams<IEmployeeRouteParams>();

    const query = useQueryParams<{ isPreRegistration: boolean }>();
    const isPreRegistration = !!query.isPreRegistration;
    const isMobile = React.useContext(MobileContext);

    const companyId = companiesStore.selectedCompanyId;
    const subsidiaryId = hrStore.selectedSubsidiaryId;
    const canEdit = authStore.canEditEmployees(subsidiaryId);

    const tableStore = useTableStore<EmployeeDocument & { updatedAt?: unknown }>(
        employeeId ?? "HrEmployeesDocumentsSite",
        {
            orderBy: "updatedAt",
            orderDir: "desc",
        },
    );

    const [startDate, setStartDate] = React.useState("");
    const [endDate, setEndDate] = React.useState("");
    const [types, setTypes] = React.useState<EmployeeDocumentType[]>();

    const { employee } = useEmployee(employeeId, isPreRegistration);

    const { tableParams } = tableStore;
    const loadDocuments = React.useCallback(async () => {
        if (!companyId || (!staffDocuments && !employeeId)) {
            tableStore.items = [];
            tableStore.resetOffset();
            return;
        }

        try {
            generalStore.isLoading = true;

            let documents;
            if (staffDocuments) {
                // Load your own personal documents
                documents = await API.getStaffDocuments(companyId, {
                    ...tableParams,
                    startDate: startDate ? startDate : undefined,
                    endDate: endDate ? endDate : undefined,
                    types,
                });
            } else {
                // Load other employee's documents
                documents = await API.getEmployeeDocuments(companyId, subsidiaryId, employeeId, isPreRegistration, {
                    ...tableParams,
                    startDate: startDate ? startDate : undefined,
                    endDate: endDate ? endDate : undefined,
                    types,
                });
            }

            if (documents.documents) {
                tableStore.items = documents.documents;
                tableStore.totalCount = documents.total;
            }
        } catch (err) {
            generalStore.setError(t("error.loadEmployeeDocuments"), err);
        } finally {
            generalStore.isLoading = false;
        }
    }, [
        companyId,
        employeeId,
        endDate,
        isPreRegistration,
        staffDocuments,
        startDate,
        subsidiaryId,
        tableParams,
        tableStore,
        types,
    ]);

    React.useEffect(() => {
        loadDocuments();
    }, [loadDocuments]);

    const handleChangeDate = (date: Date) => {
        const { startDate, endDate } = getFormattedISODateOnlyRange(date, "month");
        setStartDate(startDate);
        setEndDate(endDate);
    };

    const deleteDocuments = useDeleteEmployeeDocument({
        companyId,
        subsidiaryId,
        employeeId,
        isPreRegistration,
        onDelete: numDeleted => {
            // If after deletion there are not enough documents left for the current offset -> go to start page
            if (tableStore.offset >= tableStore.totalCount - numDeleted) {
                tableStore.resetOffset();
            }

            loadDocuments();
        },
    });

    const handleDeleteSelected = () => {
        deleteDocuments.handleDeleteDocuments(tableStore.getAllSelectedItems());
    };

    const filterItems: ITableFilterCategory[] = [
        {
            category: "types",
            entries: compact([
                companiesStore.selectedCompany?.sendPayroll && {
                    name: "payslip",
                    label: t("screen.hr.employee.documents.payslips"),
                },
                { name: "contractOfEmployment", label: t("screen.hr.employee.documents.contracts") },
                {
                    name: "certificateOfRegistration",
                    label: t("screen.hr.employee.documents.certificatesOfRegistration"),
                },
                {
                    name: "certificateOfCitizenship",
                    label: t("screen.hr.employee.documents.certificateOfCitizenship"),
                },
                { name: "identityCard", label: t("screen.hr.employee.documents.identityCard") },
                // { name: "employmentPermit", label: t("screen.hr.employee.documents.employmentPermit") },
                { name: "deregisterDocument", label: t("screen.hr.employee.documents.deregisterDocument") },
                // { name: "annualPayslips", label: t("screen.hr.employee.documents.annualPayslips"), disabled: true },
                { name: "other", label: t("screen.hr.employee.documents.generalDocuments") },
            ]),
        },
        {
            category: "general",
            entries: [
                {
                    name: "anyDate",
                    label: t("screen.hr.employee.documents.anyDate"),
                    type: DATE_FILTER_TYPE,
                    onChange: handleChangeDate,
                },
            ],
        },
    ];

    const filters = useTableFilters({
        filters: filterItems,
        onChangeFilters: (selected: ITableFilters) => {
            tableStore.resetOffset();

            const typeFilters =
                filterItems.find(filter => filter.category === "types")?.entries.map(entry => entry.name) ?? [];
            setTypes(intersection(typeFilters, Object.keys(selected)) as EmployeeDocumentType[]);

            if (!selected.anyDate) {
                setStartDate("");
                setEndDate("");
            }
        },
    });

    const handleView = (document: EmployeeDocument) => {
        const downloadEmployeeId = employee?.employee_id;
        const files = tableStore.items.map<ViewerFile>(doc => {
            return {
                id: doc.id,
                name: doc.document.name,
                src: () => {
                    if (downloadEmployeeId) {
                        return API.getEmployeeDocumentDownloadUrl(
                            companyId ?? "",
                            "hr",
                            subsidiaryId ?? "",
                            downloadEmployeeId,
                            false,
                            doc.id,
                        );
                    } else {
                        return API.getEmployeeDocumentDownloadUrl(
                            companyId ?? "",
                            "hr",
                            subsidiaryId ?? "",
                            employeeId,
                            isPreRegistration,
                            doc.id,
                        );
                    }
                },
                download: () => {
                    if (downloadEmployeeId) {
                        return API.putDownloadEmployeeDocuments(
                            companyId ?? "",
                            "hr",
                            subsidiaryId ?? "",
                            downloadEmployeeId,
                            false,
                            [doc.id],
                        );
                    } else {
                        return API.putDownloadEmployeeDocuments(
                            companyId ?? "",
                            "hr",
                            subsidiaryId ?? "",
                            employeeId,
                            isPreRegistration,
                            [doc.id],
                        );
                    }
                },
            };
        });
        viewerStore.open(files, document.id);
    };

    const downloadEmployeeDocuments = async (employeeDocumentIds: string[]) => {
        const downloadEmployeeId = employee?.employee_id;
        if (downloadEmployeeId) {
            await hrStore.downloadEmployeeFiles({
                employeeId: downloadEmployeeId,
                isPreRegistration: false,
                documentIds: employeeDocumentIds,
                subsidiaryId: subsidiaryId ?? employee.subsidiaryId,
            });
        } else {
            await hrStore.downloadEmployeeFiles({
                employeeId,
                isPreRegistration,
                documentIds: employeeDocumentIds,
                subsidiaryId: subsidiaryId ?? employee?.subsidiaryId,
            });
        }
    };

    const contextMenu = useContextMenu<EmployeeDocument>();
    let contextMenuItems: ContextMenuItem[] = [];
    if (contextMenu.contextElement) {
        const document = contextMenu.contextElement;
        contextMenuItems = compact([
            {
                title: t("menu.download"),
                icon: "download",
                onClick: () => {
                    if (document) {
                        contextMenu.close();
                        downloadEmployeeDocuments([document.id]);
                    }
                },
                disabled: tableStore.selectedItems.size > 0,
                "data-id": "context_menu_download",
            },
            !staffDocuments && {
                title: t("menu.delete"),
                icon: "archive",
                onClick: () => {
                    deleteDocuments.handleDeleteDocuments([document]);
                    contextMenu.close();
                },
                disabled:
                    (!canEdit && !authStore.isTpa) || tableStore.selectedItems.size > 0 || document.type === "payslip",
                "data-id": "context_menu_delete",
            },
        ]);
    }

    const headerFields: ITableHeaderConfig[] = [
        { column: "updatedAt", label: "table.label.date" },
        { column: "documentType", label: "table.label.document" },
        { column: "documentName", label: "table.label.documentName" },
        { column: "uploader", label: "table.label.uploadedBy" },
        { column: "needsRelease", label: "table.label.needsRelease", style: { textAlign: "center" } },
        { column: "contextMenu" },
    ];

    const handleRelease = async (document: EmployeeDocument) => {
        if (!companyId) {
            return;
        }

        try {
            generalStore.isLoading = true;
            await API.releaseEmployeeDocument(companyId, document.id);

            // Reload badges
            await companiesStore.selectedCompanyStore?.startPollingBadges();

            await loadDocuments();
        } catch (err) {
            generalStore.setError(t("error.releaseEmployeeDocument"), err);
        } finally {
            generalStore.isLoading = false;
        }
    };

    const sign = useDocumentSign({
        companyId,
        onReleaseEmployeeDocument: handleRelease,
        onReload: loadDocuments,
    });

    const tableBody = (
        <TableBody>
            {tableStore.items.length > 0 &&
                tableStore.items.map((document: EmployeeDocument, index) => {
                    return (
                        <TpaTableRow
                            key={document.id}
                            onClick={() => {
                                handleView(document);
                            }}
                            style={{ cursor: "pointer" }}
                        >
                            {!isMobile && (
                                <TpaTableCheckbox
                                    onChange={(event, checked) => {
                                        tableStore.toggleSelection(document);
                                    }}
                                    color="primary"
                                    checked={tableStore.isSelected(document)}
                                    data-id={`checkboxSelect${document.id}`}
                                />
                            )}
                            {headerFields.map(hf => {
                                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                let label: any = document[hf.column as keyof EmployeeDocument];
                                if (hf.column === "updatedAt") {
                                    return (
                                        <TpaTableCellMinWidthNoWrap key={hf.column}>
                                            {formatDate(document.document.uploadedAt, "L")}
                                        </TpaTableCellMinWidthNoWrap>
                                    );
                                } else if (hf.column === "documentType") {
                                    return (
                                        <TpaTableCellMinWidthNoWrap key={hf.column}>
                                            <FileIcon name={document.document.name} />
                                        </TpaTableCellMinWidthNoWrap>
                                    );
                                } else if (hf.column === "documentName") {
                                    return (
                                        <TpaTableCell key={hf.column}>
                                            <TableLabel style={{ maxWidth: 350 }}>{document.document.name}</TableLabel>
                                        </TpaTableCell>
                                    );
                                } else if (hf.column === "uploader") {
                                    label = document.uploader?.isTpaEmployee
                                        ? t("common.tpaFirstName")
                                        : getFullName(document.uploader);
                                } else if (hf.column === "downloadButtons") {
                                    return (
                                        <TpaTableCell key={hf.column}>
                                            <IconButton
                                                style={{ padding: 0 }}
                                                onClick={() => {
                                                    downloadEmployeeDocuments([document.id]);
                                                }}
                                                data-id={`download${document.id}`}
                                            >
                                                <Icon name="download" />
                                            </IconButton>
                                        </TpaTableCell>
                                    );
                                } else if (hf.column === "needsRelease") {
                                    if (!document.needsRelease) {
                                        return <TpaTableCellMinWidthNoWrap key={hf.column} style={hf.style} />;
                                    }

                                    // already released looks the same for all users
                                    if (document.releasedAt) {
                                        return (
                                            <TpaTableCellMinWidth key={hf.column} style={hf.style}>
                                                <OptionalTooltip
                                                    title={t("screen.hr.documents.needsRelease.released.tooltip", {
                                                        releasedAt: formatDate(document.releasedAt),
                                                        name: getFullName(employee),
                                                    })}
                                                >
                                                    <span>{t("screen.hr.documents.needsRelease.released")}</span>
                                                </OptionalTooltip>
                                            </TpaTableCellMinWidth>
                                        );
                                    }

                                    // only the staff user (= the employee) can release
                                    if (staffDocuments) {
                                        return (
                                            <TpaTableCellMinWidthNoWrap key={hf.column} style={hf.style}>
                                                <TableRowButton
                                                    color="primary"
                                                    onClick={() => {
                                                        if (hasFileExtension(document.document.name, "pdf")) {
                                                            sign.openQesDialog({
                                                                signContext: "employeeDocument",
                                                                itemToRelease: document,
                                                                mimeType: "application/pdf",
                                                            });
                                                        } else {
                                                            handleRelease(document);
                                                        }
                                                    }}
                                                    style={{
                                                        whiteSpace: "nowrap",
                                                        textTransform: "uppercase",
                                                        padding: "5px 16px 3px 16px",
                                                    }}
                                                >
                                                    {t("button.release")}
                                                </TableRowButton>
                                            </TpaTableCellMinWidthNoWrap>
                                        );
                                    }

                                    // any other user (e.g. TPA or super admins)
                                    return (
                                        <TpaTableCellMinWidth key={hf.column} style={hf.style}>
                                            {t("screen.hr.documents.needsRelease.notReleased")}
                                        </TpaTableCellMinWidth>
                                    );
                                } else if (hf.column === "contextMenu") {
                                    return (
                                        <TpaTableCell key={hf.column} style={{ textAlign: "right", width: 1 }}>
                                            <IconButton
                                                data-id={`context_menu_${index}`}
                                                style={{ padding: 0 }}
                                                onClick={event => {
                                                    contextMenu.open(event, document);
                                                }}
                                            >
                                                <Icon name="more" />
                                            </IconButton>
                                        </TpaTableCell>
                                    );
                                }

                                return (
                                    <TpaTableCell key={hf.column}>
                                        <TableLabel>{label}</TableLabel>
                                    </TpaTableCell>
                                );
                            })}
                        </TpaTableRow>
                    );
                })}
        </TableBody>
    );

    let navbar;
    if (staffDocuments) {
        navbar = (
            <NavBarBack
                backTarget={Routes.STAFF}
                backLabel={t("screen.hr.employee.details.personnelFile")}
                title={t("staff.documents.navbar.heading")}
                showCancel={false}
            />
        );
    } else if (employeeId) {
        navbar = (
            <NavBarBack
                backTarget={withParamsAndQuery(HrRoutes.EMPLOYEES.DETAILS, { employeeId: employeeId }, query)}
                backLabel={t("screen.hr.employee.documents.backTargetDescription", {
                    status: employeeStatusToList(employee && employee.status),
                    firstName: employee?.firstName,
                    lastName: employee?.lastName,
                })}
                title={t("screen.hr.employee.documents.employeeDocuments", {
                    firstName: employee?.firstName,
                    lastName: employee?.lastName,
                })}
            />
        );
    } else {
        // Just a generic fallback, we should actually never get here
        navbar = <NavBarBack title={t("common.files")} />;
    }

    return (
        <>
            {navbar}
            <CenteredContent>
                {tableStore.getIsEmptyState(generalStore.isLoading, !isEmpty(filters.activeFilters)) ? (
                    <EmptyState
                        title={t("screen.hr.documents.emptystate.title")}
                        message={t("screen.hr.documents.emptystate.message")}
                        buttonLabel={t("screen.hr.documents.emptystate.buttonlabel")}
                        onAddEntry={() => {
                            if (employeeId && employee) {
                                pushRoute(HrRoutes.EMPLOYEES.REGISTRATION_DOCUMENTS, { params: { employeeId }, query });
                            }
                        }}
                        data-id="empty_state"
                        disabled={!authStore.canEditEmployees(subsidiaryId)}
                    />
                ) : (
                    <>
                        <SiteContent>
                            <TableSearchBarWithAction
                                label="search.caption.numUploads"
                                labelSelected="search.caption.numSelected"
                                placeholder="search.placeholder.searchForFiles"
                                select={tableStore}
                                search={tableStore.search}
                                totalCount={tableStore.totalCount}
                                onChangeSearch={tableStore.handleSearchChange}
                                bulkAction={
                                    <>
                                        <BulkDownloadButton
                                            onClick={() =>
                                                downloadEmployeeDocuments(
                                                    tableStore.getAllSelectedItems().map(doc => doc.id),
                                                )
                                            }
                                        />
                                        {!staffDocuments && (
                                            <BulkDeleteButton onClick={!canEdit ? undefined : handleDeleteSelected} />
                                        )}
                                    </>
                                }
                                tableFilters={filters}
                            />
                            <TpaTableContainer>
                                <TpaTable>
                                    <TableHeader
                                        headerFields={headerFields}
                                        tableStore={tableStore}
                                        select={tableStore}
                                    />
                                    {tableBody}
                                </TpaTable>
                            </TpaTableContainer>

                            <tableStore.Pagination />
                            <ContextMenu
                                data-id="context_menu"
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                config={contextMenu}
                                items={contextMenuItems}
                            />
                        </SiteContent>
                        {tableStore.getIsNoResultState(generalStore.isLoading, !isEmpty(filters.activeFilters)) && (
                            <EmptyState title={t("table.noResults.title")} message={t("table.noResults.message")} />
                        )}
                    </>
                )}
            </CenteredContent>
            {deleteDocuments.dialog}
            {sign.qesDialog}
            {sign.qesBlockedDialog}
        </>
    );
});
