import { Mutex } from "async-mutex";
import compact from "lodash/compact";
import isEqual from "lodash/isEqual";
import sortBy from "lodash/sortBy";
import { action, computed, observable, toJS } from "mobx";
import { create, persist } from "mobx-persist";
import * as config from "../config";
import { TEST_MS_TOKEN_REFRESH_OFFLINE } from "../config";
import { GLOBAL_FEATURES } from "../features";
import { ILocales, SupportedLocales } from "../i18n/ILocales";
import { setLocale, t } from "../i18n/util";
import { API } from "../network/API";
import { apiClient } from "../network/APIClient";
import { GeneralDocumentType, Permission } from "../network/APITypes";
import { CachedApiCall } from "../network/CachedApiCall";
import { getApiError } from "../network/NetworkStapler";
import { HttpStatusCode } from "../network/httpStatusCode";
import { IUserInfo, Module } from "../types/models";
import { addStoreToWindow, debug } from "../util/debug";
import { parsePermissions } from "../util/permissionParser";
import { getFullName } from "../util/user";
import { generalStore } from "./GeneralStore";

export interface ICredentials {
    access_token: string;
    refresh_token: string;
    expires_in: number;
    token_type: string;
}

export interface IProfile {
    uid: string;
    scope: string[];
    email: string;
}

export type AuthError =
    | "CompaniesSyncTimeout"
    | "PasswordWrong"
    | "PasswordExpired"
    | "mfaCodeWrong"
    | "mfaCodeAlreadySent"
    | "Unknown";

const mutex = new Mutex();

interface Coordinator {
    wipe: () => void;
}

class Auth {
    @persist("object") @observable _credentials: ICredentials | null = null;
    @persist @observable _locale!: ILocales;
    @persist @observable isLocaleInitialized = false;
    @observable error: AuthError | null = null;
    @observable _isLoading = false;
    @observable isRehydrated = false;
    tokenTime: number | null = null;
    @observable userInfo?: IUserInfo;
    @observable permissions = parsePermissions();

    @observable forgotPasswordEmail: string | null = null;
    @observable showUnknownMSLoginDialog = false;

    // Have to persist so it survives the OAuth flow
    @persist @observable redirectAfterLogin?: string;

    // the coordinator injects itself to avoid circular dependencies
    coordinator: Coordinator | undefined = undefined;

    constructor() {
        this.locale = "de";

        apiClient.authStore = this;
    }

    get isLoading() {
        return this._isLoading;
    }

    set isLoading(isLoading: boolean) {
        if (!this._isLoading) {
            return;
        }
        this._isLoading = isLoading;
        generalStore.isLoading = isLoading;
    }
    @action wipe(error: AuthError | null) {
        this.coordinator?.wipe();

        this.credentials = null;
        this.error = error;
        this.isLoading = false;
        this.userInfo = undefined;
        this.permissions = parsePermissions();

        this.forgotPasswordEmail = null;
        this.redirectAfterLogin = undefined;
    }

    get credentials() {
        return this._credentials;
    }

    set credentials(credentials: ICredentials | null) {
        this._credentials = credentials;

        CachedApiCall.resetAll();
    }

    @computed get isAuthenticated() {
        return !!this.credentials;
    }

    @computed get userId() {
        return this.userInfo?.sub;
    }

    get locale() {
        return this._locale;
    }

    set locale(newLocale: ILocales) {
        setLocale(newLocale);
        this._locale = newLocale;
    }

    @computed get isSuperAdmin() {
        return this.permissions.isSuperAdmin;
    }

    @computed get isAdvisor() {
        return this.permissions.isAdvisor;
    }

    @computed get canChatSecret() {
        return this.permissions.canChatSecret;
    }

    @computed get canChat() {
        return this.permissions.canChat;
    }

    /**
     * `true` if the current user is a TPA employee
     *
     * Use carefully because this does not take into account any roles/permissions on the currently selected company.
     * Therefore this is mostly useful if no company is currently selected.
     */
    @computed get isTpa() {
        return !!this.userInfo?.is_tpa_employee;
    }

    @computed get isStaff() {
        return this.permissions.isStaff;
    }

    @computed get isStaffOnly() {
        if (this.isStaff) {
            // staff only, if roles are ["staff"] or ["none", "staff"]
            return (
                isEqual(this.permissions.raw?.roles, ["staff"]) ||
                isEqual(sortBy(this.permissions.raw?.roles), ["none", "staff"])
            );
        }

        return false;
    }

    @computed get canSeeTpaTicketsSidebar() {
        // TPAPORTAL-2274: only if one of advisor/accounting/hr roles present -> show tickets in sidebar
        return this.permissions.raw
            ? this.permissions.raw.roles.filter(
                  role =>
                      role === "tpa-accounting" || role === "tpa-hr" || role === "tpa-advisor" || role === "tpa-other",
              ).length > 0
            : false;
    }

    @computed get greeting() {
        if (this.isTpa || !this.userInfo?.family_name) {
            return t("screen.overview.salutation.general");
        } else if (this.userInfo.gender === "male") {
            return t("screen.overview.salutation.male", { lastName: this.userInfo.family_name });
        } else if (this.userInfo.gender === "female") {
            return t("screen.overview.salutation.female", { lastName: this.userInfo.family_name });
        } else {
            return t("screen.overview.salutation.notSpecified", {
                firstName: this.userInfo.given_name ?? "",
                lastName: this.userInfo.family_name,
            });
        }
    }

    @computed get salutation() {
        const firstName = this.userInfo?.given_name ?? "";
        const lastName = this.userInfo?.family_name ?? "";
        const fullName = getFullName({ firstName, lastName });

        if (!this.userInfo?.family_name) {
            return fullName;
        } else if (this.userInfo.gender === "male") {
            return t("common.mr", { name: lastName });
        } else if (this.userInfo.gender === "female") {
            return t("common.mrs", { name: lastName });
        } else {
            return fullName;
        }
    }

    isResponsibleForModule(module: Module) {
        return this.permissions?.map?.roles?.includes(`tpa-${module}`);
    }

    get canReadUserSettings() {
        return this.permissions.hasGlobalPermission("settings:user", "canRead") || this.isSuperAdmin;
    }

    get canUpdateUserSettings() {
        return this.permissions.canUpdateUserSettings;
    }

    get canEditCompanyImages() {
        return this.permissions.hasGlobalPermission("settings:images", "canEdit");
    }

    canReleaseGeneralDocuments(documentType: GeneralDocumentType) {
        return this.permissions.hasGlobalActions(`generalDocuments:${documentType}`, ["release"]);
    }

    canDeleteGeneralDocuments(documentType: GeneralDocumentType) {
        return this.permissions.hasGlobalActions(("generalDocuments:" + documentType) as Permission.GroupEnum, [
            "delete",
        ]);
    }

    get canReadAnyEmployees() {
        return this.permissions.canReadAnyEmployees;
    }

    canReadEmployees(subsidiaryId?: string) {
        return this.permissions.hasSubsidiaryPermission(subsidiaryId, "hr:personnel", "canRead");
    }

    canEditEmployees(subsidiaryId?: string) {
        if (!this.canReadEmployees(subsidiaryId)) {
            return false;
        }

        // TODO: We should not check permission shorts. Instead check for actions. Function in this case
        // should be split into e.g. canCreateEmployees (for "neuen MA anmelden") etc.
        return this.permissions.hasSubsidiaryPermission(subsidiaryId, "hr:personnel", "canEdit");
    }

    canDeleteEmployees(subsidiaryId?: string) {
        return this.permissions.hasSubsidiaryActions(subsidiaryId, "hr:personnel", ["delete"]);
    }

    get canReadCompanySettings() {
        return this.permissions.hasGlobalPermission("settings:company", "canRead");
    }

    get canEditCompanySettings() {
        return this.permissions.hasGlobalPermission("settings:company", "canEdit");
    }

    canCloseTicket(ticket: { author: { id: string } }) {
        // advisors can close all tickets
        return ticket.author.id === this.userId || this.isAdvisor;
    }

    get canReadProjects() {
        return this.permissions.hasGlobalActions("projects", ["read"]);
    }

    get canCreateProjects() {
        return this.permissions.hasGlobalActions("projects", ["create"]);
    }

    get canDeleteProjects() {
        return this.permissions.hasGlobalActions("projects", ["delete"]);
    }

    canReadBanking() {
        return this.permissions.hasGlobalActions("banking", ["read"]);
    }
    canCreateBanking() {
        return this.permissions.hasGlobalActions("banking", ["create"]);
    }
    canEditBanking() {
        return this.permissions.hasGlobalActions("banking", ["update"]);
    }
    canDeleteBanking() {
        return this.permissions.hasGlobalActions("banking", ["delete"]);
    }
    canEditBankAccountAccountNumber() {
        return (
            this.canReadBanking() &&
            (this.permissions.isAdvisor || !!this.permissions.raw?.roles.includes("tpa-accounting"))
        );
    }
    canDownloadBankAccountTransactionsBankStatement() {
        return (
            this.canReadBanking() &&
            (this.permissions.isAdvisor || !!this.permissions.raw?.roles.includes("tpa-accounting"))
        );
    }
    canDownloadBankAccountTransactionsBuerf() {
        return (
            this.canReadBanking() &&
            (this.permissions.isAdvisor || !!this.permissions.raw?.roles.includes("tpa-accounting"))
        );
    }

    get canSeeFaceToFaceWidget() {
        return (
            GLOBAL_FEATURES.support &&
            !this.isTpa &&
            this.permissions.raw &&
            !isEqual(this.permissions.raw.roles, ["none"]) &&
            !this.isStaffOnly
        );
    }

    @action loginWithPassword = async (username: string, password: string) => {
        if (this.isLoading) {
            // bailout, noop
            return;
        }

        this.isLoading = true;

        try {
            const response = await API.loginWithPassword({
                username: username,
                password: password,
            });

            this.error = null;

            if (!response.loginCompleted) {
                return response.flowToken;
            } else if (response.tokens) {
                this.credentials = response.tokens;
            }
        } catch (error) {
            const apiError = getApiError(error);
            if (
                apiError?.statusCode === HttpStatusCode.Unauthorized_401 ||
                apiError?.statusCode === HttpStatusCode.BadRequest_400
            ) {
                this.wipe("PasswordWrong");
            } else if (apiError?.response.type === "USER_PASSWORD_EXPIRED") {
                this.wipe("PasswordExpired");
            } else if (
                apiError?.response.type === "COMPANIES_SYNC_TIMEOUT" ||
                apiError?.statusCode === HttpStatusCode.GatewayTimeout_504
            ) {
                this.wipe("CompaniesSyncTimeout");
            } else if (apiError?.response.type === "MFA_CODE_SENT_ALREADY") {
                this.error = "mfaCodeAlreadySent";
            } else {
                this.wipe("Unknown");
            }
        } finally {
            this.isLoading = false;
        }
    };

    @action loginWithMultiFactorAuthCode = async (flowToken: string, code: string) => {
        if (this.isLoading) {
            // bailout, noop
            return;
        }

        this.isLoading = true;

        try {
            const credentials = await API.loginWithMultiFactorAuthCode({
                code: code,
                flowToken: flowToken,
            });

            this.error = null;
            this.credentials = credentials;
        } catch (error) {
            const apiError = getApiError(error);
            if (
                apiError?.statusCode === HttpStatusCode.Unauthorized_401 ||
                apiError?.statusCode === HttpStatusCode.BadRequest_400
            ) {
                this.wipe("mfaCodeWrong");
            } else if (
                apiError?.response.type === "COMPANIES_SYNC_TIMEOUT" ||
                apiError?.statusCode === HttpStatusCode.GatewayTimeout_504
            ) {
                this.wipe("CompaniesSyncTimeout");
            } else {
                this.wipe("Unknown");
            }
        } finally {
            this.isLoading = false;
        }
    };

    @action upgradeAndLoginWithMicrosoft = async (
        inviteOriginCompanyId: string,
        inviteToken?: string,
        international?: boolean,
    ) => {
        // MS registration will invalidate token
        const response = await API.getMSInvite({ inviteOriginCompanyId, inviteToken, international });
        window.location.assign(response.inviteRedeemUrl);

        // After registration user should relogin
        this.wipe(null);
    };

    refreshMSToken = async (countryCode: string) => {
        try {
            this.isLoading = true;
            // Refresh in popup -> no user interaction afterwards necessary
            if (!TEST_MS_TOKEN_REFRESH_OFFLINE) {
                const res = await API.postMSRefresh(countryCode);
                // Window will be closed later in /ms-close route
                window.open(res.redirectUrl, "TPA", config.POPUP_WINDOW_FEATURES);
            } else {
                window.open("http://localhost:3000/auth/ms-refresh-offline", "TPA", config.POPUP_WINDOW_FEATURES);
            }
        } catch (error) {
            generalStore.setError(t("error.general"), error);
        } finally {
            this.isLoading = false;
        }
    };

    acceptMSInvite = async ({
        companyId,
        inviteToken,
        international,
    }: {
        companyId?: string;
        inviteToken?: string;
        international?: boolean;
    }) => {
        try {
            this.isLoading = true;

            if (!companyId) {
                debug.error("No companyId for invitation");
                return;
            }

            const res = await API.getMSInvite({ inviteOriginCompanyId: companyId, inviteToken, international });
            window.location.assign(res.inviteRedeemUrl);
        } catch (error) {
            generalStore.setError(t("error.general"), error);
        } finally {
            this.isLoading = false;
        }
    };

    @action loadUserInfo = async (noCache?: boolean) => {
        if (!this.isAuthenticated) {
            return;
        }

        this.isLoading = true;
        try {
            this.userInfo = await API.getUserInfo(noCache);
            if (this.userInfo.locale) {
                this.locale = this.userInfo.locale as ILocales;
            }
            debug.log("### User info", toJS(this.userInfo));
        } catch (error) {
            generalStore.setError(t("error.loadProfile"), error);
            throw error;
        } finally {
            this.isLoading = false;
        }
    };

    @action loadPermissions = async (companyId: string) => {
        try {
            this.isLoading = true;
            const raw = await API.getCurrentUserPermissions(companyId);
            this.permissions = parsePermissions(raw, this.userInfo?.gender);
        } catch (error) {
            const apiError = getApiError(error);
            if (
                apiError?.statusCode === HttpStatusCode.Conflict_409 &&
                apiError.response.type === "TERMS_OF_USE_NOT_ACCEPTED"
            ) {
                // need to check if user is advisor in order to allow usage for company when terms are not accepted -> ignore this error
            } else {
                generalStore.setError(t("error.loadPermissions"), error);
                throw error;
            }
        } finally {
            this.isLoading = false;
        }
    };

    @action logout = async () => {
        if (!this.isAuthenticated) {
            return;
        }

        try {
            await API.logout(this.credentials?.refresh_token);
        } catch (err) {
            debug.error("### Error logging out", err);
        }

        this.wipe(null);
    };

    @action tokenExchange = async (keepRefresh?: boolean) => {
        this.isLoading = true;
        let success = false;
        try {
            if (this.credentials === null) {
                throw new Error(`No valid credentials are available`);
            }

            this.credentials = await API.tokenRefresh(this.credentials.refresh_token, keepRefresh);

            const date = new Date();
            this.tokenTime = date.getTime();

            this.error = null;

            success = true;
        } catch (e) {
            const apiError = getApiError(e);
            if (apiError?.response.type === "USER_PASSWORD_EXPIRED") {
                this.wipe("PasswordExpired");
            } else if (
                apiError?.response.type === "COMPANIES_SYNC_TIMEOUT" ||
                apiError?.statusCode === HttpStatusCode.GatewayTimeout_504
            ) {
                this.wipe("CompaniesSyncTimeout");
            } else {
                generalStore.setError(t("error.tokenRefresh"), e);
                this.wipe("Unknown");
            }
        } finally {
            this.isLoading = false;
        }

        return success;
    };

    handleTokenExchange = async () => {
        return await mutex.runExclusive(async () => {
            if (!this.tokenTime || !this.credentials) {
                return this.tokenExchange();
            }

            // Check if token is expired. This is needed because if e.g. 5 calls are done simultaneously on
            // a page change then all 5 would trigger a token refresh. This check makes sure only
            // the first call goes through, all subsequent calls would not be done because the new
            // token is not expired yet.
            // ATTENTION: If you expire the token manually on the BE side this would fail and isTokenExpired would
            // be false because the store expiration time is still valid
            const date = new Date();
            // expires_in is in seconds, so it needs to converted to ms
            const isTokenExpired = this.tokenTime + this.credentials.expires_in * 1000 < date.getTime();
            if (isTokenExpired) {
                return this.tokenExchange();
            }

            return true;
        });
    };

    // Returns true if token exchange was successful otherwise wipe store
    @action handleUnauthorized = async () => {
        let tokenExchangeSuccess = false;
        if (this.credentials) {
            try {
                tokenExchangeSuccess = await this.handleTokenExchange();
            } catch {
                this.wipe(null);
            }
        } else {
            this.wipe(null);
        }

        return tokenExchangeSuccess;
    };
}

let authStore: Auth;
if (import.meta.env.NODE_ENV === "test") {
    class MockAuth {
        @observable credentials: ICredentials | null = null;
        @observable isAuthenticated = false;
        @observable error: AuthError | null = null;
        @observable isRehydrated = true;

        locale = "de";

        @action loginWithPassword = () => undefined;
        @action dismissError = () => undefined;
        @action logout = () => undefined;
    }

    authStore = new MockAuth() as unknown as Auth; // no localstorage support in node env
} else {
    authStore = new Auth();

    (async () => {
        // persist this mobx state through localforage
        const hydrate = create({
            storage: (await import("localforage")).default,
        });

        hydrate("auth", authStore)
            .then(() => {
                // trigger token exchange if credentials are available...
                if (authStore.credentials !== null) {
                    debug.log("hydrate.auth: credentials are available, awaiting new token...");
                    authStore
                        .tokenExchange(true)
                        .then(() => {
                            debug.log("hydrate.auth: received new token!");
                            authStore.isRehydrated = true;
                        })
                        .catch(() => {
                            debug.log("hydrate.auth: failed to receive new token!");
                            authStore.isRehydrated = true;
                        });
                } else {
                    authStore.isRehydrated = true;
                    debug.log("rehydrated, no credentials are available.");
                }

                // Initial language selection
                if (!authStore.isLocaleInitialized) {
                    // In case browser does not support navigator.languages add browser language at the end
                    const languages = [...window.navigator.languages, window.navigator.language];

                    // filter out unsupported languages
                    const filtered = compact(
                        languages.filter(language => {
                            for (const lang of SupportedLocales) {
                                if (language.startsWith(lang)) {
                                    return true;
                                }
                            }
                            return false;
                        }),
                    );

                    // Take first preference, remove language sub code (e.g. en-US becomes en)
                    const language = (filtered[0] ?? "en").slice(0, 2);
                    authStore.locale = language as ILocales;

                    authStore.isLocaleInitialized = true;
                } else {
                    const l = authStore.locale;
                    authStore.locale = l; // reassign to trigger various updates
                }
            })
            .catch((error: unknown) => {
                console.error(error);
            });

        addStoreToWindow("authStore", authStore);
    })();
}

// singleton, exposes an instance by default
export { authStore };
