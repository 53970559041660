import { observable } from "mobx";
import {
    Permission,
    Permissions,
    PermissionsRecordTypes,
    PermissionsRequest,
    PermissionsSubsidiaries,
    Role,
    User,
} from "../network/APITypes";
import { addStoreToWindow } from "../util/debug";

export interface IConfigUser {
    id: string;
    firstName: string;
    lastName: string;
    needsRelease?: boolean;
    changedBy?: User;
    changes?: Permissions;
    confirmedAt?: Date;
    email?: string;
    roles?: Role[];
    profile_picture_url?: string;
    type: "user" | "person";
}

type PermissionsStoreState = Record<string, PermissionsRequest>;

export interface IPermissionState {
    actions: Permission.ActionsEnum[];
    group: Permission.GroupEnum;
    isSelected: boolean;
}

interface PermissionsRecordTypesState extends PermissionsRecordTypes {
    permission: IPermissionState;
}

interface PermissionsSubsidiariesState extends PermissionsSubsidiaries {
    permissions?: IPermissionState[];
    recordTypes?: PermissionsRecordTypesState[];
}

export interface IUserPermissionsState extends PermissionsRequest {
    global?: IPermissionState[];
    subsidiaries?: PermissionsSubsidiariesState[];
}

export type IPermissionsState = Record<string, IUserPermissionsState>;

class ConfigStore {
    @observable selectedEmployees: IConfigUser[] = [];
    @observable permissionsState: IPermissionsState | null = null;
    @observable permissions: PermissionsStoreState | null = null;
    @observable defaultPermissions: PermissionsStoreState | null = null;

    getCurrentEmployeeIndex(employeeId: string) {
        return this.selectedEmployees.findIndex(employee => employee.id === employeeId);
    }

    wipe() {
        this.selectedEmployees = [];
        this.permissions = null;
        this.defaultPermissions = null;
        this.permissionsState = null;
    }
}

const configStore = new ConfigStore();

export { configStore };

addStoreToWindow("configStore", configStore);
