import { Button } from "@material-ui/core";
import { Field, Form, Formik } from "formik";
import { isValid } from "iban";
import React from "react";
import * as Yup from "yup";
import { i18n, t } from "../../i18n/util";
import { YupLocalizedNumber } from "../../util/helpers";
import { CustomDialog, CustomDialogContent } from "../ui/CustomDialog";
import { CustomInputField } from "../ui/CustomInputField";

export const PaymentDialog = (props: {
    open: boolean;
    onClose: () => void;
    onSubmit: (values: { iban: string; amount: number; purpose: string }) => Promise<void>;
    iban?: string;
    amount?: number;
    purpose?: string;
    maxAmount?: number;
}) => {
    const [submitting, setSubmitting] = React.useState(false);
    const handleSubmit = async (values: { iban: string; amount: number; purpose: string }) => {
        if (submitting) {
            return;
        }

        setSubmitting(true);
        await props.onSubmit(values);
        setSubmitting(false);
        props.onClose();
    };

    return (
        <CustomDialog onClose={props.onClose} open={props.open}>
            <CustomDialogContent>
                <h1>{t("paymentDialog.title")}</h1>
                <div style={{ marginTop: 24 }}>
                    <Formik
                        initialValues={{
                            iban: props.iban ?? "",
                            amount: props.amount ?? 0,
                            purpose: props.purpose ?? "",
                        }}
                        validationSchema={Yup.object({
                            iban: Yup.string()
                                .required(t("error.requiredField"))
                                .test("isValidIban", t("paymentDialog.field.iban.invalidError"), value => {
                                    if (!value) return false;
                                    return isValid(value);
                                }),
                            amount: YupLocalizedNumber()
                                .required(t("error.requiredField"))
                                .min(0.01, t("paymentDialog.field.amount.minError"))
                                .max(
                                    props.maxAmount ?? Infinity,
                                    t("paymentDialog.field.amount.maxError", {
                                        value: i18n.intl.formatNumber(props.maxAmount ?? 0, {
                                            style: "currency",
                                            currency: "EUR",
                                        }),
                                    }),
                                ),
                            purpose: Yup.string().required(t("error.requiredField")),
                        })}
                        onSubmit={handleSubmit}
                    >
                        {({ isValid }) => (
                            <Form>
                                <div style={{ marginBottom: 10 }}>
                                    <Field
                                        component={CustomInputField}
                                        name="iban"
                                        data-id="iban"
                                        label={t("paymentDialog.field.iban")}
                                        required
                                    />
                                </div>
                                <div style={{ marginBottom: 10 }}>
                                    <Field
                                        component={CustomInputField}
                                        name="amount"
                                        data-id="amount"
                                        label={t("paymentDialog.field.amount")}
                                        required
                                    />
                                </div>
                                <div>
                                    <Field
                                        component={CustomInputField}
                                        name="purpose"
                                        data-id="purpose"
                                        label={t("paymentDialog.field.purpose")}
                                        required
                                    />
                                </div>
                                <div style={{ marginTop: 10, textAlign: "right" }}>
                                    <Button
                                        color="primary"
                                        variant="outlined"
                                        onClick={props.onClose}
                                        disabled={submitting}
                                    >
                                        {t("common.cancel")}
                                    </Button>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        type="submit"
                                        disabled={submitting || !isValid}
                                        style={{ marginLeft: 8 }}
                                    >
                                        {t("paymentDialog.button.pay")}
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </CustomDialogContent>
        </CustomDialog>
    );
};
