import { RadioGroup } from "@material-ui/core";
import * as React from "react";
import { Redirect, useParams } from "react-router";
import { IMessageIDS, t } from "../../../i18n/util";
import { Company } from "../../../network/APITypes";
import { useHideSideBar } from "../../../stores/SideBarStore";
import { pushRoute, withParams } from "../../app/router/history";
import { ModuleConfig, useModuleConfig } from "../../hooks/useModuleConfig";
import { useSuccessDialog } from "../../hooks/useSuccessDialog";
import { NavBarBack } from "../../ui/NavBarBack";
import { PageWithStepper } from "../../ui/PageWithStepper";
import { H4WithIcon, InfoButton, TpaRadio } from "../../ui/Primitives";
import { AdvisorConfigCompanyStepper } from "../AdvisorConfigCompanyStepper";
import { AdvisorRoutes } from "../router/AdvisorRoutes";
import {
    AdvisorConfigModuleTypeDetails,
    ChooseDueDate,
    ConfigSubmit,
    ConfigSwitch,
    Subsidiaries,
    ToggleGlobalReports,
    ToggleModuleActive,
    ToggleRequireTwoReportReleases,
} from "./AdvisorConfigAccountingSite";

const DueDates = ({ config, style }: { config: ModuleConfig; style: React.CSSProperties }) => {
    const dueTransferValue: IMessageIDS = config.hrRelativeDueDates
        ? "config.hr.due.transfer.select.value.relativeDueDates"
        : "config.hr.due.select.value";
    const dueTransferHelper: IMessageIDS = config.hrRelativeDueDates
        ? "config.accounting.due.transfer.select.subtitle"
        : "config.hr.due.transfer.select.subtitle";

    const dueReportsValue: IMessageIDS = config.hrRelativeDueDates
        ? "config.hr.due.reports.select.value"
        : "config.hr.due.select.value";
    const dueReportsHelper: IMessageIDS = config.hrRelativeDueDates
        ? "config.hr.due.reports.select.subtitle"
        : "config.hr.due.transfer.select.subtitle";
    const dueReportsTitle: IMessageIDS = config.hrRelativeDueDates
        ? "config.hr.due.reports.select.title"
        : "config.accounting.due.transfer.select.title";

    const dueReportsReleaseValue: IMessageIDS = config.hrRelativeDueDates
        ? "config.accounting.due.reportRelease.select.value"
        : "config.hr.due.select.value";
    const dueReportsReleaseHelper: IMessageIDS = config.hrRelativeDueDates
        ? "config.accounting.due.reportRelease.select.subtitle"
        : "config.hr.due.transfer.select.subtitle";
    const dueReportsReleaseTitle: IMessageIDS = config.hrRelativeDueDates
        ? "config.accounting.due.reportRelease.select.title"
        : "config.accounting.due.transfer.select.title";

    return (
        <div style={style}>
            <H4WithIcon icon="upload">{t("config.hr.dueDates.title")}</H4WithIcon>

            <div style={{ marginTop: 24 }}>
                <ConfigSwitch
                    data-id="switch_hr_relative_due_dates"
                    checked={config.hrRelativeDueDates}
                    onCheck={checked => {
                        config.setHrHasRelativeDueDates(checked);
                    }}
                    label={t("config.hr.due.relativeDueDates")}
                    canEdit={config.canEdit}
                />
            </div>

            <h4 style={{ marginTop: 24 }}>{t("config.hr.dueDates.reminder")}</h4>

            <ChooseDueDate
                title={`01. ${t("config.hr.due.transfer.title")}`}
                selectTitle={t("config.accounting.due.transfer.select.title")}
                dueDate={config.dueTransfer}
                setDueDate={config.setDueTransfer}
                valueString={value => t(dueTransferValue, { day: value })}
                helperText={t(dueTransferHelper)}
                maxDays={25}
                canEdit={config.canEdit}
            />

            <ChooseDueDate
                title={`02. ${t("config.accounting.due.reports.title")}`}
                selectTitle={t(dueReportsTitle)}
                dueDate={config.dueReports}
                setDueDate={config.setDueReports}
                valueString={value => t(dueReportsValue, { day: value })}
                helperText={t(dueReportsHelper)}
                maxDays={25}
                canEdit={config.canEdit}
            />

            <ChooseDueDate
                title={`03. ${t("config.accounting.due.reportRelease.title")}`}
                selectTitle={t(dueReportsReleaseTitle)}
                dueDate={config.dueReportRelease}
                setDueDate={config.setDueReportRelease}
                valueString={value => t(dueReportsReleaseValue, { day: value })}
                helperText={t(dueReportsReleaseHelper)}
                maxDays={25}
                canEdit={config.canEdit}
            />
        </div>
    );
};

const ToggleSendPayroll = ({ config, style }: { config: ModuleConfig; style?: React.CSSProperties }) => (
    <div style={style}>
        <div style={{ display: "flex", alignItems: "center" }}>
            <h4>{t("config.hr.sendPayroll.title")}</h4>
            <InfoButton
                title={t("config.hr.sendPayroll.tooltip")}
                color="primary"
                style={{ padding: 0, marginLeft: 4 }}
            />
        </div>
        <ConfigSwitch
            data-id="switch_electronic_payslip"
            checked={config.sendPayroll}
            onCheck={checked => {
                config.setSendPayroll(checked);
            }}
            label={t("config.hr.sendPayroll.description")}
            style={{ marginTop: 24 }}
            canEdit={config.canEdit}
        />
    </div>
);

const ToggleUpload = ({ config, style }: { config: ModuleConfig; style?: React.CSSProperties }) => (
    <div style={style}>
        <h4>{t("config.hr.upload.title")}</h4>
        <ConfigSwitch
            checked={config.canUpload}
            onCheck={checked => {
                config.setCanUpload(checked);
            }}
            label={t("config.hr.upload.description")}
            style={{ marginTop: 24 }}
            canEdit={config.canEdit}
        />
    </div>
);

const ToggleChat = ({ config, style }: { config: ModuleConfig; style?: React.CSSProperties }) => (
    <div style={style}>
        <h4>{t("config.hr.chat.title")}</h4>
        <ConfigSwitch
            checked={config.canChat}
            onCheck={checked => {
                config.setCanChat(checked);
            }}
            label={t("config.hr.chat.description")}
            style={{ marginTop: 24 }}
            canEdit={config.canEdit}
        />
    </div>
);

const ToggleHasResults = ({ config, style }: { config: ModuleConfig; style?: React.CSSProperties }) => {
    if (!config.company) {
        return null;
    }

    return (
        <ConfigSwitch
            data-id="switch_toggle_has_results"
            checked={config.hasHRResults}
            onCheck={checked => {
                config.setHasHRResults(checked);
            }}
            label={t("config.hr.hasResults")}
            canEdit={config.canEdit}
            style={style}
        />
    );
};

const ChooseReleaseSteps = (props: { config: ModuleConfig; style?: React.CSSProperties }) => (
    <div style={props.style}>
        <h4>{t("config.hr.releaseSteps.title")}</h4>
        {props.config.canEdit && (
            <RadioGroup
                name="releaseSteps"
                value={props.config.releaseSteps}
                onChange={event => {
                    props.config.setReleaseSteps(event.target.value as Company.ReleaseStepsEnum);
                }}
                style={{ marginTop: 24 }}
            >
                <TpaRadio
                    value="oneStep"
                    title={t("config.hr.releaseSteps.oneStep.title")}
                    subtitle={t("config.hr.releaseSteps.oneStep.description")}
                />
                <TpaRadio
                    value="twoSteps"
                    title={t("config.hr.releaseSteps.twoSteps.title")}
                    subtitle={t("config.hr.releaseSteps.twoSteps.description")}
                    style={{ marginTop: 16 }}
                />
            </RadioGroup>
        )}
        {!props.config.canEdit && (
            <div style={{ marginTop: 24 }}>
                <p>{t(`config.hr.releaseSteps.${props.config.releaseSteps}.title`)}</p>
                <p className="body2" style={{ marginTop: 8 }}>
                    {t(`config.hr.releaseSteps.${props.config.releaseSteps}.description`)}
                </p>
            </div>
        )}
    </div>
);

export const AdvisorConfigHrContent = ({ config, isSettings }: { config: ModuleConfig; isSettings?: boolean }) => {
    if (!config.company) {
        return null;
    }

    const sectionStyle = { marginTop: 32 };

    return (
        <>
            {isSettings === true ? <h3>{t("common.hr")}</h3> : <h1>{t("common.hr")}</h1>}

            {config.canEdit && <p style={{ marginTop: 16, fontSize: 12 }}>{t("config.hr.description")}</p>}
            {!config.canEdit && (
                <p style={{ marginTop: 16, fontSize: 12 }}>{t("config.accounting.description.customer")}</p>
            )}

            {config.moduleActive && config.canEdit && (
                <AdvisorConfigModuleTypeDetails
                    companyId={config.company.id}
                    module={config.module}
                    style={sectionStyle}
                />
            )}
            {config.canEdit && <ToggleModuleActive config={config} style={sectionStyle} />}
            {!config.canEdit && !config.company.hasHR && <ToggleModuleActive config={config} style={sectionStyle} />}

            {config.moduleActive && (
                <>
                    <ToggleRequireTwoReportReleases config={config} style={{ marginTop: 16 }} />
                    <ToggleHasResults config={config} style={{ marginTop: 16 }} />
                    <ToggleSendPayroll config={config} style={sectionStyle} />
                    <ChooseReleaseSteps config={config} style={sectionStyle} />
                    <ToggleChat config={config} style={sectionStyle} />
                    <ToggleUpload config={config} style={sectionStyle} />
                    <DueDates config={config} style={sectionStyle} />
                    <ToggleGlobalReports config={config} style={sectionStyle} />
                    <Subsidiaries config={config} style={sectionStyle} select />
                </>
            )}
        </>
    );
};

export const AdvisorConfigHrSite = () => {
    useHideSideBar();
    const { companyId } = useParams<{ companyId: string }>();

    const config = useModuleConfig(companyId, "hr", true);

    const successDialog = useSuccessDialog({
        title: t("dialog.companyConfig.success.header"),
        message: t("dialog.companyConfig.success.message", { name: config.company?.name }),
        onClose: () => {
            if (companyId) {
                pushRoute(withParams(AdvisorRoutes.CONFIG.OVERVIEW, { companyId }));
            } else {
                pushRoute(AdvisorRoutes.COMPANIES.INACTIVE);
            }
        },
    });

    if (!companyId) {
        // No company in route -> get out
        return <Redirect to={AdvisorRoutes.COMPANIES.INACTIVE} />;
    }

    const { company } = config;

    if (!company) {
        // Company not loaded yet -> wait
        return null;
    }

    return (
        <>
            <NavBarBack
                title={t("config.companies")}
                backLabel={`${t("config.overview")}/${t("common.accounting")}`}
                backTarget={withParams(AdvisorRoutes.CONFIG.ACCOUNTING, { companyId })}
                cancelTarget={withParams(AdvisorRoutes.CONFIG.OVERVIEW, { companyId })}
                companyName={company.name}
            />
            <PageWithStepper stepper={<AdvisorConfigCompanyStepper company={company} />}>
                <AdvisorConfigHrContent config={config} />
                <ConfigSubmit
                    style={{ marginTop: 32, textAlign: "right" }}
                    companyId={companyId}
                    config={config}
                    onSubmit={() => {
                        if (company.accountingEnabled && company.hasCockpitKPI) {
                            pushRoute(withParams(AdvisorRoutes.CONFIG.KPIS, { companyId }));
                        } else {
                            successDialog.openDialog();
                        }
                    }}
                />
            </PageWithStepper>
            {successDialog.dialog}
        </>
    );
};
