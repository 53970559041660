import { Gender, Permission } from "../network/APITypes";
import { IPermissions, Module } from "../types/models";
import { PermissionShort, permissionShortsMap } from "./permissionConfig";
import {
    containsActions,
    getModulesWithRecordTypePermission,
    getPermissionModules,
    getRoleString,
    getRolesString,
    hasAnySubsidiaryWithGroupPermission,
    permissionsCanChat,
    permissionsCanChatSecret,
    permissionsHasAnyTPARole,
    permissionsHasManagingTPARole,
    permissionsIsAdvisor,
    permissionsIsStaff,
    permissionsIsSuperAdmin,
    permissionsToMap,
} from "./permissionHelpers";

// Returns true if actions array contains actions of <permissionShort>
// e.g. containsPermissionShort(["read"], "canRead") returns true
function containsPermissionShort(actions: Permission.ActionsEnum[], permissionShort: PermissionShort) {
    const actionSubset = permissionShortsMap[permissionShort].actions;
    return containsActions(actions, actionSubset);
}

// Parse permissions and add helper functions
export function parsePermissions(permissions?: IPermissions, gender?: Gender) {
    const raw = permissions;
    const map = permissionsToMap(raw);
    const modules = getPermissionModules(raw);
    const modulesWithReadAccess = getModulesWithRecordTypePermission(raw, "canRead");
    const modulesWithEditAccess = getModulesWithRecordTypePermission(raw, "canEdit");

    const hasGlobalPermission = (group: Permission.GroupEnum, short: PermissionShort): boolean => {
        return !!map && containsPermissionShort(map.global?.permissions[group]?.actions, short);
    };

    const hasSubsidiaryPermission = (
        subsidiaryId: string | undefined,
        group: Permission.GroupEnum,
        short: PermissionShort,
    ): boolean => {
        return (
            !!subsidiaryId &&
            !!map &&
            containsPermissionShort(map.subsidiaries?.[subsidiaryId]?.permissions[group]?.actions, short)
        );
    };

    const hasRecordTypePermission = (
        subsidiaryId: string | undefined,
        recordTypeId: string | undefined,
        group: Permission.GroupEnum,
        short: PermissionShort,
    ): boolean => {
        return (
            !!subsidiaryId &&
            !!recordTypeId &&
            !!map &&
            containsPermissionShort(
                map.subsidiaries?.[subsidiaryId]?.recordTypes[recordTypeId]?.permissions[group]?.actions,
                short,
            )
        );
    };

    const hasGlobalActions = (group: Permission.GroupEnum, actions: Permission.ActionsEnum[]): boolean => {
        return !!map && containsActions(map.global?.permissions[group]?.actions, actions);
    };

    const hasSubsidiaryActions = (
        subsidiaryId: string | undefined,
        group: Permission.GroupEnum,
        actions: Permission.ActionsEnum[],
    ): boolean => {
        return (
            !!subsidiaryId &&
            !!map &&
            containsActions(map.subsidiaries?.[subsidiaryId]?.permissions[group]?.actions, actions)
        );
    };

    const _getRoleString = () => {
        if (raw) {
            return raw.roles.length === 0
                ? getRoleString({ role: "none", gender })
                : getRolesString({ roles: raw.roles, gender });
        } else {
            return "";
        }
    };

    const hasModule = (module: Module) => {
        return modules.includes(module);
    };

    const canReadAnyEmployees = hasModule("hr") && hasAnySubsidiaryWithGroupPermission(raw, "hr:personnel", "canRead");

    const canUpdateUserSettings = hasGlobalPermission("settings:user", "canUpdate") || permissionsIsSuperAdmin(raw);

    return {
        raw,
        map,
        modules,
        modulesWithReadAccess,
        modulesWithEditAccess,
        roleString: _getRoleString(),
        hasAnyTPARole: permissionsHasAnyTPARole(raw),
        /** Advisor (`tpa-advisor`), accountants (`tpa-accounting`) and HR (`tpa-hr`) are managing TPA roles */
        hasManagingTPARole: permissionsHasManagingTPARole(raw),
        isAdvisor: permissionsIsAdvisor(raw),
        isSuperAdmin: permissionsIsSuperAdmin(raw),
        isStaff: permissionsIsStaff(raw),
        canChat: permissionsCanChat(raw),
        canChatSecret: permissionsCanChatSecret(raw),
        hasModule,
        hasGlobalPermission,
        hasSubsidiaryPermission,
        hasRecordTypePermission,
        hasGlobalActions,
        hasSubsidiaryActions,
        canReadAnyEmployees,
        canUpdateUserSettings,
    } as const;
}

export type IParsedPermissions = ReturnType<typeof parsePermissions>;
