import { Collapse, ListItem, ListItemIcon, SwipeableDrawer, withStyles } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import debounce from "lodash/debounce";
import flatMap from "lodash/flatMap";
import { observer } from "mobx-react";
import * as React from "react";
import { COMPANY_LIMIT, SEARCH_DEBOUNCE_MS } from "../../../config";
import { GLOBAL_FEATURES } from "../../../features";
import { t } from "../../../i18n/util";
import { authStore } from "../../../stores/AuthStore";
import { companiesStore } from "../../../stores/CompaniesStore";
import { coordinator } from "../../../stores/Coordinator";
import { generalStore } from "../../../stores/GeneralStore";
import { SIDEBAR_WIDTH_MOBILE, sideBarStore } from "../../../stores/SideBarStore";
import { isIos } from "../../../util/helpers";
import { AdvisorRoutes } from "../../advisor/router/AdvisorRoutes";
import { Routes } from "../../app/router/Routes";
import { pushRoute } from "../../app/router/history";
import { useCompanies } from "../../hooks/useCompanies";
import { TpaMenuItem } from "../../ui/Primitives";
import { IIconNames, Icon } from "../../util/Icon";
import { TpaConnectPortrait } from "../../util/Images";
import { MobileContext } from "../../util/MobileContext";
import { customColors } from "../../util/Theme";
import { SearchFieldListItem } from "../ContextMenu";
import { AdvisorDivider, AdvisorSideBarItems } from "../sidebar/AdvisorSideBarItems";
import { CustomerSideBarItems } from "../sidebar/CustomerSideBarItems";
import { FaceToFaceWidget } from "../sidebar/FaceToFaceWidget";
import { SideBarItems } from "../sidebar/SideBarItems";

const StyledListItem = withStyles({
    selected: {
        backgroundColor: `${customColors.primaryShade} !important`,
        color: `${customColors.primaryColor} !important`,
        borderLeft: `2px solid ${customColors.primaryColor}`,
    },
    gutters: {
        padding: 16,
    },
})(ListItem);

export const ActionIcon = (props: { name: IIconNames; onClick: () => void }) => {
    return (
        <Icon
            name={props.name}
            style={{ display: "block", color: customColors.primaryColor, cursor: "pointer" }}
            onClick={props.onClick}
        />
    );
};

export const MobileMenu = observer(function MobileMenu() {
    const [isCompanyMenuOpen, setIsCompanyMenuOpen] = React.useState(false);
    const isMobile = React.useContext(MobileContext);
    const firstName = authStore.userInfo?.given_name ?? "";
    const lastName = authStore.userInfo?.family_name ?? "";
    const email = authStore.userInfo?.email ?? "";
    const [filter, setFilter] = React.useState("");
    const [value, setValue] = React.useState("");
    const [numCompanies, setNumCompanies] = React.useState<number | undefined>(undefined);

    React.useEffect(() => {
        if (!isMobile) {
            sideBarStore.mobileMenuOpen = false;
        }
    }, [isMobile]);

    const {
        initialized,
        companies,
        remainingCount: remainingCompanies,
        total,
    } = useCompanies({
        search: filter,
        limit: COMPANY_LIMIT,
        orderBy: "favorite",
    });

    const applyFilter = React.useMemo(
        () =>
            debounce((value: string) => {
                setFilter(value);
            }, SEARCH_DEBOUNCE_MS),
        [],
    );

    const handleChange = (value: string) => {
        setValue(value);
        applyFilter(value);
    };

    const handleLogout = async () => {
        try {
            await coordinator.logout();
            handleCloseMobileMenu();
        } catch (err) {
            generalStore.setError(t("error.logout"), err);
        }
    };

    const handleClickProfile = () => {
        pushRoute(Routes.USER_PROFILE);
        handleCloseMobileMenu();
    };

    const handleClickCompanyOverview = () => {
        const target = authStore.isTpa ? AdvisorRoutes.COMPANIES.ACTIVE : Routes.COMPANY_OVERVIEW;
        pushRoute(target);
        handleCloseMobileMenu();
    };

    const handleCloseMobileMenu = () => {
        sideBarStore.mobileMenuOpen = false;
    };

    const handleToggleCompanyList = () => {
        setIsCompanyMenuOpen(!isCompanyMenuOpen);
    };

    const handleMenuCompanyItemClick = async (companyId: string) => {
        setIsCompanyMenuOpen(false);
        handleCloseMobileMenu();

        try {
            await coordinator.selectCompanyById(companyId);
            pushRoute(Routes.COCKPIT);
        } catch (err) {
            generalStore.setError(t("error.general"), err);
        }
    };

    const handleOpenMobileMenu = (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event &&
            event.type === "keydown" &&
            ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")
        ) {
            return;
        }

        sideBarStore.mobileMenuOpen = true;
    };

    const iOS = isIos();

    if (!initialized) {
        return null;
    }
    const addCompanyOverview = !companiesStore.selectedCompanyId && companies.length > 0;

    if (!filter && numCompanies === undefined) {
        setNumCompanies(total);
    }
    const hasMoreThanOneCompany = (numCompanies ?? 0) > 1;

    // Cases where we cannot show the sidebar yet:
    // - No company selected -> we need the company to know if it has (accounting, hr, etc. enabled)
    // - Permissions not loaded -> we don't know if the logged in user is allowed to see every feature
    // - Terms not accepted yet
    const logoutOnly = !companiesStore.selectedCompanyStore?.permissions.raw || !companiesStore.canAccessCompany;

    return (
        <SwipeableDrawer
            disableBackdropTransition={!iOS}
            disableDiscovery={iOS}
            open={sideBarStore.mobileMenuOpen}
            onClose={handleCloseMobileMenu}
            onOpen={handleOpenMobileMenu}
            style={{
                width: "100%",
                maxWidth: SIDEBAR_WIDTH_MOBILE,
                whiteSpace: "nowrap",
            }}
            PaperProps={{ style: { width: "100%", maxWidth: SIDEBAR_WIDTH_MOBILE } }}
            anchor="left"
        >
            <div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "24px 16px",
                    }}
                >
                    <div style={{ alignSelf: "flex-start" }}>
                        <Icon
                            name="close"
                            style={{ color: customColors.primaryColor, cursor: "pointer", display: "block" }}
                            onClick={handleCloseMobileMenu}
                        />
                    </div>
                    <TpaConnectPortrait style={{ width: 126 }} />
                    {/* TODO avatars */}
                    <div />
                </div>
                <div
                    className="caption"
                    style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: 16 }}
                >
                    <span style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
                        {firstName ? `${firstName} ${lastName}` : email}
                    </span>
                    <div style={{ display: "flex" }}>
                        {(addCompanyOverview || authStore.isAdvisor) && (
                            <div style={{ marginLeft: 8 }}>
                                <ActionIcon name="suitcase" onClick={handleClickCompanyOverview} />
                            </div>
                        )}

                        <div style={{ marginLeft: 16 }}>
                            <ActionIcon name="profile" onClick={handleClickProfile} />
                        </div>
                        <div style={{ marginLeft: 16 }}>
                            <ActionIcon name="logout" onClick={handleLogout} />
                        </div>
                    </div>
                </div>
                <Divider />
                {!addCompanyOverview && !logoutOnly && (
                    <>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                padding: 16,
                                cursor: "pointer",
                            }}
                            onClick={handleToggleCompanyList}
                        >
                            <div className="caption" style={{ whiteSpace: "normal", fontWeight: "bold" }}>
                                {companiesStore.selectedCompany?.name}
                            </div>
                            {hasMoreThanOneCompany && (
                                <div>
                                    <Icon
                                        name={isCompanyMenuOpen ? "chevronUp" : "chevronRight"}
                                        style={{ display: "block", color: customColors.primaryColor }}
                                    />
                                </div>
                            )}
                        </div>
                        {hasMoreThanOneCompany && (
                            <Collapse in={isCompanyMenuOpen} timeout="auto" unmountOnExit>
                                {(hasMoreThanOneCompany || value.length > 0) && (
                                    <>
                                        <Divider />
                                        <TpaMenuItem style={{ borderBottom: `1px solid ${customColors.greyLight}` }}>
                                            <SearchFieldListItem
                                                data-id="mobile_menu_companies_search"
                                                placeholder={t("search.placeholder.companies")}
                                                value={value}
                                                onChange={value => {
                                                    handleChange(value);
                                                }}
                                            />
                                        </TpaMenuItem>
                                    </>
                                )}

                                <div style={{ maxHeight: 138, overflow: "auto" }}>
                                    {flatMap(companies, (company, index) =>
                                        company.id === companiesStore.selectedCompanyId
                                            ? []
                                            : [
                                                  <StyledListItem
                                                      key={company.id}
                                                      className="caption"
                                                      button
                                                      onClick={() => handleMenuCompanyItemClick(company.id)}
                                                  >
                                                      {company.name}
                                                  </StyledListItem>,
                                              ],
                                    )}
                                    {remainingCompanies > 0 && (
                                        <StyledListItem
                                            key={"remainingCompanies"}
                                            className="caption"
                                            button
                                            onClick={handleClickCompanyOverview}
                                        >
                                            {t("companyList.remainingCompanies", { remainingCompanies })}
                                        </StyledListItem>
                                    )}
                                </div>
                                <TpaMenuItem
                                    key={"companyOverview"}
                                    className="caption"
                                    button
                                    onClick={handleClickCompanyOverview}
                                    style={{ borderTop: `1px solid ${customColors.greyLight}` }}
                                >
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <ListItemIcon>
                                            <Icon name="suitcase" />
                                        </ListItemIcon>
                                        {t("common.companyOverview")}
                                    </div>
                                </TpaMenuItem>
                            </Collapse>
                        )}

                        <Divider style={{ marginBottom: 16 }} />
                        {authStore.isStaff && (
                            <SideBarItems
                                items={[
                                    {
                                        iconName: "personnelFile",
                                        title: t("sidebar.list.personnelFile"),
                                        target: Routes.STAFF,
                                        enabled: GLOBAL_FEATURES.staff,
                                        badgeCount:
                                            companiesStore.selectedCompanyStore?.badgeCounts
                                                .openEmployeeDocumentReleases,
                                    },
                                ]}
                                onClickItem={handleCloseMobileMenu}
                            />
                        )}
                        {/* CONNECT-550: do not give full "advisor" access to staff only TPA employees */}
                        {authStore.isTpa && !authStore.isStaffOnly && (
                            <>
                                <AdvisorSideBarItems onClickItem={handleCloseMobileMenu} />
                                <AdvisorDivider title={t("sidebar.customerView")} />
                            </>
                        )}
                        {!authStore.isStaffOnly && <CustomerSideBarItems onClickItem={handleCloseMobileMenu} />}
                        {(!!authStore.canSeeFaceToFaceWidget ||
                            (companiesStore.selectedCompany?.staffCanChat && authStore.isStaffOnly)) && (
                            <>
                                <Divider style={{ marginBottom: 16 }} />
                                <FaceToFaceWidget onClick={handleCloseMobileMenu} style={{ marginBottom: 24 }} />
                            </>
                        )}
                    </>
                )}
            </div>
        </SwipeableDrawer>
    );
});
