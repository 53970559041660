import compact from "lodash/compact";
import { observer } from "mobx-react";
import * as React from "react";
import { POLLING_MIGRATING_PROJECTS_MS } from "../../../config";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { Project } from "../../../network/APITypes";
import { getApiError } from "../../../network/NetworkStapler";
import { HttpStatusCode } from "../../../network/httpStatusCode";
import { authStore } from "../../../stores/AuthStore";
import { companiesStore } from "../../../stores/CompaniesStore";
import { generalStore } from "../../../stores/GeneralStore";
import { useTableStore } from "../../../stores/TableStore";
import { toCapitalizedLowerCase } from "../../../util/helpers";
import { pushRoute, withParams } from "../../app/router/history";
import { MSCloseRefreshState } from "../../auth/sites/AuthMSCloseSite";
import { useCrossTabState } from "../../hooks/useCrossTabState";
import { useInterval } from "../../hooks/useInterval";
import { useProjectPermissions } from "../../hooks/useProjectPermissions";
import { useProjects } from "../../hooks/useProjects";
import { useSuccessDialog } from "../../hooks/useSuccessDialog";
import { CenteredContent } from "../../ui/CenteredContent";
import { ConfirmationDialog } from "../../ui/ConfirmationDialog";
import { ContextMenu, ContextMenuItem, useContextMenu } from "../../ui/ContextMenu";
import { EmptyState } from "../../ui/EmptyState";
import { FormattedMessage } from "../../ui/FormattedMessage";
import { ProjectRow } from "../../ui/ProjectRow";
import { SiteContent } from "../../ui/SiteContent";
import { TableSearchBarWithAction } from "../../ui/TableSearchBar";
import { FinishProjectDialog } from "../FinishProjectDialog";
import { ProjectsRoutes } from "../router/ProjectsRoutes";

export const ProjectsOverviewSite = observer(function ProjectsOverviewSite({
    status = "active",
}: {
    status?: Project.StatusEnum;
}) {
    const contextMenu = useContextMenu<Project>();
    const successDialog = useSuccessDialog({
        title: t("projects.finish.successDialog.title"),
        onClose: () => {
            pushRoute(ProjectsRoutes.FINISHED);
        },
    });

    const [projectToFinish, setProjectToFinish] = React.useState<Project | null>(null);

    const [projectIdToDelete, setProjectIdToDelete] = React.useState<string | null>(null);

    const [projectIdToRestore, setProjectIdToRestore] = React.useState<string | null>(null);

    const tableStore = useTableStore(`ProjectsOverviewSite_${status}`, {
        orderBy: "name",
        orderDir: "asc",
        limit: 0,
    });

    const companyId = companiesStore.selectedCompanyId;

    const { isInitialized, projects, total, getProjectName, reloadProjects } = useProjects({
        companyId,
        status,
        tableParams: tableStore.tableParams,
    });
    tableStore.totalCount = total;

    const [refreshSite, setRefreshSite] = useCrossTabState<MSCloseRefreshState>("refreshProjectOverview", "waiting");

    // Reload site when token was refreshed
    React.useEffect(() => {
        if (refreshSite === "refresh") {
            reloadProjects();
            setRefreshSite("waiting");
        }
    }, [refreshSite, reloadProjects, setRefreshSite]);

    const projectPermissions = useProjectPermissions({ companyId, projects });

    // TPAPORTAL-2308: Poll projects if at least one project is in status "migrating"
    const containsMigratingProject = !!projects.find(p => p.status === "migrating");
    const reloadProjectsIfPending = React.useCallback(() => {
        if (containsMigratingProject) {
            reloadProjects();
        }
    }, [containsMigratingProject, reloadProjects]);
    useInterval(reloadProjectsIfPending, POLLING_MIGRATING_PROJECTS_MS);

    if (!companyId) {
        // No company selected -> get out
        return null;
    }

    const handleClickProject = (projectId: string) => () => {
        pushRoute(withParams(ProjectsRoutes.ACTIVE.PROJECT, { projectId }));
    };

    const handleCloseFinishProjectDialog = () => {
        setProjectToFinish(null);
    };

    const handleSubmitFinishProjectDialog = async (project: Project, archiveId?: string) => {
        try {
            generalStore.isLoading = true;

            await API.putProjectFinished(companyId, project.id, archiveId);
            setProjectToFinish(null);
            successDialog.openDialog();
        } catch (error) {
            generalStore.setError(t("error.archiveProjectFailed"));
        } finally {
            generalStore.isLoading = false;
        }
    };

    if (!isInitialized) {
        return null;
    }

    const getProjectRoleForCurrentUser = (projectId: string) => {
        const users = projectPermissions[projectId];
        return users?.find(user => user.userId === authStore.userId)?.role;
    };

    let contextMenuItems: ContextMenuItem[] = [];
    if (contextMenu.contextElement) {
        const project = contextMenu.contextElement;

        const role = getProjectRoleForCurrentUser(project.id);

        contextMenuItems = compact<ContextMenuItem>([
            {
                title: t("menu.download"),
                icon: "download",
                onClick: async () => {
                    contextMenu.close();
                    try {
                        generalStore.isLoading = true;

                        if (status === "active") {
                            const projectItemsResponse = await API.getProjectItems(companyId, project.id);

                            if (projectItemsResponse.items && projectItemsResponse.items.length > 0) {
                                await API.putDownloadProject(companyId, project.id);
                            } else {
                                generalStore.setError(t("error.projectIsEmpty"));
                            }
                        } else {
                            await API.putDownloadProject(companyId, project.id);
                        }
                    } catch (err) {
                        generalStore.setError(t("error.download"), err);
                    } finally {
                        generalStore.isLoading = false;
                    }
                },
                "data-id": "context_menu_download",
            },
            project.status === "active" &&
                authStore.isTpa &&
                role === "owner" && {
                    title: t("menu.edit"),
                    icon: "pen",
                    onClick: () => {
                        contextMenu.close();
                        pushRoute(withParams(ProjectsRoutes.EDIT_PROJECT, { projectId: project.id }));
                    },
                    "data-id": "context_menu_edit",
                },

            project.status === "active" &&
                authStore.isTpa && {
                    title: t("menu.openInSharepoint"),
                    icon: "open",
                    onClick: () => {
                        contextMenu.close();
                        window.open(project.webUrl, "_blank");
                    },
                    "data-id": "context_menu_open",
                },
            project.status === "active" &&
                authStore.isAdvisor &&
                role === "owner" && {
                    title: t("menu.finish"),
                    icon: "finished",
                    onClick: () => {
                        contextMenu.close();
                        setProjectToFinish(project);
                    },
                    "data-id": "context_menu_finish",
                },
            project.status === "active" &&
                authStore.canDeleteProjects &&
                role === "owner" && {
                    title: t("menu.delete"),
                    icon: "archive",
                    onClick: () => {
                        contextMenu.close();
                        setProjectIdToDelete(project.id);
                    },
                    "data-id": "context_menu_delete",
                },
            project.status === "closed" &&
                !project.cleanUpPerformedAt &&
                authStore.canCreateProjects &&
                role === "owner" && {
                    title: toCapitalizedLowerCase(t("projects.activate")),
                    icon: "pen",
                    onClick: () => {
                        contextMenu.close();
                        setProjectIdToRestore(project.id);
                    },
                    "data-id": "context_menu_restore",
                },
        ]);
    }

    const handleCloseDeleteProjectDialog = async () => {
        if (!projectIdToDelete) {
            return;
        }

        try {
            await API.deleteProject(companyId, projectIdToDelete);
            pushRoute(ProjectsRoutes.DELETED);
        } catch (error) {
            const apiError = getApiError(error);
            if (apiError?.statusCode === HttpStatusCode.Forbidden_403) {
                generalStore.setError(t("error.forbidden"), error);
            } else {
                generalStore.setError(t("error.delete"), error);
            }
        }

        setProjectIdToDelete(null);
    };

    const handleClickProjectRestore = (projectId: string) => {
        setProjectIdToRestore(projectId);
    };

    const handleCloseRestoreProjectDialog = async () => {
        if (!projectIdToRestore) {
            return;
        }
        try {
            await API.restoreProject(companyId, projectIdToRestore);
            setProjectIdToRestore(null);
            pushRoute(ProjectsRoutes.ACTIVE.ROOT);
        } catch (error) {
            const apiError = getApiError(error);
            if (apiError?.statusCode === HttpStatusCode.Forbidden_403) {
                generalStore.setError(t("error.forbidden"), error);
            } else {
                generalStore.setError(t("error.activate"), error);
            }
        }
    };

    const empty = tableStore.getIsEmptyState(generalStore.isLoading);
    const noResult = tableStore.getIsNoResultState(generalStore.isLoading);

    const emptyStatePropsByStatus: { [status in Project.StatusEnum]: React.ComponentProps<typeof EmptyState> } = {
        active: {
            title: t("projects.emptystate.title"),
            buttonLabel: t("projects.active.emptystate.buttonlabel"),
            message: t("projects.active.emptystate.message"),
        },
        closed: {
            title: t("projects.emptystate.title"),
            message: t("projects.closed.emptystate.message"),
        },
        deleted: {
            title: t("projects.emptystate.title"),
            message: t("projects.deleted.emptystate.message"),
        },
        migrating: { title: "", message: "" }, // dummy, not a site since migrating are active projects
    };

    return (
        <>
            {empty && <EmptyState {...emptyStatePropsByStatus[status]} />}
            {!empty && (
                <>
                    <CenteredContent>
                        <SiteContent>
                            <TableSearchBarWithAction
                                label="search.caption.numProjects"
                                placeholder="search.placeholder.projects"
                                search={tableStore.search}
                                totalCount={tableStore.totalCount}
                                onChangeSearch={tableStore.handleSearchChange}
                            />
                            <div
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-evenly",
                                    marginTop: 24,
                                }}
                            >
                                <div style={{ alignSelf: "center", width: "100%" }}>
                                    {noResult && (
                                        <EmptyState
                                            title={t("table.noResults.title")}
                                            message={t("table.noResults.message")}
                                        />
                                    )}
                                    {!noResult &&
                                        projects.map((project, index) => (
                                            <ProjectRow
                                                key={project.id}
                                                project={project}
                                                role={getProjectRoleForCurrentUser(project.id)}
                                                permissionUsers={projectPermissions[project.id]}
                                                onClick={handleClickProject(project.id)}
                                                onClickMenu={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                    contextMenu.open(event, project);
                                                }}
                                                onClickRestore={handleClickProjectRestore}
                                                data-id={`projects_${index}`}
                                            />
                                        ))}
                                </div>
                            </div>
                        </SiteContent>
                    </CenteredContent>
                    <ContextMenu
                        data-id="context_menu"
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        config={contextMenu}
                        items={contextMenuItems}
                    />
                    {projectToFinish && (
                        <FinishProjectDialog
                            open
                            onClose={handleCloseFinishProjectDialog}
                            onSubmit={handleSubmitFinishProjectDialog}
                            project={projectToFinish}
                        />
                    )}

                    {projectIdToDelete && (
                        <ConfirmationDialog
                            open={!!projectIdToDelete}
                            title={t("projects.deleteDialog.title.project")}
                            message={
                                <FormattedMessage
                                    id="projects.deleteDialog.message.project"
                                    values={{ name: getProjectName(projectIdToDelete) }}
                                />
                            }
                            onConfirm={handleCloseDeleteProjectDialog}
                            onCancel={() => {
                                setProjectIdToDelete(null);
                            }}
                        />
                    )}
                    {projectIdToRestore && (
                        <ConfirmationDialog
                            open={!!projectIdToRestore}
                            title={t("projects.restoreDialog.title.project")}
                            message={
                                <FormattedMessage
                                    id="projects.restoreDialog.message.project"
                                    values={{ name: getProjectName(projectIdToRestore) }}
                                />
                            }
                            onConfirm={handleCloseRestoreProjectDialog}
                            onCancel={() => {
                                setProjectIdToRestore(null);
                            }}
                        />
                    )}
                    {successDialog.dialog}
                </>
            )}
        </>
    );
});
