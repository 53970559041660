import compact from "lodash/compact";
import { observer } from "mobx-react";
import { t } from "../../i18n/util";
import { ProjectItemPermission, ProjectPermissionRequest } from "../../network/APITypes";
import { authStore } from "../../stores/AuthStore";
import { ContextMenu, ContextMenuItem, useContextMenu } from "../ui/ContextMenu";
import { User } from "../ui/User";
import { Icon } from "../util/Icon";
import { customColors } from "../util/Theme";

interface IPermissionOption {
    value: ProjectItemPermission.RoleEnum;
    label: string;
    description?: string;
    disabled?: boolean;
}

const getPermissionOptions = (disableOwner = true): IPermissionOption[] =>
    compact([
        {
            value: "owner",
            label: t("projectItemPermission.owner"),
            description: t("projectItemPermission.owner.description"),
            disabled: disableOwner,
        },
        {
            value: "write",
            label: t("projectItemPermission.write"),
            description: t("projectItemPermission.write.description"),
        },
        {
            value: "read",
            label: t("projectItemPermission.read"),
            description: t("projectItemPermission.read.description"),
        },
    ]);

const PermissionMenu = observer(function PermissionMenu({
    value,
    onChange,
    onClickRemove,
    disabled,
    disableOwner,
    userId,
}: {
    value: ProjectItemPermission.RoleEnum;
    onChange?: (value: ProjectItemPermission.RoleEnum) => void;
    onClickRemove?: () => void;
    disabled?: boolean;
    disableOwner?: boolean;
    userId?: string;
}) {
    const contextMenu = useContextMenu<ProjectPermissionRequest | "DEFAULT_PERMISSION">();

    // disable delete for current user
    const disableDelete = authStore.userId === userId;

    return (
        <>
            <div
                role="button"
                onClick={
                    disabled
                        ? undefined
                        : (event: React.MouseEvent<HTMLDivElement>) => {
                              contextMenu.open(event, "DEFAULT_PERMISSION");
                          }
                }
                style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: disabled ? undefined : "pointer",
                    color: disabled ? customColors.greyTextfields : undefined,
                }}
            >
                <span>{getPermissionOptions().find(option => option.value === value)?.label}</span>
                <div>
                    <Icon
                        name={contextMenu.isOpen ? "dropUp" : "dropDown"}
                        style={{
                            display: "block",
                            color: disabled ? undefined : customColors.primaryColor,
                            marginLeft: 4,
                        }}
                    />
                </div>
            </div>
            <ContextMenu
                config={contextMenu}
                data-id="project_item_permission_context_menu"
                maxWidth={272}
                items={[
                    ...getPermissionOptions(disableOwner).map(option => ({
                        "data-id": `context_menu_${option.value}`,
                        title: option.label,
                        description: option.description,
                        disabled: option.disabled,
                        onClick: () => {
                            onChange?.(option.value);
                            contextMenu.close();
                        },
                    })),
                    ...(onClickRemove
                        ? ([
                              {
                                  "data-id": "context_menu_delete",
                                  title: t("projects.new.permissionContextMenu.remove"),
                                  icon: "delete",
                                  borderTop: true,
                                  disabled: disableDelete,
                                  onClick: () => {
                                      onClickRemove();
                                      contextMenu.close();
                                  },
                              },
                          ] satisfies ContextMenuItem[])
                        : []),
                ]}
            />
        </>
    );
});

export const ProjectUserPermissionLine = ({
    firstName,
    lastName,
    profileImageUrl,
    isTpa,
    needsRelease,
    permissionDisabled,
    permission,
    userId,
    onChangePermission,
    onRemove,
}: {
    firstName: string;
    lastName: string;
    profileImageUrl?: string;
    isTpa: boolean;
    needsRelease?: boolean;
    permissionDisabled?: boolean;
    permission: ProjectItemPermission.RoleEnum;
    userId?: string;
    onChangePermission?: (value: ProjectItemPermission.RoleEnum) => void;
    onRemove?: () => void;
}) => {
    return (
        <div
            style={{
                height: 64,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
            }}
        >
            <div style={{ display: "flex", alignItems: "center" }}>
                <User
                    // key={user.id}
                    showAvatarOnly
                    size={40}
                    firstName={firstName}
                    lastName={lastName}
                    imageUrl={profileImageUrl}
                    isTpaStaff={isTpa}
                    avatarStyle={{ opacity: needsRelease ? 0.2 : 1 }}
                />
                <p
                    style={{ marginLeft: 12, color: needsRelease ? customColors.greyTextfields : undefined }}
                >{`${firstName} ${lastName}`}</p>
                {needsRelease && (
                    <span style={{ color: customColors.greyTextfields }}>
                        &nbsp;({t("config.users.waiting.title")})
                    </span>
                )}
            </div>
            <div style={{ marginLeft: 16 }}>
                <PermissionMenu
                    disabled={permissionDisabled}
                    value={permission}
                    onChange={onChangePermission}
                    onClickRemove={onRemove}
                    disableOwner={!isTpa}
                    userId={userId}
                />
            </div>
        </div>
    );
};
