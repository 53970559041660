import { Button } from "@material-ui/core";
import { DEFAULT_STAFF_CAN_READ } from "../../config";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { EmployeeDocument, EmployeeDocumentType } from "../../network/APITypes";
import { companiesStore } from "../../stores/CompaniesStore";
import { generalStore } from "../../stores/GeneralStore";
import { ViewerFile, viewerStore } from "../../stores/ViewerStore";
import { DocumentLine } from "../ui/DocumentLine/DocumentLine";
import { IUpload } from "../ui/DocumentUpload";
import {
    DocumentUploadSmall,
    IAcceptedFile,
    OnUpload,
    UploadFile,
    useDocumentUploadSmall,
} from "../ui/DocumentUploadSmall";
import { hrStore } from "./HrStore";

export const HrDocumentsForm = (props: {
    onSubmit: () => void;
    onUploaded?: (count: number) => void;
    onDeleted?: () => void;
    buttonText: string;
    employeeId?: string;
    isPreRegistration?: boolean;
    subsidiaryId?: string;
    isStaffUpload?: boolean;
}) => {
    const companyId = companiesStore.selectedCompanyId;
    const subsidiaryId = props.subsidiaryId ?? hrStore.selectedSubsidiaryId;

    const createFileHandler = (type: EmployeeDocumentType) => async (file: IAcceptedFile) => {
        if (props.employeeId) {
            return API.postEmployeeDocument({
                companyId,
                employeeId: props.employeeId,
                subsidiaryId,
                file: file.file,
                type,
                staffCanRead: props.isStaffUpload ? true : DEFAULT_STAFF_CAN_READ,
                isPreRegistration: props.isPreRegistration,
            });
        }
    };

    const fields = [
        { title: "screen.hr.documents.contract", handler: createFileHandler("contractOfEmployment") },
        { title: "screen.hr.documents.citizenship", handler: createFileHandler("certificateOfCitizenship") },
        { title: "screen.hr.documents.idcard", handler: createFileHandler("identityCard") },
        {
            title: "screen.hr.documents.registrationslip",
            handler: createFileHandler("certificateOfRegistration"),
        },
        { title: "screen.hr.documents.others", handler: createFileHandler("other") },
    ] as const;

    const handleView = (upload: IUpload<EmployeeDocument>) => {
        const files = [upload].map<ViewerFile>(u => ({
            id: u.response.id,
            name: u.file.name,
            src: u.file,
        }));
        viewerStore.open(files, upload.response.id);
    };

    const renderDocumentLine = (upload: IUpload<EmployeeDocument>, onDeleted: () => void) => {
        const onDelete = async () => {
            if (props.employeeId) {
                await API.deleteEmployeeDocument(
                    companyId,
                    subsidiaryId,
                    upload.response.id,
                    props.employeeId,
                    props.isPreRegistration,
                );
                onDeleted();
                if (props.onDeleted) {
                    props.onDeleted();
                }
            }
        };

        const handleChangeVisibleToEmployee = async (visible: boolean) => {
            if (!companyId || !subsidiaryId || !props.employeeId) {
                return;
            }

            try {
                await API.patchEmployeeDocument({
                    companyId,
                    subsidiaryId,
                    employeeId: props.employeeId,
                    documentId: upload.response.id,
                    isPreRegistration: props.isPreRegistration,
                    staffCanRead: visible,
                });
            } catch (error) {
                generalStore.setError(t("error.general"), error);
            }
        };

        return (
            <DocumentLine
                switchLabel={props.isStaffUpload ? undefined : t("screen.advisor.uploads.upload.visibleToEmployee")}
                onCheck={props.isStaffUpload ? undefined : handleChangeVisibleToEmployee}
                defaultChecked={props.isStaffUpload ? undefined : DEFAULT_STAFF_CAN_READ}
                fileName={upload.file.name}
                onView={() => {
                    handleView(upload);
                }}
                onDelete={props.isStaffUpload ? undefined : onDelete}
                key={upload.file.name}
            />
        );
    };

    return (
        <>
            {fields.map((field, index) => (
                <DocumentUploadSmallField
                    key={index}
                    title={field.title}
                    uploadFile={field.handler}
                    renderSuccess={renderDocumentLine}
                    data-id={`document_upload_${index}`}
                    onUpload={success => {
                        if (props.onUploaded) {
                            props.onUploaded(success.length);
                        }
                    }}
                />
            ))}

            <div style={{ textAlign: "right", marginTop: 40 }}>
                <Button data-id="documents_submit" variant="contained" color="primary" onClick={props.onSubmit}>
                    {props.buttonText}
                </Button>
            </div>
        </>
    );
};

const DocumentUploadSmallField = (
    props: Omit<React.ComponentProps<typeof DocumentUploadSmall<EmployeeDocument, IAcceptedFile>>, "config"> & {
        uploadFile: UploadFile<EmployeeDocument, IAcceptedFile>;
        onUpload: OnUpload<EmployeeDocument, IAcceptedFile>;
    },
) => {
    const config = useDocumentUploadSmall(props.uploadFile, false, props.onUpload);
    return (
        <DocumentUploadSmall
            config={config}
            title={props.title}
            renderSuccess={props.renderSuccess}
            data-id={props["data-id"]}
        />
    );
};
