import { observer } from "mobx-react";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { EmployeeDocument } from "../../network/APITypes";
import { viewerStore } from "../../stores/ViewerStore";
import { hrStore } from "../hr/HrStore";
import { Icon } from "../util/Icon";
import { customColors } from "../util/Theme";
import { DocumentDownloadLine } from "./DocumentLine/DocumentLine";

export const DocumentsSummary = observer(function DocumentsSummary({
    companyId,
    subsidiaryId,
    employeeId,
    isPreRegistration,
    onEdit,
    documents,
}: {
    companyId: string;
    subsidiaryId: string;
    employeeId: string;
    isPreRegistration: boolean;
    onEdit?: () => void;
    documents: EmployeeDocument[];
}) {
    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: 16,
                    alignItems: "center",
                }}
            >
                <h3>{t("screen.hr.summary.documents")}</h3>
                {onEdit && (
                    <div>
                        <Icon
                            name="pen"
                            style={{ color: customColors.primaryColor, cursor: "pointer", display: "block" }}
                            onClick={onEdit}
                            role="button"
                            data-id="edit_documents"
                        />
                    </div>
                )}
            </div>
            {documents.map((document: EmployeeDocument, index: number) => {
                return (
                    <DocumentDownloadLine
                        key={index}
                        fileName={document.document.name}
                        documentId={document.document.id}
                        style={{ marginBottom: 24 }}
                        onView={() => {
                            const files = documents.map(doc => {
                                return {
                                    id: doc.id,
                                    name: doc.document.name,
                                    src: () => {
                                        return API.getEmployeeDocumentDownloadUrl(
                                            companyId,
                                            "hr",
                                            subsidiaryId,
                                            employeeId,
                                            isPreRegistration,
                                            doc.id,
                                        );
                                    },
                                    download: () => {
                                        return API.putDownloadEmployeeDocuments(
                                            companyId,
                                            "hr",
                                            subsidiaryId,
                                            employeeId,
                                            isPreRegistration,
                                            [doc.id],
                                        );
                                    },
                                };
                            });
                            viewerStore.open(files, document.id);
                        }}
                        onDownload={() =>
                            hrStore.downloadEmployeeFiles({
                                employeeId,
                                isPreRegistration,
                                documentIds: [document.id],
                            })
                        }
                    />
                );
            })}
        </div>
    );
});
