import { Button, Checkbox, Divider, FormControlLabel } from "@material-ui/core";
import compact from "lodash/compact";
import { observer } from "mobx-react";
import React from "react";
import styled from "styled-components";
import { GLOBAL_FEATURES } from "../../../features";
import { IMessageIDS, t } from "../../../i18n/util";
import { Permission, Role, UserNotifications } from "../../../network/APITypes";
import { authStore } from "../../../stores/AuthStore";
import { companiesStore } from "../../../stores/CompaniesStore";
import { CompanyStore } from "../../../stores/CompanyStore";
import { hasAnySubsidiaryWithGroupActions } from "../../../util/permissionHelpers";
import { EndsWith, Replace, StartsWith } from "../../../util/ts";
import { useUserNotifications } from "../../hooks/useUserNotifications";
import { CenteredContent } from "../../ui/CenteredContent";
import { IOSSwitch } from "../../ui/IOSSwitch";
import { SiteContent } from "../../ui/SiteContent";
import { DIALOG_WIDTH } from "../../util/Theme";
import { SettingsNavBar } from "../SettingsNavBar";

type PermissionChecker = (companyStore: CompanyStore) => boolean;

function oneOf(fns: PermissionChecker[]): PermissionChecker {
    return companyStore => fns.some(fn => fn(companyStore));
}
function hasGlobalPermission(group: Permission.GroupEnum, ...actions: Permission.ActionsEnum[]): PermissionChecker {
    return companyStore => companyStore.permissions.hasGlobalActions(group, actions);
}
function hasSubsidiaryPermission(group: Permission.GroupEnum, ...actions: Permission.ActionsEnum[]): PermissionChecker {
    return companyStore => hasAnySubsidiaryWithGroupActions(companyStore.permissions.raw, group, actions);
}
function isAdvisor(companyStore: CompanyStore): boolean {
    return companyStore.permissions.isAdvisor;
}
function hasRole(role: Role): PermissionChecker {
    return companyStore => !!companyStore.permissions.raw?.roles.includes(role);
}

type AllKeys = keyof UserNotifications;

// all "notify" keys that can be set
// e.g. "notifyMessages", "notifyTicketsAccounting"
type NotifyKey = StartsWith<AllKeys, "notify">;
// all "reminder" keys that can be set
// e.g. "reminderMessages", "reminderTicketsAccounting"
type ReminderKey = StartsWith<AllKeys, "reminder">;
// Keys that require a group (excluding reminder groups)
// e.g. "notifyDeadlineExceededGroup"
type GroupKey = Replace<Exclude<EndsWith<AllKeys, "Other">, ReminderKey>, "Other", "Group">;

interface ISingleNotificationSetting {
    key: NotifyKey;
    label: IMessageIDS;
    // TODO: Enable reminderKey when BE is ready (--> decision from TPA Call: we don't need the reminderKey switches
    // https://itstpa.atlassian.net/wiki/spaces/TP/pages/365985918/Chat%2BF2F%2BEmails%2BBenachrichtigungen)
    reminderKey?: ReminderKey; // used for enabling a reminder switch -> not used for now
    isVisibleByPermissions?: PermissionChecker;
}

interface IGroupedNotificationSetting {
    key: GroupKey;
    label: IMessageIDS;
    children: INotificationSettings;
}

type INotificationSetting = ISingleNotificationSetting | IGroupedNotificationSetting;
type INotificationSettings = INotificationSetting[];

const tpaEmployeeNotificationSettings: INotificationSettings = [
    ...(GLOBAL_FEATURES.additionalNotificationSettings
        ? ([{ label: "settings.notifications.assignments", key: "notifyAssigned" }] satisfies INotificationSettings)
        : []),
    {
        label: "settings.notifications.tickets",
        key: "notifyTicketsGroup",
        children: [
            {
                label: "settings.notifications.modules.accounting",
                key: "notifyTicketsAccounting",
                /* reminderKey: "reminderTicketsAccounting" */
                isVisibleByPermissions: oneOf([
                    hasGlobalPermission("accounting:company:reports", "create"),
                    hasGlobalPermission("accounting:company:reports", "ticketRead"),
                    hasSubsidiaryPermission("accounting:records", "create"),
                    hasSubsidiaryPermission("accounting:records", "ticketRead"),
                    hasSubsidiaryPermission("accounting:reports", "create"),
                    hasSubsidiaryPermission("accounting:reports", "ticketRead"),
                ]),
            },
            {
                label: "settings.notifications.modules.hr",
                key: "notifyTicketsHR",
                /* reminderKey: "reminderTicketsHR" */
                isVisibleByPermissions: oneOf([
                    hasGlobalPermission("hr:company:reports", "create"),
                    hasGlobalPermission("hr:company:reports", "ticketRead"),
                    hasSubsidiaryPermission("hr:personnel", "create"), // pre-registration ticket
                    hasSubsidiaryPermission("hr:personnel", "ticketRead"), // pre-registration ticket
                    hasSubsidiaryPermission("hr:records", "create"),
                    hasSubsidiaryPermission("hr:records", "ticketRead"),
                    hasSubsidiaryPermission("hr:reports", "create"),
                    hasSubsidiaryPermission("hr:reports", "ticketRead"),
                ]),
            },
            // CONNECT-25: "other" is not needed for now
            // {
            //     label: "settings.notifications.modules.other",
            //     key: "notifyTicketsOther",
            //     /* reminderKey: "reminderTicketsOther" */
            // },
        ],
    },
    { label: "settings.notifications.messages", key: "notifyMessages" /* reminderKey: "reminderMessages" */ },
    {
        label: "settings.notifications.recordProvision",
        key: "notifyRecordProvisionedGroup",
        children: [
            {
                label: "settings.notifications.modules.accounting",
                key: "notifyRecordProvisionedAccounting",
                isVisibleByPermissions: oneOf([isAdvisor, hasRole("tpa-accounting")]),
            },
            {
                label: "settings.notifications.modules.hr",
                key: "notifyRecordProvisionedHR",
                isVisibleByPermissions: oneOf([isAdvisor, hasRole("tpa-hr")]),
            },
            // CONNECT-25: "other" is not needed for now
            // { label: "settings.notifications.modules.other", key: "notifyRecordProvisionedOther" },
        ],
    },
    {
        label: "settings.notifications.release",
        key: "notifyReleaseGroup",
        children: [
            {
                label: "settings.notifications.modules.accounting",
                key: "notifyReleaseAccounting",
                isVisibleByPermissions: oneOf([
                    hasGlobalPermission("accounting:company:reports", "create"),
                    hasSubsidiaryPermission("accounting:reports", "create"),
                ]),
            },
            {
                label: "settings.notifications.modules.hr",
                key: "notifyReleaseHR",
                isVisibleByPermissions: oneOf([
                    hasGlobalPermission("hr:company:reports", "create"),
                    hasSubsidiaryPermission("hr:reports", "create"),
                    oneOf([isAdvisor, hasRole("tpa-hr")]), // employee documents
                ]),
            },
            // CONNECT-25: "other" is not needed for now
            // { label: "settings.notifications.modules.other", key: "notifyReleaseOther" },
        ],
    },
    {
        label: "settings.notifications.project.items.uploaded",
        key: "notifyProjectItemsUploaded",
        isVisibleByPermissions: hasGlobalPermission("projects", "read"),
    },
    {
        label: "settings.notifications.deadlineReminder",
        key: "notifyDeadlineReminderGroup",
        children: [
            {
                label: "settings.notifications.modules.accounting",
                key: "notifyDeadlineReminderAccounting",
                isVisibleByPermissions: oneOf([
                    hasGlobalPermission("accounting:company:reports", "create"),
                    hasGlobalPermission("accounting:company:reports", "release"),
                    hasSubsidiaryPermission("accounting:records", "create"),
                    hasSubsidiaryPermission("accounting:reports", "create"),
                    hasSubsidiaryPermission("accounting:reports", "release"),
                ]),
            },
            {
                label: "settings.notifications.modules.hr",
                key: "notifyDeadlineReminderHR",
                isVisibleByPermissions: oneOf([
                    hasGlobalPermission("hr:company:reports", "create"),
                    hasGlobalPermission("hr:company:reports", "release"),
                    hasSubsidiaryPermission("hr:records", "create"),
                    hasSubsidiaryPermission("hr:reports", "create"),
                    hasSubsidiaryPermission("hr:reports", "release"),
                ]),
            },
            // CONNECT-25: "other" is not needed for now
            // { label: "settings.notifications.modules.other", key: "notifyDeadlineReminderOther" },
        ],
    },
    {
        label: "settings.notifications.deadlineExceeded",
        key: "notifyDeadlineExceededGroup",
        children: [
            {
                label: "settings.notifications.modules.accounting",
                key: "notifyDeadlineExceededAccounting",
                isVisibleByPermissions: oneOf([
                    hasGlobalPermission("accounting:company:reports", "create"),
                    hasGlobalPermission("accounting:company:reports", "release"),
                    hasSubsidiaryPermission("accounting:records", "create"),
                    hasSubsidiaryPermission("accounting:records", "ticketRead"),
                    hasSubsidiaryPermission("accounting:reports", "create"),
                    hasSubsidiaryPermission("accounting:reports", "release"),
                ]),
            },
            {
                label: "settings.notifications.modules.hr",
                key: "notifyDeadlineExceededHR",
                isVisibleByPermissions: oneOf([
                    hasGlobalPermission("hr:company:reports", "create"),
                    hasGlobalPermission("hr:company:reports", "release"),
                    hasGlobalPermission("hr:company:reports", "ticketRead"),
                    hasGlobalPermission("hr:personnel", "ticketRead"), // employee document tickets
                    hasSubsidiaryPermission("hr:records", "create"),
                    hasSubsidiaryPermission("hr:records", "ticketRead"),
                    hasSubsidiaryPermission("hr:reports", "create"),
                    hasSubsidiaryPermission("hr:reports", "release"),
                    hasSubsidiaryPermission("hr:reports", "ticketRead"),
                ]),
            },
            // CONNECT-25: "other" is not needed for now
            // { label: "settings.notifications.modules.other", key: "notifyDeadlineExceededOther" },
        ],
    },
];

const customerNotificationSettings: INotificationSettings = [
    ...(GLOBAL_FEATURES.additionalNotificationSettings
        ? ([{ label: "settings.notifications.assignments", key: "notifyAssigned" }] satisfies INotificationSettings)
        : []),
    {
        label: "settings.notifications.tickets",
        key: "notifyTicketsGroup",
        children: [
            {
                label: "settings.notifications.modules.accounting",
                key: "notifyTicketsAccounting",
                isVisibleByPermissions: oneOf([
                    hasGlobalPermission("accounting:company:reports", "create"),
                    hasGlobalPermission("accounting:company:reports", "ticketRead"),
                    hasSubsidiaryPermission("accounting:records", "create"),
                    hasSubsidiaryPermission("accounting:records", "ticketRead"),
                    hasSubsidiaryPermission("accounting:reports", "create"),
                    hasSubsidiaryPermission("accounting:reports", "ticketRead"),
                ]),
            },
            {
                label: "settings.notifications.modules.hr",
                key: "notifyTicketsHR",
                isVisibleByPermissions: oneOf([
                    hasGlobalPermission("hr:company:reports", "create"),
                    hasGlobalPermission("hr:company:reports", "ticketRead"),
                    hasSubsidiaryPermission("hr:personnel", "create"), // pre-registration ticket
                    hasSubsidiaryPermission("hr:personnel", "ticketRead"), // pre-registration ticket
                    hasSubsidiaryPermission("hr:records", "create"),
                    hasSubsidiaryPermission("hr:records", "ticketRead"),
                    hasSubsidiaryPermission("hr:reports", "create"),
                    hasSubsidiaryPermission("hr:reports", "ticketRead"),
                ]),
            },
            // CONNECT-25: "other" is not needed for now
            // { label: "settings.notifications.modules.other", key: "notifyTicketsOther" },
        ],
    },
    { label: "settings.notifications.messages", key: "notifyMessages" },
    {
        label: "settings.notifications.reportProvision",
        key: "notifyReportProvisionedGroup",
        children: [
            {
                label: "settings.notifications.modules.accounting",
                key: "notifyReportProvisionedAccounting",
                isVisibleByPermissions: oneOf([
                    hasGlobalPermission("accounting:company:reports", "release"),
                    hasSubsidiaryPermission("accounting:reports", "release"),
                ]),
            },
            {
                label: "settings.notifications.modules.hr",
                key: "notifyReportProvisionedHR",
                isVisibleByPermissions: oneOf([
                    hasGlobalPermission("hr:company:reports", "release"),
                    hasSubsidiaryPermission("hr:reports", "release"),
                    // this setting is also used for employee document uploads by TPA employees
                    hasSubsidiaryPermission("hr:personnel", "read"), // the HR personnel should be notified
                    companyStore => companyStore.permissions.isStaff, // and the employee should be notified
                ]),
            },
            // CONNECT-25: "other" is not needed for now
            // { label: "settings.notifications.modules.other", key: "notifyReportProvisionedOther" },
        ],
    },
    {
        label: "settings.notifications.release",
        key: "notifyProjectDraft",
        isVisibleByPermissions: hasGlobalPermission("projects", "read"),
    },
    {
        label: "settings.notifications.project.items.uploaded",
        key: "notifyProjectItemsUploaded",
        isVisibleByPermissions: hasGlobalPermission("projects", "read"),
    },
    {
        label: "settings.notifications.deadlineReminder",
        key: "notifyDeadlineReminderGroup",
        children: [
            {
                label: "settings.notifications.modules.accounting",
                key: "notifyDeadlineReminderAccounting",
                isVisibleByPermissions: oneOf([
                    hasGlobalPermission("accounting:company:reports", "create"),
                    hasGlobalPermission("accounting:company:reports", "release"),
                    hasSubsidiaryPermission("accounting:records", "create"),
                    hasSubsidiaryPermission("accounting:reports", "create"),
                    hasSubsidiaryPermission("accounting:reports", "release"),
                ]),
            },
            {
                label: "settings.notifications.modules.hr",
                key: "notifyDeadlineReminderHR",
                isVisibleByPermissions: oneOf([
                    hasGlobalPermission("hr:company:reports", "create"),
                    hasGlobalPermission("hr:company:reports", "release"),
                    hasSubsidiaryPermission("hr:records", "create"),
                    hasSubsidiaryPermission("hr:reports", "create"),
                    hasSubsidiaryPermission("hr:reports", "release"),
                ]),
            },
            // CONNECT-25: "other" is not needed for now
            // { label: "settings.notifications.modules.other", key: "notifyDeadlineReminderOther" },
        ],
    },
    {
        label: "settings.notifications.deadlineExceeded",
        key: "notifyDeadlineExceededGroup",
        children: [
            {
                label: "settings.notifications.modules.accounting",
                key: "notifyDeadlineExceededAccounting",
                isVisibleByPermissions: oneOf([
                    hasGlobalPermission("accounting:company:reports", "create"),
                    hasGlobalPermission("accounting:company:reports", "release"),
                    hasSubsidiaryPermission("accounting:records", "create"),
                    hasSubsidiaryPermission("accounting:records", "ticketRead"),
                    hasSubsidiaryPermission("accounting:reports", "create"),
                    hasSubsidiaryPermission("accounting:reports", "release"),
                ]),
            },
            {
                label: "settings.notifications.modules.hr",
                key: "notifyDeadlineExceededHR",
                isVisibleByPermissions: oneOf([
                    hasGlobalPermission("hr:company:reports", "create"),
                    hasGlobalPermission("hr:company:reports", "release"),
                    hasGlobalPermission("hr:company:reports", "ticketRead"),
                    hasGlobalPermission("hr:personnel", "ticketRead"), // employee document tickets
                    hasSubsidiaryPermission("hr:records", "create"),
                    hasSubsidiaryPermission("hr:records", "ticketRead"),
                    hasSubsidiaryPermission("hr:reports", "create"),
                    hasSubsidiaryPermission("hr:reports", "release"),
                    hasSubsidiaryPermission("hr:reports", "ticketRead"),
                ]),
            },
            // CONNECT-25: "other" is not needed for now
            // { label: "settings.notifications.modules.other", key: "notifyDeadlineExceededOther" },
        ],
    },
    {
        label: "settings.notifications.generalDocumentUpload",
        key: "notifyGeneralDocumentUpload",
        isVisibleByPermissions: companyStore => companyStore.canReadAnyGeneralDocuments,
    },
];

export const SettingsGeneralSite = observer(function SettingsGeneralSite() {
    const userNotifications = useUserNotifications();
    const { notifications } = userNotifications;

    let notificationSettings = authStore.isTpa ? tpaEmployeeNotificationSettings : customerNotificationSettings;

    // If you don't have accounting or hr -> disable corresponding settings

    const company = companiesStore.selectedCompany;
    if (!company?.hasAccounting) {
        notificationSettings = filterNotificationSettings(notificationSettings, ns => ns.key.endsWith("Accounting"));
    }
    if (!company?.hasHR) {
        notificationSettings = filterNotificationSettings(notificationSettings, ns => ns.key.endsWith("HR"));
    }

    // If company has no general document upload -> disable corresponding settings
    if (!company?.generalDocumentsEnabled) {
        notificationSettings = filterNotificationSettings(
            notificationSettings,
            ns => ns.key === "notifyGeneralDocumentUpload",
        );
    }

    const companyStore = companiesStore.selectedCompanyStore;
    notificationSettings = filterNotificationSettings(notificationSettings, ns => {
        if (!ns.isVisibleByPermissions) {
            return false; // no permissions check, always visible
        }
        if (!companyStore) {
            return true;
        }
        return !ns.isVisibleByPermissions(companyStore);
    });

    const handleChangeNotificationActive = (newNotifications: UserNotifications) => {
        userNotifications.patch(newNotifications);
    };

    const handleClickSetAll = (active: boolean) => {
        if (notifications) {
            const newNotifications: UserNotifications = { ...notifications };

            updateAll(newNotifications, notificationSettings, active);

            userNotifications.patch(newNotifications);
        }
    };

    const someActive = someNotificationsActive(notifications, notificationSettings);
    const allActive = allNotificationsActive(notifications, notificationSettings);

    return (
        <>
            <SettingsNavBar />
            {userNotifications.isInitialized && (
                <CenteredContent>
                    <SiteContent>
                        <div style={{ maxWidth: DIALOG_WIDTH }}>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <h3 style={{ wordBreak: "break-all" }}>{t("settings.notifications.title")}</h3>
                                <Button
                                    color="primary"
                                    size="small"
                                    style={{ marginLeft: 16 }}
                                    onClick={() => {
                                        handleClickSetAll(someActive ? false : !allActive);
                                    }}
                                >
                                    {someActive
                                        ? t("settings.notifications.deactivateAll")
                                        : t("settings.notifications.activateAll")}
                                </Button>
                            </div>
                            <p
                                style={{
                                    marginTop: 24,
                                    marginBottom: 16,
                                }}
                            >
                                {t("settings.notifications.label")}
                            </p>
                            {notificationSettings.map(ns => {
                                return (
                                    <React.Fragment key={ns.key}>
                                        <NotificationSetting
                                            ns={ns}
                                            notifications={notifications}
                                            onChange={handleChangeNotificationActive}
                                        />
                                        <Divider />
                                    </React.Fragment>
                                );
                            })}
                        </div>
                    </SiteContent>
                </CenteredContent>
            )}
        </>
    );
});

const GroupedChildren = styled.div`
    padding-left: 32px;
`;
interface GroupedNotificationSettingProps {
    notifications: UserNotifications | null;
    ns: IGroupedNotificationSetting;
    onChange: (newNotifications: UserNotifications) => void;
}
const GroupedNotificationSetting = (props: GroupedNotificationSettingProps) => {
    const { notifications, ns, onChange } = props;
    const { label, children } = ns;

    const allActive = allNotificationsActive(notifications, ns.children);
    const someActive = !allActive && someNotificationsActive(notifications, ns.children);

    const handleGroupChange = () => {
        if (notifications) {
            const newNotifications: UserNotifications = { ...notifications };
            updateAll(newNotifications, ns.children, !allActive);
            onChange(newNotifications);
        }
    };

    return (
        <div>
            <FormControlLabel
                control={
                    <Checkbox
                        color="primary"
                        checked={allActive}
                        indeterminate={someActive}
                        onChange={handleGroupChange}
                    />
                }
                label={t(label)}
            />
            <GroupedChildren>
                {children.map(child => (
                    <NotificationSetting key={child.key} ns={child} notifications={notifications} onChange={onChange} />
                ))}
            </GroupedChildren>
        </div>
    );
};

const SingleRoot = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const ReminderLabel = styled.span`
    font-size: 10;
`;
interface SingleNotificationSettingProps {
    notifications: UserNotifications | null;
    ns: ISingleNotificationSetting;
    onChange: (newNotifications: UserNotifications) => void;
}
const SingleNotificationSetting = (props: SingleNotificationSettingProps) => {
    const { notifications, ns, onChange } = props;
    const { key, label, reminderKey } = ns;

    const handleChange = (key: AllKeys) => {
        if (notifications) {
            onChange({ ...notifications, [key]: !notifications[key] });
        }
    };

    return (
        <SingleRoot>
            <FormControlLabel
                control={
                    <Checkbox
                        color="primary"
                        checked={notifications?.[key] === true}
                        onChange={() => {
                            handleChange(key);
                        }}
                    />
                }
                label={t(label)}
            />
            {reminderKey && (
                <div>
                    <ReminderLabel>{t("settings.notifications.remindAfterHours.label", { hours: 8 })}</ReminderLabel>
                    <IOSSwitch
                        checked={notifications?.[reminderKey] === true}
                        onChange={() => {
                            handleChange(reminderKey);
                        }}
                    />
                </div>
            )}
        </SingleRoot>
    );
};

interface NotificationSettingProps {
    notifications: UserNotifications | null;
    ns: INotificationSetting;
    onChange: (newNotifications: UserNotifications) => void;
}
const NotificationSetting = ({ ns, notifications, onChange }: NotificationSettingProps) => {
    if ("children" in ns) {
        return <GroupedNotificationSetting ns={ns} notifications={notifications} onChange={onChange} />;
    }
    return <SingleNotificationSetting ns={ns} notifications={notifications} onChange={onChange} />;
};

const updateAll = (
    userNotifications: UserNotifications,
    notificationSettings: INotificationSettings,
    active: boolean,
): void => {
    const update = (key: AllKeys) => {
        userNotifications[key] = active;
    };
    const walk = (ns: INotificationSetting) => {
        if ("children" in ns) {
            ns.children.forEach(walk);
            return;
        }
        update(ns.key);
        if (ns.reminderKey) {
            update(ns.reminderKey);
        }
    };
    notificationSettings.forEach(walk);
};
const someNotificationsActive = (
    userNotifications: UserNotifications | null,
    notificationSettings: INotificationSettings,
): boolean => {
    const isActive = (key: AllKeys) => userNotifications?.[key] === true;
    const walk = (ns: INotificationSetting): boolean => {
        if ("children" in ns) {
            return ns.children.some(walk);
        }
        return isActive(ns.key) || (!!ns.reminderKey && isActive(ns.reminderKey));
    };
    return notificationSettings.some(walk);
};
const allNotificationsActive = (
    userNotifications: UserNotifications | null,
    notificationSettings: INotificationSettings,
): boolean => {
    const isActive = (key: AllKeys) => userNotifications?.[key] === true;
    const walk = (ns: INotificationSetting): boolean => {
        if ("children" in ns) {
            return ns.children.every(walk);
        }
        return isActive(ns.key) && (!ns.reminderKey || isActive(ns.reminderKey));
    };
    return notificationSettings.every(walk);
};

const filterNotificationSettings = (
    settings: INotificationSetting[],
    fn: (ns: ISingleNotificationSetting) => boolean,
) => {
    const walk = (ns: INotificationSetting): INotificationSetting | null => {
        if ("children" in ns) {
            const children = compact(ns.children.map(walk));
            if (children.length === 0) {
                return null; // no need to keep the parent
            }
            return { ...ns, children };
        }
        if (fn(ns)) {
            return null;
        }
        return ns;
    };
    return compact(settings.map(walk));
};
