import * as React from "react";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { getApiError } from "../../network/NetworkStapler";
import { companiesStore } from "../../stores/CompaniesStore";
import { generalStore } from "../../stores/GeneralStore";
import { IEmployee } from "../../types/models";
import { cloneEmptyEmployee, hrStore } from "../hr/HrStore";

export const useEmployee = (
    employeeId: string | undefined,
    isPreRegistration: boolean,
    subsidiaryId: string | undefined = hrStore.selectedSubsidiaryId,
) => {
    const [employee, setEmployee] = React.useState<IEmployee | null>(null);
    const [loadFailed, setLoadFailed] = React.useState(false);

    const companyId = companiesStore.selectedCompanyId;

    React.useEffect(() => {
        const load = async () => {
            try {
                const response = isPreRegistration
                    ? await API.getPreRegistration(companyId, subsidiaryId, employeeId)
                    : await API.getEmployee(companyId, subsidiaryId, employeeId);

                const employee: IEmployee = { ...cloneEmptyEmployee(), subsidiaryId: subsidiaryId ?? "", ...response };
                setEmployee(employee);
            } catch (error) {
                const apiError = getApiError(error);
                if (apiError?.response.type === "EMPLOYEE_CONFLICT_MISSING_REQUIRED_FIELDS") {
                    generalStore.setError(t("error.invalidEmployee"));
                } else {
                    generalStore.setError(t("error.loadEmployee"), error);
                }

                setLoadFailed(true);
            }
        };

        if (employeeId) {
            load();
        } else {
            setEmployee(cloneEmptyEmployee());
        }
    }, [companyId, employeeId, isPreRegistration, subsidiaryId]);

    return { employee, loadFailed };
};
