import compact from "lodash/compact";
import { observer } from "mobx-react";
import * as React from "react";
import { GLOBAL_FEATURES } from "../../../features";
import { t } from "../../../i18n/util";
import { Report } from "../../../network/APITypes";
import { authStore } from "../../../stores/AuthStore";
import { companiesStore } from "../../../stores/CompaniesStore";
import { coordinator } from "../../../stores/Coordinator";
import { getModuleStore } from "../../../stores/moduleStores";
import { Module } from "../../../types/models";
import { getModuleRoutes } from "../../../types/moduleRoutes";
import { AccountingBankConnectionExpirationCard } from "../../accounting/AccountingBankConnectionExpirationCard";
import { AccountingNavBar } from "../../accounting/AccountingNavBar";
import { accountingStore } from "../../accounting/AccountingStore";
import { replaceRoute } from "../../app/router/history";
import { OverviewCards, useModuleReports } from "../../hooks/useModuleReports";
import { useQueryParams } from "../../hooks/useQueryParams";
import { useRefreshRecordTypes } from "../../hooks/useRefreshRecordTypes";
import { HrNavBar } from "../../hr/HrNavBar";
import { HrRegistrationCard } from "../../hr/HrRegistrationCard";
import { HrRoutes } from "../../hr/router/HrRoutes";
import { CenteredContent } from "../../ui/CenteredContent";
import { SiteContent } from "../../ui/SiteContent";
import { TextDivider } from "../../ui/TextDivider";
import { ModuleFinishedCard } from "../ModuleFinishedCard";
import { ModuleReportCard } from "../ModuleReportCard";
import { ModuleTicketsWelcomeCard } from "../ModuleTicketsWelcomeCard";
import { ModuleWelcomeCard } from "../ModuleWelcomeCard";

export const ModuleOverviewSite = observer(function ModuleOverviewSite({ module }: { module: Module }) {
    const [expanded, setExpanded] = React.useState<OverviewCards | "">("overview_welcome");

    const query: {
        reportType?: Report.TypeEnum | "global-analysis" | "global-billing";
        activeCard?: "tickets" | "welcome" | "reports" | "global-reports";
    } = useQueryParams();

    const moduleStore = getModuleStore(module);

    const selectedSubsidiaryId = moduleStore.selectedSubsidiaryId;

    const selectedPeriodId = moduleStore.selectedPeriodId;

    const periodFinished = moduleStore.selectedPeriod?.finished === true;

    const companyId = companiesStore.selectedCompanyId;

    const moduleReports = useModuleReports(
        moduleStore.isSyncing ? undefined : companyId,
        module,
        selectedPeriodId,
        selectedSubsidiaryId,
    );
    const { expandReportCard, billing, analysis, globalBilling, globalAnalysis } = moduleReports;

    // This is needed to have up to date record types for correct cards statuses
    // On initial load this will lead to a duplicate load of record types
    // TODO: avoid initial duplicate load if possible.
    useRefreshRecordTypes(moduleStore.module);

    const createExpandHandler = (panel: OverviewCards) => (isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : "");
    };

    const reportPermissions = moduleStore.getReportPermissions(selectedSubsidiaryId);

    const globalReportsConfigured =
        module === "hr"
            ? companiesStore.selectedCompany?.hasGlobalHRReports
            : companiesStore.selectedCompany?.hasGlobalAccountingReports;

    const hasRecordTickets = moduleStore.hasRecordTickets;

    const { expired, expireDuringPeriod } = companiesStore.getFilteredBankConnections(
        accountingStore.bankConnectionPeriodEnd,
    );

    // Check if any bank connection expires during the next period
    const showBankConnectionExpirationCard =
        GLOBAL_FEATURES.bankConnections &&
        (expired.length > 0 || expireDuringPeriod.length > 0) &&
        authStore.canEditBanking() &&
        !companiesStore.bankConnectionsWereUpdatedToday();

    React.useEffect(() => {
        let queryHandled = false;

        // Expand initial card
        if (query.activeCard) {
            if (query.activeCard === "tickets" && hasRecordTickets) {
                // We come from cockpit
                setExpanded("overview_tickets");
                queryHandled = true;
            } else if (query.activeCard === "welcome") {
                setExpanded("overview_welcome");
                queryHandled = true;
            } else if (query.activeCard === "reports" && !moduleReports.areEmpty) {
                setExpanded(expandReportCard({ queryReportType: query.reportType }));
                queryHandled = true;
            } else if (query.activeCard === "global-reports" && !moduleReports.areEmpty) {
                setExpanded(expandReportCard({ queryReportType: query.reportType, preferGlobal: true }));
                queryHandled = true;
            }
        }

        if (!queryHandled) {
            if (showBankConnectionExpirationCard) {
                // Bank connection expired or expires during the next period
                setExpanded("overview_bankConnections");
            } else if (periodFinished) {
                // Period is finished
                setExpanded("overview_finished");
            } else {
                // Standard cases
                if (hasRecordTickets) {
                    setExpanded("overview_tickets");
                } else if (!moduleReports.areEmpty) {
                    setExpanded(expandReportCard({ queryReportType: query.reportType }));
                } else {
                    setExpanded("overview_welcome");
                }
            }
        }
    }, [
        moduleReports.areEmpty,
        periodFinished,
        query.reportType,
        expandReportCard,
        hasRecordTickets,
        query.activeCard,
        moduleStore,
        showBankConnectionExpirationCard,
    ]);

    // Redirect if user sees nothing on overview site
    if (!moduleStore.canSeeOverviewSite()) {
        if (moduleStore.canReadAnyRecords()) {
            replaceRoute(getModuleRoutes(module).RECORDS.ROOT);
        } else if (module === "hr" && authStore.canReadAnyEmployees) {
            replaceRoute(HrRoutes.EMPLOYEES.CURRENT);
        }
    }

    const navBar = module === "hr" ? <HrNavBar /> : <AccountingNavBar />;

    // Wait until all reports and record types are loaded
    // Need to wait for record types to know if we have record tickets
    if (
        !moduleReports.areLoaded ||
        coordinator.isSyncing ||
        moduleStore.isSyncing ||
        (moduleStore.canReadAnySubsidiaryRecords(selectedSubsidiaryId) && moduleStore.recordTypes.length === 0) // this means record types haven't finished loading yet
    ) {
        return navBar;
    }

    const showTicketsCard = hasRecordTickets;
    const showWelcomeCard =
        !showTicketsCard && (authStore.isTpa || moduleStore.canEditAnySubsidiaryRecords(selectedSubsidiaryId));

    return (
        <>
            {navBar}
            <CenteredContent>
                <SiteContent>
                    {module === "accounting" && showBankConnectionExpirationCard && (
                        <>
                            <AccountingBankConnectionExpirationCard
                                expanded={expanded === "overview_bankConnections"}
                                onExpand={createExpandHandler("overview_bankConnections")}
                                data-id="accounting_bank_connections_expiration_card"
                                expired={expired.length > 0}
                            />
                        </>
                    )}
                    {showTicketsCard && (
                        <ModuleTicketsWelcomeCard
                            title={
                                moduleStore.t("screen.accounting.overview.card.overview") +
                                (moduleStore.selectedSubsidiary()?.name ?? "")
                            }
                            recordTypes={compact(moduleStore.recordTypes.map(rt => (rt.ticketCount ? rt : undefined)))}
                            expanded={expanded === "overview_tickets"}
                            onExpand={createExpandHandler("overview_tickets")}
                            data-id="tickets_welcome_card"
                            module={moduleStore.module}
                        />
                    )}
                    {!periodFinished && (
                        <>
                            {showWelcomeCard && (
                                <ModuleWelcomeCard
                                    title={
                                        moduleStore.t("screen.accounting.overview.card.overview") +
                                        (moduleStore.selectedSubsidiary()?.name ?? "")
                                    }
                                    expanded={expanded === "overview_welcome"}
                                    onExpand={createExpandHandler("overview_welcome")}
                                    data-id="welcome_card"
                                    module={moduleStore.module}
                                />
                            )}
                            {reportPermissions.canReadLocal && (
                                <>
                                    {module === "hr" &&
                                        (companiesStore.selectedCompany?.releaseSteps === "twoSteps" ||
                                            !billing.isEmpty) && (
                                            <ModuleReportCard
                                                moduleStore={moduleStore}
                                                title={
                                                    t("screen.hr.overview.card.billing") +
                                                    (moduleStore.selectedSubsidiary()?.name ?? "")
                                                }
                                                iconName="billing"
                                                reportsConfig={billing}
                                                expanded={expanded === "overview_billing"}
                                                onExpand={createExpandHandler("overview_billing")}
                                                data-id="billing_card"
                                                showRelease={reportPermissions.canReleaseLocal}
                                                showDelete={reportPermissions.canDeleteLocal}
                                                showTicket={reportPermissions.canReadLocalTickets}
                                                createTicketAllowed={reportPermissions.canCreateLocalTickets}
                                            />
                                        )}
                                    <ModuleReportCard
                                        moduleStore={moduleStore}
                                        title={
                                            moduleStore.t("screen.accounting.overview.card.analysis") +
                                            (moduleStore.selectedSubsidiary()?.name ?? "")
                                        }
                                        iconName="analysis"
                                        reportsConfig={analysis}
                                        expanded={expanded === "overview_analysis"}
                                        onExpand={createExpandHandler("overview_analysis")}
                                        data-id="analysis_card"
                                        showRelease={reportPermissions.canReleaseLocal}
                                        showDelete={reportPermissions.canDeleteLocal}
                                        showTicket={reportPermissions.canReadLocalTickets}
                                        createTicketAllowed={reportPermissions.canCreateLocalTickets}
                                    />
                                </>
                            )}
                            {reportPermissions.canReadGlobal && (
                                <>
                                    {module === "hr" &&
                                        ((!!globalReportsConfigured &&
                                            companiesStore.selectedCompany?.releaseSteps === "twoSteps") ||
                                            !globalBilling.isEmpty) && (
                                            <ModuleReportCard
                                                moduleStore={moduleStore}
                                                title={t("screen.hr.overview.card.globalBilling")}
                                                iconName="global"
                                                reportsConfig={globalBilling}
                                                expanded={expanded === "overview_globalBilling"}
                                                onExpand={createExpandHandler("overview_globalBilling")}
                                                data-id="globalBilling_card"
                                                showRelease={reportPermissions.canReleaseGlobal}
                                                showDelete={reportPermissions.canDeleteGlobal}
                                                showTicket={reportPermissions.canReadGlobalTickets}
                                                createTicketAllowed={reportPermissions.canCreateGlobalTickets}
                                            />
                                        )}
                                    {(!!globalReportsConfigured || !globalAnalysis.isEmpty) && (
                                        <ModuleReportCard
                                            moduleStore={moduleStore}
                                            title={moduleStore.t("screen.accounting.overview.card.globalAnalysis")}
                                            iconName="global"
                                            reportsConfig={globalAnalysis}
                                            expanded={expanded === "overview_globalAnalysis"}
                                            onExpand={createExpandHandler("overview_globalAnalysis")}
                                            data-id="globalAnalysis_card"
                                            showRelease={reportPermissions.canReleaseGlobal}
                                            showDelete={reportPermissions.canDeleteGlobal}
                                            showTicket={reportPermissions.canReadGlobalTickets}
                                            createTicketAllowed={reportPermissions.canCreateGlobalTickets}
                                        />
                                    )}
                                </>
                            )}
                        </>
                    )}
                    {periodFinished && (reportPermissions.canReadLocal || reportPermissions.canReadGlobal) && (
                        <ModuleFinishedCard
                            analysis={analysis}
                            billing={billing}
                            globalAnalysis={globalAnalysis}
                            globalBilling={globalBilling}
                            module={moduleStore.module}
                            title={t("common.finished")}
                            expanded={expanded === "overview_finished"}
                            onExpand={createExpandHandler("overview_finished")}
                            data-id="finished_card"
                        />
                    )}
                    {module === "hr" && authStore.canReadAnyEmployees && (
                        <>
                            <TextDivider text={t("hr.overview.continuousActions")} style={{ marginBottom: 16 }} />
                            <HrRegistrationCard
                                title={t("screen.hr.overview.card.registration")}
                                expanded={expanded === "overview_registration"}
                                onExpand={createExpandHandler("overview_registration")}
                                data-id="registration_card"
                            />
                        </>
                    )}
                </SiteContent>
            </CenteredContent>
        </>
    );
});
