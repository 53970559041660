import * as React from "react";
import { useLocation } from "react-router";
import { t } from "../../i18n/util";
import { EmployeeDocument } from "../../network/APITypes";
import { getFullName } from "../../util/user";
import { Routes } from "../app/router/Routes";
import { Icon } from "../util/Icon";
import { MobileContext } from "../util/MobileContext";
import { customColors } from "../util/Theme";
import { DocumentDownloadLine } from "./DocumentLine/DocumentLine";
import { OverviewContainer, TpaWhiteButton } from "./Primitives";

const FileBarDocumentList = (props: {
    buttonFunction?: () => void;
    buttonText: string;
    sections: IFilebarSection[];
    canEdit: boolean;
    onView: (document: EmployeeDocument) => void;
    onDownload: (documentIds: string[]) => void;
    badge: React.ReactNode;
}) => {
    const location = useLocation();
    const isStaffRoute = location.pathname.startsWith(Routes.STAFF);

    return (
        <>
            {props.sections.map((section, index) => {
                return (
                    <div key={index} style={{ marginBottom: 32 }}>
                        {section.label && (
                            <div style={{ display: "flex", justifyContent: "space-between", paddingBottom: 16 }}>
                                <h3>{section.label}</h3>
                                {section.onAdd && (
                                    <Icon
                                        name="add"
                                        style={
                                            !props.canEdit
                                                ? { color: customColors.greyButton }
                                                : { color: customColors.primaryColor, cursor: "pointer" }
                                        }
                                        role="button"
                                        onClick={() => {
                                            if (section.onAdd && props.canEdit) {
                                                section.onAdd();
                                            }
                                        }}
                                        data-id={`fileBarAddTo${section.label}`}
                                    />
                                )}
                            </div>
                        )}
                        {section.documents?.map(employeeDocument => {
                            const document = employeeDocument.document;
                            const showStaffCanRead = !isStaffRoute && employeeDocument.staffCanRead;
                            return (
                                <DocumentDownloadLine
                                    key={document.id}
                                    style={{ padding: "16px 0", borderBottom: `1px solid ${customColors.greyLight}` }}
                                    textStyle={{ fontSize: 14, color: customColors.body1Dark }}
                                    fileName={document.name}
                                    subtitle={t("screen.hr.employee.details.fileProvidedBy", {
                                        name: document.uploader.isTpaEmployee
                                            ? t("common.tpaFirstName")
                                            : getFullName(document.uploader),
                                    })}
                                    documentId={document.id}
                                    onView={() => {
                                        props.onView(employeeDocument);
                                    }}
                                    onDownload={props.onDownload}
                                    staffCanRead={showStaffCanRead}
                                />
                            );
                        })}
                    </div>
                );
            })}

            <TpaWhiteButton
                onClick={props.buttonFunction}
                style={{
                    width: "100%",
                    borderColor: !props.buttonFunction ? customColors.greyTextfields : customColors.primaryColor,
                }}
                data-id="file_bar_button"
                disabled={!props.buttonFunction}
            >
                {props.buttonText}
                {props.badge}
            </TpaWhiteButton>
        </>
    );
};

export const FileBar = (props: {
    buttonFunction?: () => void;
    buttonText: string;
    sections: IFilebarSection[];
    canEdit: boolean;
    onView: (document: EmployeeDocument) => void;
    onDownload: (documentIds: string[]) => void;
    badge: React.ReactNode;
}) => {
    return (
        <div
            style={{
                position: "fixed",
                overflow: "auto",
                top: "var(--navBarHeight)",
                right: 0,
                width: 368,
                height: "100%",
                padding: "56px 32px 32px 32px",
                paddingTop: 56,
                backgroundColor: customColors.white,
            }}
        >
            <FileBarDocumentList
                buttonFunction={props.buttonFunction}
                buttonText={props.buttonText}
                sections={props.sections}
                canEdit={props.canEdit}
                onView={props.onView}
                onDownload={props.onDownload}
                badge={props.badge}
            />
        </div>
    );
};

export const FileBarMobile = (props: {
    buttonFunction?: () => void;
    buttonText: string;
    sections: IFilebarSection[];
    canEdit: boolean;
    onView: (document: EmployeeDocument) => void;
    onDownload: (documentIds: string[]) => void;
    badge: React.ReactNode;
}) => {
    const isMobile = React.useContext(MobileContext);
    return (
        <OverviewContainer fullWidth={isMobile}>
            <FileBarDocumentList
                buttonFunction={props.buttonFunction}
                buttonText={props.buttonText}
                sections={props.sections}
                canEdit={props.canEdit}
                onView={props.onView}
                onDownload={props.onDownload}
                badge={props.badge}
            />
        </OverviewContainer>
    );
};

export interface IFilebarSection {
    label?: string;
    documents?: EmployeeDocument[];
    onAdd?: () => void;
}
