import { observer } from "mobx-react";
import React from "react";
import { DEFAULT_STAFF_CAN_READ } from "../../../config";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { Employee } from "../../../network/APITypes";
import { generalStore } from "../../../stores/GeneralStore";
import { getFullName } from "../../../util/user";
import { Autocomplete } from "../Autocomplete";
import { DocumentLine } from "./DocumentLine";

export const DocumentLineEmployees = observer(function DocumentLineEmployees({
    companyId,
    subsidiaryId,
    fileName,
    onView,
    onDelete,
    style,
    onSetEmployeeId,
    onChangeVisibleToEmployee,
    showEmptyErrors,
    preloadedEmployee,
    activeDays,
}: {
    companyId: string;
    subsidiaryId: string;
    fileName: string;
    onView: () => void;
    onDelete: () => void;
    style?: React.CSSProperties;
    onSetEmployeeId: (employeeId: string, isCurrentlyEmployed?: boolean) => void;
    onChangeVisibleToEmployee?: (visible: boolean) => void;
    showEmptyErrors?: boolean;
    preloadedEmployee?: Employee;
    activeDays?: number;
}) {
    const [error, setError] = React.useState(false);
    const [selectedEmployee, setSelectedEmployee] = React.useState<Employee | null>(preloadedEmployee ?? null);
    const [lastSubsidiaryId, setLastSubsidiaryId] = React.useState(subsidiaryId);

    // Clear selected employee when subsidiary changes
    React.useEffect(() => {
        if (lastSubsidiaryId !== subsidiaryId) {
            setLastSubsidiaryId(subsidiaryId);
            setSelectedEmployee(null);
            onSetEmployeeId("");
        }
    }, [lastSubsidiaryId, onSetEmployeeId, subsidiaryId]);

    const showError = error || (showEmptyErrors && !selectedEmployee);

    const loader = React.useCallback(
        async ({ offset, search }: { offset: number; search: string }) => {
            if (!search) {
                return { items: [], total: 0 };
            }

            try {
                const employees = await API.getCurrentEmployees(companyId, subsidiaryId, {
                    options: { offset, limit: 50, search, activeDays },
                });
                return { items: employees.employees ?? [], total: employees.total };
            } catch (err) {
                generalStore.setError(t("error.loadEmployees"), err);
                return null;
            }
        },
        [activeDays, companyId, subsidiaryId],
    );

    return (
        <div style={style}>
            <DocumentLine
                switchLabel={t("screen.advisor.uploads.upload.visibleToEmployee")}
                onCheck={onChangeVisibleToEmployee}
                defaultChecked={DEFAULT_STAFF_CAN_READ}
                fileName={fileName}
                onView={onView}
                onDelete={onDelete}
            />
            <Autocomplete
                getOptionLabel={employee => `${getFullName(employee)} (${employee.personnelNumber ?? ""})`}
                isSelected={(employee, value) => {
                    // Compare entries by id
                    return employee.id === value.id;
                }}
                loader={loader}
                getNoOptionsText={search => t(search ? "common.noSearchResults" : "webhook.employee.noSearch")}
                value={selectedEmployee}
                onChange={(employee: Employee | null) => {
                    if (employee) {
                        // Check if employee has already left the company
                        const isCurrentlyEmployed = !employee.dateOfLeaving;

                        onSetEmployeeId(employee.id, isCurrentlyEmployed);
                        setError(false);
                    } else {
                        onSetEmployeeId("");
                        setError(true);
                    }

                    setSelectedEmployee(employee);
                }}
                label={t("webhook.employee.placeholder")}
                error={showError ? t("error.missingEmployee") : undefined}
                disableClearable={false}
            />
        </div>
    );
});
