import * as React from "react";
import { ChatUserInfo, GetResponsibleUsersResponse, Role, TPAEmployeePublicUserInfo } from "../../network/APITypes";
import { useChatUsers } from "./useChatUsers";
import { useErstwhileChatUsers } from "./useErstwhileChatUsers ";
import { useResponsibleUsers } from "./useResponsibleUsers";

export function sortContacts<T extends { firstName: string; unreadChatMessages?: number }>(
    contacts: T[],
    byUnreadCount?: boolean,
) {
    // Need spread because sort() mutates original array
    return [...contacts].sort((lhs, rhs) => {
        if (byUnreadCount) {
            // Sort by unread count, but that is more confusing IMO because
            // contacts jump after reading messages
            if (lhs.unreadChatMessages || rhs.unreadChatMessages) {
                return (rhs.unreadChatMessages ?? 0) - (lhs.unreadChatMessages ?? 0);
            } else {
                return lhs.firstName.toLowerCase() < rhs.firstName.toLowerCase() ? -1 : 1;
            }
        } else {
            // Only sort by first name like slack
            return lhs.firstName.toLowerCase() < rhs.firstName.toLowerCase() ? -1 : 1;
        }
    });
}

export type ChatVariant = "active" | "inactive" | "all";

export const useChatContacts = ({
    companyId,
    isTpa,
    role,
    preloaded,
    colleagueId,
    chatVariant,
}: {
    companyId?: string;
    isTpa: boolean;
    role?: Role;
    preloaded?: GetResponsibleUsersResponse;
    colleagueId?: string;
    chatVariant?: ChatVariant;
}) => {
    const inactiveOnly = chatVariant === "inactive";
    const inactive = chatVariant === "inactive" || chatVariant === "all";

    const [admins, setAdmins] = React.useState<(ChatUserInfo | TPAEmployeePublicUserInfo)[]>([]);
    const [team, setTeam] = React.useState<(ChatUserInfo | TPAEmployeePublicUserInfo)[]>([]);
    const responsible = useResponsibleUsers({
        companyId: isTpa ? undefined : companyId,
        preloaded,
        includeBadges: true,
    });
    let chatUsers = useChatUsers(isTpa ? companyId : undefined, role, colleagueId);
    const erstwhileChatUsers = useErstwhileChatUsers(inactive ? companyId : undefined, isTpa ? "customer" : "tpa");

    if (inactiveOnly) {
        chatUsers = { ...erstwhileChatUsers, loadedColleagueId: undefined };
    }

    React.useEffect(() => {
        let newTeam;
        if (isTpa) {
            const isAdmin = (user: ChatUserInfo) =>
                !!user.roles?.includes("super-admin") || !!user.roles?.includes("admin");
            setAdmins(chatUsers.users.filter(isAdmin));
            newTeam = chatUsers.users.filter(user => !isAdmin(user));
        } else {
            if (inactiveOnly) {
                setAdmins([]);
                newTeam = chatUsers.users;
            } else {
                setAdmins(responsible.users.partners);
                newTeam = responsible.users.responsible;
            }
        }

        if (inactive && !inactiveOnly) {
            newTeam = [...newTeam, ...erstwhileChatUsers.users];
        }

        setTeam(newTeam);
    }, [isTpa, responsible.users, chatUsers.users, inactiveOnly, inactive, erstwhileChatUsers.users]);

    return {
        admins,
        team,
        reload: isTpa ? chatUsers.reload : responsible.reload,
        unreadChatMessages() {
            // Return the sum of all unread counts
            let total = 0;
            admins.forEach(u => (total += u.unreadChatMessages ?? 0));
            team.forEach(u => (total += u.unreadChatMessages ?? 0));
            return total;
        },
        loadedColleagueId: isTpa ? chatUsers.loadedColleagueId : undefined,
        getContact(userId: string) {
            return admins.find(c => c.id === userId) ?? team.find(c => c.id === userId);
        },
        isActiveContact(userId: string) {
            return !erstwhileChatUsers.isInactive(userId);
        },
    };
};
