import React from "react";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { GetReportsResponse, Report } from "../../network/APITypes";
import { getApiError } from "../../network/NetworkStapler";
import { coordinator } from "../../stores/Coordinator";
import { generalStore } from "../../stores/GeneralStore";
import { getModuleStore } from "../../stores/moduleStores";
import { Module } from "../../types/models";
import { isReportReleased } from "../../util/reports";

export const useReports = ({
    global,
    companyId,
    periodId,
    module,
    reportType,
    subsidiaryId,
}: {
    global: boolean;
    companyId: string | undefined;
    periodId: string | undefined;
    module: Module;
    reportType: Report.TypeEnum;
    subsidiaryId?: string;
}) => {
    const [reports, setReports] = React.useState<GetReportsResponse | null>(null);
    const [needRelease, setNeedRelease] = React.useState(false);
    const [hasFinishedReport, setHasFinishedReport] = React.useState(false);
    const isSyncing = coordinator.isSyncing;
    const moduleStore = getModuleStore(module);

    const canRead = subsidiaryId
        ? moduleStore.canReadReports(subsidiaryId)
        : global && moduleStore.canReadGlobalReports();

    // True if at least a single report still needs to be released
    const reportsNeedRelease = React.useCallback((reports: Report[]) => {
        const needRelease = reports.filter(report => report.needsRelease && !isReportReleased(report));
        return needRelease.length > 0;
    }, []);

    // True if at least a single report is released or doesn't need release
    const reportsHasFinished = React.useCallback((reports: Report[]) => {
        const hasFinished = reports.filter(report => !report.needsRelease || isReportReleased(report));
        return hasFinished.length > 0;
    }, []);

    const loadReports = React.useCallback(async () => {
        if (
            !companyId ||
            !periodId ||
            !canRead ||
            (!global && !subsidiaryId) ||
            (reportType === "billing" && module === "accounting") // accounting has no billing
        ) {
            // Need this so that depending code can read total and empty array to disable UI
            setReports({ limit: 0, total: 0, offset: 0, reports: [] });
            return;
        }

        try {
            generalStore.isLoading = true;

            const response = await API.getReports({
                companyId,
                module,
                periodId,
                subsidiaryId,
                reportType,
            });

            if (response) {
                setReports(response);
                if (response.total > 0 && response.reports) {
                    const release = reportsNeedRelease(response.reports);
                    const finished = reportsHasFinished(response.reports);
                    setNeedRelease(release);
                    setHasFinishedReport(finished);
                } else {
                    setNeedRelease(false);
                }
            }
        } catch (error) {
            const apiError = getApiError(error);
            if (apiError?.response.type === "SUBSIDIARY_NOT_FOUND") {
                generalStore.setError(moduleStore.t("error.accounting.subsidiaryDoesNotExist"), error);
            } else {
                generalStore.setError(t("error.loadReports"), error);
            }
        } finally {
            generalStore.isLoading = false;
        }
    }, [
        companyId,
        periodId,
        canRead,
        global,
        subsidiaryId,
        reportType,
        module,
        reportsNeedRelease,
        reportsHasFinished,
        moduleStore,
    ]);

    React.useEffect(() => {
        if (!periodId || !companyId) {
            return;
        }

        if (isSyncing) {
            return;
        }

        loadReports();
    }, [companyId, isSyncing, loadReports, periodId]);

    return {
        reports,
        needRelease,
        hasFinishedReport,
        loadReports,
        isEmpty: !reports?.reports || reports.reports.length === 0,
        isLoaded: reports !== null,
        global,
        reportType,
        companyId,
        module,
        periodId,
        subsidiaryId,
    };
};

export type IReportsConfig = ReturnType<typeof useReports>;
