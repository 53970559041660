const slovak = {
  "accounting.bankAccount.account.label": "Účet",
  "accounting.bankAccount.account.placeholder": "Účet",
  "accounting.bankAccount.account.title": "Vyberte účet",
  "accounting.bankAccount.transactions.assignInvoices.account.error": "Kategórie dokumentov nebolo možné načítať",
  "accounting.bankAccount.transactions.assignInvoices.account.placeholder": "Kategória dokumentov",
  "accounting.bankAccount.transactions.assignInvoices.filters.bankAccountTransactions.dateRange.required": "Vyberte dátum začiatku a konca.",
  "accounting.bankAccount.transactions.assignInvoices.filters.bankAccountTransactions.title": "Bankové transakcie",
  "accounting.bankAccount.transactions.assignInvoices.filters.records.recordTypes.title": "Symbol dokumentu",
  "accounting.bankAccount.transactions.assignInvoices.filters.records.title": "Príjmy",
  "accounting.bankAccount.transactions.assignInvoices.records.empty": "Nenašli sa žiadne dôkazy",
  "accounting.bankAccount.transactions.assignInvoices.records.pagination.next": "Ďalší dokument",
  "accounting.bankAccount.transactions.assignInvoices.records.pagination.previous": "Predchádzajúci dokument",
  "accounting.bankAccount.transactions.assignInvoices.subtitle": "",
  "accounting.bankAccount.transactions.assignInvoices.title": "",
  "accounting.bankAccount.transactions.table.action.assignOpenInvoices": "",
  "accounting.bankAccount.transactions.table.action.downloadBankStatement": "",
  "accounting.bankAccount.transactions.table.action.downloadBuerf": "",
  "accounting.bankAccount.transactions.table.emptyState.title": "",
  "accounting.bankAccount.transactions.table.label.amount": "",
  "accounting.bankAccount.transactions.table.label.assignInvoice": "",
  "accounting.bankAccount.transactions.table.label.date": "",
  "accounting.bankAccount.transactions.table.label.invoice": "",
  "accounting.bankAccount.transactions.table.label.isPrivate": "",
  "accounting.bankAccount.transactions.table.label.recipient": "",
  "accounting.bankAccount.transactions.table.label.text": "",
  "accounting.bankAccount.transactions.table.label.valueDate": "",
  "accounting.bankAccount.transactions.table.menu.deleteAssignedInvoice": "",
  "accounting.bankAccount.transactions.table.menu.markAsPrivate": "",
  "accounting.bankAccount.transactions.table.menu.removePrivate": "",
  "accounting.bankAccount.transactions.table.search.count": "",
  "accounting.bankAccount.transactions.table.search.placeholder": "",
  "accounting.bankAccount.transactions.table.uploadInvoice.progress.title": "",
  "accounting.bankAccount.transactions.table.uploadInvoice.subsidiaryAndRecordType.title": "",
  "accounting.bankAccount.transactions.title": "",
  "accounting.bankConnection.delete.message": "Naozaj chcete vymazať bankový účet {názov}?",
  "accounting.bankConnection.delete.title": "Odstránenie bankových údajov",
  "accounting.bankConnections.accountHolder": "Majiteľ:",
  "accounting.bankConnections.accountType": "Typ účtu:",
  "accounting.bankConnections.accountType.Bausparen": "",
  "accounting.bankConnections.accountType.Checking": "",
  "accounting.bankConnections.accountType.CreditCard": "",
  "accounting.bankConnections.accountType.Loan": "",
  "accounting.bankConnections.accountType.Membership": "",
  "accounting.bankConnections.accountType.Savings": "",
  "accounting.bankConnections.accountType.Security": "",
  "accounting.bankConnections.addBankConnection": "Pridanie bankových údajov",
  "accounting.bankConnections.balance": "Zostatok na účte:",
  "accounting.bankConnections.emptyState.description": "Pridajte nový bankový účet.",
  "accounting.bankConnections.emptyState.title": "Žiadne bankové údaje",
  "accounting.bankConnections.expired": "Vypršala platnosť",
  "accounting.bankConnections.expiredAt": "Platnosť vypršala dňa {dátum}",
  "accounting.bankConnections.expiresAt": "Platnosť končí dňa {dátum}",
  "accounting.bankConnections.iban": "IBAN:",
  "accounting.bankConnections.lastSuccessfulUpdate": "",
  "accounting.bankConnections.pending": "Vynikajúce",
  "accounting.bankConnections.refreshBankConnection": "Obnovenie bankového účtu",
  "accounting.bankConnections.refreshPending": "Priebežné obnovovanie",
  "accounting.bankConnections.refreshPending.info": "Obnovenie bankových údajov prebieha v samostatnom okne. Ak bolo okno zatvorené pred dokončením aktualizácie, musíte znovu kliknúť na \"Obnoviť bankové údaje\".",
  "accounting.bankConnections.refreshPending.label": "Prebieha proces obnovy",
  "accounting.bankConnections.upToDate": "Potvrdené",
  "accounting.details.edit.header": "Upraviť dokument",
  "accounting.details.updatedAt": "naposledy zmenené a doplnené dňa",
  "accounting.details.upload.files": "Súbory",
  "accounting.details.upload.skip": "Preskočiť všetko",
  "accounting.finished.reports": "Správy",
  "accounting.table.label.totalGrossAmount": "",
  "addFolderDialog.button.text.create": "Nový",
  "addFolderDialog.folderName": "Názov priečinka",
  "addFolderDialog.info": "Prejsť na Sharepoint a spravovať členov.",
  "addFolderDialog.title": "Vytvoriť priečinok",
  "advisor.uploads.uploadReports.title": "Odosielanie správ",
  "analysis.powerBI.reports.table.label.description": "",
  "analysis.powerBI.reports.table.label.name": "",
  "analysis.powerBI.reports.table.search.count": "",
  "analysis.powerBI.reports.table.search.placeholder": "",
  "analysis.powerBI.reports.title": "",
  "analysis.powerBI.report.title": "",
  "appNotificationDialog.button.accept": "",
  "appNotificationDialog.title.maintenance": "",
  "appNotificationDialog.title.newFeature": "",
  "appNotificationDialog.title.update": "",
  "branchType.allocationGroup": "Mzdová oblasť",
  "branchType.costCenter": "Hlavný riešiteľ nákladov",
  "branchType.department": "Oddelenie",
  "branchType.financialAccountancyCircle": "Účtovné okruhy",
  "branchType.other": "Iné",
  "branchType.wageCompany": "Mzdová spoločnosť",
  "branchType.workplace": "Pracovisko",
  "button.activateMfa": "Aktivovať teraz",
  "button.answer": "ODPOVEĎ",
  "button.archive": "Archív",
  "button.assign": "Priradenie",
  "button.back_to_login": "Späť na prihlásenie",
  "button.closeTicket": "Označiť ako hotové",
  "button.configure": "KONFIGURÁCIA",
  "button.continueInBackground": "",
  "button.continue_without_microsoft": "Pokračovanie bez konta Microsoft",
  "button.copy": "Kopírovanie/presun",
  "button.deactivateMfa": "Deaktivovať teraz",
  "button.enter": "VSTÚPIŤ",
  "button.markAsPrivate": "Označiť ako dôverné",
  "button.moreInfo": "VIAC INFORMÁCIÍ",
  "button.move": "Presuňte sa sem",
  "button.nextStep": "ďalší krok",
  "button.paste": "Kópia tu",
  "button.refresh": "Obnoviť",
  "button.release": "Uvoľniť",
  "button.submitArchiveMultiple": "{count} Archívne správy",
  "button.submitArchiveSingle": "{count} Archívna správa",
  "button.toOverview": "K prehľadu",
  "button.transfer": "Vysielať",
  "button.unlock": "Povoliť",
  "button.upload": "Nahrať súbor",
  "captcha.invalid": "",
  "captcha.placeholderText": "",
  "chatInput.deleteLink": "Odstrániť",
  "chatInput.editLink": "Upraviť",
  "chatInput.enterLink": "Odkaz",
  "chatInput.openLink": "Otvoriť adresu URL",
  "chatInput.saveLink": "Uložiť",
  "common.abacus": "Abacus",
  "common.accounting": "Účtovníctvo",
  "common.accounting.period": "Obdobie rezervácie",
  "common.accounting.recordType": "",
  "common.accounting.subsidiary": "Kód spoločnosti",
  "common.add": "Pridať",
  "common.albanian": "Shqiptare",
  "common.all": "Všetky",
  "common.amount": "Číslo",
  "common.anyDateRange": "Akékoľvek obdobie",
  "common.apply": "Použiť zmeny",
  "common.archive": "Archív",
  "common.back": "Späť",
  "common.bankConnection": "",
  "common.bankConnections": "Bankové údaje",
  "common.bankContact": "Bankové údaje",
  "common.bic": "BIC",
  "common.bmd": "BMD",
  "common.bulgarian": "Български",
  "common.cancel": "Ďalej",
  "common.cancelCaps": "ZRUŠIŤ",
  "common.chat": "Chat",
  "common.clientNumber": "Číslo zákazníka",
  "common.cockpit": "Kokpit",
  "common.companies": "Spoločnosť",
  "common.company": "Spoločnosť",
  "common.companyNumber": "Číslo spoločnosti",
  "common.companyOverview": "Prehľad spoločnosti",
  "common.companyRegisterNumber": "Registračné číslo spoločnosti",
  "common.continue": "Pokračovať",
  "common.country": "Krajina",
  "common.croatian": "Chorvátsky",
  "common.czech": "Česky",
  "common.dateformat": "DD.MM.RRRR",
  "common.dateValidation": "Zadajte platný dátum",
  "common.defaultSignature": "Vaša TPA",
  "common.delete": "Odstrániť",
  "common.done": "Hotové",
  "common.download": "Stiahnite si",
  "common.downloadAll": "",
  "common.dueDate": "Termín",
  "common.dueUntil": " najneskôr do  {dueDate}",
  "common.emailAddress": "E-mailová adresa",
  "common.english": "Angličtina",
  "common.errorOccurred": "Vyskytla sa chyba",
  "common.events": "Podujatia",
  "common.files": "Súbory",
  "common.finished": "Dokončené",
  "common.folder": "Priečinok",
  "common.from": "Z adresy",
  "common.fullName": "{krstné meno} {priezvisko}",
  "common.generalData": "Všeobecné údaje",
  "common.generalDocuments": "Všeobecné súbory",
  "common.german": "Nemecky",
  "common.global": "Celá spoločnosť",
  "common.hr": "Mzdy",
  "common.hr.period": "Účtovné obdobie",
  "common.hrPreRegistrations": "Personál - predbežné záznamy",
  "common.hr.short": "Zamestnanci",
  "common.hr.subsidiary": "Mzdová oblasť",
  "common.hungarian": "Maďarsky",
  "common.invite": "Pozvánka",
  "common.kpi": "Fakty a čísla",
  "common.kpi.long": "Fakty a čísla",
  "common.later": "Neskôr",
  "common.logout": "Odhlásenie",
  "common.me": "JA",
  "common.members": "Členovia",
  "common.moreInformation": "dozvedieť sa viac",
  "common.mr": "Pán {meno}",
  "common.mrs": "Pani {meno}",
  "common.name": "Meno",
  "common.no": "Nie",
  "common.none": "žiadne",
  "common.noSearchResults": "Nenašli sa žiadne výsledky",
  "common.notAvailable": "N/A",
  "common.notSpecified": "n.a.",
  "common.numberValidation": "Zadajte platné číslo",
  "common.ok": "Ok",
  "common.optional": "(voliteľné)",
  "common.orderBy": "",
  "common.partial": "čiastočne",
  "common.people": "Osoby",
  "common.polish": "Poľsky",
  "common.profile": "Profil",
  "common.project": "Projekt",
  "common.projects": "Projekty",
  "common.readingTimeMinutes": "Čas čítania: {time} Min",
  "common.readingTimeMinutes.short": "{time} Min",
  "common.recipient": "Príjemca",
  "common.refresh": "Obnoviť",
  "common.releasedAt": "",
  "common.releasedAtBy": "vydané dňa {date} spoločnosťou {name}",
  "common.releaseRequired.plural": "",
  "common.releaseRequired.singular": "",
  "common.remainingItems": "+{počet} viac",
  "common.remainingItemsUnknown": "",
  "common.retry": "Skúste to znova",
  "common.romanian": "Rummunský",
  "common.russian": "Ruský",
  "common.save": "Uložiť",
  "common.sendCaps": "ODOSLAŤ",
  "common.serbian": "Srbsky",
  "common.settings": "Nastavenia",
  "common.showAll": "Zobraziť všetky",
  "common.slovak": "Slovenská",
  "common.slovene": "Slovenski",
  "common.taskTitle": "Názov",
  "common.taxNumber": "Daňové číslo",
  "common.termsOfUse": "Podmienky používania",
  "common.ticket": "Konzultácie",
  "common.timePeriod": "Obdobie",
  "common.timePeriod.olderThan1Month": "Staršie ako jeden mesiac",
  "common.timePeriod.olderThan1Week": "Staršie ako jeden týždeň",
  "common.timePeriod.olderThan1Year": "Staršie ako jeden rok",
  "common.timePeriod.olderThan6Months": "Staršie ako 6 mesiacov",
  "common.to": "Až do",
  "common.tpaFirstName": "TPA",
  "common.tpaLastName": "Zamestnanci",
  "common.unknown": "neznáme",
  "common.upcomingTermsOfUse": "",
  "common.upload": "",
  "common.uploadFiles": "Nahrať súbory",
  "common.user": "",
  "common.vatNumber": "UID",
  "common.yes": "Áno",
  "commuterAllowance.IndividuellePendlerpauschale": "",
  "commuterAllowance.KeinePendlerpauschale": "",
  "commuterAllowance.MehrAls20kmKeinBus": "",
  "commuterAllowance.MehrAls40kmBus": "",
  "commuterAllowance.MehrAls40kmKeinBus": "",
  "commuterAllowance.MehrAls60kmBus": "",
  "commuterAllowance.MehrAls60kmKeinBus": "",
  "commuterAllowance.Mindestens20kmBus": "",
  "commuterAllowance.Mindestens2kmKeinBus": "",
  "companiesUserReleaseInfoActionsBar.message": "Máte {počet} nových používateľov, ktorí sa chcú podeliť",
  "companiesUserReleaseInfoActionsBar.submit": "Zobrazenie a zdieľanie",
  "companiesUserRelease.permissions.navbar.back": "Zdieľanie a pozývanie používateľov / Priradenie roly",
  "companiesUserRelease.title": "Zdieľanie a pozývanie používateľov",
  "companyImages.cover.error": "Titulný obrázok sa nepodarilo načítať",
  "companyImages.cover.title": "Titulný obrázok",
  "companyImages.delete.message": "Naozaj chcete obrázok odstrániť?",
  "companyImages.delete.title": "Odstrániť obrázok",
  "companyImages.logo.error": "Logo spoločnosti nebolo možné načítať",
  "companyImages.logo.title": "Logo spoločnosti",
  "companyImages.title": "Titulný obrázok a logo vašej spoločnosti (voliteľné)",
  "companyList.remainingCompanies": "+{remainingCompanies} Viac na",
  "company.optionalSideBarMenuItems.maxLength.name": "Názov môže obsahovať len 15 znakov",
  "company.optionalSideBarMenuItems.nameAlreadyinUse": "Voliteľná položka ponuky je už k dispozícii",
  "company.optionalSideBarMenuItems.name.label": "Názov",
  "company.optionalSideBarMenuItems.required.name": "Musí sa uviesť názov",
  "company.optionalSideBarMenuItems.required.url": "Musí byť zadaná adresa URL",
  "company.optionalSideBarMenuItems.title": "Voliteľné položky menu spoločnosti",
  "company.optionalSideBarMenuItems.tooltip.removeItem": "Odstránenie položky ponuky",
  "company.optionalSideBarMenuItems.urlAlreadyinUse": "Prepojenie na voliteľnú položku ponuky už existuje",
  "company.optionalSideBarMenuItems.url.label": "ADRESA URL",
  "company.optionalSideBarMenuItems.urlNotValid": "Zadajte platnú adresu URL (príklad: https://www.tpa-connect.app )",
  "companyOverview.emptyState.message": "Kontaktujte svojho poradcu",
  "companyOverview.emptyState.title": "Žiadna aktívna spoločnosť",
  "companyOverview.emptyState.tpa.message": "V súčasnosti nemáte žiadnych aktívnych zákazníkov",
  "companyOverview.emptyState.tpa.title": "Žiadni aktívni zákazníci",
  "companyOverview.unlockCountry.tooltip": "Odomknite nové krajiny{br}{br} Odomknite medzinárodné projekty z <b>{country}</b>, aby ste mohli využívať funkciu projektov tejto spoločnosti a zobraziť všetky dopyty.",
  "companyStatus.accountingSetupDone": "0/2 hotovo",
  "companyStatus.active": "aktívny",
  "companyStatus.hrSetupDone": "1/2 hotová",
  "companyStatus.inactive": "neaktívne",
  "companyStatus.userSetupDone": "2/2 hotovo",
  "config.accounting.accountingByCustomer": "Prenos účtovných údajov klientmi prostredníctvom služby TPA Connect",
  "config.accounting.delete.subsidiaries.last.message": "Kód spoločnosti {name} nie je možné vymazať. Musí existovať aspoň jeden kód spoločnosti.",
  "config.accounting.delete.subsidiaries.message": "Naozaj chcete vymazať kód spoločnosti {name}?",
  "config.accounting.delete.subsidiaries.title": "Odstránenie kódu spoločnosti",
  "config.accounting.description": "Určite typ a rozsah účtovnej oblasti.",
  "config.accounting.description.customer": "Túto oblasť pre vás nakonfiguroval váš TPA poradca. Ak chcete vykonať akékoľvek zmeny, kedykoľvek sa obráťte na svojho TPA poradcu.",
  "config.accounting.due.accounting.reports.select.title": "Dni po predložení účtovných dokladov",
  "config.accounting.dueDates.title": "Predkladanie účtovných dokladov",
  "config.accounting.due.reportRelease.select.subtitle": "Napríklad 4 dni po zaslaní správ zo strany TPA. To znamená 12. deň nasledujúceho mesiaca (ak TPA zaslala správy 8. deň nasledujúceho mesiaca).",
  "config.accounting.due.reportRelease.select.title": "Dni po odoslaní správ",
  "config.accounting.due.reportRelease.select.value": "{day} Dni po odoslaní správ",
  "config.accounting.due.reportRelease.title": "Schvaľovanie správ zákazníkmi",
  "config.accounting.due.reports.select.subtitle": "Napríklad 3 dni po odovzdaní účtovníctva. To znamená 8. deň nasledujúceho mesiaca (ak sa odovzdanie účtovníctva uskutočnilo 5. deň nasledujúceho mesiaca).",
  "config.accounting.due.reports.select.value": "{day} Dni po odoslaní účtov",
  "config.accounting.due.reports.title": "Zasielanie správ zo strany TPA",
  "config.accounting.due.transfer.select.subtitle": "Napríklad 5. deň nasledujúceho mesiaca.",
  "config.accounting.due.transfer.select.title": "Prenos do",
  "config.accounting.due.transfer.select.value": "{day} Dni po skončení obdobia",
  "config.accounting.due.transfer.title": "Odovzdávanie účtovníctva klientmi",
  "config.accounting.globalReports.description": "Klient má celopodnikové správy",
  "config.accounting.globalReports.title": "Celopodnikové správy",
  "config.accounting.hasAccounting": "Klient má účtovníctvo",
  "config.accounting.hasBankAccountTransactionInvoices": "",
  "config.accounting.hasCockpitKpi": "Fakty a čísla v kokpite",
  "config.accounting.hasKpi": "Fakty a čísla",
  "config.accounting.hasResults": "Hodnotenia",
  "config.accounting.incaseoflaw.description": "",
  "config.accounting.incaseoflaw.label": "",
  "config.accounting.incaseoflaw.title": "",
  "config.accounting.month.description": "Zahŕňa obdobie jedného mesiaca",
  "config.accounting.month.title": "Mesačné účtovníctvo",
  "config.accounting.quarter.description": "Zahŕňa obdobie 3 mesiacov",
  "config.accounting.quarter.title": "Účtovanie za štvrťrok",
  "config.accounting.recordTypes": "Účtovné doklady",
  "config.accounting.recordTypes.ocrDisabled": "",
  "config.accounting.recordTypes.ocrEnabled": "",
  "config.accounting.requireTwoReportReleases": "",
  "config.accounting.subsidiaries.add": "Pridanie kódu spoločnosti",
  "config.accounting.subsidiaries.title": "Definovanie kódov spoločností",
  "config.accounting.subsidiaries.title.customer": "Kódy spoločností",
  "config.accounting.subsidiary.maxReached": "Maximálny počet dosiahnutých kódov spoločností",
  "config.accounting.subsidiary.name": "Názov kódu spoločnosti",
  "config.accounting.week.description": "Trvá 5 pracovných dní a je rozdelená na kalendárne týždne.",
  "config.accounting.week.title": "Týždenné účtovníctvo",
  "config.bulk.button.configure": "Konfigurácia stránky",
  "config.bulk.data.description": "Tu môžete nahrať titulný obrázok a logo spoločností.",
  "config.bulk.data.title": "Hlavné údaje",
  "config.bulk.finish.description": "Spoločnosti sa konfigurujú. Môže to trvať niekoľko minút, nezatvárajte okno prehliadača.",
  "config.bulk.finish.start": "Spustite stránku",
  "config.bulk.finish.title": "Dokončenie konfigurácie",
  "config.companies": "Konfigurácia spoločnosti",
  "config.companies.buttonConfig": "Konfigurácia spoločnosti",
  "config.companies.count": "{count} Spoločnosť",
  "config.companies.description": "V tomto kroku určíte procesy, ktoré sú špecifické pre danú spoločnosť. Patrí medzi ne vizuálny vzhľad v aplikácii TPA Connect, typ a rozsah účtovníctva a miezd, ako aj termíny.",
  "config.companyData.cockpit": "",
  "config.companyData.description": "Tu môžete nahrať titulný obrázok a logo spoločnosti a nastaviť kritériá rezervácie.",
  "config.companyData.title": "Hlavné údaje",
  "config.companyOverview.inactive": "Prehľad spoločnosti / Neaktívne",
  "config.customMenuPoints.deleteCustomMenuPoint.confirmLabel": "Áno, vymazať",
  "config.customMenuPoints.deleteCustomMenuPoint.message": "Naozaj chcete túto položku ponuky odstrániť?",
  "config.customMenuPoints.deleteCustomMenuPoint.title": "Odstrániť položku ponuky",
  "config.customMenuPoints.noMenuPointsAvailable": "Žiadne položky menu nie sú k dispozícii",
  "config.employees.assignRole": "Priradenie úlohy",
  "config.employees.emptyState.message": "V súčasnosti nie sú žiadni aktívni zamestnanci",
  "config.employees.emptyState.title": "Žiadni aktívni zamestnanci",
  "config.employees.table.missingEmail": "Chýbajúca e-mailová adresa",
  "config.employees.table.missingEmail.tooltip": "E-mailovú adresu musí do BMD zadať konzultant TPA",
  "config.features.alreadyToggledToday.disabled": "",
  "config.features.alreadyToggledToday.enabled": "",
  "config.features.confirm.message.disabled": "",
  "config.features.confirm.message.enabled": "",
  "config.features.confirm.title.disabled": "",
  "config.features.confirm.title.enabled": "",
  "config.finishConfiguration": "Dokončenie konfigurácie",
  "config.finishConfiguration.cockpit": "Do kokpitu",
  "config.finishConfiguration.continue": "Vytvorenie ďalšej spoločnosti",
  "config.finishConfiguration.emailNotification": "Upozornenie používateľov e-mailom",
  "config.finishConfiguration.message": "Spoločnosť {company} bola úspešne nakonfigurovaná. Teraz sa môže používať na výmenu medzi TPA a klientom.",
  "config.finishSetup": "EXIT SETUP",
  "config.hr.chat.description": "Mzdoví pracovníci sa môžu obrátiť na poradcov TPA",
  "config.hr.chat.title": "Chat",
  "config.hr.delete.subsidiaries.last.message": "Mzdovú oblasť {name} nemožno vymazať. Musí existovať aspoň jedna mzdová oblasť.",
  "config.hr.delete.subsidiaries.message": "Naozaj chcete odstrániť mzdovú oblasť {name}?",
  "config.hr.delete.subsidiaries.title": "Odstrániť mzdovú oblasť",
  "config.hr.description": "Určite povahu a rozsah mzdovej oblasti.",
  "config.hr.dueDates.reminder": "",
  "config.hr.dueDates.title": "Termíny pre účtovanie miezd",
  "config.hr.due.relativeDueDates": "Dodanie dokumentov v nasledujúcom mesiaci",
  "config.hr.due.reports.select.subtitle": "Napríklad 3 dni po odoslaní výplatnej pásky. To znamená 8. deň nasledujúceho mesiaca (ak sa prenos mzdového účtovníctva uskutočnil 5. deň nasledujúceho mesiaca).",
  "config.hr.due.reports.select.title": "Dni po odoslaní výplatnej pásky",
  "config.hr.due.reports.select.value": "{day} Dní po predložení mzdového účtovníctva",
  "config.hr.due.select.value": "{day}. v mesiaci",
  "config.hr.due.transfer.select.subtitle": "Napríklad 5. deň v mesiaci.",
  "config.hr.due.transfer.select.value.relativeDueDates": "{day} Dni po skončení mesiaca",
  "config.hr.due.transfer.title": "Odosielanie miezd zákazníkmi",
  "config.hr.hasHr": "Klient má mzdy",
  "config.hr.hasResults": "",
  "config.hr.recordTypes": "Mzdové doklady",
  "config.hr.releaseSteps.oneStep.description": "Všetky mesačné hodnotenia zasiela TPA klientovi v jednom kroku. Klient musí vydať všetky potrebné dokumenty v stanovenom termíne.",
  "config.hr.releaseSteps.oneStep.title": "v jednom kroku",
  "config.hr.releaseSteps.title": "Kroky schvaľovania",
  "config.hr.releaseSteps.twoSteps.description": "Odoslanie a zverejnenie mesačného hodnotenia sa uskutočňuje v dvoch krokoch. Klient môže uvoľniť zúčtovanie pred uvoľnením všetkých ostatných mesačných hodnotení.",
  "config.hr.releaseSteps.twoSteps.title": "v dvoch krokoch",
  "config.hr.requireTwoReportReleases": "",
  "config.hr.sendPayroll.description": "Elektronické zasielanie výkazov príjmov",
  "config.hr.sendPayroll.title": "Elektronická evidencia príjmov",
  "config.hr.sendPayroll.tooltip": "Po vystavení výplatnej pásky každý zamestnanec automaticky získa prístup do aplikácie TPA Connect, pomocou ktorej si môže prezerať svoje vlastné záznamy o príjmoch, ako aj elektronický osobný spis.",
  "config.hr.subsidiaries.add": "Pridať mzdovú skupinu",
  "config.hr.subsidiaries.info": "Po výbere prvej účtovnej oblasti je možné vybrať len ďalšie účtovné oblasti rovnakého typu (napr. hlavnú nákladovú oblasť). Ak chcete vybrať nový typ (napr. FIBU kruh), musíte odstrániť všetky účtovné oblasti okrem jednej.",
  "config.hr.subsidiaries.title": "Definujte mzdové oblasti",
  "config.hr.subsidiaries.title.customer": "Mzdové oblasti",
  "config.hr.subsidiary.maxReached": "Dosiahnutý maximálny počet mzdových oblastí",
  "config.hr.subsidiary.name": "Názov účtovnej oblasti",
  "config.hr.upload.description": "Mzdoví zamestnanci môžu nahrať dokumenty do svojho osobného spisu",
  "config.hr.upload.title": "Nahrávanie dokumentov do osobného súboru",
  "config.hr.warning.noBranch": "Mzdy nie je možné aktivovať. Vytvorte si prosím mzdovú spoločnosť v systéme NTCS",
  "config.moduleTypeDetails.asp.helpInstructions": "",
  "config.moduleTypeDetails.asp.helpInstructions.open": "",
  "config.moduleTypeDetails.label.database": "",
  "config.moduleTypeDetails.label.dataSource": "",
  "config.moduleTypeDetails.label.url": "",
  "config.noDueDate": "Žiadny termín",
  "config.optionalMenuPoints.success.title": "Položky menu boli úspešne uložené",
  "config.overview": "Prehľad konfigurácie",
  "config.permission.change": "zmenený na \"{permission}\"",
  "config.permissions.accounting.globalReports": "Celopodnikové účtovné výkazy",
  "config.permissions.deleted": "(vymazané)",
  "config.permissions.description": "Vyberte oblasti, do ktorých má používateľ povolený prístup. Pre každý kód spoločnosti môžete vykonať rôzne nastavenia.",
  "config.permissions.employees": "Zamestnanci",
  "config.permissions.existingUser.navbar.back": "Prehľad konfigurácie / Správa používateľov / Priradenie roly",
  "config.permissions.existingUser.navbar.backToUser": "Prehľad konfigurácie / Správa používateľov / Priradenie roly / {employeeName}",
  "config.permissions.global": "Celá spoločnosť",
  "config.permissions.internationalProjects.info": "Projekty Povolenia nie je možné odobrať: Členovia medzinárodných projektov ich dostávajú automaticky.",
  "config.permissions.navbar.back": "Prehľad konfigurácie / Správa používateľov / Výber kontaktnej osoby / Priradenie roly",
  "config.permissions.navbar.backToUser": "Prehľad konfigurácie / Správa používateľov / Výber kontaktnej osoby / Priradenie roly / {employeeName}",
  "config.permissions.noPermissionSelected.message": "Vyberte aspoň jedno oprávnenie.",
  "config.permissions.noPermissionSelected.title": "Nebolo zvolené žiadne oprávnenie",
  "config.permissions.title": "Prispôsobenie oprávnení",
  "config.roles.change": "Úloha zmenená z {oldRole} na {newRole}",
  "config.roles.customizePermissions": "Prispôsobenie oprávnení",
  "config.roles.description": "Vyberte rolu s preddefinovaným súborom oprávnení. V prípade potreby môžete oprávnenia upraviť v ďalšom kroku.",
  "config.roles.multipleCompaniesDialog.message": "Používateľ {meno} existuje v nasledujúcich spoločnostiach. Chcete vykonať zmeny oprávnení vo všetkých spoločnostiach?",
  "config.roles.multipleCompaniesDialog.title": "Aplikovať zmeny vo viacerých spoločnostiach",
  "config.roles.multipleCompaniesDialog.warning": "Spoločnosti nebolo možné načítať. Zmeny sa použijú len na aktuálnu spoločnosť.",
  "config.roles.navbar.back": "Prehľad konfigurácie / Správa používateľov / Výber kontaktných osôb",
  "config.roles.navbar.heading": "{employeeCount} Vybraný používateľ",
  "config.roles.option.copy.description": "Zadajte meno používateľa, aby ste zdedili jeho rolu a povolenia. V prípade potreby môžete v ďalšom kroku upraviť oprávnenia.",
  "config.roles.option.copy.label": "Prevzatie úlohy od iného používateľa",
  "config.roles.option.copy.placeholder": "Meno používateľa",
  "config.roles.releaseInformation": "Ak chcete upraviť nastavenia používateľa <b>{userName}</b>, musí zmeny schváliť/odmietnuť <b>superadmin</b>.",
  "config.roles.releaseInformation.info.message": "Ak ktokoľvek (okrem superadmina) vykoná zmeny v nastaveniach používateľa, tieto zmeny musí superadmin schváliť alebo zamietnuť. Dovtedy sú nastavenia používateľa uzamknuté a nemožno ich upravovať.",
  "config.roles.releaseInformation.info.title": "Prečo nie je možné upraviť nastavenia používateľa?",
  "config.roles.releaseInformation.superAdmin": "{name} vykonal zmeny v <b>{userName}</b>. Ak chcete pokračovať, potvrďte zmeny používateľa.",
  "config.roles.sidebar.title": "Zvolení používatelia",
  "config.roles.submit": "Navrhnúť používateľa",
  "config.roles.submit.existingUser": "Navrhnúť zmeny",
  "config.roles.submit.superAdmin": "Pozvať používateľa",
  "config.roles.title": "Prideliť rolu",
  "config.roles.viewPermissions": "Zobraziť oprávnenia",
  "config.terms.allSigneesAcceptedWarningDialog.confirmLabel": "OK, uložte",
  "config.terms.allSigneesAcceptedWarningDialog.message": "Pozor: Keďže všetky pridané osoby už podpísali, spoločnosť sa aktivuje po uložení.",
  "config.terms.allSigneesAcceptedWarningDialog.title": "Uložiť podmienky používania",
  "config.terms.alreadySigned": "Podmienky používania ich spoločnosti už boli podpísané.",
  "config.terms.button.revokeActivation": "Zrušenie aktivácie",
  "config.terms.changeDocumentVersionDialog.confirmLabel": "Áno, zmena dokumentu",
  "config.terms.changeDocumentVersionDialog.message": "Ak budete pokračovať, všetky už pridané e-mailové adresy musia znovu prijať podmienky používania.",
  "config.terms.changeDocumentVersionDialog.title": "Dokument o zmene",
  "config.terms.deleteEmailDialog.confirmLabel": "Áno, vymazať e-mailovú adresu",
  "config.terms.deleteEmailDialog.message": "Naozaj chcete odstrániť e-mailovú adresu?",
  "config.terms.deleteEmailDialog.title": "Odstránenie e-mailovej adresy",
  "config.terms.documentVersion": "Verzia dokumentu",
  "config.terms.emailAddressesInfo": "",
  "config.terms.emailAddressesTitle": "E-mailové adresy",
  "config.terms.emailAlreadyUsed": "Táto e-mailová adresa už bola pridaná",
  "config.terms.invalidEmail": "Neplatná e-mailová adresa",
  "config.terms.noDocumentsAvailable": "V súčasnosti nie sú k dispozícii žiadne dokumenty",
  "config.terms.noSigneesAvailable": "Zatiaľ nie sú žiadni signatári",
  "config.termsOfUse.navbar.back": "Prehľad konfigurácie / základné údaje",
  "config.terms.removeEmail": "Odstránenie e-mailovej adresy",
  "config.terms.resendEmail.header": "Odoslaný e-mail",
  "config.terms.resendEmail.message": "E-mail na prijatie podmienok používania bol odoslaný znova",
  "config.terms.resendMail": "Opätovné odoslanie e-mailu",
  "config.terms.revokeActivationDialog.confirmLabel": "áno, aktivácia zrušená",
  "config.terms.revokeActivationDialog.message": "Ste si istí, že chcete zrušiť aktiváciu spoločnosti? Všetky registrované osoby musia opätovne podpísať podmienky používania, aby mohli opätovne aktivovať spoločnosť.",
  "config.terms.revokeActivationDialog.title": "Zrušenie aktivácie",
  "config.terms.signedVersion": "podpísaná verzia:",
  "config.terms.skip.button": "preskočiť",
  "config.terms.skip.dialog.message": "Používatelia na strane klienta sa môžu prihlásiť až po prijatí podmienok používania. Dovtedy je spoločnosť dostupná len na interné účely TPA v aplikácii Connect.",
  "config.terms.skip.dialog.title": "Informácie",
  "config.terms.success.header": "Úspešne uložené",
  "config.terms.termsOfUseInfo": "Vyberte príslušnú verziu dokumentu",
  "config.terms.termsOfUseTitle": "Podmienky používania",
  "config.users": "Správa používateľov",
  "config.users.active.title": "Aktívni používatelia",
  "config.users.assignRole": "Priradenie roly",
  "config.users.buttonConfig": "Správa používateľov",
  "config.users.changeUsername": "Nový e-mail",
  "config.users.changeUsername.tooltip": "Nová e-mailová adresa používateľa je {newUsername}. Kliknutím na tlačidlo sa používateľ vyzve na potvrdenie novej e-mailovej adresy.",
  "config.users.description": "Priradenie používateľov alebo skupín používateľov k jednotlivým procesom. Vy určujete, kto má do ktorých oblastí prístup a v akom rozsahu.",
  "config.users.edit": "Zmena",
  "config.users.emptyState.message": "V súčasnosti nie sú žiadni aktívni používatelia{br}Ak chcete vytvoriť superadmina, použite BMD NTCS.",
  "config.users.emptyState.title": "Žiadni aktívni používatelia",
  "config.users.inviteAgain": "Opätovné pozvanie",
  "config.users.navBar.back": "Prehľad konfigurácie / správa používateľov",
  "config.users.new": "Nový",
  "config.users.newUsers": "Vytvorenie nového používateľa",
  "config.users.newUsers.description": "Vyberte kontaktné osoby, ktoré chcete vytvoriť ako používateľov služby TPA Connect. V ďalšom kroku môžete vybraným osobám priradiť rolu a v prípade potreby upraviť preddefinované oprávnenia.",
  "config.users.newUsers.title": "Vyberte kontaktnú osobu",
  "config.users.reject": "Pokles",
  "config.users.release": "Schváliť",
  "config.users.releaseAndInvite": "Zdieľanie a pozývanie",
  "config.users.table.invalidEmail": "Neplatná e-mailová adresa",
  "config.users.table.invalidEmail.tooltip": "E-mailovú adresu musí opraviť konzultant TPA v BMD",
  "config.users.waiting.title": "Čakanie na schválenie",
  "cookies.acceptAll": "Povoliť všetky súbory cookie",
  "cookies.acceptFunctional": "Používajte len potrebné súbory cookie",
  "cookies.cookieList": "Vyhlásenie o súboroch cookie",
  "cookies.text": "Súbory cookie používame na optimalizáciu našich služieb. Podrobnejšie informácie o tom nájdete na {link}.",
  "country.al": "Albánsko",
  "country.at": "Rakúsko",
  "country.bg": "Bulharsko",
  "country.cz": "Česká republika",
  "country.hr": "Chorvátsko",
  "country.hu": "Maďarsko",
  "country.me": "Čierna Hora",
  "country.pl": "Poľsko",
  "country.ro": "Rumunsko",
  "country.rs": "Srbsko",
  "country.si": "Slovinsko",
  "country.sk": "Slovensko",
  "deleteDialog.message.plural": "Naozaj chcete odstrániť dokumenty <b>{documents}</b>?{br}{br}Túto akciu nemožno vrátiť späť.",
  "deleteDialog.message.singular": "Naozaj chcete vymazať dokument <b>{documents}</b>?{br}{br}Túto akciu nemožno vrátiť späť.",
  "deleteDialog.title": "Vymazať dokumenty?",
  "deleteUser.message": "Ste si istí, že chcete odstrániť používateľa <b>{meno}</b>?",
  "deleteUser.multipleCompanies.confirmLabel": "Odstránenie používateľa vo všetkých spoločnostiach",
  "deleteUser.multipleCompanies.message": "Používateľ je prítomný v týchto spoločnostiach:",
  "deleteUser.title": "Odstránenie používateľa",
  "deleteUser.warning.superadminskipped": "",
  "dialog.archive.checkbox_pdf": "Uložiť PDF lokálne",
  "dialog.archive.subject": "Predmet",
  "dialog.archive.success.text": "Teraz môžete pristupovať k archivovaným správam v {bmd}.",
  "dialog.archive.success.title": "Správy boli úspešne archivované!",
  "dialog.archive.text": "Vyberte spoločnosť, pre ktorú chcete uložiť vybrané správy do BMD.",
  "dialog.archive.title_multiple": "{count} Archívne správy",
  "dialog.archive.title_single": "1 Archívna správa",
  "dialog.assignTicket.header": "Priradiť dopyt",
  "dialog.assignTicket.message": "Vyberte osobu, ktorej chcete priradiť dopyt.",
  "dialog.button.understood": "Rozumiem",
  "dialog.closeTicket.success.header": "Dopyt sa uskutočnil",
  "dialog.companyConfig.success.header": "Konfigurácia spoločnosti bola úspešne uložená.",
  "dialog.companyConfig.success.message": "Oblasti pre {name} sú teraz pripravené.",
  "dialog.eldaTransfer.andNext": " a ďalej",
  "dialog.eldaTransfer.downloadLater": "Stiahnuť neskôr",
  "dialog.eldaTransfer.success.header": "Úspešne podaná žiadosť",
  "dialog.loginError.multiFactorCodeWrong.title": "Zadaný kód je bohužiaľ nesprávny",
  "dialog.loginError.subtitle": "Skúste to prosím znova.",
  "dialog.loginError.title": "Neznáma e-mailová adresa alebo nesprávne heslo",
  "dialog.noPhoneNumberWarning.message": "Aspoň jedna z vybraných kontaktných osôb nemá nastavené telefónne číslo. Bez telefónneho čísla nie je k dispozícii dvojfaktorové overenie a pozvánka nemusí byť bezpečná.",
  "dialog.noPhoneNumberWarning.title": "Nie je nastavené žiadne telefónne číslo",
  "dialog.passwordExpired.message": "",
  "dialog.passwordExpired.title": "",
  "dialog.password_reset.success.header": "Heslo bolo úspešne zmenené",
  "dialog.registration.success.button": "Prihláste sa teraz",
  "dialog.registration.success.header": "Úspešne ste sa zaregistrovali!",
  "dialog.registration.success.message": "Prihláste sa pomocou služby Microsoft a používajte službu TPA Connect.",
  "dialog.transferReports.success.message": "Váš klient si teraz prezrie súbory a kontaktuje vás s prípadnými otázkami.",
  "dialog.transfer.success.header": "Úspešne odoslané súbory",
  "dialog.userConfig.success.header": "Uloženie používateľských nastavení",
  "dialog.user_not_found.button": "Rozumiem",
  "dialog.user_not_found.subtitle": "Obráťte sa na správcu systému alebo sa prihláste pomocou konta TPA Connect.",
  "dialog.user_not_found.title": "Nie je známe žiadne používateľské konto s touto e-mailovou adresou",
  "documentUpload.error.amount": "<b>{failedUploads}</b> z {uploads} súbory nebolo možné nahrať:",
  "documentUpload.error.button.text.continue": "Pokračujte bez týchto súborov",
  "documentUpload.error.button.text.retry": "Skúste to znova",
  "documentUpload.error.message": "Chyba pri nahrávaní súborov",
  "documentUpload.error.rejection.amount": "<b>{failedUploads}</b> z {uploads} súbory nemožno nahrať:",
  "documentUpload.error.tooBigImages.amount": "<b>{failedUploads}</b> Obrázky sú väčšie ako max. {maxImageDimension} pixelov. Ich veľkosť sa automaticky zmenší. Pokračovať?",
  "documentUpload.error.tooBigImages.amount.singular": "Obrázok je väčší ako max. {maxImageDimension} pixelov. Jeho veľkosť sa automaticky zmenší. Pokračovať?",
  "documentUpload.error.tooBigImages.button.text.continue": "Oddialiť a pokračovať",
  "documentUpload.error.tooBigImages.pdf": "Obrázky v PDF sú väčšie ako max. {maxImageDimension} pixelov. Prosím, zmenšite veľkosť obrázkov.",
  "documentUpload.error.tooManyFiles.plural": "",
  "documentUpload.error.tooManyFiles.singular": "",
  "documentUpload.max.size": "Maximálna veľkosť dokumentu {maxUploadSizeMb} Mb",
  "documentUpload.tooBigImages.message": "Príliš veľké obrázky",
  "documentUpload.type.file.formats": "Podporované formáty",
  "dropzone.button.label.search": "vyhľadať to.",
  "dropzone.image.text": "Jednoducho potiahnite obrázok do tejto oblasti alebo",
  "dropzone.info.redo": "Znovu nahrať",
  "dropzone.info.text": "Jednoducho pretiahnite súbory do tejto oblasti alebo",
  "dueDate.answerAt": "na ktoré sa má odpovedať do {date}",
  "dueDate.closedAt": "dokončené dňa {date}",
  "dueDate.sentAt": "odoslaná dňa {date}",
  "dueDate.sentAtWithTime": "odoslané dňa {date} v {time}",
  "dueDate.transferredAt": "odoslané dňa {date}",
  "dueDate.until": "do {date}",
  "employmentInsuranceType.apprentice": "Učeň",
  "employmentInsuranceType.fullTime": "plne poistený",
  "employmentInsuranceType.independentContractor": "bezplatná DN",
  "employmentInsuranceType.marginalIndependentContractor": "Okrajovo voľné DN",
  "employmentInsuranceType.marginally": "okrajovo",
  "employmentRelationship.employee": "Zamestnanec",
  "employmentRelationship.worker": "Pracovníci",
  "error.accounting.postSubsidiary": "Kód spoločnosti sa nepodarilo vytvoriť",
  "error.accounting.subsidiaryDoesNotExist": "Kód spoločnosti nebol nájdený",
  "error.activate": "Chyba aktivácie",
  "error.addBankAccountTransactionInvoices": "",
  "error.addBankConnection": "Bankové údaje nebolo možné pridať",
  "error.addCompanyToUserFavorites": "Spoločnosť nemohla byť pridaná do vašich obľúbených položiek",
  "error.addFolderBadRequest": "Priečinok sa nepodarilo vytvoriť. Uistite sa, že ste nepoužili žiadne nepovolené špeciálne znaky: \" * : < > ? / \\ |",
  "error.archiveDisabledForCompany": "Archivácia nie je v tejto spoločnosti možná",
  "error.archiveFailed": "Chyba počas archivácie",
  "error.archiveProjectFailed": "Projekt nebolo možné archivovať",
  "error.avatarUploadFailed": "Profilový obrázok sa nedal nahrať. Uistite sa, že formát je JPEG alebo PNG a veľkosť súboru je max. {maxSize}MB",
  "error.avatarUploadOnlyOneImage": "Môžete nahrať len jeden obrázok",
  "error.changeUsernameEmail": "E-mail na zmenu používateľského mena sa nepodarilo odoslať",
  "error.changeUsername.newUsernameConflict": "Nový e-mail sa nezhoduje s uloženým e-mailom.",
  "error.changeUsername.newUsernameDuplicate": "E-mail, ktorý sa už používa.",
  "error.changeUsername.noNewUsername": "Nebola pridelená žiadna nová e-mailová adresa.",
  "error.changeUsername.tokenExpired": "Platnosť tohto prepojenia vypršala.",
  "error.changeUsername.tokenNotFound": "Neplatné prepojenie, bola nová e-mailová adresa už potvrdená?",
  "error.chatForbidden": "V tejto spoločnosti nie je aktivovaná služba Face to Face.",
  "error.chatNoCompany": "Správa bez firemného kontextu.",
  "error.chatNoRecipient": "Správa nemá príjemcu.",
  "error.chatSearch": "Vyhľadávanie bolo neúspešné. Skúste to prosím znova.",
  "error.completeSign": "Elektronický podpis nebolo možné vyplniť",
  "error.config.accounting.duplicate": "Zadali ste niekoľko kódov spoločností s rovnakým názvom",
  "error.config.accounting.noSubsidiary": "Ak chcete pokračovať, zadajte aspoň jeden kód spoločnosti.",
  "error.config.activateCompanyFailed": "Tento zákazník ešte nebol technicky pripravený na používanie služby TPA Connect. Obráťte sa na IT podporu a požiadajte o vytvorenie vhodného používateľa.",
  "error.config.corruptSubsidiary": "Kód spoločnosti bez ID",
  "error.config.hr.duplicate": "Zadali ste niekoľko mzdových oblastí s rovnakým názvom",
  "error.config.hr.noSubsidiary": "Na pokračovanie nastavte aspoň jednu mzdovú oblasť.",
  "error.copy.alreadyExists.file": "",
  "error.copy.alreadyExists.folder": "",
  "error.copy.alreadyExists.unknown": "",
  "error.create": "Chyba pri vytváraní",
  "error.create.alreadyExists.project": "Projekt s týmto názvom už existuje",
  "error.createEmployee": "Zamestnanca nebolo možné vytvoriť",
  "error.delete": "Chyba pri vymazávaní",
  "error.deleteBankAccountTransactionInvoices": "",
  "error.deleteBankConnection": "Bankové údaje nebolo možné vymazať",
  "error.delete.fileLocked": "Súbor sa práve upravuje, a preto ho nemožno vymazať.",
  "error.deleteUser": "Používateľa nebolo možné vymazať. Skúste to prosím neskôr.",
  "error.deregisterEmployee": "Zamestnanca nebolo možné odhlásiť",
  "error.documentForbidden": "Nemáte prístup k dokumentu",
  "error.documentInProgress": "Dokument sa odosiela do TPA a nie je možné ho prevziať. Skúste to čoskoro znova.",
  "error.documentNotFound": "Dokument nebol nájdený",
  "error.documentsInProgress": "Dokumenty sa odosielajú do TPA a nie je možné ich prevziať. Skúste to čoskoro zopakovať.",
  "error.download": "Chyba pri sťahovaní",
  "error.downloadTermsOfUseDocument": "Pri preberaní podmienok používania došlo k chybe",
  "error.download.transferInProgress": "Dokument sa prenáša. Prosím, skúste to znova o niečo neskôr.",
  "error.dueDate.minDate": "Dátum uzávierky nesmie byť v minulosti",
  "error.duplicateName": "Meno je už použité",
  "error.duplicateUsername": "Novo pridelená e-mailová adresa sa už používa",
  "error.edit": "Chyba počas úprav",
  "error.employeeWithoutEmail": "",
  "error.folderIsEmpty": "Priečinok je prázdny, a preto ho nemožno stiahnuť",
  "error.foldersAreEmpty": "Všetky vybrané priečinky sú prázdne, a preto ich nie je možné stiahnuť",
  "error.forbidden": "Žiadne povolenie",
  "error.general": "Ospravedlňujeme sa, došlo k chybe. Skúste to prosím neskôr.",
  "error.getSign": "Pri načítaní požadovaných informácií pre elektronický podpis došlo k chybe",
  "error.hr.noSubsidiarySelected": "Nie je vybraná žiadna mzdová oblasť ",
  "error.hr.postSubsidiary": "Mzdovú oblasť sa nepodarilo vytvoriť",
  "error.hr.subsidiaryDoesNotExist": "Mzdová oblasť nebola nájdená",
  "error.imageCrop": "Chyby pri spracovaní obrazu",
  "error.imageTooBig": "Príliš veľký obrázok",
  "error.invalidCharacters": "Vstup obsahuje neplatné znaky",
  "error.invalidCompanyId": "Neplatné ID spoločnosti",
  "error.invalid_credentials": "Nesprávne ID používateľa alebo heslo",
  "error.invalidDateFormat": "Nesprávny formát dátumu",
  "error.invalidEmployee": "Údaje o zamestnancovi sú neúplné alebo nesprávne",
  "error.invalidPeriodId": "Neplatné obdobie pre túto spoločnosť",
  "error.invalidRolePermissions": "Oprávnenia rolí sú neplatné",
  "error.invalidSsn": "Neplatné číslo sociálneho poistenia",
  "error.invalidTicket": "Neplatný lístok",
  "error.invalidWebhookParams": "Neplatné parametre webhooku.",
  "error.invitationNoCompany": "Pozvánka bez IČO",
  "error.linkExpired": "Platnosť tohto prepojenia vypršala. Požiadajte o nový.",
  "error.linkInvalid": "Toto prepojenie je neplatné.",
  "error.loadAccount": "Účet sa nepodarilo načítať",
  "error.loadAccountListing": "Zoznam zostatkov sa nepodarilo načítať",
  "error.loadAccounts": "",
  "error.loadAccountTransactions": "Rezervácie nebolo možné načítať",
  "error.loadArchiveDocuments": "Nie všetky archívne dokumenty sa dali načítať",
  "error.loadBadges": "Chyba pri načítaní oznámení",
  "error.loadBalanceSheet": "Bilancia sa nedala načítať",
  "error.loadBankAccountTransactions": "",
  "error.loadBankConnections": "Bankové údaje nebolo možné načítať",
  "error.loadBranches": "Pobočky sa nepodarilo načítať",
  "error.loadCashAccounting": "Účet príjmov a výdavkov nebolo možné načítať",
  "error.loadCompany": "Spoločnosť nebolo možné načítať",
  "error.loadCompanyFeatures": "",
  "error.loadContacts": "Kontakty sa nedali načítať",
  "error.loadCostCenters": "Nákladové strediská nebolo možné načítať",
  "error.loadCountries": "Údaje o krajine sa nepodarilo načítať",
  "error.loadCustomerListing": "",
  "error.loadDestinations": "Miesta obsadenia nebolo možné načítať. Skúste to prosím neskôr",
  "error.loadDocument": "Dokument sa nepodarilo načítať",
  "error.loadDocuments": "Dokumenty nebolo možné načítať",
  "error.loadDraft": "Vydanie sa nedalo načítať",
  "error.loadEmployee": "Zamestnanca nebolo možné načítať",
  "error.loadEmployeeDocuments": "Súbory nebolo možné načítať",
  "error.loadEmployees": "Zamestnanci nemohli byť pozvaní",
  "error.loadEvents": "Udalosti nebolo možné načítať, skúste to neskôr",
  "error.loadFeatureFlags": "",
  "error.loadFeedCountries": "Krajiny nebolo možné načítať.",
  "error.loadFinancialAccountancies": "",
  "error.loadFormData": "Údaje formulára sa nepodarilo načítať",
  "error.loadFunders": "Nákladové objekty nebolo možné načítať",
  "error.loadGeneralDocumentsTimeout": "",
  "error.loading": "Chyba načítania",
  "error.loadInsuranceCarrierBalance": "",
  "error.loadInsuranceCarriers": "Chyba pri načítaní fondov zdravotného poistenia",
  "error.loadInvitationResults": "Chyba pri načítaní uvoľnených zmien používateľa",
  "error.loadKpiConfigurations": "",
  "error.loadKpis": "Fakty a čísla sa nedali načítať, skúste to neskôr",
  "error.loadMessages": "Správy nebolo možné načítať",
  "error.loadModuleTypeDetails": "",
  "error.loadNews": "Správy nebolo možné načítať, skúste to neskôr",
  "error.loadNews.noSource": "Kanál správ je momentálne nedostupný.",
  "error.loadNotifications": "Nastavenia oznámení sa nepodarilo načítať",
  "error.loadOptionalMenuItems": "Voliteľné položky ponuky sa nedali načítať",
  "error.loadPayrollAccount": "",
  "error.loadPayrollAccountDetails": "",
  "error.loadPendingMicrosoftActions": "Pozvánky a informácie o obnovení sa nepodarilo načítať",
  "error.loadPeriod": "Chyba pri načítaní obdobia",
  "error.loadPeriodData": "Chyba pri načítaní údajov o období",
  "error.loadPermissions": "Oprávnenia nebolo možné načítať",
  "error.loadPersonnelFile": "Personálny súbor sa nepodarilo načítať",
  "error.loadPersonnelFile.notStaff": "Používateľ nie je mzdový zamestnanec",
  "error.loadPowerBIReportEmbedToken": "",
  "error.loadPowerBIReports": "",
  "error.loadPreRegistration": "Predbežný záznam sa nepodarilo načítať.",
  "error.loadProfile": "Profil sa nepodarilo načítať",
  "error.loadProfitAndLoss": "G&V sa nedá načítať",
  "error.loadProjects": "Projekty sa nepodarilo načítať",
  "error.loadProjectsCompanies": "Spoločnosti pre projekty nebolo možné načítať",
  "error.loadPromotions": "Položky nebolo možné načítať, skúste to neskôr",
  "error.loadPublications": "Publikácie sa nedali načítať, skúste to neskôr",
  "error.loadRecord": "Dokument sa nepodarilo načítať",
  "error.loadRecords": "Dokumenty nebolo možné načítať",
  "error.loadRecordTypes": "Chyba pri načítavaní typov rezervácie",
  "error.loadReports": "Správy nebolo možné načítať",
  "error.loadResponsibleUsers": "Poradcovia sa nedali načítať",
  "error.loadRolePermissions": "Chyba pri načítaní oprávnení rolí",
  "error.loadSubsidiaries": "Chyba pri načítaní kódov spoločnosti",
  "error.loadSubsidiary": "Chyba pri načítaní kódu spoločnosti",
  "error.loadTeam": "Členov tímu nebolo možné načítať",
  "error.loadTermsofUse": "Pri načítaní podmienok používania došlo k chybe",
  "error.loadTicketDetails": "Konzultácia sa nedala načítať",
  "error.loadTickets": "Dopyty nebolo možné načítať",
  "error.loadUserCompanySettings": "Chyba pri načítaní používateľských nastavení",
  "error.loadUsers": "Používateľov nebolo možné načítať",
  "error.loadVendorListing": "",
  "error.loadWaitingUsers": "Chyba pri načítaní používateľov, ktorí majú byť prepustení",
  "error.loginAccountSetup": "",
  "error.logout": "Chyba pri odhlásení",
  "error.markAsDone": "Ako dokončené môžete označiť len tie dopyty, ktoré ste vytvorili",
  "error.markBankAccountTransactionAsPrivate": "",
  "error.maxCharacters": "Maximálny povolený počet znakov",
  "error.mfaActivation.codeNotMatched": "Zadaný kód je nesprávny",
  "error.mfaCodeAlreadySent": "Kód už bol odoslaný, pred vyžiadaním ďalšieho kódu počkajte aspoň 20 sekúnd.",
  "error.mfaCodeWrong": "Je nám ľúto, ale zadaný kód je nesprávny. Skúste to prosím znova.",
  "error.mfaSettingChange": "Funkciu 2FA nie je možné aktivovať ani deaktivovať, pretože už bol vyžiadaný kód na aktiváciu. Pred ďalším pokusom počkajte aspoň 20 sekúnd.",
  "error.mfaSettingChange.codeAlreadySent": "Už bola podaná žiadosť o nový kód. Pred ďalším pokusom počkajte aspoň 20 sekúnd.",
  "error.mfaSettingChange.codeNotMatched": "Zadaný kód je nesprávny",
  "error.microsoftLogin": "Pri komunikácii so spoločnosťou Microsoft došlo k problému. Skúste sa prihlásiť znova.",
  "error.missingDocument": "Chýbajúci dokument",
  "error.missingEmail": "Chýbajúca e-mailová adresa",
  "error.missingEmployee": "Chýbajúci zamestnanec",
  "error.missingParams": "Chýbajúce parametre",
  "error.move": "Chyba presunu",
  "error.move.alreadyExists.unknown": "",
  "error.msTokenExpired": "Platnosť vášho tokenu MS vypršala. Prosím, obnovte ho.",
  "error.multipleArchives": "K dispozícii je niekoľko archívov klientov tejto spoločnosti",
  "error.noArchive": "Pre túto spoločnosť nie je k dispozícii žiadny archív klientov",
  "error.noCompanySelected": "Žiadna vybraná spoločnosť",
  "error.noDocumentSelected": "Vyberte si dokument pre podmienky používania",
  "error.noDotAtEnd": "Posledný znak nesmie byť bodka",
  "error.noFinancialAccountancies": "Spoločnosť nemá žiadne FIBU",
  "error.noMsAccountAvailable": "Používateľa nemožno pozvať. Očakáva sa, že zamestnanci TPA už majú vytvorené konto Microsoft.",
  "error.noRecordTypes": "Nenašli sa žiadne kategórie dokumentov",
  "error.noSignees": "Pridajte e-mailové adresy",
  "error.noSubsidiaries.accounting": "Nenašli sa žiadne kódy spoločností",
  "error.noSubsidiaries.hr": "Neboli nájdené žiadne mzdové oblasti",
  "error.noSubsidiaryWithEmployeeRead": "Žiadny mzdový okruh s právom na čítanie zamestnancov.",
  "error.notAdvisor": "Žiadne povolenia pre konzultantov",
  "error.notAllowed": "Akcia sa nesmie vykonať",
  "error.notificationCenter.load": "",
  "error.notificationCenter.markAllAsRead": "",
  "error.notificationCenter.markAsRead": "",
  "error.oauthApi": "Vyskytol sa problém s overovaním. Skúste to prosím znova.",
  "error.oauthCompany": "Žiadna vybraná spoločnosť. Vyberte spoločnosť a skúste to znova.",
  "error.oauthUrl": "Vyskytol sa problém s vyvolaním položky Fakty a čísla. Skúste to prosím znova.",
  "error.overlappingSignature": "Prekrývajúci sa podpis. Vyberte novú pozíciu",
  "error.passwordReset.tokenExpired": "Platnosť tohto prepojenia na obnovenie hesla vypršala",
  "error.passwordReset.tokenNotFound": "Neplatné prepojenie na obnovenie hesla",
  "error.payAccountTransaction": "",
  "error.payTaxAccount": "",
  "error.payUnpaidAccount": "",
  "error.periodClosed": "Už ukončené obdobie",
  "error.permission.corruptRoles": "Neplatné úlohy",
  "error.permission.onlyOneRoleAllowed": "Môže sa nastaviť len jeden hod",
  "error.postTicket": "Dopyt nebolo možné vytvoriť.",
  "error.projectForbidden": "Nemáte prístup k projektom",
  "error.projectIsEmpty": "Projekt je prázdny, a preto sa nedá stiahnuť",
  "error.projectPermissions": "Oprávnenia projektu sa nepodarilo načítať",
  "error.projects.cantMove": "Súbory nebolo možné presunúť. Skúste to prosím neskôr.",
  "error.projects.cantPaste": "Súbory nebolo možné vložiť. Skúste to prosím neskôr.",
  "error.projects.openBookmarkUrlFailed": "Adresu URL sa nepodarilo otvoriť",
  "error.qes.missingSignaturePositions": "",
  "error.recordForbidden": "Nemáte prístup k poukazu",
  "error.recordWithNotAllowedCompany": "Žiadne povolenie pre spoločnosť, ktorej je dokument pridelený.",
  "error.refreshBankConnection": "Bankový účet nebolo možné obnoviť",
  "error.releaseEmployeeDocument": "Chyba uvoľnenia",
  "error.releaseReport": "Zdieľanie zlyhalo",
  "error.removeBankAccountTransactionPrivateMark": "",
  "error.removeCompanyFromUserFavorites": "Spoločnosť nebolo možné odstrániť z vašich obľúbených položiek",
  "error.rename.alreadyExists.file": "Chyba pri premenovaní: Súbor s týmto názvom už existuje",
  "error.rename.alreadyExists.folder": "Chyba pri premenovaní: Priečinok s týmto názvom už existuje",
  "error.requiredField": "Požadovaný vstup",
  "error.resendTermsOfUseEmail": "Pri opätovnom odosielaní e-mailu došlo k chybe",
  "error.save": "Chyba pri ukladaní",
  "error.saveKpiConfigurations": "",
  "error.saveOptionalMenuItems": "Voliteľné položky ponuky sa nepodarilo načítať. Skúste to prosím neskôr",
  "error.saveTermsOfUse": "Pri ukladaní podmienok používania došlo k chybe. Skúste to prosím neskôr",
  "error.send": "Chyba pri odosielaní.",
  "error.setDocumentOrSignees": "Pri ukladaní dokumentu alebo osôb, ktoré sa majú podpísať, došlo k chybe",
  "error.settings": "Chyba pri zmene nastavení",
  "error.signFileTooLarge": "",
  "error.signReadOnlyDocument": "Dokument je určený len na čítanie, a preto ho nemožno podpísať",
  "error.signTermsOfUse": "Pri akceptovaní podmienok používania došlo k chybe. Skúste to prosím neskôr",
  "error.subsidiaryWithoutRecordTypes": "{subsidiary} nemá žiadne aktívne symboly dokumentov",
  "error.termsOfUseDuplicateFile": "Rovnaký súbor s iným názvom už existuje",
  "error.termsOfUseFolderNotFound": "Zatiaľ nebol vytvorený žiadny priečinok s podmienkami používania",
  "error.timeout": "Tento proces v súčasnosti trvá o niečo dlhšie. Skúste to prosím neskôr.",
  "error.toggleCompanyFeature": "",
  "error.tokenRefresh": "Chyba overenia",
  "error.tooManyCharacters": "Maximálny počet {maxCharacters} znakov povolených na správu.",
  "error.transfer": "Chyba prenosu",
  "error.transferEmployee": "Zamestnanec nebolo možné preniesť",
  "error.transferEmployeeDirect": "Prenos do fondu zdravotného poistenia sa nepodaril. Obráťte sa na svojho poradcu.",
  "error.transferEmployee.validation.general": "",
  "error.transferEmployee.validation.general.zipcode": "",
  "error.unknown": "Neznáma chyba ({statusCode})",
  "error.unsupportedDocumentType": "Tento typ dokumentu nie je podporovaný",
  "error.updateBankAccountAccountNumber": "",
  "error.upgradeToMicrosoft": "Aktualizáciu nebolo možné vykonať. Skontrolujte svoje heslo.",
  "error.upload": "Chyba pri nahrávaní",
  "error.userDeactivated": "Tento používateľ spoločnosti Microsoft bol deaktivovaný.",
  "error.userNotRegistered": "Používateľ nie je registrovaný",
  "error.userUnknown": "Neznámy používateľ",
  "externalLinkDialog.content": "",
  "externalLinkDialog.title": "",
  "failedUserDialog.amount": "<b>{failedUserCount}</b> z {userCount} používateľov nebolo možné vytvoriť/zmeniť:",
  "failedUserDialog.button.text.continue": "Pokračovať napriek tomu",
  "failedUserDialog.button.text.retry": "Skúsiť znova",
  "failedUserDialog.error.emailAlreadyExists": "Táto e-mailová adresa sa už používa",
  "failedUserDialog.error.pendingPermissionChanges": "Už čaká na schválenie",
  "failedUserDialog.message": "Chyba pri vytváraní/zmene",
  "features.default.prices.from": "",
  "features.default.prices.fromFlat": "",
  "features.default.prices.fromTo": "",
  "features.default.prices.fromToFlat": "",
  "filterDialog.apply": "Použiť filter",
  "filterDialog.category.advanced": "Rozšírené filtre",
  "filterDialog.category.assigned": "Pridelená oblasť",
  "filterDialog.header": "Možnosti filtrovania",
  "filterDialog.required": "",
  "filterDialog.reset": "RESETOVAŤ FILTER",
  "finishProjectDialog.text": "Ste si istí, že chcete dokončiť projekt <b>{projectName}</b>?",
  "finishProjectDialog.title": "Kompletný projekt",
  "footer.copyright": "Všetky práva vyhradené",
  "footer.disclaimer": "Zrieknutie sa zodpovednosti",
  "footer.generalBusinessTerms": "AGB",
  "footer.legalInformation": "Odtlačok",
  "footer.privacy": "Ochrana údajov",
  "helpcenter.ideas": "",
  "helpcenter.problems": "",
  "helpcenter.problems.email": "",
  "helpcenter.problems.send": "",
  "helpcenter.problems.upload.failed": "",
  "helpcenter.problems.upload.success": "",
  "helpcenter.problems.upload.tooManyRequests": "",
  "helpcenter.problems.whathappened": "",
  "helpcenter.problems.whatshouldhappen": "",
  "helpcenter.problems.whatwasdone": "",
  "helpCenter.title": "",
  "helpcenter.videos": "",
  "helpcenter.videos.tpaonly": "",
  "hr.casualEmployment": "Zamestnávanie na individuálnom základe",
  "hr.disclaimer.additionalData": "Na priamy prenos potrebujeme ešte niekoľko ďalších údajov. Prosím, vyplňte ich.",
  "hr.disclaimer.employmentDate": "Dátum nástupu do zamestnania",
  "hr.disclaimer.subtitle": "Chcete údaje posielať TPA alebo priamo zdravotnej poisťovni?",
  "hr.disclaimer.title": "Prenos",
  "hr.disclaimer.transfer.direct": "Prenos údajov sa má uskutočniť priamo do rakúskej zdravotnej poisťovne",
  "hr.disclaimer.transfer.direct.subtitle": "Podotýkame, že TPA bude údaje kontrolovať najskôr v najbližších dňoch, keď bude úrad otvorený.",
  "hr.disclaimer.transfer.tpa": "Prenos údajov sa uskutočňuje do TPA",
  "hr.disclaimer.transfer.tpa.subtitle": "Upozorňujeme, že registrácia zamestnancov v rakúskej zdravotnej poisťovni sa uskutoční až po spracovaní údajov TPA.",
  "hr.documents.backLabel": "Personál/súbory",
  "hr.documents.emptyState.header": " zadajte",
  "hr.documents.emptyState.message": "Začnite nahrávať svoje súbory teraz.",
  "hr.documents.list.backLabel": "Personál/dokumenty",
  "hr.documents.nonWorkingTimes": "Časy bez výkonu",
  "hr.documents.nonWorkingTimesUpload": "Čas nefunkčnosti nahrávania súborov",
  "hr.documents.upload.navHeader": "{documentType}",
  "hr.documents.upload.navLabel": "Personál/dokumenty",
  "hr.employee.employment": ". Pracovný pomer",
  "hr.employee.employmentRelationship": "Pracovný pomer",
  "hr.employee.jobDescription": "Názov pracovnej pozície",
  "hr.employee.payslip.upload.date": "",
  "hr.employee.payslip.upload.title": "",
  "hr.employee.payslip.upload.type": "",
  "hr.employee.payslip.upload.type.monthly": "",
  "hr.employee.payslip.upload.type.yearly": "",
  "hr.employee.reasonForLeaving": "Dôvod odchodu",
  "hr.employee.workplaceState": "Stav pracoviska",
  "hr.finished.analysis": "Mesačné hodnotenia",
  "hr.finished.billing": "Mesačné výpisy",
  "hr.finished.globalAnalysis": "Celopodnikové hodnotenie",
  "hr.finished.globalBilling": "Celopodnikové účtovníctvo",
  "hr.insuranceCarrier": "Poisťovateľ",
  "hr.overview.continuousActions": "Priebežné funkcie",
  "hr.recordTypes.names.benefitsInKindCar": "",
  "hr.recordTypes.names.contractAdjustmentsAndSpecialPayments": "",
  "hr.recordTypes.names.mealDeductions": "",
  "hr.recordTypes.names.nonWorkingTimes": "",
  "hr.recordTypes.names.overtimeAndBonusPayments": "",
  "hr.recordTypes.names.supplementaryDocuments": "",
  "hr.recordTypes.names.timeRecords": "",
  "hr.recordTypes.names.travelExpenseReport": "",
  "hr.tickets.preRegistration.NavBar": "Dotaz na {preRegistration}",
  "hr.tickets.preRegistration.NavBarLabel": "Personál / Zamestnanci / Otázky",
  "hr.tickets.preRegistration.toOverview": "DO OSOBNÉHO SPISU",
  "imageCrop.description": "Potiahnutím presuniete obrázok",
  "imageCrop.title": "Pozičný obrázok",
  "imageCrop.zoom": "Priblíženie",
  "language.german": "Nemčina",
  "maritalStatus.divorced": "rozvedený",
  "maritalStatus.livingInPartnership": "Život v partnerstve",
  "maritalStatus.married": "Manželstvo/registrované partnerstvo",
  "maritalStatus.separated": "Oddelené bývanie",
  "maritalStatus.unknown": "neznáme",
  "maritalStatus.unmarried": "slobodný",
  "maritalStatus.widowed": "ovdovený",
  "menu.answer": "Odpoveď",
  "menu.assign": "Priradenie",
  "menu.cancelRelease": "Odobrať súhlas",
  "menu.delete": "Odstrániť",
  "menu.details": "Podrobnosti",
  "menu.download": "Stiahnuť",
  "menu.draft": "Žiadosť o schválenie",
  "menu.edit": "Upraviť",
  "menu.editRelease": "Opätovná žiadosť o schválenie",
  "menu.finish": "Dokončiť",
  "menu.markAsDone": "Označiť ako hotové",
  "menu.move": "Presun",
  "menu.openInBrowser": "Otvoriť v prehliadači",
  "menu.openInSharepoint": "Otvoriť v Sharepointe",
  "menu.rename": "Premenovať",
  "menu.requestRelease": "Žiadosť o schválenie",
  "menu.showReleases": "Zobraziť súhlas",
  "menu.view": "Zobraziť",
  "mfa.activation.dialog.code.required": "Zadajte prosím kód",
  "mfa.activation.dialog.description": "Na aktiváciu dvojfaktorového overovania zadajte kód, ktorý sme vám poslali na vaše telefónne číslo.",
  "mfa.activation.dialog.title": "Aktivácia funkcie 2FA",
  "mfa.activation.success.dialog.title": "2FA bola úspešne aktivovaná",
  "mfa.changeSetting.dialog.code.field.label": "Kód",
  "mfa.changeSetting.dialog.resendCode": "Opätovné odoslanie kódu",
  "mfa.codeResend.success.dialog.title": "Kód bol opäť odoslaný",
  "mfa.deactivation.dialog.description": "Zadajte kód, ktorý sme vám poslali na vaše telefónne číslo, aby ste deaktivovali dvojfaktorové overovanie.",
  "mfa.deactivation.dialog.title": "Deaktivácia funkcie 2FA",
  "mfa.deactivation.success.dialog.title": "Funkcia 2FA bola úspešne deaktivovaná",
  "mfa.validation.code.length": "Kód má 6 číslic",
  "mfa.validation.code.required": "Musí sa zadať kód",
  "mfa.validation.code.type": "Povolené sú len čísla",
  "microsoft_oauth.button.login.title": "Prihlásenie pomocou služby Microsoft",
  "microsoft_oauth.button.send_code.title": "Odoslať kód",
  "microsoft_oauth.button.upgrade.title": "Vykonajte upgrade",
  "missingEmailDialog.description": "E-mailová adresa <b>{name}</b> nie je v súbore. Zadajte platnú e-mailovú adresu, aby bolo možné zaslať pozvánku.",
  "missingEmailDialog.title": "Chýbajúca e-mailová adresa",
  "missingEmail.email.error": "Zadajte prosím správnu e-mailovú adresu",
  "missingEmail.email.label": "Zadajte e-mailovú adresu",
  "module.finished.globalReports": "Celopodnikové správy",
  "modules.delete.report.message": "Naozaj chcete vymazať správu {name}?",
  "modules.delete.report.title": "Vymazať správu",
  "navbar.week": "KW {week} {year}",
  "newUsername.checkbox.error": "Potvrďte svoju novú e-mailovú adresu",
  "notificationCenter.emptyState": "",
  "notificationCenter.filter.accounting": "",
  "notificationCenter.filter.appNotification:maintenance": "",
  "notificationCenter.filter.appNotification:newFeature": "",
  "notificationCenter.filter.appNotification:update": "",
  "notificationCenter.filter.companySpecific": "",
  "notificationCenter.filter.employeeDocument": "",
  "notificationCenter.filter.file": "",
  "notificationCenter.filter.general": "",
  "notificationCenter.filter.generalDocument": "",
  "notificationCenter.filter.hr": "",
  "notificationCenter.filter.invoice": "",
  "notificationCenter.filter.login": "",
  "notificationCenter.filter.module": "",
  "notificationCenter.filter.notificationType": "",
  "notificationCenter.filter.other": "",
  "notificationCenter.filter.payslip": "",
  "notificationCenter.filter.project": "",
  "notificationCenter.filter.report": "",
  "notificationCenter.filter.ticket": "",
  "notificationCenter.filter.unread": "",
  "notificationCenter.markAllAsRead": "",
  "notificationCenter.title": "",
  "openEmployeeDocumentReleasesDialog.releaseMessage.multi": "V osobnom spise máte <b>{počet} nových dokumentov</b>, ktoré si vyžadujú vaše schválenie.",
  "openEmployeeDocumentReleasesDialog.releaseMessage.single": "V osobnom spise máte <b>nový dokument</b>, ktorý si vyžaduje vaše schválenie.",
  "openEmployeeDocumentReleasesDialog.submit": "Zobrazenie a zdieľanie",
  "openEmployeeDocumentReleasesDialog.title": "Zdieľať dokumenty",
  "paymentDialog.button.pay": "",
  "paymentDialog.field.amount": "",
  "paymentDialog.field.amount.maxError": "",
  "paymentDialog.field.amount.minError": "",
  "paymentDialog.field.amount.typeError": "",
  "paymentDialog.field.iban": "",
  "paymentDialog.field.iban.invalidError": "",
  "paymentDialog.field.purpose": "",
  "paymentDialog.title": "",
  "pdfView.error.caption": "Dokument sa nepodarilo načítať, skúste to prosím znova",
  "pendingUserCreationDialog.message": "Vytvorenie používateľov v službe Sharepoint môže trvať až 30 minút. Buďte trpezliví, kým sa vytvoria oprávnenia.",
  "pendingUserCreationDialog.title": "Používatelia sú vytvorení",
  "permission.accounting.banking": "Bankové údaje",
  "permission.accounting.global.reports": "Celopodnikové účtovné výkazy",
  "permission.accounting.global.results": "Celopodnikové účtovné hodnotenia",
  "permission.accounting.incaseoflaw": "",
  "permission.accounting.records": "Oznámenie o účtovníctve",
  "permission.accounting.reports": "Účtovné správy",
  "permission.accountTransaction.payment": "",
  "permission.expenses.cockpit": "Zobrazenie faktov a údajov v kokpite",
  "permission.expenses.kpi": "Používanie modulu Fakty a čísla",
  "permission.hr.employeePayrollAccount": "",
  "permission.hr.employerPayrollAccount": "",
  "permission.hr.employerPayrollAccount.details": "",
  "permission.hr.global.reports": "Celopodnikové mzdové účtovné výkazy",
  "permission.hr.global.results": "",
  "permission.hr.personnel": "Zamestnanci",
  "permission.hr.records": "Oznámenie o personálnom vyrovnaní",
  "permission.hr.reports": "Mzdové účtovné výkazy",
  "permission.option.canAccess": "môžu pristupovať",
  "permission.option.canEdit": "môže upravovať",
  "permission.option.canEdit.all": "môžete všetko upraviť/odomknúť",
  "permission.option.canEdit.none": "nemožno nič upraviť/odomknúť",
  "permission.option.canEdit.some": "Môže čiastočne upraviť/uvoľniť",
  "permission.option.canPay": "",
  "permission.option.canRead": "vidí",
  "permission.option.canRead.all": "vidí všetko",
  "permission.option.canRead.none": "nič nevidí",
  "permission.option.canRead.some": "čiastočne vidí",
  "permission.option.canRelease": "môže schváliť",
  "permission.option.canUpdate": "môže upravovať",
  "permission.option.canUpload": "môžete nahrať",
  "permission.option.mixed": "zmiešané",
  "permission.option.none": "žiadne povolenie",
  "permission.projects": "Používanie modulu projektov",
  "permission.settings.company": "Zobraziť nastavenia spoločnosti",
  "permission.settings.features": "",
  "permission.settings.images": "Zmena titulného obrázku a loga",
  "permission.settings.user": "Navrhnúť zmeny práv používateľa",
  "permission.taxAccount.payment": "",
  "permission.unpaidAccount.payment": "",
  "project.invitation": "Pozvánka na projekt",
  "projectItemPermission.owner": "Plný prístup",
  "projectItemPermission.owner.description": "Všetky práva na úpravu priečinkov a súborov. Upravte a dokončite projekt a spravujte členov a oprávnenia.",
  "projectItemPermission.read": "Čítať",
  "projectItemPermission.read.description": "Zobrazenie priečinkov a súborov a sťahovanie súborov",
  "projectItemPermission.readNoDownload": "Len na čítanie",
  "projectItemPermission.write": "Upraviť",
  "projectItemPermission.write.description": "Zobrazenie, vytváranie a premenovanie priečinkov. Zobrazovanie, nahrávanie, úprava a odstraňovanie súborov.",
  "projects.activate": "OBNOVIŤ",
  "projects.active.emptystate.buttonlabel": "Vytvoriť projekt",
  "projects.active.emptystate.message": "Pre túto spoločnosť zatiaľ neexistuje žiadny projekt",
  "projects.addCompanies": "Pridať spoločnosť",
  "projects.addCompanies.tooltip": "Vytvorenie projektu v niekoľkých spoločnostiach",
  "projects.addFolder": "Vytvoriť priečinok",
  "projects.addMembers": "Pridať členov",
  "projects.addMembers.bulk.success.title": "Úspešne pridaní členovia",
  "projects.bulkDeleteDialog.message": "Ste si istí, že chcete odstrániť vybrané súbory a priečinky?",
  "projects.bulkDeleteDialog.title": "Odstránenie súborov a priečinkov",
  "projects.cancelRequestedReleaseConfirmationDialog.confirm": "Vziať späť",
  "projects.cancelRequestedReleaseConfirmationDialog.message": "Ste si istí, že chcete stiahnuť otvorený podiel na <b>{názov súboru}</b>?",
  "projects.cancelRequestedReleaseConfirmationDialog.title": "Stiahnuť existujúce schválenie?",
  "projects.closed.emptystate.message": "Pre túto spoločnosť zatiaľ neexistuje žiadny dokončený projekt",
  "projects.companies.emptyState.title": "Žiadne spoločnosti nie sú k dispozícii",
  "projects.companies.submit": "Pridať",
  "projects.copySuccessDialog.message": "Vybrané súbory boli skopírované do schránky",
  "projects.copySuccessDialog.title": "Skopírované do schránky",
  "projects.create.bulk.backToProjects": "Späť na projekty",
  "projects.create.bulk.description": "Projekty sa vytvárajú. Môže to trvať niekoľko minút, nezatvárajte okno prehliadača.",
  "projects.create.bulk.start": "Spustite stránku",
  "projects.deleted.emptystate.message": "Pre túto spoločnosť zatiaľ neexistuje žiadny vymazaný projekt",
  "projects.deleteDialog.message.file": "Určite chcete odstrániť súbor <b>{name}</b>?",
  "projects.deleteDialog.message.folder": "Ste si istí, že chcete odstrániť priečinok <b>{name}</b>?",
  "projects.deleteDialog.message.project": "Ste si istí, že chcete odstrániť projekt <b>{name}</b>?",
  "projects.deleteDialog.title.file": "Odstrániť súbor",
  "projects.deleteDialog.title.folder": "Odstrániť priečinok",
  "projects.deleteDialog.title.project": "Odstrániť projekt",
  "projects.emptystate.title": "Žiadne projekty nie sú k dispozícii",
  "projects.externalId": "Referenčné číslo:",
  "projects.finish.successDialog.title": "Úspešne ukončený projekt",
  "projects.folder.emptystate.message": "Teraz začnite plniť priečinok.",
  "projects.folder.emptystate.title": "Vytváranie a nahrávanie obsahu",
  "projects.importUsers.add": "Pridať",
  "projects.importUsers.replace": "Vymeňte stránku",
  "projects.importUsers.switch": "Import členov z iného projektu",
  "projects.international": "Medzinárodný projekt",
  "projects.international.activate": "Ak chcete previesť projekt na medzinárodný, musíte túto funkciu povoliť.",
  "projects.international.confirmButton": "Konvertovať projekt",
  "projects.international.disabled.refresh": "Platnosť vášho medzinárodného prihlasovacieho mena do MS vypršala, obnovte ho. Potom môžete zmeniť \"{projekt}\" na medzinárodný projekt.",
  "projects.international.disabled.unlock": "Ak chcete previesť projekt na medzinárodný, musíte túto funkciu povoliť v prehľade projektov.",
  "projects.international.info.message": "Premena projektu na medzinárodný projekt umožňuje zamestnancom TPA prizvať do projektu ďalšie krajiny, aby mohli so zákazníkom spolupracovať aj za hranicami. Na tento účel sa všetky súbory a dokumenty, ktoré sú uložené ako súčasť projektu na Microsoft SharePoint, prenášajú medzi rôznymi užívateľmi Microsoftu a zároveň sa obnovujú všetky používateľské oprávnenia na prácu na projekte. Akékoľvek dočasné oprávnenia sa môžu v procese stratiť. Po prechode na medzinárodný projekt sa preto odporúča skontrolovať možnosti prístupu. Tento krok nie je možné vrátiť späť.",
  "projects.international.info.subtitle": "Upozorňujeme na nasledujúce:",
  "projects.international.invite.message": "Boli ste pridaní do nových medzinárodných projektov. Akceptujte pozvanie, aby ste si mohli prezerať projekty z týchto krajín.\n",
  "projects.international.invite.title": "Odomknite nové krajiny",
  "projects.international.migrating": "Medzinárodný projekt (konverzia do práce)\n",
  "projects.international.migrating.message": "Projekt sa v súčasnosti konvertuje na medzinárodný projekt. Opätovné úpravy sú možné až po dokončení konverzie.",
  "projects.international.plural": "Medzinárodné projekty",
  "projects.international.switch": "Zmeniť typ projektu na medzinárodný projekt",
  "projects.international.undoWarning.message": "Ste si istý, že chcete zmeniť tento projekt na medzinárodný projekt? Všetky dáta projektu sa skopírujú do TPA AT Sharepointu. Po vytvorení projektu nie je možné túto akciu vrátiť späť.\n",
  "projects.international.undoWarning.message.at": "Ste si istí, že chcete tento projekt premeniť na medzinárodný projekt? Po vytvorení projektu sa táto akcia nedá vrátiť späť.",
  "projects.international.undoWarning.title": "Túto akciu nie je možné vrátiť späť",
  "projects.invite.international": "Boli ste pridaní do medzinárodných projektov. Teraz ich odomknite.\n",
  "projects.invite.message": "Boli ste pridaní do projektov z {numCountries} krajín. Teraz ich odomknite.",
  "projects.invite.message.singular": "Boli ste pridaný do projektov z krajiny {country}. Teraz ich odomknite.",
  "projects.members.assignPermission": "Udeliť povolenie\n",
  "projects.membersIncluded.multi": "Vrátane {names} a {name}.",
  "projects.membersIncluded.single": "Vrátane {name}.",
  "projects.moveConfirmationDialog.message": "Presunuté súbory sa z pôvodného priečinka vymažú!",
  "projects.moveConfirmationDialog.title": "Presunúť súbory?",
  "projects.moveSuccessDialog.message": "Súbory boli úspešne presunuté",
  "projects.moveSuccessDialog.title": "Presunutie súborov",
  "projects.navbar.activeProjects": "Aktívne",
  "projects.navbar.deletedProjects": "Odstránené",
  "projects.navbar.fileUpload": "Nahrávanie súborov",
  "projects.navbar.finishedProjects": "Dokončené",
  "projects.navbar.heading": "Projekty",
  "projects.navbar.notConfiguredProjects": "Nie je nakonfigurované",
  "projects.navbar.overview": "Prehľad{path}",
  "projects.navbar.project.fileTree": "Štruktúra priečinkov",
  "projects.navbar.project.heading": "Projekt: {projectName}",
  "projects.navbar.project.releases": "Povolenia",
  "projects.navbar.releases.heading": "Schválenie žiadosti pre {fileName}",
  "projects.navbar.ticket.heading": "Dotaz na {fileName}",
  "projects.new": "Nový projekt",
  "projects.new.createNewProject": "Vytvoriť projekt",
  "projects.new.externalId": "Referenčné číslo",
  "projects.new.name": "Názov projektu",
  "projects.new.permissionContextMenu.remove": "Odstrániť",
  "projects.new.permissions.text": "Pridávanie nových členov a prideľovanie používateľských práv.",
  "projects.new.title": "Názov projektu",
  "projects.new.userSearch.placeholder": "Pozvite člena...",
  "projects.notConfigured.emptystate.message": "Pre túto spoločnosť zatiaľ nie je možné konfigurovať žiadny projekt",
  "projects.openReleases.plural": "Otvorené verzie",
  "projects.openReleases.singular": "Otvorené uvoľnenie",
  "projects.pasteProgressDialog.title": "",
  "projects.pasteSuccessDialog.message": "Súbory boli úspešne vložené",
  "projects.pasteSuccessDialog.title": "Vložené súbory",
  "projects.refreshDialog.message": "Platnosť prihlasovacích údajov spoločnosti Microsoft pre nasledujúce krajiny vypršala. Obnovte ich.",
  "projects.refreshDialog.title": "\nPlatnosť prihlásenia Microsoft vypršala",
  "projects.refresh.message": "\nPlatnosť prihlasovacích údajov spoločnosti Microsoft pre {numCountries} krajín vypršala. Obnovte ich.",
  "projects.refresh.message.singular": "\nPlatnosť vášho prihlasovacieho mena Microsoft pre krajinu {country} vypršala. Prosím, obnovte to.",
  "projects.releaseConfirmationDialog.message": "Všimnite si, prosím, nasledujúci komentár od {name}:",
  "projects.releaseConfirmationDialog.title": "Zdieľať dokument",
  "projects.releases.emptystate.message": "V súčasnosti nemáte žiadne vydania.",
  "projects.releases.emptystate.title": "Nie sú k dispozícii žiadne vydania",
  "projects.releases.reject": "Odmietnuť",
  "projects.releases.status.canceled": "stiahnuté",
  "projects.releases.status.canceledFrom": "stiahnuté z {name}",
  "projects.releases.status.canceled.tooltip": "stiahnuté z {performedAt} od {name}",
  "projects.releases.status.open": "Čakanie na vydanie",
  "projects.releases.status.openFrom": "čaká na vydanie {name}",
  "projects.releases.status.open.tooltip": "čaká na uvoľnenie (termín: {dueDate}) {name}",
  "projects.releases.status.rejected": "Odmietnuté",
  "projects.releases.status.rejectedFrom": "zamietnuté podľa {name}",
  "projects.releases.status.rejected.tooltip": "zamietnuté na {performedAt} od {name}",
  "projects.releases.status.released": "zdieľané",
  "projects.releases.status.releasedFrom": "schválené {name}",
  "projects.releases.status.released.tooltip": "uvoľnené na {performedAt} podľa {name}",
  "projects.releaseSuccessDialog.message": "<b>{name}</b> sa teraz môže pozrieť na súbory a bude vás kontaktovať s prípadnými otázkami.",
  "projects.releaseSuccessDialog.multi.message": "Pridelené osoby si teraz prezrú súbory a budú vás kontaktovať v prípade akýchkoľvek otázok.",
  "projects.releaseSuccessDialog.title": "Úspešne podaná žiadosť o uvoľnenie",
  "projects.requestReleaseConfirmationDialog.confirm": "Stiahnite a obnovte",
  "projects.requestReleaseConfirmationDialog.message": "Pre <b>{fileName}</b> je už otvorená žiadosť na schválenie. Ak chcete vytvoriť novú žiadosť, existujúcu žiadosť musíte stiahnuť.",
  "projects.requestReleaseConfirmationDialog.multi.message": "Vo vašom výbere sú už otvorené vydania. Ak chcete vytvoriť nové akcie, existujúce akcie sa musia stiahnuť.",
  "projects.requestReleaseConfirmationDialog.multi.title": "Stiahnuť existujúce vydania?",
  "projects.requestReleaseConfirmationDialog.title": "Stiahnuť existujúci súhlas?",
  "projects.restoreDialog.message.project": "Ste si istí, že chcete aktivovať projekt <b>{name}</b>?",
  "projects.restoreDialog.title.project": "Aktivácia projektu",
  "projects.statusClosed": "Dokončené dňa",
  "projects.statusClosed.personalized": "Dokončil {name} dňa",
  "projects.statusDeleted": "Vymazané na",
  "projects.statusDeleted.personalized": "Odstránené {name} dňa",
  "projects.statusInProgress": "Naposledy upravené dňa",
  "projects.statusInProgress.personalized": "Naposledy upravil {name} dňa",
  "projects.tickets.plural": "Otázky",
  "projects.tickets.singular": "Konzultácie",
  "records.details.upload.header": "Záznam {recordType}",
  "recordType.statusClosed": "Odoslané na",
  "recordType.statusInProgress": "Upravené dňa",
  "recordType.statusOpen": "Neupravené",
  "renameDialog.button.text.rename": "Premenovať",
  "renameDialog.label.document": "Označenie",
  "renameDialog.label.file": "Názov súboru",
  "renameDialog.label.folder": "Názov priečinka",
  "renameDialog.title.document": "Premenovať dokument",
  "renameDialog.title.file": "Premenovanie súboru",
  "renameDialog.title.folder": "Premenovanie priečinka",
  "renameProjectItemDialog.button.text.rename": "Premenovať",
  "renameProjectItemDialog.itemName.file": "Názov súboru",
  "renameProjectItemDialog.itemName.folder": "Názov priečinka",
  "renameProjectItemDialog.title.file": "Premenovanie súboru",
  "renameProjectItemDialog.title.folder": "Premenovanie priečinka",
  "renameRecordDialog.button.text.rename": "Premenovať",
  "renameRecordDialog.name": "Označenie",
  "renameRecordDialog.title": "Premenovať dokument",
  "requestReleaseProjectItemDialog.button.text.request": "Otázky",
  "requestReleaseProjectItemDialog.info": "",
  "requestReleaseProjectItemDialog.label.comment": "Komentár",
  "requestReleaseProjectItemDialog.label.dueDate": "Termín",
  "requestReleaseProjectItemDialog.label.requiresQes": "Uvoľnenie elektronickým podpisom (len pri súboroch PDF)",
  "requestReleaseProjectItemDialog.placeholder.searchForUsers": "Zadajte meno...",
  "requestReleaseProjectItemDialog.qes.collective.subtitle": "Všetky osoby musia uvoľniť",
  "requestReleaseProjectItemDialog.qes.collective.title": "Kolektívne uvoľnenie",
  "requestReleaseProjectItemDialog.qes.single.subtitle": "Uvoľniť musí iba jedna osoba",
  "requestReleaseProjectItemDialog.qes.single.title": "Jednotlivé vydanie",
  "requestReleaseProjectItemDialog.searchForUsers.error": "Vyberte meno zo zoznamu",
  "requestReleaseProjectItemDialog.text": "Vyberte osoby, ktorým chcete priradiť uvoľnenie k uvedeným dokumentom, a zadajte termín.",
  "requestReleaseProjectItemDialog.title.addResponsibleUser": "Pridať ďalšiu osobu",
  "requestReleaseProjectItemDialog.title.create": "Žiadosť o uvoľnenie",
  "requestReleaseProjectItemDialog.title.edit": "Úprava vydania",
  "results.accountSheet.accountDetails.pay": "",
  "results.accountSheet.accountTransaction.lastCompletedPayment": "",
  "results.accountSheet.accountTransaction.lastCompletedPaymentWithAmount": "",
  "results.accountSheet.accountTransaction.lastCompletedPaymentWithAmount.detailed": "",
  "results.accountSheet.backLabel.accountSheet": "Hárok účtu {accountNr} \"{name}\"",
  "results.accountSheet.backLabel.results.accountListing": "Zoznam zostatkov",
  "results.accountSheet.backLabel.results.balanceSheet": "Súvaha",
  "results.accountSheet.backLabel.results.cashAccounting": "Výkaz príjmov a výdavkov",
  "results.accountSheet.backLabel.results.customerListing": "",
  "results.accountSheet.backLabel.results.profitAndLoss": "G&V",
  "results.accountSheet.backLabel.results.vendorListing": "",
  "results.accountSheet.columns.amount": "Suma",
  "results.accountSheet.columns.documentDate": "Dátum dokumentu",
  "results.accountSheet.columns.documentNumber": "Dokument č.",
  "results.accountSheet.columns.documents": "Príjmy",
  "results.accountSheet.columns.offsettingAccounts": "Protiúčty",
  "results.accountSheet.columns.openItemAmount": "Otvorená suma",
  "results.accountSheet.columns.postingSymbol": "BS",
  "results.accountSheet.columns.postingText": "Text vysielania",
  "results.accountSheet.details.costCenter": "Nákladové stredisko",
  "results.accountSheet.details.discount": "Zľava v hotovosti",
  "results.accountSheet.details.externalDocumentNumber": "Externý dokument č.",
  "results.accountSheet.details.foreignCurrency": "Cudzia mena / výmenný kurz",
  "results.accountSheet.details.period": "Obdobie",
  "results.accountSheet.details.serialNumber": "Časopis č.",
  "results.accountSheet.details.tax": "Daň",
  "results.accountSheet.details.taxCode.Ausfuhrlieferungen": "",
  "results.accountSheet.details.taxCode.BauleistungenMitVSt": "Stavebné práce s DPH",
  "results.accountSheet.details.taxCode.BauleistungenOhneVSt": "Stavebné práce bez DPH",
  "results.accountSheet.details.taxCode.EUSt": "EUSt",
  "results.accountSheet.details.taxCode.igEMitVSt": "igE s DPH",
  "results.accountSheet.details.taxCode.igEOhneVst": "igE bez DPH",
  "results.accountSheet.details.taxCode.igLieferung": "",
  "results.accountSheet.details.taxCode.Kleinunternehmer": "",
  "results.accountSheet.details.taxCode.RCMitVSt": "RC s VSt",
  "results.accountSheet.details.taxCode.RCOhneVSt": "RC bez DPH",
  "results.accountSheet.details.taxCode.sonstigeLeistungenEuZm": "",
  "results.accountSheet.details.taxCode.TeilabzugsfaehigeVSt": "čiastočne odpočítateľná DPH",
  "results.accountSheet.details.taxCode.Ust": "DPH",
  "results.accountSheet.details.taxCode.VSt": "VSt",
  "results.accountSheet.details.taxPercent": "Daňové percento",
  "results.accountSheet.details.vatPeriod": "Obdobie UVA",
  "results.accountSheet.documents.downloadAll": "Stiahnite si všetky súbory",
  "results.accountSheet.filters.amount": "Suma",
  "results.accountSheet.filters.documentDate": "Dátum dokumentu",
  "results.accountSheet.filters.postingSymbol": "Rezervačný symbol",
  "results.accountSheet.noResults.message": "Pre vybraný účet sa nepodarilo nájsť žiadne položky",
  "results.accountSheet.noResults.open.message": "Pre vybraný účet nebolo možné nájsť žiadne otvorené položky",
  "results.accountSheet.noResults.open.title": "Nenašli sa žiadne otvorené položky",
  "results.accountSheet.noResults.title": "Nenašli sa žiadne príspevky",
  "results.accountSheet.offsettingAccounts.more": "{count} viac",
  "results.accountSheet.paid": "platené",
  "results.accountSheet.search.count": "Rezervácie: <b>{počet}</b>",
  "results.accountSheet.search.placeholder": "Vyhľadávanie textu rezervácie...",
  "results.accountSheet.sum.title": "",
  "results.accountSheet.tabs.all": "Všetky položky",
  "results.accountSheet.tabs.open": "Len otvorené položky",
  "results.accountSheet.title": "Účtovný výkaz pre účet {accountNr} \"{name}\"",
  "results.columns.changeAbsolute": "Abw.",
  "results.columns.changePercentage": "Dev. (%)",
  "results.columns.credit": "",
  "results.columns.debit": "",
  "results.common.accounts": "Účty",
  "results.common.quickExpand.expandAccounts": "Všetky (vrátane účtov)",
  "results.common.quickExpand.expandNthDepth": "Úroveň {hĺbka}",
  "results.common.quickExpand.expandRoot": "Hlavná úroveň členenia",
  "results.common.quickExpand.expandRows": "Rozložené čiary:",
  "results.disclaimer": "",
  "results.disclaimerMessage": "",
  "results.navbar.buttons.accountListing": "Zoznam zostatkov",
  "results.navbar.buttons.balanceSheet": "Súvaha",
  "results.navbar.buttons.cashAccounting": "Výkaz príjmov a výdavkov",
  "results.navbar.buttons.customerListing": "",
  "results.navbar.buttons.payrollAccount": "",
  "results.navbar.buttons.profitAndLoss": "G&V",
  "results.navbar.buttons.vendorListing": "",
  "results.noFinancialAccountancies.message": "V súčasnosti nie sú k dispozícii žiadne hodnotenia.",
  "results.noFinancialAccountancies.title": "Žiadne hodnotenia",
  "results.payrollAccountSheet.dataTable.month": "",
  "results.payrollAccountSheet.dataTable.number": "",
  "results.payrollAccountSheet.dataTable.payrollType": "",
  "results.payrollAccountSheet.dataTable.total": "",
  "results.payrollAccountSheet.dataTable.totalPreviousYear": "",
  "results.payrollAccountSheet.noPermission.message": "",
  "results.payrollAccountSheet.noPermission.title": "",
  "results.payrollAccountSheet.noResults.details": "",
  "results.payrollAccountSheet.noResultsYear.message": "",
  "results.payrollAccountSheet.noResultsYear.title": "",
  "results.settings.accountsVisibility.hideAccounts": "Skryť účty",
  "results.settings.accountsVisibility.showAccounts": "Zobraziť účty",
  "results.settings.orderBy.accountNameAsc": "Názov účtu vzostupne",
  "results.settings.orderBy.accountNameDesc": "Názov účtu zostupne",
  "results.settings.orderBy.accountNrAsc": "Číslo účtu vzostupne",
  "results.settings.orderBy.accountNrDesc": "Číslo účtu zostupne",
  "results.settings.orderBy.amountAsc": "Suma vzostupne",
  "results.settings.orderBy.amountDesc": "Klesajúca suma",
  "results.settings.orderBy.title": "Zoradiť podľa: {orderBy}",
  "results.settings.previousYearRanges.fullYear": "Zobraziť celkový predchádzajúci rok",
  "results.settings.previousYearRanges.partialYear": "Uvažujte predchádzajúci rok len do {previousYearRangeUntil}.",
  "results.settings.variant.compactView": "",
  "results.settings.variant.currentAndPastYear": "Novinky a predchádzajúci rok",
  "results.settings.variant.currentAndPastYearAbsoluteChange": "Aktuálny a predchádzajúci rok s absolútnou zmenou",
  "results.settings.variant.currentAndPastYearPercentageChange": "Aktuálny a predchádzajúci rok s percentuálnou zmenou",
  "results.settings.variant.currentYear": "Bežný rok",
  "results.settings.variant.detailView": "",
  "results.title": "Hodnotenia",
  "role.accountingAssistent": "Účtovný asistent",
  "role.accountingAssistent.description": "Prístup k účtovníctvu bez schvaľovacích práv",
  "role.accountingManagement": "Finančný manažment",
  "role.accountingManagement.description": "Prístup k účtovníctvu vrátane schvaľovacích práv",
  "role.admin": "Administratíva / Manažment",
  "role.admin.description": "Všetky prístupové práva do všetkých oblastí",
  "role.assistent": "Pomoc",
  "role.assistent.description": "Prístup k účtovníctvu a mzdám bez práv na schvaľovanie {br}Môže navrhnúť zmenu používateľov",
  "role.custom": "Vlastné",
  "role.custom.description": "Prístupové práva definované používateľom",
  "role.custom.jobTitle": "Zamestnanci",
  "role.custom.jobTitle.female": "Zamestnanci",
  "role.hrAssistent": "Asistentka pre mzdy",
  "role.hrAssistent.description": "Prístup k mzdovému účtovníctvu bez schvaľovacích práv",
  "role.hrManagement": "Riadenie ľudských zdrojov",
  "role.hrManagement.description": "Prístup k mzdovému účtovníctvu vrátane schvaľovacích práv",
  "role.internationalProjects": "Zamestnanci projektu",
  "role.internationalProjects.female": "Zamestnanci projektu",
  "role.legalAdvisor": "Právny poradca: v",
  "role.noRole": "žiadna rola",
  "role.noRole.description": "Žiadne prístupové práva k službe TPA Connect",
  "role.staff": "Zamestnanec",
  "role.staff.description": "Dostáva elektronické výkazy príjmov",
  "role.staff.female": "Zamestnanec",
  "role.superAdmin": "Superadmin",
  "role.superAdmin.description": "Prístup do všetkých oblastí vrátane správy používateľov",
  "role.tpaAccounting": "Účtovníctvo",
  "role.tpaAccounting.description": "Prístup konzultanta k účtovníctvu",
  "role.tpaAdvisor": "Poradca",
  "role.tpaAdvisor.description": "Prístup konzultanta do všetkých oblastí",
  "role.tpaAdvisor.female": "Poradca",
  "role.tpaAnnualAccounts": "Ročná účtovná závierka",
  "role.tpaAnnualAccounts.description": "Zodpovednosť za ročnú účtovnú závierku",
  "role.tpaAuditor": "Audit",
  "role.tpaBusinessConsultant": "Poradenstvo v oblasti riadenia",
  "role.tpaBusinessConsultant.description": "Zodpovednosť za poradenstvo v oblasti riadenia",
  "role.tpaChat": "Kontaktná osoba",
  "role.tpaChatSecret": "Kontaktná osoba",
  "role.tpaHr": "Mzdy",
  "role.tpaHr.description": "Prístup konzultanta k mzdovému účtovníctvu",
  "role.tpaOther": "",
  "role.tpaOther.female": "",
  "role.tpaPartner": "Partner",
  "role.tpaPartner.description": "Prístup konzultanta do všetkých oblastí",
  "role.tpaPartner.female": "Partner",
  "role.tpaPayrollAccountant": "Mzdy",
  "role.tpaReporting": "Nahlasovanie",
  "role.tpaReporting.description": "Zodpovednosť za podávanie správ",
  "role.tpaSpecialAdvisor": "Špeciálna konzultácia",
  "role.tpaSpecialAdvisor.description": "Zodpovednosť za špeciálne poradenstvo",
  "role.tpaTaxAccountant": "Daňové poradenstvo",
  "role.tpaTaxAccountant.description": "Zodpovednosť za daňové poradenstvo",
  "screen.accounting.navbar.bankConnections": "Bankové údaje",
  "screen.accounting.navbar.heading": "Účtovníctvo",
  "screen.accounting.navbar.overview": "Prehľad",
  "screen.accounting.navbar.records": "Príjmy",
  "screen.accounting.overview.bankConnection": "{salutation}, {br} {br} V tomto účtovnom období sa skončí platnosť jedného alebo viacerých vašich bankových účtov. Prosím, obnovte ich.{br} {br} S úctou{br} {podpis}",
  "screen.accounting.overview.bankConnection.expired": "{salutation}, {br} {br} Platnosť jedného alebo viacerých vašich bankových údajov vypršala. Prosím, obnovte ich.{br} {br} S úctou{br} {podpis}",
  "screen.accounting.overview.bankConnection.expired.title": "Bankové údaje, ktorých platnosť vypršala",
  "screen.accounting.overview.bankConnections.refreshBankConnections": "Obnovenie bankových účtov",
  "screen.accounting.overview.bankConnection.title": "Platnosť bankových účtov vyprší",
  "screen.accounting.overview.card.analysis": "Schváliť správy pre",
  "screen.accounting.overview.card.globalAnalysis": "Schválenie celopodnikových správ",
  "screen.accounting.overview.card.overview": "Oznámenie o účtovaní",
  "screen.accounting.overview.finished.download": "Stiahnite si všetky súbory",
  "screen.accounting.overview.finished.message": "{salutation}, {br} {br} Ďakujem vám za kontrolu a schválenie účtov. Nižšie nájdete Vaše správy.{br} {br} S úctou, {br}{signature}",
  "screen.accounting.overview.reports": "{salutation}, {br} {br} V prílohe nájdete nasledujúce dokumenty za {obdobie}. Dokumenty boli vypracované na základe vami poskytnutých informácií a predložených dokumentov. Ak v dokumentoch nájdete nepresnosti alebo neúplnosti, dajte nám, prosím, vedieť, aby sme mohli vykonať príslušné opravy. {br} {br} V prípade akýchkoľvek otázok nás neváhajte kontaktovať.{br} {br} S pozdravom, {br}{signature}",
  "screen.accounting.overview.ticketsWelcome.inProgress": "{salutation}, {br} {br} Ďakujem za zaslanie podporných dokumentov. V procese účtovania sa vyskytli niektoré otázky:",
  "screen.accounting.overview.welcome.closed": "{salutation}, {br} {br} Ďakujeme za predloženie všetkých relevantných podkladov. Spracujeme ich podľa dohodnutých termínov a ozveme sa vám s prípadnými otázkami.",
  "screen.accounting.overview.welcome.inProgress": "{{br} {br} Žiadame vás o nahratie rezervačných dokladov za {obdobie}{dátum splatnosti}. Pošlite nám všetky potrebné dokumenty. {br} {br}S pozdravom, {br}{signature}",
  "screen.accounting.records.banner.selectedAll": "Všetky {count} súbory  v \"{recordType}\" sú vybrané.",
  "screen.accounting.records.banner.selectedAll.transferFailed": "Všetky {count} neúspešné súbory v \"{recordType}\" sú vybrané.",
  "screen.accounting.records.banner.selectedCount": "Všetky {count} súbory  na tejto stránke sú vybrané",
  "screen.accounting.records.banner.totalCount": "Vyberte všetky {count} súbory v \"{recordType}\".",
  "screen.accounting.records.banner.totalCount.transferFailed": "Vyberte všetky {count} neúspešné súbory v \"{recordType}\".",
  "screen.accounting.records.banner.unselect": "Zrušiť výber",
  "screen.accounting.records.documents": "Súbory:",
  "screen.accounting.records.empty": "Súbor je prázdny",
  "screen.accounting.records.filter.transferFailed": "Prenos sa nepodaril",
  "screen.accounting.records.format.error": "Skontrolujte formát",
  "screen.accounting.records.move.dialog.button.text": "Presun",
  "screen.accounting.records.move.multiple.dialog.title": "Presun dokumentov",
  "screen.accounting.records.move.single.dialog.title": "Presun dokumentu",
  "screen.accounting.records.navbar.tab.list": "Príjmy ({count})",
  "screen.accounting.records.navbar.tab.tickets": "Dotazy ({count})",
  "screen.accounting.records.person.responsible": "Zodpovednosť",
  "screen.accounting.records.serverUpload.error": "Súbor sa nepodarilo nahrať na server",
  "screen.accounting.records.serverUpload.typeError": "Formát nie je akceptovaný",
  "screen.accounting.records.sizeAndFormat.error": "Skontrolujte veľkosť a formát súboru",
  "screen.accounting.records.size.error": "Skontrolujte veľkosť súboru",
  "screen.accounting.records.tickets": "| Otázky: {numTickets}",
  "screen.accounting.records.transfer.button.text": "Odoslať do TPA",
  "screen.accounting.records.transfer.dialog.text": "Chcete preniesť <b>všetky účtovné</b> súbory za obdobie {periodStart} - {periodEnd} do TPA na zaúčtovanie?",
  "screen.accounting.records.transfer.dialog.textRecordType": "Chcete preniesť <b>všetky súbory \"{názov}\"</b> za obdobie {periodStart} - {periodEnd} do TPA na zaúčtovanie?",
  "screen.accounting.records.transfer.dialog.title": "Predložiť účtovníctvo",
  "screen.accounting.records.transfer.retryDialog.button.text": "Skúste to znova.",
  "screen.accounting.records.transfer.retryDialog.file": "Chyba pri prenose {failedFile}",
  "screen.accounting.records.transfer.retryDialog.text": "Ups. Niečo sa tu pokazilo. Skúste to prosím znova.",
  "screen.accounting.records.transfer.retryDialog.title": "Prenos zlyhal",
  "screen.accounting.records.transfer.successDialog.text": "Váš TPA poradca teraz skontroluje údaje a kontaktuje vás s prípadnými otázkami.",
  "screen.accounting.records.transfer.successDialog.title": "Úspešne odoslané údaje",
  "screen.accounting.records.types.header": "Účtovníctvo",
  "screen.accounting.records.unknown.error": "Neznáma chyba",
  "screen.accounting.records.upload.button": "Nahraj potvrdenia",
  "screen.accounting.records.upload.navbar.back": "Účtovníctvo / Poukazy",
  "screen.accounting.recordTypes.title": "Vyberte typ dokumentov",
  "screen.advisor.tabbar.active": "Aktívne",
  "screen.advisor.tabbar.notConfigured": "Nie je nakonfigurované",
  "screen.advisor.upload.approve.info": "",
  "screen.advisor.uploads.upload.approve": "Schválenie zákazníkom",
  "screen.advisor.uploads.upload.approve.option.click": "Uvoľnenie na kliknutie",
  "screen.advisor.uploads.upload.approve.option.none": "Žiadne schválenie zákazníkom",
  "screen.advisor.uploads.upload.approve.option.qes": "Uvoľnenie elektronickým podpisom",
  "screen.advisor.uploads.upload.buttonText": "Preniesť",
  "screen.advisor.uploads.upload.category": "Kategória",
  "screen.advisor.uploads.upload.category.validation": "Vyberte kategóriu",
  "screen.advisor.uploads.upload.comment": "Text prenosu",
  "screen.advisor.uploads.upload.comment.downloadError": "",
  "screen.advisor.uploads.upload.dueDate": "Splatnosť dňa",
  "screen.advisor.uploads.upload.dueDate.validation": "Zadajte dátum splatnosti",
  "screen.advisor.uploads.upload.global": "Celá spoločnosť",
  "screen.advisor.uploads.upload.module": "Oblasť",
  "screen.advisor.uploads.upload.module.validation": "Vyberte oblasť",
  "screen.advisor.uploads.upload.navBack": "Nahrávanie",
  "screen.advisor.uploads.upload.navHeader": "Nahrávanie súborov: {category}",
  "screen.advisor.uploads.upload.notifyCustomer": "Upozorniť zákazníkov",
  "screen.advisor.uploads.upload.subsidiary.validation": "Vyberte kód spoločnosti",
  "screen.advisor.uploads.upload.visibleToEmployee": "Schválenie pre MA",
  "screen.auth.forgot_password_confirm.subtitle.section_1": "Ak sme našli používateľa s e-mailovou adresou {email}, čoskoro dostanete e-mail s odkazom na obnovenie hesla.",
  "screen.auth.forgot_password_confirm.subtitle.section_2": "Nedostali ste žiadnu poštu? Skontrolujte priečinok so spamom alebo sa obráťte na správcu systému.",
  "screen.auth.forgot_password_confirm.title": "Čoskoro dostanete e-mail",
  "screen.auth.forgot_password_email.form.button": "Odoslať odkaz na resetovanie",
  "screen.auth.forgot_password_email.form.label.email": "ID používateľa / e-mailová adresa",
  "screen.auth.forgot_password_email.subtitle": "Zadajte svoju e-mailovú adresu a my vám pošleme e-mail s odkazom na obnovenie hesla.",
  "screen.auth.forgot_password_email.title": "Obnovenie hesla",
  "screen.auth.forgot_password_email.validation_error": "Zadajte svoju e-mailovú adresu",
  "screen.auth.forgot_password_reset.error.password_not_set": "Zadajte heslo",
  "screen.auth.forgot_password_reset.error.passwords_do_not_match": "Heslá sa nezhodujú",
  "screen.auth.forgot_password_reset.error.password_weak": "Vaše heslo je stále príliš slabé.",
  "screen.auth.forgot_password_reset.form.button": "Zmena hesla",
  "screen.auth.forgot_password_reset.form.button.mfa": "Odoslať kód",
  "screen.auth.forgot_password_reset.form.button.mfa.resend_code": "Opätovné odoslanie kódu",
  "screen.auth.forgot_password_reset.form.mfa": "Kód",
  "screen.auth.forgot_password_reset.form.password": "Heslo",
  "screen.auth.forgot_password_reset.form.repeat_password": "Opakovanie hesla",
  "screen.auth.forgot_password_reset.subtitle": "Nastavte nové heslo pre svoje konto TPA Connect.",
  "screen.auth.forgot_password_reset.subtitle.mfa": "Zadajte kód, ktorý sme vám poslali na vaše telefónne číslo prostredníctvom SMS.",
  "screen.auth.forgot_password_reset.title": "Zmena hesla",
  "screen.auth.forgot_password_reset.title.mfa": "Potvrdenie zmeny hesla pomocou dvojfaktorového overovania",
  "screen.auth.registration.form.button": "Registrácia",
  "screen.auth.registration.form.button.mfa": "Odoslať kód",
  "screen.auth.registration.form.button.mfa.resendCode": "Opätovné odoslanie kódu",
  "screen.auth.registration.form.label.mfa": "Kód",
  "screen.auth.registration.form.label.password": "Heslo",
  "screen.auth.registration.form.label.repeat_password": "Opakovanie hesla",
  "screen.auth.registration.form.section_1": "Použite aspoň 8 znakov.",
  "screen.auth.registration.form.section_2": "Na posilnenie hesla môžete použiť veľké a malé písmená, čísla a špeciálne znaky.",
  "screen.auth.registration.form.section_3": "Vymyslite si mnemotechnickú pomôcku, ktorá vám pomôže zapamätať si heslo.",
  "screen.auth.registration.mfa.subtitle": "Zadajte kód, ktorý sme vám poslali na vaše telefónne číslo prostredníctvom SMS.",
  "screen.auth.registration.subtitle": "Nastavte heslo pre svoje konto TPA Connect.",
  "screen.auth.registration.title": "Nastavenie a registrácia hesla",
  "screen.auth.welcome.subtitle": "Ak chcete naplno využívať všetky funkcie, prepojte svoje konto TPA Connect s kontom Microsoft. Svoje konto môžete prepojiť aj neskôr.",
  "screen.auth.welcome.subtitle.msOnly": "Ak chcete naplno využívať všetky funkcie, prepojte svoje konto TPA Connect s kontom Microsoft.",
  "screen.auth.welcome.title": "Vitajte v službe TPA Connect!",
  "screen.changeUsername.checkbox": "Týmto potvrdzujem svoju novú e-mailovú adresu",
  "screen.changeUsername.message": "Výpočtové jadro aplikácie TPA Connect si všimlo, že vaša e-mailová adresa sa zmenila na <b>{nové používateľské meno}</b>. Aby sme si mohli byť úplne istí, že táto zmena je správna, je nevyhnutné ju potvrdiť jediným kliknutím.",
  "screen.changeUsername.title": "Nová e-mailová adresa",
  "screen.cockpit.allEvents": "Podujatia: Všetky podujatia",
  "screen.cockpit.allNews": "Novinky: Všetky články",
  "screen.cockpit.dueDates.export": "Termíny vývozu",
  "screen.cockpit.dueDates.navbar.heading": "Otvorené termíny",
  "screen.cockpit.dueDates.projects.text": "Schválenie pre {documentName}",
  "screen.cockpit.dueDates.records.accounting.text": "Zverejnenie účtovných informácií pre {subsidiaryName} - {period}",
  "screen.cockpit.dueDates.records.hr.text": "Zverejnenie údajov o mzdách pre {subsidiaryName} - {period}",
  "screen.cockpit.dueDates.reports.global.text": "Vydanie správy pre \"celopodnikové správy\" - {period}",
  "screen.cockpit.dueDates.reports.subsidiary.text": "Vydanie správy pre {subsidiaryName} - {period}",
  "screen.cockpit.dueDates.reportUpload.bmd.message": "Na poskytovanie správ použite BMD NTCS",
  "screen.cockpit.dueDates.reportUpload.bmd.title": "Nahrávanie správ",
  "screen.cockpit.dueDates.reportUpload.global.text": "Nahrávanie správ pre \"celopodnikové správy\" - {period}",
  "screen.cockpit.dueDates.reportUpload.subsidiary.text": "Nahrávanie hlásení pre {subsidiaryName} - {period}",
  "screen.cockpit.dueDates.tickets.global.text": "Termín nahliadnutia do dokumentu {documentName}",
  "screen.cockpit.dueDates.tickets.subsidiary.text": "Termín na nahliadnutie do dokumentu {documentName} pre {subsidiaryName}",
  "screen.cockpit.emptyState.message": "Vráťte sa neskôr",
  "screen.cockpit.emptyState.title": "Momentálne nie sú k dispozícii žiadne novinky",
  "screen.cockpit.eventDetail": "Detail podujatia",
  "screen.cockpit.eventDetails.heading": "Podrobnosti a registrácia",
  "screen.cockpit.eventDetails.lecturer.email": "E: {email}",
  "screen.cockpit.eventDetails.lecturers": "Prednášajúci",
  "screen.cockpit.eventDetails.lecturer.telephone": "T: {telephone}",
  "screen.cockpit.eventDetails.register": "Zaregistrujte sa teraz",
  "screen.cockpit.event.moreInformation": "INFORMÁCIE A REGISTRÁCIA",
  "screen.cockpit.kpis.add": "",
  "screen.cockpit.kpis.chart.legend": "v 1000 EUR",
  "screen.cockpit.kpis.configurationDialog.insuranceCarrierBalance.label": "",
  "screen.cockpit.kpis.configurationDialog.metric.label": "",
  "screen.cockpit.kpis.configurationDialog.months.label": "",
  "screen.cockpit.kpis.configurationDialog.title": "",
  "screen.cockpit.kpis.ebit.chart.name": "Vývoj EBIT",
  "screen.cockpit.kpis.ebit.kpi.name": "Doterajší EBIT",
  "screen.cockpit.kpis.ebit.kpi.tooltip": "Zobrazuje hodnotu EBIT za všetky mesiace, ktoré už boli plne zaúčtované od začiatku obchodného roka.",
  "screen.cockpit.kpis.empty": "Váš kód spoločnosti \"{name}\" momentálne nie je k dispozícii.",
  "screen.cockpit.kpis.insuranceCarrier.balance.kpi.name": "",
  "screen.cockpit.kpis.noneConfigured": "",
  "screen.cockpit.kpis.revenue.chart.name": "Trend obratu",
  "screen.cockpit.kpis.revenue.kpi.name": "Doterajší obrat",
  "screen.cockpit.kpis.revenue.kpi.tooltip": "Zobrazuje obrat všetkých už plne zaúčtovaných mesiacov od začiatku obchodného roka.",
  "screen.cockpit.kpis.save": "",
  "screen.cockpit.kpis.svsAccount.kpi.info": "",
  "screen.cockpit.kpis.svsAccount.kpi.label": "",
  "screen.cockpit.kpis.svsAccount.kpi.name": "",
  "screen.cockpit.kpis.taxAccount.kpi.arrears": "Nevybavené",
  "screen.cockpit.kpis.taxAccount.kpi.credit": "Úverová poznámka",
  "screen.cockpit.kpis.taxAccount.kpi.label": "Stav k {date}",
  "screen.cockpit.kpis.taxAccount.kpi.lastCompletedPayment": "",
  "screen.cockpit.kpis.taxAccount.kpi.name": "Zostatok daňového účtu",
  "screen.cockpit.kpis.unpaidAccount.kpi.lastCompletedPayment": "",
  "screen.cockpit.kpis.unpaidAccounts.kpi.caption": "",
  "screen.cockpit.kpis.unpaidAccounts.kpi.label": "",
  "screen.cockpit.kpis.unpaidAccounts.kpi.name": "",
  "screen.cockpit.navbar.heading": "Kokpit",
  "screen.cockpit.overview.kpis.title": "Fakty a čísla",
  "screen.cockpit.overview.newestPublications": "Nedávne publikácie",
  "screen.cockpit.overview.notifications.dueDates": "Otvorené termíny",
  "screen.cockpit.overview.notifications.messages": "Správy - Chat",
  "screen.cockpit.overview.notifications.tickets": "Otázky",
  "screen.cockpit.overview.notifications.title": "Oznámenia",
  "screen.cockpit.overview.promotions.title": "Novinky1",
  "screen.cockpit.overview.relevantNews.title": "Relevantné správy",
  "screen.cockpit.overview.relevantPublications.description": "Môžete požiadať o tlačenú publikáciu TPA, ktorá vám bude zaslaná do niekoľkých dní. Alebo: Môžete si stiahnuť elektronickú verziu (PDF) požadovanej publikácie TPA.",
  "screen.cockpit.overview.relevantPublications.title": "Príslušné publikácie",
  "screen.cockpit.promotion": "Novinky: Článok",
  "screen.cockpit.promotion.additionalNews": "Novinky",
  "screen.cockpit.relevantNews": "Novinky: Článok",
  "screen.cockpit.relevantNews.additionalNews": "Ďalšie novinky",
  "screen.cockpit.tickets.navbar.heading": "Otvorené dotazy",
  "screen.hr.additionaldata.addBonus": "Pridajte ďalší príspevok",
  "screen.hr.additionaldata.additionaldata": "Doplňujúce informácie",
  "screen.hr.additionaldata.additionalInformation": "Ďalšie informácie",
  "screen.hr.additionaldata.amount": "Suma v {currency} za mesiac",
  "screen.hr.additionaldata.amount_error": "Zadajte, prosím, sumu",
  "screen.hr.additionaldata.bankDetails": "Bankové údaje",
  "screen.hr.additionaldata.bic": "BIC",
  "screen.hr.additionaldata.bic.validation": "Neplatný BIC",
  "screen.hr.additionaldata.commuterAllowance": "",
  "screen.hr.additionaldata.costCenter": "Nákladové stredisko",
  "screen.hr.additionaldata.costCenterNote": "Poznámka k nákladovému stredisku",
  "screen.hr.additionaldata.description": "Pri nástupe do služby sa môžu vyplniť aj nasledujúce informácie, ak je to potrebné.",
  "screen.hr.additionaldata.designator": "Príspevok",
  "screen.hr.additionaldata.designator_error": "Prosím, uveďte príspevok",
  "screen.hr.additionaldata.emailAddress": "E-mailová adresa",
  "screen.hr.additionaldata.email_error": "Zadajte prosím správnu e-mailovú adresu",
  "screen.hr.additionaldata.familyBonusPlusAmount": "",
  "screen.hr.additionaldata.familyBonusPlusAmount_error": "",
  "screen.hr.additionaldata.funder": "Nákladová jednotka",
  "screen.hr.additionaldata.gross": "V hrubom",
  "screen.hr.additionaldata.hours_error": "Zadajte číslo medzi {minHours} a {maxHours}.",
  "screen.hr.additionaldata.iban": "IBAN",
  "screen.hr.additionaldata.iban_error": "Neplatný IBAN",
  "screen.hr.additionaldata.jobDescription": "Názov pracovnej pozície",
  "screen.hr.additionaldata.kilometersForCommuterEuro": "",
  "screen.hr.additionaldata.kilometersForCommuterEuro_error": "",
  "screen.hr.additionaldata.kilometersForCommuterEuro_typeerror": "",
  "screen.hr.additionaldata.maritalStatus": "Rodinný stav",
  "screen.hr.additionaldata.monthlySalary": "Plat v {currency} za mesiac",
  "screen.hr.additionaldata.monthlySalary_error": "",
  "screen.hr.additionaldata.net": "V čistom",
  "screen.hr.additionaldata.nextPage": "Pokračovať na dokumenty",
  "screen.hr.additionaldata.number_error": "Zadajte číslo",
  "screen.hr.additionaldata.per": "na",
  "screen.hr.additionaldata.personaldata": "Osobné údaje",
  "screen.hr.additionaldata.religiousConfession": "Náboženské vyznanie",
  "screen.hr.additionaldata.salarayAndPosition": "Odmeňovanie a pozícia",
  "screen.hr.additionaldata.saveChanges": "Uloženie zmien",
  "screen.hr.additionaldata.soleEarnerStatus": "",
  "screen.hr.additionaldata.titelAndAcademicDegrees": "Tituly a akademické hodnosti",
  "screen.hr.additionaldata.titlePrefix": "Titul pred menom",
  "screen.hr.additionaldata.titleSuffix": "Titul za menom",
  "screen.hr.additionaldata.wageAgreementClassification": "Klasifikačná kolektívna zmluva",
  "screen.hr.additionaldata.weeklyWorkDays": "Počet pracovných dní v týždni",
  "screen.hr.additionaldata.weeklyWorkDays_error": "",
  "screen.hr.additionaldata.workingHours": "Počet hodín",
  "screen.hr.additionaldata.workingHoursInterval": "Obdobie",
  "screen.hr.additionaldata.workingTimes": "Pracovný čas",
  "screen.hr.cancelRegistration.dataSaved": "Ak zrušíte zadanie, zadané údaje zostanú uložené. V registrácii je možné pokračovať neskôr.",
  "screen.hr.cancelRegistration.header": "Zrušenie registrácie",
  "screen.hr.cancelRegistration.question1": "Ste si istí, že chcete zrušiť proces registrácie? Doteraz zadané údaje môžete použiť na",
  "screen.hr.cancelRegistration.question2": " a predložte ho svojmu TPA daňovému poradcovi na registráciu.",
  "screen.hr.deregistration.data.navback": "Personál / Zamestnanci / Registrovaný / {firstname} {lastname}",
  "screen.hr.deregistration.documents.navback": "Personál / Zamestnanci / Registrovaný / {firstname} {lastname} / Údaje o zrušení registrácie",
  "screen.hr.deregistration.navheader": "{firstname} {lastname} odhlásiť",
  "screen.hr.deregistration.validation.deregistrationDate": "Zadajte dátum odchodu",
  "screen.hr.deregistration.validation.deregistrationDateMin": "Dátum odchodu musí byť vyšší ako dátum príchodu",
  "screen.hr.deregistration.validation.deregistrationReason": "Uveďte dôvod odchodu",
  "screen.hr.deregistration.validation.remainingHolidays": "Uveďte, prosím, zostávajúcu dovolenku",
  "screen.hr.deregistration.validation.remainingOvertime": "Prosím, uveďte voľné nadčasy",
  "screen.hr.documents.citizenship": "Osvedčenie o štátnom občianstve",
  "screen.hr.documents.contract": "Servicecontract",
  "screen.hr.documents.description": "Nahrajte ďalšie dokumenty, ako napríklad: pracovnú zmluvu, občiansky preukaz, doklad o občianstve, registračný formulár alebo iné dokumenty.",
  "screen.hr.documents.emptystate.buttonlabel": "Nahrávanie dokumentov",
  "screen.hr.documents.emptystate.message": "Pre tohto zamestnanca nie sú k dispozícii žiadne dokumenty",
  "screen.hr.documents.emptystate.title": "Žiadne dokumenty nie sú k dispozícii",
  "screen.hr.documents.enterManually": "Manuálne zadávanie údajov",
  "screen.hr.documents.idcard": "Osobný preukaz totožnosti",
  "screen.hr.documents.logTime": "Rekordné časy",
  "screen.hr.documents.needsRelease.notReleased": "Čakanie na vydanie",
  "screen.hr.documents.needsRelease.released": "uvoľnené",
  "screen.hr.documents.needsRelease.released.tooltip": "vydané dňa {releasedAt} podľa {názvu}",
  "screen.hr.documents.nextButton": "K zhrnutiu",
  "screen.hr.documents.nonworkingtimes.emptystate.message": "Začnite nahrávať svoje súbory teraz alebo zadajte časy bez výkonu manuálne.",
  "screen.hr.documents.others": "Iné",
  "screen.hr.documents.registrationslip": "Správalist",
  "screen.hr.documents.uploadDocuments": "Nahrávanie dokumentov",
  "screen.hr.edit.flow.navback": "Pracovníci / Zamestnanci / TODO: Stav / {firstname} {lastname}",
  "screen.hr.edit.flow.navheader": "{firstname} {lastname} editovať",
  "screen.hr.employee.current.all": "Všetky",
  "screen.hr.employee.current.anyDateOfJoining": "Akýkoľvek dátum vstupu",
  "screen.hr.employee.current.anyDateOfJoiningExiting": "Akýkoľvek dátum vstupu/výstupu",
  "screen.hr.employee.current.cancellation": "Zrušenie registrácie",
  "screen.hr.employee.current.changeUsername": "",
  "screen.hr.employee.current.changeUsername.successDialog.message": "",
  "screen.hr.employee.current.changeUsername.successDialog.title": "",
  "screen.hr.employee.current.changeUsername.tooltip": "",
  "screen.hr.employee.current.dataChange": "Zmeny základných údajov",
  "screen.hr.employee.current.notTransferred": "Neprenáša sa",
  "screen.hr.employee.current.registration": "Registrácie",
  "screen.hr.employee.deregistration.data.date": "Dátum odchodu",
  "screen.hr.employee.deregistration.data.header": "Dátum odhlásenia",
  "screen.hr.employee.deregistration.data.reason": "Dôvod odchodu",
  "screen.hr.employee.deregistration.data.remainingHolidays": "Zostávajúca dovolenka v dňoch",
  "screen.hr.employee.deregistration.data.remainingOvertime": "Otvorené/nevyčerpané nadčasy",
  "screen.hr.employee.deregistration.data.subtitle": "Ak sú k dispozícii, nahrajte výstupné dokumenty na konečné zúčtovanie.",
  "screen.hr.employee.deregistration.documents.submitButton": "Odoslanie zrušenia registrácie",
  "screen.hr.employee.deregistration.stepper.data": "Údaje o odhlásení",
  "screen.hr.employee.deregistration.stepper.documents": "Výstupné dokumenty",
  "screen.hr.employee.details.addFiles": "Pridanie dokumentov",
  "screen.hr.employee.details.currentEmployment": "Súčasný pracovný pomer",
  "screen.hr.employee.details.deregisterEmployee": "Odhlásenie zamestnanca",
  "screen.hr.employee.details.employments": "Pracovnoprávne vzťahy",
  "screen.hr.employee.details.fileProvidedBy": "poskytuje {name}",
  "screen.hr.employee.details.fileReleased": "Viditeľnosť pre zamestnancov",
  "screen.hr.employee.details.linkedFiles": "Prepojené súbory",
  "screen.hr.employee.details.navBack": "Zamestnanci / {list}",
  "screen.hr.employee.details.pastEmployments": "Predchádzajúce zamestnanie",
  "screen.hr.employee.details.payrollAccount.navBack": "",
  "screen.hr.employee.details.personnelFile": "Personálny spis",
  "screen.hr.employee.details.personnelNumber": "Osobné Číslo:",
  "screen.hr.employee.details.records": "Doklad o príjmoch",
  "screen.hr.employee.details.reregisterEmployee": "Opätovná registrácia zamestnanca",
  "screen.hr.employee.details.showAll": "Zobraziť všetky",
  "screen.hr.employee.details.showEmployeePayrollAccount": "",
  "screen.hr.employee.details.status": "Stav",
  "screen.hr.employee.details.weeklyWorkDays": "{weeklyWorkDays} dní v týždni",
  "screen.hr.employee.details.workingHours": "{workingHours} hodín za {workingHoursInterval};",
  "screen.hr.employee.documents.all": "Všetky",
  "screen.hr.employee.documents.annualPayslips": "Ročný výplatný list",
  "screen.hr.employee.documents.anyDate": "Akékoľvek obdobie",
  "screen.hr.employee.documents.backTargetDescription": "Pracovníci / Zamestnanci / {status} / {firstname} {lastname}",
  "screen.hr.employee.documents.certificateOfCitizenship": "Doklad o občianstve / Občiansky preukaz",
  "screen.hr.employee.documents.certificatesOfRegistration": "Potvrdenia o registrácii",
  "screen.hr.employee.documents.contracts": "Zmluva o poskytovaní služieb",
  "screen.hr.employee.documents.deregisterDocument": "Doklady o zrušení registrácie",
  "screen.hr.employee.documents.employeeDocuments": "Súbory od {firstname} {lastname}",
  "screen.hr.employee.documents.employmentPermit": "Pracovné povolenie",
  "screen.hr.employee.documents.generalDocuments": "Iné",
  "screen.hr.employee.documents.identityCard": "Preukaz totožnosti",
  "screen.hr.employee.documents.payslips": "Doklad o príjmoch",
  "screen.hr.employee.emptystate.buttonlabel": "Záznam zamestnancov",
  "screen.hr.employee.emptystate.message": "Začnite registrovať nových zamestnancov hneď teraz",
  "screen.hr.employee.emptystate.title": "Registrácia zamestnancov",
  "screen.hr.employee.former.anyDateOfLeaving": "Akýkoľvek dátum odchodu",
  "screen.hr.employee.registration.stepper.additional": "Doplňujúce informácie",
  "screen.hr.employee.registration.stepper.documents": "Nahrávanie dokumentov",
  "screen.hr.employee.registration.stepper.personal": "Osobné údaje",
  "screen.hr.employee.registration.stepper.status": "Krok {activeStep} z {totalSteps}",
  "screen.hr.employee.registration.stepper.summary": "Zhrnutie",
  "screen.hr.employees.current.registerEmployee": "Registrácia zamestnanca",
  "screen.hr.employees.current.uploadPayslip": "",
  "screen.hr.employees.documents.recordType.title": "",
  "screen.hr.employees.documents.title": "",
  "screen.hr.navbar.documents": "Súbory",
  "screen.hr.navbar.employees": "Zamestnanci",
  "screen.hr.navbar.heading": "Pracovník",
  "screen.hr.navbar.overview": "Prehľad",
  "screen.hr.navbar.tabbar.current": "Aktuálne DN",
  "screen.hr.navbar.tabbar.documents": "Súbory",
  "screen.hr.navbar.tabbar.employees": "Zamestnanci",
  "screen.hr.navbar.tabbar.former": "DN na dôchodku",
  "screen.hr.navbar.tabbar.inprogress": "Prebieha",
  "screen.hr.navbar.tabbar.preRegistartionTickets": "Dotazy ({preRegistrationTicketCount})",
  "screen.hr.overview.card.analysis": "Zverejnenie mesačných hodnotení pre",
  "screen.hr.overview.card.billing": "Zverejňovanie mesačných výkazov pre",
  "screen.hr.overview.card.globalAnalysis": "Zverejnenie celopodnikových hodnotení",
  "screen.hr.overview.card.globalBilling": "Uvoľnenie celopodnikových vyrovnaní",
  "screen.hr.overview.card.overview": "Zverejnenie mesačných personálnych údajov za",
  "screen.hr.overview.card.registration": "Prihlásenie/odhlásenie zamestnancov",
  "screen.hr.overview.finished.message": "{salutation}, {br} {br} Ďakujem vám za kontrolu a schválenie výplatnej listiny. Nižšie nájdete Vaše správy.{br} {br} S úctou, {br}{signature}",
  "screen.hr.overview.registration": "{salutation}, {br} {br} prosíme o oznámenie akýchkoľvek zmien v užívateľoch služieb, aby sme mohli vykonať registráciu a zrušenie registrácie.{br} {br} S úctou,{br} {signature}",
  "screen.hr.overview.registration.button": "Registrácia/odhlásenie zamestnancov",
  "screen.hr.overview.ticketsWelcome.inProgress": "{salutation}, {br} {br}Ďakujeme za zaslanie osobných údajov. V priebehu spracovania sa vyskytli niektoré otázky:",
  "screen.hr.overview.welcome.closed": "{salutation}, {br} {br} Ďakujeme za zaslanie všetkých relevantných personálnych údajov. Spracujeme ich v súlade s dohodnutými termínmi a budeme vás kontaktovať v prípade akýchkoľvek otázok.",
  "screen.hr.overview.welcome.inProgress": "{{br} {br} Žiadam Vás, aby ste nám poskytli všetky potrebné údaje o zamestnancoch za mzdové {period}{dueDate}. {br}{br}S úctou,{br}{signature}",
  "screen.hr.personaldata.address": "Ulica a číslo domu",
  "screen.hr.personaldata.addressAddition": "Prípona adresy",
  "screen.hr.personaldata.addressHead": "Adresa",
  "screen.hr.personaldata.area.validation": "Prosím, uveďte miesto",
  "screen.hr.personaldata.birthdate.validation": "Zadajte dátum narodenia",
  "screen.hr.personaldata.citizenship": "Občianstvo",
  "screen.hr.personaldata.citizenship.validation": "Prosím, uveďte štátnu príslušnosť",
  "screen.hr.personaldata.city": "Umiestnenie",
  "screen.hr.personaldata.country": "Krajina",
  "screen.hr.personaldata.country.validation": "Vyberte krajinu",
  "screen.hr.personaldata.dateOfBirth": "Dátum narodenia",
  "screen.hr.personaldata.dateOfJoining": "Dátum vstupu",
  "screen.hr.personaldata.description": "Pred začatím poskytovania služby je povinná registrácia, ktorá obsahuje tieto informácie.",
  "screen.hr.personaldata.diverse": "Rôzne",
  "screen.hr.personaldata.employmentInsuranceType": "Skupina poisťovní",
  "screen.hr.personaldata.employmentRelationship": "Pracovný pomer",
  "screen.hr.personaldata.employmenttypeheader": "Typ zamestnania",
  "screen.hr.personaldata.employmenttypeinformationbody": "Všetky hrubé mesačné príjmy nad hranicou nízkeho príjmu sa považujú za povinne plne poistené.",
  "screen.hr.personaldata.employmenttypeinformationhead": "Plne poistené / marginálne poistené:",
  "screen.hr.personaldata.employmenttype.validation": "Prosím, vyberte pracovný pomer",
  "screen.hr.personaldata.entrydate.invalid": "Neplatný dátum vstupu",
  "screen.hr.personaldata.entrydate.validation": "Zadajte dátum vstupu",
  "screen.hr.personaldata.female": "Žena",
  "screen.hr.personaldata.firstname": "Krstné meno",
  "screen.hr.personaldata.firstname.validation": "Zadajte, prosím, krstné meno",
  "screen.hr.personaldata.fullTime": "Plne poistené",
  "screen.hr.personaldata.houseNumber": "Číslo domu",
  "screen.hr.personaldata.houseNumber.validation": "Zadajte číslo domu",
  "screen.hr.personaldata.insurancedata": "Údaje o poistení",
  "screen.hr.personaldata.insurancedatainformationbody": "Ak nie je uvedené žiadne číslo, nechajte pole pre číslo národného poistenia prázdne.",
  "screen.hr.personaldata.insurancedatainformationhead": "Tip:",
  "screen.hr.personaldata.insurancetype.validation": "Vyberte si poistenie",
  "screen.hr.personaldata.lastname": "Priezvisko",
  "screen.hr.personaldata.lastname.validation": "Zadajte prosím priezvisko",
  "screen.hr.personaldata.male": "Muž",
  "screen.hr.personaldata.marginally": "Skrátený",
  "screen.hr.personaldata.navback": "Zamestnanci / zamestnanci",
  "screen.hr.personaldata.navheader": "Registrácia zamestnancov",
  "screen.hr.personaldata.nextpage": "Ďalšie doplňujúce informácie",
  "screen.hr.personaldata.notSpecified": "Nie je špecifikované",
  "screen.hr.personaldata.personaldata": "Osobné údaje",
  "screen.hr.personaldata.personalinformation": "Osobné údaje",
  "screen.hr.personaldata.previouslyEmployed": "Bola táto osoba u vás niekedy zamestnaná za posledných 12 mesiacov?",
  "screen.hr.personaldata.socialsecuritynumber.validation": "Uveďte číslo sociálneho poistenia",
  "screen.hr.personaldata.ssn": "Číslo sociálneho poistenia",
  "screen.hr.personaldata.state.validation": "Zadajte prosím stav",
  "screen.hr.personaldata.streetnr.validation": "Zadajte adresu",
  "screen.hr.personaldata.workplaceState": "Miesto práce",
  "screen.hr.personaldata.zipCode": "PSČ",
  "screen.hr.personaldata.zipcode.length.validation": "",
  "screen.hr.personaldata.zipcode.validation": "Zadajte, prosím, poštové smerovacie číslo",
  "screen.hr.records.move.multiple.dialog.title": "Presun súborov",
  "screen.hr.records.move.single.dialog.title": "Presunúť súbor",
  "screen.hr.records.navbar.tab.list": "Súbory ({count})",
  "screen.hr.records.navbar.tab.tickets": "Dotazy ({count})",
  "screen.hr.records.transfer.dialog.text": "Chcete preniesť <b>všetky mzdové súbory a informácie</b> za obdobie {periodStart} - {periodEnd} do TPA na zaúčtovanie?",
  "screen.hr.records.transfer.dialog.textRecordType": "Chcete preniesť <b>všetky súbory a informácie z \"{názov}\"</b> za obdobie {periodStart} - {periodEnd} do TPA na zaúčtovanie?",
  "screen.hr.records.transfer.dialog.title": "Prenos súborov",
  "screen.hr.records.types.header": "Zamestnanci",
  "screen.hr.records.upload.button": "Nahrávanie súborov",
  "screen.hr.records.upload.navbar.back": "Personál / Súbory",
  "screen.hr.recordTypes.title": "Vyberte typ dokumentov",
  "screen.hr.registration.flow.navback": "Krok späť",
  "screen.hr.registration.flow.navheader": "{firstname} {lastname} prihlásenie",
  "screen.hr.summary.additionalinformation": "Doplňujúce informácie",
  "screen.hr.summary.bonuses": "Príspevky",
  "screen.hr.summary.changeData": "Pridať do zoznamu",
  "screen.hr.summary.completeAdditionalData": "Poskytli ste všetky doplňujúce informácie.",
  "screen.hr.summary.completePersonalData": "Zadali ste všetky povinné údaje pre registráciu do zdravotnej poisťovne.",
  "screen.hr.summary.day": "Deň",
  "screen.hr.summary.daysPerWeek": "Dni v týždni",
  "screen.hr.summary.description": "Skontrolujte, či sú poskytnuté informácie správne, aby ste ich mohli v ďalšom kroku predložiť TPA.",
  "screen.hr.summary.dialogQuestion1": "Prajete si",
  "screen.hr.summary.dialogQuestion2": " a poslať ho svojmu daňovému poradcovi TPA na registráciu?",
  "screen.hr.summary.documents": "Súbory",
  "screen.hr.summary.gender": "Pohlavie",
  "screen.hr.summary.grossSalary": "Hrubá mzda v {currency}",
  "screen.hr.summary.hours": "Hodiny",
  "screen.hr.summary.incompleteAdditionalData": "Ešte ste nezadali všetky dodatočné informácie. Upozorňujeme, že niektoré dodatočné údaje sa môžu vyžadovať až do prvej výplaty mzdy. Môžete ich kedykoľvek pridať.",
  "screen.hr.summary.month": "Mesiac",
  "screen.hr.summary.name": "{firstname} {lastname}",
  "screen.hr.summary.netSalary": "Čistá mzda v {currency}",
  "screen.hr.summary.noPreregistration": "Žiadne základné údaje sa nezmenili. Prenos údajov nie je potrebný.",
  "screen.hr.summary.openDialog": "Odoslať žiadosť",
  "screen.hr.summary.summary": "Zhrnutie",
  "screen.hr.summary.transferDataChange": "Odoslanie zmeny základných údajov",
  "screen.hr.summary.transferEmployeeDialog.success.subtitle": " bola vytvorená a predložená daňovému poradcovi TPA na registráciu.",
  "screen.hr.summary.transferEmployeeDialog.success.subtitle.elda": "{názov} bol vytvorený. Protokol ELDA si môžete pozrieť a stiahnuť nižšie. Prípadne si protokol môžete stiahnuť z osobného súboru zamestnanca.",
  "screen.hr.summary.week": "Týždeň",
  "screen.login.button.forgot_password": "ZABUDLI STE HESLO?",
  "screen.login.button.resendCode": "Opätovné odoslanie kódu",
  "screen.login.form.divider": "alebo",
  "screen.login.form.multiFactorAuth.label": "Kód",
  "screen.login.form.multiFactorAuth.validation_error": "Zadajte prosím kód.",
  "screen.login.form.password.label": "Heslo",
  "screen.login.form.password.validation_error": "Zadajte heslo",
  "screen.login.form.submit": "Prihlásiť sa",
  "screen.login.form.title": "Prihlásiť sa",
  "screen.login.form.username.label": "ID používateľa / e-mailová adresa",
  "screen.login.form.username.validation_error": "Zadajte svoje ID používateľa",
  "screen.login.multiFactorAuth": "Zadajte kód, ktorý sme vám poslali na vaše telefónne číslo prostredníctvom SMS.",
  "screen.login.welcome": "Vitajte na našej webovej stránke! Zadajte svoju e-mailovú adresu.",
  "screen.module.overview.report.empty.message": "",
  "screen.module.overview.report.empty.title": "",
  "screen.module.overview.report.upload": "",
  "screen.moduleReportUpload.comment": "",
  "screen.moduleReportUpload.title": "",
  "screen.not_found.button.text": "Spustenie z kokpitu",
  "screen.not_found.header": "Chyba 404",
  "screen.not_found.message": "V číslach sme naozaj dobrí, ale vlastne sme vás toho chceli ušetriť.",
  "screen.overview.salutation.female": "Vážená pani {lastName}",
  "screen.overview.salutation.general": "Vážené dámy a páni",
  "screen.overview.salutation.male": "Vážený pán {lastName}",
  "screen.overview.salutation.notSpecified": "Dobrý deň {firstname} {lastname}",
  "screen.records.upload.header": "Nahrávanie súborov {recordType}",
  "screen.records.upload.message": "Teraz začnite nahrávať prvé súbory.",
  "screen.records.upload.title": "Nahrať {recordType}",
  "screen.server_error.button.text": "Do kokpitu",
  "screen.server_error.header": "505 Chyba",
  "screen.server_error.message": "V číslach sme naozaj dobrí, ale vlastne sme vás toho chceli ušetriť.",
  "screen.termsNotAccepted.button": "Späť na prehľad spoločnosti",
  "screen.termsNotAccepted.buttonAcceptNow": "",
  "screen.termsNotAccepted.header": "Akceptácia podmienok používania v očakávaní",
  "screen.termsNotAccepted.message": "Podmienky používania vašej spoločnosti ešte neboli prijaté. Ak máte akékoľvek otázky, obráťte sa na svojho poradcu TPA.",
  "screen.termsOfUse.accept": "Podpísať",
  "screen.termsOfUse.alreadySignedInfo": "Podmienky používania už boli prijaté alebo ich prijatie už nie je potrebné",
  "screen.termsOfUse.backToLogin": "Späť na prihlásenie",
  "screen.termsOfUse.checkbox": "Týmto prijímam podmienky používania",
  "screen.termsOfUse.success.sign": "Podmienky používania boli úspešne prijaté",
  "screen.termsOfUse.title": "Prijať podmienky používania",
  "screen.termsOfUseWithCompanyNames.title": "Prijatie podmienok používania pre spoločnosti [počet]",
  "screen.termsOfUseWithCompanyName.title": "Prijať podmienky používania",
  "screen.tickets.navbar.tabbar.closed": "Hotovo",
  "screen.tickets.navbar.tabbar.open": "Otvorte stránku",
  "screen.tickets.navbar.tabbar.reported": "Poskytovateľ",
  "screen.upgradeToMicrosoft.error.azureUserAlreadyExists": "",
  "screen.upgradeToMicrosoft.feature.projects.text": "Ak chcete získať prístup k funkcii \"Projekty\", aktualizujte svoje konto.",
  "screen.upgradeToMicrosoft.form.code.description": "Zadajte kód, ktorý sme vám poslali prostredníctvom SMS, aby ste mohli pokračovať v aktualizácii Microsoft.",
  "screen.upgradeToMicrosoft.form.code.label": "Kód",
  "screen.upgradeToMicrosoft.form.password.label": "Heslo",
  "screen.upgradeToMicrosoft.form.password.validation_error": "Zadajte svoje heslo",
  "screen.upgradeToMicrosoft.needCompany": "Prepojenie s účtom MS je možné vykonať len vtedy, keď je vybraná spoločnosť.",
  "screen.upgradeToMicrosoft.resendButton.label": "Opätovné odoslanie kódu",
  "screen.upgradeToMicrosoft.title": "Prepojenie konta Microsoft",
  "search.caption.numCompanies": "Spoločnosť: <b>{count}</b>",
  "search.caption.numDespositedPersons": "Počet uložených osôb: <b>{count}</b>",
  "search.caption.numDocuments": "Súbory: <b>{count}</b>",
  "search.caption.numPersons": "Osoby: <b>{count}</b>",
  "search.caption.numProjects": "Projekty: <b>{count}</b>",
  "search.caption.numResults": "Výsledky: <b>{count}</b>",
  "search.caption.numSelected": "Vybrané položky: <b>{count}</b>",
  "search.caption.numTickets": "Otázky: <b>{count}</b>",
  "search.caption.numUploads": "Nahrané súbory: <b>{count}</b>",
  "search.caption.numUsers": "Používateľ: <b>{count}</b>",
  "search.placeholder.companies": "Spoločnosti hľadajú ...",
  "search.placeholder.projects": "vyhľadávanie projektu...",
  "search.placeholder.search": "Vyhľadávanie...",
  "search.placeholder.searchForActiveUsers": "Vyhľadávanie aktívnych používateľov podľa mena...",
  "search.placeholder.searchForEmployees": "vyhľadávanie mien...",
  "search.placeholder.searchForEmployeesPersonalNumber": "vyhľadajte mená alebo čísla zamestnancov...",
  "search.placeholder.searchForFileName": "Vyhľadajte názov súboru...",
  "search.placeholder.searchForFiles": "Vyhľadávanie názvov súborov...",
  "search.placeholder.searchForUsers": "vyhľadávanie mien...",
  "search.placeholder.searchForUsersFileName": "Vyhľadávanie podľa názvu alebo názvu súboru...",
  "selectQesPosition.message": "Vyberte pozíciu pre podpis a potom kliknite na tlačidlo {button}.",
  "selectQesPosition.message.collective": "Pre každého používateľa vyberte pozíciu pre jeho podpis a potom kliknite na tlačidlo Ďalej.",
  "selectQesPosition.title": "Výber polohy podpisu",
  "settings.navbar.company": "Spoločnosť",
  "settings.navbar.companyData": "Hlavné údaje",
  "settings.navbar.features": "",
  "settings.navbar.general": "Všeobecné",
  "settings.navbar.optionalSideBarItems": "Voliteľné položky ponuky",
  "settings.navbar.userManagement": "Správa používateľov",
  "settings.notifications.activateAll": "AKTIVOVAŤ VŠETKO",
  "settings.notifications.assignments": "Prideľovanie prostriedkov",
  "settings.notifications.deactivateAll": "DEAKTIVOVAŤ VŠETKO",
  "settings.notifications.deadlineExceeded": "Zmeškaný termín",
  "settings.notifications.deadlineReminder": "Pripomienka termínu",
  "settings.notifications.generalDocumentUpload": "Nahrávanie všeobecných súborov",
  "settings.notifications.label": "E-mailové upozornenia, keď:",
  "settings.notifications.messages": "Správy - Chat2",
  "settings.notifications.modules.accounting": "",
  "settings.notifications.modules.hr": "",
  "settings.notifications.modules.other": "",
  "settings.notifications.project.items.uploaded": "",
  "settings.notifications.recordProvision": "Nahrávanie klienta",
  "settings.notifications.release": "Schvaľovanie",
  "settings.notifications.remindAfterHours.label": "Pripomenutie po {hours}h",
  "settings.notifications.reportProvision": "Poskytovanie správ",
  "settings.notifications.tickets": "Otázky",
  "settings.notifications.title": "Oznámenia",
  "settings.personalData.email": "E-mailová adresa",
  "settings.personalData.language": "Jazyk",
  "settings.personalData.location": "Umiestnenie",
  "settings.personalData.microsoftAccount": "Účet Microsoft",
  "settings.personalData.microsoftUpgrade.info": "Konto Microsoft:{br}{br}Konto TPA Connect môžete prepojiť s kontom Microsoft. To vám umožní využívať všetky funkcie služby TPA Connect.{br}{br}Po prepojení konta so službou Microsoft sa budete musieť prihlásiť pomocou konta Microsoft namiesto e-mailovej adresy.",
  "settings.personalData.microsoftUpgrade.label": "UPGRADE",
  "settings.personalData.phoneNumber": "Telefónne číslo",
  "settings.personalData.role": "Rola",
  "settings.personalData.title": "Osobné údaje",
  "settings.personalData.username": "Používateľské meno",
  "settings.userManagement.permissions.existingUser.navbar.back": "Správa používateľov / Priradenie roly",
  "settings.userManagement.permissions.existingUser.navbar.backToUser": "Správa používateľov / Priradiť rolu / {employeeName}",
  "settings.userManagement.permissions.navbar.back": "Správa používateľov / Výber kontaktnej osoby / Priradenie roly",
  "settings.userManagement.permissions.navbar.backToUser": "Správa používateľov / Vybrať kontaktnú osobu / Prideliť rolu / {employeeName}",
  "settings.userManagement.roles.navbar.back": "Správa používateľov / Výber kontaktnej osoby",
  "sidebar.advisor.employees": "Zoznam zamestnancov",
  "sidebar.advisor.inbox": "P.O. Box",
  "sidebar.advisor.tickets": "Otázky",
  "sidebar.advisor.uploads": "Nahrávanie",
  "sidebar.customerView": "Pohľad zákazníka",
  "sidebar.faceToFace.button": "Kontakt",
  "sidebar.faceToFace.title": "Tvárou v tvár s",
  "sidebar.list.accounting": "Účtovníctvo",
  "sidebar.list.analysis": "",
  "sidebar.list.cockpit": "Kokpit",
  "sidebar.list.documents": "Správy",
  "sidebar.list.faceToFace": "Tvárou v tvár",
  "sidebar.list.hr": "Zamestnanci",
  "sidebar.list.kpi": "Fakty a čísla",
  "sidebar.list.personnelFile": "Personálny spis",
  "sidebar.list.projects": "Projekty",
  "sidebar.list.results": "Hodnotenia",
  "sidebar.list.settings": "Nastavenia",
  "sidebar.list.support": "Podpora",
  "sidebar.list.tickets": "Otázky",
  "signDialog.noQesEnabled.message": "Týmto potvrdzujem vydanie dokumentu.",
  "signDialog.noQesRequired.button.click": "Uvoľnenie na kliknutie",
  "signDialog.noQesRequired.button.qes": "Elektronický podpis",
  "signDialog.noQesRequired.message": "Pri schvaľovaní si môžete vybrať medzi schválením kliknutím alebo elektronickým podpisom.",
  "signDialog.qesblocked.message": "Podpísanie elektronickým podpisom v súčasnosti nie je možné, pretože v tomto čase už bol spustený postup podpisovania inou osobou. Skúste to znova približne o 15 minút.",
  "signDialog.qesblocked.title": "Vydanie v súčasnosti nie je možné",
  "signDialog.qesRequired.confirm": "OK, podpíšte sa elektronicky",
  "signDialog.qesRequired.message": "Pre vydanie bol požadovaný elektronický podpis. Teraz budete presmerovaní na elektronický podpis.",
  "signDialog.title": "Uvoľnenie",
  "soleEarnerStatus.Alleinerzieher": "",
  "soleEarnerStatus.Alleinverdiener": "",
  "soleEarnerStatus.Nein": "",
  "staff.documents.navbar.heading": "Moje súbory",
  "staff.personnelFile.navbar.heading": "Ahoj {name}!",
  "stepper.optional": "voliteľné",
  "stepper.progress": "Pokrok",
  "support.admins.title": "Správcovia",
  "support.filter.option.activeChats": "Len aktívne chaty",
  "support.filter.option.allChats": "Všetky chaty",
  "support.filter.option.inactiveChats": "Len neaktívne chaty",
  "support.filter.setting.showInactiveColleagues": "Zobraziť neaktívnych zamestnancov",
  "support.inactiveEmployee": "Bývalý zamestnanec",
  "support.partners.title": "Partneri",
  "support.privateChat.dialog.markAsPrivate.confirm": "Označiť ako dôverné",
  "support.privateChat.dialog.markAsPrivate.message": "Ak bol chat označený ako dôverný, <b>žiadna iná osoba</b> nemôže chat zobraziť a odpovedať na správy vo vašej neprítomnosti.{br}{br}Váš klient <b>nebude</b> informovaný</b> o tomto nastavení.",
  "support.privateChat.dialog.markAsPrivate.title": "Označiť chat ako dôverný?",
  "support.privateChat.dialog.markAsPublic.confirm": "Uverejnenie dôverných ",
  "support.privateChat.dialog.markAsPublic.message": "Keď chat už nie je označený ako dôverný, <b>ostatné osoby</b> môžu chat prezerať a odpovedať na správy vo vašej neprítomnosti.{br}{br}Váš klient <b>nie je</b> informovaný</b> o tomto nastavení.",
  "support.privateChat.dialog.markAsPublic.title": "Už nie je možné označiť chat ako dôverný?",
  "support.privateChat.info": "Chat je označený ako <b>dôverný</b> a nemôžu si ho prezerať iné osoby.",
  "support.privateChat.info.markAsPublic": "UPDATE",
  "support.privateChat.info.mobile": "Chat je <b>dôverný</b>.",
  "support.privateChat.placeholder": "Zadajte správu...",
  "support.responsible.title": "Členovia tímu",
  "support.search.navbar.title": "Výsledky vyhľadávania",
  "support.substitute.author": "{substituteName} (náhrada za {authorName})",
  "support.substitute.back": "SPÄŤ DO MÔJHO CHATU",
  "support.substitute.back.mobile": "MÔJ CHAT",
  "support.substitute.warning": "<b>Pozor:</b> Nachádzate sa v chate <b>{name}</b>.",
  "support.substitute.warning.mobile": "Chat od <b>{name}</b>",
  "support.yourContacts": "Vaše kontaktné osoby",
  "svsAccount.transactions.table.contextMenu.download": "",
  "svsAccount.transactions.table.contextMenu.show": "",
  "svsAccount.transactions.table.filters.quarter": "",
  "svsAccount.transactions.table.label.amount": "",
  "svsAccount.transactions.table.label.quarter": "",
  "svsAccount.transactions.table.label.text": "",
  "svsAccount.transactions.table.search.count": "",
  "svsAccount.transactions.table.search.placeholder": "",
  "svsAccount.transactions.title": "",
  "table.button.release": "UVOĽNENIE",
  "table.employees.dateOfJoining": "Dátum vstupu",
  "table.employees.dateOfJoiningExiting": "Dátum vstupu/výstupu",
  "table.employees.dateOfLeaving": "Dátum odchodu",
  "table.employees.dateOfTransmitting": "Odoslané na",
  "table.employees.documents.date": "Dátum",
  "table.employees.documents.document": "Súbor",
  "table.employees.documents.documentName": "Označenie",
  "table.employees.documents.downloadDocuments": "Stiahnite si súbory",
  "table.employees.documents.uploadedBy": "Nahral",
  "table.employees.employee": "Zamestnanec",
  "table.employees.jobDescription": "Názov pracovnej pozície",
  "table.employees.missingDetails": "Chýbajúce údaje",
  "table.employees.personalId": "Číslo zamestnanca",
  "table.employees.reasonForLeaving": "Dôvod odchodu",
  "table.employees.status": "Stav",
  "table.employees.status.active": "Aktívny",
  "table.employees.status.inactive": "Neaktívny",
  "table.employees.status.inDataChange": "Zmena hlavných údajov",
  "table.employees.status.inDataChangeNotTransferred": "Pozmeňujúci a doplňujúci návrh (návrh)",
  "table.employees.status.inDeregistration": "Pri zrušení registrácie",
  "table.employees.status.inDeregistrationNotTransferred": "Zrušenie registrácie (návrh)",
  "table.employees.status.inRegistration": "Pri registrácii",
  "table.employees.status.inRegistrationNotTransferred": "Žiadosť (návrh)",
  "table.label.changes": "Zmeny",
  "table.label.changeUsername": "Odoslanie e-mailu na zmenu používateľských mien",
  "table.label.comment": "Komentár",
  "table.label.companyName": "Názov spoločnosti",
  "table.label.contactPerson": "Kontakt",
  "table.label.contactPersonId": "Osobné číslo",
  "table.label.costCenter": "Nákladové stredisko",
  "table.label.createdAt": "Dátum odoslania",
  "table.label.createdAt.variant": "Vytvorené dňa",
  "table.label.createdBy": "",
  "table.label.date": "Dátum",
  "table.label.dateOfJoining": "Dátum vstupu",
  "table.label.dateOfJoiningExiting": "Dátum vstupu/výstupu",
  "table.label.dateOfLeaving": "Dátum odchodu",
  "table.label.dateOfTransmitting": "Odoslané na",
  "table.label.deleteUser": "Odstránenie používateľa",
  "table.label.document": "Súbor",
  "table.label.documentName": "Názov",
  "table.label.documentType": "Súbor typ",
  "table.label.downloadDocuments": "Stiahnite si súbory",
  "table.label.dueDate": "Termín",
  "table.label.dueDateForDraft": "Termín vydania",
  "table.label.email": "E-mailová adresa",
  "table.label.employee": "Zamestnanec",
  "table.label.file": "Súbor",
  "table.label.fileName": "Názov",
  "table.label.folder": "Priečinok",
  "table.label.holiday": "Dovolenka",
  "table.label.info": "Informácie",
  "table.label.jobDescription": "Názov pracovnej pozície",
  "table.label.lastAuthenticatedAt": "",
  "table.label.lastAuthenticatedAt.registered.tooltip": "",
  "table.label.lastAuthenticatedAt.unregistered.tooltip": "",
  "table.label.lastCreated": "Naposledy vytvorené",
  "table.label.members": "Členovia",
  "table.label.message": "Správa",
  "table.label.messages": "Správy - Chat",
  "table.label.missingDetails": "Chýbajúce údaje",
  "table.label.modulePermissions": "Povolenia na úrovni oblasti",
  "table.label.modules": "Prístupy",
  "table.label.needsRelease": "Vyžaduje sa uvoľnenie",
  "table.label.openReleases": "Otvorené verzie",
  "table.label.otherAbsence": "Iná neprítomnosť",
  "table.label.personalId": "Číslo personálu",
  "table.label.personalNumber": "Číslo personálu",
  "table.label.phoneNumber": "Telefónne číslo",
  "table.label.reasonForLeaving": "Dôvod odchodu",
  "table.label.recordedBy": "Zachytené z",
  "table.label.release": "Uvoľňuje",
  "table.label.released": "Odoslané",
  "table.label.releasedAt": "Odoslané na",
  "table.label.releaseRequestedBy": "Vydanie poskytol",
  "table.label.role": "Roll",
  "table.label.sentBy": "Odoslané z",
  "table.label.sickLeave": "Práce neschopnosť",
  "table.label.status": "Stav",
  "table.label.teamMessages": "Novinky v tíme",
  "table.label.ticket": "Prehľad",
  "table.label.ticketBy": "Otázku položil",
  "table.label.ticketDescription": "Označenie",
  "table.label.ticketDueDate": "Termín",
  "table.label.tickets": "Otázky",
  "table.label.transferStatus": "Prenos",
  "table.label.transmittedAt": "Odoslané na",
  "table.label.type": "Art",
  "table.label.updatedAt": "Posledná úprava",
  "table.label.updatedAt.variant": "Posledná úprava",
  "table.label.uploadedBy": "Nahral",
  "table.label.username": "Používateľské meno",
  "table.noResults.message": "Vaše vyhľadávanie neviedlo k žiadnym výsledkom.",
  "table.noResults.title": "Žiadne výsledky vyhľadávania",
  "taxAccount.pay.button": "",
  "taxAccount.transactions.table.filters.date": "",
  "taxAccount.transactions.table.filters.tax": "",
  "taxAccount.transactions.table.label.amount": "",
  "taxAccount.transactions.table.label.date": "",
  "taxAccount.transactions.table.label.due": "",
  "taxAccount.transactions.table.label.taxPeriod": "",
  "taxAccount.transactions.table.label.text": "",
  "taxAccount.transactions.table.search.count": "",
  "taxAccount.transactions.table.search.placeholder": "",
  "taxAccount.transactions.title": "",
  "termsOfUse.checkbox.error": "Prosím, prijmite podmienky používania",
  "termsOfUse.upcoming.navbar.button": "",
  "termsOfUse.upcoming.navbar.message": "",
  "ticketList.emptystate.title": "Nie sú k dispozícii žiadne otázky",
  "unpaidAccounts.invoices.pay": "",
  "unpaidAccounts.invoices.table.filters.date": "",
  "unpaidAccounts.invoices.table.label.documentDate": "",
  "unpaidAccounts.invoices.table.label.documentNumber": "",
  "unpaidAccounts.invoices.table.label.documents": "",
  "unpaidAccounts.invoices.table.label.documentText": "",
  "unpaidAccounts.invoices.table.label.netAmount": "",
  "unpaidAccounts.invoices.table.label.opAmount": "",
  "unpaidAccounts.invoices.table.label.receiptDate": "",
  "unpaidAccounts.invoices.table.label.receiptNumber": "",
  "unpaidAccounts.invoices.table.label.receiptText": "",
  "unpaidAccounts.invoices.table.search.count": "",
  "unpaidAccounts.invoices.table.search.placeholder": "",
  "unpaidAccounts.invoices.title": "",
  "upload.category.analysis": "Správa: Mesačné hodnotenie",
  "upload.category.annualReports": "Ročná účtovná závierka",
  "upload.category.articles": "Vypracovanie",
  "upload.category.billing": "Správa: Mesačný výkaz",
  "upload.category.certificateOfCitizenship": "Doklad o občianstve",
  "upload.category.certificateOfRegistration": "Potvrdenie registrácie",
  "upload.category.contractOfEmployment": "Zmluva",
  "upload.category.contracts": "Zmluvy",
  "upload.category.correspondence": "Korešpondencia",
  "upload.category.dataChangeConfirmation": "Zmena hlavných údajov",
  "upload.category.deregisterDocument": "",
  "upload.category.deregistrationConfirmation": "Zrušenie registrácie zamestnanca",
  "upload.category.employmentPermit": "Pracovné povolenie",
  "upload.category.identityCard": "Preukaz totožnosti",
  "upload.category.information": "Informácie",
  "upload.category.notices": "Oznámenia",
  "upload.category.other": "Iné",
  "upload.category.payslip": "",
  "upload.category.registrationConfirmation": "Registrácia zamestnancov",
  "upload.category.report": "Správy",
  "upload.category.taxOffice": "Daňový úrad",
  "upload.category.taxReturn": "Daňové priznania",
  "userManagement.successDialog.changeUsername.message": "<b>{názov používateľa}</b> dostane žiadosť o potvrdenie novej e-mailovej adresy.",
  "userManagement.successDialog.deleteUser.single.message": "<b>{názov používateľa}</b> bol úspešne odstránený.",
  "userManagement.successDialog.deleteUser.title": "Používateľ bol úspešne odstránený.",
  "userManagement.successDialog.existingUser.superAdmin.multi.message": "Boli prijaté nastavenia pre <b>{count} používateľov</b>.",
  "userManagement.successDialog.existingUser.superAdmin.single.message": "Boli prijaté nastavenia pre <b>{userName}</b>.",
  "userManagement.successDialog.existingUser.superAdmin.title": "Úspešné uloženie používateľských nastavení",
  "userManagement.successDialog.existingUser.title": "Úspešne navrhnuté používateľské nastavenia",
  "userManagement.successDialog.message": "Váš návrh musí teraz schváliť <b>superadmin</b>.",
  "userManagement.successDialog.newUser.superAdmin.multi.message": "<b>{count} používateľov</b> dostane pozvánku e-mailom.",
  "userManagement.successDialog.newUser.superAdmin.single.message": "<b>{userName}</b> dostane pozvánku e-mailom.",
  "userManagement.successDialog.newUser.superAdmin.title": "Používateľ úspešne pozvaný",
  "userManagement.successDialog.newUser.title": "Používateľ úspešne navrhol",
  "userManagement.successDialog.rejectUser.title": "Používateľ úspešne odmietol",
  "userManagement.successDialog.releaseExistingUser.title": "Používateľ úspešne prepustený",
  "userManagement.successDialog.releaseNewUser.title": "Používateľ úspešne prepustený a pozvaný",
  "userManagement.successDialog.sendUsernameChangeEmail.title": "E-mail na zmenu používateľského mena bol úspešne odoslaný.",
  "userProfile.createBmdTodos": "Vytvorenie úloh v BMD pre dotazy",
  "userProfile.deleteAccount.message": "",
  "userProfile.deleteAccount.title": "",
  "userProfile.multiFactorAuth": "Povolenie dvojfaktorového overovania pomocou telefónneho čísla",
  "userProfile.navbar.heading": "Profil",
  "userProfile.upgrade.navbar.heading": "Aktualizácia",
  "userProfile.userInterests.title": "",
  "userReleaseInfoAdminDialog.releaseInfo.multi": "Uvoľnite prosím nových používateľov a pozvite ich.",
  "userReleaseInfoAdminDialog.releaseInfo.single": "Ak chcete pozvať nového používateľa, potvrďte ho.",
  "userReleaseInfoAdminDialog.releaseMessage.multi": "{userName} odoslal <b>{count} nových užívateľov</b>.",
  "userReleaseInfoAdminDialog.releaseMessage.single": "{userName} odoslal <b>{count} novému používateľovi</b>.",
  "userReleaseInfoAdminDialog.submit": "Zobrazenie a zdieľanie",
  "userReleaseInfoAdminDialog.title": "Zdieľanie a pozývanie používateľov",
  "userReleaseInfoDialog.cancel": "Na správu používateľov",
  "userReleaseInfoDialog.from": "z (koho/čoho)",
  "userReleaseInfoDialog.message": "Vaše navrhované zmeny týkajúce sa nasledujúcich používateľov boli schválené/zamietnuté:",
  "userReleaseInfoDialog.rejected": "Odmietnuté",
  "userReleaseInfoDialog.released": "uvoľnené",
  "userReleaseInfoDialog.title": "Zmena používateľa",
  "viewer.unsupported.message": "{názov} sa momentálne nedá zobraziť.",
  "webhook.employee.noSearch": "Vyžaduje sa zadanie vyhľadávania",
  "webhook.employee.placeholder": "Meno zamestnanca",
  "webhook.noCompanies": "Žiadne oprávnenia spoločnosti",
  "webhook.tickets.button": "Vytvoriť dopyt",
  "webhook.tickets.info": "Priradenie dokumentu k oblasti a výber kategórie",
  "webhook.tickets.navbar": "Konzultácie o {document}",
  "webhook.tickets.taskTitle": "Úloha pre {name}",
  "webhook.tickets.title": "Vytvoriť dopyt",
  "webhook.uploads.navbar": "Prenos súborov",
  "welcomeCard.accounting.addMoreRecords.button": "Zadajte ďalšie dokumenty",
  "welcomeCard.accounting.addRecords.button": "Zadajte dokumenty",
  "welcomeCard.hr.addMoreRecords.button": "Odoslať viac súborov",
  "welcomeCard.hr.addRecords.button": "Prenos súborov",
  "texterify_timestamp": "2024-11-21T10:06:43Z"
} as const;

export { slovak };
