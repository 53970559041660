import { useEffect, useState } from "react";
import { getFileExtension } from "../../../util/files";
import { RendererProps, ViewerFileSrc } from "../types";
import { ErrorRenderer } from "./ErrorRenderer";
import { CenteredPanel } from "./Helper";

interface TextRendererProps extends RendererProps {}

export const TextRenderer = ({ file, align }: TextRendererProps) => {
    const [text, setText] = useState<string | null>(null);

    const [error, setError] = useState<unknown>(null);

    const rawFileSrc = file.src;
    useEffect(() => {
        let cancelled = false;
        const urlOrBlob = async (src: ViewerFileSrc) => {
            if (typeof src === "string") {
                try {
                    const res = await fetch(src);
                    if (cancelled) {
                        return;
                    }
                    const text = await res.text();
                    if (cancelled) {
                        return;
                    }
                    setText(text);
                } catch (error) {
                    setError(error);
                }
            } else {
                try {
                    const text = await src.text();
                    if (cancelled) {
                        return;
                    }
                    setText(text);
                } catch (error) {
                    setError(error);
                }
            }
        };
        const load = async () => {
            if (typeof rawFileSrc === "function") {
                try {
                    const src = await rawFileSrc();
                    if (cancelled) {
                        return;
                    }
                    urlOrBlob(src);
                } catch (error) {
                    setError(error);
                }
            } else {
                urlOrBlob(rawFileSrc);
            }
        };
        load();
        return () => {
            cancelled = true;
        };
    }, [rawFileSrc]);

    if (error) {
        return <ErrorRenderer error={error} />;
    }

    if (!text) {
        return null;
    }

    let children;
    const ext = getFileExtension(file.name).toLowerCase();
    if (ext === "json" || ext === "xml") {
        children = (
            <pre>
                <code>{text}</code>
            </pre>
        );
    } else {
        children = <p>{text}</p>;
    }

    return <CenteredPanel style={align === "top" ? { height: "unset" } : undefined}>{children}</CenteredPanel>;
};
