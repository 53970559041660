import { Button, Popover } from "@material-ui/core";
import { useState } from "react";
import styled from "styled-components";
import { t } from "../../i18n/util";
import { NamedExternalDocumentId } from "../../network/APITypes";
import { generalStore } from "../../stores/GeneralStore";
import { viewerStore } from "../../stores/ViewerStore";
import { Icon } from "../util/Icon";
import { ViewerFile } from "../viewer/types";
import { DottedLink } from "./Primitives";

const DocumentsDownloadAllButton = styled(Button)`
    list-style: none;
    text-align: right;
`;
const DocumentsList = styled.ul`
    margin-top: 16px;
    list-style: none;
`;
const DocumentsListItem = styled.li`
    display: flex;
    align-items: center;
    gap: 16px;
`;
const DocumentLink = styled(DottedLink)`
    align-items: center;
    display: inline-flex;
`;

interface Props {
    documents: NamedExternalDocumentId[];
    downloadUrl: (document: NamedExternalDocumentId) => Promise<string>;
    download: (document: NamedExternalDocumentId) => Promise<void>;
    downloadAll: (documents: NamedExternalDocumentId[]) => Promise<void>;
}

const documentId = (document: NamedExternalDocumentId) =>
    `${document.source ?? ""}/${document.additionalSourceData ?? ""}/${document.archiveId ?? ""}/${document.documentId ?? ""}`;

export const TableDocuments = ({ documents, downloadAll, download, downloadUrl }: Props) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement>();

    const handleClick = (ev: React.MouseEvent<HTMLElement>) => {
        ev.preventDefault();
        setAnchorEl(ev.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(undefined);
    };

    const handleDownloadAllClick = async () => {
        try {
            await downloadAll(documents);
        } catch (error) {
            generalStore.setError(t("error.download"), error);
        }
    };
    const handleDownloadClick = async (document: NamedExternalDocumentId) => {
        try {
            await download(document);
        } catch (error) {
            generalStore.setError(t("error.download"), error);
        }
    };
    const handleOpenClick = (ev: React.MouseEvent, selectedDocument: NamedExternalDocumentId) => {
        ev.preventDefault();

        setAnchorEl(undefined);

        const files = documents.map<ViewerFile>(document => {
            return {
                id: documentId(document),
                name: document.name,
                src: () => {
                    return downloadUrl(document);
                },
                download: () => {
                    return download(document);
                },
            };
        });
        viewerStore.open(files, documentId(selectedDocument));
    };

    return (
        <>
            <DocumentLink to="#" onClick={handleClick}>
                {documents.length}
                <Icon name="documents" style={{ marginLeft: 8 }} />
            </DocumentLink>
            <Popover
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                PaperProps={{
                    style: {
                        padding: "8px 16px",
                    },
                }}
            >
                <DocumentsDownloadAllButton
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={handleDownloadAllClick}
                    data-id="download_all"
                    fullWidth
                >
                    {t("common.downloadAll")}
                </DocumentsDownloadAllButton>
                <DocumentsList>
                    {documents.map(document => {
                        return (
                            <DocumentsListItem key={documentId(document)}>
                                <DocumentLink
                                    to="#"
                                    onClick={ev => {
                                        handleOpenClick(ev, document);
                                    }}
                                >
                                    {document.name}
                                </DocumentLink>
                                <Icon
                                    name="download"
                                    onClick={() => {
                                        handleDownloadClick(document);
                                    }}
                                    style={{ cursor: "pointer" }}
                                />
                            </DocumentsListItem>
                        );
                    })}
                </DocumentsList>
            </Popover>
        </>
    );
};
