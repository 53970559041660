import { useEffect, useState } from "react";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { companiesStore } from "../../stores/CompaniesStore";
import { generalStore } from "../../stores/GeneralStore";
import { ViewerFile, viewerStore } from "../../stores/ViewerStore";
import { ApprovalOption, IArchiveDocumentId, ICompany, Module } from "../../types/models";
import { getPeriodActionsDatesForSubsidiary } from "../../util/periods";
import { ISignatureUserSignature, createAnonymousSignatureUsers } from "../../util/qes";
import { getCommentPlaceholder, getRequiredReportReleasers } from "../../util/reports";
import { Routes } from "../app/router/Routes";
import { pushRoute } from "../app/router/history";
import { usePdfSignaturePositionExternalDocumentDialog } from "../hooks/usePdfSignaturePositionExternalDocumentDialog";
import { DocumentCategory } from "../hooks/useWebhookConfiguration";
import { ModuleConfiguration, useWebhookModuleConfiguration } from "../hooks/useWebhookModuleConfiguration";
import { DocumentLine } from "../ui/DocumentLine/DocumentLine";
import { UploadSmallPaper } from "../ui/DocumentUploadSmall";
import { Comment } from "./Comment";
import { IUploadDocument } from "./types";

export const UploadReportList = (props: {
    companyId?: string;
    company?: ICompany | null;
    module?: Module;
    category?: DocumentCategory | "";
    reports: IUploadDocument[];
    setReports: (documents: IUploadDocument[]) => void;
    namesOnly?: boolean;
    onSubmit?: (params: { periodId?: string; subsidiaryId?: string }) => void;
}) => {
    const [comment, setComment] = useState("");
    const { reports, setReports } = props;

    const onSubmit = async () => {
        if (!props.company || !props.module) {
            return;
        }

        // check that all reports have the required QES signature positions
        const requiredReleasers = getRequiredReportReleasers(props.company, props.module);
        const hasMissingSignaturePositions = props.reports.some(report => {
            return (
                report.requiresQes &&
                (!report.signaturePositions || report.signaturePositions.length < requiredReleasers)
            );
        });
        if (hasMissingSignaturePositions) {
            generalStore.setError(t("error.qes.missingSignaturePositions"));
            return;
        }

        generalStore.isLoading = true;

        try {
            await API.postReportsWebhook({
                companyId: props.company.id,
                module: props.module,
                periodId: moduleConfig.periodId,
                subsidiaryId: moduleConfig.globalReport ? undefined : moduleConfig.subsidiaryId,
                reportType: props.category === "billing" ? "billing" : "analysis",
                body: {
                    documents: props.reports.map(report => ({
                        source: report.source ?? "TPA",
                        additionalSourceData: report.additionalSourceData,
                        archiveID: report.archiveId ?? "",
                        documentID: report.documentId ?? "", // this is safe, documentId is always set for NTCS docs
                        name: report.name,
                        needsRelease: !!report.needsRelease,
                        requiresQes: !!report.requiresQes,
                        qesPositions: report.requiresQes ? report.signaturePositions : undefined,
                    })),
                    sendEmail: moduleConfig.notifyCustomer,
                    comment,
                },
            });

            props.onSubmit?.({
                periodId: moduleConfig.periodId,
                subsidiaryId: moduleConfig.globalReport ? undefined : moduleConfig.subsidiaryId,
            });
        } catch (err) {
            generalStore.setError(t("error.upload"), err);
        }

        generalStore.isLoading = false;
    };

    const moduleConfig = useWebhookModuleConfiguration({
        companyId: props.companyId,
        company: props.company,
        module: props.module,
    });

    // update the comment when the period or subsidiary changes
    useEffect(() => {
        const period = moduleConfig.periods.find(p => p.id === moduleConfig.periodId);
        if (period) {
            const pad = getPeriodActionsDatesForSubsidiary(
                period.periodActionsDates,
                moduleConfig.subsidiaryId,
                moduleConfig.globalReport,
            );
            if (pad?.reportsComment) {
                setComment(pad.reportsComment);
            }
        }
    }, [moduleConfig.globalReport, moduleConfig.periodId, moduleConfig.periods, moduleConfig.subsidiaryId]);

    const handleSelectSignaturePositions = (
        archiveDocumentId: IArchiveDocumentId,
        signaturePositions: ISignatureUserSignature[],
    ) => {
        const found = reports.find(
            report =>
                report.archiveId === archiveDocumentId.archiveId && report.documentId === archiveDocumentId.documentId,
        );
        if (!found) {
            return;
        }

        found.signaturePositions = signaturePositions.map(s => s.signaturePosition);
    };
    const pdfSignatureDialog = usePdfSignaturePositionExternalDocumentDialog(handleSelectSignaturePositions);

    const openPdfSignatureDialog = (report: IUploadDocument) => {
        if (report.requiresQes && report.archiveId && report.documentId && props.company && props.module) {
            const requiredReleasers = getRequiredReportReleasers(props.company, props.module);
            const countSignatures = report.signaturePositions?.length ?? 0;
            const count = Math.max(requiredReleasers, countSignatures);
            const users = createAnonymousSignatureUsers(count);
            pdfSignatureDialog.open(
                {
                    source: report.source ?? "TPA",
                    additionalSourceData: report.additionalSourceData,
                    archiveId: report.archiveId,
                    documentId: report.documentId,
                },
                users,
                report.signaturePositions?.map((s, i) => ({ user: users[i], signaturePosition: s })),
            );
        }
    };

    const handleView = (report: IUploadDocument) => {
        const files = reports.map<ViewerFile>(report => ({
            id: report.documentId ?? "",
            name: report.name,
            src: () => {
                return API.getExternalDocumentDownloadUrl({
                    archiveId: report.archiveId ?? "",
                    documentId: report.documentId ?? "",
                    source: report.source ?? "TPA",
                    additionalSourceData: report.additionalSourceData,
                });
            },
            download: () => {
                return API.downloadExternalDocument({
                    archiveId: report.archiveId ?? "",
                    documentId: report.documentId ?? "",
                    source: report.source ?? "TPA",
                    additionalSourceData: report.additionalSourceData,
                });
            },
        }));
        viewerStore.open(files, report.documentId ?? "");
    };

    const content = (
        <>
            <UploadSmallPaper>
                {reports.map((report, index) => {
                    let approvalOption: ApprovalOption = "none";
                    if (report.needsRelease) {
                        if (report.requiresQes) {
                            approvalOption = "qes";
                        } else {
                            approvalOption = "click";
                        }
                    }
                    return (
                        <DocumentLine
                            fileName={report.name}
                            showReleaseOptions
                            defaultApprovalOption={approvalOption}
                            onView={() => {
                                handleView(report);
                            }}
                            onChangeReleaseOption={
                                props.namesOnly
                                    ? undefined
                                    : option => {
                                          report.needsRelease = option === "click" || option === "qes";
                                          report.requiresQes = option === "qes";
                                          openPdfSignatureDialog(report);
                                      }
                            }
                            onClickReleaseOption={() => {
                                openPdfSignatureDialog(report);
                            }}
                            onDelete={() => {
                                if (reports.length === 1) {
                                    // Last doc deleted -> go to cockpit
                                    pushRoute(Routes.COCKPIT);
                                } else {
                                    const newDocs = [...reports];
                                    newDocs.splice(index, 1);
                                    setReports(newDocs);
                                }
                            }}
                            key={report.documentId ?? index}
                        />
                    );
                })}
                {pdfSignatureDialog.component}
            </UploadSmallPaper>
            {props.module ? (
                <Comment
                    value={comment}
                    onChange={setComment}
                    placeholder={getCommentPlaceholder({
                        period: moduleConfig.periods.find(p => p.id === moduleConfig.periodId),
                        signature: companiesStore.mainResponsibleSignatureName(props.module),
                    })}
                    shrinkLabel
                />
            ) : null}
        </>
    );

    return props.namesOnly ? (
        content
    ) : (
        <ModuleConfiguration {...moduleConfig} onSubmit={onSubmit}>
            {content}
        </ModuleConfiguration>
    );
};
