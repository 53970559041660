import * as React from "react";
import { Report } from "../../network/APITypes";
import { Module } from "../../types/models";
import { useReports } from "./useReports";

export type OverviewCards =
    | "overview_welcome"
    | "overview_tickets"
    | "overview_billing"
    | "overview_analysis"
    | "overview_globalBilling"
    | "overview_globalAnalysis"
    | "overview_finished"
    | "overview_registration"
    | "overview_bankConnections";

// Load all reports for a module
export function useModuleReports(
    companyId: string | undefined,
    module: Module,
    periodId: string | undefined,
    subsidiaryId: string | undefined,
) {
    const billing = useReports({
        global: false,
        companyId,
        periodId,
        module,
        reportType: "billing",
        subsidiaryId,
    });
    const analysis = useReports({
        global: false,
        companyId,
        periodId,
        module,
        reportType: "analysis",
        subsidiaryId,
    });
    const globalAnalysis = useReports({
        global: true,
        companyId,
        periodId,
        module,
        reportType: "analysis",
    });
    const globalBilling = useReports({
        global: true,
        companyId,
        periodId,
        module,
        reportType: "billing",
    });

    const needRelease =
        globalAnalysis.needRelease || globalBilling.needRelease || billing.needRelease || analysis.needRelease;

    // Decide which card to expand on the module overview screen.
    // Open card can be forced by query string for deep links from emails.
    const expandReportCard = React.useCallback(
        ({
            queryReportType,
            preferGlobal,
        }: {
            queryReportType?: Report.TypeEnum | "global-analysis" | "global-billing";
            preferGlobal?: boolean;
        }): OverviewCards => {
            if (queryReportType) {
                // Deep links go here
                if (!billing.isEmpty && queryReportType === "billing") {
                    return "overview_billing";
                } else if (!analysis.isEmpty && queryReportType === "analysis") {
                    return "overview_analysis";
                } else if (!globalBilling.isEmpty && queryReportType === "global-billing") {
                    return "overview_globalBilling";
                } else if (!globalAnalysis.isEmpty && queryReportType === "global-analysis") {
                    return "overview_globalAnalysis";
                }
            } else {
                const nothingToRelease = !needRelease;

                let localCard: OverviewCards = "overview_welcome";
                // Report types that need release have priority
                if (!billing.isEmpty && (billing.needRelease || nothingToRelease)) {
                    localCard = "overview_billing";
                } else if (!analysis.isEmpty && (analysis.needRelease || nothingToRelease)) {
                    localCard = "overview_analysis";
                }

                let globalCard: OverviewCards = "overview_welcome";
                if (!globalBilling.isEmpty && (globalBilling.needRelease || nothingToRelease)) {
                    globalCard = "overview_globalBilling";
                } else if (!globalAnalysis.isEmpty && (globalAnalysis.needRelease || nothingToRelease)) {
                    globalCard = "overview_globalAnalysis";
                }

                // Local reports normally have priority but can be overridden by preferGlobal
                if (!preferGlobal || globalCard === "overview_welcome") {
                    return localCard;
                } else {
                    return globalCard;
                }
            }

            // You should only get here if deep link is invalid (e.g. linking to billing on accounting overview)
            return "overview_welcome";
        },
        [
            analysis.isEmpty,
            analysis.needRelease,
            billing.isEmpty,
            billing.needRelease,
            globalAnalysis.isEmpty,
            globalAnalysis.needRelease,
            globalBilling.isEmpty,
            globalBilling.needRelease,
            needRelease,
        ],
    );

    return {
        billing,
        analysis,
        globalBilling,
        globalAnalysis,
        areLoaded: billing.isLoaded && analysis.isLoaded && globalBilling.isLoaded && globalAnalysis.isLoaded,
        needRelease,
        areEmpty: globalAnalysis.isEmpty && globalBilling.isEmpty && billing.isEmpty && analysis.isEmpty,
        expandReportCard,
    };
}
