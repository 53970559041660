import { useEffect, useState } from "react";
import styled from "styled-components";
import { RendererProps, ViewerFileSrc } from "../types";
import { ErrorRenderer } from "./ErrorRenderer";

interface Src {
    url: string;
    revoke: boolean;
}

const srcFromViewerSrc = (src: ViewerFileSrc): Src => {
    if (typeof src === "string") {
        // The office viewer is an external page that cannot access our localhost. However we can use a tunnel
        // to expose our localhost to the internet. For example: running the command from
        // https://localhost.run/ allows us to access our backend via the generated URL.
        // Just replace it here and uncomment the code so that the office viewer can access our localhost.
        // return { url: src.replace("http://localhost:8080", "https://255148ce2cc45b.lhr.life"), revoke: false };
        return { url: src, revoke: false };
    }
    return { url: window.URL.createObjectURL(src), revoke: true };
};

const IFrame = styled.iframe`
    border: 0;
    width: 100%;
    height: 100%;
`;

interface OfficeRendererProps extends RendererProps {}

export const OfficeRenderer = ({ file }: OfficeRendererProps) => {
    const [src, setSrc] = useState<Src | null>(() => {
        if (typeof file.src === "function") {
            return null; // see useEffect below
        }
        return srcFromViewerSrc(file.src);
    });

    const [error, setError] = useState<unknown>(null);

    const rawFileSrc = file.src;
    useEffect(() => {
        if (typeof rawFileSrc !== "function") {
            return; // already set in the use state above
        }
        let cancelled = false;
        const load = async () => {
            try {
                const src = await rawFileSrc();
                if (cancelled) {
                    return;
                }
                setSrc(srcFromViewerSrc(src));
            } catch (error) {
                setError(error);
            }
        };
        load();
        return () => {
            cancelled = true;
        };
    }, [rawFileSrc]);

    useEffect(() => {
        return () => {
            if (src?.revoke) {
                window.URL.revokeObjectURL(src.url);
            }
        };
    }, [src]);

    if (error) {
        return <ErrorRenderer error={error} />;
    }

    if (!src) {
        return null;
    }

    return <IFrame src={`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(src.url)}`} />;
};
