import {
    CircularProgress,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import Decimal from "decimal.js";
import noop from "lodash/noop";
import moment from "moment";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import styled from "styled-components";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { companiesStore } from "../../stores/CompaniesStore";
import { generalStore } from "../../stores/GeneralStore";
import { getFullName } from "../../util/user";
import { UseParams, withParams } from "../app/router/history";
import { useAPI } from "../hooks/useAPI";
import { EmptyState } from "../ui/EmptyState";
import { DottedLink, TableLabel } from "../ui/Primitives";
import { TableDropdownFilterButton } from "../ui/filter/TableFilter";
import { Icon } from "../util/Icon";
import { MobileContext } from "../util/MobileContext";
import { customColors } from "../util/Theme";
import { variantTextKeys } from "./ResultsSettings";
import { Currency, CurrencyPill, PercentagePill } from "./ResultsValue";
import { EmployeeDetail, mapPayrollItem, PayrollItem } from "./payrollUtils";
import { ResultsRoutes } from "./router/ResultsRoutes";
import { Variant } from "./types";
import { variantToQuery } from "./utils";

const TableHeaderCell = styled(TableCell)<{ $isPointer?: boolean }>`
    background-color: ${customColors.primaryColor};
    color: white;
    height: 35px;
    font-weight: bold;
    font-size: 13px;
    padding: 6px 16px;
    padding-left: 16px;
    cursor: ${props => (props.$isPointer ? "pointer" : "default")};
`;

const TableBodyCell = styled(TableCell)`
    background-color: white;
    font-size: 13px;
    height: 35px;
    overflow: hidden;
    padding: 6px 16px;
    border-bottom: 1px solid #eee;
`;

const StyledTableRow = styled(TableRow)<{ $isPointer?: boolean }>`
    cursor: ${props => (props.$isPointer ? "pointer" : "default")};
    &:hover > td {
        background-color: #f6f6f6;
    }
`;

const SettingsRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
    margin-bottom: 32px;
`;

const StyledDottedLink = styled(DottedLink)`
    font-size: 12px;
`;

interface Props {
    type: "employer" | "employee";
    loader: (
        companyId: string,
        year: number,
        options: { filter: string; previousYears: number },
    ) => Promise<PayrollItem[]>;
    companyId: string;
}
export const ResultsPayrollAccount = (props: Props) => {
    const { companyId, type } = props;
    const currentYear = new Date().getFullYear();
    const paramsEmployee = useParams<UseParams<typeof ResultsRoutes.EMPLOYEE_PAYROLL_ACCOUNT>>();
    const paramsEmployer = useParams<UseParams<typeof ResultsRoutes.EMPLOYER_PAYROLL_ACCOUNT>>();

    const location = useLocation();
    const { employeeName, previousRoute } = (location.state as { employeeName?: string; previousRoute?: string }) || {};

    const initialYear = type === "employee" ? paramsEmployee.year : paramsEmployer.year;
    const [year, setYear] = useState<string>(initialYear ?? currentYear.toString());
    const [variant, setVariant] = useState<Variant>("currentYear");
    const [filterType, setFilterType] = useState<string>("Compact1");

    const history = useHistory();

    const handleYearChange = (newYear: string) => {
        setYear(newYear);
        if (type === "employee") {
            history.push({
                pathname: withParams(ResultsRoutes.EMPLOYEE_PAYROLL_ACCOUNT, {
                    ...paramsEmployee,
                    employeeId: paramsEmployee.employeeId ?? "",
                    year: newYear,
                }),
                state: {
                    employeeName: employeeName,
                    previousRoute: previousRoute,
                },
            });
        } else if (type === "employer") {
            history.push({
                pathname: withParams(ResultsRoutes.EMPLOYER_PAYROLL_ACCOUNT, {
                    ...paramsEmployer,
                    year: newYear,
                }),
            });
        }
    };

    const propsLoader = props.loader;
    const loader = useCallback(async () => {
        return propsLoader(companyId, parseInt(year), {
            filter: filterType,
            previousYears: variantToQuery[variant]?.previousYears ?? 0,
        });
    }, [companyId, filterType, variant, propsLoader, year]);

    const result = useAPI(loader);
    if (result.state === "loading") {
        return null;
    } else if (result.state !== "success") {
        return <EmptyState title={t("error.loadPayrollAccount")} />;
    }

    const payrollItems = result.data;
    const companyStore = companiesStore.selectedCompanyStore;
    const canViewEmployerPayrollAccountDetails =
        type === "employer" && companyStore?.canReadEmployerPayrollAccountDetails;

    const handleVariantChange = (variantString: string): void => {
        setVariant(variantString as Variant);
    };

    return (
        <>
            <SettingsRow>
                <YearFilter year={year} onChange={handleYearChange} />
                <PreviousYearFilter variant={variant} onChange={handleVariantChange} />
                <DetailVsCompactFilter filterType={filterType} onChange={setFilterType} />
            </SettingsRow>
            <DataTable
                payrollAccount={payrollItems}
                filterType={filterType}
                variant={variant}
                companyId={companyId}
                year={year}
                showDetails={canViewEmployerPayrollAccountDetails ?? false}
            />
        </>
    );
};

type Details = Record<number, PayrollItem[]>;

const sortDetail = (items: PayrollItem[], orderBy: OrderBy): PayrollItem[] => {
    const { column, monthIndex, sortOrder } = orderBy;
    return items.map(payrollItem => {
        if (payrollItem.employees) {
            payrollItem.employees.sort((a, b) => {
                if (column === "lastName") {
                    if (a.employee?.lastName !== undefined && b.employee?.lastName !== undefined) {
                        return sortOrder === "asc"
                            ? a.employee.lastName.localeCompare(b.employee.lastName)
                            : b.employee.lastName.localeCompare(a.employee.lastName);
                    }
                } else if (column === "number") {
                    if (a.employee?.number !== undefined && b.employee?.number !== undefined) {
                        return sortOrder === "asc"
                            ? a.employee.number - b.employee.number
                            : b.employee.number - a.employee.number;
                    }
                } else if (column === "month" && monthIndex !== undefined) {
                    if (a.months?.[monthIndex].amount !== undefined && b.months?.[monthIndex].amount !== undefined) {
                        return sortOrder === "asc"
                            ? a.months[monthIndex].amount.comparedTo(b.months[monthIndex].amount)
                            : b.months[monthIndex].amount.comparedTo(a.months[monthIndex].amount);
                    }
                } else if (column === "total") {
                    if (a.total?.amount !== undefined && b.total?.amount !== undefined) {
                        return sortOrder === "asc"
                            ? a.total.amount.comparedTo(b.total.amount)
                            : b.total.amount.comparedTo(a.total.amount);
                    }
                } else if (column === "totalPreviousYear") {
                    if (a.lastYearTotalAmount !== undefined && b.lastYearTotalAmount !== undefined) {
                        return sortOrder === "asc"
                            ? a.lastYearTotalAmount.comparedTo(b.lastYearTotalAmount)
                            : b.lastYearTotalAmount.comparedTo(a.lastYearTotalAmount);
                    }
                } else if (column === "changeAbsolute") {
                    if (a.total?.deviationFromLastYear !== undefined && b.total?.deviationFromLastYear !== undefined) {
                        return sortOrder === "asc"
                            ? a.total.deviationFromLastYear.comparedTo(b.total.deviationFromLastYear)
                            : b.total.deviationFromLastYear.comparedTo(a.total.deviationFromLastYear);
                    }
                } else if (column === "changePercentage") {
                    if (
                        a.total?.deviationFromLastYearPercentage !== undefined &&
                        b.total?.deviationFromLastYearPercentage !== undefined
                    ) {
                        return sortOrder === "asc"
                            ? a.total.deviationFromLastYearPercentage.comparedTo(
                                  b.total.deviationFromLastYearPercentage,
                              )
                            : b.total.deviationFromLastYearPercentage.comparedTo(
                                  a.total.deviationFromLastYearPercentage,
                              );
                    }
                }
                return 0;
            });
        }
        return payrollItem;
    });
};

const sortDetails = (details: Details, orderBy: OrderBy): Details => {
    const sortedDetails: Details = {};

    // Iterate over the keys of the details object
    Object.keys(details).forEach(key => {
        sortedDetails[Number(key)] = sortDetail(details[Number(key)], orderBy);
    });

    return sortedDetails;
};

const formatNumber = (number: Decimal, isSum: boolean | undefined) =>
    number.isZero() ? "-" : <Currency value={number} style={{ fontWeight: isSum ? "bold" : "normal" }} />;

interface OrderBy {
    column: "number" | "lastName" | "total" | "month" | "totalPreviousYear" | "changeAbsolute" | "changePercentage";
    monthIndex: number | undefined;
    sortOrder: "asc" | "desc";
}

const getMonthName = (index: number): string => {
    return moment(index + 1, "M").format("MMMM");
};

const formatAbsoluteDeviation = (value: Decimal) => <CurrencyPill value={value} />;

const formatPercentageDeviation = (percentage: Decimal) => <PercentagePill value={percentage} />;

interface DataTableProps {
    payrollAccount: PayrollItem[] | undefined;
    filterType: string;
    variant: Variant;
    companyId: string;
    year: string;
    showDetails: boolean;
}

const DataTable = (props: DataTableProps) => {
    const { payrollAccount, filterType, variant, companyId, year, showDetails } = props;
    const isMobile = React.useContext(MobileContext);

    const [expandedRows, setExpandedRows] = useState<number[]>([]);
    const [details, setDetails] = useState<Details>({});
    const [loadingRowIndex, setLoadingRowIndex] = useState<number | null>(null);

    const [orderBy, setOrderBy] = useState<OrderBy>({
        column: "lastName",
        monthIndex: undefined,
        sortOrder: "asc",
    });

    useEffect(() => {
        setDetails({});
        setExpandedRows([]);
    }, [year, variant]);

    const handleSort = (column: OrderBy["column"], monthIndex?: number) => {
        const newOrderBy: OrderBy = {
            column,
            monthIndex,
            sortOrder:
                orderBy.column === column && orderBy.monthIndex === monthIndex && orderBy.sortOrder === "asc"
                    ? "desc"
                    : "asc",
        };
        setOrderBy(newOrderBy);
        setDetails(details => sortDetails(details, newOrderBy));
    };

    const handleRowClick = async (index: number, payrollTypeId: string | undefined) => {
        if (payrollTypeId === undefined) return;

        // If the row is already expanded, collapse it
        if (expandedRows.includes(index)) {
            setExpandedRows(expandedRows.filter(i => i !== index));
            return;
        }

        // Expand the row and load the details if not already loaded
        setExpandedRows([...expandedRows, index]);

        // If details are already loaded, do nothing more
        if (details[index]) return;

        setLoadingRowIndex(index); // Set loading state

        try {
            const response = await API.getEmployerPayrollAccountDetails({
                companyId,
                year: parseInt(year),
                payrollTypeId,
                query: { filter: filterType, previousYears: variantToQuery[variant]?.previousYears ?? 0 },
            });

            const mappedPayrollDetails = (response.employerPayrollAccountDetails.payrollItems ?? []).map(
                mapPayrollItem,
            );

            setDetails(prevDetails => ({
                ...prevDetails,
                [index]: sortDetail(mappedPayrollDetails, orderBy),
            }));
        } catch (error) {
            generalStore.setError(t("error.loadPayrollAccountDetails"), error);
        } finally {
            setLoadingRowIndex(null); // Reset loading state
        }
    };

    if (!payrollAccount || payrollAccount.length === 0) {
        return (
            <EmptyState
                title={t("results.payrollAccountSheet.noResultsYear.title")}
                message={t("results.payrollAccountSheet.noResultsYear.message")}
            />
        );
    }

    const maxMonths = payrollAccount.reduce((max, account) => Math.max(max, account.months?.length ?? 0), 0);

    const renderIcon = (isExpanded: boolean, isLoading: boolean) => {
        if (isLoading) {
            return <CircularProgress size={20} />; // Spinner replaces chevron during loading
        } else if (isExpanded) {
            return <Icon name="chevronDown" />;
        } else {
            return <Icon name="chevronRight" />;
        }
    };

    const getSortIndicator = (column: string, monthIndex?: number) => {
        if (showDetails && orderBy.column === column && orderBy.monthIndex === monthIndex) {
            return orderBy.sortOrder === "asc" ? (
                <ArrowUpwardIcon style={{ fontSize: 18, marginBottom: -4, marginRight: 3 }} />
            ) : (
                <ArrowDownwardIcon style={{ fontSize: 18, marginBottom: -4, marginRight: 3 }} />
            );
        }
        return "";
    };

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        {showDetails && <TableHeaderCell $isPointer={showDetails} />}
                        <TableHeaderCell
                            $isPointer={showDetails}
                            onClick={() => {
                                handleSort("number");
                            }}
                            style={{ minWidth: 80 }}
                        >
                            {t("results.payrollAccountSheet.dataTable.number")} {getSortIndicator("number")}
                        </TableHeaderCell>
                        <TableHeaderCell
                            $isPointer={showDetails}
                            onClick={() => {
                                handleSort("lastName");
                            }}
                        >
                            {t("results.payrollAccountSheet.dataTable.payrollType")} {getSortIndicator("lastName")}
                        </TableHeaderCell>
                        {Array.from({ length: maxMonths }).map((_, i) => (
                            <TableHeaderCell
                                $isPointer={showDetails}
                                onClick={() => {
                                    handleSort("month", i);
                                }}
                                style={{ minWidth: 120 }}
                                align="right"
                                key={i}
                            >
                                {getSortIndicator("month", i)}
                                {getMonthName(i)}
                            </TableHeaderCell>
                        ))}
                        <TableHeaderCell
                            $isPointer={showDetails}
                            onClick={() => {
                                handleSort("total");
                            }}
                            align="right"
                            style={{ minWidth: 110 }}
                        >
                            {getSortIndicator("total")}
                            {t("results.payrollAccountSheet.dataTable.total")}
                        </TableHeaderCell>
                        {variant !== "currentYear" && (
                            <TableHeaderCell
                                $isPointer={showDetails}
                                onClick={() => {
                                    handleSort("totalPreviousYear");
                                }}
                                style={{
                                    minWidth: 150,
                                }}
                                align="right"
                            >
                                {getSortIndicator("totalPreviousYear")}
                                {t("results.payrollAccountSheet.dataTable.totalPreviousYear")}
                            </TableHeaderCell>
                        )}
                        {variant === "currentAndPastYearAbsoluteChange" && (
                            <TableHeaderCell
                                $isPointer={showDetails}
                                onClick={() => {
                                    handleSort("changeAbsolute");
                                }}
                                style={{ minWidth: 100 }}
                                align="right"
                            >
                                {getSortIndicator("changeAbsolute")}

                                {t("results.columns.changeAbsolute")}
                            </TableHeaderCell>
                        )}
                        {variant === "currentAndPastYearPercentageChange" && (
                            <TableHeaderCell
                                $isPointer={showDetails}
                                onClick={() => {
                                    handleSort("changePercentage");
                                }}
                                style={{ minWidth: 105 }}
                                align="right"
                            >
                                {getSortIndicator("changePercentage")}

                                {t("results.columns.changePercentage")}
                            </TableHeaderCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {payrollAccount.map((account, index) => {
                        const isExpanded = expandedRows.includes(index);
                        const isLoading = loadingRowIndex === index;
                        return (
                            <Fragment key={index}>
                                <StyledTableRow
                                    $isPointer={showDetails}
                                    onClick={e => {
                                        e.stopPropagation();
                                        if (showDetails) {
                                            handleRowClick(index, account.payrollType?.payrollTypeId);
                                        }
                                    }}
                                >
                                    {showDetails && (
                                        <TableBodyCell style={{ padding: 4 }}>
                                            <IconButton
                                                style={{
                                                    color: "inherit",
                                                    padding: 0,
                                                }}
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    handleRowClick(index, account.payrollType?.payrollTypeId);
                                                }}
                                                disabled={isLoading} // Disable button while loading
                                            >
                                                {renderIcon(isExpanded, isLoading)}
                                            </IconButton>
                                        </TableBodyCell>
                                    )}
                                    <TableBodyCell>
                                        <span style={{ fontWeight: account.payrollType?.isSum ? "bold" : "normal" }}>
                                            {account.payrollType?.number}
                                        </span>
                                    </TableBodyCell>
                                    <TableBodyCell>
                                        <TableLabel
                                            style={{
                                                maxWidth: 200,
                                                fontWeight: account.payrollType?.isSum ? "bold" : "normal",
                                            }}
                                        >
                                            {account.payrollType?.description ?? ""}
                                        </TableLabel>
                                    </TableBodyCell>
                                    {account.months?.map((month, monthIndex) => (
                                        <TableBodyCell key={monthIndex} align="right">
                                            {formatNumber(month.amount, account.payrollType?.isSum)}
                                        </TableBodyCell>
                                    ))}
                                    <TableBodyCell align="right">
                                        {account.total !== undefined &&
                                            formatNumber(account.total.amount, account.payrollType?.isSum)}
                                    </TableBodyCell>
                                    {variant !== "currentYear" && (
                                        <TableBodyCell align="right">
                                            {account.previousYears?.[0]?.total !== undefined &&
                                                formatNumber(
                                                    account.previousYears[0]?.total.amount,
                                                    account.payrollType?.isSum,
                                                )}
                                        </TableBodyCell>
                                    )}
                                    {variant === "currentAndPastYearAbsoluteChange" && (
                                        <TableBodyCell align="right">
                                            {account.previousYears !== undefined &&
                                                account.total?.deviationFromLastYear !== undefined &&
                                                formatAbsoluteDeviation(account.total.deviationFromLastYear)}
                                        </TableBodyCell>
                                    )}
                                    {variant === "currentAndPastYearPercentageChange" && (
                                        <TableBodyCell align="right">
                                            {account.previousYears !== undefined &&
                                                account.total?.amount !== undefined &&
                                                formatPercentageDeviation(
                                                    account.total.deviationFromLastYearPercentage,
                                                )}
                                        </TableBodyCell>
                                    )}
                                </StyledTableRow>
                                {showDetails &&
                                    expandedRows.includes(index) &&
                                    !isLoading &&
                                    (details[index] !== undefined && details[index].length > 0 ? (
                                        details[index].map((payrollItem, itemIndex) => (
                                            <EmployeeDetails
                                                key={itemIndex}
                                                payrollItem={payrollItem}
                                                year={year}
                                                variant={variant}
                                            />
                                        ))
                                    ) : (
                                        <StyledTableRow>
                                            <TableBodyCell />
                                            <TableBodyCell colSpan={maxMonths + 5}>
                                                {t("results.payrollAccountSheet.noResults.details")}
                                            </TableBodyCell>
                                        </StyledTableRow>
                                    ))}
                            </Fragment>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const getEmployeeName = (employee: EmployeeDetail) => {
    const firstName = employee.employee?.firstName ?? "";
    const lastName = employee.employee?.lastName ?? "";
    return getFullName({ firstName, lastName });
};
const getEmployeeId = (employee: EmployeeDetail) => employee.employee?.employeeId ?? "";

const EmployeeLink = ({
    employee,
    year,
    children,
}: {
    employee: EmployeeDetail;
    year: string;
    children: React.ReactNode;
}) => {
    const to = toEmployeeDetail(employee, year);
    if (to) {
        return (
            <StyledDottedLink
                to={to}
                onClick={e => {
                    e.stopPropagation();
                }}
            >
                {children}
            </StyledDottedLink>
        );
    }
    return <>{children}</>;
};

const toEmployeeDetail = (employee: EmployeeDetail, year: string) => {
    const employeeId = getEmployeeId(employee);
    if (!employeeId) {
        return null;
    }

    const employeeName = getEmployeeName(employee);
    return {
        pathname: withParams(ResultsRoutes.EMPLOYEE_PAYROLL_ACCOUNT, {
            employeeId,
            year,
        }),
        state: {
            employeeName,
        },
    };
};

const formatNumberDetails = (number: Decimal, employee: EmployeeDetail, year: string) =>
    number.isZero() ? (
        "-"
    ) : (
        <EmployeeLink employee={employee} year={year}>
            <Currency value={number} />
        </EmployeeLink>
    );

interface EmployeeDetailsProps {
    payrollItem: PayrollItem;
    year: string;
    variant: Variant;
}
const EmployeeDetails = ({ payrollItem, year, variant }: EmployeeDetailsProps) => {
    const history = useHistory();

    const handleDetailsRowClick = (employee: EmployeeDetail) => () => {
        const employeeDetail = toEmployeeDetail(employee, year);
        if (employeeDetail) {
            history.push(employeeDetail);
        }
    };

    return (
        <Fragment>
            {payrollItem.employees?.map((employee, employeeIndex) => (
                <StyledTableRow $isPointer key={employeeIndex} onClick={handleDetailsRowClick(employee)}>
                    <TableBodyCell />
                    <TableBodyCell>
                        <EmployeeLink employee={employee} year={year}>
                            {employee.employee?.number}
                        </EmployeeLink>
                    </TableBodyCell>
                    <TableBodyCell>
                        <EmployeeLink employee={employee} year={year}>
                            {getEmployeeName(employee)}
                        </EmployeeLink>
                    </TableBodyCell>
                    {employee.months?.map((month, monthIndex) => (
                        <TableBodyCell key={monthIndex} align="right">
                            {formatNumberDetails(month.amount, employee, year)}
                        </TableBodyCell>
                    ))}
                    <TableBodyCell align="right">
                        {employee.total !== undefined && formatNumberDetails(employee.total.amount, employee, year)}
                    </TableBodyCell>
                    {variant !== "currentYear" && (
                        <TableBodyCell align="right">
                            {formatNumberDetails(employee.lastYearTotalAmount, employee, year)}
                        </TableBodyCell>
                    )}
                    {variant === "currentAndPastYearAbsoluteChange" && (
                        <TableBodyCell align="right">
                            {employee.total !== undefined &&
                                formatAbsoluteDeviation(employee.total.deviationFromLastYear)}
                        </TableBodyCell>
                    )}
                    {variant === "currentAndPastYearPercentageChange" && (
                        <TableBodyCell align="right">
                            {employee.total !== undefined &&
                                formatPercentageDeviation(employee.total.deviationFromLastYearPercentage)}
                        </TableBodyCell>
                    )}
                </StyledTableRow>
            ))}
        </Fragment>
    );
};

interface YearFilterProps {
    year: string;
    onChange: (year: string) => void;
}
const YearFilter = ({ year, onChange }: YearFilterProps) => {
    const currentYear = new Date().getFullYear();

    const years = [
        { value: `${currentYear}`, label: `${currentYear}` },
        { value: `${currentYear - 1}`, label: `${currentYear - 1}` },
        { value: `${currentYear - 2}`, label: `${currentYear - 2}` },
    ];

    return (
        <TableDropdownFilterButton
            onClick={noop}
            isSelected
            onChange={onChange}
            disabled={false}
            data-id="year-picker"
            options={years}
            toggle={false}
        >
            {year}
        </TableDropdownFilterButton>
    );
};

interface PreviousYearFilterProps {
    variant: Variant;
    onChange: (value: string) => void;
}
const PreviousYearFilter = ({ variant, onChange }: PreviousYearFilterProps) => {
    const previousYearsOptions = Object.keys(variantTextKeys).map(key => ({
        value: key,
        label: t(variantTextKeys[key as Variant]),
    }));

    const selectedLabel = previousYearsOptions.find(option => option.value === variant)?.label;

    return (
        <TableDropdownFilterButton
            onClick={noop}
            isSelected
            onChange={onChange}
            disabled={false}
            data-id="previous-year-picker"
            options={previousYearsOptions}
            toggle={false}
        >
            {selectedLabel}
        </TableDropdownFilterButton>
    );
};

interface DetailVsCompactFilterProps {
    filterType: string;
    onChange: (filterType: string) => void;
}
const DetailVsCompactFilter = ({ filterType, onChange }: DetailVsCompactFilterProps) => {
    // Possible filter options: All, Compact1, Compact2, Compact3, Custom, System
    const options = [
        { value: "Compact1", label: t("results.settings.variant.compactView") },
        { value: "All", label: t("results.settings.variant.detailView") },
    ];

    const selectedLabel = options.find(option => option.value === filterType)?.label;

    return (
        <TableDropdownFilterButton
            onClick={noop}
            isSelected
            onChange={onChange}
            disabled={false}
            data-id="detail-vs-compact-picker"
            options={options}
            toggle={false}
        >
            {selectedLabel}
        </TableDropdownFilterButton>
    );
};
