import { Button, Checkbox, FormControl, FormControlLabel, MenuItem, RadioGroup, TextField } from "@material-ui/core";
import OndemandVideoOutlinedIcon from "@material-ui/icons/OndemandVideoOutlined";
import { Autocomplete } from "@material-ui/lab";
import range from "lodash/range";
import * as React from "react";
import { Redirect, useParams } from "react-router";
import styled from "styled-components";
import { MAX_SUBSIDIARIES } from "../../../config";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import {
    BranchNameEntry,
    GetModuleTypeDetailsResponse,
    Period,
    RecordDestination,
    RecordType,
} from "../../../network/APITypes";
import { generalStore } from "../../../stores/GeneralStore";
import { useHideSideBar } from "../../../stores/SideBarStore";
import { getModuleStore } from "../../../stores/moduleStores";
import { Module } from "../../../types/models";
import { pushRoute, withParams } from "../../app/router/history";
import { getBranchTypeString } from "../../hooks/useCompanyBranches";
import { useDocumentAccountingDestinations } from "../../hooks/useDocumentAccountingDestinations";
import { IConfigDueDate, ISetupSubsidiary, ModuleConfig, useModuleConfig } from "../../hooks/useModuleConfig";
import { useSuccessDialog } from "../../hooks/useSuccessDialog";
import { ConfigAccordion, ConfigAccordionDetails, ConfigAccordionSummary } from "../../ui/ConfigAccordion";
import { ConfirmationDialog } from "../../ui/ConfirmationDialog";
import { FieldError } from "../../ui/CustomInputField";
import { IOSSwitch } from "../../ui/IOSSwitch";
import { NavBarBack } from "../../ui/NavBarBack";
import { OverviewLine } from "../../ui/OverviewLine";
import { PageWithStepper } from "../../ui/PageWithStepper";
import { H4WithIcon, IconToggleButton, InfoButton, LinkAction, StyledSelect, TpaRadio } from "../../ui/Primitives";
import { Icon } from "../../util/Icon";
import { MobileContext } from "../../util/MobileContext";
import { customColors } from "../../util/Theme";
import { AdvisorConfigCompanyStepper } from "../AdvisorConfigCompanyStepper";
import { AdvisorRoutes } from "../router/AdvisorRoutes";

export const ConfigSwitch = (props: {
    checked: boolean;
    onCheck: (checked: boolean) => void;
    label: string;
    style?: React.CSSProperties;
    "data-id"?: string;
    checkbox?: boolean;
    disabled?: boolean;
    canEdit: boolean;
}) => (
    <>
        {props.canEdit && (
            <FormControlLabel
                control={
                    props.checkbox === true ? (
                        <Checkbox
                            color="primary"
                            data-id={props["data-id"]}
                            checked={props.checked}
                            onChange={(_, checked) => {
                                props.onCheck(checked);
                            }}
                        />
                    ) : (
                        <IOSSwitch
                            data-id={props["data-id"]}
                            checked={props.checked}
                            onChange={(_, checked) => {
                                props.onCheck(checked);
                            }}
                        />
                    )
                }
                label={props.label}
                style={props.style}
                disabled={props.disabled}
            />
        )}
        {!props.canEdit && (
            <div style={{ display: "flex", ...props.style }}>
                <Icon
                    name={props.checked ? "checkmark" : "closeSmall"}
                    style={{ color: props.checked ? customColors.secondaryColor : customColors.redUrgent }}
                />
                <p style={{ marginLeft: 8 }}>{props.label}</p>
            </div>
        )}
    </>
);

export const ToggleModuleActive = (props: { config: ModuleConfig; style: React.CSSProperties }) => {
    // debug.log(
    //     "### render module toggle",
    //     props.config.module,
    //     props.config.branches.branches.length,
    //     props.config.recordTypes.length,
    // );

    // HR and not branches -> can't activate module
    const noHrBranches = props.config.module === "hr" && props.config.branches.branches.length === 0;

    // No record types because no FIBU -> can't activate module (default records types should always exist according to Leo)
    const noFibu = props.config.companyRecordTypes.length === 0;

    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "space-between" }}>
            <ConfigSwitch
                data-id="switch_toggle_module"
                checked={props.config.moduleActive}
                onCheck={checked => {
                    props.config.setModuleActive(checked);
                }}
                label={t(props.config.module === "accounting" ? "config.accounting.hasAccounting" : "config.hr.hasHr")}
                style={props.style}
                disabled={noFibu || noHrBranches}
                canEdit={props.config.canEdit}
            />
            {props.config.module === "hr" && props.config.branches.branches.length === 0 && !generalStore.isLoading && (
                <div style={{ marginTop: 16, color: customColors.error }}>{t("config.hr.warning.noBranch")}</div>
            )}
        </div>
    );
};

export const ChoosePeriodType = (props: { config: ModuleConfig; style?: React.CSSProperties }) => {
    let content: React.ReactNode;
    if (props.config.canEdit) {
        content = (
            <RadioGroup
                name="periodType"
                value={props.config.periodType}
                onChange={event => {
                    props.config.setPeriodType(event.target.value as Period.PeriodTypeEnum);
                }}
                style={{ marginTop: 24 }}
            >
                <TpaRadio
                    value="week"
                    title={t("config.accounting.week.title")}
                    subtitle={t("config.accounting.week.description")}
                />
                <TpaRadio
                    value="month"
                    title={t("config.accounting.month.title")}
                    subtitle={t("config.accounting.month.description")}
                    style={{ marginTop: 16 }}
                />
                <TpaRadio
                    value="quarter"
                    title={t("config.accounting.quarter.title")}
                    subtitle={t("config.accounting.quarter.description")}
                    style={{ marginTop: 16 }}
                />
            </RadioGroup>
        );
    } else {
        content = (
            <div style={{ marginTop: 24 }}>
                <p>{t(`config.accounting.${props.config.periodType}.title`)}</p>
                <p className="body2" style={{ marginTop: 8 }}>
                    {t(`config.accounting.${props.config.periodType}.description`)}
                </p>
            </div>
        );
    }

    return (
        <div style={props.style}>
            <H4WithIcon icon="calendar">{t("common.timePeriod")}</H4WithIcon>
            {content}
        </div>
    );
};

export const ChooseDueDate = (props: {
    title: string;
    selectTitle: string;
    dueDate: IConfigDueDate;
    setDueDate: (dueDate: IConfigDueDate) => void;
    valueString: (value: number) => string;
    maxDays: number;
    helperText?: string;
    canEdit: boolean;
}) => {
    const isMobile = React.useContext(MobileContext);

    return (
        <>
            <p style={{ marginTop: 24 }}>{props.title}</p>

            {props.canEdit && (
                <div style={{ display: "flex", alignItems: "flex-start", flexWrap: isMobile ? "wrap" : "nowrap" }}>
                    <TextField
                        variant="outlined"
                        label={props.selectTitle}
                        value={props.dueDate.dueDate}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            props.setDueDate({
                                dueDate: event.target.value as unknown as number,
                                noDueDate: props.dueDate.noDueDate,
                            });
                        }}
                        select
                        style={{ marginTop: 16, marginLeft: 24, flexBasis: 400 }}
                        helperText={props.helperText}
                        disabled={props.dueDate.noDueDate}
                    >
                        {range(1, props.maxDays + 1).map(day => (
                            <MenuItem key={day} value={day}>
                                {props.valueString(day)}
                            </MenuItem>
                        ))}
                    </TextField>
                    <FormControlLabel
                        control={<Checkbox color="primary" />}
                        onChange={(_, checked) => {
                            props.setDueDate({
                                dueDate: props.dueDate.dueDate,
                                noDueDate: checked,
                            });
                        }}
                        checked={props.dueDate.noDueDate}
                        label={t("config.noDueDate")}
                        style={{ marginLeft: 16, marginTop: 16, flexShrink: 0 }}
                    />
                </div>
            )}
            {!props.canEdit && (
                <>
                    <p className="body2" style={{ marginLeft: 24, marginTop: 8 }}>
                        {props.dueDate.noDueDate ? t("config.noDueDate") : props.valueString(props.dueDate.dueDate)}
                    </p>
                    <div
                        style={{ marginTop: 16, borderBottom: `1px solid ${customColors.greyLight}`, maxWidth: 360 }}
                    />
                </>
            )}
        </>
    );
};

const DueDates = ({ config, style }: { config: ModuleConfig; style: React.CSSProperties }) => {
    if (!config.canEdit && !config.accountingByCustomer) {
        return null;
    }

    return (
        <div style={style}>
            <H4WithIcon icon="upload">{t("config.accounting.dueDates.title")}</H4WithIcon>
            <div style={{ marginTop: 24 }}>
                <ConfigSwitch
                    data-id="switch_accounting_by_customer"
                    checked={config.accountingByCustomer}
                    onCheck={checked => {
                        config.setAccountingByCustomer(checked);
                    }}
                    label={t("config.accounting.accountingByCustomer")}
                    canEdit={config.canEdit}
                />
            </div>

            {config.accountingByCustomer && (
                <>
                    <ChooseDueDate
                        title={`01. ${t("config.accounting.due.transfer.title")}`}
                        selectTitle={t("config.accounting.due.transfer.select.title")}
                        dueDate={config.dueTransfer}
                        setDueDate={config.setDueTransfer}
                        valueString={value => t("config.accounting.due.transfer.select.value", { day: value })}
                        helperText={t("config.accounting.due.transfer.select.subtitle")}
                        maxDays={20}
                        canEdit={config.canEdit}
                    />

                    <ChooseDueDate
                        title={`02. ${t("config.accounting.due.reports.title")}`}
                        selectTitle={t("config.accounting.due.accounting.reports.select.title")}
                        dueDate={config.dueReports}
                        setDueDate={config.setDueReports}
                        valueString={value => t("config.accounting.due.reports.select.value", { day: value })}
                        helperText={t("config.accounting.due.reports.select.subtitle")}
                        maxDays={14}
                        canEdit={config.canEdit}
                    />

                    <ChooseDueDate
                        title={`03. ${t("config.accounting.due.reportRelease.title")}`}
                        selectTitle={t("config.accounting.due.reportRelease.select.title")}
                        dueDate={config.dueReportRelease}
                        setDueDate={config.setDueReportRelease}
                        valueString={value => t("config.accounting.due.reportRelease.select.value", { day: value })}
                        helperText={t("config.accounting.due.reportRelease.select.subtitle")}
                        maxDays={9}
                        canEdit={config.canEdit}
                    />
                </>
            )}
        </div>
    );
};

export const ToggleGlobalReports = (props: { config: ModuleConfig; style?: React.CSSProperties }) => {
    if (!props.config.canEdit && !props.config.hasGlobalReports) {
        return null;
    }

    return (
        <div style={props.style}>
            <H4WithIcon icon="globe">{t("config.accounting.globalReports.title")}</H4WithIcon>
            <ConfigSwitch
                data-id="switch_has_global_reports"
                checked={props.config.hasGlobalReports}
                onCheck={checked => {
                    props.config.setHasGlobalReports(checked);
                }}
                label={t("config.accounting.globalReports.description")}
                style={{ marginTop: 16 }}
                canEdit={props.config.canEdit}
            />
        </div>
    );
};

const AddSubsidiaryButton = (props: {
    style: React.CSSProperties;
    title: string;
    disabled?: boolean;
    onClick: () => void;
}) => {
    const color = props.disabled === true ? customColors.greyButton : customColors.primaryColor;
    return (
        <div
            style={{
                ...props.style,
                color,
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
            }}
            role="button"
            onClick={props.disabled ? undefined : props.onClick}
        >
            <Icon name="add" />
            <p style={{ color, marginLeft: 16 }}>{props.title}</p>
        </div>
    );
};

const ConfirmDeleteSubsidiaryDialog = (props: {
    module: Module;
    open: boolean;
    subsidiaryName: string;
    lastSubsidiary: boolean;
    onClose: () => void;
    onDelete: () => void;
}) => {
    const store = getModuleStore(props.module);
    return (
        <ConfirmationDialog
            open={props.open}
            title={store.t("config.accounting.delete.subsidiaries.title")}
            message={store.t(
                props.lastSubsidiary
                    ? "config.accounting.delete.subsidiaries.last.message"
                    : "config.accounting.delete.subsidiaries.message",
                { name: props.subsidiaryName },
            )}
            confirmLabel={t("common.delete")}
            onCancel={props.onClose}
            onConfirm={props.lastSubsidiary ? undefined : props.onDelete}
        />
    );
};

const SubsidiarySelect = (props: {
    config: ModuleConfig;
    subsidiary: ISetupSubsidiary;
    select?: boolean;
    index: number;
}) => {
    const { subsidiaries, setSubsidiaries, branches, module, company, canEdit } = props.config;
    const { index, subsidiary } = props;
    const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

    const onDeleteSubsidiary = () => {
        setDeleteDialogOpen(true);
    };

    const doDelete = async () => {
        const subsidiaryId = subsidiary.id;
        const companyId = company?.id;
        let deleteFailed = false;
        if (companyId && subsidiaryId) {
            try {
                await API.deleteSubsidiary(companyId, props.config.module, subsidiaryId);
            } catch (err) {
                generalStore.setError(t("error.delete"), err);
                deleteFailed = true;
            }
        }

        if (!deleteFailed) {
            const newSubs = [...subsidiaries];
            newSubs.splice(index, 1);
            setSubsidiaries(newSubs);
            setDeleteDialogOpen(false);
        }
    };

    const errors = (
        <>
            {subsidiary.touched && !subsidiary.name && <FieldError>{t("error.requiredField")}</FieldError>}
            {subsidiary.duplicate && <FieldError>{t("error.duplicateName")}</FieldError>}
        </>
    );

    const hasError = (!!subsidiary.touched && !subsidiary.name) || subsidiary.duplicate;

    const onChange = (name: string | null) => {
        // Accounting duplicates are only by name
        subsidiary.duplicate = subsidiaries.findIndex(s => s.name === name) >= 0;
        subsidiary.name = name ?? "";
        setSubsidiaries([...subsidiaries]);
    };

    const onSelect = (branch: BranchNameEntry) => {
        // HR duplicates are by name and type
        subsidiary.duplicate =
            subsidiaries.findIndex(
                s => s.name === branch.name && s.type === branch.type && s.typeId === branch.typeId,
            ) >= 0;
        subsidiary.name = branch.name ?? "";
        subsidiary.type = branch.type;
        subsidiary.typeId = branch.typeId;
        setSubsidiaries([...subsidiaries]);
    };

    // If the current subsidiary is not the first then only allow types of the previous subsidiaries
    const selectOptions =
        props.config.subsidiaryType && props.config.subsidiaries.length > 1
            ? branches.branches.filter(b => b.type === props.config.subsidiaryType)
            : branches.branches;

    const moduleT = getModuleStore(module).t;

    return (
        <>
            {canEdit && (
                <div style={{ display: "flex", alignItems: "center" }}>
                    {!props.select && ( // accounting goes here
                        <Autocomplete
                            data-id={`subsidiary_name_${index}`}
                            options={branches.branches.map(b => b.name)}
                            style={{ width: "100%" }}
                            freeSolo
                            autoSelect
                            value={subsidiary.name}
                            onChange={(_, value) => {
                                onChange(value);
                            }}
                            renderInput={params => (
                                <>
                                    <TextField
                                        {...params}
                                        label={moduleT("config.accounting.subsidiary.name")}
                                        variant="outlined"
                                        error={hasError}
                                        onBlur={() => {
                                            subsidiary.touched = true;
                                        }}
                                        onChange={() => {
                                            subsidiary.touched = false;
                                        }}
                                        autoComplete="off" // try to fix chrome autocomplete dropdown
                                    />
                                    {errors}
                                </>
                            )}
                        />
                    )}
                    {props.select && ( // hr goes here
                        <div style={{ width: "100%" }}>
                            <TextField
                                data-id={`subsidiary_name_${index}`}
                                select
                                label={moduleT("config.accounting.subsidiary.name")}
                                variant="outlined"
                                error={hasError}
                                style={{ width: "100%" }}
                                value={selectOptions.findIndex(b => {
                                    return (
                                        b.name === subsidiary.name &&
                                        b.type === subsidiary.type &&
                                        b.typeId === subsidiary.typeId
                                    );
                                })}
                                // inputProps={{ readOnly: branches.fixed }}
                                disabled={branches.fixed}
                                onChange={event => {
                                    onSelect(selectOptions[parseInt(event.target.value)]);
                                }}
                            >
                                {selectOptions.map((branch, index) => (
                                    <MenuItem key={index} value={index}>
                                        {`${branch.name} (${getBranchTypeString(branch)})`}
                                    </MenuItem>
                                ))}
                            </TextField>
                            {errors}
                        </div>
                    )}
                    {!branches.fixed && <LinkAction onClick={onDeleteSubsidiary}>{t("common.delete")}</LinkAction>}
                    <ConfirmDeleteSubsidiaryDialog
                        module={props.config.module}
                        subsidiaryName={subsidiary.name}
                        lastSubsidiary={subsidiaries.length === 1}
                        open={deleteDialogOpen}
                        onClose={() => {
                            setDeleteDialogOpen(false);
                        }}
                        onDelete={doDelete}
                    />
                </div>
            )}
            {!canEdit && <p>{subsidiary.name}</p>}
        </>
    );
};

const StyledRecordType = styled.div`
    padding: 0 15px;
    height: 54px;
    border: 1px solid ${customColors.greyLight};
    display: flex;
    align-items: center;
`;

const Subsidiary = ({
    subsidiary,
    config,
    destinations,
    select,
    index,
}: {
    subsidiary: ISetupSubsidiary;
    config: ModuleConfig;
    destinations: RecordDestination[];
    select?: boolean;
    index: number;
}) => {
    const moduleStore = getModuleStore(config.module);
    const moduleT = moduleStore.t;
    const isFirst = index === 0;
    const [expanded, setExpanded] = React.useState(isFirst);
    const [renderRecordTypes, setRenderRecordTypes] = React.useState(isFirst);
    const [expandedTimeoutId, setExpandedTimeoutId] = React.useState<number>(0);

    const handleChangeRecordTypeDestination = (
        event: React.ChangeEvent<{ value: unknown }>,
        subsidiaryRecordType: RecordType,
    ) => {
        const destination = destinations.find(destination => destination.id === event.target.value);

        if (destination) {
            subsidiaryRecordType.destination = destination;
            config.setSubsidiaries([...config.subsidiaries]);
        }
    };

    const handleChangeRecordTypeOCREnabled = (checked: boolean, subsidiaryRecordType: RecordType) => {
        config.setSubsidiaries(subsidiaries => {
            return subsidiaries.map(sub => {
                if (sub.id !== subsidiary.id) {
                    return sub;
                }
                return {
                    ...sub,
                    recordTypes: sub.recordTypes.map(rt => {
                        if (rt.id !== subsidiaryRecordType.id) {
                            return rt;
                        }
                        return { ...rt, ocrEnabled: checked };
                    }),
                };
            });
        });
    };

    const { companyRecordTypes } = config;
    const allRecordTypesSelected = React.useMemo(() => {
        const all = companyRecordTypes.every(rt => subsidiary.recordTypes.find(srt => srt.id === rt.id));
        const some = subsidiary.recordTypes.length > 0 && !all;
        return { all, some };
    }, [companyRecordTypes, subsidiary.recordTypes]);

    function handleSelectAll(checked: boolean) {
        let recordTypes: RecordType[] = [];
        if (checked) {
            // pick all `companyRecordTypes` and fill the destination
            const defaultDestination = destinations.length > 0 ? destinations[0] : undefined;
            recordTypes = companyRecordTypes.map(recordType => {
                const srt = subsidiary.recordTypes.find(srt => srt.id === recordType.id);
                return {
                    ...recordType,
                    ...srt,
                    destination: srt?.destination ?? recordType.destination ?? defaultDestination,
                };
            });
        } else {
            // no record types should be selected
        }

        config.setSubsidiaries(subsidiaries => {
            return subsidiaries.map(sub => {
                if (sub.id !== subsidiary.id) {
                    return sub;
                }
                return { ...sub, recordTypes };
            });
        });
    }

    return (
        <div style={{ marginTop: 24 }}>
            <SubsidiarySelect config={config} subsidiary={subsidiary} select={select} index={index} />
            <ConfigAccordion
                expanded={expanded}
                style={{ marginTop: 16 }}
                onChange={(event, expanded) => {
                    clearTimeout(expandedTimeoutId);
                    setExpanded(expanded);
                    if (expanded) {
                        setRenderRecordTypes(true);
                    } else {
                        // Keep rendering during close animation
                        const timeoutId = setTimeout(() => {
                            setRenderRecordTypes(false);
                        }, 1000);
                        setExpandedTimeoutId(timeoutId);
                    }
                }}
            >
                <ConfigAccordionSummary className="body2">
                    {config.canEdit && (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="primary"
                                    data-id={`switch_${subsidiary.name}_select_all`}
                                    checked={allRecordTypesSelected.all}
                                    onChange={(_, checked) => {
                                        handleSelectAll(checked);
                                    }}
                                    indeterminate={allRecordTypesSelected.some}
                                />
                            }
                            label={""}
                            // prevent toggling of the accordion if this switch is used inside the accordion summary
                            // see https://v4.mui.com/components/accordion/#additional-actions
                            onFocus={event => {
                                event.stopPropagation();
                            }}
                            onClick={event => {
                                event.stopPropagation();
                            }}
                        />
                    )}
                    {moduleT("config.accounting.recordTypes")}
                </ConfigAccordionSummary>
                <ConfigAccordionDetails>
                    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                        {renderRecordTypes &&
                            companyRecordTypes.map(recordType => {
                                const subsidiaryRecordType = subsidiary.recordTypes.find(rt => rt.id === recordType.id);
                                const destination = destinations.find(
                                    destination => destination.id === subsidiaryRecordType?.destination?.id,
                                );

                                // hide unchecked accounting record types for non-TPA users
                                if (config.module === "accounting" && !config.canEdit && subsidiaryRecordType == null) {
                                    return null;
                                }

                                return (
                                    <StyledRecordType key={recordType.id}>
                                        <ConfigSwitch
                                            checkbox
                                            data-id={`switch_${subsidiary.name}_${recordType.name}`}
                                            checked={!!subsidiaryRecordType}
                                            onCheck={checked => {
                                                const recordTypes = [...subsidiary.recordTypes];
                                                const found = recordTypes.findIndex(rt => rt.id === recordType.id);

                                                if (checked && found < 0) {
                                                    // need to readd default destination since it gets lost after removing record type from subsidiary
                                                    const defaultDestination =
                                                        destinations.length > 0 ? destinations[0] : undefined;

                                                    // add subsidiary
                                                    recordTypes.push({
                                                        ...recordType,
                                                        destination: recordType.destination ?? defaultDestination,
                                                    });
                                                } else if (!checked && found >= 0) {
                                                    // remove subsidiary
                                                    recordTypes.splice(found, 1);
                                                }
                                                config.setSubsidiaries(subsidiaries => {
                                                    return subsidiaries.map(sub => {
                                                        if (sub.id !== subsidiary.id) {
                                                            return sub;
                                                        }
                                                        return { ...sub, recordTypes };
                                                    });
                                                });
                                            }}
                                            label={moduleStore.getRecordTypeName(recordType)}
                                            canEdit={config.canEdit}
                                            style={{ flex: 1 }}
                                        />
                                        {config.module === "accounting" && subsidiaryRecordType && config.canEdit && (
                                            <>
                                                <FormControl>
                                                    <StyledSelect
                                                        value={destination?.id ?? ""}
                                                        onChange={e => {
                                                            handleChangeRecordTypeDestination(e, subsidiaryRecordType);
                                                        }}
                                                        style={{ width: "100%" }}
                                                        MenuProps={{
                                                            anchorOrigin: { vertical: "bottom", horizontal: "left" },
                                                            transformOrigin: { vertical: "top", horizontal: "left" },
                                                            getContentAnchorEl: null,
                                                        }}
                                                        disableUnderline
                                                        disabled={!config.canEdit}
                                                    >
                                                        {destinations.map(destination => (
                                                            <MenuItem key={destination.id} value={destination.id}>
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        flexWrap: "nowrap",
                                                                        flexShrink: 0,
                                                                    }}
                                                                >
                                                                    {destination.description}
                                                                    {destination.hint && (
                                                                        <InfoButton
                                                                            title={destination.hint}
                                                                            color="primary"
                                                                            style={{
                                                                                padding: 0,
                                                                                marginLeft: 8,
                                                                            }}
                                                                        />
                                                                    )}
                                                                </div>
                                                            </MenuItem>
                                                        ))}
                                                    </StyledSelect>
                                                </FormControl>
                                                <IconToggleButton
                                                    checked={!!subsidiaryRecordType.ocrEnabled}
                                                    onChange={checked => {
                                                        handleChangeRecordTypeOCREnabled(checked, subsidiaryRecordType);
                                                    }}
                                                    checkedIcon={<Icon name="ocr" />}
                                                    uncheckedICon={<Icon name="ocrDisabled" />}
                                                    checkedTitle={moduleT("config.accounting.recordTypes.ocrEnabled")}
                                                    uncheckedTitle={moduleT(
                                                        "config.accounting.recordTypes.ocrDisabled",
                                                    )}
                                                    size="small"
                                                    style={{
                                                        color: "black",
                                                        marginLeft: 8,
                                                    }}
                                                />
                                            </>
                                        )}
                                    </StyledRecordType>
                                );
                            })}
                    </div>
                </ConfigAccordionDetails>
            </ConfigAccordion>
        </div>
    );
};

export const Subsidiaries = (props: { config: ModuleConfig; style?: React.CSSProperties; select?: boolean }) => {
    const { subsidiaries, setSubsidiaries, companyRecordTypes, branches, module, canEdit } = props.config;
    const destinations = useDocumentAccountingDestinations(props.config.company?.id);

    let defaultDestination: RecordDestination;

    if (destinations.length > 0) {
        defaultDestination = destinations[0];
    }

    const onAddSubsidiary = () => {
        const subsidiary: ISetupSubsidiary = {
            reactKey: Math.random(), // create a random unique key für .map
            name: "",
            recordTypes: companyRecordTypes.map(recordType => ({
                ...recordType,
                destination: recordType.destination ?? defaultDestination,
            })),
        };

        // Add subsidiary to front
        setSubsidiaries([subsidiary, ...(subsidiaries ?? [])]);
    };

    const moduleT = getModuleStore(module).t;

    return (
        <div style={props.style}>
            {canEdit && (
                <>
                    <H4WithIcon icon="subsidiary">{moduleT("config.accounting.subsidiaries.title")}</H4WithIcon>
                    {props.config.module === "hr" && (
                        <p style={{ fontSize: 12, marginTop: 16 }}>{t("config.hr.subsidiaries.info")}</p>
                    )}
                </>
            )}
            {!canEdit && (
                <H4WithIcon icon="subsidiary">{moduleT("config.accounting.subsidiaries.title.customer")}</H4WithIcon>
            )}

            {canEdit && !branches.fixed && (
                <AddSubsidiaryButton
                    title={moduleT(
                        subsidiaries.length >= MAX_SUBSIDIARIES
                            ? "config.accounting.subsidiary.maxReached"
                            : "config.accounting.subsidiaries.add",
                    )}
                    style={{ marginTop: 16 }}
                    onClick={onAddSubsidiary}
                    disabled={subsidiaries.length >= MAX_SUBSIDIARIES}
                />
            )}

            {subsidiaries.map((subsidiary, index) => {
                return (
                    <Subsidiary
                        subsidiary={subsidiary}
                        destinations={destinations}
                        index={index}
                        config={props.config}
                        select={props.select}
                        key={subsidiary.id ?? subsidiary.reactKey}
                    />
                );
            })}
        </div>
    );
};

export const ConfigSubmit = (props: {
    title?: string;
    companyId: string;
    config: ModuleConfig;
    style?: React.CSSProperties;
    onSubmit?: () => void;
}) => {
    const config = props.config;
    const { subsidiaries, setSubsidiaries } = config;
    const [isSaving, setIsSaving] = React.useState(false);
    const moduleStore = getModuleStore(props.config.module);

    const onSubmit = async () => {
        setIsSaving(true);

        try {
            await API.patchCompany(props.companyId, props.config.patchCompanyPayload);
        } catch (err) {
            generalStore.setError(t("error.edit"), err);
        }

        await Promise.all(
            subsidiaries.map(async subsidiary => {
                // debug.log("### saving ", subsidiary);
                if (subsidiary.recordTypes.length === 0 || !subsidiary.name) {
                    //dont POST/PATCH invalid Subsidiaries
                } else if (subsidiary.reactKey) {
                    try {
                        const res = await API.postSubsidiary({
                            companyId: props.companyId,
                            module: props.config.module,
                            name: subsidiary.name,
                            type: subsidiary.type,
                            typeId: subsidiary.typeId,
                            recordTypes: subsidiary.recordTypes,
                        });

                        subsidiary.reactKey = undefined;
                        subsidiary.id = res.id;
                    } catch (err) {
                        generalStore.setError(moduleStore.t("error.accounting.postSubsidiary"), err);
                    }
                } else if (subsidiary.id) {
                    try {
                        await API.patchSubsidiary({
                            companyId: props.companyId,
                            module: props.config.module,
                            subsidiaryId: subsidiary.id,
                            name: subsidiary.name,
                            type: subsidiary.type,
                            typeId: subsidiary.typeId,
                            recordTypes: subsidiary.recordTypes,
                        });
                    } catch (err) {
                        generalStore.setError(moduleStore.t("error.accounting.postSubsidiary"), err);
                    }
                } else {
                    generalStore.setError(t("error.config.corruptSubsidiary"));
                }
            }),
        );

        setIsSaving(false);

        if (props.onSubmit) {
            props.onSubmit();
        }
    };

    const handleSubmit = () => {
        if (config.moduleActive) {
            let allSubsidiariesHaveNames = true;
            let hasDuplicates = false;

            // We have no formik -> manually touch all subsidiaries and check if all names were filled in
            subsidiaries.forEach(subsidiary => {
                allSubsidiariesHaveNames = allSubsidiariesHaveNames && !!subsidiary.name;
                if (subsidiary.duplicate) {
                    hasDuplicates = true;
                }
                subsidiary.touched = true;
            });

            if (hasDuplicates) {
                generalStore.setError(moduleStore.t("error.config.accounting.duplicate"));
                return;
            }

            if (!allSubsidiariesHaveNames) {
                // Trigger redraw
                setSubsidiaries([...subsidiaries]);
                return;
            }
            if (config.subsidiaries.length === 0) {
                generalStore.setError(moduleStore.t("error.config.accounting.noSubsidiary"));
                return;
            } else {
                for (let i = 0; i < subsidiaries.length; i++) {
                    if (subsidiaries[0].recordTypes.length === 0) {
                        generalStore.setError(
                            t("error.subsidiaryWithoutRecordTypes", {
                                subsidiary: subsidiaries[0].name
                                    ? subsidiaries[0].name
                                    : moduleStore.t("common.accounting.subsidiary"),
                            }),
                        );
                        return;
                    }
                }
            }
        }

        onSubmit();
    };

    return (
        <div style={props.style}>
            <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isSaving}>
                {props.title ?? t("button.nextStep")}
            </Button>
        </div>
    );
};

const ToggleHasResults = ({ config, style }: { config: ModuleConfig; style?: React.CSSProperties }) => {
    if (!config.company) {
        return null;
    }

    return (
        <ConfigSwitch
            data-id="switch_toggle_has_results"
            checked={config.hasAccountingResults}
            onCheck={checked => {
                config.setHasAccountingResults(checked);
            }}
            label={t("config.accounting.hasResults")}
            canEdit={config.canEdit}
            style={style}
        />
    );
};

export const ToggleRequireTwoReportReleases = ({
    config,
    style,
}: {
    config: ModuleConfig;
    style?: React.CSSProperties;
}) => {
    if (!config.company) {
        return null;
    }

    const store = getModuleStore(config.module);
    return (
        <ConfigSwitch
            data-id="switch_toggle_require_two_report_releases"
            checked={config.requiredReportReleasers === 2}
            onCheck={checked => {
                config.setRequiredReportReleasers(checked ? 2 : 1);
            }}
            label={store.t("config.accounting.requireTwoReportReleases")}
            canEdit={config.canEdit}
            style={style}
        />
    );
};

export const ToggleBankAccountTransactionInvoices = ({
    config,
    style,
}: {
    config: ModuleConfig;
    style?: React.CSSProperties;
}) => {
    if (!config.company) {
        return null;
    }

    const store = getModuleStore(config.module);
    return (
        <ConfigSwitch
            data-id="switch_toggle_has_bank_account_transaction_invoices"
            checked={config.hasBankAccountTransactionInvoices}
            onCheck={checked => {
                config.setHasBankAccountTransactionInvoices(checked);
            }}
            label={store.t("config.accounting.hasBankAccountTransactionInvoices")}
            canEdit={config.canEdit}
            style={style}
        />
    );
};

export const AdvisorConfigAccountingContent = ({
    config,
    isSettings,
}: {
    config: ModuleConfig;
    isSettings?: boolean;
}) => {
    if (!config.company) {
        return null;
    }

    const sectionStyle = { marginTop: 32 };

    return (
        <>
            {isSettings === true ? <h3>{t("common.accounting")}</h3> : <h1>{t("common.accounting")}</h1>}

            {config.canEdit && <p style={{ marginTop: 16, fontSize: 12 }}>{t("config.accounting.description")}</p>}
            {!config.canEdit && (
                <p style={{ marginTop: 16, fontSize: 12 }}>{t("config.accounting.description.customer")}</p>
            )}

            {config.moduleActive && config.canEdit && (
                <AdvisorConfigModuleTypeDetails
                    companyId={config.company.id}
                    module={config.module}
                    style={sectionStyle}
                />
            )}
            {config.canEdit && <ToggleModuleActive config={config} style={sectionStyle} />}
            {!config.canEdit && !config.company.hasAccounting && (
                <ToggleModuleActive config={config} style={sectionStyle} />
            )}
            {config.moduleActive && (
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <ToggleHasResults config={config} style={{ marginTop: 16 }} />
                    <ToggleRequireTwoReportReleases config={config} style={{ marginTop: 16 }} />
                    <ToggleBankAccountTransactionInvoices config={config} style={{ marginTop: 16 }} />
                    <ChoosePeriodType config={config} style={sectionStyle} />
                    <DueDates config={config} style={sectionStyle} />
                    <ToggleGlobalReports config={config} style={sectionStyle} />
                    <Subsidiaries config={config} style={sectionStyle} />
                </div>
            )}
        </>
    );
};

export const AdvisorConfigModuleTypeDetails = (props: {
    companyId: string;
    module: Module;
    style?: React.CSSProperties;
}) => {
    const [moduleTypeDetail, setModuleTypeDetail] = React.useState<GetModuleTypeDetailsResponse>();
    React.useEffect(() => {
        const load = async () => {
            try {
                generalStore.isLoading = true;
                const response = await API.getModuleTypeDetails(props.companyId, props.module);
                setModuleTypeDetail(response);
            } catch (err) {
                generalStore.setError(t("error.loadModuleTypeDetails"), err);
            } finally {
                generalStore.isLoading = false;
            }
        };

        load();
    }, [props.companyId, props.module]);

    if (!moduleTypeDetail) {
        return null;
    }

    return (
        <div style={props.style}>
            <h4 style={{ marginBottom: 16 }}>{t("common.generalData")}</h4>
            <OverviewLine
                title={t("config.moduleTypeDetails.asp.helpInstructions")}
                component={
                    <Button
                        variant="text"
                        color="primary"
                        size="small"
                        startIcon={<OndemandVideoOutlinedIcon />}
                        onClick={() =>
                            window.open(
                                "https://tpagroupat.sharepoint.com/sites/TPAConnectInfo/SitePages/Sie-haben-Klienten,-die-die-TPA-Connect-App-jetzt-schon-nützen-könnten--So-funktioniert-das-On-Boarding!.aspx#c-h-e-c-k-l-i-s-t-e",
                                "_blank",
                            )
                        }
                    >
                        {t("config.moduleTypeDetails.asp.helpInstructions.open")}
                    </Button>
                }
            />
            <OverviewLine
                title={t("config.moduleTypeDetails.label.dataSource")}
                label={moduleTypeDetail.source.displayName}
            />
            {moduleTypeDetail.source.additionalData &&
                Object.entries(moduleTypeDetail.source.additionalData).map(([key, value]) => (
                    <OverviewLine key={key} title={key} label={value} />
                ))}
        </div>
    );
};

export const AdvisorConfigAccountingSite = () => {
    useHideSideBar();
    const { companyId } = useParams<{ companyId: string }>();
    const config = useModuleConfig(companyId, "accounting", true);

    const successDialog = useSuccessDialog({
        title: t("dialog.companyConfig.success.header"),
        onClose: () => {
            if (companyId) {
                pushRoute(withParams(AdvisorRoutes.CONFIG.OVERVIEW, { companyId }));
            } else {
                pushRoute(AdvisorRoutes.COMPANIES.INACTIVE);
            }
        },
    });

    if (!companyId) {
        // No company in route -> get out
        return <Redirect to={AdvisorRoutes.COMPANIES.INACTIVE} />;
    }

    if (!config.company) {
        // Company not loaded yet -> wait
        return null;
    }

    return (
        <>
            <NavBarBack
                title={t("config.companies")}
                backLabel={`${t("config.overview")}/${t("config.terms.termsOfUseTitle")}`}
                backTarget={withParams(AdvisorRoutes.CONFIG.TERMS_OF_USE, { companyId })}
                cancelTarget={withParams(AdvisorRoutes.CONFIG.OVERVIEW, { companyId })}
                companyName={config.company.name}
            />
            <PageWithStepper stepper={<AdvisorConfigCompanyStepper company={config.company} />}>
                <AdvisorConfigAccountingContent config={config} />
                <ConfigSubmit
                    style={{ marginTop: 32, textAlign: "right" }}
                    companyId={companyId}
                    config={config}
                    onSubmit={() => {
                        if (config.company?.hrEnabled) {
                            pushRoute(withParams(AdvisorRoutes.CONFIG.HR, { companyId }));
                        } else {
                            successDialog.openDialog();
                        }
                    }}
                />
            </PageWithStepper>
        </>
    );
};
