import { observer } from "mobx-react";
import * as React from "react";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { companiesStore } from "../../../stores/CompaniesStore";
import { generalStore } from "../../../stores/GeneralStore";
import { useHideSideBar } from "../../../stores/SideBarStore";
import { ViewerFile, viewerStore } from "../../../stores/ViewerStore";
import { DocumentModule, Module } from "../../../types/models";
import { debug } from "../../../util/debug";
import { formatISODateOnly } from "../../../util/helpers";
import { Routes } from "../../app/router/Routes";
import { pushRoute } from "../../app/router/history";
import { useExternalDocuments } from "../../hooks/useExternalDocuments";
import { useQueryParams } from "../../hooks/useQueryParams";
import { useWebhookConfiguration } from "../../hooks/useWebhookConfiguration";
import { ModuleConfiguration, useWebhookModuleConfiguration } from "../../hooks/useWebhookModuleConfiguration";
import { CenteredContent } from "../../ui/CenteredContent";
import { DocumentLine } from "../../ui/DocumentLine/DocumentLine";
import { NavBarBack } from "../../ui/NavBarBack";
import { CenteredForm } from "../../ui/Primitives";
import { SiteContent } from "../../ui/SiteContent";
import { webhookStore } from "../WebhookStore";
import { WebhookRoutes } from "../router/WebhookRoutes";

export const WebhookDocumentTicketsSite = observer(function WebhookDocumentTicketsSite() {
    useHideSideBar();

    const query: {
        source?: string;
        additionalSourceData?: string;
        archive?: string;
        documentId?: string[];
        databox?: string[];
        dueDate?: string;
        companyId?: string;
        module?: DocumentModule;
        periodId?: string;
        subsidiaryId?: string;
        taskTitle?: string;
        recordTypeId?: string;
    } = useQueryParams({ arrayKeys: ["documentId", "databox"] });
    const databoxValid = !!query.databox && query.databox.length === 1;
    const archiveValid = !!query.archive && !!query.documentId && query.documentId.length === 1;

    const validQuery = databoxValid || archiveValid;
    if (!validQuery) {
        debug.error("### invalid ticket query");

        // Query could be invalid because we get to many IDs -> continue with first entry
        const hasDatabox = query.databox && query.databox.length > 0;
        const hasArchive = query.archive && query.documentId && query.documentId.length > 0;

        if (!hasDatabox && !hasArchive) {
            // We have no databox and no archive document ids -> bail out
            pushRoute(Routes.COCKPIT);
        }
    }

    const { documents, errorDialog } = useExternalDocuments({
        source: query.source ?? "TPA",
        additionalSourceData: query.additionalSourceData,
        archive: query.archive,
        documentIds: query.documentId,
        databoxIds: query.databox,
        generateThumbnails: true,
    });
    const document = documents?.[0];

    const hasRecord = !!document?.record;

    React.useEffect(() => {
        if (hasRecord && !document?.companyId) {
            generalStore.setError(t("error.recordWithNotAllowedCompany"));
        }
    }, [document, hasRecord]);

    const webhookConfig = useWebhookConfiguration({
        showCompany: true,
        chooseCompany: true,
        modules: ["accounting", "hr"],
        showCategory: false,
        // Pre select company for the first document (all docs are from the same
        // company since that's how you multiselect in NTCS)
        companyId: query.companyId ?? document?.companyId,
        module: query.module ?? document?.record?.moduleType,
        disabled: hasRecord,
    });

    const onSubmit = (dueDate?: Date, taskTitle?: string) => {
        webhookStore.company = webhookConfig.company ?? undefined;
        webhookStore.document = document;

        pushRoute(WebhookRoutes.DOCUMENT_TICKETS_DETAILS, {
            params: {
                companyId: webhookConfig.company?.id ?? "",
                module: webhookConfig.module,
                periodId: moduleConfig.periodId,
                subsidiaryId: moduleConfig.subsidiaryId,
                recordTypeId: moduleConfig.recordTypeId,
            },
            query: {
                databox: query.databox,
                dueDate: dueDate ? formatISODateOnly(dueDate) : undefined,
                source: query.source,
                additionalSourceData: query.additionalSourceData,
                archive: query.archive,
                documentId: query.documentId,
                recordId: document?.record?.id,
                taskTitle: taskTitle,
            },
        });
    };

    const moduleConfig = useWebhookModuleConfiguration({
        companyId: webhookConfig.companyId,
        company: webhookConfig.company,
        module: webhookConfig.module as Module,
        periodId: query.periodId ?? document?.record?.periodId,
        subsidiaryId: query.subsidiaryId ?? document?.record?.subsidiaryId,
        recordTypeId: query.recordTypeId ?? document?.record?.recordTypeId,
    });

    if (!validQuery) {
        return null;
    }

    const handleView = () => {
        const files = [document].map<ViewerFile>(doc => ({
            id: doc.documentId ?? "",
            name: doc.name,
            src: () => {
                return API.getExternalDocumentDownloadUrl({
                    archiveId: doc.archiveId ?? "",
                    documentId: doc.documentId ?? "",
                    source: doc.source ?? "TPA",
                    additionalSourceData: doc.additionalSourceData,
                });
            },
            download: () => {
                return API.downloadExternalDocument({
                    archiveId: doc.archiveId ?? "",
                    documentId: doc.documentId ?? "",
                    source: doc.source ?? "TPA",
                    additionalSourceData: doc.additionalSourceData,
                });
            },
        }));
        viewerStore.open(files, document.documentId ?? "");
    };

    return (
        <>
            <NavBarBack
                cancelTarget={companiesStore.selectedCompanyId ? Routes.COCKPIT : Routes.COMPANY_OVERVIEW}
                title={t("webhook.tickets.navbar", { document: document?.name })}
            />
            <CenteredContent>
                <SiteContent>
                    <CenteredForm>
                        <h1>{t("webhook.tickets.title")}</h1>
                        <p style={{ marginTop: 16 }} className="body2">
                            {t("webhook.tickets.info")}
                        </p>
                        <CenteredForm>
                            <DocumentLine
                                fileName={document?.name}
                                style={{ marginBottom: 32, padding: 0, fontWeight: "bold" }}
                                onView={handleView}
                            />
                            {webhookConfig.uploadConfiguration}
                            {webhookConfig.companyId && webhookConfig.module && (
                                <ModuleConfiguration
                                    {...moduleConfig}
                                    dueDate={query.dueDate}
                                    initialTaskTitle={
                                        query.taskTitle ?? t("webhook.tickets.taskTitle", { name: document?.name })
                                    }
                                    submitButtonLabel={t("webhook.tickets.button")}
                                    showGlobal={false}
                                    showNotifyCustomer={false}
                                    showDueDate={true}
                                    chooseRecordTypes={true}
                                    disabled={hasRecord}
                                    onSubmit={onSubmit}
                                />
                            )}
                        </CenteredForm>
                    </CenteredForm>
                </SiteContent>
            </CenteredContent>
            {errorDialog}
        </>
    );
});
