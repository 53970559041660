import { Button } from "@material-ui/core";
import { useParams } from "react-router";
import { DEFAULT_STAFF_CAN_READ } from "../../../config";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { EmployeeDocument } from "../../../network/APITypes";
import { companiesStore } from "../../../stores/CompaniesStore";
import { generalStore } from "../../../stores/GeneralStore";
import { useHideSideBar } from "../../../stores/SideBarStore";
import { ViewerFile, viewerStore } from "../../../stores/ViewerStore";
import { IEmployeeRouteParams } from "../../../types/models";
import { pushRoute, withParamsAndQuery } from "../../app/router/history";
import { useEmployee } from "../../hooks/useEmployee";
import { useQueryParams } from "../../hooks/useQueryParams";
import { DocumentLine } from "../../ui/DocumentLine/DocumentLine";
import { IUpload } from "../../ui/DocumentUpload";
import { DocumentUploadSmall, IAcceptedFile, useDocumentUploadSmall } from "../../ui/DocumentUploadSmall";
import { HrDeregistrationSiteContainer } from "../HrDeregistrationSiteContainer";
import { hrStore } from "../HrStore";
import { HrRoutes } from "../router/HrRoutes";
import { IRegistrationQuery } from "../types";

export const HrEmployeesDeregistrationDocumentsSite = () => {
    useHideSideBar();

    const companyId = companiesStore.selectedCompanyId;
    const subsidiaryId = hrStore.selectedSubsidiaryId;

    const query: IRegistrationQuery = useQueryParams();

    const { employeeId } = useParams<IEmployeeRouteParams>();
    const { employee } = useEmployee(employeeId, !!query.isPreRegistration);

    const uploadFile = async (file: IAcceptedFile<never>) => {
        if (employeeId) {
            try {
                return await API.postEmployeeDocument({
                    companyId,
                    subsidiaryId,
                    employeeId,
                    file: file.file,
                    type: "deregisterDocument",
                    staffCanRead: DEFAULT_STAFF_CAN_READ,
                    isPreRegistration: !!query.isPreRegistration,
                });
            } catch (error) {
                generalStore.setError(t("error.upload"), error);
            }
        }
    };

    const onSubmitDeregistration = async () => {
        if (employee) {
            try {
                generalStore.isLoading = true;
                // Deregistrations are not directly transferred
                await API.transferPreRegistration(companyId, subsidiaryId, employee, {});
                pushRoute(HrRoutes.EMPLOYEES.FORMER);
            } catch (error) {
                generalStore.setError(t("error.transferEmployee"), error);
            } finally {
                generalStore.isLoading = false;
            }
        }
    };

    const config = useDocumentUploadSmall(uploadFile, false);

    if (!employee || !employeeId) {
        return null;
    }

    const handleView = (upload: IUpload<EmployeeDocument>) => {
        const files = [upload].map<ViewerFile>(u => ({
            id: u.response.id,
            name: u.file.name,
            src: u.file,
        }));
        viewerStore.open(files, upload.response.id);
    };

    const renderDocumentLine = (upload: IUpload<EmployeeDocument>, onDeleted: () => void) => {
        const onDelete = async () => {
            await API.deleteEmployeeDocument(
                companyId,
                subsidiaryId,
                upload.response.id,
                employeeId,
                !!query.isPreRegistration,
            );
            onDeleted();
        };

        const handleChangeVisibleToEmployee = async (visible: boolean) => {
            if (!companyId || !subsidiaryId || !employeeId) {
                return;
            }

            try {
                await API.patchEmployeeDocument({
                    companyId,
                    subsidiaryId,
                    employeeId,
                    documentId: upload.response.id,
                    isPreRegistration: !!query.isPreRegistration,
                    staffCanRead: visible,
                });
            } catch (error) {
                generalStore.setError(t("error.general"), error);
            }
        };

        return (
            <DocumentLine
                switchLabel={t("screen.advisor.uploads.upload.visibleToEmployee")}
                onCheck={handleChangeVisibleToEmployee}
                defaultChecked={DEFAULT_STAFF_CAN_READ}
                fileName={upload.file.name}
                onView={() => {
                    handleView(upload);
                }}
                onDelete={onDelete}
                key={upload.file.name}
            />
        );
    };

    return (
        <HrDeregistrationSiteContainer
            backTarget={withParamsAndQuery(HrRoutes.EMPLOYEES.DEREGISTRATION_DATA, { employeeId }, query)}
            backLabel={t("screen.hr.deregistration.documents.navback", {
                firstname: employee.firstName,
                lastname: employee.lastName,
            })}
            cancelTarget={HrRoutes.EMPLOYEES.IN_PROGRESS}
            employee={employee}
            query={query}
        >
            <h1 style={{ marginBottom: 16 }}>{t("screen.hr.employee.deregistration.stepper.documents")}</h1>
            <p style={{ fontSize: 10, lineHeight: "14px", color: "#444756", marginBottom: 24 }}>
                {t("screen.hr.employee.deregistration.data.subtitle")}
            </p>
            <DocumentUploadSmall
                config={config}
                renderSuccess={renderDocumentLine}
                data-id="deregistration_document_upload"
            />
            <div style={{ textAlign: "right", marginTop: 40 }}>
                <Button
                    data-id="deregistration_submit"
                    variant="contained"
                    color="primary"
                    onClick={onSubmitDeregistration}
                    disabled={generalStore.isLoading}
                >
                    {t("screen.hr.employee.deregistration.documents.submitButton")}
                </Button>
            </div>
        </HrDeregistrationSiteContainer>
    );
};
