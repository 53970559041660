import { observer } from "mobx-react";
import { Redirect } from "react-router";
import { t } from "../../../i18n/util";
import { ViewerFile, viewerStore } from "../../../stores/ViewerStore";
import { debug } from "../../../util/debug";
import { Routes } from "../../app/router/Routes";
import { useQueryParams } from "../../hooks/useQueryParams";
import { useWebhookConfiguration } from "../../hooks/useWebhookConfiguration";
import { DocumentUploadSite } from "../../shared/DocumentUploadSite";
import { DocumentLineEmployees } from "../../ui/DocumentLine/DocumentLineEmployees";
import { FileWithMetaData, usePayslipUploadConfig } from "../hooks/usePayslipUploadConfig";
import { HrRoutes } from "../router/HrRoutes";

export const HrEmployeePayslipUploadSite = observer(function HrEmployeePayslipUploadSite() {
    const { companyId, subsidiaryId } = useQueryParams<{ companyId: string; subsidiaryId: string }>();

    const uploadConfig = useWebhookConfiguration({
        showCompany: true,
        chooseCompany: false,
        companyId,
        chooseModule: false,
        module: "hr",
        showCategory: true,
        chooseCategory: false,
        category: "payslip",
        isUpload: true,
    });

    const payslipConfig = usePayslipUploadConfig({ companyId, subsidiaryId });

    const validQuery = !!companyId;
    if (!validQuery) {
        debug.error("### invalid payslip Upload query");
        return <Redirect to={Routes.COCKPIT} />;
    }

    const handleView = (file: FileWithMetaData) => {
        const files = [file].map<ViewerFile>(file => {
            return {
                id: file.file.name,
                name: file.file.name,
                src: file.file,
            };
        });
        viewerStore.open(files, file.file.name);
    };

    const renderAccepted = (file: FileWithMetaData, onDelete: () => void) => {
        return (
            <DocumentLineEmployees
                companyId={uploadConfig.companyId}
                subsidiaryId={payslipConfig.subsidiaryId ?? ""}
                fileName={file.file.name}
                onView={() => {
                    handleView(file);
                }}
                onDelete={onDelete}
                key={file.file.name}
                onSetEmployeeId={employeeId => {
                    file.metaData = { ...file.metaData, employeeId };
                }}
                showEmptyErrors={payslipConfig.showEmployeeMissingError}
            />
        );
    };

    return (
        <DocumentUploadSite
            title={t("hr.employee.payslip.upload.title")}
            cancelTarget={HrRoutes.EMPLOYEES.CURRENT}
            uploadConfig={uploadConfig}
            documentUploadSmallConfig={payslipConfig.documentUploadSmallConfig}
            renderAccepted={renderAccepted}
        >
            {documentUploadSmallElement => {
                return (
                    <>
                        {payslipConfig.configComponent}
                        {documentUploadSmallElement}
                        {payslipConfig.confirmComponent}
                    </>
                );
            }}
        </DocumentUploadSite>
    );
});
