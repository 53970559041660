import { Button, CircularProgress } from "@material-ui/core";
import { Location } from "history";
import { observer } from "mobx-react";
import { useCallback, useState } from "react";
import styled from "styled-components";
import { POPUP_WINDOW_FEATURES } from "../../../config";
import { GLOBAL_FEATURES } from "../../../features";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { Account, NamedExternalDocumentId } from "../../../network/APITypes";
import { companiesStore } from "../../../stores/CompaniesStore";
import { generalStore } from "../../../stores/GeneralStore";
import { formatDate, toCurrency } from "../../../util/helpers";
import { useExternalWindow } from "../../hooks/useExternalWindow";
import { PaymentDialog } from "../../shared/PaymentDialog";
import { RenderCell } from "../../ui/GridTable";
import { TableLabel } from "../../ui/Primitives";
import { TableDocuments } from "../../ui/TableDocuments";
import { Icon } from "../../util/Icon";
import { LastCompletedAccountTransactionPayment } from "../LastCompletedAccountTransactionPayment";
import { Currency, Pill } from "../ResultsValue";
import { EnhancedAccountTransaction } from "./useAccountTransactions";

export function useResultsAccountSheetTableRenderCell(
    companyId: string,
    financialAccountancyId: string,
    account: Account,
    onPaymentCompleted: () => void,
): RenderCell<EnhancedAccountTransaction> {
    return useCallback(
        (accountTransaction, column) => {
            switch (column.column) {
                case "postingSymbol":
                    return accountTransaction.postingSymbol;

                case "documentNumber":
                    return accountTransaction.documentNumber;

                case "postingText":
                    return <TableLabel style={{ maxWidth: undefined }}>{accountTransaction.postingText}</TableLabel>;

                case "documentDate":
                    return formatDate(accountTransaction.documentDate);

                case "amount": {
                    let content = (
                        <CurrentCurrency
                            value={accountTransaction.amount}
                            currency={toCurrency(accountTransaction.currency)}
                            currencyPosition="start"
                        />
                    );
                    if (
                        accountTransaction.foreignCurrencyAmount &&
                        !accountTransaction.foreignCurrencyAmount.isZero()
                    ) {
                        content = (
                            <FlexColumn>
                                {content}
                                <ForeignCurrency
                                    value={accountTransaction.foreignCurrencyAmount}
                                    currency={accountTransaction.foreignCurrency}
                                    currencyPosition="start"
                                />
                            </FlexColumn>
                        );
                    }
                    return content;
                }

                case "openItemAmount":
                    if (
                        accountTransaction.isOpenItem &&
                        accountTransaction.openItemAmount &&
                        !accountTransaction.openItemAmount.isZero()
                    ) {
                        let content = (
                            <CurrentCurrency
                                value={accountTransaction.openItemAmount}
                                currency={toCurrency(accountTransaction.currency)}
                                currencyPosition="start"
                            />
                        );
                        if (
                            accountTransaction.foreignCurrencyOpenItemAmount &&
                            !accountTransaction.foreignCurrencyOpenItemAmount.isZero()
                        ) {
                            content = (
                                <FlexColumn>
                                    {content}
                                    <ForeignCurrency
                                        value={accountTransaction.foreignCurrencyOpenItemAmount}
                                        currency={accountTransaction.foreignCurrency}
                                        currencyPosition="start"
                                    />
                                </FlexColumn>
                            );
                        }
                        return (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                }}
                            >
                                {content}
                                {accountTransaction.lastCompletedPayment ? (
                                    <LastCompletedAccountTransactionPayment
                                        lastCompletedPayment={accountTransaction.lastCompletedPayment}
                                    />
                                ) : null}
                            </div>
                        );
                    } else {
                        return <Pill variant="green">{t("results.accountSheet.paid")}</Pill>;
                    }

                case "documents": {
                    const { documents } = accountTransaction;
                    if (!documents?.length) {
                        return null;
                    }
                    return (
                        <Documents
                            companyId={companyId}
                            financialAccountancyId={financialAccountancyId}
                            account={account}
                            accountTransaction={accountTransaction}
                            documents={documents}
                        />
                    );
                }

                case "accountBankingInformation": {
                    if (!GLOBAL_FEATURES.payAccountTransactions) {
                        return null;
                    }

                    if (account.accountGroup !== "VendorAccount") {
                        return null;
                    }
                    return (
                        <PaymentButton
                            companyId={companyId}
                            financialAccountancyId={financialAccountancyId}
                            accountTransaction={accountTransaction}
                            onPaymentCompleted={onPaymentCompleted}
                        />
                    );
                }

                default:
                    return null;
            }
        },
        [account, companyId, financialAccountancyId, onPaymentCompleted],
    );
}

interface PaymentButtonProps {
    companyId: string;
    financialAccountancyId: string;
    accountTransaction: EnhancedAccountTransaction;
    onPaymentCompleted: () => void;
}

const PaymentButton = observer(
    ({ companyId, financialAccountancyId, accountTransaction, onPaymentCompleted }: PaymentButtonProps) => {
        const handleExternalWindowClosed = () => {
            onPaymentCompleted();
        };

        const [isPaymentDialogOpen, setIsPaymentDialogOpen] = useState<boolean>(false);
        const externalWindow = useExternalWindow(handleExternalWindowClosed);

        const { accountBankingInformation } = accountTransaction;
        if (!accountBankingInformation) {
            return null;
        }

        return (
            <>
                <Button
                    color="primary"
                    startIcon={
                        externalWindow.isOpen ? (
                            <CircularProgress size={16} style={{ marginRight: 8 }} />
                        ) : (
                            <Icon name="payment" data-id="accountbankinginformation-pay" />
                        )
                    }
                    onClick={() => {
                        setIsPaymentDialogOpen(true);
                    }}
                    disabled={
                        !!externalWindow.isOpen ||
                        !!accountTransaction.lastCompletedPayment ||
                        !companiesStore.selectedCompanyStore?.canPayAccountTransaction ||
                        accountTransaction.isPaymentDisabled
                    }
                >
                    {t("results.accountSheet.accountDetails.pay")}
                </Button>
                <PaymentDialog
                    open={isPaymentDialogOpen}
                    onClose={function (): void {
                        setIsPaymentDialogOpen(false);
                    }}
                    onSubmit={async values => {
                        try {
                            generalStore.isLoading = true;
                            const { url } = await API.postAccountTransactionPayment(
                                companyId,
                                accountTransaction.accountNumber,
                                financialAccountancyId,
                                accountTransaction.transactionId,
                                {
                                    iban: values.iban,
                                    openItemAmount: values.amount.toString().replace(",", "."),
                                    purpose: values.purpose,
                                },
                            );

                            if (url) {
                                externalWindow.open(url, "TPA", POPUP_WINDOW_FEATURES);
                            }
                        } catch (error) {
                            generalStore.setError(t("error.payAccountTransaction"), error);
                        } finally {
                            generalStore.isLoading = false;
                        }
                    }}
                    iban={accountBankingInformation.iban}
                    amount={accountTransaction.openItemAmount?.abs().toNumber()}
                    purpose={accountTransaction.externalDocumentNumber}
                    maxAmount={accountTransaction.openItemAmount?.abs().toNumber()}
                />
                {externalWindow.components}
            </>
        );
    },
);

const CurrentCurrency = styled(Currency)`
    font-weight: bold;
`;
const ForeignCurrency = styled(Currency)`
    font-size: 0.9em;
`;

const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
`;

interface DocumentsProps {
    companyId: string;
    financialAccountancyId: string;
    account: Account;
    accountTransaction: EnhancedAccountTransaction;
    documents: NamedExternalDocumentId[];
}

const Documents = ({ companyId, financialAccountancyId, account, accountTransaction, documents }: DocumentsProps) => {
    const downloadUrl = (document: NamedExternalDocumentId) => {
        return API.getAccountTransactionsDocumentDownloadUrl({
            companyId,
            financialAccountancyId,
            accountNr: account.number,
            body: {
                transactionName: accountTransaction.postingText,
                documents: [document],
            },
        });
    };
    const download = async (document: NamedExternalDocumentId) => {
        return API.putDownloadAccountTransactionsDocuments({
            companyId,
            financialAccountancyId,
            accountNr: account.number,
            body: {
                transactionName: accountTransaction.postingText,
                documents: [document],
            },
        });
    };
    const downloadAll = async (documents: NamedExternalDocumentId[]) => {
        return API.putDownloadAccountTransactionsDocuments({
            companyId,
            financialAccountancyId,
            accountNr: account.number,
            body: {
                transactionName: accountTransaction.postingText,
                documents,
            },
        });
    };

    return (
        <TableDocuments documents={documents} downloadUrl={downloadUrl} download={download} downloadAll={downloadAll} />
    );
};

export interface ResultsAccountSheetNavigationState {
    type: "accountSheet";
    location: Location;
    account: Account;
    documentId: string;
    doNotScroll: true;
}
