import { Button, Slide } from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import React from "react";
import { t } from "../../i18n/util";
import { HttpStatusCode } from "../../network/httpStatusCode";
import { APIClientStatusCodeError, isValidationError } from "../../network/NetworkStapler";
import { IConfigUser } from "../../stores/ConfigStore";
import { CustomDialog } from "../ui/CustomDialog";
import { FormattedMessage } from "../ui/FormattedMessage";
import { TpaWhiteButton } from "../ui/Primitives";
import { ResponsiveButtonContainer } from "../ui/ResponsiveButtonContainer";
import { Icon } from "../util/Icon";
import { customColors } from "../util/Theme";

export interface IFailedUser {
    employee: IConfigUser;
    statusCode: number;
    apiError?: APIClientStatusCodeError | null;
}

const errorToString = (failedUser: IFailedUser) => {
    const statusCode = failedUser.statusCode;
    const apiError = failedUser.apiError;
    const isNewUser = failedUser.employee.type !== "user";

    switch (statusCode) {
        case HttpStatusCode.Conflict_409: {
            return isNewUser
                ? t("failedUserDialog.error.emailAlreadyExists")
                : t("failedUserDialog.error.pendingPermissionChanges");
        }
        case HttpStatusCode.Forbidden_403: {
            return t("error.forbidden");
        }
        case HttpStatusCode.BadRequest_400: {
            if (apiError) {
                const response = apiError.response;
                if (isValidationError(response) && response.validationErrors?.length) {
                    const roles = response.validationErrors.find(error => error.key === "permissions.roles");
                    if (roles?.error === "permissions.roles in body should have at most 1 items") {
                        return t("error.permission.onlyOneRoleAllowed");
                    } else {
                        return t("error.permission.corruptRoles");
                    }
                }

                if (response.type === "NO_USER_EMAIL") {
                    return t("error.missingEmail");
                }
            }

            return t("error.unknown", { statusCode });
        }
        default: {
            return t("error.unknown", { statusCode });
        }
    }
};

const Transition = React.forwardRef(function Transition(props: TransitionProps, ref: React.Ref<unknown>) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const FailedUserDialog = (props: {
    open: boolean;
    onClose?: () => void;
    onSubmit?: () => void;
    failedUsers: IFailedUser[];
    totalUsers: number;
    onRetry?: () => void;
}) => {
    return (
        <CustomDialog open={props.open} TransitionComponent={Transition} keepMounted onClose={props.onClose}>
            <div style={{ padding: "32px 0 24px 0" }}>
                <div style={{ padding: "0 42px 16px 42px", borderBottom: `1px solid ${customColors.greyLight}` }}>
                    <h1 style={{ marginBottom: 16 }}>{t("failedUserDialog.message")}</h1>
                    <FormattedMessage
                        id="failedUserDialog.amount"
                        values={{
                            failedUserCount: props.failedUsers.length,
                            userCount: props.totalUsers,
                        }}
                    />
                </div>
                <div style={{ padding: 16, borderBottom: `1px solid ${customColors.greyLight}` }}>
                    <div>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            {props.failedUsers.map((failedUser, index) => (
                                <React.Fragment key={failedUser.employee.id}>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            height: 44,
                                            color: customColors.error,
                                            backgroundColor: customColors.errorBackground,
                                            borderRadius: 4,
                                            padding: 8,
                                            marginTop: index > 0 ? 4 : undefined,
                                        }}
                                    >
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <div>
                                                <Icon
                                                    name="user"
                                                    style={{ display: "block", color: customColors.error }}
                                                />
                                            </div>
                                            <span style={{ marginLeft: 16 }}>
                                                {failedUser.employee.firstName} {failedUser.employee.lastName}
                                            </span>
                                        </div>
                                        <span>{errorToString(failedUser)}</span>
                                    </div>
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                </div>

                <div style={{ padding: "24px 42px 0" }}>
                    <ResponsiveButtonContainer>
                        {props.onSubmit && (
                            <TpaWhiteButton onClick={props.onSubmit} data-id="continue_without">
                                {t("failedUserDialog.button.text.continue")}
                            </TpaWhiteButton>
                        )}
                        {props.onRetry && (
                            <Button onClick={props.onRetry} variant="contained" color="primary" data-id="retry_upload">
                                {t("failedUserDialog.button.text.retry")}
                            </Button>
                        )}
                    </ResponsiveButtonContainer>
                </div>
            </div>
        </CustomDialog>
    );
};
