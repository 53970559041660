import { useMediaQuery } from "@material-ui/core";
import { observer } from "mobx-react";
import { t } from "../../i18n/util";
import { EmployeeDocument, GetEmployeeDocumentsResponse } from "../../network/APITypes";
import { companiesStore } from "../../stores/CompaniesStore";
import { generalStore } from "../../stores/GeneralStore";
import { FileBar, FileBarMobile, IFilebarSection } from "../ui/FileBar";
import { TpaBadge } from "../ui/Primitives";

interface Props {
    masterDocuments: GetEmployeeDocumentsResponse | null;
    ongoingDocuments: GetEmployeeDocumentsResponse | null;
    canEdit?: boolean;
    onShowAll?: () => void;
    onAddFiles?: () => void;
    onViewFile: (document: EmployeeDocument) => void;
    onDownloadFiles?: (employeeDocumentIds: string[]) => void;
}

export const HrEmployeeOverviewFileBar = observer(function HrEmployeeOverviewFileBar(props: Props) {
    const fileBarBreakpoint = useMediaQuery(`(max-width: 1230px)`, { noSsr: true });

    const NUM_FILE_BAR_DOCUMENTS = 3;

    let masterDataDocuments: EmployeeDocument[] = [];
    if (props.masterDocuments?.documents) {
        masterDataDocuments = props.masterDocuments.documents.slice(0, NUM_FILE_BAR_DOCUMENTS);
    }

    let ongoingDocuments: EmployeeDocument[] = [];
    if (props.ongoingDocuments?.documents) {
        ongoingDocuments = props.ongoingDocuments.documents.slice(0, NUM_FILE_BAR_DOCUMENTS);
    }

    const sections: IFilebarSection[] = [];

    let showAllDocuments = false;
    if (masterDataDocuments.length > 0) {
        sections.push({
            label: t("screen.hr.employee.details.linkedFiles"),
            documents: masterDataDocuments,
            onAdd: props.onAddFiles,
        });

        showAllDocuments = true;
    }

    if (ongoingDocuments.length > 0) {
        sections.push({
            label: t("screen.hr.employee.details.records"),
            documents: ongoingDocuments,
            onAdd: masterDataDocuments.length === 0 ? props.onAddFiles : undefined,
        });

        showAllDocuments = true;
    }

    let documentsAction;
    if (showAllDocuments) {
        documentsAction = () => {
            props.onShowAll?.();
        };
    } else if (props.canEdit) {
        documentsAction = () => {
            if (masterDataDocuments.length === 0 && ongoingDocuments.length === 0) {
                props.onAddFiles?.();
            }
        };
    }

    const documentsActionLabel = t(
        showAllDocuments ? "screen.hr.employee.details.showAll" : "screen.hr.employee.details.addFiles",
    );

    const handleDownload = (documentIds: string[]) => {
        if (!props.onDownloadFiles) {
            return;
        }

        // Convert documentIds to employeeDocumentIds
        const employeeDocuments = masterDataDocuments
            .filter(document => documentIds.includes(document.document.id))
            .concat(ongoingDocuments.filter(document => documentIds.includes(document.document.id)))
            .map(document => document.id);

        if (employeeDocuments.length === 0) {
            generalStore.setError("error.documentNotFound");
            return;
        }

        props.onDownloadFiles(employeeDocuments);
    };

    const badgeCounts = companiesStore.selectedCompanyStore?.badgeCounts;
    const badge = showAllDocuments && !!badgeCounts?.openEmployeeDocumentReleases && (
        <TpaBadge style={{ marginLeft: 8 }}>{badgeCounts.openEmployeeDocumentReleases}</TpaBadge>
    );

    // All empty, no action -> get out
    if (masterDataDocuments.length === 0 && ongoingDocuments.length === 0 && !props.onAddFiles) {
        return null;
    }

    return fileBarBreakpoint ? (
        <FileBarMobile
            buttonFunction={documentsAction}
            buttonText={documentsActionLabel}
            sections={sections}
            canEdit={!!props.canEdit}
            onView={props.onViewFile}
            onDownload={handleDownload}
            badge={badge}
        />
    ) : (
        <FileBar
            buttonFunction={documentsAction}
            buttonText={documentsActionLabel}
            sections={sections}
            canEdit={!!props.canEdit}
            onView={props.onViewFile}
            onDownload={handleDownload}
            badge={badge}
        />
    );
});
