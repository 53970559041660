import { withStyles } from "@material-ui/core";
import compact from "lodash/compact";
import * as React from "react";
import { useLocation } from "react-router";
import { IMessageIDS, t } from "../../i18n/util";
import { ChatUserInfo, GetResponsibleUsersResponse, Role, TPAEmployeePublicUserInfo } from "../../network/APITypes";
import { authStore } from "../../stores/AuthStore";
import { companiesStore } from "../../stores/CompaniesStore";
import { Status } from "../../stores/Status";
import { roleMapping } from "../../util/permissionConfig";
import { getFullName } from "../../util/user";
import { useChatColleagues } from "../hooks/useChatColleagues";
import { ChatVariant, useChatContacts } from "../hooks/useChatContacts";
import { useQueryParams } from "../hooks/useQueryParams";
import { useTableFilters } from "../hooks/useTableFilters";
import { IOSSwitch } from "../ui/IOSSwitch";
import { SiteContent } from "../ui/SiteContent";
import { TextDivider } from "../ui/TextDivider";
import { UserCard } from "../ui/UserCard";
import { DROPDOWN_FILTER_TYPE, IFilterItem, ITableFilters } from "../ui/filter/types";
import { MobileContext } from "../util/MobileContext";

const CustomSwitch = withStyles({
    root: {
        margin: 0,
    },
})(IOSSwitch);

export const useContactList = () => {
    // In case we get passes a list of responsible users via history state
    const location = useLocation<{ responsibleUsers?: GetResponsibleUsersResponse }>();

    const query: { selectedContactId?: string; selectedColleagueId?: string } = useQueryParams();

    const [role, setRole] = React.useState<Role>();

    const [showInactiveColleagues, setShowInactiveColleagues] = React.useState(false);
    const [selectedColleagueId, setSelectedColleagueId] = React.useState<string | undefined>(query.selectedColleagueId);
    const [selectedColleagueIsActive, setSelectedColleagueIsActive] = React.useState<boolean>(true);
    const [selectedColleague, setSelectedColleague] = React.useState<ChatUserInfo | undefined>(undefined);

    const [chatVariant, setChatVariant] = React.useState<ChatVariant>("active");

    const companyId = companiesStore.selectedCompanyId;
    const company = companiesStore.selectedCompany;
    const companyStore = companiesStore.selectedCompanyStore;

    const isTpa = !!companyStore?.permissions.hasAnyTPARole;
    // CONNECT-550: do not give full chat access to staff only TPA employees
    const isTpaNotStaff = isTpa && !authStore.isStaffOnly;

    const colleagues = useChatColleagues(isTpaNotStaff ? companyId : undefined, showInactiveColleagues);

    React.useEffect(() => {
        // Cache the selected colleague, so that when we switch between active and inactive colleagues, we can still
        // return the colleague data
        const colleague = colleagues.users.find(u => u.id === selectedColleagueId);
        if (colleague) {
            setSelectedColleague(colleague);
        }
    }, [colleagues.users, selectedColleagueId]);

    const contacts = useChatContacts({
        companyId: companyId,
        isTpa: isTpaNotStaff,
        role,
        preloaded: location.state?.responsibleUsers,
        colleagueId: selectedColleagueId,
        chatVariant,
    });

    const admins = contacts.admins;
    const team = contacts.team;
    const [selectedContactId, setSelectedContactId] = React.useState(query.selectedContactId ?? "");

    // TPAPORTAL-1921: preselect first contact that has an unread count > 0
    React.useEffect(() => {
        // select first contact with unread chat messages
        if (!selectedContactId) {
            const firstUnreadAdmin = contacts.admins.find(a => (a.unreadChatMessages ?? 0) > 0);
            if (firstUnreadAdmin) {
                setSelectedContactId(firstUnreadAdmin.id);
            } else {
                const firstUnreadTeam = contacts.team.find(a => (a.unreadChatMessages ?? 0) > 0);
                if (firstUnreadTeam) {
                    setSelectedContactId(firstUnreadTeam.id);
                }
            }
        }
    }, [contacts.admins, contacts.team, selectedContactId]);

    const isMobile = React.useContext(MobileContext);

    const handleClickUser = (user: ChatUserInfo | TPAEmployeePublicUserInfo) => {
        setSelectedContactId(user.id);
    };

    // Roles used for filtering chat partners: all except tpa roles, none and staff
    let filterRoles = (Object.keys(roleMapping) as Role[]).filter(role => {
        const isTpa = role.includes("tpa-");
        const isNone = role === "none";
        const isStaff = role === "staff";
        const isCustom = role === "custom";
        return !isTpa && !isNone && !isStaff && !isCustom;
    });

    // TPAPORTAL-2424
    filterRoles = filterRoles.filter(role => role !== "international-projects");

    // TPAPORTAL-2457 - move super-admin to top
    filterRoles = filterRoles.filter(role => role !== "super-admin");
    filterRoles.unshift("super-admin");

    if (!company?.hasAccounting) {
        filterRoles = filterRoles.filter(role => role !== "accounting-management" && role !== "accounting-assistent");
    }
    if (!company?.hasHR) {
        filterRoles = filterRoles.filter(role => role !== "hr-management" && role !== "hr-assistent");
    }
    if (!company?.hasAccounting && !company?.hasHR) {
        filterRoles = filterRoles.filter(role => role !== "assistent");
    }

    const rolesFilter: IFilterItem = {
        type: DROPDOWN_FILTER_TYPE,
        name: "roles",
        label: t("settings.personalData.role"),
        options: [{ value: "all", label: t("common.all") }].concat(
            filterRoles.map(key => {
                const role = roleMapping[key];
                let labelM: IMessageIDS | undefined = "jobTitle" in role ? role.jobTitle : undefined;
                let labelF: IMessageIDS | undefined = "jobTitleFemale" in role ? role.jobTitleFemale : undefined;

                if (!labelM) {
                    labelM = role.male;
                }
                if (!labelF && role.female) {
                    labelF = role.female;
                }
                return {
                    value: key,
                    label: t(labelM) + (labelF ? ` / ${t(labelF)}` : ""),
                };
            }),
        ),
        toggle: false,
        onChange(role: string) {
            setRole(role === "all" ? undefined : (role as Role));
        },
    };

    // Used for impersonation -> choose a colleague to impersonate
    const colleaguesFilter: IFilterItem = {
        type: DROPDOWN_FILTER_TYPE,
        name: "recipient",
        label: t("common.recipient"),
        listSettings: [
            <div key="inactive" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div>{t("support.filter.setting.showInactiveColleagues")}</div>
                <div style={{ marginLeft: 32 }}>
                    <CustomSwitch
                        data-id="showInactiveColleagues"
                        checked={showInactiveColleagues}
                        onChange={(_, checked) => {
                            setShowInactiveColleagues(checked);

                            // TODO set option back to "me"?
                            // how to set the filter?
                            //   setSelectedColleagueId(undefined);
                        }}
                    />
                </div>
            </div>,
        ],
        options: [
            {
                value: "me",
                label: `${t("common.me")} (${companyStore?.badgeCounts.unreadChatMessages ?? 0})`,
            },
        ].concat(
            colleagues.users.map(user => ({
                value: user.id,
                label: `${getFullName(user)} (${user.unreadChatMessages ?? 0})`,
            })),
        ),
        toggle: false,
        onChange(value: string) {
            if (value === "me") {
                setSelectedColleagueId(undefined);
            } else {
                setSelectedColleagueId(value);
                setSelectedColleagueIsActive(!showInactiveColleagues);
            }

            setSelectedContactId("");
        },
    };

    // Switch between active and inactive chats
    const chatVariantFilter: IFilterItem = {
        type: DROPDOWN_FILTER_TYPE,
        name: "chatVariant",
        label: t("common.chat"),
        options: [
            {
                value: "active",
                label: t("support.filter.option.activeChats"),
            },
            {
                value: "inactive",
                label: t("support.filter.option.inactiveChats"),
            },
            {
                value: "all",
                label: t("support.filter.option.allChats"),
            },
        ],
        toggle: false,
        onChange(value: string) {
            setChatVariant(value as ChatVariant);
            setSelectedContactId("");
        },
    };

    const filters = useTableFilters({
        showAllButton: false,
        initialFilters: {
            roles: { value: "all" },
            recipient: { value: selectedColleagueId ?? "me" },
            chatVariant: { value: "active" },
        },
        filters: [
            {
                category: "general",
                entries: compact([
                    isTpaNotStaff ? rolesFilter : undefined,
                    isTpaNotStaff ? colleaguesFilter : undefined,
                    chatVariantFilter,
                ]) as IFilterItem[],
            },
        ],
        onChangeFilters: (selected: ITableFilters) => {
            // pass
        },
    });

    // When selecting contact -> if unreadChatMessages() mismatches the one from colleagues list
    // The contact list count takes precedence. This can happen when a message was sent after the
    // last colleagues poll. Then the badges in the colleagues drop down don't match up with the unreadChatMessages from the list
    const loadedColleagueId = contacts.loadedColleagueId;
    React.useEffect(() => {
        // We have a colleague and his/her contact list was loaded
        if (selectedColleagueId && selectedColleagueId === loadedColleagueId) {
            // The colleague was loaded and cached
            if (selectedColleague) {
                const unreadContactChatMessages = contacts.unreadChatMessages();
                // Check for unread count mismatch
                if (selectedColleague.unreadChatMessages !== unreadContactChatMessages) {
                    selectedColleague.unreadChatMessages = unreadContactChatMessages;
                    colleagues.refresh();
                }
            }
        }
    }, [colleagues, contacts, loadedColleagueId, selectedColleague, selectedColleagueId]);

    const getUserStatus = (user: ChatUserInfo | TPAEmployeePublicUserInfo): Status | undefined => {
        if (user.id === selectedContactId) {
            return "inProgress";
        } else if ((user.unreadChatMessages ?? 0) > 0) {
            return "open";
        }
    };

    const contactList = (
        <SiteContent
            style={{
                overflow: "auto",
                height: isMobile ? undefined : `calc(100vh - var(--navBarHeight))`,
            }}
        >
            <h4>{t("support.yourContacts")}</h4>
            {filters.Buttons}
            {admins.length > 0 && (
                <TextDivider
                    text={isTpaNotStaff ? t("support.admins.title") : t("support.partners.title")}
                    style={{ marginTop: 24 }}
                />
            )}
            {admins.map(user => (
                <UserCard
                    onClick={() => {
                        handleClickUser(user);
                    }}
                    user={user}
                    active={contacts.isActiveContact(user.id)}
                    status={getUserStatus(user)}
                    showDetail={user.id === selectedContactId}
                    highlightSelection
                    key={user.id}
                />
            ))}
            {team.length > 0 && <TextDivider text={t("support.responsible.title")} style={{ marginTop: 24 }} />}
            {team.map(user => (
                <UserCard
                    onClick={() => {
                        handleClickUser(user);
                    }}
                    user={user}
                    active={contacts.isActiveContact(user.id)}
                    status={getUserStatus(user)}
                    showDetail={user.id === selectedContactId}
                    highlightSelection
                    key={user.id}
                />
            ))}

            {filters.Components}
        </SiteContent>
    );

    // Are we currently impersonating an inactive colleague?
    const impersonateInactive = !!selectedColleagueId && !selectedColleagueIsActive;

    return {
        contactList,
        selectedContactId,
        setSelectedContactId,
        selectedContact() {
            return contacts.getContact(selectedContactId);
        },
        selectedColleagueId,
        isActiveChat:
            (chatVariant === "active" || (chatVariant === "all" && contacts.isActiveContact(selectedContactId))) &&
            !impersonateInactive,
        clearSelectedColleague: () => {
            setSelectedColleagueId(undefined);
            filters.changeFilter("recipient", "me");
            setSelectedContactId("");
        },
        selectedColleague: selectedColleagueId ? selectedColleague : undefined,
        reload: contacts.reload,
        reloadColleagues: colleagues.reload,
    };
};
