import { useEffect, useState } from "react";
import { t } from "../../../i18n/util";
import { RendererProps, ViewerFileSrc } from "../types";
import { ErrorRenderer } from "./ErrorRenderer";
import { Centered } from "./Helper";

interface Src {
    url: string;
    revoke: boolean;
}

const srcFromViewerSrc = (src: ViewerFileSrc): Src => {
    if (typeof src === "string") {
        return { url: src, revoke: false };
    }
    return { url: window.URL.createObjectURL(src), revoke: true };
};

interface ImageRendererProps extends RendererProps {}

export const ImageRenderer = ({ file, zoomLevel, align }: ImageRendererProps) => {
    const [src, setSrc] = useState<Src | null>(() => {
        if (typeof file.src === "function") {
            return null; // see useEffect below
        }
        return srcFromViewerSrc(file.src);
    });

    const [error, setError] = useState<unknown>(null);

    const rawFileSrc = file.src;
    useEffect(() => {
        if (typeof rawFileSrc !== "function") {
            return; // already set in the use state above
        }
        let cancelled = false;
        const load = async () => {
            try {
                const src = await rawFileSrc();
                if (cancelled) {
                    return;
                }
                setSrc(srcFromViewerSrc(src));
            } catch (error) {
                setError(error);
            }
        };
        load();
        return () => {
            cancelled = true;
        };
    }, [rawFileSrc]);

    useEffect(() => {
        return () => {
            if (src?.revoke) {
                window.URL.revokeObjectURL(src.url);
            }
        };
    }, [src]);

    const handleError = () => {
        setError(t("error.general"));
    };

    if (error) {
        return <ErrorRenderer error={error} />;
    }

    if (!src) {
        return null;
    }

    return (
        <Centered style={align === "top" ? { height: "unset" } : undefined}>
            <img src={src.url} alt={file.name} onError={handleError} style={{ zoom: zoomLevel }} />
        </Centered>
    );
};
