import { observer } from "mobx-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { SvsAccount, SvsTransaction } from "../../../network/APITypes";
import { companiesStore } from "../../../stores/CompaniesStore";
import { generalStore } from "../../../stores/GeneralStore";
import { useInMemoryTableStore, useTableStore } from "../../../stores/TableStore";
import { viewerStore } from "../../../stores/ViewerStore";
import { getMinMaxDate } from "../../../util/date";
import { replaceTextWithElements } from "../../../util/helpers";
import { Comparer } from "../../../util/sort";
import { Routes } from "../../app/router/Routes";
import { useTableFilters } from "../../hooks/useTableFilters";
import { Currency } from "../../results/ResultsValue";
import { CenteredContent } from "../../ui/CenteredContent";
import { NavBarBack } from "../../ui/NavBarBack";
import { FormattedCurrency, TableLabel } from "../../ui/Primitives";
import { SiteContent } from "../../ui/SiteContent";
import { createContextMenuColumn, GetContextMenuItems, RenderCell, Table, TableColumn } from "../../ui/Table";
import { TableSearchBarWithAction } from "../../ui/TableSearchBar";
import { DATE_RANGE_FILTER_TYPE, DateRange, ITableFilters } from "../../ui/filter/types";

export const KpisSvsAccountTransactionsSite = observer(function KpisSvsAccountTransactionsSite() {
    const kpiStore = companiesStore.selectedCompanyStore?.kpiStore;

    const svsAccount = kpiStore?.svsAccount;

    useEffect(() => {
        kpiStore?.loadSvsAccount();
    }, [kpiStore]);

    if (!svsAccount) {
        return null; // a loading indicator is displayed by the `generalStore`
    }

    const value = (
        <FormattedCurrency
            key="value"
            value={svsAccount.liabilityEnd}
            minimumFractionDigits={0}
            maximumFractionDigits={0}
        />
    );

    const latestTransaction = svsAccount.transactions?.[svsAccount.transactions.length - 1];
    const latestTransactionQuarter = latestTransaction ? `${latestTransaction.year}/Q${latestTransaction.quarter}` : "";

    const title = replaceTextWithElements(t("svsAccount.transactions.title"), { "[value]": value });
    const date = t("screen.cockpit.kpis.svsAccount.kpi.label", {
        quarter: latestTransactionQuarter,
    });

    return (
        <>
            <NavBarBack
                title={title}
                controlComponent={date}
                backLabel={t("sidebar.list.cockpit")}
                backTarget={Routes.COCKPIT}
                showCancel={false}
            />
            <KpisSvsAccountTransactions svsAccount={svsAccount} />
        </>
    );
});

type TransactionWithId = SvsTransaction & {
    id: string;
    period: string;
};

const withId = (transaction: SvsTransaction, index: number): TransactionWithId => {
    return {
        ...transaction,
        period: `${transaction.year ?? ""} Q${transaction.quarter ?? ""}`,
        id: `${index}`,
    };
};

const KpisSvsAccountTransactions = ({ svsAccount }: { svsAccount: SvsAccount }) => {
    const transactions = useMemo(() => {
        return svsAccount.transactions?.map<TransactionWithId>(withId) ?? [];
    }, [svsAccount.transactions]);

    return (
        <CenteredContent>
            <SiteContent>
                <TransactionsTable transactions={transactions ?? []} />
            </SiteContent>
        </CenteredContent>
    );
};

const renderCell: RenderCell<TransactionWithId> = (transaction, column) => {
    switch (column.column) {
        case "period":
            return <TableLabel style={{ maxWidth: undefined }}>{transaction.period ?? ""}</TableLabel>;
        case "text":
            return <TableLabel style={{ maxWidth: undefined }}>{transaction.text ?? ""}</TableLabel>;
        case "liability":
            return <Currency value={transaction.liability ?? 0} />;
        default:
            return null;
    }
};

const baseColumns: TableColumn<TransactionWithId>[] = [
    { column: "period", label: "svsAccount.transactions.table.label.quarter", width: "min-width-nowrap" },
    { column: "text", label: "svsAccount.transactions.table.label.text" },
    {
        column: "liability",
        label: "svsAccount.transactions.table.label.amount",
        width: "min-width-nowrap",
        align: "right",
    },
    createContextMenuColumn({}),
];

const columns: TableColumn<TransactionWithId>[] = [...baseColumns];

const searchFn = (transaction: TransactionWithId, search: string) => {
    return (
        !!transaction.text?.toLocaleLowerCase().includes(search) ||
        !!transaction.liability?.toString().includes(search) ||
        false
    );
};

const sortComparer: Comparer<TransactionWithId> = {
    text: (a, b) => {
        const textA = a.text ?? "";
        const textB = b.text ?? "";
        return textA.localeCompare(textB);
    },
};

const TransactionsTable = observer(function TransactionsTable({ transactions }: { transactions: TransactionWithId[] }) {
    const tableStore = useTableStore<TransactionWithId>("SvsAccountTransactionsTable", {
        orderBy: "period",
        orderDir: "asc",
    });
    const companyId = companiesStore.selectedCompanyId;
    const { filterFn, tableFilters } = useFilters(transactions);

    useInMemoryTableStore({
        tableStore,
        items: transactions,
        filterFn,
        searchFn,
        sortComparer,
    });

    const getContextMenuItems = useCallback<GetContextMenuItems<TransactionWithId>>(
        transaction => [
            {
                title: t("svsAccount.transactions.table.contextMenu.download"),
                onClick: () => handleDownloadDocument(transaction, companyId ?? ""),
            },
        ],
        [companyId],
    );

    const handleDownloadDocument = async (transaction: TransactionWithId, companyId: string) => {
        try {
            await API.putDownloadSvsQuarterDocument({
                companyId,
                year: transaction.year,
                quarter: transaction.quarter,
            });
        } catch (error) {
            generalStore.setError(t("error.download"), error);
        }
    };

    const handleShowDocument = (transaction: TransactionWithId, companyId: string) => {
        const files = [
            {
                id: transaction.id,
                name: `SVS ${transaction.year} Q${transaction.quarter}.pdf`,
                src: () => {
                    return API.getSsvDocumentDownloadUrl({
                        companyId,
                        year: transaction.year,
                        quarter: transaction.quarter,
                    });
                },
                download: () => {
                    return API.putDownloadSvsQuarterDocument({
                        companyId,
                        year: transaction.year,
                        quarter: transaction.quarter,
                    });
                },
            },
        ];
        viewerStore.open(files, transaction.id);
    };

    const handleRowClick = (transaction: TransactionWithId) => {
        handleShowDocument(transaction, companyId ?? "");
    };

    return (
        <>
            <TableSearchBarWithAction
                label="svsAccount.transactions.table.search.count"
                placeholder="svsAccount.transactions.table.search.placeholder"
                search={tableStore.search}
                totalCount={tableStore.items.length}
                onChangeSearch={tableStore.handleSearchChange}
                tableFilters={tableFilters}
            />
            <Table
                columns={columns}
                tableStore={tableStore}
                renderCell={renderCell}
                getContextMenuItems={getContextMenuItems}
                onRowClick={handleRowClick}
            />
        </>
    );
});

function useFilters(transactions: TransactionWithId[]) {
    const [dateRange, setDateRange] = useState<DateRange | null>(null);

    const { min: minDate, max: maxDate } = useMemo(() => {
        return getMinMaxDate(
            transactions.map(transaction => {
                const year = transaction.year ?? 0;
                const quarter = transaction.quarter ?? 1;
                return new Date(year, (quarter - 1) * 3);
            }),
        );
    }, [transactions]);

    const tableFilters = useTableFilters({
        filters: [
            {
                category: "dateRange",
                entries: [
                    {
                        type: DATE_RANGE_FILTER_TYPE,
                        name: "dateRange",
                        label: t("svsAccount.transactions.table.filters.quarter"),
                        onChange: range => {
                            setDateRange(range);
                        },
                        minDate: minDate,
                        maxDate: maxDate,
                    },
                ],
            },
        ],
        onChangeFilters: (selected: ITableFilters) => {
            if (!selected.dateRange) {
                setDateRange(null);
            }
        },
    });

    const filterFn = useCallback(
        (transaction: TransactionWithId) => {
            if (dateRange) {
                const year = transaction.year ?? 0;
                const quarter = transaction.quarter ?? 1;
                const date = new Date(year, (quarter - 1) * 3);
                if ((dateRange.from && dateRange.from > date) || (dateRange.to && dateRange.to < date)) {
                    return false;
                }
            }

            return true;
        },
        [dateRange],
    );

    return { filterFn, tableFilters };
}
