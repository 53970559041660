import React from "react";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { Report, ReportDetails } from "../../network/APITypes";
import { generalStore } from "../../stores/GeneralStore";
import { getModuleStore } from "../../stores/moduleStores";
import { Module } from "../../types/models";
import { hasFileExtension } from "../../util/files";
import { SignReport, useDocumentSign } from "./useDocumentSign";

export const useReport = ({
    global,
    companyId,
    periodId,
    module,
    reportType,
    reportId,
    subsidiaryId,
}: {
    global: boolean;
    companyId: string | undefined;
    periodId: string | undefined;
    module: Module;
    reportType?: Report.TypeEnum;
    reportId?: string;
    subsidiaryId?: string;
}) => {
    const [report, setReport] = React.useState<ReportDetails | null>(null);

    const moduleStore = getModuleStore(module);

    const canRead = subsidiaryId
        ? moduleStore.canReadReports(subsidiaryId)
        : global && moduleStore.canReadGlobalReports();
    const canRelease = subsidiaryId
        ? moduleStore.canReleaseReports(subsidiaryId)
        : global && moduleStore.canReleaseGlobalReports();

    const loadReport = React.useCallback(async () => {
        if (!companyId || !periodId || !canRead || (!global && !subsidiaryId) || !reportId) {
            return;
        }

        try {
            generalStore.isLoading = true;
            const response = await API.getReport({
                companyId,
                module,
                periodId,
                reportId,
                subsidiaryId: global ? undefined : subsidiaryId,
                reportType,
            });
            if (response) {
                setReport(response);
            }
        } catch (err) {
            generalStore.setError(t("error.loadReports"), err);
        } finally {
            generalStore.isLoading = false;
        }
    }, [companyId, periodId, canRead, global, subsidiaryId, reportId, module, reportType]);

    React.useEffect(() => {
        loadReport();
    }, [loadReport]);

    const onReleaseReport = async (_: SignReport) => {
        if (!report) {
            return;
        }
        await moduleStore.releaseReport({
            companyId: companyId,
            reportId: report.id,
            isGlobal: !report.subsidiaryId,
            periodId: report.periodId,
            subsidiaryId: report.subsidiaryId,
        });
        await loadReport();
    };

    const sign = useDocumentSign({
        onReleaseReport,
        onReload: loadReport,
    });

    return {
        report,
        global,
        canRelease,
        release: () => {
            if (!companyId || !canRelease || !report) {
                return;
            }

            if (hasFileExtension(report.document.name, "pdf")) {
                sign.openQesDialog({
                    signContext: "report",
                    itemToRelease: report,
                    reportConfig: {
                        companyId,
                        subsidiaryId: report.subsidiaryId,
                        module: report.moduleType,
                        global: !report.subsidiaryId,
                        periodId: report.periodId,
                    },
                    mimeType: report.document.mimeType,
                });
            } else {
                onReleaseReport(report);
            }
        },
        components: (
            <>
                {sign.qesDialog}
                {sign.qesBlockedDialog}
            </>
        ),
    };
};
