import { Modal } from "@material-ui/core";
import { observer } from "mobx-react";
import { ViewerFile, viewerStore } from "../../stores/ViewerStore";
import { InlineViewer } from "./InlineViewer";

export const FullscreenViewer = observer(function FullscreenViewer() {
    const { files, selectedFileId } = viewerStore;

    const open = files.length > 0;

    const handleSelect = (file: ViewerFile) => {
        viewerStore.selectedFileId = file.id;
    };
    const handleClose = () => {
        viewerStore.close();
    };
    const handleZoom = (zoomLevel: number) => {
        viewerStore.zoomLevel = zoomLevel;
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <InlineViewer
                files={files}
                selectedFileId={selectedFileId}
                onSelect={handleSelect}
                onClose={handleClose}
                zoomLevel={viewerStore.zoomLevel}
                onZoom={handleZoom}
            />
        </Modal>
    );
});
