import { Button, Table, TableBody, TableCell, TableRow, withStyles } from "@material-ui/core";
import { observer } from "mobx-react";
import * as React from "react";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import {
    BasicRecordWithIDs,
    DetailedEmployeePreRegistration,
    Draft,
    Message,
    TicketDetails,
    User,
} from "../../network/APITypes";
import { getApiError } from "../../network/NetworkStapler";
import { HttpStatusCode } from "../../network/httpStatusCode";
import { authStore } from "../../stores/AuthStore";
import { coordinator } from "../../stores/Coordinator";
import { generalStore } from "../../stores/GeneralStore";
import { viewerStore } from "../../stores/ViewerStore";
import { getModuleStore } from "../../stores/moduleStores";
import { IDocument, IEmployee } from "../../types/models";
import { formatDate, formatDateTime } from "../../util/date";
import { handleChatMessageError } from "../../util/helpers";
import { getDraftStatusWithName } from "../../util/projectHelpers";
import { getFullName } from "../../util/user";
import { pushRoute, withParamsAndQuery } from "../app/router/history";
import { useCloseTicket } from "../hooks/useCloseTicket";
import { useTicketDetails } from "../hooks/useTicketDetails";
import { useTicketMessages } from "../hooks/useTicketMessages";
import { HrEmployeePersonalDataOverview } from "../hr/HrEmployeePersonalDataOverview";
import { hrStore } from "../hr/HrStore";
import { HrRoutes } from "../hr/router/HrRoutes";
import { Chat } from "../shared/Chat";
import { AssignTicketDialog } from "../ui/AssignTicketDialog";
import { CenteredContent } from "../ui/CenteredContent";
import { DocumentDownloadLine } from "../ui/DocumentLine/DocumentLine";
import { SplitContainer } from "../ui/SplitContainer";
import { Thumbnails } from "../ui/Thumbnails";
import { MobileContext } from "../util/MobileContext";
import { CONTENT_PADDING_HORIZONTAL, customColors } from "../util/Theme";

const Description = withStyles({
    root: {
        borderBottom: "none",
        padding: "8px 0",
        fontSize: 12,
        color: customColors.body2Dark,
        verticalAlign: "top",
    },
})(TableCell);

const Label = withStyles({
    root: {
        borderBottom: "none",
        padding: "8px 0",
        paddingLeft: 16,
        fontSize: 14,
        color: customColors.body1Dark,
    },
})(TableCell);

const PREVIEW_MIN_WIDTH = 300;
const DOWNLOAD_MARGIN = 16;

const DocumentMetaData = observer(function DocumentMetaData({
    showDownload,
    uploadedAt,
    uploader,
    dueDate,
    document,
    projectItemDraft,
    record,
    onDownloadDocument,
}: {
    showDownload?: boolean;
    uploadedAt?: Date;
    uploader?: User;
    dueDate?: Date;
    document?: Partial<IDocument>;
    projectItemDraft?: Draft;
    record?: BasicRecordWithIDs;
    onDownloadDocument: () => void;
}) {
    const moduleStore = record ? getModuleStore(record.moduleType) : undefined;

    return (
        <div style={{ width: "100%", minWidth: PREVIEW_MIN_WIDTH }}>
            {showDownload && document && (
                <DocumentDownloadLine
                    fileName={document.name}
                    documentId={document.id}
                    onDownload={onDownloadDocument}
                />
            )}
            <Table style={{ marginTop: DOWNLOAD_MARGIN }}>
                <TableBody>
                    {!projectItemDraft && (
                        <>
                            <TableRow>
                                <Description>{t("table.label.transmittedAt")}</Description>
                                <Label>{formatDate(uploadedAt)}</Label>
                            </TableRow>
                            <TableRow>
                                <Description>{t("table.label.uploadedBy")}</Description>
                                <Label>{getFullName(uploader)}</Label>
                            </TableRow>
                        </>
                    )}
                    {record && (
                        <>
                            <TableRow>
                                <Description>{t("table.label.costCenter")}</Description>
                                <Label>
                                    {moduleStore?.getCostCenterLabel(
                                        moduleStore?.getCostCenter(record?.costCenter, true),
                                    ) ?? "-"}
                                </Label>
                            </TableRow>
                            <TableRow>
                                <Description>{t("table.label.comment")}</Description>
                                <Label>{record.comment ?? "-"}</Label>
                            </TableRow>
                        </>
                    )}
                    {projectItemDraft && (
                        <>
                            <TableRow>
                                <Description>{t("table.label.updatedAt.variant")}</Description>
                                <Label>{formatDateTime(projectItemDraft.updatedAt)}</Label>
                            </TableRow>
                            <TableRow>
                                <Description>{t("table.label.status")}</Description>
                                <Label style={{ whiteSpace: "pre-line" }}>
                                    {getDraftStatusWithName(projectItemDraft)}
                                </Label>
                            </TableRow>
                            <TableRow>
                                <Description>{t("table.label.releaseRequestedBy")}</Description>
                                <Label>{getFullName(projectItemDraft.requestor)}</Label>
                            </TableRow>
                            <TableRow>
                                <Description>{t("table.label.uploadedBy")}</Description>
                                <Label>{getFullName(projectItemDraft.requestor)}</Label>
                            </TableRow>
                        </>
                    )}
                    {dueDate && (
                        <TableRow>
                            <Description>
                                {projectItemDraft ? t("table.label.dueDateForDraft") : t("table.label.dueDate")}
                            </Description>
                            <Label>{formatDate(dueDate)}</Label>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </div>
    );
});

// TPAPORTAL-1878: Cannot use document.uploader because that is only the first uploader of a
// document. If a doc is uploaded multiple times via webhook, we have to use the
// record/report uploader instead.
const DocumentTicketContent = ({
    document,
    uploadedAt,
    uploader,
    dueDate,
    projectItemDraft,
    record,
    onDownloadDocument,
}: {
    document?: Partial<IDocument>;
    uploadedAt?: Date;
    uploader?: User;
    dueDate?: Date;
    projectItemDraft?: Draft;
    record?: BasicRecordWithIDs;
    onDownloadDocument: () => void;
}) => {
    const isMobile = React.useContext(MobileContext);

    const thumbnails = document?.thumbnailUrls ? (
        <>
            <Thumbnails document={document} />
            <div style={{ width: "100%", minWidth: PREVIEW_MIN_WIDTH, marginTop: DOWNLOAD_MARGIN }}>
                <DocumentDownloadLine
                    fileName={document.name}
                    documentId={document.id}
                    onDownload={onDownloadDocument}
                />
            </div>
        </>
    ) : null;

    const description = document ? (
        <DocumentMetaData
            showDownload={!thumbnails}
            uploadedAt={uploadedAt}
            uploader={uploader}
            dueDate={dueDate}
            document={document}
            projectItemDraft={projectItemDraft}
            record={record}
            onDownloadDocument={onDownloadDocument}
        />
    ) : null;

    return (
        <div
            style={{
                maxWidth: 400,
                marginRight: isMobile ? undefined : 32,
                minHeight: "100%",
                padding: isMobile ? "16px" : `32px ${CONTENT_PADDING_HORIZONTAL}px`,
                display: "flex",
                flexDirection: "column",
                justifyContent: thumbnails ? "center" : undefined,
                alignItems: thumbnails ? "center" : undefined,
            }}
        >
            {thumbnails}
            {description}
        </div>
    );
};

const PreRegistrationTicketContent = ({
    preRegistration,
    companyId,
    subsidiaryId,
}: {
    preRegistration: IEmployee | DetailedEmployeePreRegistration;
    companyId?: string;
    subsidiaryId?: string;
}) => {
    const isMobile = React.useContext(MobileContext);

    const handleToOverview = async () => {
        let ok = true;
        if (companyId && subsidiaryId) {
            await coordinator.selectCompanyById(companyId);
            ok = await hrStore.selectCurrentPeriod(subsidiaryId);
        }

        if (ok) {
            pushRoute(
                withParamsAndQuery(
                    HrRoutes.EMPLOYEES.DETAILS,
                    { employeeId: preRegistration.id },
                    { isPreRegistration: true },
                ),
            );
        }
    };

    return (
        <div style={{ width: isMobile ? "100vw" : undefined, display: "flex", flexDirection: "column" }}>
            <HrEmployeePersonalDataOverview employee={preRegistration} noBackground />
            <Button
                style={{ marginTop: 4, marginRight: 16, marginBottom: 16, alignSelf: "flex-end" }}
                color="primary"
                data-id="to_overview"
                onClick={handleToOverview}
            >
                {t("hr.tickets.preRegistration.toOverview")}
            </Button>
        </div>
    );
};

export const TicketSiteContent = observer(function TicketSiteContent({
    companyId,
    subsidiaryId,
    onRelease,
    onCloseTicket,
    onCreateTicket,
    onTicketLoaded,
    onDownloadDocument,
    ticketId,
    ticketDetails,
    dummyMessage,
    draft,
    ...props
}: {
    companyId?: string;
    subsidiaryId?: string;
    document?: Partial<IDocument>;
    preRegistration?: IEmployee | DetailedEmployeePreRegistration;
    record?: BasicRecordWithIDs;
    onRelease?: () => void;
    onCloseTicket?: () => void;
    onCreateTicket?: (message: string) => Promise<string | undefined>;
    onTicketLoaded?: (ticketName: string) => void; // ticketName can be document or preRegistration name
    onDownloadDocument?: () => void;
    ticketId?: string;
    ticketDetails?: TicketDetails;
    uploadedAt?: Date;
    uploader?: User;
    dummyMessage?: Message;
    draft?: Draft;
}) {
    const isMobile = React.useContext(MobileContext);

    const closeTicket = useCloseTicket(companyId, onCloseTicket);

    const [assignOpen, setAssignOpen] = React.useState(false);

    const ticket = useTicketDetails({ companyId, ticketId, ticketDetails });

    // TPAPORTAL-2124: document from ticket takes precedence over props.document
    const document =
        ticket.details?.projectItemDraft?.document ??
        props.document ??
        ticket.details?.record?.document ??
        ticket.details?.report?.document;

    if (document?.name && onTicketLoaded) {
        onTicketLoaded(document.name);
    }

    const preRegistration = props.preRegistration ?? ticket.details?.preRegistration;

    if (preRegistration && onTicketLoaded) {
        onTicketLoaded(getFullName(preRegistration));
    }

    const messages = useTicketMessages({ companyId, ticketId, dummyMessage });
    const handleSend = async (_message?: string, file?: File) => {
        let message = _message;

        if (!companyId) {
            return false;
        }

        // Nothing to send
        if (!message && !file) {
            return false;
        }

        let reloadMessages = false;

        // copy prop to local variable because we might have to create the ticket and
        // send the message afterwards if the first message is an upload
        let localTicketId = ticketId;
        if (!localTicketId) {
            if (onCreateTicket) {
                // ticket creation does not allow file uploads, so if we have a
                // file then we first create the ticket with empty message and
                // below send the file including its message.
                const creatMsg = file ? "" : (message ?? "");
                localTicketId = await onCreateTicket(creatMsg);

                // Ticket creation failed -> get out
                if (!localTicketId) {
                    return false;
                }

                reloadMessages = true;

                if (!file) {
                    // No file -> we are done
                    message = undefined;
                }
            }
        }

        // We have a ticket and a message or file (after a potential create)
        if (localTicketId && (message || file)) {
            try {
                await API.postTicketMessage(companyId, localTicketId, {
                    text: message,
                    file,
                });
                reloadMessages = true;

                if (ticket.details?.status === "closed") {
                    // Posting a message reopens the ticket -> reload
                    await ticket.reload();
                }
            } catch (error) {
                const apiError = getApiError(error);
                handleChatMessageError(apiError);
                return false;
            }
        }

        if (reloadMessages) {
            await messages.reload();
        }

        return true;
    };

    const handleAssignTicket = () => {
        setAssignOpen(true);
    };

    const getCloseTicketHandler = () => {
        if (ticket.details && ticket.details.status === "open") {
            const canCloseTicket = authStore.canCloseTicket(ticket.details);
            if (canCloseTicket) {
                return async () => {
                    await closeTicket.close([ticketId]);
                    await ticket.reload();
                };
            }
        }
    };

    const handleDownloadDocument = async () => {
        if (!companyId) {
            return;
        }

        if (ticket.details) {
            const record = ticket.details.record;
            const report = ticket.details.report;
            const projectItemDraft = ticket.details.projectItemDraft;
            // Check for periodId, recordTypeId and subsidiaryId is not necessary because they are required
            // but codegen generates them as optional
            if (record?.periodId && record.subsidiaryId && record.recordTypeId) {
                try {
                    await API.putDownloadRecords({
                        companyId,
                        module: record.moduleType,
                        periodId: record.periodId,
                        subsidiaryId: record.subsidiaryId,
                        recordTypeId: record.recordTypeId,
                        recordIds: [record.id],
                    });
                } catch (error) {
                    generalStore.setError(t("error.download"), error);
                }
            } else if (report) {
                try {
                    await API.putDownloadReport({
                        companyId,
                        module: report.moduleType,
                        periodId: report.periodId,
                        subsidiaryId: report.subsidiaryId,
                        reportIds: [report.id],
                    });
                } catch (error) {
                    const apiError = getApiError(error);
                    if (
                        apiError?.statusCode === HttpStatusCode.Locked_423 &&
                        apiError.response.type === "DOCUMENT_LOCKED_TRANSFER_IN_PROGRESS"
                    ) {
                        generalStore.setError(t("error.documentInProgress"), error);
                    } else {
                        generalStore.setError(t("error.download"), error);
                    }
                }
            } else if (projectItemDraft) {
                try {
                    await API.putDownloadProjectDraft(companyId, projectItemDraft.projectID, [projectItemDraft.id]);
                } catch (error) {
                    generalStore.setError(t("error.download"), error);
                }
            }
        }
    };

    const handleMessageAttachmentView = (message: Message) => {
        if (!companyId || !ticketId) {
            return;
        }

        const files = [
            {
                id: message.id,
                name: message.attachment?.name ?? "",
                src: () => {
                    return API.getTicketAttachmentDownloadUrl({
                        companyId,
                        ticketId,
                        messageIds: [message.id],
                    });
                },
                download: () => {
                    return API.putDownloadTicketAttachment({
                        companyId,
                        ticketId,
                        messageIds: [message.id],
                    });
                },
            },
        ];
        viewerStore.open(files, message.id);
    };
    const handleMessageAttachmentDownload = async (message: Message) => {
        if (!companyId || !ticketId) {
            return;
        }

        try {
            await API.putDownloadTicketAttachment({
                companyId,
                ticketId,
                messageIds: [message.id],
            });
        } catch (error) {
            generalStore.setError(t("error.download"));
        }
    };

    const uploader =
        props.uploader ??
        ticket.details?.record?.uploader ??
        ticket.details?.report?.uploader ??
        ticket.details?.projectItemDraft?.document?.uploader;

    const uploadedAt =
        props.uploadedAt ??
        ticket.details?.record?.uploadedAt ??
        ticket.details?.report?.uploadedAt ??
        ticket.details?.projectItemDraft?.document?.uploadedAt;

    const projectItemDraft = draft ?? ticket.details?.projectItemDraft;
    let dueDate = projectItemDraft?.dueDate;
    if (!dueDate && ticket.details?.dueDate) {
        dueDate = ticket.details.dueDate;
    }

    return (
        <CenteredContent ticketBackground={!isMobile}>
            {(!!document || !!preRegistration) && (
                <SplitContainer
                    style={{ alignItems: isMobile ? "center" : undefined }}
                    splitPercentage={45}
                    leftChildren={
                        <div
                            style={{
                                overflow: "auto",
                                height: isMobile ? undefined : `calc(100vh - var(--navBarHeight))`,
                            }}
                        >
                            {preRegistration ? (
                                <PreRegistrationTicketContent
                                    preRegistration={preRegistration}
                                    companyId={companyId}
                                    subsidiaryId={subsidiaryId}
                                />
                            ) : (
                                <DocumentTicketContent
                                    document={document}
                                    uploader={uploader}
                                    uploadedAt={uploadedAt}
                                    dueDate={dueDate ? new Date(dueDate) : undefined}
                                    record={ticket.details?.record ?? props.record}
                                    projectItemDraft={projectItemDraft}
                                    onDownloadDocument={onDownloadDocument ?? handleDownloadDocument}
                                />
                            )}
                        </div>
                    }
                >
                    <Chat
                        onRelease={onRelease}
                        onCloseTicket={getCloseTicketHandler()}
                        onAssignTicket={handleAssignTicket}
                        onSend={handleSend}
                        disableUpload={!ticketId}
                        messages={messages.messages}
                        loadOlder={messages.loadOlder}
                        mobileFullScreen={isMobile}
                        onMessageAttachmentView={companyId && ticketId ? handleMessageAttachmentView : undefined}
                        onMessageAttachmentDownload={
                            companyId && ticketId ? handleMessageAttachmentDownload : undefined
                        }
                    />
                    {closeTicket.successDialog.dialog}
                    <AssignTicketDialog
                        open={assignOpen}
                        onClose={() => {
                            setAssignOpen(false);
                        }}
                    />
                </SplitContainer>
            )}
        </CenteredContent>
    );
});
