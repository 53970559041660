import { IconButton, TableBody } from "@material-ui/core";
import compact from "lodash/compact";
import intersection from "lodash/intersection";
import isEmpty from "lodash/isEmpty";
import { observer } from "mobx-react";
import * as React from "react";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { EmployeeDocumentType, EmployeesDocument } from "../../../network/APITypes";
import { companiesStore } from "../../../stores/CompaniesStore";
import { generalStore } from "../../../stores/GeneralStore";
import { useTableStore } from "../../../stores/TableStore";
import { ViewerFile, viewerStore } from "../../../stores/ViewerStore";
import { formatDate } from "../../../util/date";
import { getFullName } from "../../../util/user";
import { useTableFilters } from "../../hooks/useTableFilters";
import { BulkDownloadButton } from "../../ui/BulkActionButton";
import { CenteredContent } from "../../ui/CenteredContent";
import { ContextMenu, ContextMenuItem, useContextMenu } from "../../ui/ContextMenu";
import { EmptyState } from "../../ui/EmptyState";
import { NavBarBack } from "../../ui/NavBarBack";
import {
    OptionalTooltip,
    TableLabel,
    TpaTable,
    TpaTableCell,
    TpaTableCellMinWidth,
    TpaTableCellMinWidthNoWrap,
    TpaTableCheckbox,
    TpaTableContainer,
    TpaTableRow,
} from "../../ui/Primitives";
import { SiteContent } from "../../ui/SiteContent";
import { ITableHeaderConfig, TableHeader } from "../../ui/TableHeader";
import { TableSearchBarWithAction } from "../../ui/TableSearchBar";
import { ITableFilterCategory, ITableFilters } from "../../ui/filter/types";
import { FileIcon } from "../../util/FileIcon";
import { Icon } from "../../util/Icon";
import { MobileContext } from "../../util/MobileContext";
import { hrStore } from "../HrStore";
import { HrRoutes } from "../router/HrRoutes";

export const HrEmployeesAllDocumentsSite = observer(function HrEmployeesAllDocumentsSite() {
    const isMobile = React.useContext(MobileContext);

    const companyId = companiesStore.selectedCompanyId;
    const period = hrStore.selectedPeriod;
    const subsidiaryId = hrStore.selectedSubsidiaryId;

    const tableStore = useTableStore<EmployeesDocument & { employeeName?: unknown; updatedAt?: unknown }>(
        "HrEmployeesAllDocumentsSite",
        {
            orderBy: "employeeName",
            orderDir: "asc",
        },
    );

    const [types, setTypes] = React.useState<EmployeeDocumentType[]>();

    const { tableParams } = tableStore;
    const loadDocuments = React.useCallback(async () => {
        if (!companyId || !subsidiaryId) {
            tableStore.items = [];
            tableStore.resetOffset();
            return;
        }

        try {
            generalStore.isLoading = true;

            const response = await API.getEmployeesDocuments(companyId, subsidiaryId, {
                ...tableParams,
                startDate: period?.periodStart,
                endDate: period?.periodEnd,
                types,
            });

            if (response.documents) {
                tableStore.items = response.documents;
                tableStore.totalCount = response.total;
            }
        } catch (err) {
            generalStore.setError(t("error.loadEmployeeDocuments"), err);
        } finally {
            generalStore.isLoading = false;
        }
    }, [companyId, period?.periodEnd, period?.periodStart, subsidiaryId, tableParams, tableStore, types]);

    React.useEffect(() => {
        loadDocuments();
    }, [loadDocuments]);

    const filterItems: ITableFilterCategory[] = [
        {
            category: "types",
            entries: compact([
                companiesStore.selectedCompany?.sendPayroll && {
                    name: "payslip",
                    label: t("screen.hr.employee.documents.payslips"),
                },
                { name: "contractOfEmployment", label: t("screen.hr.employee.documents.contracts") },
                {
                    name: "certificateOfRegistration",
                    label: t("screen.hr.employee.documents.certificatesOfRegistration"),
                },
                {
                    name: "certificateOfCitizenship",
                    label: t("screen.hr.employee.documents.certificateOfCitizenship"),
                },
                { name: "identityCard", label: t("screen.hr.employee.documents.identityCard") },
                // { name: "employmentPermit", label: t("screen.hr.employee.documents.employmentPermit") },
                { name: "deregisterDocument", label: t("screen.hr.employee.documents.deregisterDocument") },
                // { name: "annualPayslips", label: t("screen.hr.employee.documents.annualPayslips"), disabled: true },
                { name: "other", label: t("screen.hr.employee.documents.generalDocuments") },
            ]),
        },
    ];

    const filters = useTableFilters({
        filters: filterItems,
        onChangeFilters: (selected: ITableFilters) => {
            tableStore.resetOffset();

            const typeFilters =
                filterItems.find(filter => filter.category === "types")?.entries.map(entry => entry.name) ?? [];
            setTypes(intersection(typeFilters, Object.keys(selected)) as EmployeeDocumentType[]);
        },
    });

    const handleView = (document: EmployeesDocument) => {
        const files = tableStore.items.map<ViewerFile>(doc => ({
            id: doc.id,
            name: doc.document.document.name,
            src: () => {
                return API.getEmployeesDocumentDownloadUrl(companyId ?? "", subsidiaryId ?? "", doc.id);
            },
            download: () => {
                return API.putDownloadEmployeesDocuments(companyId ?? "", subsidiaryId ?? "", [doc.id]);
            },
        }));
        viewerStore.open(files, document.id);
    };

    const downloadEmployeeDocuments = async (documentIds: string[]) => {
        if (!companyId || !subsidiaryId || documentIds.length === 0) {
            return;
        }

        try {
            generalStore.isLoading = true;
            await API.putDownloadEmployeesDocuments(companyId, subsidiaryId, documentIds);
        } catch (error) {
            generalStore.setError(t("error.download"), error);
        } finally {
            generalStore.isLoading = false;
        }
    };

    const contextMenu = useContextMenu<EmployeesDocument>();
    let contextMenuItems: ContextMenuItem[] = [];
    if (contextMenu.contextElement) {
        const document = contextMenu.contextElement;
        contextMenuItems = [
            {
                title: t("menu.download"),
                icon: "download",
                onClick: () => {
                    contextMenu.close();
                    downloadEmployeeDocuments([document.id]);
                },
                disabled: tableStore.selectedItems.size > 0,
                "data-id": "context_menu_download",
            },
        ];
    }

    const headerFields: ITableHeaderConfig[] = [
        { column: "updatedAt", label: "table.label.date" },
        { column: "documentType", label: "table.label.document" },
        { column: "documentName", label: "table.label.documentName" },
        { column: "employeeName", label: "table.label.employee" },
        { column: "uploader", label: "table.label.uploadedBy" },
        { column: "needsRelease", label: "table.label.needsRelease", style: { textAlign: "center" } },
        { column: "contextMenu" },
    ];

    const tableBody = (
        <TableBody>
            {tableStore.items.length > 0 &&
                tableStore.items.map((employeesDocument: EmployeesDocument, index) => {
                    const { employee, document } = employeesDocument;
                    return (
                        <TpaTableRow
                            key={document.id}
                            onClick={() => {
                                handleView(employeesDocument);
                            }}
                            style={{ cursor: "pointer" }}
                        >
                            {!isMobile && (
                                <TpaTableCheckbox
                                    onChange={(event, checked) => {
                                        tableStore.toggleSelection(employeesDocument);
                                    }}
                                    color="primary"
                                    checked={tableStore.isSelected(employeesDocument)}
                                    data-id={`checkboxSelect${document.id}`}
                                />
                            )}
                            {headerFields.map(hf => {
                                if (hf.column === "updatedAt") {
                                    return (
                                        <TpaTableCellMinWidthNoWrap key={hf.column}>
                                            {formatDate(document.document.uploadedAt, "L")}
                                        </TpaTableCellMinWidthNoWrap>
                                    );
                                } else if (hf.column === "documentType") {
                                    return (
                                        <TpaTableCellMinWidthNoWrap key={hf.column}>
                                            <FileIcon name={document.document.name} />
                                        </TpaTableCellMinWidthNoWrap>
                                    );
                                } else if (hf.column === "documentName") {
                                    return (
                                        <TpaTableCell key={hf.column}>
                                            <TableLabel style={{ maxWidth: 250 }}>{document.document.name}</TableLabel>
                                        </TpaTableCell>
                                    );
                                } else if (hf.column === "employeeName") {
                                    return (
                                        <TpaTableCellMinWidthNoWrap key={hf.column}>
                                            {getFullName(employeesDocument.employee)}
                                        </TpaTableCellMinWidthNoWrap>
                                    );
                                } else if (hf.column === "uploader") {
                                    const label = document.uploader?.isTpaEmployee
                                        ? t("common.tpaFirstName")
                                        : getFullName(document.uploader);
                                    return (
                                        <TpaTableCell key={hf.column}>
                                            <TableLabel>{label}</TableLabel>
                                        </TpaTableCell>
                                    );
                                } else if (hf.column === "needsRelease") {
                                    if (!document.needsRelease) {
                                        return <TpaTableCellMinWidthNoWrap key={hf.column} style={hf.style} />;
                                    }

                                    // already released looks the same for all users
                                    if (document.releasedAt) {
                                        return (
                                            <TpaTableCellMinWidth key={hf.column} style={hf.style}>
                                                <OptionalTooltip
                                                    title={t("screen.hr.documents.needsRelease.released.tooltip", {
                                                        releasedAt: formatDate(document.releasedAt),
                                                        name: getFullName(employee),
                                                    })}
                                                >
                                                    <span>{t("screen.hr.documents.needsRelease.released")}</span>
                                                </OptionalTooltip>
                                            </TpaTableCellMinWidth>
                                        );
                                    }

                                    // any other user (e.g. TPA or super admins)
                                    return (
                                        <TpaTableCellMinWidth key={hf.column} style={hf.style}>
                                            {t("screen.hr.documents.needsRelease.notReleased")}
                                        </TpaTableCellMinWidth>
                                    );
                                } else if (hf.column === "contextMenu") {
                                    return (
                                        <TpaTableCell key={hf.column} style={{ textAlign: "right", width: 1 }}>
                                            <IconButton
                                                data-id={`context_menu_${index}`}
                                                style={{ padding: 0 }}
                                                onClick={event => {
                                                    contextMenu.open(event, employeesDocument);
                                                }}
                                            >
                                                <Icon name="more" />
                                            </IconButton>
                                        </TpaTableCell>
                                    );
                                }

                                return <TpaTableCell key={hf.column} />;
                            })}
                        </TpaTableRow>
                    );
                })}
        </TableBody>
    );

    return (
        <>
            <NavBarBack
                backTarget={HrRoutes.RECORDS.ROOT}
                backLabel={t("screen.hr.navbar.heading")}
                title={t("screen.hr.employees.documents.title")}
                moduleInfo="hr"
                moduleInfoReadOnly={false}
                showCancel={false}
            />
            <CenteredContent>
                {tableStore.getIsEmptyState(generalStore.isLoading, !isEmpty(filters.activeFilters)) ? (
                    <EmptyState title={t("screen.hr.documents.emptystate.title")} data-id="empty_state" />
                ) : (
                    <>
                        <SiteContent>
                            <TableSearchBarWithAction
                                label="search.caption.numDocuments"
                                labelSelected="search.caption.numSelected"
                                placeholder="search.placeholder.searchForFiles"
                                select={tableStore}
                                search={tableStore.search}
                                totalCount={tableStore.totalCount}
                                onChangeSearch={tableStore.handleSearchChange}
                                bulkAction={
                                    <BulkDownloadButton
                                        onClick={() =>
                                            downloadEmployeeDocuments(
                                                tableStore.getAllSelectedItems().map(doc => doc.id),
                                            )
                                        }
                                    />
                                }
                                tableFilters={filters}
                            />
                            <TpaTableContainer>
                                <TpaTable>
                                    <TableHeader
                                        headerFields={headerFields}
                                        tableStore={tableStore}
                                        select={tableStore}
                                    />
                                    {tableBody}
                                </TpaTable>
                            </TpaTableContainer>

                            <tableStore.Pagination />
                            <ContextMenu
                                data-id="context_menu"
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                config={contextMenu}
                                items={contextMenuItems}
                            />
                        </SiteContent>
                        {tableStore.getIsNoResultState(generalStore.isLoading, !isEmpty(filters.activeFilters)) && (
                            <EmptyState title={t("table.noResults.title")} message={t("table.noResults.message")} />
                        )}
                    </>
                )}
            </CenteredContent>
        </>
    );
});
