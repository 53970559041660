import { Tooltip } from "@material-ui/core";
import { Decimal } from "decimal.js";
import { observer } from "mobx-react";
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { POPUP_WINDOW_FEATURES } from "../../../config";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { TaxAccount, TaxAccountTax, TaxAccountTransaction } from "../../../network/APITypes";
import { companiesStore } from "../../../stores/CompaniesStore";
import { generalStore } from "../../../stores/GeneralStore";
import { TableStore, useInMemoryTableStore, useTableStore } from "../../../stores/TableStore";
import { formatDate, getMinMaxDate } from "../../../util/date";
import { replaceTextWithElements } from "../../../util/helpers";
import { Comparer } from "../../../util/sort";
import { Routes } from "../../app/router/Routes";
import { useExternalWindow } from "../../hooks/useExternalWindow";
import { useTableFilters } from "../../hooks/useTableFilters";
import { Currency, Pill, PillVariant } from "../../results/ResultsValue";
import { CenteredContent } from "../../ui/CenteredContent";
import { GridTable, GridTableColumn, RenderCell, RenderExpanded, createExpandColumn } from "../../ui/GridTable";
import { NavBarBack } from "../../ui/NavBarBack";
import { FormattedCurrency, TableLabel } from "../../ui/Primitives";
import { SiteContent } from "../../ui/SiteContent";
import { TableSearchBarButton, TableSearchBarWithAction } from "../../ui/TableSearchBar";
import {
    DATE_RANGE_FILTER_TYPE,
    DROPDOWN_FILTER_TYPE,
    DateRange,
    ITableFilterOption,
    ITableFilters,
} from "../../ui/filter/types";
import { MobileContext } from "../../util/MobileContext";
import { theme } from "../../util/Theme";
import { LastCompletedTaxAccountPayment } from "../LastCompletedTaxAccountPayment";

const Context = createContext({
    reload: () => {
        return;
    },
});

export const KpisTaxAccountTransactionsSite = observer(function KpisTaxAccountTransactionsSite() {
    const isMobile = useContext(MobileContext);
    const kpiStore = companiesStore.selectedCompanyStore?.kpiStore;
    const taxAccount = kpiStore?.taxAccount;

    useEffect(() => {
        kpiStore?.loadTaxAccount();
    }, [kpiStore]);

    if (!taxAccount) {
        return null; // a loading indicator is displayed by the `generalStore`
    }

    const value = (
        <FormattedCurrency key="value" value={taxAccount.value} minimumFractionDigits={0} maximumFractionDigits={0} />
    );

    const title = isMobile ? (
        <>
            {replaceTextWithElements(t("taxAccount.transactions.title"), { "[value]": "" })} <br />
            {value}{" "}
            {taxAccount.value >= 0
                ? t("screen.cockpit.kpis.taxAccount.kpi.credit")
                : t("screen.cockpit.kpis.taxAccount.kpi.arrears")}
        </>
    ) : (
        <>
            {replaceTextWithElements(t("taxAccount.transactions.title"), { "[value]": value })}{" "}
            {taxAccount.value >= 0
                ? t("screen.cockpit.kpis.taxAccount.kpi.credit")
                : t("screen.cockpit.kpis.taxAccount.kpi.arrears")}
        </>
    );
    const date = t("screen.cockpit.kpis.taxAccount.kpi.label", { date: formatDate(new Date()) });

    return (
        <Context.Provider
            value={{
                reload: () => {
                    kpiStore.loadTaxAccount(true);
                },
            }}
        >
            <>
                <NavBarBack
                    title={title}
                    controlComponent={date}
                    backLabel={t("sidebar.list.cockpit")}
                    backTarget={Routes.COCKPIT}
                    showCancel={false}
                />
                <KpisTaxAccountTransactions taxAccount={taxAccount} />
            </>
        </Context.Provider>
    );
});

type TransactionWithId = Omit<TaxAccountTransaction, "liability" | "positions"> & {
    id: string;
    liability: Decimal;
    positions?: TransactionWithId[];
};

const withId = (transaction: TaxAccountTransaction, index: number): TransactionWithId => {
    return {
        ...transaction,
        id: `${index}`,
        liability: new Decimal(transaction.liability),
        positions: transaction.positions?.map(withId),
    };
};

const KpisTaxAccountTransactions = ({ taxAccount }: { taxAccount: TaxAccount }) => {
    const transactions = useMemo(() => {
        return taxAccount.transactions?.map<TransactionWithId>(withId) ?? [];
    }, [taxAccount.transactions]);

    return (
        <CenteredContent>
            <SiteContent>
                <TransactionsTable taxAccount={taxAccount} transactions={transactions ?? []} />
            </SiteContent>
        </CenteredContent>
    );
};

const canExpand = (transaction: TransactionWithId) => {
    return transaction.positions ? transaction.positions.length > 0 : false;
};
const renderCell: RenderCell<TransactionWithId> = (transaction, column) => {
    switch (column.column) {
        case "date":
            return formatDate(transaction.date);
        case "period":
            return periodToString(transaction.period);
        case "tax":
            if (transaction.tax?.abbreviation) {
                return (
                    <Tooltip title={transaction.tax.name}>
                        <TaxPill tax={transaction.tax} />
                    </Tooltip>
                );
            }
            return null;
        case "text":
            return (
                <TableLabel style={{ maxWidth: undefined }}>
                    {transaction.text ?? transaction.tax?.name ?? ""}
                </TableLabel>
            );
        case "liability":
            return <Currency value={transaction.liability} />;
        case "due":
            return formatDate(transaction.due);
        default:
            return null;
    }
};
const renderExpanded: RenderExpanded<TransactionWithId> = transaction => {
    return <ExpandedTransactionsTable transactions={transaction.positions ?? []} />;
};

const baseColumns: GridTableColumn<TransactionWithId>[] = [
    { column: "date", label: "taxAccount.transactions.table.label.date", gridTemplateColumn: "100px" },
    {
        column: "period",
        label: "taxAccount.transactions.table.label.taxPeriod",
        gridTemplateColumn: "min-content",
        sort: false,
    },
    { column: "tax", gridTemplateColumn: "50px" },
    { column: "text", label: "taxAccount.transactions.table.label.text", gridTemplateColumn: "auto" },
    {
        column: "liability",
        label: "taxAccount.transactions.table.label.amount",
        gridTemplateColumn: "min-content",
        align: "right",
    },
    {
        column: "due",
        label: "taxAccount.transactions.table.label.due",
        gridTemplateColumn: "100px",
    },
];
const columns: GridTableColumn<TransactionWithId>[] = [
    ...baseColumns,
    createExpandColumn<TransactionWithId>({ gridTemplateColumn: "56px" }),
];
const expandedColumns: GridTableColumn<TransactionWithId>[] = [
    ...baseColumns,
    {
        // create an empty column to fill the space of the expand column to align the columns with the parent table
        column: "expandPlaceholder" as never,
        gridTemplateColumn: "40px", // 56 (expand column above) - 16 (padding of expanded grid table)
    },
];

const searchFn = (transaction: TransactionWithId, search: string) => {
    return (
        !!transaction.text?.toLocaleLowerCase().includes(search) ||
        !!transaction.tax?.abbreviation.toLocaleLowerCase().includes(search) ||
        !!transaction.tax?.name.toLocaleLowerCase().includes(search) ||
        false
    );
};
const sortComparer: Comparer<TransactionWithId> = {
    text: (a, b) => {
        const textA = a.text ?? a.tax?.name ?? "";
        const textB = b.text ?? b.tax?.name ?? "";
        return textA.localeCompare(textB);
    },
};

const TransactionsTable = observer(function TransactionsTable({
    taxAccount,
    transactions,
}: {
    taxAccount: TaxAccount;
    transactions: TransactionWithId[];
}) {
    const companyStore = companiesStore.selectedCompanyStore;

    const tableStore = useTableStore<TransactionWithId>("TransactionsTable", {
        orderBy: "date",
        orderDir: "asc",
    });
    if (!tableStore.canExpand) {
        tableStore.canExpand = canExpand;
    }

    const handleExternalWindowClosed = () => {
        reload();
    };

    const { filterFn, tableFilters } = useFilters(transactions);
    const { reload: reload } = useContext(Context);
    const externalWindow = useExternalWindow(handleExternalWindowClosed);

    useInMemoryTableStore({
        tableStore,
        items: transactions,
        filterFn,
        searchFn,
        sortComparer,
    });

    const handlePay = async () => {
        try {
            generalStore.isLoading = true;
            const { url } = await API.postTaxAccountPayment(companyStore?.id ?? "");
            externalWindow.open(url, "TPA", POPUP_WINDOW_FEATURES);
        } catch (error) {
            generalStore.setError(t("error.payTaxAccount"), error);
        } finally {
            generalStore.isLoading = false;
        }
    };

    return (
        <>
            <TableSearchBarWithAction
                label="taxAccount.transactions.table.search.count"
                placeholder="taxAccount.transactions.table.search.placeholder"
                search={tableStore.search}
                totalCount={tableStore.items.length}
                onChangeSearch={tableStore.handleSearchChange}
                tableFilters={tableFilters}
                action={
                    <>
                        <TableSearchBarButton
                            onClick={handlePay}
                            disabled={
                                !!externalWindow.isOpen ||
                                !companyStore?.kpiStore.canPayTaxAccount ||
                                taxAccount.value >= 0
                            }
                        >
                            {t("taxAccount.pay.button")}
                        </TableSearchBarButton>
                        {taxAccount.lastCompletedPayment ? (
                            <LastCompletedTaxAccountPayment lastCompletedPayment={taxAccount.lastCompletedPayment} />
                        ) : null}
                    </>
                }
            />
            <GridTable
                columns={columns}
                tableStore={tableStore}
                renderCell={renderCell}
                renderExpanded={renderExpanded}
            />
            {externalWindow.components}
        </>
    );
});

const ExpandedGridTable: typeof GridTable<TransactionWithId> = styled(GridTable)`
    background: ${theme.palette.background.default};
    padding: 8px 16px;
`;

const ExpandedTransactionsTable = observer(function ExpandedTransactionsTable({
    transactions,
}: {
    transactions: TransactionWithId[];
}) {
    const tableStoreRef = useRef<TableStore<TransactionWithId>>();
    if (!tableStoreRef.current) {
        tableStoreRef.current = new TableStore<TransactionWithId>(
            {
                orderBy: "date",
                orderDir: "asc",
            },
            "ExpandedTransactionsTable",
        );
    }
    const tableStore = tableStoreRef.current;

    useInMemoryTableStore({
        tableStore,
        items: transactions,
        sortComparer,
    });

    return <ExpandedGridTable columns={expandedColumns} tableStore={tableStore} renderCell={renderCell} />;
});

function useFilters(transactions: TransactionWithId[]) {
    const [tax, setTax] = useState<string | null>(null);
    const [dateRange, setDateRange] = useState<DateRange | null>(null);

    const taxOptions = useMemo(() => {
        const options = new Map<string, ITableFilterOption>();
        const walk = (transaction: TransactionWithId) => {
            const { tax } = transaction;
            if (tax && !options.has(tax.abbreviation)) {
                options.set(tax.abbreviation, {
                    label: (
                        <>
                            {tax.name}
                            <TaxPill tax={tax} style={{ marginLeft: 8 }} />
                        </>
                    ),
                    value: tax.abbreviation,
                });
            }
            transaction.positions?.forEach(walk);
        };
        transactions.forEach(walk);
        return Array.from(options.values());
    }, [transactions]);

    const { min: minDate, max: maxDate } = useMemo(() => {
        return getMinMaxDate(
            transactions.map(transaction => (transaction.date ? new Date(transaction.date) : undefined)),
        );
    }, [transactions]);

    const tableFilters = useTableFilters({
        filters: [
            {
                category: "tax",
                entries: [
                    {
                        type: DROPDOWN_FILTER_TYPE,
                        name: "tax",
                        label: t("taxAccount.transactions.table.filters.tax"),
                        options: taxOptions,
                        onChange: tax => {
                            setTax(tax);
                        },
                    },
                ],
            },
            {
                category: "dateRange",
                entries: [
                    {
                        type: DATE_RANGE_FILTER_TYPE,
                        name: "dateRange",
                        label: t("taxAccount.transactions.table.filters.date"),
                        onChange: range => {
                            setDateRange(range);
                        },
                        minDate: minDate,
                        maxDate: maxDate,
                    },
                ],
            },
        ],
        onChangeFilters: (selected: ITableFilters) => {
            if (!selected.tax) {
                setTax(null);
            }
            if (!selected.dateRange) {
                setDateRange(null);
            }
        },
    });

    const filterFn = useCallback(
        (transaction: TransactionWithId) => {
            if (transaction.positions?.some(filterFn)) {
                return true; // one of the children matched, so show the parent too
            }

            if (tax !== null && transaction.tax?.abbreviation !== tax) {
                return false;
            }

            if (dateRange) {
                const date = transaction.date && new Date(transaction.date);
                if (!date || (dateRange.from && dateRange.from > date)) {
                    return false;
                }
                if (!date || (dateRange.to && dateRange.to < date)) {
                    return false;
                }
            }

            return true;
        },
        [dateRange, tax],
    );

    return { filterFn, tableFilters };
}

function periodToString(period: TaxAccountTransaction["period"]): string | null {
    if (!period) {
        return null;
    }
    const from = new Date(period.from);
    const to = new Date(period.to);

    const year = from.getFullYear();
    const month = from.getMonth() + 1;

    switch (period.type) {
        case "Year":
            return year.toString();
        case "Quarter":
            return `${year}/Q${Math.floor((month - 1) / 3) + 1}`;
        case "Month":
            return `${year}/${month}`;
        case "Custom": {
            const toYear = to.getFullYear();
            const toMonth = to.getMonth() + 1;
            if (toYear === year) {
                return `${year}/${month}-${toMonth}`;
            }
            return `${year}/${month}-${toYear}/${toMonth}`;
        }
        default:
            return null;
    }
}

const taxAbbrToPillVariant: Record<string, PillVariant> = {
    DB: "blue",
    DZ: "brown",
    K: "cyan",
    L: "green",
    U: "magenta",
    ZI: "orange",
};

const TaxPill = React.forwardRef<HTMLDivElement, { tax: TaxAccountTax; style?: React.CSSProperties }>(function TaxPill(
    { tax, style, ...rest },
    ref,
) {
    const variant = taxAbbrToPillVariant[tax.abbreviation] ?? "grey";
    return (
        <Pill variant={variant} style={style} ref={ref} {...rest}>
            {tax.abbreviation}
        </Pill>
    );
});
