import { Button } from "@material-ui/core";
import * as React from "react";
import { useParams } from "react-router";
import { t } from "../../../i18n/util";
import { API } from "../../../network/API";
import { authStore } from "../../../stores/AuthStore";
import { companiesStore } from "../../../stores/CompaniesStore";
import { viewerStore } from "../../../stores/ViewerStore";
import { IEmployeeRouteParams } from "../../../types/models";
import { employeeStatusToList, employeeStatusToListUrl } from "../../../util/helpers";
import { getFullName } from "../../../util/user";
import { goBack, pushRoute } from "../../app/router/history";
import { useEmployee } from "../../hooks/useEmployee";
import { useEmployeeDocuments } from "../../hooks/useEmployeeDocuments";
import { useQueryParams } from "../../hooks/useQueryParams";
import { CenteredContent } from "../../ui/CenteredContent";
import { NavBarBack } from "../../ui/NavBarBack";
import { SiteContent } from "../../ui/SiteContent";
import { HrEmployeeAdditionalDataOverview } from "../HrEmployeeAdditionalDataOverview";
import { HrEmployeeOverviewFileBar } from "../HrEmployeeOverviewFileBar";
import { HrEmployeeOverviewHeader } from "../HrEmployeeOverviewHeader";
import { HrEmployeeOverviewPastEmployments } from "../HrEmployeeOverviewPastEmployments";
import { HrEmployeePersonalDataOverview } from "../HrEmployeePersonalDataOverview";
import { HrEmployeeStatus } from "../HrEmployeeStatus";
import { hrStore } from "../HrStore";
import { HrRoutes } from "../router/HrRoutes";
import { IRegistrationQuery } from "../types";

export const HrEmployeeDetailsSite = () => {
    const { employeeId } = useParams<IEmployeeRouteParams>();
    const query = useQueryParams<{ isPreRegistration: boolean }>();
    const isPreRegistration = !!query.isPreRegistration;

    const { employee, loadFailed } = useEmployee(employeeId, isPreRegistration);
    if (employee) {
        if (!employee.dateOfLeaving) {
            employee.dateOfLeaving = null;
        }
    }

    const masterDataEmployeeDocuments = useEmployeeDocuments(
        { category: "masterData", orderBy: "updatedAt", orderDir: "desc" },
        isPreRegistration,
        employeeId,
    );
    const ongoingEmployeeDocuments = useEmployeeDocuments(
        { category: "ongoing", orderBy: "updatedAt", orderDir: "desc" },
        isPreRegistration,
        employeeId,
    );

    // Cannot edit already transferred preRegistrations
    const canEdit =
        authStore.canEditEmployees(hrStore.selectedSubsidiaryId) &&
        employee?.status !== "inRegistration" &&
        employee?.status !== "inDeregistration" &&
        employee?.status !== "inDataChange";

    const companyId = companiesStore.selectedCompanyId;
    const hasPreRegistration = companiesStore.selectedCompany?.preRegistrationEnabled;

    React.useEffect(() => {
        if (!employeeId || loadFailed) {
            goBack(HrRoutes.EMPLOYEES.LIST);
        }
    }, [employeeId, loadFailed]);

    const onDeregisterEmployee = () => {
        if (employee && employeeId) {
            pushRoute(HrRoutes.EMPLOYEES.DEREGISTRATION_DATA, { params: { employeeId }, query: { isPreRegistration } });
        }
    };

    const onReregisterEmployee = () => {
        if (employee) {
            pushRoute(HrRoutes.EMPLOYEES.REGISTRATION_PERSONAL_DATA, {
                query: { employeeId: employee.id, isReRegistration: true, isPreRegistration },
            });
        }
    };

    const onEditEmployee = (route: string) => {
        if (employee) {
            const params = { employeeId: employee.id };
            const query: IRegistrationQuery = { isEdit: true, isPreRegistration };
            if (route === HrRoutes.EMPLOYEES.REGISTRATION_DOCUMENTS) {
                query.isDocumentUpload = true;
            }

            if (route === HrRoutes.EMPLOYEES.REGISTRATION_PERSONAL_DATA) {
                pushRoute(route, { query: { ...params, ...query } });
            } else {
                pushRoute(route, { params, query });
            }
        }
    };

    if (!employee) {
        return null;
    }

    let actionButton = null;
    if (hasPreRegistration && employeeStatusToListUrl(employee.status) === HrRoutes.EMPLOYEES.CURRENT) {
        actionButton = (
            <Button
                onClick={onDeregisterEmployee}
                variant="contained"
                color="primary"
                data-id="button_deregister"
                disabled={!canEdit}
            >
                {t("screen.hr.employee.details.deregisterEmployee")}
            </Button>
        );
    }

    if (hasPreRegistration && employeeStatusToListUrl(employee.status) === HrRoutes.EMPLOYEES.FORMER) {
        actionButton = (
            <Button
                onClick={onReregisterEmployee}
                variant="contained"
                color="primary"
                data-id="button_reregister"
                disabled={!canEdit}
            >
                {t("screen.hr.employee.details.reregisterEmployee")}
            </Button>
        );
    }

    return (
        <>
            <NavBarBack
                backTarget={employeeStatusToListUrl(employee.status)}
                backLabel={t("screen.hr.employee.details.navBack", {
                    list: employeeStatusToList(employee.status),
                })}
                title={getFullName(employee)}
                useFullWidth // Fix for TPAPORTAL-2024
            />
            <CenteredContent>
                <SiteContent style={{ padding: 32, maxWidth: 656 }}>
                    <HrEmployeeOverviewHeader employee={employee} button={actionButton} />
                    <HrEmployeeStatus employee={employee} />
                    <HrEmployeePersonalDataOverview
                        employee={employee}
                        onEdit={
                            canEdit
                                ? () => {
                                      onEditEmployee(HrRoutes.EMPLOYEES.REGISTRATION_PERSONAL_DATA);
                                  }
                                : undefined
                        }
                    />
                    <HrEmployeeAdditionalDataOverview
                        employee={employee}
                        onEdit={
                            canEdit
                                ? () => {
                                      onEditEmployee(HrRoutes.EMPLOYEES.REGISTRATION_ADDITIONAL_DATA);
                                  }
                                : undefined
                        }
                    />
                    <HrEmployeeOverviewPastEmployments employee={employee} />
                    <HrEmployeeOverviewFileBar
                        masterDocuments={masterDataEmployeeDocuments}
                        ongoingDocuments={ongoingEmployeeDocuments}
                        canEdit={canEdit}
                        onShowAll={() => {
                            if (employeeId) {
                                pushRoute(HrRoutes.EMPLOYEES.DOCUMENTS, {
                                    params: { employeeId },
                                    query: { isPreRegistration },
                                });
                            }
                        }}
                        onAddFiles={() => {
                            onEditEmployee(HrRoutes.EMPLOYEES.REGISTRATION_DOCUMENTS);
                        }}
                        onViewFile={employeeDocument => {
                            const subsidiaryId = hrStore.selectedSubsidiaryId;
                            if (!companyId || !subsidiaryId) {
                                return;
                            }
                            const downloadEmployeeId = employee?.employee_id;
                            const files = [
                                {
                                    id: employeeDocument.id,
                                    name: employeeDocument.document.name,
                                    src: () => {
                                        if (downloadEmployeeId) {
                                            return API.getEmployeeDocumentDownloadUrl(
                                                companyId,
                                                "hr",
                                                subsidiaryId,
                                                downloadEmployeeId,
                                                false,
                                                employeeDocument.id,
                                            );
                                        } else {
                                            return API.getEmployeeDocumentDownloadUrl(
                                                companyId,
                                                "hr",
                                                subsidiaryId,
                                                employeeId,
                                                isPreRegistration,
                                                employeeDocument.id,
                                            );
                                        }
                                    },
                                    download: () => {
                                        if (downloadEmployeeId) {
                                            return API.putDownloadEmployeeDocuments(
                                                companyId,
                                                "hr",
                                                subsidiaryId,
                                                downloadEmployeeId,
                                                false,
                                                [employeeDocument.id],
                                            );
                                        } else {
                                            return API.putDownloadEmployeeDocuments(
                                                companyId,
                                                "hr",
                                                subsidiaryId,
                                                employeeId,
                                                isPreRegistration,
                                                [employeeDocument.id],
                                            );
                                        }
                                    },
                                },
                            ];
                            viewerStore.open(files, employeeDocument.id);
                        }}
                        onDownloadFiles={documentIds => {
                            const downloadEmployeeId = employee.employee_id;
                            if (downloadEmployeeId) {
                                hrStore.downloadEmployeeFiles({
                                    employeeId: downloadEmployeeId,
                                    isPreRegistration: false,
                                    documentIds,
                                });
                            } else {
                                hrStore.downloadEmployeeFiles({ employeeId, isPreRegistration, documentIds });
                            }
                        }}
                    />
                </SiteContent>
            </CenteredContent>
        </>
    );
};
