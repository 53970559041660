import { Decimal } from "decimal.js";
import { useCallback, useMemo } from "react";
import { API } from "../../../network/API";
import { AccountTransaction } from "../../../network/APITypes";
import { TableStore } from "../../../stores/TableStore";
import { sort } from "../../../util/sort";
import { APIResult, useAPI } from "../../hooks/useAPI";

interface UseAccountTransactionsProps {
    companyId: string;
    financialAccountancyId: string;
    accountNr: number;
    tableStore: TableStore<EnhancedAccountTransaction>;
    isOpenOnly: boolean;
}

export interface EnhancedAccountTransaction
    extends Omit<
        AccountTransaction,
        | "amount"
        | "discount"
        | "exchangeRate"
        | "foreignCurrencyAmount"
        | "foreignCurrencyDiscount"
        | "foreignCurrencyGross"
        | "foreignCurrencyNet"
        | "foreignCurrencyOpenItemAmount"
        | "foreignCurrencyTaxAmount"
        | "gross"
        | "net"
        | "openItemAmount"
        | "taxAmount"
        | "taxPercent"
        | "transactionId"
    > {
    id: string;
    amount: Decimal;
    discount: Decimal | undefined;
    exchangeRate: Decimal | undefined;
    foreignCurrencyAmount: Decimal | undefined;
    foreignCurrencyDiscount: Decimal | undefined;
    foreignCurrencyGross: Decimal | undefined;
    foreignCurrencyNet: Decimal | undefined;
    foreignCurrencyOpenItemAmount: Decimal | undefined;
    foreignCurrencyTaxAmount: Decimal | undefined;
    gross: Decimal | undefined;
    net: Decimal | undefined;
    openItemAmount: Decimal | undefined;
    taxAmount: Decimal | undefined;
    taxPercent: Decimal | undefined;
    transactionId: string;
}

export function useAccountTransactions({
    companyId,
    financialAccountancyId,
    accountNr,
    tableStore,
    isOpenOnly,
}: UseAccountTransactionsProps) {
    const loader = useCallback(async () => {
        const response = await API.getAccountTransactions({
            companyId,
            financialAccountancyId,
            accountNr,
        });

        const toDecimal = (v: string) => new Decimal(v);
        const toDecimalOptional = (v: string | undefined) => (typeof v === "string" ? new Decimal(v) : undefined);
        return response.accountTransactions.map(
            (at, index): EnhancedAccountTransaction => ({
                ...at,
                // there is unfortunately no unique ID on an account transaction
                // (documentId is the closest, but testing revealed that there are some duplicates...)
                id: `${financialAccountancyId}/${accountNr}/${index}`,
                // convert all financial values to `Decimal`
                amount: toDecimal(at.amount),
                discount: toDecimalOptional(at.discount),
                exchangeRate: toDecimalOptional(at.exchangeRate),
                foreignCurrencyAmount: toDecimalOptional(at.foreignCurrencyAmount),
                foreignCurrencyDiscount: toDecimalOptional(at.foreignCurrencyDiscount),
                foreignCurrencyGross: toDecimalOptional(at.foreignCurrencyGross),
                foreignCurrencyNet: toDecimalOptional(at.foreignCurrencyNet),
                foreignCurrencyOpenItemAmount: toDecimalOptional(at.foreignCurrencyOpenItemAmount),
                foreignCurrencyTaxAmount: toDecimalOptional(at.foreignCurrencyTaxAmount),
                gross: toDecimalOptional(at.gross),
                net: toDecimalOptional(at.net),
                openItemAmount: toDecimalOptional(at.openItemAmount),
                taxAmount: toDecimalOptional(at.taxAmount),
                taxPercent: toDecimalOptional(at.taxPercent),
                transactionId: at.transactionId,
            }),
        );
    }, [accountNr, companyId, financialAccountancyId]);

    const result = useAPI(loader, {
        generalStoreErrorMessage: "error.loadAccountTransactions",
    });

    const allOrOpenResult = useMemo((): APIResult<EnhancedAccountTransaction[]> => {
        if (result.state !== "success") {
            return result;
        }
        if (!isOpenOnly) {
            return result;
        }
        return { state: "success", data: result.data.filter(at => at.isOpenItem) };
    }, [isOpenOnly, result]);

    const { search } = tableStore;
    const filteredResult = useMemo((): APIResult<EnhancedAccountTransaction[]> => {
        if (allOrOpenResult.state !== "success") {
            return allOrOpenResult;
        }
        return { state: "success", data: filter(allOrOpenResult.data, search) };
    }, [allOrOpenResult, search]);

    const { orderBy, orderDir } = tableStore;
    const sortedResult = useMemo((): APIResult<EnhancedAccountTransaction[]> => {
        if (filteredResult.state !== "success") {
            return filteredResult;
        }
        return { state: "success", data: sort(filteredResult.data, orderBy, orderDir) };
    }, [filteredResult, orderBy, orderDir]);

    return { result: sortedResult, loadedResult: result };
}

function filter(accountTransactions: EnhancedAccountTransaction[], search: string): EnhancedAccountTransaction[] {
    if (!search) {
        return accountTransactions.slice();
    }
    const lowerSearch = search.toLocaleLowerCase();
    return accountTransactions.filter(accountTransaction => {
        return (accountTransaction.postingText || "").toLocaleLowerCase().includes(lowerSearch);
    });
}
