import { InputAdornment, ListItemIcon, Menu, Popover, PopoverOrigin, TextField } from "@material-ui/core";
import * as React from "react";
import { IIconNames, Icon } from "../util/Icon";
import { customColors } from "../util/Theme";
import { TpaMenuItem } from "./Primitives";

export interface ContextMenuHook<T> {
    anchor: HTMLElement | null;
    open: (event: React.MouseEvent<HTMLElement>, element?: T) => void;
    close: () => void;
    isOpen: boolean;
    contextElement: T | null;
}

// Hook to simplify context menu usage
export function useContextMenu<T>(): ContextMenuHook<T> {
    const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);
    const [contextElement, setContextElement] = React.useState<null | T>(null);
    const open = (event: React.MouseEvent<HTMLElement>, element?: T) => {
        event.preventDefault();
        event.stopPropagation(); // prevent click from being propagated to parent (e.g. table row)
        setAnchor(event.currentTarget);
        setContextElement(element ?? null);
    };

    const close = () => {
        setAnchor(null);
        setContextElement(null);
    };

    return {
        anchor,
        open,
        close,
        isOpen: !!anchor,
        contextElement,
    };
}

export interface ContextMenuItem {
    "data-id"?: string;
    icon?: IIconNames | React.ReactElement;
    selected?: boolean;
    title: string;
    description?: string;
    onClick?: (config: Pick<ContextMenuHook<unknown>, "close">) => void;
    disabled?: boolean;
    borderTop?: boolean;
}

export const ContextMenuLine = (props: { item: ContextMenuItem; config: Pick<ContextMenuHook<unknown>, "close"> }) => {
    return (
        <TpaMenuItem
            key={props.item.title}
            selected={props.item.selected}
            onClick={() => props.item.onClick?.(props.config)}
            disabled={props.item.disabled}
            data-id={props.item["data-id"]}
            style={props.item.borderTop ? { borderTop: `1px solid ${customColors.greyLight}` } : undefined}
        >
            {props.item.icon && (
                <ListItemIcon>
                    {typeof props.item.icon === "string" ? <Icon name={props.item.icon} /> : props.item.icon}
                </ListItemIcon>
            )}
            <div>
                <span
                    style={{
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                    }}
                >
                    {props.item.title}
                </span>
                {props.item.description && (
                    <p className="caption" style={{ whiteSpace: "normal" }}>
                        {props.item.description}
                    </p>
                )}
            </div>
        </TpaMenuItem>
    );
};

export const SearchFieldListItem = (props: {
    "data-id": string;
    placeholder?: string;
    onChange?: (value: string) => void;
    value?: string;
}) => {
    return (
        <TextField
            style={{ width: "100%" }}
            inputProps={{ "data-id": props["data-id"] }}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <Icon name="search" style={{ color: customColors.greyDarkIcons }} />
                    </InputAdornment>
                ),
                disableUnderline: true,
            }}
            placeholder={props.placeholder}
            onChange={event => {
                if (props.onChange) {
                    props.onChange(event.target.value);
                }
            }}
            value={props.value}
        />
    );
};

export const ContextMenu = (props: {
    anchorOrigin?: PopoverOrigin;
    transformOrigin?: PopoverOrigin;
    items: ContextMenuItem[];
    footerItem?: ContextMenuItem;
    maxWidth?: number;
    maxHeight?: number;
    "data-id": string;
    searchField?: {
        "data-id": string;
        placeholder?: string;
        value?: string;
        onChange?: (value: string) => void;
    };
    config: Pick<ContextMenuHook<unknown>, "anchor" | "close">;
}) => {
    const menuOpen = !!props.config.anchor;
    const minWidth = Math.min(160, props.maxWidth ?? 160);
    const searchField = props.searchField;
    const Container = searchField ? Popover : Menu;
    return (
        <Container
            data-id={props["data-id"]}
            open={menuOpen}
            getContentAnchorEl={null}
            anchorOrigin={
                props.anchorOrigin ?? {
                    vertical: "bottom",
                    horizontal: "center",
                }
            }
            transformOrigin={
                props.transformOrigin ?? {
                    vertical: "top",
                    horizontal: "center",
                }
            }
            anchorEl={props.config.anchor}
            onClose={props.config.close}
            PaperProps={{ style: { minWidth: minWidth, maxWidth: props.maxWidth } }}
            onClick={event => {
                event.stopPropagation(); // do not leak clicks to parent
            }}
        >
            {searchField && (
                <TpaMenuItem style={{ borderBottom: `1px solid ${customColors.greyLight}` }}>
                    <SearchFieldListItem
                        value={searchField.value}
                        onChange={searchField.onChange}
                        data-id={searchField["data-id"]}
                        placeholder={searchField.placeholder}
                    />
                </TpaMenuItem>
            )}
            <div style={props.maxHeight ? { maxHeight: props.maxHeight, overflow: "auto" } : undefined}>
                {props.items.map((item, index) => {
                    return <ContextMenuLine key={`${item.title}_${index}`} item={item} config={props.config} />;
                })}
            </div>
            {props.footerItem && <ContextMenuLine item={props.footerItem} config={props.config} />}
        </Container>
    );
};
