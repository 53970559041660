import { IMessageIDS, t } from "../i18n/util";
import { Draft } from "../network/APITypes";
import { formatDate, formatTime } from "./date";
import { getFullName } from "./user";

const statusText: { [key in Draft.StatusEnum]: IMessageIDS } = {
    open: "projects.releases.status.open",
    canceled: "projects.releases.status.canceled",
    rejected: "projects.releases.status.rejected",
    released: "projects.releases.status.released",
};

const statusTextWithName: { [key in Draft.StatusEnum]: IMessageIDS } = {
    open: "projects.releases.status.openFrom",
    canceled: "projects.releases.status.canceledFrom",
    rejected: "projects.releases.status.rejectedFrom",
    released: "projects.releases.status.releasedFrom",
};

const statusTextTooltips: { [key in Draft.StatusEnum]: IMessageIDS } = {
    open: "projects.releases.status.open.tooltip",
    canceled: "projects.releases.status.canceled.tooltip",
    rejected: "projects.releases.status.rejected.tooltip",
    released: "projects.releases.status.released.tooltip",
};

export const getDetailedDraftStatusText = (draft: Draft) => {
    let releasers = draft.releasers;
    if (draft.status !== "open") {
        // Filter releasers that are open
        releasers = releasers.filter(releaser => releaser.status !== "open");
    }

    return (
        getDraftCollectiveReleaseInfo(draft) +
        releasers
            .map(releaser => {
                return t(statusTextTooltips[releaser.status], {
                    dueDate: formatDate(draft.dueDate),
                    performedAt: formatDate(releaser.actionPerformedAt),
                    name: getFullName(releaser.status === "open" ? releaser.responsible : releaser.actionPerformedBy),
                });
            })
            .join("\n")
    );
};

export const getDraftStatus = (draft: Draft) => {
    return t(statusText[draft.status]);
};

const getDraftCollectiveReleaseInfo = (draft: Draft) => {
    // Only display if more than one releaser
    if (draft.releasers.length > 1) {
        return `${
            draft.collectiveRelease
                ? t("requestReleaseProjectItemDialog.qes.collective.title")
                : t("requestReleaseProjectItemDialog.qes.single.title")
        }\n\n`;
    }

    return "";
};

export const getDraftStatusWithName = (draft: Draft) => {
    let draftStatus = getDraftStatus(draft);

    // Capitalize first letter of draft status
    draftStatus = draftStatus.charAt(0).toUpperCase() + draftStatus.slice(1);

    return `${draftStatus}:\n\n${draft.releasers
        .map(releaser =>
            t(statusTextWithName[releaser.status], {
                name: getFullName(releaser.status === "open" ? releaser.responsible : releaser.actionPerformedBy),
                date: `${formatDate(releaser.actionPerformedAt)}, ${formatTime(releaser.actionPerformedAt)}`,
            }),
        )
        .join("\n")}`;
};
