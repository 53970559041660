import { LocationState } from "history";
import { observer } from "mobx-react";
import * as React from "react";
import { useLocation } from "react-router";
import { t } from "../../i18n/util";
import { pushRoute } from "../app/router/history";
import { useForceRerenderOnMount } from "../hooks/useForceRerenderOnMount";
import { Icon } from "../util/Icon";
import { MobileContext } from "../util/MobileContext";
import { customColors } from "../util/Theme";

const ICONMARGINLEFT = 16;
const ICONRADIUS = 24;
export const MIN_WIDTH = 272;

export const StepperTitle = (props: { children: React.ReactNode; style?: React.CSSProperties }) => (
    <h3 style={{ marginLeft: ICONMARGINLEFT, ...props.style }}>{props.children}</h3>
);

const StepTracker = (props: { activeStep: number; totalSteps: number }) => (
    <p className="caption">
        {t("screen.hr.employee.registration.stepper.status", {
            activeStep: props.activeStep + 1,
            totalSteps: props.totalSteps,
        })}
    </p>
);

const StepIcon = (props: {
    activeStep: number;
    totalSteps: number;
    index: number;
    start: React.RefObject<HTMLDivElement>;
    end: React.RefObject<HTMLDivElement>;
    stepStyle: React.CSSProperties;
}) => {
    const setRef = () => {
        switch (props.index) {
            case 0:
                return props.start;
            case props.totalSteps - 1:
                return props.end;
            default:
                break;
        }
    };

    return (
        <div
            ref={setRef()}
            style={{
                width: ICONRADIUS,
                height: ICONRADIUS,
                marginLeft: ICONMARGINLEFT,
                backgroundColor: props.stepStyle.backgroundColor,
                borderRadius: "100%",
                border: props.stepStyle.border,
            }}
        >
            {props.index < props.activeStep && <Icon name="checkmark" style={{ color: customColors.white }} />}
        </div>
    );
};

const StepLabel = (props: {
    activeStep: number;
    index: number;
    stepStyle: React.CSSProperties;
    optional?: boolean;
    label: string;
}) => (
    <div
        style={{
            color: props.stepStyle.color,
            fontWeight: props.stepStyle.fontWeight,
            padding: "0px 16px",
        }}
    >
        {props.label}
        {props.optional && props.activeStep <= props.index && (
            <p
                style={{
                    fontWeight: "normal",
                    fontSize: 10,
                    color: props.stepStyle.color,
                    lineHeight: "14px",
                }}
            >
                {t("stepper.optional")}
            </p>
        )}
    </div>
);

const Step = (props: {
    activeStep: number;
    index: number;
    steps: StepType[];
    start: React.RefObject<HTMLDivElement>;
    end: React.RefObject<HTMLDivElement>;
    disabled?: boolean;
}) => {
    const showSite = (index: number) => {
        const step = props.steps[index];
        pushRoute(step.url, { state: step.urlState });
    };

    const isActive = props.index === props.activeStep;
    const canNavigate = props.index < props.activeStep && props.disabled !== true;

    let stepStyle: React.CSSProperties = {
        backgroundColor: customColors.white,
        color: customColors.placeholder,
        border: `1px solid ${customColors.greyTextfields}`,
        fontWeight: "normal",
    };

    if (isActive) {
        stepStyle = {
            backgroundColor: customColors.blueFinished,
            color: customColors.blueFinished,
            border: "none",
            fontWeight: "bold",
        };
    } else if (props.index < props.activeStep) {
        stepStyle = {
            backgroundColor: customColors.primaryColor,
            color: customColors.primaryColor,
            border: "none",
            fontWeight: "bold",
        };
    }
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                height: 48,
                background: isActive ? "rgba(60,78,153,0.1)" : undefined,
                borderRadius: 48,
                marginBottom: 14,
                cursor: canNavigate ? "pointer" : "default",
            }}
            onClick={
                canNavigate
                    ? () => {
                          showSite(props.index);
                      }
                    : undefined
            }
            role="button"
        >
            <StepIcon
                activeStep={props.activeStep}
                totalSteps={props.steps.length}
                index={props.index}
                start={props.start}
                end={props.end}
                stepStyle={stepStyle}
            />
            <StepLabel
                activeStep={props.activeStep}
                index={props.index}
                stepStyle={stepStyle}
                optional={props.steps[props.index].optional}
                label={props.steps[props.index].label}
            />
        </div>
    );
};

const StepperLine = (props: { start: React.RefObject<HTMLDivElement>; end: React.RefObject<HTMLDivElement> }) => (
    <div
        style={{
            width: 1,
            backgroundColor: customColors.greyTextfields,
            height:
                !props.start.current || !props.end.current
                    ? 0
                    : props.end.current.offsetTop - props.start.current.offsetTop,
            left: ICONRADIUS / 2 + ICONMARGINLEFT,
            top: props.start.current?.offsetTop,
            position: "absolute",
        }}
    />
);

export interface StepType {
    label: string;
    optional?: boolean;
    url: string;
    urlState?: LocationState;
}

export const Stepper = observer(
    React.forwardRef<HTMLDivElement, { steps: StepType[]; disabled?: boolean }>(function Stepper(props, ref) {
        const { pathname } = useLocation();
        const [activeStep, setActiveStep] = React.useState(1);
        const isMobile = React.useContext(MobileContext);

        const totalSteps = props.steps.length;

        React.useEffect(() => {
            const index = props.steps.findIndex(step => pathname === step.url.split("?")[0]);
            if (index >= 0) {
                setActiveStep(index);
            }
        }, [pathname, props.steps]);

        const start = React.useRef<HTMLDivElement>(null);
        const end = React.useRef<HTMLDivElement>(null);

        useForceRerenderOnMount();

        return isMobile ? (
            <StepTracker activeStep={activeStep} totalSteps={totalSteps} />
        ) : (
            <div ref={ref}>
                <StepperTitle style={{ marginBottom: 8 }}>{t("stepper.progress")}</StepperTitle>
                <div style={{ marginBottom: 8, marginLeft: ICONMARGINLEFT }}>
                    <StepTracker activeStep={activeStep} totalSteps={totalSteps} />
                </div>
                <div
                    style={{
                        position: "relative", // has to be "relative" as anchor for the line which is "absolute"
                        minWidth: MIN_WIDTH,
                    }}
                >
                    <StepperLine start={start} end={end} />

                    <div
                        style={{
                            // Has to be "relative" so that z-ordering with the line works,
                            // because position: "static" is not sorted correctly with the "absolute" line
                            position: "relative",
                        }}
                    >
                        {props.steps.map((step, index) => {
                            return (
                                <Step
                                    key={step.label}
                                    activeStep={activeStep}
                                    index={index}
                                    steps={props.steps}
                                    start={start}
                                    end={end}
                                    disabled={props.disabled}
                                    data-id={`step${index}`}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }),
);
