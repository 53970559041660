import * as React from "react";
import { t } from "../../i18n/util";
import { API } from "../../network/API";
import { ChatUserInfo, TPAEmployeePublicUserInfo } from "../../network/APITypes";
import { companiesStore } from "../../stores/CompaniesStore";
import { generalStore } from "../../stores/GeneralStore";
import { ConfirmationDialog } from "../ui/ConfirmationDialog";
import { FormattedMessage } from "../ui/FormattedMessage";

export const usePrivacyDialog = (
    onTogglePrivacy: () => void,
    selectedContact?: ChatUserInfo | TPAEmployeePublicUserInfo,
) => {
    const [showPrivacyDialog, setShowPrivacyDialog] = React.useState(false);

    const isPrivateChat =
        selectedContact && ("isConfidential" in selectedContact ? !!selectedContact?.isConfidential : false);

    const handleClosePrivacyDialog = () => {
        setShowPrivacyDialog(false);
    };

    const handleTogglePrivacy = async () => {
        const companyId = companiesStore.selectedCompanyId;
        if (!companyId || !selectedContact) {
            return;
        }

        try {
            await API.patchChat(companyId, selectedContact.id, { isConfidential: !isPrivateChat });
            onTogglePrivacy();
            handleClosePrivacyDialog();
        } catch (error) {
            generalStore.setError(t("error.general"), error);
        }
    };

    const dialog = (
        <ConfirmationDialog
            open={showPrivacyDialog}
            title={
                isPrivateChat
                    ? t("support.privateChat.dialog.markAsPublic.title")
                    : t("support.privateChat.dialog.markAsPrivate.title")
            }
            message={
                isPrivateChat ? (
                    <FormattedMessage id="support.privateChat.dialog.markAsPublic.message" />
                ) : (
                    <FormattedMessage id="support.privateChat.dialog.markAsPrivate.message" />
                )
            }
            confirmLabel={
                isPrivateChat
                    ? t("support.privateChat.dialog.markAsPublic.confirm")
                    : t("support.privateChat.dialog.markAsPrivate.confirm")
            }
            onCancel={handleClosePrivacyDialog}
            onConfirm={handleTogglePrivacy}
        />
    );

    return {
        open: () => {
            setShowPrivacyDialog(true);
        },
        dialog,
        isPrivateChat,
    };
};
