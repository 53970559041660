const slovene = {
  "accounting.bankAccount.account.label": "",
  "accounting.bankAccount.account.placeholder": "",
  "accounting.bankAccount.account.title": "",
  "accounting.bankAccount.transactions.assignInvoices.account.error": "",
  "accounting.bankAccount.transactions.assignInvoices.account.placeholder": "",
  "accounting.bankAccount.transactions.assignInvoices.filters.bankAccountTransactions.dateRange.required": "",
  "accounting.bankAccount.transactions.assignInvoices.filters.bankAccountTransactions.title": "",
  "accounting.bankAccount.transactions.assignInvoices.filters.records.recordTypes.title": "",
  "accounting.bankAccount.transactions.assignInvoices.filters.records.title": "",
  "accounting.bankAccount.transactions.assignInvoices.records.empty": "",
  "accounting.bankAccount.transactions.assignInvoices.records.pagination.next": "",
  "accounting.bankAccount.transactions.assignInvoices.records.pagination.previous": "",
  "accounting.bankAccount.transactions.assignInvoices.subtitle": "",
  "accounting.bankAccount.transactions.assignInvoices.title": "",
  "accounting.bankAccount.transactions.table.action.assignOpenInvoices": "",
  "accounting.bankAccount.transactions.table.action.downloadBankStatement": "",
  "accounting.bankAccount.transactions.table.action.downloadBuerf": "",
  "accounting.bankAccount.transactions.table.emptyState.title": "",
  "accounting.bankAccount.transactions.table.label.amount": "",
  "accounting.bankAccount.transactions.table.label.assignInvoice": "",
  "accounting.bankAccount.transactions.table.label.date": "",
  "accounting.bankAccount.transactions.table.label.invoice": "",
  "accounting.bankAccount.transactions.table.label.isPrivate": "",
  "accounting.bankAccount.transactions.table.label.recipient": "",
  "accounting.bankAccount.transactions.table.label.text": "",
  "accounting.bankAccount.transactions.table.label.valueDate": "",
  "accounting.bankAccount.transactions.table.menu.deleteAssignedInvoice": "",
  "accounting.bankAccount.transactions.table.menu.markAsPrivate": "",
  "accounting.bankAccount.transactions.table.menu.removePrivate": "",
  "accounting.bankAccount.transactions.table.search.count": "",
  "accounting.bankAccount.transactions.table.search.placeholder": "",
  "accounting.bankAccount.transactions.table.uploadInvoice.progress.title": "",
  "accounting.bankAccount.transactions.table.uploadInvoice.subsidiaryAndRecordType.title": "",
  "accounting.bankAccount.transactions.title": "",
  "accounting.bankConnection.delete.message": "Ali res želite izbrisati bančni račun {naziv}?",
  "accounting.bankConnection.delete.title": "Brisanje bančnih podatkov",
  "accounting.bankConnections.accountHolder": "Lastnik:",
  "accounting.bankConnections.accountType": "Vrsta računa:",
  "accounting.bankConnections.accountType.Bausparen": "",
  "accounting.bankConnections.accountType.Checking": "",
  "accounting.bankConnections.accountType.CreditCard": "",
  "accounting.bankConnections.accountType.Loan": "",
  "accounting.bankConnections.accountType.Membership": "",
  "accounting.bankConnections.accountType.Savings": "",
  "accounting.bankConnections.accountType.Security": "",
  "accounting.bankConnections.addBankConnection": "Dodajanje bančnih podatkov",
  "accounting.bankConnections.balance": "Stanje na računu:",
  "accounting.bankConnections.emptyState.description": "Dodajte nov bančni račun.",
  "accounting.bankConnections.emptyState.title": "Brez bančnih podatkov",
  "accounting.bankConnections.expired": "Pretekla veljavnost",
  "accounting.bankConnections.expiredAt": "Potekel na {datum}",
  "accounting.bankConnections.expiresAt": "Velja do {data}",
  "accounting.bankConnections.iban": "IBAN:",
  "accounting.bankConnections.lastSuccessfulUpdate": "",
  "accounting.bankConnections.pending": "Izjemen",
  "accounting.bankConnections.refreshBankConnection": "Obnovitev bančnega računa",
  "accounting.bankConnections.refreshPending": "Tekoča obnovitev",
  "accounting.bankConnections.refreshPending.info": "Prenova bančnih podatkov se izvede v ločenem oknu. Če je bilo okno zaprto, preden je bila posodobitev končana, morate ponovno klikniti na \"Obnovi bančne podatke\".",
  "accounting.bankConnections.refreshPending.label": "Postopek obnove v teku",
  "accounting.bankConnections.upToDate": "Potrjeno",
  "accounting.details.edit.header": "Urejanje potrdila o prejemu",
  "accounting.details.updatedAt": "zadnjič spremenjeno dne",
  "accounting.details.upload.files": "Datoteke",
  "accounting.details.upload.skip": "Preskoči vse",
  "accounting.finished.reports": "Poročila",
  "accounting.table.label.totalGrossAmount": "",
  "addFolderDialog.button.text.create": "Ustvarite spletno stran",
  "addFolderDialog.folderName": "Ime mape",
  "addFolderDialog.info": "Če želite upravljati člane, pojdite v Sharepoint.",
  "addFolderDialog.title": "Ustvari mapo",
  "advisor.uploads.uploadReports.title": "Posredovanje poročil",
  "analysis.powerBI.reports.table.label.description": "",
  "analysis.powerBI.reports.table.label.name": "",
  "analysis.powerBI.reports.table.search.count": "",
  "analysis.powerBI.reports.table.search.placeholder": "",
  "analysis.powerBI.reports.title": "",
  "analysis.powerBI.report.title": "",
  "appNotificationDialog.button.accept": "",
  "appNotificationDialog.title.maintenance": "",
  "appNotificationDialog.title.newFeature": "",
  "appNotificationDialog.title.update": "",
  "branchType.allocationGroup": "Območje naselja",
  "branchType.costCenter": "Glavni vodja stroškov",
  "branchType.department": "Oddelek",
  "branchType.financialAccountancyCircle": "krog FIBU",
  "branchType.other": "Drugo",
  "branchType.wageCompany": "Podjetje za plače",
  "branchType.workplace": "Delovno mesto",
  "button.activateMfa": "Aktivirajte zdaj",
  "button.answer": "ODGOVOR",
  "button.archive": "Arhiv",
  "button.assign": "Dodelitev",
  "button.back_to_login": "Nazaj na prijavo",
  "button.closeTicket": "Označi kot opravljeno",
  "button.configure": "KONFIGURIRANJE",
  "button.continueInBackground": "",
  "button.continue_without_microsoft": "Nadaljevanje brez Microsoftovega računa",
  "button.copy": "Kopiranje/premikanje",
  "button.deactivateMfa": "Deaktivirati zdaj",
  "button.enter": "VSTOPITE",
  "button.markAsPrivate": "Označi kot zaupno",
  "button.moreInfo": "VEČ INFORMACIJ",
  "button.move": "Preseli se sem",
  "button.nextStep": "naslednji korak",
  "button.paste": "Kopiraj tukaj",
  "button.refresh": "Obnovite",
  "button.release": "Delite",
  "button.submitArchiveMultiple": "{count} Arhivska sporočila",
  "button.submitArchiveSingle": "{count} Arhivsko sporočilo",
  "button.toOverview": "Za pregled",
  "button.transfer": "Prenos",
  "button.unlock": "Odklepanje spletne strani",
  "button.upload": "Naloži datoteko",
  "captcha.invalid": "",
  "captcha.placeholderText": "",
  "chatInput.deleteLink": "Izbriši",
  "chatInput.editLink": "Uredi",
  "chatInput.enterLink": "Povezava",
  "chatInput.openLink": "Odprti URL",
  "chatInput.saveLink": "Shrani",
  "common.abacus": "Abakus",
  "common.accounting": "Računovodstvo",
  "common.accounting.period": "Obdobje rezervacije",
  "common.accounting.recordType": "",
  "common.accounting.subsidiary": "Koda podjetja",
  "common.add": "Dodaj",
  "common.albanian": "Shqiptare",
  "common.all": "Vse",
  "common.amount": "Številka",
  "common.anyDateRange": "Katero koli obdobje",
  "common.apply": "Prevzemite nadzor",
  "common.archive": "Arhiv",
  "common.back": "Nazaj",
  "common.bankConnection": "",
  "common.bankConnections": "Bančni podatki",
  "common.bankContact": "Bančni podatki",
  "common.bic": "BIC",
  "common.bmd": "BMD",
  "common.bulgarian": "Български",
  "common.cancel": "Prekliči",
  "common.cancelCaps": "ODPOVEDI",
  "common.chat": "Klepet",
  "common.clientNumber": "Številka stranke",
  "common.cockpit": "Kokpit",
  "common.companies": "Podjetje",
  "common.company": "Podjetje",
  "common.companyNumber": "Številka podjetja",
  "common.companyOverview": "Pregled podjetja",
  "common.companyRegisterNumber": "Registrska številka podjetja",
  "common.continue": "Dodatno",
  "common.country": "Država",
  "common.croatian": "Hrvatski",
  "common.czech": "Česky",
  "common.dateformat": "DD.MM.LLLLL",
  "common.dateValidation": "Vnesite veljavni datum",
  "common.defaultSignature": "Vaš TPA",
  "common.delete": "Izbriši",
  "common.done": "Pripravljen",
  "common.download": "Prenesi",
  "common.downloadAll": "",
  "common.dueDate": "Rok",
  "common.dueUntil": " najpozneje do {dueDate}",
  "common.emailAddress": "E-poštni naslov",
  "common.english": "English",
  "common.errorOccurred": "Zgodila se je napaka",
  "common.events": "Dogodki",
  "common.files": "Datoteke",
  "common.finished": "Zaključeno",
  "common.folder": "Mapa",
  "common.from": "S spletne strani",
  "common.fullName": "{prvo ime} {lastno ime}",
  "common.generalData": "Splošni podatki",
  "common.generalDocuments": "Splošne datoteke",
  "common.german": "Deutsch",
  "common.global": "V celotnem podjetju",
  "common.hr": "Obračun plač",
  "common.hr.period": "Obračunsko obdobje",
  "common.hrPreRegistrations": "Osebje - predhodni vnosi",
  "common.hr.short": "Osebje",
  "common.hr.subsidiary": "Območje naselja",
  "common.hungarian": "Magyar",
  "common.invite": "Povabilo",
  "common.kpi": "Dejstva in številke",
  "common.kpi.long": "Dejstva in številke",
  "common.later": "Pozneje",
  "common.logout": "Odjava",
  "common.me": "I",
  "common.members": "Člani",
  "common.moreInformation": "več informacij",
  "common.mr": "gospod {imeno}",
  "common.mrs": "gospa {ime}",
  "common.name": "Ime",
  "common.no": "Ne",
  "common.none": "ni",
  "common.noSearchResults": "Ni bilo najdenih rezultatov",
  "common.notAvailable": "NI RELEVANTNO",
  "common.notSpecified": "n.a.",
  "common.numberValidation": "Vnesite veljavno številko",
  "common.ok": "Ok",
  "common.optional": "(neobvezno)",
  "common.orderBy": "",
  "common.partial": "delno",
  "common.people": "Ljudje",
  "common.polish": "Polski",
  "common.profile": "Profil",
  "common.project": "Projekt",
  "common.projects": "Projekti",
  "common.readingTimeMinutes": "Čas branja: {time} Min",
  "common.readingTimeMinutes.short": "{čas} Min",
  "common.recipient": "Sprejemnik",
  "common.refresh": "Osvežitev",
  "common.releasedAt": "",
  "common.releasedAtBy": "izdano dne {datum} s strani {imena}",
  "common.releaseRequired.plural": "",
  "common.releaseRequired.singular": "",
  "common.remainingItems": "+{count} več",
  "common.remainingItemsUnknown": "",
  "common.retry": "Poskusite znova",
  "common.romanian": "Românesc",
  "common.russian": "Русский",
  "common.save": "Shrani",
  "common.sendCaps": "POŠLJI",
  "common.serbian": "Српски",
  "common.settings": "Nastavitve",
  "common.showAll": "Pokaži vse",
  "common.slovak": "Slovenská",
  "common.slovene": "Slovenski",
  "common.taskTitle": "Naslov",
  "common.taxNumber": "Davčna številka",
  "common.termsOfUse": "Pogoji uporabe",
  "common.ticket": "Posvetovanje",
  "common.timePeriod": "Obdobje",
  "common.timePeriod.olderThan1Month": "Starejši od enega meseca",
  "common.timePeriod.olderThan1Week": "Starejši od enega tedna",
  "common.timePeriod.olderThan1Year": "Starejši od enega leta",
  "common.timePeriod.olderThan6Months": "Starejši od 6 mesecev",
  "common.to": "Do",
  "common.tpaFirstName": "TPA",
  "common.tpaLastName": "Zaposleni",
  "common.unknown": "neznano",
  "common.upcomingTermsOfUse": "",
  "common.upload": "",
  "common.uploadFiles": "Prenesite datoteke",
  "common.user": "",
  "common.vatNumber": "UID",
  "common.yes": "Da",
  "commuterAllowance.IndividuellePendlerpauschale": "",
  "commuterAllowance.KeinePendlerpauschale": "",
  "commuterAllowance.MehrAls20kmKeinBus": "",
  "commuterAllowance.MehrAls40kmBus": "",
  "commuterAllowance.MehrAls40kmKeinBus": "",
  "commuterAllowance.MehrAls60kmBus": "",
  "commuterAllowance.MehrAls60kmKeinBus": "",
  "commuterAllowance.Mindestens20kmBus": "",
  "commuterAllowance.Mindestens2kmKeinBus": "",
  "companiesUserReleaseInfoActionsBar.message": "Imate {število} novih uporabnikov, ki jih želite deliti",
  "companiesUserReleaseInfoActionsBar.submit": "Oglejte si in delite",
  "companiesUserRelease.permissions.navbar.back": "Skupna raba in povabilo uporabnikom / dodelitev vloge",
  "companiesUserRelease.title": "Skupna raba in povabilo uporabnikom",
  "companyImages.cover.error": "Slike z naslovnice ni bilo mogoče naložiti",
  "companyImages.cover.title": "Slika na naslovnici",
  "companyImages.delete.message": "Ali res želite izbrisati sliko?",
  "companyImages.delete.title": "Izbriši sliko",
  "companyImages.logo.error": "Logotipa podjetja ni bilo mogoče naložiti",
  "companyImages.logo.title": "Logotip podjetja",
  "companyImages.title": "Slika na naslovnici in logotip vašega podjetja (neobvezno)",
  "companyList.remainingCompanies": "+{ ostala podjetja} Več",
  "company.optionalSideBarMenuItems.maxLength.name": "Ime lahko vsebuje le 15 znakov",
  "company.optionalSideBarMenuItems.nameAlreadyinUse": "Ime za izbirni element menija že obstaja",
  "company.optionalSideBarMenuItems.name.label": "Ime",
  "company.optionalSideBarMenuItems.required.name": "Navesti je treba ime",
  "company.optionalSideBarMenuItems.required.url": "URL je treba navesti",
  "company.optionalSideBarMenuItems.title": "Neobvezni elementi menija podjetja",
  "company.optionalSideBarMenuItems.tooltip.removeItem": "Odstranitev elementa menija",
  "company.optionalSideBarMenuItems.urlAlreadyinUse": "Povezava za izbirni element menija že obstaja",
  "company.optionalSideBarMenuItems.url.label": "URL",
  "company.optionalSideBarMenuItems.urlNotValid": "Vnesite veljavni naslov URL (primer: https://www.tpa-connect.app )",
  "companyOverview.emptyState.message": "Obrnite se na svojega svetovalca.",
  "companyOverview.emptyState.title": "Ni aktivnega podjetja",
  "companyOverview.emptyState.tpa.message": "Trenutno nimate aktivnih strank",
  "companyOverview.emptyState.tpa.title": "Ni aktivnih strank",
  "companyOverview.unlockCountry.tooltip": "Odklepanje novih držav{br}{br} Odklepanje mednarodnih projektov iz <b>{država}</b> za uporabo funkcije projektov tega podjetja in ogled vseh poizvedb.",
  "companyStatus.accountingSetupDone": "0/2 opravljeno",
  "companyStatus.active": "aktivni",
  "companyStatus.hrSetupDone": "1/2 končano",
  "companyStatus.inactive": "neaktivni",
  "companyStatus.userSetupDone": "2/2 opravljeno",
  "config.accounting.accountingByCustomer": "Prenos računovodskih podatkov prek TPA Connect s strani strank",
  "config.accounting.delete.subsidiaries.last.message": "Šifre podjetja {name} ni mogoče izbrisati. Obstajati mora vsaj ena koda podjetja.",
  "config.accounting.delete.subsidiaries.message": "Ali res želite izbrisati kodo podjetja {naziv}?",
  "config.accounting.delete.subsidiaries.title": "Brisanje kode podjetja",
  "config.accounting.description": "Določite vrsto in obseg računovodskega področja.",
  "config.accounting.description.customer": "To področje je za vas konfiguriral vaš svetovalec TPA. Če želite kar koli spremeniti, se kadar koli obrnite na svojega svetovalca TPA.",
  "config.accounting.due.accounting.reports.select.title": "Dnevi po posredovanju obračuna",
  "config.accounting.dueDates.title": "Prenos računovodskih dokumentov",
  "config.accounting.due.reportRelease.select.subtitle": "Na primer 4 dni po tem, ko je TPA posredoval poročila. To pomeni 12. dan naslednjega meseca (če je TPA poročila poslala 8. dan naslednjega meseca).",
  "config.accounting.due.reportRelease.select.title": "Dnevi po posredovanju poročil",
  "config.accounting.due.reportRelease.select.value": "{day} Dnevi po posredovanju poročil",
  "config.accounting.due.reportRelease.title": "Objava poročil s strani strank",
  "config.accounting.due.reports.select.subtitle": "Na primer 3 dni po posredovanju obračuna. To pomeni 8. dan naslednjega meseca (če je bilo računovodstvo poslano 5. dan naslednjega meseca).",
  "config.accounting.due.reports.select.value": "{day} Dnevi po posredovanju računov",
  "config.accounting.due.reports.title": "Posredovanje poročil s strani TPA",
  "config.accounting.due.transfer.select.subtitle": "Na primer 5. dan naslednjega meseca.",
  "config.accounting.due.transfer.select.title": "Prenos do",
  "config.accounting.due.transfer.select.value": "{day} Dnevi po koncu obdobja",
  "config.accounting.due.transfer.title": "Posredovanje računovodskih podatkov s strani strank",
  "config.accounting.globalReports.description": "Stranka ima poročila za celotno podjetje",
  "config.accounting.globalReports.title": "Poročila za celotno podjetje",
  "config.accounting.hasAccounting": "Stranka ima računovodstvo",
  "config.accounting.hasBankAccountTransactionInvoices": "",
  "config.accounting.hasCockpitKpi": "Dejstva in številke v pilotski kabini",
  "config.accounting.hasKpi": "Dejstva in številke",
  "config.accounting.hasResults": "Vrednotenja",
  "config.accounting.incaseoflaw.description": "",
  "config.accounting.incaseoflaw.label": "",
  "config.accounting.incaseoflaw.title": "",
  "config.accounting.month.description": "Vključuje obdobje enega meseca",
  "config.accounting.month.title": "Mesečno računovodstvo",
  "config.accounting.quarter.description": "Trajanje 3 mesece",
  "config.accounting.quarter.title": "Računovodstvo na četrtletje",
  "config.accounting.recordTypes": "Računovodski prejemki",
  "config.accounting.recordTypes.ocrDisabled": "",
  "config.accounting.recordTypes.ocrEnabled": "",
  "config.accounting.requireTwoReportReleases": "",
  "config.accounting.subsidiaries.add": "Dodajte kodo podjetja",
  "config.accounting.subsidiaries.title": "Opredelitev oznak podjetij",
  "config.accounting.subsidiaries.title.customer": "Oznake podjetij",
  "config.accounting.subsidiary.maxReached": "Največje doseženo število kod podjetij",
  "config.accounting.subsidiary.name": "Kodno ime podjetja",
  "config.accounting.week.description": "traja 5 delovnih dni in je razdeljen na koledarske tedne.",
  "config.accounting.week.title": "Tedensko računovodstvo",
  "config.bulk.button.configure": "Konfiguracija spletne strani",
  "config.bulk.data.description": "Tu lahko naložite naslovno sliko in logotip podjetij.",
  "config.bulk.data.title": "Osnovni podatki",
  "config.bulk.finish.description": "Podjetja so v postopku konfiguracije. To lahko traja nekaj minut, zato ne zapirajte okna brskalnika.",
  "config.bulk.finish.start": "Zagon",
  "config.bulk.finish.title": "Dokončanje konfiguracije",
  "config.companies": "Konfiguracija podjetja",
  "config.companies.buttonConfig": "Konfiguracija podjetja",
  "config.companies.count": "{count} Podjetje",
  "config.companies.description": "V tem koraku določite postopke, ki so značilni za podjetje. Ti vključujejo vizualni videz v programu TPA Connect, vrsto in obseg računovodstva in obračuna plač ter roke.",
  "config.companyData.cockpit": "",
  "config.companyData.description": "Tu lahko naložite sliko naslovnice in logotip podjetja ter določite merila za rezervacijo.",
  "config.companyData.title": "Osnovni podatki",
  "config.companyOverview.inactive": "Pregled podjetja / Neaktivno",
  "config.customMenuPoints.deleteCustomMenuPoint.confirmLabel": "Da, izbrišite",
  "config.customMenuPoints.deleteCustomMenuPoint.message": "Ali res želite izbrisati ta element menija?",
  "config.customMenuPoints.deleteCustomMenuPoint.title": "Brisanje elementa menija",
  "config.customMenuPoints.noMenuPointsAvailable": "Na voljo ni elementov menija",
  "config.employees.assignRole": "Dodelitev vloge",
  "config.employees.emptyState.message": "Trenutno ni aktivnih zaposlenih",
  "config.employees.emptyState.title": "Ni aktivnih zaposlenih",
  "config.employees.table.missingEmail": "Manjka e-poštni naslov",
  "config.employees.table.missingEmail.tooltip": "Elektronski naslov mora v BMD vnesti svetovalec TPA",
  "config.features.alreadyToggledToday.disabled": "",
  "config.features.alreadyToggledToday.enabled": "",
  "config.features.confirm.message.disabled": "",
  "config.features.confirm.message.enabled": "",
  "config.features.confirm.title.disabled": "",
  "config.features.confirm.title.enabled": "",
  "config.finishConfiguration": "Dokončanje konfiguracije",
  "config.finishConfiguration.cockpit": "V pilotsko kabino",
  "config.finishConfiguration.continue": "Ustvarite drugo podjetje",
  "config.finishConfiguration.emailNotification": "Obveščanje uporabnikov po e-pošti",
  "config.finishConfiguration.message": "Podjetje {company} je bilo uspešno konfigurirano. Zdaj se lahko uporablja za izmenjavo med agencijo TPA in stranko.",
  "config.finishSetup": "IZHOD IZ NASTAVITEV",
  "config.hr.chat.description": "Zaposleni, ki prejemajo plačo, se lahko obrnejo na svetovalce TPA.",
  "config.hr.chat.title": "Klepet",
  "config.hr.delete.subsidiaries.last.message": "Računovodskega območja {name} ni mogoče izbrisati. Obstajati mora vsaj eno računovodsko področje.",
  "config.hr.delete.subsidiaries.message": "Ali res želite izbrisati računovodsko področje {naziv}?",
  "config.hr.delete.subsidiaries.title": "Brisanje področja plač",
  "config.hr.description": "Določite naravo in obseg področja obračuna plač.",
  "config.hr.dueDates.reminder": "",
  "config.hr.dueDates.title": "Roki za obračun plač",
  "config.hr.due.relativeDueDates": "Dostava dokumentov v naslednjem mesecu",
  "config.hr.due.reports.select.subtitle": "Na primer 3 dni po posredovanju plačilne liste. To pomeni 8. dan naslednjega meseca (če je bil prenos obračuna plač opravljen 5. dan naslednjega meseca).",
  "config.hr.due.reports.select.title": "Dnevi po posredovanju plačilne liste",
  "config.hr.due.reports.select.value": "{day} Dnevi po posredovanju izjave o osebju",
  "config.hr.due.select.value": "{dan}. v mesecu",
  "config.hr.due.transfer.select.subtitle": "Na primer 5. v mesecu.",
  "config.hr.due.transfer.select.value.relativeDueDates": "{day} Dnevi po koncu meseca",
  "config.hr.due.transfer.title": "Posredovanje plač s strani strank",
  "config.hr.hasHr": "Stranka ima plačilno listo",
  "config.hr.hasResults": "",
  "config.hr.recordTypes": "Dokumenti za obračun plač",
  "config.hr.releaseSteps.oneStep.description": "Vse mesečne ocene TPA posreduje stranki v enem koraku. Stranka mora do določenega roka izdati vse potrebne dokumente.",
  "config.hr.releaseSteps.oneStep.title": "v enem koraku",
  "config.hr.releaseSteps.title": "Koraki sproščanja",
  "config.hr.releaseSteps.twoSteps.description": "Prenos in objava mesečne ocene se opravita v dveh korakih. Stranka lahko sprosti poravnave pred sprostitvijo vseh drugih mesečnih ocen.",
  "config.hr.releaseSteps.twoSteps.title": "v dveh korakih",
  "config.hr.requireTwoReportReleases": "",
  "config.hr.sendPayroll.description": "elektronsko pošiljanje izpiskov o dohodkih",
  "config.hr.sendPayroll.title": "Elektronska evidenca zaslužka",
  "config.hr.sendPayroll.tooltip": "Ob izdaji plačilne liste vsak zaposleni samodejno prejme dostop do aplikacije TPA Connect, s katero lahko pregleduje svoje zapise o zaslužkih in elektronsko osebno mapo.",
  "config.hr.subsidiaries.add": "Dodajanje območja za obračun plač",
  "config.hr.subsidiaries.info": "Po izbiri prvega računovodskega področja lahko izberete samo še druga istovrstna področja (npr. stroškovno mesto). Če želite izbrati novo vrsto (npr. krog FIBU), je treba izbrisati vsa računovodska območja razen enega.",
  "config.hr.subsidiaries.title": "Določite področja plač",
  "config.hr.subsidiaries.title.customer": "Računovodska vezja",
  "config.hr.subsidiary.maxReached": "Doseženo največje število računovodskih tokokrogov",
  "config.hr.subsidiary.name": "Ime računovodskega področja",
  "config.hr.upload.description": "Zaposleni, ki prejemajo plačo, lahko v svojo osebno mapo naložijo dokumente.",
  "config.hr.upload.title": "Nalaganje dokumentov v kadrovsko datoteko",
  "config.hr.warning.noBranch": "Obračuna plač ni mogoče aktivirati. Ustvarite podjetje za obračun plač v sistemu NTCS",
  "config.moduleTypeDetails.asp.helpInstructions": "",
  "config.moduleTypeDetails.asp.helpInstructions.open": "",
  "config.moduleTypeDetails.label.database": "",
  "config.moduleTypeDetails.label.dataSource": "",
  "config.moduleTypeDetails.label.url": "",
  "config.noDueDate": "Brez roka",
  "config.optionalMenuPoints.success.title": "Uspešno shranjeni elementi menija",
  "config.overview": "Pregled konfiguracije",
  "config.permission.change": "spremenjeno v \"{dovoljenje}\".",
  "config.permissions.accounting.globalReports": "Računovodska poročila za celotno podjetje",
  "config.permissions.deleted": "(izbrisano)",
  "config.permissions.description": "Izberite področja, do katerih lahko uporabnik dostopa. Za vsako kodo podjetja lahko nastavite različne nastavitve.",
  "config.permissions.employees": "Zaposleni",
  "config.permissions.existingUser.navbar.back": "Pregled konfiguracije / Upravljanje uporabnikov / Dodelitev vloge",
  "config.permissions.existingUser.navbar.backToUser": "Pregled konfiguracije / Upravljanje uporabnikov / Dodelitev vloge / {employeeName}",
  "config.permissions.global": "V celotnem podjetju",
  "config.permissions.internationalProjects.info": "Projekti Dovoljenj ni mogoče preklicati: Člani mednarodnih projektov jih prejmejo samodejno.",
  "config.permissions.navbar.back": "Pregled konfiguracije / Upravljanje uporabnikov / Izbira kontaktne osebe / Dodelitev vloge",
  "config.permissions.navbar.backToUser": "Pregled konfiguracije / Upravljanje uporabnikov / Izbira kontaktne osebe / Dodelitev vloge / {Ime zaposlenega}",
  "config.permissions.noPermissionSelected.message": "Izberite vsaj eno pooblastilo.",
  "config.permissions.noPermissionSelected.title": "Ni izbrano nobeno dovoljenje",
  "config.permissions.title": "Prilagoditev dovoljenj",
  "config.roles.change": "Vloga je spremenjena iz {oldRole} v {newRole}",
  "config.roles.customizePermissions": "Prilagoditev dovoljenj",
  "config.roles.description": "Izberite vlogo z vnaprej določenim naborom dovoljenj. Po potrebi lahko dovoljenja prilagodite v naslednjem koraku.",
  "config.roles.multipleCompaniesDialog.message": "Uporabnik {ime} obstaja v naslednjih podjetjih. Ali želite spremeniti pooblastila v vseh podjetjih?",
  "config.roles.multipleCompaniesDialog.title": "Uporaba sprememb v več podjetjih",
  "config.roles.multipleCompaniesDialog.warning": "Podjetja ni bilo mogoče naložiti. Spremembe se uporabijo samo za trenutno podjetje.",
  "config.roles.navbar.back": "Pregled konfiguracije / Upravljanje uporabnikov / Izbira kontaktnih oseb",
  "config.roles.navbar.heading": "{employeeCount} Izbrani uporabnik",
  "config.roles.option.copy.description": "Vnesite ime uporabnika, da prevzamete njegovo vlogo in dovoljenja. V naslednjem koraku lahko po potrebi prilagodite dovoljenja.",
  "config.roles.option.copy.label": "Prevzem vloge od drugega uporabnika",
  "config.roles.option.copy.placeholder": "Uporabniško ime",
  "config.roles.releaseInformation": "Za urejanje uporabniških nastavitev <b>{imena uporabnika}</b> mora <b>superadmin</b> odobriti/zavrniti spremembe.",
  "config.roles.releaseInformation.info.message": "Če kdo (razen superadmina) spremeni uporabniške nastavitve, mora te spremembe sprostiti ali zavrniti superadmin. Do takrat so uporabnikove nastavitve zaklenjene in jih ni mogoče urejati.",
  "config.roles.releaseInformation.info.title": "Zakaj ni mogoče urejati uporabniških nastavitev?",
  "config.roles.releaseInformation.superAdmin": "{ime} je vnesel spremembe v <b>{uporabniško ime}</b>. Če želite nadaljevati, potrdite uporabnikove spremembe.",
  "config.roles.sidebar.title": "Izbrani uporabniki",
  "config.roles.submit": "Predlagajte uporabnika",
  "config.roles.submit.existingUser": "Predlagajte spremembe",
  "config.roles.submit.superAdmin": "Povabite uporabnika",
  "config.roles.title": "Dodelitev vloge",
  "config.roles.viewPermissions": "Pogled na dovoljenja",
  "config.terms.allSigneesAcceptedWarningDialog.confirmLabel": "V redu, shranite",
  "config.terms.allSigneesAcceptedWarningDialog.message": "Pozor: Ker so vse dodane osebe že podpisane, se bo podjetje aktiviralo po shranjevanju.",
  "config.terms.allSigneesAcceptedWarningDialog.title": "Shrani pogoje uporabe",
  "config.terms.alreadySigned": "Pogoji uporabe njihovega podjetja so že podpisani.",
  "config.terms.button.revokeActivation": "Preklic aktivacije",
  "config.terms.changeDocumentVersionDialog.confirmLabel": "Da, spremenite dokument",
  "config.terms.changeDocumentVersionDialog.message": "Če nadaljujete, morajo vsi že dodani e-poštni naslovi ponovno sprejeti pogoje uporabe.",
  "config.terms.changeDocumentVersionDialog.title": "Dokument o spremembi",
  "config.terms.deleteEmailDialog.confirmLabel": "Da, izbrišite e-poštni naslov",
  "config.terms.deleteEmailDialog.message": "Ali res želite izbrisati e-poštni naslov?",
  "config.terms.deleteEmailDialog.title": "Brisanje e-poštnega naslova",
  "config.terms.documentVersion": "Različica dokumenta",
  "config.terms.emailAddressesInfo": "",
  "config.terms.emailAddressesTitle": "E-poštni naslovi",
  "config.terms.emailAlreadyUsed": "Ta e-poštni naslov je bil že dodan",
  "config.terms.invalidEmail": "Nepravilen e-poštni naslov",
  "config.terms.noDocumentsAvailable": "Trenutno ni na voljo nobenih dokumentov",
  "config.terms.noSigneesAvailable": "Podpisnikov še ni",
  "config.termsOfUse.navbar.back": "Pregled konfiguracije / osnovni podatki",
  "config.terms.removeEmail": "Brisanje e-poštnega naslova",
  "config.terms.resendEmail.header": "Poslana e-pošta",
  "config.terms.resendEmail.message": "Ponovno je bilo poslano e-poštno sporočilo za sprejetje pogojev uporabe",
  "config.terms.resendMail": "Ponovno pošiljanje e-pošte",
  "config.terms.revokeActivationDialog.confirmLabel": "da, aktivacija preklicana",
  "config.terms.revokeActivationDialog.message": "Ste prepričani, da želite preklicati aktivacijo podjetja? Vse registrirane osebe morajo ponovno podpisati pogoje uporabe, da lahko ponovno aktivirajo podjetje.",
  "config.terms.revokeActivationDialog.title": "Preklic aktivacije",
  "config.terms.signedVersion": "podpisana različica:",
  "config.terms.skip.button": "preskočite",
  "config.terms.skip.dialog.message": "Uporabniki na strani odjemalca se lahko prijavijo šele po sprejetju pogojev uporabe. Do takrat je podjetje na voljo le za notranje namene TPA v aplikaciji Connect.",
  "config.terms.skip.dialog.title": "Informacije",
  "config.terms.success.header": "Uspešno shranjeno",
  "config.terms.termsOfUseInfo": "Izberite ustrezno različico dokumenta",
  "config.terms.termsOfUseTitle": "Pogoji uporabe",
  "config.users": "Upravljanje uporabnikov",
  "config.users.active.title": "Aktivni uporabniki",
  "config.users.assignRole": "Dodelitev vloge",
  "config.users.buttonConfig": "Upravljanje uporabnikov",
  "config.users.changeUsername": "Nova e-pošta",
  "config.users.changeUsername.tooltip": "Uporabnikov novi e-poštni naslov je {newUsername}. S klikom na gumb se od uporabnika zahteva potrditev novega e-poštnega naslova.",
  "config.users.description": "Posameznim procesom dodelite uporabnike ali skupine uporabnikov. Določite, kdo ima dostop do katerih področij in v kakšnem obsegu.",
  "config.users.edit": "Sprememba",
  "config.users.emptyState.message": "Trenutno ni aktivnih uporabnikov{br}Če želite ustvariti superadmina, uporabite BMD NTCS.",
  "config.users.emptyState.title": "Ni aktivnih uporabnikov",
  "config.users.inviteAgain": "Ponovno povabilo",
  "config.users.navBar.back": "Pregled konfiguracije / upravljanje uporabnikov",
  "config.users.new": "Novo",
  "config.users.newUsers": "Ustvari novega uporabnika",
  "config.users.newUsers.description": "Izberite kontaktne osebe, ki jih želite ustvariti kot uporabnike storitve TPA Connect. V naslednjem koraku lahko izbranim osebam dodelite vlogo in po potrebi prilagodite vnaprej določena dovoljenja.",
  "config.users.newUsers.title": "Izberite kontaktno osebo",
  "config.users.reject": "Upadanje",
  "config.users.release": "Delite",
  "config.users.releaseAndInvite": "Skupna raba in povabilo",
  "config.users.table.invalidEmail": "Nepravilen e-poštni naslov",
  "config.users.table.invalidEmail.tooltip": "Elektronski naslov mora popraviti svetovalec TPA v BMD",
  "config.users.waiting.title": "Čakanje na sprostitev",
  "cookies.acceptAll": "Dovolite vse piškotke",
  "cookies.acceptFunctional": "Uporabljajte samo potrebne piškotke.",
  "cookies.cookieList": "Izjava o piškotkih",
  "cookies.text": "Piškotke uporabljamo za optimizacijo naših storitev. Več podrobnosti o tem najdete na {povezavi}.",
  "country.al": "Albanija",
  "country.at": "Avstrija",
  "country.bg": "Bolgarija",
  "country.cz": "Češka republika",
  "country.hr": "Hrvaška",
  "country.hu": "Madžarska",
  "country.me": "Črna gora",
  "country.pl": "Poljska",
  "country.ro": "Romunija",
  "country.rs": "Srbija",
  "country.si": "Slovenija",
  "country.sk": "Slovaška",
  "deleteDialog.message.plural": "Ali res želite izbrisati dokumente <b>{dokumenti}</b>?{br}{br}Tega dejanja ni mogoče preklicati.",
  "deleteDialog.message.singular": "Ali res želite izbrisati dokument <b>{dokumenti}</b>?{br}{br}Tega dejanja ni mogoče preklicati.",
  "deleteDialog.title": "Brisanje dokumentov?",
  "deleteUser.message": "Ali ste prepričani, da želite izbrisati uporabnika <b>{naslov}</b>?",
  "deleteUser.multipleCompanies.confirmLabel": "Brisanje uporabnika v vseh podjetjih",
  "deleteUser.multipleCompanies.message": "Uporabnik je prisoten v naslednjih podjetjih:",
  "deleteUser.title": "Izbriši uporabnika",
  "deleteUser.warning.superadminskipped": "",
  "dialog.archive.checkbox_pdf": "Lokalno shranjevanje PDF",
  "dialog.archive.subject": "Zadeva",
  "dialog.archive.success.text": "Do arhiviranih sporočil lahko zdaj dostopate v {bmd}.",
  "dialog.archive.success.title": "Sporočila uspešno arhivirana!",
  "dialog.archive.text": "Izberite podjetje, za katero želite shraniti izbrana sporočila v BMD.",
  "dialog.archive.title_multiple": "{count} Arhivska sporočila",
  "dialog.archive.title_single": "1 Arhivsko sporočilo",
  "dialog.assignTicket.header": "Dodelitev poizvedbe",
  "dialog.assignTicket.message": "Izberite osebo, ki ji želite dodeliti posvetovanje.",
  "dialog.button.understood": "Razumljivo",
  "dialog.closeTicket.success.header": "Opravljeno posvetovanje",
  "dialog.companyConfig.success.header": "Konfiguracija podjetja je bila uspešno shranjena.",
  "dialog.companyConfig.success.message": "Območja za {imeno} so pripravljena.",
  "dialog.eldaTransfer.andNext": " in nadalje",
  "dialog.eldaTransfer.downloadLater": "Prenesi pozneje",
  "dialog.eldaTransfer.success.header": "Vloga je bila uspešno oddana",
  "dialog.loginError.multiFactorCodeWrong.title": "Vnesena koda je žal napačna.",
  "dialog.loginError.subtitle": "Poskusite znova.",
  "dialog.loginError.title": "Neznani e-poštni naslov ali napačno geslo",
  "dialog.noPhoneNumberWarning.message": "Vsaj eden od izbranih stikov nima nastavljene telefonske številke. Brez telefonske številke dvostopenjska avtentikacija ni na voljo in povabilo morda ne bo varno.",
  "dialog.noPhoneNumberWarning.title": "Telefonska številka ni nastavljena",
  "dialog.passwordExpired.message": "",
  "dialog.passwordExpired.title": "",
  "dialog.password_reset.success.header": "Geslo je bilo uspešno spremenjeno",
  "dialog.registration.success.button": "Prijavite se zdaj",
  "dialog.registration.success.header": "Uspešno ste se registrirali!",
  "dialog.registration.success.message": "Prijavite se z Microsoftom in uporabite TPA Connect.",
  "dialog.transferReports.success.message": "Vaša stranka si bo zdaj ogledala datoteke in vas kontaktirala z morebitnimi vprašanji.",
  "dialog.transfer.success.header": "Datoteke so bile uspešno prenesene",
  "dialog.userConfig.success.header": "Shranjene uporabniške nastavitve",
  "dialog.user_not_found.button": "Razumljivo",
  "dialog.user_not_found.subtitle": "Obrnite se na skrbnika sistema ali se prijavite z računom TPA Connect.",
  "dialog.user_not_found.title": "Uporabniški račun s tem e-poštnim naslovom ni znan",
  "documentUpload.error.amount": "<b>{{failedUploads}</b> datotek {uploads} ni bilo mogoče naložiti:",
  "documentUpload.error.button.text.continue": "Nadaljujte brez teh datotek",
  "documentUpload.error.button.text.retry": "Poskusite znova",
  "documentUpload.error.message": "Napaka pri nalaganju datotek",
  "documentUpload.error.rejection.amount": "<b>{{failedUploads}</b> datotek {uploads} ni mogoče naložiti:",
  "documentUpload.error.tooBigImages.amount": "<b>{failedUploads}</b> Slike so večje od max. {maxImageDimension} pikslov. Njihova velikost se samodejno zmanjša. Nadaljujte?",
  "documentUpload.error.tooBigImages.amount.singular": "Slika je večja od max. {maxImageDimension} pikslov. Velikost se samodejno zmanjša. Nadaljujte?",
  "documentUpload.error.tooBigImages.button.text.continue": "Zmanjšajte in nadaljujte",
  "documentUpload.error.tooBigImages.pdf": "Slike v dokumentu PDF so večje od največ {maxImageDimension} pikslov. Zmanjšajte velikost slik.",
  "documentUpload.error.tooManyFiles.plural": "",
  "documentUpload.error.tooManyFiles.singular": "",
  "documentUpload.max.size": "Največja velikost dokumenta {maxUploadSizeMb} Mb",
  "documentUpload.tooBigImages.message": "Prevelike slike",
  "documentUpload.type.file.formats": "Podprti formati",
  "dropzone.button.label.search": "ga poiščite.",
  "dropzone.image.text": "Preprosto povlecite sliko v to območje ali",
  "dropzone.info.redo": "Ponovno naložite",
  "dropzone.info.text": "Preprosto povlecite in spustite datoteke v to območje ali",
  "dueDate.answerAt": "odgovoriti do {data}",
  "dueDate.closedAt": "končano dne {datum}",
  "dueDate.sentAt": "poslano dne {datum}",
  "dueDate.sentAtWithTime": "poslano na {datum} ob {času}",
  "dueDate.transferredAt": "poslano na {datum}",
  "dueDate.until": "do {data}",
  "employmentInsuranceType.apprentice": "Učenec",
  "employmentInsuranceType.fullTime": "v celoti zavarovan",
  "employmentInsuranceType.independentContractor": "brezplačno DN",
  "employmentInsuranceType.marginalIndependentContractor": "Manjši brezplačni DN",
  "employmentInsuranceType.marginally": "obrobno",
  "employmentRelationship.employee": "Zaposleni",
  "employmentRelationship.worker": "Delavci",
  "error.accounting.postSubsidiary": "Kode podjetja ni bilo mogoče ustvariti",
  "error.accounting.subsidiaryDoesNotExist": "Koda podjetja ni bila najdena",
  "error.activate": "Napaka pri aktiviranju",
  "error.addBankAccountTransactionInvoices": "",
  "error.addBankConnection": "Bančnih podatkov ni bilo mogoče dodati",
  "error.addCompanyToUserFavorites": "Podjetja ni bilo mogoče dodati med vaše priljubljene",
  "error.addFolderBadRequest": "Mape ni bilo mogoče ustvariti. Prepričajte se, da niste uporabili nedovoljenih posebnih znakov: \" * : < > ? / \\ |",
  "error.archiveDisabledForCompany": "Arhiviranje pri tem podjetju ni mogoče",
  "error.archiveFailed": "Napaka med arhiviranjem",
  "error.archiveProjectFailed": "Projekta ni bilo mogoče arhivirati",
  "error.avatarUploadFailed": "Slike profila ni bilo mogoče naložiti. Prepričajte se, da je format JPEG ali PNG in da je velikost datoteke največ {maxSize}MB",
  "error.avatarUploadOnlyOneImage": "Prenesete lahko samo eno sliko",
  "error.changeUsernameEmail": "Elektronskega sporočila za spremembo uporabniškega imena ni bilo mogoče poslati",
  "error.changeUsername.newUsernameConflict": "Novo e-poštno sporočilo se ne ujema s shranjenim e-poštnim sporočilom.",
  "error.changeUsername.newUsernameDuplicate": "E-pošta je že v uporabi.",
  "error.changeUsername.noNewUsername": "Nov e-poštni naslov ni bil dodeljen.",
  "error.changeUsername.tokenExpired": "Ta povezava je potekla.",
  "error.changeUsername.tokenNotFound": "Povezava je neveljavna, ali je bil novi e-poštni naslov že potrjen?",
  "error.chatForbidden": "Pri tem podjetju vam storitev Face to Face ni omogočena.",
  "error.chatNoCompany": "Sporočilo brez konteksta podjetja.",
  "error.chatNoRecipient": "Sporočilo nima prejemnika.",
  "error.chatSearch": "Iskanje ni bilo uspešno. Poskusite znova.",
  "error.completeSign": "Elektronskega podpisa ni bilo mogoče izpolniti",
  "error.config.accounting.duplicate": "Vnesli ste več kod podjetij z istim imenom.",
  "error.config.accounting.noSubsidiary": "Če želite nadaljevati, določite vsaj eno šifro podjetja.",
  "error.config.activateCompanyFailed": "Ta stranka še ni bila tehnično pripravljena na uporabo sistema TPA Connect. Za vzpostavitev ustreznega uporabnika se obrnite na informacijsko podporo.",
  "error.config.corruptSubsidiary": "Koda podjetja brez ID",
  "error.config.hr.duplicate": "Določili ste več področij plač z enakim imenom",
  "error.config.hr.noSubsidiary": "Če želite nadaljevati, nastavite vsaj eno računovodsko področje.",
  "error.copy.alreadyExists.file": "",
  "error.copy.alreadyExists.folder": "",
  "error.copy.alreadyExists.unknown": "",
  "error.create": "Napaka med ustvarjanjem",
  "error.create.alreadyExists.project": "Projekt s tem imenom že obstaja",
  "error.createEmployee": "Zaposlenega ni bilo mogoče ustvariti",
  "error.delete": "Napaka med brisanjem",
  "error.deleteBankAccountTransactionInvoices": "",
  "error.deleteBankConnection": "Bančnih podatkov ni bilo mogoče izbrisati",
  "error.delete.fileLocked": "Datoteka se trenutno ureja, zato je ni mogoče izbrisati.",
  "error.deleteUser": "Uporabnika ni bilo mogoče izbrisati. Prosimo, poskusite znova pozneje.",
  "error.deregisterEmployee": "Zaposlenega ni bilo mogoče odjaviti",
  "error.documentForbidden": "Nimate dostopa do dokumenta",
  "error.documentInProgress": "Dokument se pošilja TPA in ga ni mogoče prenesti. Kmalu poskusite znova.",
  "error.documentNotFound": "Dokument ni najden",
  "error.documentsInProgress": "Dokumenti se pošiljajo v agencijo TPA in jih ni mogoče prenesti. Kmalu poskusite znova.",
  "error.download": "Napaka pri prenosu",
  "error.downloadTermsOfUseDocument": "Pri prenosu pogojev uporabe je prišlo do napake",
  "error.download.transferInProgress": "Dokument se prenaša. Poskusite znova malo pozneje.",
  "error.dueDate.minDate": "Datum roka ne sme biti v preteklosti.",
  "error.duplicateName": "Že dodeljeno ime",
  "error.duplicateUsername": "Novo dodeljeni e-poštni naslov je že v uporabi",
  "error.edit": "Napaka med urejanjem",
  "error.employeeWithoutEmail": "",
  "error.folderIsEmpty": "Mapa je prazna, zato je ni mogoče prenesti",
  "error.foldersAreEmpty": "Vse izbrane mape so prazne, zato jih ni mogoče prenesti",
  "error.forbidden": "Brez dovoljenja",
  "error.general": "Žal je prišlo do napake. Poskusite pozneje.",
  "error.getSign": "Pri nalaganju zahtevanih podatkov za elektronski podpis je prišlo do napake.",
  "error.hr.noSubsidiarySelected": "Ni izbrano območje obračuna plač",
  "error.hr.postSubsidiary": "Območja plač ni bilo mogoče ustvariti",
  "error.hr.subsidiaryDoesNotExist": "Območje obračuna plač ni bilo najdeno",
  "error.imageCrop": "Napake pri obdelavi slik",
  "error.imageTooBig": "Prevelika slika",
  "error.invalidCharacters": "Vnos vsebuje neveljavne znake",
  "error.invalidCompanyId": "Nepravilna identifikacijska številka podjetja",
  "error.invalid_credentials": "Napačno uporabniško ime ali geslo",
  "error.invalidDateFormat": "Nepravilna oblika datuma",
  "error.invalidEmployee": "Nepopolni ali nepravilni podatki o zaposlenem",
  "error.invalidPeriodId": "Neveljavno obdobje za to podjetje",
  "error.invalidRolePermissions": "Dovoljenja vlog so neveljavna",
  "error.invalidSsn": "Neveljavna številka nacionalnega zavarovanja",
  "error.invalidTicket": "Neveljavna vozovnica",
  "error.invalidWebhookParams": "Nepravilni parametri spletne klike.",
  "error.invitationNoCompany": "Vabilo brez ID podjetja",
  "error.linkExpired": "Ta povezava je potekla. Zahtevajte novo.",
  "error.linkInvalid": "Ta povezava je neveljavna.",
  "error.loadAccount": "Računa ni bilo mogoče naložiti",
  "error.loadAccountListing": "Seznama stanja ni bilo mogoče naložiti",
  "error.loadAccounts": "",
  "error.loadAccountTransactions": "Rezervacij ni bilo mogoče naložiti",
  "error.loadArchiveDocuments": "Ni bilo mogoče naložiti vseh arhivskih dokumentov",
  "error.loadBadges": "Napaka pri nalaganju obvestil",
  "error.loadBalanceSheet": "Balance ni bilo mogoče naložiti",
  "error.loadBankAccountTransactions": "",
  "error.loadBankConnections": "Bančnih podatkov ni bilo mogoče naložiti",
  "error.loadBranches": "Vetve ni bilo mogoče naložiti",
  "error.loadCashAccounting": "Računa prihodkov in odhodkov ni bilo mogoče naložiti",
  "error.loadCompany": "Družbe ni bilo mogoče naložiti",
  "error.loadCompanyFeatures": "",
  "error.loadContacts": "Stikov ni bilo mogoče naložiti",
  "error.loadCostCenters": "Stroškovnih centrov ni bilo mogoče naložiti",
  "error.loadCountries": "Podatkov o državi ni bilo mogoče naložiti",
  "error.loadCustomerListing": "",
  "error.loadDestinations": "Lokacij zasedenosti ni bilo mogoče naložiti. Poskusite znova pozneje",
  "error.loadDocument": "Dokumenta ni bilo mogoče naložiti",
  "error.loadDocuments": "Dokumentov ni bilo mogoče naložiti",
  "error.loadDraft": "Izdaje ni bilo mogoče naložiti",
  "error.loadEmployee": "Zaposlenega ni bilo mogoče naložiti",
  "error.loadEmployeeDocuments": "Datotek ni bilo mogoče naložiti",
  "error.loadEmployees": "Zaposlenih ni bilo mogoče povabiti",
  "error.loadEvents": "Dogodkov ni bilo mogoče naložiti, poskusite znova pozneje",
  "error.loadFeatureFlags": "",
  "error.loadFeedCountries": "Države ni bilo mogoče naložiti.",
  "error.loadFinancialAccountancies": "",
  "error.loadFormData": "Podatkov iz obrazca ni bilo mogoče naložiti",
  "error.loadFunders": "Stroškovne enote ni bilo mogoče naložiti",
  "error.loadGeneralDocumentsTimeout": "",
  "error.loading": "Napaka pri nalaganju",
  "error.loadInsuranceCarrierBalance": "",
  "error.loadInsuranceCarriers": "Napaka pri nalaganju skladov zdravstvenega zavarovanja",
  "error.loadInvitationResults": "Napaka pri nalaganju sproščenih uporabniških sprememb",
  "error.loadKpiConfigurations": "",
  "error.loadKpis": "Dejstev in številk ni bilo mogoče naložiti, poskusite znova pozneje",
  "error.loadMessages": "Sporočil ni bilo mogoče naložiti",
  "error.loadModuleTypeDetails": "",
  "error.loadNews": "Sporočil ni bilo mogoče naložiti, poskusite znova pozneje",
  "error.loadNews.noSource": "Kanal novic trenutno ni na voljo.",
  "error.loadNotifications": "Nastavitev obvestil ni bilo mogoče naložiti",
  "error.loadOptionalMenuItems": "Izbirnih elementov menija ni bilo mogoče naložiti",
  "error.loadPayrollAccount": "",
  "error.loadPayrollAccountDetails": "",
  "error.loadPendingMicrosoftActions": "Informacij o vabilih in osvežitvi ni bilo mogoče naložiti",
  "error.loadPeriod": "Napaka pri nalaganju obdobja",
  "error.loadPeriodData": "Napaka pri nalaganju podatkov o obdobju",
  "error.loadPermissions": "Dovoljenj ni bilo mogoče naložiti",
  "error.loadPersonnelFile": "Datoteke z osebjem ni bilo mogoče naložiti",
  "error.loadPersonnelFile.notStaff": "Uporabnik ne prejema plače",
  "error.loadPowerBIReportEmbedToken": "",
  "error.loadPowerBIReports": "",
  "error.loadPreRegistration": "Predpomnilnika ni bilo mogoče naložiti.",
  "error.loadProfile": "Profila ni bilo mogoče naložiti",
  "error.loadProfitAndLoss": "G&V ni bilo mogoče naložiti",
  "error.loadProjects": "Projektov ni bilo mogoče naložiti",
  "error.loadProjectsCompanies": "Podjetja za projekte ni bilo mogoče naložiti",
  "error.loadPromotions": "Elementov ni bilo mogoče naložiti, poskusite znova pozneje",
  "error.loadPublications": "Publikacij ni bilo mogoče naložiti, poskusite znova pozneje",
  "error.loadRecord": "Dokumenta ni bilo mogoče naložiti",
  "error.loadRecords": "Računov ni bilo mogoče naložiti",
  "error.loadRecordTypes": "Napaka pri nalaganju vrst rezervacij",
  "error.loadReports": "Poročil ni bilo mogoče naložiti",
  "error.loadResponsibleUsers": "Svetovalcev ni bilo mogoče naložiti",
  "error.loadRolePermissions": "Napaka pri nalaganju dovoljenj vloge",
  "error.loadSubsidiaries": "Napaka pri nalaganju kod podjetja",
  "error.loadSubsidiary": "Napaka pri nalaganju kode podjetja",
  "error.loadTeam": "Članov ekipe ni bilo mogoče naložiti",
  "error.loadTermsofUse": "Pri nalaganju pogojev uporabe je prišlo do napake",
  "error.loadTicketDetails": "Posvetovanja ni bilo mogoče naložiti",
  "error.loadTickets": "Poizvedb ni bilo mogoče naložiti",
  "error.loadUserCompanySettings": "Napaka pri nalaganju uporabniških nastavitev",
  "error.loadUsers": "Uporabnikov ni bilo mogoče naložiti",
  "error.loadVendorListing": "",
  "error.loadWaitingUsers": "Napaka pri nalaganju uporabnikov, ki jih je treba sprostiti",
  "error.loginAccountSetup": "",
  "error.logout": "Napaka pri odjavi",
  "error.markAsDone": "Kot dokončane lahko označite samo poizvedbe, ki ste jih ustvarili",
  "error.markBankAccountTransactionAsPrivate": "",
  "error.maxCharacters": "Največje dovoljeno število znakov {count}",
  "error.mfaActivation.codeNotMatched": "Vnesena koda je napačna",
  "error.mfaCodeAlreadySent": "Koda je bila že poslana, počakajte vsaj 20 sekund, preden zahtevate naslednjo kodo.",
  "error.mfaCodeWrong": "Žal je vnesena koda napačna. Prosimo, poskusite znova.",
  "error.mfaSettingChange": "2FA ni mogoče aktivirati ali deaktivirati, ker je bila za aktivacijo že zahtevana koda. Pred ponovnim poskusom počakajte vsaj 20 sekund.",
  "error.mfaSettingChange.codeAlreadySent": "Zahtevana je bila že nova koda. Pred ponovnim poskusom počakajte vsaj 20 sekund.",
  "error.mfaSettingChange.codeNotMatched": "Vnesena koda je napačna",
  "error.microsoftLogin": "Pri komunikaciji z Microsoftom je prišlo do težave. Poskusite se znova prijaviti.",
  "error.missingDocument": "Manjka dokument",
  "error.missingEmail": "Manjkajoči e-poštni naslov",
  "error.missingEmployee": "Manjkajoči zaposleni",
  "error.missingParams": "Manjkajoči parametri",
  "error.move": "Napaka pri premikanju",
  "error.move.alreadyExists.unknown": "",
  "error.msTokenExpired": "Vašemu žetonu MS je potekel rok veljavnosti. Prosimo, da ga obnovite.",
  "error.multipleArchives": "Za to podjetje je na voljo več arhivov strank",
  "error.noArchive": "Arhiv strank za to podjetje ni na voljo",
  "error.noCompanySelected": "Izbrano podjetje ni bilo izbrano",
  "error.noDocumentSelected": "Izberite dokument za pogoje uporabe",
  "error.noDotAtEnd": "Zadnji znak ne sme biti pika.",
  "error.noFinancialAccountancies": "Družba nima enot FIBU",
  "error.noMsAccountAvailable": "Uporabnika ni mogoče povabiti. Pričakuje se, da osebje TPA že ima Microsoftov račun.",
  "error.noRecordTypes": "Ni najdenih kategorij dokumentov",
  "error.noSignees": "Dodajte e-poštne naslove",
  "error.noSubsidiaries.accounting": "Ni bilo najdenih kod podjetij",
  "error.noSubsidiaries.hr": "Računovodska področja niso bila najdena",
  "error.noSubsidiaryWithEmployeeRead": "Ni kroga plačilne liste s pravicami do branja zaposlenih.",
  "error.notAdvisor": "Ni dovoljenj za svetovalce",
  "error.notAllowed": "Ukrep se ne sme izvesti",
  "error.notificationCenter.load": "",
  "error.notificationCenter.markAllAsRead": "",
  "error.notificationCenter.markAsRead": "",
  "error.oauthApi": "Pri preverjanju pristnosti je prišlo do težave. Poskusite znova.",
  "error.oauthCompany": "Izbranega podjetja ni. Izberite podjetje in poskusite znova.",
  "error.oauthUrl": "Nastala je težava pri priklicu storitve Dejstva in številke. Poskusite znova.",
  "error.overlappingSignature": "Prekrivajoči se podpis. Izberite nov položaj",
  "error.passwordReset.tokenExpired": "Ta povezava za ponastavitev gesla je potekla",
  "error.passwordReset.tokenNotFound": "Neveljavna povezava za ponastavitev gesla",
  "error.payAccountTransaction": "",
  "error.payTaxAccount": "",
  "error.payUnpaidAccount": "",
  "error.periodClosed": "Že zaključeno obdobje",
  "error.permission.corruptRoles": "Neveljavne vloge",
  "error.permission.onlyOneRoleAllowed": "Nastavi se lahko samo en zvitek.",
  "error.postTicket": "Posvetovanja ni bilo mogoče ustvariti.",
  "error.projectForbidden": "Nimate dostopa do projektov",
  "error.projectIsEmpty": "Projekt je prazen, zato ga ni mogoče prenesti",
  "error.projectPermissions": "Dovoljenj projekta ni bilo mogoče naložiti",
  "error.projects.cantMove": "Datotek ni bilo mogoče premakniti. Poskusite znova pozneje.",
  "error.projects.cantPaste": "Datoteke ni bilo mogoče vstaviti. Poskusite znova pozneje.",
  "error.projects.openBookmarkUrlFailed": "URL-ja ni bilo mogoče odpreti",
  "error.qes.missingSignaturePositions": "",
  "error.recordForbidden": "Nimate dostopa do kupona",
  "error.recordWithNotAllowedCompany": "Za podjetje, ki mu je dokument dodeljen, ni dovoljenja.",
  "error.refreshBankConnection": "Bančnega računa ni bilo mogoče obnoviti",
  "error.releaseEmployeeDocument": "Napaka pri sprostitvi",
  "error.releaseReport": "Napaka pri sprostitvi",
  "error.removeBankAccountTransactionPrivateMark": "",
  "error.removeCompanyFromUserFavorites": "Podjetja ni bilo mogoče odstraniti iz vaših priljubljenih",
  "error.rename.alreadyExists.file": "Napaka pri preimenovanju: Datoteka s tem imenom že obstaja",
  "error.rename.alreadyExists.folder": "Napaka pri preimenovanju: Mapa s tem imenom že obstaja",
  "error.requiredField": "Zahtevani vhodni podatki",
  "error.resendTermsOfUseEmail": "Pri ponovnem pošiljanju e-pošte je prišlo do napake",
  "error.save": "Napaka pri shranjevanju",
  "error.saveKpiConfigurations": "",
  "error.saveOptionalMenuItems": "Izbirnih elementov menija ni bilo mogoče shraniti. Poskusite znova pozneje",
  "error.saveTermsOfUse": "Pri shranjevanju pogojev uporabe je prišlo do napake. Prosimo, poskusite znova pozneje",
  "error.send": "Napaka pri pošiljanju.",
  "error.setDocumentOrSignees": "Pri shranjevanju dokumenta ali oseb, ki jih je treba podpisati, je prišlo do napake.",
  "error.settings": "Napaka pri spreminjanju nastavitev",
  "error.signFileTooLarge": "",
  "error.signReadOnlyDocument": "Dokument je namenjen samo branju, zato ga ni mogoče podpisati.",
  "error.signTermsOfUse": "Pri sprejemanju pogojev uporabe je prišlo do napake. Prosimo, poskusite znova pozneje",
  "error.subsidiaryWithoutRecordTypes": "{subsidiary} nima aktivnih simbolov dokumentov",
  "error.termsOfUseDuplicateFile": "Ista datoteka z drugim imenom že obstaja",
  "error.termsOfUseFolderNotFound": "Ni bila ustvarjena še nobena mapa s pogoji uporabe",
  "error.timeout": "Ta postopek trenutno traja nekoliko dlje. Poskusite pozneje.",
  "error.toggleCompanyFeature": "",
  "error.tokenRefresh": "Napaka pri preverjanju pristnosti",
  "error.tooManyCharacters": "Največ {maxCharacters} znakov, dovoljenih na sporočilo.",
  "error.transfer": "Napaka pri prenosu",
  "error.transferEmployee": "Delavca ni bilo mogoče premestiti",
  "error.transferEmployeeDirect": "Prenos v sklad za zdravstveno zavarovanje ni bil uspešen. Obrnite se na svojega svetovalca.",
  "error.transferEmployee.validation.general": "",
  "error.transferEmployee.validation.general.zipcode": "",
  "error.unknown": "Neznana napaka ({statusCode})",
  "error.unsupportedDocumentType": "Ta vrsta dokumenta ni podprta",
  "error.updateBankAccountAccountNumber": "",
  "error.upgradeToMicrosoft": "Nadgradnje ni bilo mogoče izvesti. Preverite svoje geslo.",
  "error.upload": "Napaka pri nalaganju",
  "error.userDeactivated": "Ta Microsoftov uporabnik je bil deaktiviran.",
  "error.userNotRegistered": "Uporabnik ni registriran",
  "error.userUnknown": "Neznani uporabnik",
  "externalLinkDialog.content": "",
  "externalLinkDialog.title": "",
  "failedUserDialog.amount": "<b>{{neuspešnihUporabnikov}</b> od {uporabnikov} ni bilo mogoče ustvariti/spremeniti uporabnikov:",
  "failedUserDialog.button.text.continue": "Nadaljuj kljub temu",
  "failedUserDialog.button.text.retry": "Poskusite znova",
  "failedUserDialog.error.emailAlreadyExists": "Že uporabljen e-poštni naslov",
  "failedUserDialog.error.pendingPermissionChanges": "Že čakamo na sprostitev",
  "failedUserDialog.message": "Napaka pri ustvarjanju/spreminjanju",
  "features.default.prices.from": "",
  "features.default.prices.fromFlat": "",
  "features.default.prices.fromTo": "",
  "features.default.prices.fromToFlat": "",
  "filterDialog.apply": "Uporabi filter",
  "filterDialog.category.advanced": "Napredni filtri",
  "filterDialog.category.assigned": "Dodeljeno območje",
  "filterDialog.header": "Možnosti filtriranja",
  "filterDialog.required": "",
  "filterDialog.reset": "PONASTAVITEV FILTRA",
  "finishProjectDialog.text": "Ali ste prepričani, da želite dokončati projekt <b>{naslov projekta}</b>?",
  "finishProjectDialog.title": "Dokončanje projekta",
  "footer.copyright": "Vse pravice pridržane",
  "footer.disclaimer": "Izjava o omejitvi odgovornosti",
  "footer.generalBusinessTerms": "AGB",
  "footer.legalInformation": "Odtis",
  "footer.privacy": "Varstvo podatkov",
  "helpcenter.ideas": "",
  "helpcenter.problems": "",
  "helpcenter.problems.email": "",
  "helpcenter.problems.send": "",
  "helpcenter.problems.upload.failed": "",
  "helpcenter.problems.upload.success": "",
  "helpcenter.problems.upload.tooManyRequests": "",
  "helpcenter.problems.whathappened": "",
  "helpcenter.problems.whatshouldhappen": "",
  "helpcenter.problems.whatwasdone": "",
  "helpCenter.title": "",
  "helpcenter.videos": "",
  "helpcenter.videos.tpaonly": "",
  "hr.casualEmployment": "Zaposlitev za vsak primer posebej",
  "hr.disclaimer.additionalData": "Za neposredni prenos potrebujemo še nekaj dodatnih podatkov. Prosimo, da jih izpolnite.",
  "hr.disclaimer.employmentDate": "Datum zaposlitve",
  "hr.disclaimer.subtitle": "Ali želite podatke poslati TPA ali neposredno zdravstveni zavarovalnici?",
  "hr.disclaimer.title": "Prenos",
  "hr.disclaimer.transfer.direct": "Prenos podatkov se opravi neposredno avstrijski zdravstveni blagajni.",
  "hr.disclaimer.transfer.direct.subtitle": "Ugotavljamo, da bo TPA podatke preverila šele v naslednjih dneh, in sicer najprej takrat, ko bo pisarna odprta.",
  "hr.disclaimer.transfer.tpa": "Prenos podatkov se opravi v TPA",
  "hr.disclaimer.transfer.tpa.subtitle": "Opozarjamo, da bo prijava zaposlenega v avstrijsko zdravstveno blagajno izvedena šele po obdelavi podatkov s strani TPA.",
  "hr.documents.backLabel": "Osebje/spisi",
  "hr.documents.emptyState.header": " zajemanje",
  "hr.documents.emptyState.message": "Zdaj začnite nalagati datoteke.",
  "hr.documents.list.backLabel": "Osebje/dokumenti",
  "hr.documents.nonWorkingTimes": "Časi, ko se ne izvaja",
  "hr.documents.nonWorkingTimesUpload": "Čas nedelovanja prenosa datotek",
  "hr.documents.upload.navHeader": "{documentType}",
  "hr.documents.upload.navLabel": "Osebje/dokumenti",
  "hr.employee.employment": ". Delovno razmerje",
  "hr.employee.employmentRelationship": "Delovno razmerje",
  "hr.employee.jobDescription": "Naziv delovnega mesta",
  "hr.employee.payslip.upload.date": "",
  "hr.employee.payslip.upload.title": "",
  "hr.employee.payslip.upload.type": "",
  "hr.employee.payslip.upload.type.monthly": "",
  "hr.employee.payslip.upload.type.yearly": "",
  "hr.employee.reasonForLeaving": "Razlog za odhod",
  "hr.employee.workplaceState": "Stanje delovnega mesta",
  "hr.finished.analysis": "Mesečne ocene",
  "hr.finished.billing": "Mesečni izpiski",
  "hr.finished.globalAnalysis": "Vrednotenje na ravni podjetja",
  "hr.finished.globalBilling": "Računovodstvo na ravni podjetja",
  "hr.insuranceCarrier": "Nosilec zavarovanja",
  "hr.overview.continuousActions": "Neprekinjene funkcije",
  "hr.recordTypes.names.benefitsInKindCar": "",
  "hr.recordTypes.names.contractAdjustmentsAndSpecialPayments": "",
  "hr.recordTypes.names.mealDeductions": "",
  "hr.recordTypes.names.nonWorkingTimes": "",
  "hr.recordTypes.names.overtimeAndBonusPayments": "",
  "hr.recordTypes.names.supplementaryDocuments": "",
  "hr.recordTypes.names.timeRecords": "",
  "hr.recordTypes.names.travelExpenseReport": "",
  "hr.tickets.preRegistration.NavBar": "Povpraševanje o {preRegistration}",
  "hr.tickets.preRegistration.NavBarLabel": "Osebje / Zaposleni / Vprašanja",
  "hr.tickets.preRegistration.toOverview": "V OSEBNO MAPO",
  "imageCrop.description": "Povlecite in premaknite sliko",
  "imageCrop.title": "Slika položaja",
  "imageCrop.zoom": "Povečanje",
  "language.german": "Nemščina",
  "maritalStatus.divorced": "razvezani",
  "maritalStatus.livingInPartnership": "Življenje v partnerstvu",
  "maritalStatus.married": "Poročen/registrirano partnerstvo",
  "maritalStatus.separated": "Ločeno življenje",
  "maritalStatus.unknown": "neznano",
  "maritalStatus.unmarried": "enojni",
  "maritalStatus.widowed": "ovdoveli",
  "menu.answer": "Odgovor",
  "menu.assign": "Dodelitev",
  "menu.cancelRelease": "Odvzem sprostitve",
  "menu.delete": "Izbriši",
  "menu.details": "Podrobnosti",
  "menu.download": "Prenesi",
  "menu.draft": "Zahteva za odobritev",
  "menu.edit": "Uredi",
  "menu.editRelease": "Ponovna zahteva za sprostitev",
  "menu.finish": "Zaklepanje",
  "menu.markAsDone": "Označi kot opravljeno",
  "menu.move": "Premikanje",
  "menu.openInBrowser": "Odprite v brskalniku",
  "menu.openInSharepoint": "Odprite v točki Sharepoint",
  "menu.rename": "Preimenovanje",
  "menu.requestRelease": "Zahteva za sprostitev",
  "menu.showReleases": "Prikaži izdaje",
  "menu.view": "Oglejte si",
  "mfa.activation.dialog.code.required": "Prosimo, vnesite kodo",
  "mfa.activation.dialog.description": "Vnesite kodo, ki smo jo poslali na vašo telefonsko številko, da aktivirate dvostopenjsko preverjanje pristnosti.",
  "mfa.activation.dialog.title": "Aktivacija storitve 2FA",
  "mfa.activation.success.dialog.title": "2FA je bila uspešno aktivirana",
  "mfa.changeSetting.dialog.code.field.label": "Koda",
  "mfa.changeSetting.dialog.resendCode": "Ponovno pošljite kodo",
  "mfa.codeResend.success.dialog.title": "Koda je bila ponovno poslana",
  "mfa.deactivation.dialog.description": "Vnesite kodo, ki smo jo poslali na vašo telefonsko številko, da onemogočite dvofaktorsko preverjanje pristnosti.",
  "mfa.deactivation.dialog.title": "Deaktiviranje storitve 2FA",
  "mfa.deactivation.success.dialog.title": "2FA je bila uspešno deaktivirana",
  "mfa.validation.code.length": "Koda ima 6 številk.",
  "mfa.validation.code.required": "Vnesti je treba kodo",
  "mfa.validation.code.type": "Dovoljene so samo številke",
  "microsoft_oauth.button.login.title": "Prijava z Microsoftom",
  "microsoft_oauth.button.send_code.title": "Pošlji kodo",
  "microsoft_oauth.button.upgrade.title": "Izvedite nadgradnjo",
  "missingEmailDialog.description": "E-poštni naslov <b>{ime}</b> ni v datoteki. Vnesite veljaven e-poštni naslov, da bomo lahko poslali vabilo.",
  "missingEmailDialog.title": "Manjka e-poštni naslov",
  "missingEmail.email.error": "Vnesite pravilen e-poštni naslov",
  "missingEmail.email.label": "Vnesite e-poštni naslov",
  "module.finished.globalReports": "Poročila za celotno podjetje",
  "modules.delete.report.message": "Ali res želite izbrisati poročilo {naziv}?",
  "modules.delete.report.title": "Brisanje poročila",
  "navbar.week": "KW {teden} {leto}",
  "newUsername.checkbox.error": "Potrdite svoj novi e-poštni naslov",
  "notificationCenter.emptyState": "",
  "notificationCenter.filter.accounting": "",
  "notificationCenter.filter.appNotification:maintenance": "",
  "notificationCenter.filter.appNotification:newFeature": "",
  "notificationCenter.filter.appNotification:update": "",
  "notificationCenter.filter.companySpecific": "",
  "notificationCenter.filter.employeeDocument": "",
  "notificationCenter.filter.file": "",
  "notificationCenter.filter.general": "",
  "notificationCenter.filter.generalDocument": "",
  "notificationCenter.filter.hr": "",
  "notificationCenter.filter.invoice": "",
  "notificationCenter.filter.login": "",
  "notificationCenter.filter.module": "",
  "notificationCenter.filter.notificationType": "",
  "notificationCenter.filter.other": "",
  "notificationCenter.filter.payslip": "",
  "notificationCenter.filter.project": "",
  "notificationCenter.filter.report": "",
  "notificationCenter.filter.ticket": "",
  "notificationCenter.filter.unread": "",
  "notificationCenter.markAllAsRead": "",
  "notificationCenter.title": "",
  "openEmployeeDocumentReleasesDialog.releaseMessage.multi": "V svoji osebni mapi imate <b>{število} novih dokumentov</b>, ki jih morate odobriti.",
  "openEmployeeDocumentReleasesDialog.releaseMessage.single": "V svoji osebni mapi imate <b>nov dokument</b>, ki zahteva vašo odobritev.",
  "openEmployeeDocumentReleasesDialog.submit": "Oglejte si in delite",
  "openEmployeeDocumentReleasesDialog.title": "Skupna raba dokumentov",
  "paymentDialog.button.pay": "",
  "paymentDialog.field.amount": "",
  "paymentDialog.field.amount.maxError": "",
  "paymentDialog.field.amount.minError": "",
  "paymentDialog.field.amount.typeError": "",
  "paymentDialog.field.iban": "",
  "paymentDialog.field.iban.invalidError": "",
  "paymentDialog.field.purpose": "",
  "paymentDialog.title": "",
  "pdfView.error.caption": "Dokumenta ni bilo mogoče naložiti, poskusite znova",
  "pendingUserCreationDialog.message": "Ustvarjanje uporabnikov v Sharepointu lahko traja do 30 minut. Bodite potrpežljivi, dokler dovoljenja ne bodo ustvarjena.",
  "pendingUserCreationDialog.title": "Uporabniki so ustvarjeni",
  "permission.accounting.banking": "Bančni podatki",
  "permission.accounting.global.reports": "Računovodska poročila za celotno podjetje",
  "permission.accounting.global.results": "Računovodske ocene na ravni podjetja",
  "permission.accounting.incaseoflaw": "",
  "permission.accounting.records": "Obvestilo o računovodstvu",
  "permission.accounting.reports": "Računovodska poročila",
  "permission.accountTransaction.payment": "",
  "permission.expenses.cockpit": "Ogled dejstev in številk v pilotski kabini",
  "permission.expenses.kpi": "Uporaba modula Dejstva in številke",
  "permission.hr.employeePayrollAccount": "",
  "permission.hr.employerPayrollAccount": "",
  "permission.hr.employerPayrollAccount.details": "",
  "permission.hr.global.reports": "računovodska poročila o plačah za celotno podjetje",
  "permission.hr.global.results": "",
  "permission.hr.personnel": "Zaposleni",
  "permission.hr.records": "Objava kadrovske poravnave",
  "permission.hr.reports": "Računovodska poročila o plačah",
  "permission.option.canAccess": "lahko dostopate do",
  "permission.option.canEdit": "lahko urejate",
  "permission.option.canEdit.all": "lahko uredite/odklenete vse",
  "permission.option.canEdit.none": "ne more urediti/odkleniti ničesar",
  "permission.option.canEdit.some": "Lahko delno uredite/odstrani",
  "permission.option.canPay": "",
  "permission.option.canRead": "lahko vidite",
  "permission.option.canRead.all": "lahko vidite vse.",
  "permission.option.canRead.none": "ne more videti ničesar",
  "permission.option.canRead.some": "lahko delno vidite",
  "permission.option.canRelease": "lahko sprosti",
  "permission.option.canUpdate": "lahko urejate",
  "permission.option.canUpload": "lahko naložite",
  "permission.option.mixed": "mešani",
  "permission.option.none": "brez dovoljenja",
  "permission.projects": "Uporaba modula za projekte",
  "permission.settings.company": "Prikaži nastavitve podjetja",
  "permission.settings.features": "",
  "permission.settings.images": "Spremenite sliko naslovnice in logotip",
  "permission.settings.user": "Predlaganje sprememb uporabniških pravic",
  "permission.taxAccount.payment": "",
  "permission.unpaidAccount.payment": "",
  "project.invitation": "Povabilo k projektu",
  "projectItemPermission.owner": "Polni dostop",
  "projectItemPermission.owner.description": "Vse pravice za urejanje map in datotek. Urejanje in dokončanje projekta ter upravljanje članov in dovoljenj.",
  "projectItemPermission.read": "Preberi",
  "projectItemPermission.read.description": "Pregledovanje map in datotek ter prenašanje datotek",
  "projectItemPermission.readNoDownload": "Samo za branje",
  "projectItemPermission.write": "Uredi",
  "projectItemPermission.write.description": "Pregledovanje, ustvarjanje in preimenovanje map. Ogled, nalaganje, urejanje in brisanje datotek.",
  "projects.activate": "PONOVNA OBNOVA",
  "projects.active.emptystate.buttonlabel": "Ustvari projekt",
  "projects.active.emptystate.message": "Za to podjetje še ni projekta",
  "projects.addCompanies": "Dodajte podjetje",
  "projects.addCompanies.tooltip": "Ustvarite projekt v več podjetjih",
  "projects.addFolder": "Ustvari mapo",
  "projects.addMembers": "Dodajanje članov",
  "projects.addMembers.bulk.success.title": "Uspešno dodani člani",
  "projects.bulkDeleteDialog.message": "Ali ste prepričani, da želite izbrisati izbrane datoteke in mape?",
  "projects.bulkDeleteDialog.title": "Brisanje datotek in map",
  "projects.cancelRequestedReleaseConfirmationDialog.confirm": "Umik",
  "projects.cancelRequestedReleaseConfirmationDialog.message": "Ali ste prepričani, da želite umakniti odprti delež v <b>{imenom datoteke}</b>?",
  "projects.cancelRequestedReleaseConfirmationDialog.title": "Umik obstoječe izdaje?",
  "projects.closed.emptystate.message": "Za to podjetje še ni zaključenega projekta",
  "projects.companies.emptyState.title": "Podjetja niso na voljo",
  "projects.companies.submit": "Dodaj",
  "projects.copySuccessDialog.message": "Izbrane datoteke so bile kopirane v odložišče",
  "projects.copySuccessDialog.title": "Kopiranje v odložišče",
  "projects.create.bulk.backToProjects": "Nazaj na projekte",
  "projects.create.bulk.description": "Projekti so v pripravi. To lahko traja nekaj minut, zato ne zapirajte okna brskalnika.",
  "projects.create.bulk.start": "Zagon",
  "projects.deleted.emptystate.message": "Za to podjetje še ni izbrisanega projekta",
  "projects.deleteDialog.message.file": "Ali ste prepričani, da želite izbrisati datoteko <b>{naziv}</b>?",
  "projects.deleteDialog.message.folder": "Ali ste prepričani, da želite izbrisati mapo <b>{naslov}</b>?",
  "projects.deleteDialog.message.project": "Ali ste prepričani, da želite izbrisati projekt <b>{name}</b>?",
  "projects.deleteDialog.title.file": "Izbriši datoteko",
  "projects.deleteDialog.title.folder": "Izbriši mapo",
  "projects.deleteDialog.title.project": "Izbriši projekt",
  "projects.emptystate.title": "Projekti niso na voljo",
  "projects.externalId": "Referenčna številka:",
  "projects.finish.successDialog.title": "Uspešno zaključen projekt",
  "projects.folder.emptystate.message": "Zdaj začnite polniti mapo.",
  "projects.folder.emptystate.title": "Ustvarjanje in nalaganje vsebine",
  "projects.importUsers.add": "Dodaj",
  "projects.importUsers.replace": "Zamenjava",
  "projects.importUsers.switch": "Uvoz članov iz drugega projekta",
  "projects.international": "Mednarodni projekt",
  "projects.international.activate": "Če želite projekt pretvoriti v mednarodni projekt, morate omogočiti to funkcijo.",
  "projects.international.confirmButton": "Pretvori projekt",
  "projects.international.disabled.refresh": "Vaša mednarodna prijava v MS je potekla, zato jo obnovite. Nato lahko spremenite \"{projekt}\" v mednarodni projekt.",
  "projects.international.disabled.unlock": "Če želite projekt pretvoriti v mednarodni projekt, morate to funkcijo omogočiti v pregledu projekta.",
  "projects.international.info.message": "Preoblikovanje projekta v mednarodni projekt omogoča, da se k projektu povabi osebje TPA iz drugih držav, da bi lahko čezmejno sodelovalo z naročnikom. V ta namen se vse datoteke in dokumenti, shranjeni kot del projekta v Microsoft SharePoint, prenesejo med različnimi Microsoftovimi najemniki, pri tem pa se ponovno vzpostavijo vsa pooblastila uporabnikov za delo na projektu. Pri tem se lahko izgubijo vsa podrobna dovoljenja. Zato je priporočljivo, da po pretvorbi v mednarodni projekt preverite možnosti dostopa. Tega koraka ni mogoče preklicati.",
  "projects.international.info.subtitle": "Upoštevajte naslednje:",
  "projects.international.invite.message": "Dodani ste v nove mednarodne projekte. Enkrat aktivirajte naslednje države, da si boste lahko ogledali projekte iz teh držav.",
  "projects.international.invite.title": "Odklepanje novih držav",
  "projects.international.migrating": "Mednarodni projekt (pretvorba v teku)",
  "projects.international.migrating.message": "Projekt se trenutno spreminja v mednarodni projekt. Dokler pretvorba ne bo končana, urejanje ne bo več mogoče.",
  "projects.international.plural": "Mednarodni projekti",
  "projects.international.switch": "spremenite ta projekt v mednarodni projekt",
  "projects.international.undoWarning.message": "Ali ste prepričani, da želite ta projekt spremeniti v mednarodni projekt? Vsi podatki o projektu bodo kopirani v TPA AT sharepoint. Ko je projekt ustvarjen, tega dejanja ni mogoče preklicati.",
  "projects.international.undoWarning.message.at": "Ali ste prepričani, da želite ta projekt spremeniti v mednarodni projekt? Ko je projekt ustvarjen, tega dejanja ni mogoče preklicati.",
  "projects.international.undoWarning.title": "Tega dejanja ni mogoče preklicati.",
  "projects.invite.international": "Dodani ste v mednarodne projekte. Zdaj jih odklepajte.",
  "projects.invite.message": "Dodani ste v projekte iz {numCountries} držav. Zdaj jih odklenite.",
  "projects.invite.message.singular": "Dodani ste projektom iz {države}. Zdaj jih odklepajte.",
  "projects.members.assignPermission": "Dodelitev pooblastila",
  "projects.membersIncluded.multi": "Vključno s {imeni} in {naslovom}.",
  "projects.membersIncluded.single": "Vključno z imenom {name}.",
  "projects.moveConfirmationDialog.message": "Premaknjene datoteke se izbrišejo iz prvotne mape!",
  "projects.moveConfirmationDialog.title": "Premikanje datotek?",
  "projects.moveSuccessDialog.message": "Datoteke so bile uspešno premaknjene",
  "projects.moveSuccessDialog.title": "Premaknjene datoteke",
  "projects.navbar.activeProjects": "Aktivno",
  "projects.navbar.deletedProjects": "Izbrisano",
  "projects.navbar.fileUpload": "Prenos datotek",
  "projects.navbar.finishedProjects": "Zaključeno",
  "projects.navbar.heading": "Projekti",
  "projects.navbar.notConfiguredProjects": "Ni konfigurirano",
  "projects.navbar.overview": "Pregled{path}",
  "projects.navbar.project.fileTree": "Struktura mape",
  "projects.navbar.project.heading": "Projekt: {projectName}",
  "projects.navbar.project.releases": "Sprostitve",
  "projects.navbar.releases.heading": "Deleži za {imenom datoteke}",
  "projects.navbar.ticket.heading": "Poizvedba o {imenski datoteki}",
  "projects.new": "Nov projekt",
  "projects.new.createNewProject": "Ustvari projekt",
  "projects.new.externalId": "Referenčna številka",
  "projects.new.name": "Ime projekta",
  "projects.new.permissionContextMenu.remove": "Odstranite",
  "projects.new.permissions.text": "Dodajanje novih članov in dodeljevanje uporabniških pravic.",
  "projects.new.title": "Ime projekta",
  "projects.new.userSearch.placeholder": "Povabite člana...",
  "projects.notConfigured.emptystate.message": "Za to podjetje še ni projekta, ki bi ga bilo treba konfigurirati",
  "projects.openReleases.plural": "Odprte izdaje",
  "projects.openReleases.singular": "Odprta izdaja",
  "projects.pasteProgressDialog.title": "",
  "projects.pasteSuccessDialog.message": "Datoteke so bile uspešno vstavljene",
  "projects.pasteSuccessDialog.title": "Vložene datoteke",
  "projects.refreshDialog.message": "Microsoftove prijave za naslednje države so potekle. Obnovite jih.",
  "projects.refreshDialog.title": "Microsoftove prijave so potekle",
  "projects.refresh.message": "Microsoftove prijave za države {numCountries} so potekle. Prosimo, da jih obnovite.",
  "projects.refresh.message.singular": "Vaša Microsoftova prijava za {država} je potekla. Prosimo, obnovite ga.",
  "projects.releaseConfirmationDialog.message": "Upoštevajte naslednjo pripombo, ki jo je poslal {name}:",
  "projects.releaseConfirmationDialog.title": "Skupna raba dokumenta",
  "projects.releases.emptystate.message": "Trenutno nimate nobenih izdaj.",
  "projects.releases.emptystate.title": "Ni na voljo nobenih izdaj",
  "projects.releases.reject": "Upadanje",
  "projects.releases.status.canceled": "umaknjeno",
  "projects.releases.status.canceledFrom": "ki ga je umaknil {name}",
  "projects.releases.status.canceled.tooltip": "umaknjeno na {performedAt} s strani {name}",
  "projects.releases.status.open": "Čakanje na sprostitev",
  "projects.releases.status.openFrom": "čakanje na sprostitev {imena}",
  "projects.releases.status.open.tooltip": "čaka na sprostitev (rok: {dueDate}) za {name}",
  "projects.releases.status.rejected": "Zavrnjeno",
  "projects.releases.status.rejectedFrom": "zavrnjeno s strani {imena}",
  "projects.releases.status.rejected.tooltip": "zavrnjeno na {performedAt} s strani {name}",
  "projects.releases.status.released": "objavljeno",
  "projects.releases.status.releasedFrom": "ki ga je izdal {naslov}",
  "projects.releases.status.released.tooltip": "izdano na {performedAt} s strani {name}",
  "projects.releaseSuccessDialog.message": "<b>{naslov}</b> bo zdaj pregledal datoteke in vas kontaktiral z morebitnimi vprašanji.",
  "projects.releaseSuccessDialog.multi.message": "Dodeljene osebe bodo zdaj pregledale datoteke in vas kontaktirale z morebitnimi vprašanji.",
  "projects.releaseSuccessDialog.title": "Uspešno zahtevana sprostitev",
  "projects.requestReleaseConfirmationDialog.confirm": "Umakni in obnovi",
  "projects.requestReleaseConfirmationDialog.message": "Za <b>{imenom datoteke}</b> je že odprta skupna raba. Če želite ustvariti nov delež, morate obstoječi delež umakniti.",
  "projects.requestReleaseConfirmationDialog.multi.message": "V vašem izboru so že odprte izdaje. Če želite ustvariti nove izdaje, je treba obstoječe izdaje umakniti.",
  "projects.requestReleaseConfirmationDialog.multi.title": "Umik obstoječih izdaj?",
  "projects.requestReleaseConfirmationDialog.title": "Umik obstoječe izdaje?",
  "projects.restoreDialog.message.project": "Ali ste prepričani, da želite aktivirati projekt <b>{naslov}</b>?",
  "projects.restoreDialog.title.project": "Aktivacija projekta",
  "projects.statusClosed": "Zaključeno na",
  "projects.statusClosed.personalized": "Zaključil {ime} dne",
  "projects.statusDeleted": "Izbrisano na",
  "projects.statusDeleted.personalized": "Izbrisano s strani {name} dne",
  "projects.statusInProgress": "Nazadnje spremenjeno dne",
  "projects.statusInProgress.personalized": "Nazadnje spremenil {ime} dne",
  "projects.tickets.plural": "Poizvedbe",
  "projects.tickets.singular": "Posvetovanje",
  "records.details.upload.header": "Zapis {recordType}",
  "recordType.statusClosed": "Poslano na",
  "recordType.statusInProgress": "Urejeno na",
  "recordType.statusOpen": "Neurejeno",
  "renameDialog.button.text.rename": "Preimenovanje",
  "renameDialog.label.document": "Poimenovanje",
  "renameDialog.label.file": "Ime datoteke",
  "renameDialog.label.folder": "Ime mape",
  "renameDialog.title.document": "Preimenovanje dokumenta",
  "renameDialog.title.file": "Preimenovanje datoteke",
  "renameDialog.title.folder": "Preimenovanje mape",
  "renameProjectItemDialog.button.text.rename": "Preimenovanje",
  "renameProjectItemDialog.itemName.file": "Ime datoteke",
  "renameProjectItemDialog.itemName.folder": "Ime mape",
  "renameProjectItemDialog.title.file": "Preimenovanje datoteke",
  "renameProjectItemDialog.title.folder": "Preimenovanje mape",
  "renameRecordDialog.button.text.rename": "Preimenovanje",
  "renameRecordDialog.name": "Poimenovanje",
  "renameRecordDialog.title": "Preimenovanje dokumenta",
  "requestReleaseProjectItemDialog.button.text.request": "Poizvedbe",
  "requestReleaseProjectItemDialog.info": "",
  "requestReleaseProjectItemDialog.label.comment": "Komentar:",
  "requestReleaseProjectItemDialog.label.dueDate": "Rok",
  "requestReleaseProjectItemDialog.label.requiresQes": "sprostitev z elektronskim podpisom (samo datoteke PDF)",
  "requestReleaseProjectItemDialog.placeholder.searchForUsers": "Vnesite ime...",
  "requestReleaseProjectItemDialog.qes.collective.subtitle": "Vse osebe morajo sprostiti",
  "requestReleaseProjectItemDialog.qes.collective.title": "Kolektivni izpust",
  "requestReleaseProjectItemDialog.qes.single.subtitle": "Samo ena oseba mora sprostiti",
  "requestReleaseProjectItemDialog.qes.single.title": "Enotna izdaja",
  "requestReleaseProjectItemDialog.searchForUsers.error": "Izberite ime s seznama",
  "requestReleaseProjectItemDialog.text": "Izberite osebe, ki jim želite dodeliti sprostitev za navedene dokumente, in vnesite rok.",
  "requestReleaseProjectItemDialog.title.addResponsibleUser": "Dodajte še eno osebo",
  "requestReleaseProjectItemDialog.title.create": "Zahteva za sprostitev",
  "requestReleaseProjectItemDialog.title.edit": "Ureditev izdaje",
  "results.accountSheet.accountDetails.pay": "",
  "results.accountSheet.accountTransaction.lastCompletedPayment": "",
  "results.accountSheet.accountTransaction.lastCompletedPaymentWithAmount": "",
  "results.accountSheet.accountTransaction.lastCompletedPaymentWithAmount.detailed": "",
  "results.accountSheet.backLabel.accountSheet": "List računa {accountNr} \"{name}\"",
  "results.accountSheet.backLabel.results.accountListing": "Bilančni seznam",
  "results.accountSheet.backLabel.results.balanceSheet": "Bilanca stanja",
  "results.accountSheet.backLabel.results.cashAccounting": "Izkaz prihodkov in odhodkov",
  "results.accountSheet.backLabel.results.customerListing": "",
  "results.accountSheet.backLabel.results.profitAndLoss": "G&V",
  "results.accountSheet.backLabel.results.vendorListing": "",
  "results.accountSheet.columns.amount": "Znesek",
  "results.accountSheet.columns.documentDate": "Datum dokumenta",
  "results.accountSheet.columns.documentNumber": "Dokument št.",
  "results.accountSheet.columns.documents": "Prejemki",
  "results.accountSheet.columns.offsettingAccounts": "Kontra računi",
  "results.accountSheet.columns.openItemAmount": "Odprti znesek",
  "results.accountSheet.columns.postingSymbol": "BS",
  "results.accountSheet.columns.postingText": "Besedilo objave",
  "results.accountSheet.details.costCenter": "Stroškovno središče",
  "results.accountSheet.details.discount": "Denarni popust",
  "results.accountSheet.details.externalDocumentNumber": "Zunanji dokument št.",
  "results.accountSheet.details.foreignCurrency": "Tuja valuta / menjalni tečaj",
  "results.accountSheet.details.period": "Obdobje",
  "results.accountSheet.details.serialNumber": "Časopis št.",
  "results.accountSheet.details.tax": "Davek",
  "results.accountSheet.details.taxCode.Ausfuhrlieferungen": "",
  "results.accountSheet.details.taxCode.BauleistungenMitVSt": "Gradbena dela z DDV",
  "results.accountSheet.details.taxCode.BauleistungenOhneVSt": "Gradbena dela brez DDV",
  "results.accountSheet.details.taxCode.EUSt": "EUSt",
  "results.accountSheet.details.taxCode.igEMitVSt": "igE z DDV",
  "results.accountSheet.details.taxCode.igEOhneVst": "igE brez DDV",
  "results.accountSheet.details.taxCode.igLieferung": "",
  "results.accountSheet.details.taxCode.Kleinunternehmer": "",
  "results.accountSheet.details.taxCode.RCMitVSt": "RC z VSt",
  "results.accountSheet.details.taxCode.RCOhneVSt": "RC brez DDV",
  "results.accountSheet.details.taxCode.sonstigeLeistungenEuZm": "",
  "results.accountSheet.details.taxCode.TeilabzugsfaehigeVSt": "delno odbitni DDV",
  "results.accountSheet.details.taxCode.Ust": "DDV",
  "results.accountSheet.details.taxCode.VSt": "VSt",
  "results.accountSheet.details.taxPercent": "Davčni odstotek",
  "results.accountSheet.details.vatPeriod": "Obdobje UVA",
  "results.accountSheet.documents.downloadAll": "Prenesite vse datoteke",
  "results.accountSheet.filters.amount": "Znesek",
  "results.accountSheet.filters.documentDate": "Datum dokumenta",
  "results.accountSheet.filters.postingSymbol": "Simbol rezervacije",
  "results.accountSheet.noResults.message": "Za izbrani račun ni bilo mogoče najti nobenih elementov",
  "results.accountSheet.noResults.open.message": "Za izbrani račun ni bilo mogoče najti odprtih elementov",
  "results.accountSheet.noResults.open.title": "Ni bilo najdenih odprtih elementov",
  "results.accountSheet.noResults.title": "Ni najdenih delovnih mest",
  "results.accountSheet.offsettingAccounts.more": "{count} več",
  "results.accountSheet.paid": "plačano",
  "results.accountSheet.search.count": "Rezervacije: <b>{count}</b>",
  "results.accountSheet.search.placeholder": "Iskanje besedila za rezervacijo...",
  "results.accountSheet.sum.title": "",
  "results.accountSheet.tabs.all": "Vsi predmeti",
  "results.accountSheet.tabs.open": "Samo odprti predmeti",
  "results.accountSheet.title": "Računski list za račun {accountNr} \"{name}\"",
  "results.columns.changeAbsolute": "Abw.",
  "results.columns.changePercentage": "Dev. (%)",
  "results.columns.credit": "",
  "results.columns.debit": "",
  "results.common.accounts": "Računi",
  "results.common.quickExpand.expandAccounts": "Vsi (vključno z računi)",
  "results.common.quickExpand.expandNthDepth": "Raven {globina}",
  "results.common.quickExpand.expandRoot": "Glavna raven razčlenitve",
  "results.common.quickExpand.expandRows": "Zložite črte:",
  "results.disclaimer": "",
  "results.disclaimerMessage": "",
  "results.navbar.buttons.accountListing": "Bilančni seznam",
  "results.navbar.buttons.balanceSheet": "Bilanca stanja",
  "results.navbar.buttons.cashAccounting": "Izkaz prihodkov in odhodkov",
  "results.navbar.buttons.customerListing": "",
  "results.navbar.buttons.payrollAccount": "",
  "results.navbar.buttons.profitAndLoss": "G&V",
  "results.navbar.buttons.vendorListing": "",
  "results.noFinancialAccountancies.message": "Trenutno ni na voljo nobenih ocen.",
  "results.noFinancialAccountancies.title": "Brez ocenjevanj",
  "results.payrollAccountSheet.dataTable.month": "",
  "results.payrollAccountSheet.dataTable.number": "",
  "results.payrollAccountSheet.dataTable.payrollType": "",
  "results.payrollAccountSheet.dataTable.total": "",
  "results.payrollAccountSheet.dataTable.totalPreviousYear": "",
  "results.payrollAccountSheet.noPermission.message": "",
  "results.payrollAccountSheet.noPermission.title": "",
  "results.payrollAccountSheet.noResults.details": "",
  "results.payrollAccountSheet.noResultsYear.message": "",
  "results.payrollAccountSheet.noResultsYear.title": "",
  "results.settings.accountsVisibility.hideAccounts": "Skrij račune",
  "results.settings.accountsVisibility.showAccounts": "Prikaži račune",
  "results.settings.orderBy.accountNameAsc": "Ime računa naraščajoče",
  "results.settings.orderBy.accountNameDesc": "Ime računa v padajočem vrstnem redu",
  "results.settings.orderBy.accountNrAsc": "naraščajoča številka računa",
  "results.settings.orderBy.accountNrDesc": "Številka računa padajoče",
  "results.settings.orderBy.amountAsc": "Znesek naraščajoče",
  "results.settings.orderBy.amountDesc": "Znesek po padajočem vrstnem redu",
  "results.settings.orderBy.title": "Razvrsti po: {orderBy}",
  "results.settings.previousYearRanges.fullYear": "Prikaži skupno prejšnje leto",
  "results.settings.previousYearRanges.partialYear": "Upoštevajte prejšnje leto samo do {previousYearRangeUntil}.",
  "results.settings.variant.compactView": "",
  "results.settings.variant.currentAndPastYear": "Novice in prejšnje leto",
  "results.settings.variant.currentAndPastYearAbsoluteChange": "Tekoče in prejšnje leto z absolutno spremembo",
  "results.settings.variant.currentAndPastYearPercentageChange": "Tekoče in prejšnje leto z odstotno spremembo",
  "results.settings.variant.currentYear": "Tekoče leto",
  "results.settings.variant.detailView": "",
  "results.title": "Vrednotenja",
  "role.accountingAssistent": "Računovodski pomočnik",
  "role.accountingAssistent.description": "Dostop do računovodstva brez pravic do izdaje",
  "role.accountingManagement": "Upravljanje financ",
  "role.accountingManagement.description": "Dostop do računovodstva, vključno s pravicami do izdaje",
  "role.admin": "Upravljanje / vodenje",
  "role.admin.description": "Vse pravice dostopa do vseh območij",
  "role.assistent": "Pomoč",
  "role.assistent.description": "Dostop do računovodstva in obračuna plač brez pravic za sprostitev{br}Predlaga lahko spremembo uporabnikov",
  "role.custom": "Po meri",
  "role.custom.description": "Uporabniško opredeljene pravice dostopa",
  "role.custom.jobTitle": "Zaposleni",
  "role.custom.jobTitle.female": "Osebje",
  "role.hrAssistent": "Pomočnik za obračun plač",
  "role.hrAssistent.description": "Dostop do obračuna plač brez pravic za sprostitev",
  "role.hrManagement": "Upravljanje človeških virov",
  "role.hrManagement.description": "Dostop do računovodstva plač, vključno s pravicami do sprostitve.",
  "role.internationalProjects": "Projektno osebje",
  "role.internationalProjects.female": "Projektno osebje",
  "role.legalAdvisor": "Pravni svetovalec: v",
  "role.noRole": "brez dela",
  "role.noRole.description": "Brez pravic dostopa do storitve TPA Connect",
  "role.staff": "Zaposleni",
  "role.staff.description": "prejema elektronske izpiske o zaslužkih",
  "role.staff.female": "Zaposleni",
  "role.superAdmin": "Superadmin",
  "role.superAdmin.description": "Dostop do vseh področij, vključno z upravljanjem uporabnikov",
  "role.tpaAccounting": "Računovodstvo",
  "role.tpaAccounting.description": "Dostop svetovalca do računovodstva",
  "role.tpaAdvisor": "Svetovalec",
  "role.tpaAdvisor.description": "Dostop svetovalca do vseh prostorov",
  "role.tpaAdvisor.female": "Svetovalec",
  "role.tpaAnnualAccounts": "Letni računovodski izkazi",
  "role.tpaAnnualAccounts.description": "Odgovornost za letne računovodske izkaze",
  "role.tpaAuditor": "Revizija",
  "role.tpaBusinessConsultant": "Svetovanje na področju upravljanja",
  "role.tpaBusinessConsultant.description": "Odgovoren za svetovanje na področju upravljanja",
  "role.tpaChat": "Pišite na",
  "role.tpaChatSecret": "Pišite na",
  "role.tpaHr": "Obračun plač",
  "role.tpaHr.description": "Dostop svetovalca do obračuna plač",
  "role.tpaOther": "",
  "role.tpaOther.female": "",
  "role.tpaPartner": "Partner",
  "role.tpaPartner.description": "Dostop svetovalca do vseh prostorov",
  "role.tpaPartner.female": "Partner",
  "role.tpaPayrollAccountant": "Obračun plač",
  "role.tpaReporting": "Poročanje",
  "role.tpaReporting.description": "Odgovoren za poročanje",
  "role.tpaSpecialAdvisor": "Posebno posvetovanje",
  "role.tpaSpecialAdvisor.description": "Odgovoren za posebno svetovanje",
  "role.tpaTaxAccountant": "Davčno svetovanje",
  "role.tpaTaxAccountant.description": "Odgovoren za davčno svetovanje",
  "screen.accounting.navbar.bankConnections": "Bančni podatki",
  "screen.accounting.navbar.heading": "Računovodstvo",
  "screen.accounting.navbar.overview": "Pregled",
  "screen.accounting.navbar.records": "Prejemki",
  "screen.accounting.overview.bankConnection": "{salutation}, {br} {br} V tem obračunskem obdobju poteče veljavnost enega ali več vaših bančnih računov. Prosimo, da jih obnovite.{br} {br} Z vsem spoštovanjem{br} {podpis}",
  "screen.accounting.overview.bankConnection.expired": "{salutation}, {br} {br} Rok veljavnosti enega ali več vaših bančnih podatkov je potekel. Prosimo, da jih obnovite.{br} {br} Z vsem spoštovanjem{br} {podpis}",
  "screen.accounting.overview.bankConnection.expired.title": "Bančni podatki so potekli",
  "screen.accounting.overview.bankConnections.refreshBankConnections": "Obnovitev bančnih računov",
  "screen.accounting.overview.bankConnection.title": "Bančni računi potečejo",
  "screen.accounting.overview.card.analysis": "Poročila o sprostitvi za",
  "screen.accounting.overview.card.globalAnalysis": "Objava poročil za celotno podjetje",
  "screen.accounting.overview.card.overview": "Obvestilo o obračunavanju",
  "screen.accounting.overview.finished.download": "Prenesite vse datoteke",
  "screen.accounting.overview.finished.message": "{salutation}, {br} {br} Zahvaljujem se vam za preverjanje in potrditev računov. V nadaljevanju najdete vaša poročila.{br} {br} S spoštovanjem, {br}{podpis}",
  "screen.accounting.overview.reports": "{salutation}, {br} {br} V prilogi najdete naslednje dokumente za {obdobje}. Dokumenti so bili pripravljeni na podlagi informacij, ki ste jih navedli, in dokumentov, ki ste jih predložili. Če v dokumentih odkrijete netočnosti ali nepopolnosti, nas o tem obvestite, da jih bomo lahko ustrezno popravili. {br} {br} Če imate kakršnakoli vprašanja, se obrnite na nas.{br} {br} Z vsem spoštovanjem, {br}{podpis}",
  "screen.accounting.overview.ticketsWelcome.inProgress": "{salutation}, {br} {br} Zahvaljujem se vam za posredovanje kuponov. Med računovodstvom se je pojavilo nekaj vprašanj:",
  "screen.accounting.overview.welcome.closed": "{salutation}, {br} {br} Zahvaljujemo se vam za predložitev vseh ustreznih dokazil. Obdelali jih bomo v skladu z dogovorjenimi roki in vas obvestili o morebitnih vprašanjih.",
  "screen.accounting.overview.welcome.inProgress": "{{br} {br} Zahtevamo, da naložite dokumente za rezervacijo za {obdobje}{datum zapadlosti}. Pošljite nam vse potrebne dokumente. {br} {br}S najlepšim pozdravom, {br}{podpis}",
  "screen.accounting.records.banner.selectedAll": "Izbrane so vse {count} datoteke v \"{recordType}\".",
  "screen.accounting.records.banner.selectedAll.transferFailed": "Izbrane so vse {count} neuspešne datoteke v \"{recordType}\".",
  "screen.accounting.records.banner.selectedCount": "Izbrane so vse datoteke {count} na tej strani",
  "screen.accounting.records.banner.totalCount": "Izberite vse {count} datoteke v \"{recordType}\".",
  "screen.accounting.records.banner.totalCount.transferFailed": "Izberite vse {count} neuspešne datoteke v \"{recordType}\".",
  "screen.accounting.records.banner.unselect": "Prekliči izbiro",
  "screen.accounting.records.documents": "Datoteke:",
  "screen.accounting.records.empty": "Datoteka je prazna",
  "screen.accounting.records.filter.transferFailed": "Prenos ni uspel",
  "screen.accounting.records.format.error": "Preverite format",
  "screen.accounting.records.move.dialog.button.text": "Premikanje",
  "screen.accounting.records.move.multiple.dialog.title": "Premikanje dokumentov",
  "screen.accounting.records.move.single.dialog.title": "Prenos dokumenta",
  "screen.accounting.records.navbar.tab.list": "Prejemki ({število})",
  "screen.accounting.records.navbar.tab.tickets": "Poizvedbe ({count})",
  "screen.accounting.records.person.responsible": "Odgovornost",
  "screen.accounting.records.serverUpload.error": "Datoteke ni bilo mogoče prenesti v strežnik",
  "screen.accounting.records.serverUpload.typeError": "Format ni sprejet",
  "screen.accounting.records.sizeAndFormat.error": "Preverite velikost in format datoteke",
  "screen.accounting.records.size.error": "Preverite velikost datoteke",
  "screen.accounting.records.tickets": "| Povpraševanja: {numTickets}",
  "screen.accounting.records.transfer.button.text": "Prenos v TPA",
  "screen.accounting.records.transfer.dialog.text": "Ali želite prenesti <b>vse računovodske</b> datoteke za obdobje {periodStart} - {periodEnd} v TPA za knjiženje?",
  "screen.accounting.records.transfer.dialog.textRecordType": "Ali želite prenesti <b>vse datoteke \"{imena}\"</b> za obdobje {periodStart} - {periodEnd} v TPA za knjiženje?",
  "screen.accounting.records.transfer.dialog.title": "Obračunavanje prenosa",
  "screen.accounting.records.transfer.retryDialog.button.text": "Poskusite znova.",
  "screen.accounting.records.transfer.retryDialog.file": "Napaka pri prenosu datoteke {failedFile}",
  "screen.accounting.records.transfer.retryDialog.text": "Ups. Nekaj je šlo narobe. Poskusite znova.",
  "screen.accounting.records.transfer.retryDialog.title": "Neuspešen menjalnik",
  "screen.accounting.records.transfer.successDialog.text": "Vaš svetovalec TPA bo zdaj pregledal podatke in vas kontaktiral z morebitnimi vprašanji.",
  "screen.accounting.records.transfer.successDialog.title": "Uspešno preneseni podatki",
  "screen.accounting.records.types.header": "Računovodstvo",
  "screen.accounting.records.unknown.error": "Neznana napaka",
  "screen.accounting.records.upload.button": "Naložite račune",
  "screen.accounting.records.upload.navbar.back": "Računovodstvo / kuponi",
  "screen.accounting.recordTypes.title": "Izberite vrsto kuponov",
  "screen.advisor.tabbar.active": "Aktivno",
  "screen.advisor.tabbar.notConfigured": "Ni konfigurirano",
  "screen.advisor.upload.approve.info": "",
  "screen.advisor.uploads.upload.approve": "Izpustitev za stranke",
  "screen.advisor.uploads.upload.approve.option.click": "Izdaja na klik",
  "screen.advisor.uploads.upload.approve.option.none": "Brez odobritve stranke",
  "screen.advisor.uploads.upload.approve.option.qes": "Sprostitev z elektronskim podpisom",
  "screen.advisor.uploads.upload.buttonText": "Prenos",
  "screen.advisor.uploads.upload.category": "Kategorija",
  "screen.advisor.uploads.upload.category.validation": "Izberite kategorijo",
  "screen.advisor.uploads.upload.comment": "Besedilo prenosa",
  "screen.advisor.uploads.upload.comment.downloadError": "",
  "screen.advisor.uploads.upload.dueDate": "zapade v plačilo dne",
  "screen.advisor.uploads.upload.dueDate.validation": "Prosimo, vnesite datum zapadlosti",
  "screen.advisor.uploads.upload.global": "V celotnem podjetju",
  "screen.advisor.uploads.upload.module": "Območje",
  "screen.advisor.uploads.upload.module.validation": "Izberite območje",
  "screen.advisor.uploads.upload.navBack": "Nalaganje",
  "screen.advisor.uploads.upload.navHeader": "Prenesite datoteke: {kategorija}",
  "screen.advisor.uploads.upload.notifyCustomer": "Obveščanje strank",
  "screen.advisor.uploads.upload.subsidiary.validation": "Izberite kodo podjetja",
  "screen.advisor.uploads.upload.visibleToEmployee": "Sprostitev za MA",
  "screen.auth.forgot_password_confirm.subtitle.section_1": "Če smo našli uporabnika z e-poštnim naslovom {email}, boste kmalu prejeli e-poštno sporočilo s povezavo za ponastavitev gesla.",
  "screen.auth.forgot_password_confirm.subtitle.section_2": "Niste prejeli nobene pošte? Preverite mapo za neželeno pošto ali se obrnite na skrbnika sistema.",
  "screen.auth.forgot_password_confirm.title": "Kmalu boste prejeli e-poštno sporočilo",
  "screen.auth.forgot_password_email.form.button": "Pošlji povezavo za ponastavitev",
  "screen.auth.forgot_password_email.form.label.email": "ID uporabnika / e-poštni naslov",
  "screen.auth.forgot_password_email.subtitle": "Vnesite svoj e-poštni naslov in poslali vam bomo e-poštno sporočilo s povezavo za ponastavitev gesla.",
  "screen.auth.forgot_password_email.title": "Ponastavitev gesla",
  "screen.auth.forgot_password_email.validation_error": "Vnesite svoj e-poštni naslov",
  "screen.auth.forgot_password_reset.error.password_not_set": "Vnesite geslo",
  "screen.auth.forgot_password_reset.error.passwords_do_not_match": "Gesla se ne ujemajo",
  "screen.auth.forgot_password_reset.error.password_weak": "Vaše geslo je še vedno prešibko.",
  "screen.auth.forgot_password_reset.form.button": "Sprememba gesla",
  "screen.auth.forgot_password_reset.form.button.mfa": "Pošlji kodo",
  "screen.auth.forgot_password_reset.form.button.mfa.resend_code": "Ponovno pošljite kodo",
  "screen.auth.forgot_password_reset.form.mfa": "Koda",
  "screen.auth.forgot_password_reset.form.password": "Geslo",
  "screen.auth.forgot_password_reset.form.repeat_password": "Ponovite geslo",
  "screen.auth.forgot_password_reset.subtitle": "Nastavite novo geslo za račun TPA Connect.",
  "screen.auth.forgot_password_reset.subtitle.mfa": "Vnesite kodo, ki smo jo poslali na vašo telefonsko številko prek sporočila SMS.",
  "screen.auth.forgot_password_reset.title": "Sprememba gesla",
  "screen.auth.forgot_password_reset.title.mfa": "Potrditev spremembe gesla z dvofaktorskim preverjanjem pristnosti",
  "screen.auth.registration.form.button": "Register",
  "screen.auth.registration.form.button.mfa": "Pošlji kodo",
  "screen.auth.registration.form.button.mfa.resendCode": "Ponovno pošljite kodo",
  "screen.auth.registration.form.label.mfa": "Koda",
  "screen.auth.registration.form.label.password": "Geslo",
  "screen.auth.registration.form.label.repeat_password": "Ponovite geslo",
  "screen.auth.registration.form.section_1": "Uporabite vsaj 8 znakov.",
  "screen.auth.registration.form.section_2": "Za močnejše geslo lahko uporabite velike in male črke, številke in posebne znake.",
  "screen.auth.registration.form.section_3": "Zamislite si mnemotehnični pripomoček, ki vam bo pomagal zapomniti si geslo.",
  "screen.auth.registration.mfa.subtitle": "Vnesite kodo, ki smo jo poslali na vašo telefonsko številko prek sporočila SMS.",
  "screen.auth.registration.subtitle": "Nastavite geslo za račun TPA Connect.",
  "screen.auth.registration.title": "Nastavitev in registracija gesla",
  "screen.auth.welcome.subtitle": "Če želite v celoti izkoristiti vse funkcije, povežite račun TPA Connect z Microsoftovim računom. Račun lahko povežete tudi pozneje.",
  "screen.auth.welcome.subtitle.msOnly": "Če želite v celoti izkoristiti vse funkcije, povežite račun TPA Connect z Microsoftovim računom.",
  "screen.auth.welcome.title": "Dobrodošli v TPA Connect!",
  "screen.changeUsername.checkbox": "S tem potrjujem svoj novi e-poštni naslov",
  "screen.changeUsername.message": "Računalniško srce aplikacije TPA Connect je opazilo, da se je vaš e-poštni naslov spremenil v <b>{novo uporabniško ime}</b>. Da bi bili popolnoma prepričani, da je ta sprememba pravilna, jo morate nujno potrditi z enim samim klikom.",
  "screen.changeUsername.title": "Nov e-poštni naslov",
  "screen.cockpit.allEvents": "Dogodki: Vsi dogodki",
  "screen.cockpit.allNews": "Novice: Vsi članki",
  "screen.cockpit.dueDates.export": "Izvozni roki",
  "screen.cockpit.dueDates.navbar.heading": "Odprti roki",
  "screen.cockpit.dueDates.projects.text": "Sprostitev za {imenom dokumenta}",
  "screen.cockpit.dueDates.records.accounting.text": "Računovodsko razkritje za {naslov podružnice} - {obdobje}",
  "screen.cockpit.dueDates.records.hr.text": "Razkritje podatkov o plačah za {naslov podružnice} - {obdobje}",
  "screen.cockpit.dueDates.reports.global.text": "Izdaja poročila za \"poročila za celotno podjetje\" - {obdobje}",
  "screen.cockpit.dueDates.reports.subsidiary.text": "Izdaja poročila za {podrejeno ime} - {obdobje}",
  "screen.cockpit.dueDates.reportUpload.bmd.message": "Za zagotavljanje poročil uporabite BMD NTCS",
  "screen.cockpit.dueDates.reportUpload.bmd.title": "Prenos poročila",
  "screen.cockpit.dueDates.reportUpload.global.text": "Prenos poročila za \"poročila za celotno podjetje\" - {obdobje}",
  "screen.cockpit.dueDates.reportUpload.subsidiary.text": "Naložitev poročila za {naslov podružnice} - {obdobje}",
  "screen.cockpit.dueDates.tickets.global.text": "Rok za vpogled v dokument {documentName}",
  "screen.cockpit.dueDates.tickets.subsidiary.text": "Rok za vpogled v dokument {naslov dokumenta} za {naslov podružnice}",
  "screen.cockpit.emptyState.message": "Vrnite se pozneje",
  "screen.cockpit.emptyState.title": "Trenutno ni nobenih novic",
  "screen.cockpit.eventDetail": "Podrobnosti o dogodku",
  "screen.cockpit.eventDetails.heading": "Podrobnosti in registracija",
  "screen.cockpit.eventDetails.lecturer.email": "E: {email}",
  "screen.cockpit.eventDetails.lecturers": "Predavatelj",
  "screen.cockpit.eventDetails.lecturer.telephone": "T: {telefon}",
  "screen.cockpit.eventDetails.register": "Registrirajte se zdaj",
  "screen.cockpit.event.moreInformation": "INFORMACIJE IN PRIJAVA",
  "screen.cockpit.kpis.add": "",
  "screen.cockpit.kpis.chart.legend": "v 1000 €",
  "screen.cockpit.kpis.configurationDialog.insuranceCarrierBalance.label": "",
  "screen.cockpit.kpis.configurationDialog.metric.label": "",
  "screen.cockpit.kpis.configurationDialog.months.label": "",
  "screen.cockpit.kpis.configurationDialog.title": "",
  "screen.cockpit.kpis.ebit.chart.name": "Razvoj EBIT",
  "screen.cockpit.kpis.ebit.kpi.name": "Dosedanji EBIT",
  "screen.cockpit.kpis.ebit.kpi.tooltip": "Prikazuje vrednost EBIT za vse mesece, ki so že v celoti knjiženi od začetka poslovnega leta.",
  "screen.cockpit.kpis.empty": "Vaša koda podjetja \"{ime}\" trenutno ni na voljo.",
  "screen.cockpit.kpis.insuranceCarrier.balance.kpi.name": "",
  "screen.cockpit.kpis.noneConfigured": "",
  "screen.cockpit.kpis.revenue.chart.name": "Gibanje prometa",
  "screen.cockpit.kpis.revenue.kpi.name": "Dosedanji promet",
  "screen.cockpit.kpis.revenue.kpi.tooltip": "Prikazuje promet vseh že v celoti rezerviranih mesecev od začetka poslovnega leta.",
  "screen.cockpit.kpis.save": "",
  "screen.cockpit.kpis.svsAccount.kpi.info": "",
  "screen.cockpit.kpis.svsAccount.kpi.label": "",
  "screen.cockpit.kpis.svsAccount.kpi.name": "",
  "screen.cockpit.kpis.taxAccount.kpi.arrears": "Neporavnani računi",
  "screen.cockpit.kpis.taxAccount.kpi.credit": "Kreditna opomba",
  "screen.cockpit.kpis.taxAccount.kpi.label": "Stanje na dan {datum}",
  "screen.cockpit.kpis.taxAccount.kpi.lastCompletedPayment": "",
  "screen.cockpit.kpis.taxAccount.kpi.name": "Bilanca davčnega računa",
  "screen.cockpit.kpis.unpaidAccount.kpi.lastCompletedPayment": "",
  "screen.cockpit.kpis.unpaidAccounts.kpi.caption": "",
  "screen.cockpit.kpis.unpaidAccounts.kpi.label": "",
  "screen.cockpit.kpis.unpaidAccounts.kpi.name": "",
  "screen.cockpit.navbar.heading": "Kokpit",
  "screen.cockpit.overview.kpis.title": "Dejstva in številke",
  "screen.cockpit.overview.newestPublications": "Nedavne objave",
  "screen.cockpit.overview.notifications.dueDates": "Odprti roki",
  "screen.cockpit.overview.notifications.messages": "Novice",
  "screen.cockpit.overview.notifications.tickets": "Poizvedbe",
  "screen.cockpit.overview.notifications.title": "Obvestila",
  "screen.cockpit.overview.promotions.title": "Novice",
  "screen.cockpit.overview.relevantNews.title": "Pomembne novice",
  "screen.cockpit.overview.relevantPublications.description": "Zahtevate lahko tiskano publikacijo TPA, ki vam bo poslana v nekaj dneh. Ali: prenesete lahko elektronsko različico (PDF) želene publikacije TPA.",
  "screen.cockpit.overview.relevantPublications.title": "Ustrezne publikacije",
  "screen.cockpit.promotion": "Novice: članek",
  "screen.cockpit.promotion.additionalNews": "Novice",
  "screen.cockpit.relevantNews": "Novice: Člen",
  "screen.cockpit.relevantNews.additionalNews": "Več novic",
  "screen.cockpit.tickets.navbar.heading": "Odprte poizvedbe",
  "screen.hr.additionaldata.addBonus": "Dodajte še en dodatek",
  "screen.hr.additionaldata.additionaldata": "Dodatne informacije",
  "screen.hr.additionaldata.additionalInformation": "Dodatne informacije",
  "screen.hr.additionaldata.amount": "Znesek v {valuti} na mesec",
  "screen.hr.additionaldata.amount_error": "Vnesite znesek",
  "screen.hr.additionaldata.bankDetails": "Bančni podatki",
  "screen.hr.additionaldata.bic": "BIC",
  "screen.hr.additionaldata.bic.validation": "Nepravilen BIC",
  "screen.hr.additionaldata.commuterAllowance": "",
  "screen.hr.additionaldata.costCenter": "Stroškovno središče",
  "screen.hr.additionaldata.costCenterNote": "Opomba o stroškovnem mestu",
  "screen.hr.additionaldata.description": "Naslednje informacije se lahko po potrebi izpolnijo tudi ob začetku opravljanja storitev.",
  "screen.hr.additionaldata.designator": "Nadomestilo",
  "screen.hr.additionaldata.designator_error": "Prosimo, navedite nadomestilo",
  "screen.hr.additionaldata.emailAddress": "E-poštni naslov",
  "screen.hr.additionaldata.email_error": "Vnesite pravilen e-poštni naslov",
  "screen.hr.additionaldata.familyBonusPlusAmount": "",
  "screen.hr.additionaldata.familyBonusPlusAmount_error": "",
  "screen.hr.additionaldata.funder": "Stroškovna enota",
  "screen.hr.additionaldata.gross": "Bruto",
  "screen.hr.additionaldata.hours_error": "Vnesite število med {minHours} in {maxHours}.",
  "screen.hr.additionaldata.iban": "IBAN",
  "screen.hr.additionaldata.iban_error": "Nepravilen IBAN",
  "screen.hr.additionaldata.jobDescription": "Naziv delovnega mesta",
  "screen.hr.additionaldata.kilometersForCommuterEuro": "",
  "screen.hr.additionaldata.kilometersForCommuterEuro_error": "",
  "screen.hr.additionaldata.kilometersForCommuterEuro_typeerror": "",
  "screen.hr.additionaldata.maritalStatus": "Zakonski stan",
  "screen.hr.additionaldata.monthlySalary": "Plača v {valuti} na mesec",
  "screen.hr.additionaldata.monthlySalary_error": "",
  "screen.hr.additionaldata.net": "Neto",
  "screen.hr.additionaldata.nextPage": "Nadaljuj z dokumenti",
  "screen.hr.additionaldata.number_error": "Prosimo, vnesite številko",
  "screen.hr.additionaldata.per": "na",
  "screen.hr.additionaldata.personaldata": "Osebni podatki",
  "screen.hr.additionaldata.religiousConfession": "Verska izpoved",
  "screen.hr.additionaldata.salarayAndPosition": "Prejemki in položaj",
  "screen.hr.additionaldata.saveChanges": "Shranjevanje sprememb",
  "screen.hr.additionaldata.soleEarnerStatus": "",
  "screen.hr.additionaldata.titelAndAcademicDegrees": "Nazivi in akademske stopnje",
  "screen.hr.additionaldata.titlePrefix": "Predponski obrazec",
  "screen.hr.additionaldata.titleSuffix": "Pod",
  "screen.hr.additionaldata.wageAgreementClassification": "Kolektivna pogodba o razvrstitvi",
  "screen.hr.additionaldata.weeklyWorkDays": "Število delovnih dni na teden",
  "screen.hr.additionaldata.weeklyWorkDays_error": "",
  "screen.hr.additionaldata.workingHours": "Število ur",
  "screen.hr.additionaldata.workingHoursInterval": "Obdobje",
  "screen.hr.additionaldata.workingTimes": "Delovni čas",
  "screen.hr.cancelRegistration.dataSaved": "Če prekličete, bodo vneseni podatki ostali shranjeni. Registracijo lahko nadaljujete pozneje.",
  "screen.hr.cancelRegistration.header": "Prekliči registracijo",
  "screen.hr.cancelRegistration.question1": "Ste prepričani, da želite preklicati postopek registracije? Doslej vnesene podatke lahko uporabite za",
  "screen.hr.cancelRegistration.question2": " in ga predložite svojemu davčnemu svetovalcu TPA v registracijo.",
  "screen.hr.deregistration.data.navback": "Osebje / Zaposleni / Registrirani / {prvo ime} {lastno ime}",
  "screen.hr.deregistration.documents.navback": "Osebje / Zaposleni / Registrirani / {prvo ime} {lastno ime} / Podatki o izbrisu iz registracije",
  "screen.hr.deregistration.navheader": "{prvo ime} {lastno ime} odjavite se",
  "screen.hr.deregistration.validation.deregistrationDate": "Vnesite datum izstopa",
  "screen.hr.deregistration.validation.deregistrationDateMin": "Datum izstopa mora biti daljši od datuma vstopa.",
  "screen.hr.deregistration.validation.deregistrationReason": "Navedite razlog za odhod",
  "screen.hr.deregistration.validation.remainingHolidays": "Navedite preostali dopust",
  "screen.hr.deregistration.validation.remainingOvertime": "Navedite, koliko nadur je še odprtih",
  "screen.hr.documents.citizenship": "Potrdilo o državljanstvu",
  "screen.hr.documents.contract": "Storitevpogodba",
  "screen.hr.documents.description": "naložite dodatne dokumente, kot so: pogodba o zaposlitvi, osebna izkaznica, dokazilo o državljanstvu, obrazec za registracijo ali drugi dokumenti.",
  "screen.hr.documents.emptystate.buttonlabel": "Prenesite dokumente",
  "screen.hr.documents.emptystate.message": "Za tega zaposlenega ni na voljo nobenih dokumentov",
  "screen.hr.documents.emptystate.title": "Dokumenti niso na voljo",
  "screen.hr.documents.enterManually": "Ročni vnos podatkov",
  "screen.hr.documents.idcard": "Osebnaosebna izkaznica",
  "screen.hr.documents.logTime": "Rekordni časi",
  "screen.hr.documents.needsRelease.notReleased": "Čakanje na sprostitev",
  "screen.hr.documents.needsRelease.released": "objavljeno",
  "screen.hr.documents.needsRelease.released.tooltip": "izdano dne {releasedAt} s strani {naslova}",
  "screen.hr.documents.nextButton": "Za povzetek",
  "screen.hr.documents.nonworkingtimes.emptystate.message": "Zdaj začnite nalagati datoteke ali pa ročno vnesite čase, ko se ne izvajajo.",
  "screen.hr.documents.others": "Drugo",
  "screen.hr.documents.registrationslip": "Sporočilolist",
  "screen.hr.documents.uploadDocuments": "Prenesite dokumente",
  "screen.hr.edit.flow.navback": "Osebje / Zaposleni / TODO: Status / {prvo ime} {lastno ime}",
  "screen.hr.edit.flow.navheader": "{prvo ime} {lastno ime} uredi",
  "screen.hr.employee.current.all": "Vse",
  "screen.hr.employee.current.anyDateOfJoining": "Katerikoli datum vnosa",
  "screen.hr.employee.current.anyDateOfJoiningExiting": "Katerikoli datum vstopa/izstopa",
  "screen.hr.employee.current.cancellation": "Izbris iz registracije",
  "screen.hr.employee.current.changeUsername": "",
  "screen.hr.employee.current.changeUsername.successDialog.message": "",
  "screen.hr.employee.current.changeUsername.successDialog.title": "",
  "screen.hr.employee.current.changeUsername.tooltip": "",
  "screen.hr.employee.current.dataChange": "Spremembe matičnih podatkov",
  "screen.hr.employee.current.notTransferred": "Ni poslano",
  "screen.hr.employee.current.registration": "Registracije",
  "screen.hr.employee.deregistration.data.date": "Datum izstopa",
  "screen.hr.employee.deregistration.data.header": "Podatki o odjavi",
  "screen.hr.employee.deregistration.data.reason": "Razlog za odhod",
  "screen.hr.employee.deregistration.data.remainingHolidays": "Preostali dopust v dnevih",
  "screen.hr.employee.deregistration.data.remainingOvertime": "Odprte nadure",
  "screen.hr.employee.deregistration.data.subtitle": "Če so na voljo, naložite izstopne dokumente za končno poravnavo.",
  "screen.hr.employee.deregistration.documents.submitButton": "Odprava registracije prenosa",
  "screen.hr.employee.deregistration.stepper.data": "Podatki o odjavi",
  "screen.hr.employee.deregistration.stepper.documents": "Izstopni dokumenti",
  "screen.hr.employee.details.addFiles": "Dodajanje dokumentov",
  "screen.hr.employee.details.currentEmployment": "Trenutno delovno razmerje",
  "screen.hr.employee.details.deregisterEmployee": "Izbris zaposlenega iz registra",
  "screen.hr.employee.details.employments": "Delovna razmerja",
  "screen.hr.employee.details.fileProvidedBy": "ki ga zagotavlja {name}",
  "screen.hr.employee.details.fileReleased": "Vidno za zaposlene",
  "screen.hr.employee.details.linkedFiles": "Povezane datoteke",
  "screen.hr.employee.details.navBack": "Osebje / {list}",
  "screen.hr.employee.details.pastEmployments": "Prejšnja zaposlitev",
  "screen.hr.employee.details.payrollAccount.navBack": "",
  "screen.hr.employee.details.personnelFile": "Osebna mapa",
  "screen.hr.employee.details.personnelNumber": "Številka osebja:",
  "screen.hr.employee.details.records": "Dokazilo o zaslužku",
  "screen.hr.employee.details.reregisterEmployee": "Ponovna registracija zaposlenega",
  "screen.hr.employee.details.showAll": "Pokaži vse",
  "screen.hr.employee.details.showEmployeePayrollAccount": "",
  "screen.hr.employee.details.status": "Status",
  "screen.hr.employee.details.weeklyWorkDays": "{weeklyWorkDays} dni na teden",
  "screen.hr.employee.details.workingHours": "{workingHours} ur na {workingHoursInterval};",
  "screen.hr.employee.documents.all": "Vse",
  "screen.hr.employee.documents.annualPayslips": "Letni plačilni list",
  "screen.hr.employee.documents.anyDate": "Katero koli obdobje",
  "screen.hr.employee.documents.backTargetDescription": "Osebje / Zaposleni / {status} / {prvo ime} {lastno ime}",
  "screen.hr.employee.documents.certificateOfCitizenship": "Dokazilo o državljanstvu",
  "screen.hr.employee.documents.certificatesOfRegistration": "Potrdila o registraciji",
  "screen.hr.employee.documents.contracts": "Pogodba o storitvah",
  "screen.hr.employee.documents.deregisterDocument": "Dokumenti za izbris iz registracije",
  "screen.hr.employee.documents.employeeDocuments": "Datoteke od {prvo ime} {lastno ime}",
  "screen.hr.employee.documents.employmentPermit": "Delovno dovoljenje",
  "screen.hr.employee.documents.generalDocuments": "Drugo",
  "screen.hr.employee.documents.identityCard": "Osebna izkaznica",
  "screen.hr.employee.documents.payslips": "Dokazilo o zaslužku",
  "screen.hr.employee.emptystate.buttonlabel": "Zaposleni v evidenci",
  "screen.hr.employee.emptystate.message": "Začnite registrirati nove zaposlene zdaj",
  "screen.hr.employee.emptystate.title": "Registracija zaposlenih",
  "screen.hr.employee.former.anyDateOfLeaving": "Vsak datum izstopa",
  "screen.hr.employee.registration.stepper.additional": "Dodatne informacije",
  "screen.hr.employee.registration.stepper.documents": "Prenesite dokumente",
  "screen.hr.employee.registration.stepper.personal": "Osebni podatki",
  "screen.hr.employee.registration.stepper.status": "Korak {activeStep} od {totalSteps}",
  "screen.hr.employee.registration.stepper.summary": "Povzetek",
  "screen.hr.employees.current.registerEmployee": "Registracija zaposlenega",
  "screen.hr.employees.current.uploadPayslip": "",
  "screen.hr.employees.documents.recordType.title": "",
  "screen.hr.employees.documents.title": "",
  "screen.hr.navbar.documents": "Datoteke",
  "screen.hr.navbar.employees": "Zaposleni",
  "screen.hr.navbar.heading": "Osebje",
  "screen.hr.navbar.overview": "Pregled",
  "screen.hr.navbar.tabbar.current": "Trenutni DN",
  "screen.hr.navbar.tabbar.documents": "Datoteke",
  "screen.hr.navbar.tabbar.employees": "Zaposleni",
  "screen.hr.navbar.tabbar.former": "Upokojeni DN",
  "screen.hr.navbar.tabbar.inprogress": "V teku",
  "screen.hr.navbar.tabbar.preRegistartionTickets": "Poizvedbe ({preRegistrationTicketCount})",
  "screen.hr.overview.card.analysis": "Objava mesečnih ocen za",
  "screen.hr.overview.card.billing": "Objava mesečnih poročil za",
  "screen.hr.overview.card.globalAnalysis": "Objava ocen na ravni podjetja",
  "screen.hr.overview.card.globalBilling": "Objava poravnav na ravni podjetja",
  "screen.hr.overview.card.overview": "Razkritje mesečnih podatkov o osebju za",
  "screen.hr.overview.card.registration": "Registracija/odjava zaposlenih",
  "screen.hr.overview.finished.message": "{salutation}, {br} {br} Zahvaljujem se vam za preverjanje in potrditev plačilne liste. V nadaljevanju najdete vaša poročila.{br} {br} S spoštovanjem, {br}{podpis}",
  "screen.hr.overview.registration": "{{br} {br} prosimo, da nas obvestite o morebitnih spremembah uporabnikov storitev, da bomo lahko izvedli registracijo in izbris iz registracije.{br} {br} Z vsem spoštovanjem,{br} {podpis}",
  "screen.hr.overview.registration.button": "Registracija/odjava zaposlenih",
  "screen.hr.overview.ticketsWelcome.inProgress": "{salutation}, {br} {br}Za posredovanje osebnih podatkov se vam zahvaljujemo. Med obdelavo se je pojavilo nekaj vprašanj:",
  "screen.hr.overview.welcome.closed": "{salutation}, {br} {br} Zahvaljujemo se vam za posredovanje vseh ustreznih kadrovskih podatkov. Obdelali jih bomo v skladu z dogovorjenimi roki in vas kontaktirali v primeru kakršnih koli vprašanj.",
  "screen.hr.overview.welcome.inProgress": "{{br} {br} Prosim vas, da mi posredujete vse potrebne podatke o zaposlenih za plačilno listo {obdobje}{datum plačila}. {br}{br}S spoštovanjem,{br}{podpis}",
  "screen.hr.personaldata.address": "Ulica in hišna številka",
  "screen.hr.personaldata.addressAddition": "Končnica naslova",
  "screen.hr.personaldata.addressHead": "Naslov",
  "screen.hr.personaldata.area.validation": "Navedite lokacijo",
  "screen.hr.personaldata.birthdate.validation": "Vnesite datum rojstva",
  "screen.hr.personaldata.citizenship": "Državljanstvo",
  "screen.hr.personaldata.citizenship.validation": "Prosimo, navedite državljanstvo",
  "screen.hr.personaldata.city": "Lokacija",
  "screen.hr.personaldata.country": "Država",
  "screen.hr.personaldata.country.validation": "Izberite državo",
  "screen.hr.personaldata.dateOfBirth": "Datum rojstva",
  "screen.hr.personaldata.dateOfJoining": "Datum vnosa",
  "screen.hr.personaldata.description": "Naslednji podatki so obvezni za registracijo pred začetkom opravljanja storitve.",
  "screen.hr.personaldata.diverse": "Potapljači",
  "screen.hr.personaldata.employmentInsuranceType": "Zavarovalniška skupina",
  "screen.hr.personaldata.employmentRelationship": "Delovno razmerje",
  "screen.hr.personaldata.employmenttypeheader": "Vrsta zaposlitve",
  "screen.hr.personaldata.employmenttypeinformationbody": "Vsi bruto mesečni dohodki, ki presegajo prag nizkega dohodka, se štejejo za obvezno polno zavarovane.",
  "screen.hr.personaldata.employmenttypeinformationhead": "Popolnoma zavarovano / mejno zavarovano:",
  "screen.hr.personaldata.employmenttype.validation": "Prosimo, izberite delovno razmerje",
  "screen.hr.personaldata.entrydate.invalid": "Nepravilen datum vnosa",
  "screen.hr.personaldata.entrydate.validation": "Prosimo, vnesite datum vnosa",
  "screen.hr.personaldata.female": "Ženske",
  "screen.hr.personaldata.firstname": "Ime in priimek",
  "screen.hr.personaldata.firstname.validation": "Prosimo, vnesite ime",
  "screen.hr.personaldata.fullTime": "Popolnoma zavarovano",
  "screen.hr.personaldata.houseNumber": "Številka hiše",
  "screen.hr.personaldata.houseNumber.validation": "Prosimo, vnesite hišno številko",
  "screen.hr.personaldata.insurancedata": "Podatki o zavarovanju",
  "screen.hr.personaldata.insurancedatainformationbody": "Če ni številke SI, pustite polje za številko nacionalnega zavarovanja prazno.",
  "screen.hr.personaldata.insurancedatainformationhead": "Namig:",
  "screen.hr.personaldata.insurancetype.validation": "Izberite zavarovanje",
  "screen.hr.personaldata.lastname": "Priimek",
  "screen.hr.personaldata.lastname.validation": "Prosimo, vnesite priimek",
  "screen.hr.personaldata.male": "Moški",
  "screen.hr.personaldata.marginally": "Manjši",
  "screen.hr.personaldata.navback": "Osebje / zaposleni",
  "screen.hr.personaldata.navheader": "Registracija zaposlenih",
  "screen.hr.personaldata.nextpage": "Dodatne informacije",
  "screen.hr.personaldata.notSpecified": "Ni določeno",
  "screen.hr.personaldata.personaldata": "Osebni podatki",
  "screen.hr.personaldata.personalinformation": "Osebni podatki",
  "screen.hr.personaldata.previouslyEmployed": "Ali je bila ta oseba v zadnjih 12 mesecih pri vas kdaj zaposlena?",
  "screen.hr.personaldata.socialsecuritynumber.validation": "Navedite številko nacionalnega zavarovanja",
  "screen.hr.personaldata.ssn": "Številka nacionalnega zavarovanja",
  "screen.hr.personaldata.state.validation": "Prosimo, vnesite zvezno državo",
  "screen.hr.personaldata.streetnr.validation": "Vnesite naslov",
  "screen.hr.personaldata.workplaceState": "Stanje delovnega mesta",
  "screen.hr.personaldata.zipCode": "POŠTNA KODA",
  "screen.hr.personaldata.zipcode.length.validation": "",
  "screen.hr.personaldata.zipcode.validation": "Vnesite poštno številko",
  "screen.hr.records.move.multiple.dialog.title": "Premikanje datotek",
  "screen.hr.records.move.single.dialog.title": "Premaknite datoteko",
  "screen.hr.records.navbar.tab.list": "Datoteke ({count})",
  "screen.hr.records.navbar.tab.tickets": "Poizvedbe ({count})",
  "screen.hr.records.transfer.dialog.text": "Ali želite prenesti <b>vse datoteke in informacije o plačah</b> za obdobje {periodStart} - {periodEnd} v TPA za knjiženje?",
  "screen.hr.records.transfer.dialog.textRecordType": "Ali želite prenesti <b>vse datoteke in informacije iz \"{imena}\"</b> za obdobje {periodStart} - {periodEnd} v TPA za knjiženje?",
  "screen.hr.records.transfer.dialog.title": "Prenos datotek",
  "screen.hr.records.types.header": "Osebje",
  "screen.hr.records.upload.button": "Prenesite datoteke",
  "screen.hr.records.upload.navbar.back": "Osebje / Datoteke",
  "screen.hr.recordTypes.title": "Izberite vrsto dokumentov",
  "screen.hr.registration.flow.navback": "Korak nazaj",
  "screen.hr.registration.flow.navheader": "{prvo ime} {lastno ime} prijavite se",
  "screen.hr.summary.additionalinformation": "Dodatne informacije",
  "screen.hr.summary.bonuses": "Nadomestila",
  "screen.hr.summary.changeData": "Dodaj na seznam",
  "screen.hr.summary.completeAdditionalData": "Navedli ste vse dodatne informacije.",
  "screen.hr.summary.completePersonalData": "Vnesli ste vse obvezne podatke za prijavo v regionalno zdravstveno blagajno.",
  "screen.hr.summary.day": "Dan",
  "screen.hr.summary.daysPerWeek": "Dnevi na teden",
  "screen.hr.summary.description": "Preverite, ali so predložene informacije pravilne, da jih lahko v naslednjem koraku predložite agenciji za zagotavljanje prihrankov.",
  "screen.hr.summary.dialogQuestion1": "Ali želite",
  "screen.hr.summary.dialogQuestion2": " in ga pošljete svojemu davčnemu svetovalcu TPA v registracijo?",
  "screen.hr.summary.documents": "Datoteke",
  "screen.hr.summary.gender": "Spol",
  "screen.hr.summary.grossSalary": "Bruto plača v {valuti}",
  "screen.hr.summary.hours": "Ure",
  "screen.hr.summary.incompleteAdditionalData": "Še niste vnesli vseh dodatnih podatkov. Upoštevajte, da bodo nekateri dodatni podatki morda potrebni do prvega izplačila plače. Te lahko dodate kadar koli.",
  "screen.hr.summary.month": "Mesec",
  "screen.hr.summary.name": "{prvo ime} {lastno ime}",
  "screen.hr.summary.netSalary": "Neto plača v {valuti}",
  "screen.hr.summary.noPreregistration": "Osnovni podatki niso bili spremenjeni. Prenos podatkov ni potreben.",
  "screen.hr.summary.openDialog": "Oddaja vloge",
  "screen.hr.summary.summary": "Povzetek",
  "screen.hr.summary.transferDataChange": "Posredovanje spremembe matičnih podatkov",
  "screen.hr.summary.transferEmployeeDialog.success.subtitle": " je bil ustvarjen in predložen davčnemu svetovalcu TPA v registracijo.",
  "screen.hr.summary.transferEmployeeDialog.success.subtitle.elda": "{name} je bil ustvarjen. Protokol ELDA si lahko ogledate in prenesete spodaj. Protokol lahko prenesete tudi iz osebne mape zaposlenega.",
  "screen.hr.summary.week": "Teden",
  "screen.login.button.forgot_password": "POZABLJENO GESLO?",
  "screen.login.button.resendCode": "Ponovno pošljite kodo",
  "screen.login.form.divider": "ali",
  "screen.login.form.multiFactorAuth.label": "Koda",
  "screen.login.form.multiFactorAuth.validation_error": "Vnesite kodo.",
  "screen.login.form.password.label": "Geslo",
  "screen.login.form.password.validation_error": "Vnesite geslo",
  "screen.login.form.submit": "Prijavite se",
  "screen.login.form.title": "Prijavite se",
  "screen.login.form.username.label": "ID uporabnika / e-poštni naslov",
  "screen.login.form.username.validation_error": "Vnesite svoje uporabniško ime",
  "screen.login.multiFactorAuth": "Vnesite kodo, ki smo jo poslali na vašo telefonsko številko prek sporočila SMS.",
  "screen.login.welcome": "Dobrodošli na našem spletnem mestu! Vnesite svoj e-poštni naslov.",
  "screen.module.overview.report.empty.message": "",
  "screen.module.overview.report.empty.title": "",
  "screen.module.overview.report.upload": "",
  "screen.moduleReportUpload.comment": "",
  "screen.moduleReportUpload.title": "",
  "screen.not_found.button.text": "Izstrelitev iz pilotske kabine",
  "screen.not_found.header": "Napaka 404",
  "screen.not_found.message": "S številkami smo res dobri, vendar smo vam to želeli prihraniti.",
  "screen.overview.salutation.female": "Spoštovana gospa {lastno ime}",
  "screen.overview.salutation.general": "Spoštovane gospe in gospodje",
  "screen.overview.salutation.male": "Spoštovani gospod {lastno ime}",
  "screen.overview.salutation.notSpecified": "Dober dan {prvo ime} {lastno ime}",
  "screen.records.upload.header": "Prenos datoteke {recordType}",
  "screen.records.upload.message": "Zdaj začnite nalagati prve datoteke.",
  "screen.records.upload.title": "Naložite {recordType}",
  "screen.server_error.button.text": "V pilotsko kabino",
  "screen.server_error.header": "505 Napaka",
  "screen.server_error.message": "S številkami smo res dobri, vendar smo vam to želeli prihraniti.",
  "screen.termsNotAccepted.button": "Nazaj na pregled podjetja",
  "screen.termsNotAccepted.buttonAcceptNow": "",
  "screen.termsNotAccepted.header": "Sprejemanje pogojev uporabe v pričakovanju",
  "screen.termsNotAccepted.message": "Pogoje uporabe vašega podjetja še niso podpisale vse odgovorne strani. Če imate kakršna koli vprašanja, se obrnite na svojega svetovalca TPA.",
  "screen.termsOfUse.accept": "Znak",
  "screen.termsOfUse.alreadySignedInfo": "Pogoji uporabe so bili že sprejeti ali pa sprejetje ni več potrebno.",
  "screen.termsOfUse.backToLogin": "Nazaj na prijavo",
  "screen.termsOfUse.checkbox": "Sprejemam pogoje uporabe",
  "screen.termsOfUse.success.sign": "Pogoji uporabe so bili uspešno sprejeti",
  "screen.termsOfUse.title": "Sprejmite pogoje uporabe",
  "screen.termsOfUseWithCompanyNames.title": "Sprejmite pogoje uporabe za podjetja [šteti]",
  "screen.termsOfUseWithCompanyName.title": "Sprejmite pogoje uporabe za {imenom podjetja}",
  "screen.tickets.navbar.tabbar.closed": "Končano",
  "screen.tickets.navbar.tabbar.open": "Odpri",
  "screen.tickets.navbar.tabbar.reported": "Zagotavljam jaz",
  "screen.upgradeToMicrosoft.error.azureUserAlreadyExists": "",
  "screen.upgradeToMicrosoft.feature.projects.text": "Za dostop do funkcije \"Projekti\" nadgradite svoj račun.",
  "screen.upgradeToMicrosoft.form.code.description": "Vnesite kodo, ki smo vam jo poslali s sporočilom SMS, da nadaljujete z Microsoftovo nadgradnjo.",
  "screen.upgradeToMicrosoft.form.code.label": "Koda",
  "screen.upgradeToMicrosoft.form.password.label": "Geslo",
  "screen.upgradeToMicrosoft.form.password.validation_error": "Prosimo, vnesite svoje geslo",
  "screen.upgradeToMicrosoft.needCompany": "Povezovanje z računom MS je mogoče opraviti le, če je izbrano podjetje.",
  "screen.upgradeToMicrosoft.resendButton.label": "Ponovno pošljite kodo",
  "screen.upgradeToMicrosoft.title": "Povezava Microsoftovega računa",
  "search.caption.numCompanies": "Podjetje: <b>{število}</b>",
  "search.caption.numDespositedPersons": "Deponirane osebe: <b>{count}</b>",
  "search.caption.numDocuments": "Datoteke: <b>{count}</b>",
  "search.caption.numPersons": "Osebe: <b>{count}</b>",
  "search.caption.numProjects": "Projekti: <b>{število}</b>",
  "search.caption.numResults": "Rezultati: <b>{count}</b>",
  "search.caption.numSelected": "Izbrani vnosi: <b>{število}</b>",
  "search.caption.numTickets": "Poizvedbe: <b>{count}</b>",
  "search.caption.numUploads": "Nalaganje: <b>{count}</b>",
  "search.caption.numUsers": "Uporabnik: <b>{count}</b>",
  "search.placeholder.companies": "Podjetja iščejo ...",
  "search.placeholder.projects": "iskanje projekta ...",
  "search.placeholder.search": "Iskanje ...",
  "search.placeholder.searchForActiveUsers": "Iskanje aktivnih uporabnikov po imenu...",
  "search.placeholder.searchForEmployees": "iskanje imen...",
  "search.placeholder.searchForEmployeesPersonalNumber": "iskanje imen ali številk osebja...",
  "search.placeholder.searchForFileName": "Poiščite ime datoteke...",
  "search.placeholder.searchForFiles": "Iskanje imen datotek...",
  "search.placeholder.searchForUsers": "iskanje imen...",
  "search.placeholder.searchForUsersFileName": "Iskanje po imenu ali imenu datoteke...",
  "selectQesPosition.message": "Izberite položaj za podpis in kliknite V redu.",
  "selectQesPosition.message.collective": "Za vsakega uporabnika izberite položaj za njegov podpis in kliknite Naprej.",
  "selectQesPosition.title": "Izberite položaj podpisa",
  "settings.navbar.company": "Podjetje",
  "settings.navbar.companyData": "Osnovni podatki",
  "settings.navbar.features": "",
  "settings.navbar.general": "Splošno",
  "settings.navbar.optionalSideBarItems": "Izbirni elementi menija",
  "settings.navbar.userManagement": "Upravljanje uporabnikov",
  "settings.notifications.activateAll": "AKTIVIRAJTE VSE",
  "settings.notifications.assignments": "Dodelitve",
  "settings.notifications.deactivateAll": "DEAKTIVIRAJTE VSE",
  "settings.notifications.deadlineExceeded": "Zamujeni rok",
  "settings.notifications.deadlineReminder": "Opomnik o roku",
  "settings.notifications.generalDocumentUpload": "Naložite splošne datoteke",
  "settings.notifications.label": "Obvestila po e-pošti, ko:",
  "settings.notifications.messages": "Novice",
  "settings.notifications.modules.accounting": "",
  "settings.notifications.modules.hr": "",
  "settings.notifications.modules.other": "",
  "settings.notifications.project.items.uploaded": "",
  "settings.notifications.recordProvision": "Nalaganje odjemalca",
  "settings.notifications.release": "Sprostitve",
  "settings.notifications.remindAfterHours.label": "Pomnilnik po {hours}h",
  "settings.notifications.reportProvision": "Zagotavljanje poročila",
  "settings.notifications.tickets": "Poizvedbe",
  "settings.notifications.title": "Obvestila",
  "settings.personalData.email": "E-poštni naslov",
  "settings.personalData.language": "Jezik",
  "settings.personalData.location": "Lokacija",
  "settings.personalData.microsoftAccount": "Microsoftov račun",
  "settings.personalData.microsoftUpgrade.info": "Microsoftov račun:{br}{br}Račun TPA Connect lahko povežete z Microsoftovim računom. Tako lahko uporabljate vse funkcije storitve TPA Connect.{br}{br}Ko boste svoj račun povezali z Microsoftom, se boste morali namesto z e-poštnim naslovom prijaviti z Microsoftovim računom.",
  "settings.personalData.microsoftUpgrade.label": "NADGRADNJA",
  "settings.personalData.phoneNumber": "Telefonska številka",
  "settings.personalData.role": "Roll",
  "settings.personalData.title": "Osebni podatki",
  "settings.personalData.username": "Uporabniško ime",
  "settings.userManagement.permissions.existingUser.navbar.back": "Upravljanje uporabnikov / Dodelitev vloge",
  "settings.userManagement.permissions.existingUser.navbar.backToUser": "Upravljanje uporabnikov / Dodelitev vloge / {naziv zaposlenega}",
  "settings.userManagement.permissions.navbar.back": "Upravljanje uporabnikov / Izbira kontaktne osebe / Dodelitev vloge",
  "settings.userManagement.permissions.navbar.backToUser": "Upravljanje uporabnikov / Izberite kontaktno osebo / Dodelitev vloge / {naziv zaposlenega}",
  "settings.userManagement.roles.navbar.back": "Upravljanje uporabnikov / Izberite kontaktno osebo",
  "sidebar.advisor.employees": "Seznam osebja",
  "sidebar.advisor.inbox": "poštni predal",
  "sidebar.advisor.tickets": "Poizvedbe",
  "sidebar.advisor.uploads": "Nalaganje",
  "sidebar.customerView": "Pogled stranke",
  "sidebar.faceToFace.button": "Pišite na",
  "sidebar.faceToFace.title": "Iz oči v oči z",
  "sidebar.list.accounting": "Računovodstvo",
  "sidebar.list.analysis": "",
  "sidebar.list.cockpit": "Kokpit",
  "sidebar.list.documents": "Poročila",
  "sidebar.list.faceToFace": "Iz oči v oči",
  "sidebar.list.hr": "Osebje",
  "sidebar.list.kpi": "Dejstva in številke",
  "sidebar.list.personnelFile": "Osebna mapa",
  "sidebar.list.projects": "Projekti",
  "sidebar.list.results": "Vrednotenja",
  "sidebar.list.settings": "Nastavitve",
  "sidebar.list.support": "Podpora",
  "sidebar.list.tickets": "Poizvedbe",
  "signDialog.noQesEnabled.message": "S tem potrjujem izdajo dokumenta.",
  "signDialog.noQesRequired.button.click": "Delite",
  "signDialog.noQesRequired.button.qes": "Elektronski podpis",
  "signDialog.noQesRequired.message": "Pri odobritvi lahko izbirate med odobritvijo s klikom in elektronskim podpisom.",
  "signDialog.qesblocked.message": "Podpisovanje z elektronskim podpisom trenutno ni mogoče, saj je postopek podpisovanja v tem trenutku že začela druga oseba. Poskusite znova čez približno 15 minut.",
  "signDialog.qesblocked.title": "Izdaja trenutno ni mogoča",
  "signDialog.qesRequired.confirm": "V redu, podpišite elektronsko",
  "signDialog.qesRequired.message": "Za sprostitev je bil zahtevan elektronski podpis. Zdaj boste preusmerjeni na elektronski podpis.",
  "signDialog.title": "Izdaja",
  "soleEarnerStatus.Alleinerzieher": "",
  "soleEarnerStatus.Alleinverdiener": "",
  "soleEarnerStatus.Nein": "",
  "staff.documents.navbar.heading": "Moje datoteke",
  "staff.personnelFile.navbar.heading": "Pozdravljeni {imeno}!",
  "stepper.optional": "neobvezno",
  "stepper.progress": "Napredek",
  "support.admins.title": "Administratorji",
  "support.filter.option.activeChats": "Samo aktivni pogovori",
  "support.filter.option.allChats": "Vsi pogovori",
  "support.filter.option.inactiveChats": "Samo neaktivni pogovori",
  "support.filter.setting.showInactiveColleagues": "Prikaži neaktivne zaposlene",
  "support.inactiveEmployee": "Nekdanji zaposleni",
  "support.partners.title": "Partnerji",
  "support.privateChat.dialog.markAsPrivate.confirm": "Označi kot zaupno",
  "support.privateChat.dialog.markAsPrivate.message": "Če je klepet označen kot zaupen, si <b>druga oseba</b> ne more ogledati klepeta in odgovarjati na sporočila v vaši odsotnosti.{br}{br}O tej nastavitvi <b>odjemalec <b>ne bo obveščen</b>.",
  "support.privateChat.dialog.markAsPrivate.title": "Označite klepet kot zaupen?",
  "support.privateChat.dialog.markAsPublic.confirm": "Odpoved zaupnosti",
  "support.privateChat.dialog.markAsPublic.message": "Ko klepet ni več označen kot zaupen, lahko <b>druge osebe</b> pregledujejo klepet in odgovarjajo na sporočila v vaši odsotnosti.{br}{br}O tej nastavitvi <b>odjemalec ni obveščen</b>.",
  "support.privateChat.dialog.markAsPublic.title": "Klepeta ne morete več označiti kot zaupnega?",
  "support.privateChat.info": "Klepet je označen kot <b>zaupen</b> in ga drugi ne morejo videti.",
  "support.privateChat.info.markAsPublic": "DVIGNITE SE NAVZGOR",
  "support.privateChat.info.mobile": "Klepet je <b>zaupen</b>.",
  "support.privateChat.placeholder": "Vnesite sporočilo ...",
  "support.responsible.title": "Člani ekipe",
  "support.search.navbar.title": "Rezultati iskanja",
  "support.substitute.author": "{substituteName} (nadomestek za {authorName})",
  "support.substitute.back": "NAZAJ NA MOJ KLEPET",
  "support.substitute.back.mobile": "MOJ KLEPET",
  "support.substitute.warning": "<b>Pozor:</b> Ste v klepetu <b>{naslov}</b>.",
  "support.substitute.warning.mobile": "Klepet z <b>{naslov}</b>",
  "support.yourContacts": "Vaše kontaktne osebe",
  "svsAccount.transactions.table.contextMenu.download": "",
  "svsAccount.transactions.table.contextMenu.show": "",
  "svsAccount.transactions.table.filters.quarter": "",
  "svsAccount.transactions.table.label.amount": "",
  "svsAccount.transactions.table.label.quarter": "",
  "svsAccount.transactions.table.label.text": "",
  "svsAccount.transactions.table.search.count": "",
  "svsAccount.transactions.table.search.placeholder": "",
  "svsAccount.transactions.title": "",
  "table.button.release": "IZPUSTITE",
  "table.employees.dateOfJoining": "Datum vnosa",
  "table.employees.dateOfJoiningExiting": "Datum vstopa/izstopa",
  "table.employees.dateOfLeaving": "Datum izstopa",
  "table.employees.dateOfTransmitting": "Poslano na",
  "table.employees.documents.date": "Datum",
  "table.employees.documents.document": "Datoteka",
  "table.employees.documents.documentName": "Poimenovanje",
  "table.employees.documents.downloadDocuments": "Datoteke za prenos",
  "table.employees.documents.uploadedBy": "Naložil/a ga je",
  "table.employees.employee": "Zaposleni",
  "table.employees.jobDescription": "Naziv delovnega mesta",
  "table.employees.missingDetails": "Manjkajoči podatki",
  "table.employees.personalId": "Številka osebja",
  "table.employees.reasonForLeaving": "Razlog za odhod",
  "table.employees.status": "Status",
  "table.employees.status.active": "Aktivno",
  "table.employees.status.inactive": "Neaktivno",
  "table.employees.status.inDataChange": "Sprememba osnovnih podatkov",
  "table.employees.status.inDataChangeNotTransferred": "Sprememba (osnutek)",
  "table.employees.status.inDeregistration": "Pri izbrisu iz registracije",
  "table.employees.status.inDeregistrationNotTransferred": "Izbris iz registracije (osnutek)",
  "table.employees.status.inRegistration": "Pri registraciji",
  "table.employees.status.inRegistrationNotTransferred": "Vloga (osnutek)",
  "table.label.changes": "Spremembe",
  "table.label.changeUsername": "Pošlji e-pošto za spremembo uporabniških imen",
  "table.label.comment": "Komentar:",
  "table.label.companyName": "Ime podjetja",
  "table.label.contactPerson": "Pišite na",
  "table.label.contactPersonId": "Osebna številka",
  "table.label.costCenter": "Stroškovno središče",
  "table.label.createdAt": "Datum nalaganja",
  "table.label.createdAt.variant": "Ustvarjeno na",
  "table.label.createdBy": "",
  "table.label.date": "Datum",
  "table.label.dateOfJoining": "Datum vnosa",
  "table.label.dateOfJoiningExiting": "Datum vstopa/izstopa",
  "table.label.dateOfLeaving": "Datum izstopa",
  "table.label.dateOfTransmitting": "Poslano na",
  "table.label.deleteUser": "Izbriši uporabnika",
  "table.label.document": "Datoteka",
  "table.label.documentName": "Poimenovanje",
  "table.label.documentType": "Datoteka",
  "table.label.downloadDocuments": "Datoteke za prenos",
  "table.label.dueDate": "Rok",
  "table.label.dueDateForDraft": "Rok za sprostitev",
  "table.label.email": "E-poštni naslov",
  "table.label.employee": "Zaposleni",
  "table.label.file": "Datoteka",
  "table.label.fileName": "Poimenovanje",
  "table.label.folder": "Mapa",
  "table.label.holiday": "Počitnice",
  "table.label.info": "Info",
  "table.label.jobDescription": "Naziv delovnega mesta",
  "table.label.lastAuthenticatedAt": "",
  "table.label.lastAuthenticatedAt.registered.tooltip": "",
  "table.label.lastAuthenticatedAt.unregistered.tooltip": "",
  "table.label.lastCreated": "Nazadnje ustvarjen",
  "table.label.members": "Člani",
  "table.label.message": "Sporočilo",
  "table.label.messages": "Novice",
  "table.label.missingDetails": "Manjkajoči podatki",
  "table.label.modulePermissions": "Dovoljenja na ravni območja",
  "table.label.modules": "Področja",
  "table.label.needsRelease": "Zahteva se sprostitev",
  "table.label.openReleases": "Odprte izdaje",
  "table.label.otherAbsence": "Druga odsotnost",
  "table.label.personalId": "Številka osebja",
  "table.label.personalNumber": "Številka osebja",
  "table.label.phoneNumber": "Telefonska številka",
  "table.label.reasonForLeaving": "Razlog za odhod",
  "table.label.recordedBy": "Posneto iz",
  "table.label.release": "Sprostitve",
  "table.label.released": "Poslano",
  "table.label.releasedAt": "Poslano na",
  "table.label.releaseRequestedBy": "Izdaja, ki jo zagotavlja",
  "table.label.role": "Roll",
  "table.label.sentBy": "Poslano iz",
  "table.label.sickLeave": "Bolniški dopust",
  "table.label.status": "Status",
  "table.label.teamMessages": "Novice o ekipi",
  "table.label.ticket": "Posvetovanje",
  "table.label.ticketBy": "Vprašanje, ki ga je postavil",
  "table.label.ticketDescription": "Poimenovanje",
  "table.label.ticketDueDate": "Rok",
  "table.label.tickets": "Poizvedbe",
  "table.label.transferStatus": "Prenos",
  "table.label.transmittedAt": "Poslano na",
  "table.label.type": "Art",
  "table.label.updatedAt": "Nazadnje urejeno",
  "table.label.updatedAt.variant": "Nazadnje spremenjeno",
  "table.label.uploadedBy": "Naložil/a ga je",
  "table.label.username": "Uporabniško ime",
  "table.noResults.message": "Vaše iskanje ni prineslo nobenih rezultatov.",
  "table.noResults.title": "Ni rezultatov iskanja",
  "taxAccount.pay.button": "",
  "taxAccount.transactions.table.filters.date": "",
  "taxAccount.transactions.table.filters.tax": "",
  "taxAccount.transactions.table.label.amount": "",
  "taxAccount.transactions.table.label.date": "",
  "taxAccount.transactions.table.label.due": "",
  "taxAccount.transactions.table.label.taxPeriod": "",
  "taxAccount.transactions.table.label.text": "",
  "taxAccount.transactions.table.search.count": "",
  "taxAccount.transactions.table.search.placeholder": "",
  "taxAccount.transactions.title": "",
  "termsOfUse.checkbox.error": "Sprejmite pogoje uporabe",
  "termsOfUse.upcoming.navbar.button": "",
  "termsOfUse.upcoming.navbar.message": "",
  "ticketList.emptystate.title": "Poizvedbe niso na voljo",
  "unpaidAccounts.invoices.pay": "",
  "unpaidAccounts.invoices.table.filters.date": "",
  "unpaidAccounts.invoices.table.label.documentDate": "",
  "unpaidAccounts.invoices.table.label.documentNumber": "",
  "unpaidAccounts.invoices.table.label.documents": "",
  "unpaidAccounts.invoices.table.label.documentText": "",
  "unpaidAccounts.invoices.table.label.netAmount": "",
  "unpaidAccounts.invoices.table.label.opAmount": "",
  "unpaidAccounts.invoices.table.label.receiptDate": "",
  "unpaidAccounts.invoices.table.label.receiptNumber": "",
  "unpaidAccounts.invoices.table.label.receiptText": "",
  "unpaidAccounts.invoices.table.search.count": "",
  "unpaidAccounts.invoices.table.search.placeholder": "",
  "unpaidAccounts.invoices.title": "",
  "upload.category.analysis": "Poročilo: Mesečna ocena",
  "upload.category.annualReports": "Letni računovodski izkazi",
  "upload.category.articles": "Elaborati",
  "upload.category.billing": "Poročilo: Mesečno poročilo",
  "upload.category.certificateOfCitizenship": "Dokazilo o državljanstvu",
  "upload.category.certificateOfRegistration": "Potrditev registracije",
  "upload.category.contractOfEmployment": "Pogodba",
  "upload.category.contracts": "Pogodbe",
  "upload.category.correspondence": "Korespondenca",
  "upload.category.dataChangeConfirmation": "Sprememba osnovnih podatkov",
  "upload.category.deregisterDocument": "",
  "upload.category.deregistrationConfirmation": "Izbris zaposlenega iz registra",
  "upload.category.employmentPermit": "Delovno dovoljenje",
  "upload.category.identityCard": "Osebna izkaznica",
  "upload.category.information": "Informacije",
  "upload.category.notices": "Obvestila",
  "upload.category.other": "Drugo",
  "upload.category.payslip": "",
  "upload.category.registrationConfirmation": "Registracija zaposlenih",
  "upload.category.report": "Poročila",
  "upload.category.taxOffice": "Davčni urad",
  "upload.category.taxReturn": "Davčne napovedi",
  "userManagement.successDialog.changeUsername.message": "<b>{uporabniško ime}</b> prejme zahtevo za potrditev na novo objavljenega e-poštnega naslova.",
  "userManagement.successDialog.deleteUser.single.message": "<b>{userName}</b> je bil uspešno izbrisan.",
  "userManagement.successDialog.deleteUser.title": "Uporabnik je bil uspešno izbrisan.",
  "userManagement.successDialog.existingUser.superAdmin.multi.message": "Sprejete so bile nastavitve za <b>{število} uporabnikov</b>.",
  "userManagement.successDialog.existingUser.superAdmin.single.message": "Sprejete so bile nastavitve za <b>{uporabniško ime}</b>.",
  "userManagement.successDialog.existingUser.superAdmin.title": "Uspešno shranjene uporabniške nastavitve",
  "userManagement.successDialog.existingUser.title": "Uspešno predlagane uporabniške nastavitve",
  "userManagement.successDialog.message": "Vaš predlog mora zdaj odobriti <b>upravitelj</b>.",
  "userManagement.successDialog.newUser.superAdmin.multi.message": "<b>{število} uporabnikov</b> prejme vabilo po e-pošti.",
  "userManagement.successDialog.newUser.superAdmin.single.message": "<b>{uporabniško ime}</b> prejme vabilo po e-pošti.",
  "userManagement.successDialog.newUser.superAdmin.title": "Uporabnik uspešno povabljen",
  "userManagement.successDialog.newUser.title": "Uporabnik je uspešno predlagal",
  "userManagement.successDialog.rejectUser.title": "Uporabnik je bil uspešno zavrnjen",
  "userManagement.successDialog.releaseExistingUser.title": "Uporabnik je bil uspešno izpuščen",
  "userManagement.successDialog.releaseNewUser.title": "Uporabnik je bil uspešno sproščen in povabljen",
  "userManagement.successDialog.sendUsernameChangeEmail.title": "Elektronsko sporočilo za spremembo uporabniškega imena je bilo uspešno poslano.",
  "userProfile.createBmdTodos": "Ustvarjanje opravil v BMD za poizvedbe",
  "userProfile.deleteAccount.message": "",
  "userProfile.deleteAccount.title": "",
  "userProfile.multiFactorAuth": "Omogočanje dvostopenjskega preverjanja pristnosti s telefonsko številko",
  "userProfile.navbar.heading": "Profil",
  "userProfile.upgrade.navbar.heading": "Nadgradnja",
  "userProfile.userInterests.title": "",
  "userReleaseInfoAdminDialog.releaseInfo.multi": "Sprostite nove uporabnike in jih povabite.",
  "userReleaseInfoAdminDialog.releaseInfo.single": "Potrdite novega uporabnika in ga povabite.",
  "userReleaseInfoAdminDialog.releaseMessage.multi": "{userName} je predložil <b>{count} novih uporabnikov</b>.",
  "userReleaseInfoAdminDialog.releaseMessage.single": "{userName} je predložil <b>{count} novemu uporabniku</b>.",
  "userReleaseInfoAdminDialog.submit": "Oglejte si in delite",
  "userReleaseInfoAdminDialog.title": "Skupna raba in povabilo uporabnikom",
  "userReleaseInfoDialog.cancel": "Za administracijo uporabnika",
  "userReleaseInfoDialog.from": "s spletne strani",
  "userReleaseInfoDialog.message": "Vaše predlagane spremembe za naslednje uporabnike so bile odobrene/zavrnjene:",
  "userReleaseInfoDialog.rejected": "Zavrnjeno",
  "userReleaseInfoDialog.released": "objavljeno",
  "userReleaseInfoDialog.title": "Sprememba uporabnika",
  "viewer.unsupported.message": "{name} trenutno ni mogoče prikazati.",
  "webhook.employee.noSearch": "Zahteva se vnos iskanja",
  "webhook.employee.placeholder": "Ime zaposlenega",
  "webhook.noCompanies": "Brez dovoljenj za podjetja",
  "webhook.tickets.button": "Ustvarite poizvedbo",
  "webhook.tickets.info": "Dokument pripišite območju in izberite kategorijo",
  "webhook.tickets.navbar": "Posvetovanje o {dokumentu}",
  "webhook.tickets.taskTitle": "Naloga za {ime}",
  "webhook.tickets.title": "Ustvarite poizvedbo",
  "webhook.uploads.navbar": "Prenos datotek",
  "welcomeCard.accounting.addMoreRecords.button": "Vnesite nadaljnje dokumente",
  "welcomeCard.accounting.addRecords.button": "Vnos dokumentov",
  "welcomeCard.hr.addMoreRecords.button": "Pošljite več datotek",
  "welcomeCard.hr.addRecords.button": "Prenos datotek",
  "texterify_timestamp": "2024-11-21T10:06:44Z"
} as const;

export { slovene };
