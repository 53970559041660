import { Button, TextField } from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import React, { useCallback, useState } from "react";
import { t } from "../../i18n/util";
import { customColors } from "../util/Theme";

const generateRandomCharacter = (): string => {
    // NOTE: Removed chars: I, l
    const characters = "ABCDEFGHJKLMNOPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz0123456789";
    return characters[Math.floor(Math.random() * characters.length)];
};

const generateCaptchaText = () => {
    // Captcha text is 6 characters long
    let text = "";
    for (let i = 0; i < 6; i++) {
        text += generateRandomCharacter();
    }
    return text;
};

// NOTE: This function may be buggy with some sizes. It's intended to be used with a 200x40 canvas, but it should work with other sizes as well.
const drawCaptchaOnCanvas = (ctx: CanvasRenderingContext2D, text: string) => {
    // Clear the canvas
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    const textColors = ["rgb(0,0,0)", "rgb(130,130,130)"];
    const letterSpace = ctx.canvas.width / (text.length + 1);

    // Add background noise lines
    for (let i = 0; i < 30; i++) {
        ctx.strokeStyle = `rgba(${Math.random() * 255}, ${Math.random() * 255}, ${Math.random() * 255}, 0.3)`;
        ctx.beginPath();
        ctx.moveTo(Math.random() * ctx.canvas.width, Math.random() * ctx.canvas.height);
        ctx.lineTo(Math.random() * ctx.canvas.width, Math.random() * ctx.canvas.height);
        ctx.stroke();
    }

    // Add text
    for (let i = 0; i < text.length; i++) {
        // Random scale
        const scale = 1 + Math.random() * 0.3; // Random scale between 1 and 1.3

        const minTextHeight = ctx.canvas.height * 0.6;
        const maxTextHeight = ctx.canvas.height * 0.8;

        // Random vertical position. y is between min and max height
        const x = i * letterSpace + 10;
        const y = Math.random() * (maxTextHeight - minTextHeight) + minTextHeight;

        // Random color and fontSize
        ctx.fillStyle = textColors[Math.floor(Math.random() * textColors.length)];
        ctx.font = `${Math.floor(ctx.canvas.height * 0.4) + Math.random() * 10}px Arial`;

        // Position and scale the text
        ctx.save();
        ctx.translate(x, y);
        ctx.scale(scale, scale);
        ctx.fillText(text[i], 0, 0);
        ctx.restore();
    }
};

export const useCaptcha = (canvasWidth = 200, canvasHeight = 40) => {
    const [captchaText, setCaptchaText] = useState("");
    const [captchaInput, setCaptchaInput] = useState("");
    const [success, setSuccess] = useState<boolean | null>(null);

    const [canvas, setCanvas] = React.useState<HTMLCanvasElement>();

    const initializeCaptcha = useCallback((ctx: CanvasRenderingContext2D | null | undefined) => {
        if (!ctx) {
            return;
        }

        setCaptchaInput("");

        const captchaText = generateCaptchaText();
        setCaptchaText(captchaText);
        drawCaptchaOnCanvas(ctx, captchaText);
    }, []);

    const handleCaptchaInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCaptchaInput(e.target.value);
    };

    const validateCaptcha = (): boolean => {
        if (captchaInput === captchaText) {
            setSuccess(true);
            return true;
        } else {
            setSuccess(false);
            return false;
        }
    };

    const canvasRefInitialize = useCallback(
        (canvas: HTMLCanvasElement | null) => {
            setCanvas(canvas ?? undefined);
            initializeCaptcha(canvas?.getContext("2d"));
        },
        [initializeCaptcha],
    );

    const captcha = (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "fit-content",
            }}
        >
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <canvas
                    ref={canvasRefInitialize}
                    width={canvasWidth}
                    height={canvasHeight}
                    style={{
                        border: "1px solid",
                        borderColor: success === false ? customColors.error : customColors.greyTextfields,
                        borderRadius: 4,
                    }}
                ></canvas>
                <Button
                    style={{
                        aspectRatio: "1",
                        height: canvasHeight,
                        minWidth: "0",
                    }}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        initializeCaptcha(canvas?.getContext("2d"));
                    }}
                >
                    <RefreshIcon style={{ fontSize: canvasHeight / 2 }} />
                </Button>
            </div>
            <TextField
                size="small"
                name="captchaInput"
                type="text"
                value={captchaInput}
                onChange={handleCaptchaInputChanged}
                placeholder={t("captcha.placeholderText")}
                variant="outlined"
                style={{ marginTop: "8px", width: "100%" }}
            />
            {success === false && <p style={{ color: customColors.error, marginTop: 4 }}>{t("captcha.invalid")}</p>}
        </div>
    );

    return { captcha, validateCaptcha };
};
