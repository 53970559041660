const czech = {
  "accounting.bankAccount.account.label": "Účet",
  "accounting.bankAccount.account.placeholder": "Účet",
  "accounting.bankAccount.account.title": "Vyberte účet",
  "accounting.bankAccount.transactions.assignInvoices.account.error": "Kategorie dokumentů nelze načíst",
  "accounting.bankAccount.transactions.assignInvoices.account.placeholder": "Kategorie dokumentů",
  "accounting.bankAccount.transactions.assignInvoices.filters.bankAccountTransactions.dateRange.required": "Vyberte datum zahájení a ukončení.",
  "accounting.bankAccount.transactions.assignInvoices.filters.bankAccountTransactions.title": "Bankovní transakce",
  "accounting.bankAccount.transactions.assignInvoices.filters.records.recordTypes.title": "Symbol dokumentu",
  "accounting.bankAccount.transactions.assignInvoices.filters.records.title": "Příjmy",
  "accounting.bankAccount.transactions.assignInvoices.records.empty": "Nebyly nalezeny žádné důkazy",
  "accounting.bankAccount.transactions.assignInvoices.records.pagination.next": "Další dokument",
  "accounting.bankAccount.transactions.assignInvoices.records.pagination.previous": "Předchozí dokument",
  "accounting.bankAccount.transactions.assignInvoices.subtitle": "Přiřaďte prosím účtenku k bankovní transakci.",
  "accounting.bankAccount.transactions.assignInvoices.title": "Přidělování poukazů",
  "accounting.bankAccount.transactions.table.action.assignOpenInvoices": "Přiřazení otevřených poukazů",
  "accounting.bankAccount.transactions.table.action.downloadBankStatement": "Export pro zaúčtování bankovních výpisů",
  "accounting.bankAccount.transactions.table.action.downloadBuerf": "Export pro rezervační import v NTCS",
  "accounting.bankAccount.transactions.table.emptyState.title": "Nebyly nalezeny žádné transakce",
  "accounting.bankAccount.transactions.table.label.amount": "Částka",
  "accounting.bankAccount.transactions.table.label.assignInvoice": "Přiřazení dokumentu",
  "accounting.bankAccount.transactions.table.label.date": "Datum rezervace",
  "accounting.bankAccount.transactions.table.label.invoice": "Příjem",
  "accounting.bankAccount.transactions.table.label.isPrivate": "Soukromé",
  "accounting.bankAccount.transactions.table.label.recipient": "Přijímač",
  "accounting.bankAccount.transactions.table.label.text": "Text rezervace",
  "accounting.bankAccount.transactions.table.label.valueDate": "Datum hodnoty",
  "accounting.bankAccount.transactions.table.menu.deleteAssignedInvoice": "Odstranění odkazu na dokument",
  "accounting.bankAccount.transactions.table.menu.markAsPrivate": "Označit jako soukromou transakci",
  "accounting.bankAccount.transactions.table.menu.removePrivate": "Zrušení soukromého označení",
  "accounting.bankAccount.transactions.table.search.count": "Transakce: <b>{počet}</b>",
  "accounting.bankAccount.transactions.table.search.placeholder": "Vyhledávání transakcí",
  "accounting.bankAccount.transactions.table.uploadInvoice.progress.title": "Nahrání účtenky",
  "accounting.bankAccount.transactions.table.uploadInvoice.subsidiaryAndRecordType.title": "Vyberte kód společnosti a symbol dokumentu",
  "accounting.bankAccount.transactions.title": "Transakce",
  "accounting.bankConnection.delete.message": "Opravdu chcete smazat bankovní účet {jméno}?",
  "accounting.bankConnection.delete.title": "Odstranění bankovních údajů",
  "accounting.bankConnections.accountHolder": "Majitel:",
  "accounting.bankConnections.accountType": "Typ účtu:",
  "accounting.bankConnections.accountType.Bausparen": "Stavební spoření",
  "accounting.bankConnections.accountType.Checking": "Běžný účet",
  "accounting.bankConnections.accountType.CreditCard": "Kreditní karta",
  "accounting.bankConnections.accountType.Loan": "Úvěrový účet",
  "accounting.bankConnections.accountType.Membership": "Družstevní akcie",
  "accounting.bankConnections.accountType.Savings": "Spořicí účet",
  "accounting.bankConnections.accountType.Security": "Účet cenných papírů",
  "accounting.bankConnections.addBankConnection": "Přidání bankovních údajů",
  "accounting.bankConnections.balance": "Zůstatek na účtu:",
  "accounting.bankConnections.emptyState.description": "Přidejte nový bankovní účet.",
  "accounting.bankConnections.emptyState.title": "Žádné bankovní údaje",
  "accounting.bankConnections.expired": "Vypršela platnost",
  "accounting.bankConnections.expiredAt": "Platnost vypršela dne {datum}",
  "accounting.bankConnections.expiresAt": "Platnost končí dne {date}",
  "accounting.bankConnections.iban": "IBAN:",
  "accounting.bankConnections.lastSuccessfulUpdate": "na {datum}",
  "accounting.bankConnections.pending": "Vynikající",
  "accounting.bankConnections.refreshBankConnection": "Obnovení bankovního účtu",
  "accounting.bankConnections.refreshPending": "Průběžné obnovy",
  "accounting.bankConnections.refreshPending.info": "Obnova bankovních údajů probíhá v samostatném okně. Pokud bylo okno zavřeno před dokončením aktualizace, musíte znovu kliknout na \"Obnovit bankovní údaje\".",
  "accounting.bankConnections.refreshPending.label": "Probíhá proces obnovy",
  "accounting.bankConnections.upToDate": "Potvrzeno",
  "accounting.details.edit.header": "Upravit dokument",
  "accounting.details.updatedAt": "naposledy změněno dne",
  "accounting.details.upload.files": "Soubory",
  "accounting.details.upload.skip": "Přeskočit vše",
  "accounting.finished.reports": "Zprávy",
  "accounting.table.label.totalGrossAmount": "Fakturovaná částka",
  "addFolderDialog.button.text.create": "Vytvořit",
  "addFolderDialog.folderName": "Název složky",
  "addFolderDialog.info": "Přejděte do služby Sharepoint a spravujte členy.",
  "addFolderDialog.title": "Vytvořit složku",
  "advisor.uploads.uploadReports.title": "Předávání zpráv",
  "analysis.powerBI.reports.table.label.description": "",
  "analysis.powerBI.reports.table.label.name": "",
  "analysis.powerBI.reports.table.search.count": "",
  "analysis.powerBI.reports.table.search.placeholder": "",
  "analysis.powerBI.reports.title": "",
  "analysis.powerBI.report.title": "",
  "appNotificationDialog.button.accept": "Rozumím",
  "appNotificationDialog.title.maintenance": "Údržba",
  "appNotificationDialog.title.newFeature": "Nové funkce",
  "appNotificationDialog.title.update": "",
  "branchType.allocationGroup": "Oblast osídlení",
  "branchType.costCenter": "Master nákladů",
  "branchType.department": "Oddělení",
  "branchType.financialAccountancyCircle": "Kruh FIBU",
  "branchType.other": "Další",
  "branchType.wageCompany": "Mzdová společnost",
  "branchType.workplace": "Pracoviště",
  "button.activateMfa": "Aktivovat nyní",
  "button.answer": "ODPOVĚĎ",
  "button.archive": "Archiv",
  "button.assign": "Přiřazení",
  "button.back_to_login": "Zpět na přihlášení",
  "button.closeTicket": "Označit jako hotové",
  "button.configure": "KONFIGURACE",
  "button.continueInBackground": "Pokračovat v pozadí",
  "button.continue_without_microsoft": "Pokračování bez účtu Microsoft",
  "button.copy": "Kopírovat/přesunout",
  "button.deactivateMfa": "Deaktivovat nyní",
  "button.enter": "VSTUPTE",
  "button.markAsPrivate": "Označit jako důvěrné",
  "button.moreInfo": "DALŠÍ INFORMACE",
  "button.move": "Přestěhujte se sem",
  "button.nextStep": "další krok",
  "button.paste": "Kopírovat zde",
  "button.refresh": "Obnovit",
  "button.release": "Sdílet",
  "button.submitArchiveMultiple": "{count} Archivní zprávy",
  "button.submitArchiveSingle": "{count} Archivní zpráva",
  "button.toOverview": "K přehledu",
  "button.transfer": "Vysílání",
  "button.unlock": "Odemknutí stránky",
  "button.upload": "Nahrát soubor",
  "captcha.invalid": "",
  "captcha.placeholderText": "",
  "chatInput.deleteLink": "Odstranit",
  "chatInput.editLink": "Upravit",
  "chatInput.enterLink": "Odkaz",
  "chatInput.openLink": "Otevřít adresu URL",
  "chatInput.saveLink": "Uložit",
  "common.abacus": "Abacus",
  "common.accounting": "Účetnictví",
  "common.accounting.period": "Doba rezervace",
  "common.accounting.recordType": "Symbol dokumentu",
  "common.accounting.subsidiary": "Kód společnosti",
  "common.add": "Přidat",
  "common.albanian": "Shqiptare",
  "common.all": "Všechny",
  "common.amount": "Číslo",
  "common.anyDateRange": "Jakékoli období",
  "common.apply": "Převzetí",
  "common.archive": "Archiv",
  "common.back": "Zpět",
  "common.bankConnection": "Bankovní údaje",
  "common.bankConnections": "Bankovní údaje",
  "common.bankContact": "Bankovní údaje",
  "common.bic": "BIC",
  "common.bmd": "BMD",
  "common.bulgarian": "Български",
  "common.cancel": "Zrušit",
  "common.cancelCaps": "CANCEL",
  "common.chat": "Chat",
  "common.clientNumber": "Číslo zákazníka",
  "common.cockpit": "Kokpit",
  "common.companies": "Společnost",
  "common.company": "Společnost",
  "common.companyNumber": "Číslo společnosti",
  "common.companyOverview": "Přehled společnosti",
  "common.companyRegisterNumber": "Registrační číslo společnosti",
  "common.continue": "Další",
  "common.country": "Země",
  "common.croatian": "Hrvatski",
  "common.czech": "Česky",
  "common.dateformat": "DD.MM.RRRR",
  "common.dateValidation": "Zadejte prosím platné datum",
  "common.defaultSignature": "Vaše TPA",
  "common.delete": "Odstranit",
  "common.done": "Připraveno",
  "common.download": "Stáhnout",
  "common.downloadAll": "",
  "common.dueDate": "Termín",
  "common.dueUntil": " nejpozději do {dueDate}",
  "common.emailAddress": "E-mailová adresa",
  "common.english": "English",
  "common.errorOccurred": "Došlo k chybě",
  "common.events": "Události",
  "common.files": "Soubory",
  "common.finished": "Dokončeno",
  "common.folder": "Složka",
  "common.from": "Z",
  "common.fullName": "{jméno} {příjmení}",
  "common.generalData": "Obecné údaje",
  "common.generalDocuments": "Obecné soubory",
  "common.german": "Deutsch",
  "common.global": "Celá společnost",
  "common.hr": "Mzdy",
  "common.hr.period": "Účetní období",
  "common.hrPreRegistrations": "Personál - předběžné záznamy",
  "common.hr.short": "Zaměstnanci",
  "common.hr.subsidiary": "Oblast osídlení",
  "common.hungarian": "Magyar",
  "common.invite": "Pozvánka",
  "common.kpi": "Fakta a čísla",
  "common.kpi.long": "Fakta a čísla",
  "common.later": "Později",
  "common.logout": "Odhlášení",
  "common.me": "I",
  "common.members": "Členové",
  "common.moreInformation": "dozvědět se více",
  "common.mr": "Pan {jméno}",
  "common.mrs": "Paní {jméno}",
  "common.name": "Název",
  "common.no": "Ne",
  "common.none": "žádné",
  "common.noSearchResults": "Nebyly nalezeny žádné výsledky",
  "common.notAvailable": "NEUPLATŇUJE SE",
  "common.notSpecified": "n.a.",
  "common.numberValidation": "Zadejte prosím platné číslo",
  "common.ok": "Ok",
  "common.optional": "(nepovinné)",
  "common.orderBy": "Seřadit podle",
  "common.partial": "částečně",
  "common.people": "Lidé",
  "common.polish": "Polski",
  "common.profile": "Profil",
  "common.project": "Projekt",
  "common.projects": "Projekty",
  "common.readingTimeMinutes": "Doba čtení: {čas} Min",
  "common.readingTimeMinutes.short": "{čas} Min",
  "common.recipient": "Přijímač",
  "common.refresh": "Obnovit",
  "common.releasedAt": "vydáno dne {datum}",
  "common.releasedAtBy": "vydáno dne {datum} společností {jméno}",
  "common.releaseRequired.plural": "{count} Požadované uvolnění",
  "common.releaseRequired.singular": "Je vyžadováno uvolnění",
  "common.remainingItems": "+{count} více",
  "common.remainingItemsUnknown": "Načíst více",
  "common.retry": "Zkuste to znovu",
  "common.romanian": "Românesc",
  "common.russian": "Русский",
  "common.save": "Uložit",
  "common.sendCaps": "ODESLAT",
  "common.serbian": "Српски",
  "common.settings": "Nastavení",
  "common.showAll": "Zobrazit všechny",
  "common.slovak": "Slovenská",
  "common.slovene": "Slovenski",
  "common.taskTitle": "Název",
  "common.taxNumber": "Daňové číslo",
  "common.termsOfUse": "Podmínky použití",
  "common.ticket": "Konzultace",
  "common.timePeriod": "Období",
  "common.timePeriod.olderThan1Month": "Starší než jeden měsíc",
  "common.timePeriod.olderThan1Week": "Starší než jeden týden",
  "common.timePeriod.olderThan1Year": "Starší než jeden rok",
  "common.timePeriod.olderThan6Months": "Starší než 6 měsíců",
  "common.to": "Až do",
  "common.tpaFirstName": "TPA",
  "common.tpaLastName": "Zaměstnanci",
  "common.unknown": "neznámý",
  "common.upcomingTermsOfUse": "Budoucí podmínky používání",
  "common.upload": "Nahrát",
  "common.uploadFiles": "Nahrávání souborů",
  "common.user": "Uživatelé",
  "common.vatNumber": "UID",
  "common.yes": "Ano",
  "commuterAllowance.IndividuellePendlerpauschale": "Individuální příspěvek na dojíždění",
  "commuterAllowance.KeinePendlerpauschale": "Žádný příspěvek na dojíždění",
  "commuterAllowance.MehrAls20kmKeinBus": "Více než 20 km, žádný autobus",
  "commuterAllowance.MehrAls40kmBus": "Více než 40 km, autobus",
  "commuterAllowance.MehrAls40kmKeinBus": "Více než 40 km, žádný autobus",
  "commuterAllowance.MehrAls60kmBus": "Více než 60 km, autobus",
  "commuterAllowance.MehrAls60kmKeinBus": "Více než 60 km, žádný autobus",
  "commuterAllowance.Mindestens20kmBus": "Nejméně 20 km, autobus",
  "commuterAllowance.Mindestens2kmKeinBus": "Nejméně 2 km, žádný autobus",
  "companiesUserReleaseInfoActionsBar.message": "Máte {počet} nových uživatelů ke sdílení",
  "companiesUserReleaseInfoActionsBar.submit": "Zobrazení a sdílení",
  "companiesUserRelease.permissions.navbar.back": "Sdílení a pozvání uživatelů / přiřazení role",
  "companiesUserRelease.title": "Sdílení a pozvání uživatelů",
  "companyImages.cover.error": "Obrázek na obálce nelze načíst",
  "companyImages.cover.title": "Obrázek na obálce",
  "companyImages.delete.message": "Opravdu chcete obrázek smazat?",
  "companyImages.delete.title": "Odstranit obrázek",
  "companyImages.logo.error": "Logo společnosti nelze načíst",
  "companyImages.logo.title": "Logo společnosti",
  "companyImages.title": "Obrázek na obálce a logo vaší společnosti (volitelné)",
  "companyList.remainingCompanies": "+{zbyléspolečnosti} Více na",
  "company.optionalSideBarMenuItems.maxLength.name": "Název může obsahovat pouze 15 znaků",
  "company.optionalSideBarMenuItems.nameAlreadyinUse": "Volitelná položka nabídky je již k dispozici",
  "company.optionalSideBarMenuItems.name.label": "Název",
  "company.optionalSideBarMenuItems.required.name": "Musí být uveden název",
  "company.optionalSideBarMenuItems.required.url": "Musí být zadána adresa URL",
  "company.optionalSideBarMenuItems.title": "Volitelné položky menu společnosti",
  "company.optionalSideBarMenuItems.tooltip.removeItem": "Odstranění položky nabídky",
  "company.optionalSideBarMenuItems.urlAlreadyinUse": "Odkaz na volitelnou položku nabídky již existuje",
  "company.optionalSideBarMenuItems.url.label": "ADRESA URL",
  "company.optionalSideBarMenuItems.urlNotValid": "Zadejte prosím platnou adresu URL (příklad: https://www.tpa-connect.app ).",
  "companyOverview.emptyState.message": "Obraťte se na svého poradce",
  "companyOverview.emptyState.title": "Žádná aktivní společnost",
  "companyOverview.emptyState.tpa.message": "V současné době nemáte žádné aktivní zákazníky",
  "companyOverview.emptyState.tpa.title": "Žádní aktivní zákazníci",
  "companyOverview.unlockCountry.tooltip": "Odemkněte nové země{br}{br} Odemkněte mezinárodní projekty z <b>{krajina}</b>, abyste mohli používat funkci projektů této společnosti a zobrazit všechny dotazy.",
  "companyStatus.accountingSetupDone": "0/2 hotovo",
  "companyStatus.active": "aktivní",
  "companyStatus.hrSetupDone": "1/2 hotovo",
  "companyStatus.inactive": "neaktivní",
  "companyStatus.userSetupDone": "2/2 hotovo",
  "config.accounting.accountingByCustomer": "Přenos účetních dat klienty prostřednictvím služby TPA Connect",
  "config.accounting.delete.subsidiaries.last.message": "Kód společnosti {jméno} nelze smazat. Musí existovat alespoň jeden kód společnosti.",
  "config.accounting.delete.subsidiaries.message": "Opravdu chcete odstranit kód společnosti {jméno}?",
  "config.accounting.delete.subsidiaries.title": "Odstranění kódu společnosti",
  "config.accounting.description": "Určete typ a rozsah účetní oblasti.",
  "config.accounting.description.customer": "Tuto oblast pro vás nakonfiguroval váš poradce TPA. Pokud si přejete provést jakékoli změny, obraťte se kdykoli na svého poradce TPA.",
  "config.accounting.due.accounting.reports.select.title": "Dny po předání účetnictví",
  "config.accounting.dueDates.title": "Předávání účetních dokladů",
  "config.accounting.due.reportRelease.select.subtitle": "Například 4 dny po předání zpráv od TPA. To znamená 12. den následujícího měsíce (pokud TPA předala hlášení 8. den následujícího měsíce).",
  "config.accounting.due.reportRelease.select.title": "Dny po předání zpráv",
  "config.accounting.due.reportRelease.select.value": "{day} Dny po předání zpráv",
  "config.accounting.due.reportRelease.title": "Vydávání zpráv zákazníky",
  "config.accounting.due.reports.select.subtitle": "Například 3 dny po předání účetnictví. To znamená 8. dne následujícího měsíce (pokud k předání účetnictví došlo 5. dne následujícího měsíce).",
  "config.accounting.due.reports.select.value": "{day} Dny po předání účtů",
  "config.accounting.due.reports.title": "Předávání zpráv prostřednictvím TPA",
  "config.accounting.due.transfer.select.subtitle": "Například 5. dne následujícího měsíce.",
  "config.accounting.due.transfer.select.title": "Přenos do",
  "config.accounting.due.transfer.select.value": "{day} Dny po konci období",
  "config.accounting.due.transfer.title": "Předávání účetnictví klienty",
  "config.accounting.globalReports.description": "Klient má k dispozici celopodnikové zprávy",
  "config.accounting.globalReports.title": "Celopodnikové zprávy",
  "config.accounting.hasAccounting": "Klient má účetnictví",
  "config.accounting.hasBankAccountTransactionInvoices": "Přiřazení dokladů k bankovním transakcím",
  "config.accounting.hasCockpitKpi": "Fakta a čísla v kokpitu",
  "config.accounting.hasKpi": "Fakta a čísla",
  "config.accounting.hasResults": "Hodnocení",
  "config.accounting.incaseoflaw.description": "Zákazník má funkci \"V případě práva",
  "config.accounting.incaseoflaw.label": "V případě práva",
  "config.accounting.incaseoflaw.title": "V případě práva",
  "config.accounting.month.description": "Zahrnuje dobu trvání jednoho měsíce",
  "config.accounting.month.title": "Měsíční účetnictví",
  "config.accounting.quarter.description": "Zahrnuje dobu trvání 3 měsíců",
  "config.accounting.quarter.title": "Účtování za čtvrtletí",
  "config.accounting.recordTypes": "Účetní doklady",
  "config.accounting.recordTypes.ocrDisabled": "Automatické rozpoznávání dokumentů (OCR) deaktivováno",
  "config.accounting.recordTypes.ocrEnabled": "Aktivované automatické rozpoznávání dokumentů (OCR)",
  "config.accounting.requireTwoReportReleases": "Schvalování zpráv dvěma uživateli (princip dvojí kontroly)",
  "config.accounting.subsidiaries.add": "Přidání kódu společnosti",
  "config.accounting.subsidiaries.title": "Definice kódů společností",
  "config.accounting.subsidiaries.title.customer": "Kódy společností",
  "config.accounting.subsidiary.maxReached": "Maximální počet dosažených kódů společností",
  "config.accounting.subsidiary.name": "Kód společnosti",
  "config.accounting.week.description": "Trvá 5 pracovních dnů a je rozdělena na kalendářní týdny.",
  "config.accounting.week.title": "Týdenní účetnictví",
  "config.bulk.button.configure": "Konfigurace",
  "config.bulk.data.description": "Zde můžete nahrát obrázek obálky a logo společností.",
  "config.bulk.data.title": "Základní údaje",
  "config.bulk.finish.description": "Společnosti jsou konfigurovány. To může trvat několik minut, nezavírejte prosím okno prohlížeče.",
  "config.bulk.finish.start": "Spuštění",
  "config.bulk.finish.title": "Dokončení konfigurace",
  "config.companies": "Konfigurace společnosti",
  "config.companies.buttonConfig": "Konfigurace společnosti",
  "config.companies.count": "{count} Společnost",
  "config.companies.description": "V tomto kroku určíte procesy, které jsou specifické pro danou společnost. Patří mezi ně vizuální vzhled v aplikaci TPA Connect, typ a rozsah účetnictví a mezd a také termíny.",
  "config.companyData.cockpit": "Kokpit",
  "config.companyData.description": "Zde můžete nahrát obrázek na obálku a logo společnosti a nastavit kritéria rezervace.",
  "config.companyData.title": "Základní údaje",
  "config.companyOverview.inactive": "Přehled společnosti / Neaktivní",
  "config.customMenuPoints.deleteCustomMenuPoint.confirmLabel": "Ano, vymazat",
  "config.customMenuPoints.deleteCustomMenuPoint.message": "Opravdu chcete tuto položku nabídky odstranit?",
  "config.customMenuPoints.deleteCustomMenuPoint.title": "Odstranit položku nabídky",
  "config.customMenuPoints.noMenuPointsAvailable": "Žádné položky menu nejsou k dispozici",
  "config.employees.assignRole": "Přiřazení role",
  "config.employees.emptyState.message": "V současné době nemáme žádné aktivní zaměstnance",
  "config.employees.emptyState.title": "Žádní aktivní zaměstnanci",
  "config.employees.table.missingEmail": "Chybějící e-mailová adresa",
  "config.employees.table.missingEmail.tooltip": "E-mailovou adresu musí do BMD zadat konzultant TPA.",
  "config.features.alreadyToggledToday.disabled": "Doplněk byl dnes již deaktivován.",
  "config.features.alreadyToggledToday.enabled": "Doplněk byl již dnes aktivován",
  "config.features.confirm.message.disabled": "Pokud doplněk aktivujete, bude zpoplatněný doplněk k dispozici okamžitě a bude vám ode dneška účtován. Doplněk můžete zítra opět deaktivovat.",
  "config.features.confirm.message.enabled": "Zpoplatněný doplněk bude na konci dne deaktivován. Za dnešní den vám bude stále účtován poplatek. Doplněk můžete znovu aktivovat od zítřka.",
  "config.features.confirm.title.disabled": "Aktivace doplňku",
  "config.features.confirm.title.enabled": "Deaktivace doplňku",
  "config.finishConfiguration": "Dokončení konfigurace",
  "config.finishConfiguration.cockpit": "Do kokpitu",
  "config.finishConfiguration.continue": "Vytvoření další společnosti",
  "config.finishConfiguration.emailNotification": "Upozornění uživatelů e-mailem",
  "config.finishConfiguration.message": "{společnost} byla úspěšně nakonfigurována. Nyní jej lze použít pro výměnu mezi TPA a klientem.",
  "config.finishSetup": "EXIT SETUP",
  "config.hr.chat.description": "Zaměstnanci mohou kontaktovat poradce TPA.",
  "config.hr.chat.title": "Chat",
  "config.hr.delete.subsidiaries.last.message": "Účetní oblast {název} nelze odstranit. Musí existovat alespoň jedna účetní oblast.",
  "config.hr.delete.subsidiaries.message": "Opravdu chcete odstranit účetní oblast {název}?",
  "config.hr.delete.subsidiaries.title": "Odstranění oblasti mezd",
  "config.hr.description": "Určete povahu a rozsah mzdové oblasti.",
  "config.hr.dueDates.reminder": "Připomenutí termínů",
  "config.hr.dueDates.title": "Lhůty pro mzdové účetnictví",
  "config.hr.due.relativeDueDates": "Dodání dokumentů v následujícím měsíci",
  "config.hr.due.reports.select.subtitle": "Například 3 dny po předání výplatní pásky. To znamená 8. den následujícího měsíce (pokud k předání mzdového účetnictví došlo 5. den následujícího měsíce).",
  "config.hr.due.reports.select.title": "Dny po předání výplatní pásky",
  "config.hr.due.reports.select.value": "{den} Dny po předání personálního výkazu",
  "config.hr.due.select.value": "{den}. v měsíci",
  "config.hr.due.transfer.select.subtitle": "Například 5. dne v měsíci.",
  "config.hr.due.transfer.select.value.relativeDueDates": "{day} Dny po skončení měsíce",
  "config.hr.due.transfer.title": "Předávání výplatních pásek zákazníky",
  "config.hr.hasHr": "Klient má mzdovou agendu",
  "config.hr.hasResults": "Analýzy",
  "config.hr.recordTypes": "Mzdové doklady",
  "config.hr.releaseSteps.oneStep.description": "Všechna měsíční hodnocení předává TPA klientovi v jednom kroku. Klient musí vydat všechny potřebné dokumenty ve stanoveném termínu.",
  "config.hr.releaseSteps.oneStep.title": "v jednom kroku",
  "config.hr.releaseSteps.title": "Kroky uvolnění",
  "config.hr.releaseSteps.twoSteps.description": "Předání a zveřejnění měsíčního hodnocení probíhá ve dvou krocích. Klient může uvolnit vyúčtování před uvolněním všech ostatních měsíčních hodnocení.",
  "config.hr.releaseSteps.twoSteps.title": "ve dvou krocích",
  "config.hr.requireTwoReportReleases": "Schvalování zpráv dvěma uživateli (princip dvojí kontroly)",
  "config.hr.sendPayroll.description": "Elektronické zasílání výkazů příjmů",
  "config.hr.sendPayroll.title": "Elektronická evidence příjmů",
  "config.hr.sendPayroll.tooltip": "Při vystavení výplatní pásky získá každý zaměstnanec automaticky přístup do aplikace TPA Connect, kde si může prohlédnout své vlastní záznamy o příjmech i elektronický osobní spis.",
  "config.hr.subsidiaries.add": "Přidání oblasti mezd",
  "config.hr.subsidiaries.info": "Po výběru první účetní oblasti lze vybírat pouze další stejného typu (např. hlavní náklady). Chcete-li vybrat nový typ (např. FIBU kruh), je třeba odstranit všechny účetní oblasti kromě jedné.",
  "config.hr.subsidiaries.title": "Určete oblasti výplat",
  "config.hr.subsidiaries.title.customer": "Účetní okruhy",
  "config.hr.subsidiary.maxReached": "Dosažený maximální počet účetních okruhů",
  "config.hr.subsidiary.name": "Název účetní oblasti",
  "config.hr.upload.description": "Zaměstnanci, kteří pobírají plat, mohou do svého osobního spisu nahrát dokumenty.",
  "config.hr.upload.title": "Nahrání dokumentů do osobního souboru",
  "config.hr.warning.noBranch": "Mzdy nelze aktivovat. Vytvořte prosím mzdovou společnost v NTCS",
  "config.moduleTypeDetails.asp.helpInstructions": "Pokyny pro zákazníky ASP",
  "config.moduleTypeDetails.asp.helpInstructions.open": "K pokynům",
  "config.moduleTypeDetails.label.database": "Databáze",
  "config.moduleTypeDetails.label.dataSource": "Zdroj dat",
  "config.moduleTypeDetails.label.url": "ADRESA URL",
  "config.noDueDate": "Žádný termín",
  "config.optionalMenuPoints.success.title": "Položky menu byly úspěšně uloženy",
  "config.overview": "Přehled konfigurace",
  "config.permission.change": "změněno na \"{povolení}\"",
  "config.permissions.accounting.globalReports": "Účetní výkazy pro celou společnost",
  "config.permissions.deleted": "(smazáno)",
  "config.permissions.description": "Vyberte oblasti, do kterých má uživatel povolen přístup. Pro každý kód společnosti můžete provést jiná nastavení.",
  "config.permissions.employees": "Zaměstnanci",
  "config.permissions.existingUser.navbar.back": "Přehled konfigurace / Správa uživatelů / Přiřazení role",
  "config.permissions.existingUser.navbar.backToUser": "Přehled konfigurace / Správa uživatelů / Přiřazení role / {jméno zaměstnance}",
  "config.permissions.global": "Celá společnost",
  "config.permissions.internationalProjects.info": "Projekty Oprávnění nelze odebrat: Členové mezinárodních projektů je dostávají automaticky.",
  "config.permissions.navbar.back": "Přehled konfigurace / Správa uživatelů / Výběr kontaktní osoby / Přiřazení role",
  "config.permissions.navbar.backToUser": "Přehled konfigurace / Správa uživatelů / Výběr kontaktní osoby / Přiřazení role / {employeeName}",
  "config.permissions.noPermissionSelected.message": "Vyberte alespoň jedno oprávnění.",
  "config.permissions.noPermissionSelected.title": "Nebylo vybráno žádné povolení",
  "config.permissions.title": "Přizpůsobení oprávnění",
  "config.roles.change": "Role změněna z {oldRole} na {newRole}",
  "config.roles.customizePermissions": "Přizpůsobení oprávnění",
  "config.roles.description": "Vyberte roli s předdefinovanou sadou oprávnění. V případě potřeby můžete oprávnění upravit v dalším kroku.",
  "config.roles.multipleCompaniesDialog.message": "Uživatel {jméno} existuje v následujících společnostech. Chcete provést změny oprávnění ve všech společnostech?",
  "config.roles.multipleCompaniesDialog.title": "Uplatnění změn v několika společnostech",
  "config.roles.multipleCompaniesDialog.warning": "Společnosti se nepodařilo načíst. Změny se vztahují pouze na aktuální společnost.",
  "config.roles.navbar.back": "Přehled konfigurace / Správa uživatelů / Výběr kontaktních osob",
  "config.roles.navbar.heading": "{employeeCount} Vybraný uživatel",
  "config.roles.option.copy.description": "Zadejte jméno uživatele, který převezme svou roli a oprávnění. V případě potřeby můžete oprávnění upravit v dalším kroku.",
  "config.roles.option.copy.label": "Převzetí role od jiného uživatele",
  "config.roles.option.copy.placeholder": "Uživatelské jméno",
  "config.roles.releaseInformation": "Aby bylo možné upravit nastavení uživatele <b>{jméno uživatele}</b>, musí změny schválit/odmítnout <b>superadmin</b>.",
  "config.roles.releaseInformation.info.message": "Pokud kdokoli (kromě superadmina) provede změny v nastavení uživatele, musí tyto změny uvolnit nebo zamítnout superadmin. Do té doby jsou nastavení uživatele uzamčena a nelze je upravovat.",
  "config.roles.releaseInformation.info.title": "Proč nelze upravit nastavení uživatele?",
  "config.roles.releaseInformation.superAdmin": "{jméno} provedl změny v <b>{jméno uživatele}</b>. Chcete-li pokračovat, potvrďte změny uživatele.",
  "config.roles.sidebar.title": "Zvolení uživatelé",
  "config.roles.submit": "Navrhnout uživatele",
  "config.roles.submit.existingUser": "Navrhnout změny",
  "config.roles.submit.superAdmin": "Pozvat uživatele",
  "config.roles.title": "Přiřazení role",
  "config.roles.viewPermissions": "Zobrazit oprávnění",
  "config.terms.allSigneesAcceptedWarningDialog.confirmLabel": "OK, uložit",
  "config.terms.allSigneesAcceptedWarningDialog.message": "Upozornění: Vzhledem k tomu, že všechny přidané osoby již podepsaly, bude společnost aktivována až po uložení.",
  "config.terms.allSigneesAcceptedWarningDialog.title": "Uložit podmínky použití",
  "config.terms.alreadySigned": "Podmínky používání jejich společnosti již byly podepsány.",
  "config.terms.button.revokeActivation": "Zrušení aktivace",
  "config.terms.changeDocumentVersionDialog.confirmLabel": "Ano, změna dokumentu",
  "config.terms.changeDocumentVersionDialog.message": "Pokud budete pokračovat, všechny již přidané e-mailové adresy musí znovu přijmout podmínky používání.",
  "config.terms.changeDocumentVersionDialog.title": "Změnit dokument",
  "config.terms.deleteEmailDialog.confirmLabel": "Ano, vymazat e-mailovou adresu",
  "config.terms.deleteEmailDialog.message": "Opravdu chcete e-mailovou adresu odstranit?",
  "config.terms.deleteEmailDialog.title": "Odstranění e-mailové adresy",
  "config.terms.documentVersion": "Verze dokumentu",
  "config.terms.emailAddressesInfo": "Přidejte e-mailové adresy požadovaných osob, které mají být e-mailem vyzvány k podpisu příslušných podmínek používání. Přidávejte pouze e-mailové adresy oprávněných signatářů! Podmínky používání se na níže uvedené e-mailové adresy zasílají až po dokončení konfigurace firmy a uživatelů.",
  "config.terms.emailAddressesTitle": "E-mailové adresy",
  "config.terms.emailAlreadyUsed": "Tato e-mailová adresa již byla přidána",
  "config.terms.invalidEmail": "Neplatná e-mailová adresa",
  "config.terms.noDocumentsAvailable": "V současné době nejsou k dispozici žádné dokumenty",
  "config.terms.noSigneesAvailable": "Zatím nejsou žádní signatáři",
  "config.termsOfUse.navbar.back": "Přehled konfigurace / základní údaje",
  "config.terms.removeEmail": "Odstranění e-mailové adresy",
  "config.terms.resendEmail.header": "Odeslaný e-mail",
  "config.terms.resendEmail.message": "Byl znovu odeslán e-mail s žádostí o přijetí podmínek používání",
  "config.terms.resendMail": "Opětovné odeslání e-mailu",
  "config.terms.revokeActivationDialog.confirmLabel": "ano, aktivace zrušena",
  "config.terms.revokeActivationDialog.message": "Jste si jisti, že chcete zrušit aktivaci společnosti? Pro opětovnou aktivaci společnosti musí všechny registrované osoby znovu podepsat podmínky používání.",
  "config.terms.revokeActivationDialog.title": "Zrušení aktivace",
  "config.terms.signedVersion": "podepsaná verze:",
  "config.terms.skip.button": "přeskočit",
  "config.terms.skip.dialog.message": "Uživatelé na straně klienta se mohou přihlásit až po přijetí podmínek používání. Do té doby je společnost dostupná pouze pro interní účely TPA v aplikaci Connect.",
  "config.terms.skip.dialog.title": "Informace",
  "config.terms.success.header": "Úspěšně uloženo",
  "config.terms.termsOfUseInfo": "Vyberte příslušnou verzi dokumentu",
  "config.terms.termsOfUseTitle": "Podmínky použití",
  "config.users": "Správa uživatelů",
  "config.users.active.title": "Aktivní uživatelé",
  "config.users.assignRole": "Přiřazení role",
  "config.users.buttonConfig": "Správa uživatelů",
  "config.users.changeUsername": "Nový e-mail",
  "config.users.changeUsername.tooltip": "Nová e-mailová adresa uživatele je {newUsername}. Kliknutím na tlačítko je uživatel vyzván k potvrzení nové e-mailové adresy.",
  "config.users.description": "Přiřazení uživatelů nebo skupin uživatelů k jednotlivým procesům. Určete, kdo a v jakém rozsahu má do kterých oblastí přístup.",
  "config.users.edit": "Změna",
  "config.users.emptyState.message": "V současné době nejsou žádní aktivní uživatelé{br}Chcete-li vytvořit superadmina, použijte BMD NTCS.",
  "config.users.emptyState.title": "Žádní aktivní uživatelé",
  "config.users.inviteAgain": "Znovu pozvat",
  "config.users.navBar.back": "Přehled konfigurace / správa uživatelů",
  "config.users.new": "Nový",
  "config.users.newUsers": "Vytvoření nového uživatele",
  "config.users.newUsers.description": "Vyberte kontaktní osoby, které chcete vytvořit jako uživatele služby TPA Connect. V dalším kroku můžete vybraným osobám přiřadit roli a v případě potřeby upravit předdefinovaná oprávnění.",
  "config.users.newUsers.title": "Vyberte kontaktní osobu",
  "config.users.reject": "Pokles",
  "config.users.release": "Sdílet",
  "config.users.releaseAndInvite": "Sdílet a pozvat",
  "config.users.table.invalidEmail": "Neplatná e-mailová adresa",
  "config.users.table.invalidEmail.tooltip": "E-mailovou adresu musí opravit konzultant TPA v BMD.",
  "config.users.waiting.title": "Čekání na vydání",
  "cookies.acceptAll": "Povolit všechny soubory cookie",
  "cookies.acceptFunctional": "Používejte pouze nezbytné soubory cookie",
  "cookies.cookieList": "Prohlášení o souborech cookie",
  "cookies.text": "Soubory cookie používáme k optimalizaci našich služeb. Další podrobnosti o této problematice najdete na {odkazu}.",
  "country.al": "Albánie",
  "country.at": "Rakousko",
  "country.bg": "Bulharsko",
  "country.cz": "Česká republika",
  "country.hr": "Chorvatsko",
  "country.hu": "Maďarsko",
  "country.me": "Černá Hora",
  "country.pl": "Polsko",
  "country.ro": "Rumunsko",
  "country.rs": "Srbsko",
  "country.si": "Slovinsko",
  "country.sk": "Slovensko",
  "deleteDialog.message.plural": "Opravdu chcete odstranit dokumenty <b>{dokumenty}</b>?{br}{br}Tuto akci nelze vrátit zpět.",
  "deleteDialog.message.singular": "Opravdu chcete odstranit dokument <b>{dokumenty}</b>?{br}{br}Tuto akci nelze vrátit zpět.",
  "deleteDialog.title": "Smazat dokumenty?",
  "deleteUser.message": "Opravdu chcete odstranit uživatele <b>{jméno}</b>?",
  "deleteUser.multipleCompanies.confirmLabel": "Odstranění uživatele ve všech společnostech",
  "deleteUser.multipleCompanies.message": "Uživatel je přítomen u následujících společností:",
  "deleteUser.title": "Odstranění uživatele",
  "deleteUser.warning.superadminskipped": "Pozor! Společnosti, ve kterých mají uživatelé roli Superadmin, jsou při mazání přeskočeny.",
  "dialog.archive.checkbox_pdf": "Lokální uložení PDF",
  "dialog.archive.subject": "Předmět",
  "dialog.archive.success.text": "Nyní máte přístup k archivovaným zprávám v {bmd}.",
  "dialog.archive.success.title": "Zprávy byly úspěšně archivovány!",
  "dialog.archive.text": "Vyberte společnost, pro kterou chcete vybrané zprávy uložit do BMD.",
  "dialog.archive.title_multiple": "{count} Archivní zprávy",
  "dialog.archive.title_single": "1 Archivní zpráva",
  "dialog.assignTicket.header": "Přiřazení dotazu",
  "dialog.assignTicket.message": "Vyberte osobu, které chcete konzultaci přiřadit.",
  "dialog.button.understood": "Rozumím",
  "dialog.closeTicket.success.header": "Konzultace byla provedena",
  "dialog.companyConfig.success.header": "Konfigurace společnosti byla úspěšně uložena.",
  "dialog.companyConfig.success.message": "Oblasti pro {jméno} jsou nyní připraveny.",
  "dialog.eldaTransfer.andNext": " a dále",
  "dialog.eldaTransfer.downloadLater": "Stáhnout později",
  "dialog.eldaTransfer.success.header": "Úspěšně podaná žádost",
  "dialog.loginError.multiFactorCodeWrong.title": "Zadaný kód je bohužel nesprávný",
  "dialog.loginError.subtitle": "Zkuste to prosím znovu.",
  "dialog.loginError.title": "Neznámá e-mailová adresa nebo nesprávné heslo",
  "dialog.noPhoneNumberWarning.message": "Alespoň jeden z vybraných kontaktů nemá nastavené telefonní číslo. Bez telefonního čísla není dvoufaktorové ověřování k dispozici a pozvánka nemusí být bezpečná.",
  "dialog.noPhoneNumberWarning.title": "Není nastaveno žádné telefonní číslo",
  "dialog.passwordExpired.message": "Platnost vašeho hesla vypršela a je třeba ho změnit. V nejbližší době obdržíte e-mail s žádostí o změnu hesla. V případě jakýchkoli dotazů kontaktujte TPA na čísle {telefonní číslo}. Děkujeme vám!",
  "dialog.passwordExpired.title": "Heslo vypršelo",
  "dialog.password_reset.success.header": "Heslo bylo úspěšně změněno",
  "dialog.registration.success.button": "Přihlaste se nyní",
  "dialog.registration.success.header": "Úspěšně jste se zaregistrovali!",
  "dialog.registration.success.message": "Chcete-li používat službu TPA Connect, přihlaste se pomocí služby Microsoft.",
  "dialog.transferReports.success.message": "Váš klient si nyní soubory prohlédne a bude vás kontaktovat s případnými dotazy.",
  "dialog.transfer.success.header": "Soubory byly úspěšně přeneseny",
  "dialog.userConfig.success.header": "Uložená uživatelská nastavení",
  "dialog.user_not_found.button": "Rozumím",
  "dialog.user_not_found.subtitle": "Kontaktujte správce systému nebo se přihlaste pomocí účtu TPA Connect.",
  "dialog.user_not_found.title": "Není znám žádný uživatelský účet s touto e-mailovou adresou",
  "documentUpload.error.amount": "<b>{{failedUploads}</b> z {uploads} souborů se nepodařilo nahrát:",
  "documentUpload.error.button.text.continue": "Pokračujte bez těchto souborů",
  "documentUpload.error.button.text.retry": "Zkuste to znovu",
  "documentUpload.error.message": "Chyba při nahrávání souborů",
  "documentUpload.error.rejection.amount": "<b>{{failedUploads}</b> z {uploads} soubory nelze nahrát:",
  "documentUpload.error.tooBigImages.amount": "<b>{failedUploads}</b> Obrázky jsou větší než max. {maxImageDimension} pixelů. Jejich velikost se automaticky zmenší. Pokračovat?",
  "documentUpload.error.tooBigImages.amount.singular": "Obrázek je větší než max. {maxImageDimension} pixelů. Jeho velikost se automaticky zmenší. Pokračovat?",
  "documentUpload.error.tooBigImages.button.text.continue": "Snížit a pokračovat",
  "documentUpload.error.tooBigImages.pdf": "Obrázky v PDF jsou větší než max. {maxImageDimension} pixelů. Zmenšete prosím velikost obrázků.",
  "documentUpload.error.tooManyFiles.plural": "Nahrát lze maximálně <b>{počet}</b> souborů:",
  "documentUpload.error.tooManyFiles.singular": "Nahrát lze maximálně <b>jeden</b> soubor:",
  "documentUpload.max.size": "Maximální velikost dokumentu {maxUploadSizeMb} Mb",
  "documentUpload.tooBigImages.message": "Příliš velké obrázky",
  "documentUpload.type.file.formats": "Podporované formáty",
  "dropzone.button.label.search": "hledat.",
  "dropzone.image.text": "Jednoduše přetáhněte obrázek do této oblasti nebo",
  "dropzone.info.redo": "Znovu nahrát",
  "dropzone.info.text": "Jednoduše přetáhněte soubory do této oblasti nebo",
  "dueDate.answerAt": "které mají být zodpovězeny do {data}",
  "dueDate.closedAt": "dokončeno dne {datum}",
  "dueDate.sentAt": "odesláno dne {datum}",
  "dueDate.sentAtWithTime": "odesláno dne {datum} v {čas}",
  "dueDate.transferredAt": "předáno dne {datum}",
  "dueDate.until": "do {data}",
  "employmentInsuranceType.apprentice": "Učeň",
  "employmentInsuranceType.fullTime": "plně pojištěné",
  "employmentInsuranceType.independentContractor": "DN zdarma",
  "employmentInsuranceType.marginalIndependentContractor": "Drobné volné DN",
  "employmentInsuranceType.marginally": "okrajově",
  "employmentRelationship.employee": "Zaměstnanci",
  "employmentRelationship.worker": "Pracovníci",
  "error.accounting.postSubsidiary": "Kód společnosti se nepodařilo vytvořit",
  "error.accounting.subsidiaryDoesNotExist": "Kód společnosti nebyl nalezen",
  "error.activate": "Chyba aktivace",
  "error.addBankAccountTransactionInvoices": "Transakce a doklady nelze propojit",
  "error.addBankConnection": "Bankovní údaje nebylo možné přidat",
  "error.addCompanyToUserFavorites": "Společnost nemohla být přidána do oblíbených",
  "error.addFolderBadRequest": "Složku se nepodařilo vytvořit. Ujistěte se, že jste nepoužili žádné nepovolené speciální znaky: \" * : < > ? / \\ |",
  "error.archiveDisabledForCompany": "Archivace není u této společnosti možná",
  "error.archiveFailed": "Chyba při archivaci",
  "error.archiveProjectFailed": "Projekt nelze archivovat",
  "error.avatarUploadFailed": "Profilový obrázek se nepodařilo nahrát. Ujistěte se, že formát je JPEG nebo PNG a velikost souboru je max. {maxSize}MB.",
  "error.avatarUploadOnlyOneImage": "Můžete nahrát pouze jeden obrázek",
  "error.changeUsernameEmail": "E-mail pro změnu uživatelského jména se nepodařilo odeslat",
  "error.changeUsername.newUsernameConflict": "Nový e-mail neodpovídá uloženému e-mailu.",
  "error.changeUsername.newUsernameDuplicate": "již používaný e-mail.",
  "error.changeUsername.noNewUsername": "Nebyla přiřazena žádná nová e-mailová adresa.",
  "error.changeUsername.tokenExpired": "Platnost tohoto odkazu vypršela.",
  "error.changeUsername.tokenNotFound": "Odkaz je neplatný, byla nová e-mailová adresa již potvrzena?",
  "error.chatForbidden": "U této společnosti není aktivována služba Face to Face.",
  "error.chatNoCompany": "Zpráva bez kontextu společnosti.",
  "error.chatNoRecipient": "Zpráva nemá příjemce.",
  "error.chatSearch": "Hledání se nezdařilo. Zkuste to prosím znovu.",
  "error.completeSign": "Elektronický podpis nebylo možné dokončit",
  "error.config.accounting.duplicate": "Zadali jste několik kódů společností se stejným názvem.",
  "error.config.accounting.noSubsidiary": "Pro pokračování zadejte alespoň jeden kód společnosti.",
  "error.config.activateCompanyFailed": "Tento zákazník dosud nebyl technicky připraven na používání služby TPA Connect. Obraťte se na IT podporu a požádejte o vytvoření vhodného uživatele.",
  "error.config.corruptSubsidiary": "Kód společnosti bez ID",
  "error.config.hr.duplicate": "Zadali jste několik oblastí mezd se stejným názvem.",
  "error.config.hr.noSubsidiary": "Pro pokračování nastavte alespoň jednu účetní oblast.",
  "error.copy.alreadyExists.file": "Chyba při kopírování: Soubor s tímto názvem již existuje",
  "error.copy.alreadyExists.folder": "Chyba při kopírování: Složka s tímto názvem již existuje",
  "error.copy.alreadyExists.unknown": "Chyba při kopírování: Složka nebo soubor s tímto názvem již existuje",
  "error.create": "Chyba při vytváření",
  "error.create.alreadyExists.project": "Projekt s tímto názvem již existuje",
  "error.createEmployee": "Zaměstnance se nepodařilo vytvořit",
  "error.delete": "Chyba při mazání",
  "error.deleteBankAccountTransactionInvoices": "Odkaz nelze odstranit",
  "error.deleteBankConnection": "Bankovní údaje nebylo možné vymazat",
  "error.delete.fileLocked": "Soubor je právě upravován, a proto jej nelze odstranit.",
  "error.deleteUser": "Uživatele nebylo možné odstranit. Zkuste to prosím později.",
  "error.deregisterEmployee": "Zaměstnance nelze odhlásit",
  "error.documentForbidden": "K dokumentu nemáte přístup",
  "error.documentInProgress": "Dokument se odesílá do TPA a nelze jej stáhnout. Zkuste to brzy znovu.",
  "error.documentNotFound": "Dokument nebyl nalezen",
  "error.documentsInProgress": "Dokumenty se odesílají do TPA a nelze je stáhnout. Zkuste to brzy znovu.",
  "error.download": "Chyba při stahování",
  "error.downloadTermsOfUseDocument": "Při stahování podmínek používání došlo k chybě.",
  "error.download.transferInProgress": "Dokument se přenáší. Zkuste to prosím znovu o něco později.",
  "error.dueDate.minDate": "Datum uzávěrky nesmí být v minulosti.",
  "error.duplicateName": "Již přiřazené jméno",
  "error.duplicateUsername": "Nově přidělená e-mailová adresa je již používána",
  "error.edit": "Chyba při úpravách",
  "error.employeeWithoutEmail": "Zaměstnanec nemá platnou e-mailovou adresu",
  "error.folderIsEmpty": "Složka je prázdná, a proto ji nelze stáhnout.",
  "error.foldersAreEmpty": "Všechny vybrané složky jsou prázdné, a proto je nelze stáhnout.",
  "error.forbidden": "Žádné povolení",
  "error.general": "Omlouváme se, došlo k chybě. Zkuste to prosím později.",
  "error.getSign": "Při načítání požadovaných informací pro elektronický podpis došlo k chybě.",
  "error.hr.noSubsidiarySelected": "Není vybrána žádná oblast mezd",
  "error.hr.postSubsidiary": "Oblast výplatních pásek se nepodařilo vytvořit",
  "error.hr.subsidiaryDoesNotExist": "Oblast výplatních pásek nebyla nalezena",
  "error.imageCrop": "Chyby při zpracování obrazu",
  "error.imageTooBig": "Příliš velký obrázek",
  "error.invalidCharacters": "Vstup obsahuje neplatné znaky",
  "error.invalidCompanyId": "Neplatné ID společnosti",
  "error.invalid_credentials": "Nesprávné ID uživatele nebo heslo",
  "error.invalidDateFormat": "Nesprávný formát data",
  "error.invalidEmployee": "Údaje o zaměstnanci jsou neúplné nebo nesprávné",
  "error.invalidPeriodId": "Neplatné období pro tuto společnost",
  "error.invalidRolePermissions": "Oprávnění rolí jsou neplatná",
  "error.invalidSsn": "Neplatné číslo národního pojištění",
  "error.invalidTicket": "Neplatný tip",
  "error.invalidWebhookParams": "Neplatné parametry webhooku.",
  "error.invitationNoCompany": "Pozvánka bez ID společnosti",
  "error.linkExpired": "Platnost tohoto odkazu vypršela. Požádejte o nový.",
  "error.linkInvalid": "Tento odkaz je neplatný.",
  "error.loadAccount": "Účet se nepodařilo načíst",
  "error.loadAccountListing": "Seznam zůstatků se nepodařilo načíst",
  "error.loadAccounts": "Účty se nepodařilo načíst",
  "error.loadAccountTransactions": "Rezervace nelze načíst",
  "error.loadArchiveDocuments": "Ne všechny archivní dokumenty bylo možné načíst",
  "error.loadBadges": "Chyba při načítání oznámení",
  "error.loadBalanceSheet": "Zůstatek se nepodařilo načíst",
  "error.loadBankAccountTransactions": "Transakce nelze načíst",
  "error.loadBankConnections": "Bankovní údaje se nepodařilo načíst",
  "error.loadBranches": "Větve se nepodařilo načíst",
  "error.loadCashAccounting": "Účet příjmů a výdajů nebylo možné načíst",
  "error.loadCompany": "Společnost nelze načíst",
  "error.loadCompanyFeatures": "Doplňky nelze načíst",
  "error.loadContacts": "Kontakty se nepodařilo načíst",
  "error.loadCostCenters": "Nákladová střediska nebylo možné načíst",
  "error.loadCountries": "Údaje o zemi se nepodařilo načíst",
  "error.loadCustomerListing": "Seznam dlužníků se nepodařilo načíst",
  "error.loadDestinations": "Místa obsazení se nepodařilo načíst. Zkuste to prosím později",
  "error.loadDocument": "Dokument se nepodařilo načíst",
  "error.loadDocuments": "Dokumenty se nepodařilo načíst",
  "error.loadDraft": "Vydání nelze načíst",
  "error.loadEmployee": "Zaměstnance nelze načíst",
  "error.loadEmployeeDocuments": "Soubory se nepodařilo načíst",
  "error.loadEmployees": "Zaměstnanci nemohli být pozváni",
  "error.loadEvents": "Události se nepodařilo načíst, zkuste to později",
  "error.loadFeatureFlags": "Příznaky funkcí nelze načíst",
  "error.loadFeedCountries": "Země se nepodařilo načíst.",
  "error.loadFinancialAccountancies": "",
  "error.loadFormData": "Data formuláře se nepodařilo načíst",
  "error.loadFunders": "Nákladovou jednotku nelze načíst",
  "error.loadGeneralDocumentsTimeout": "Zprávy jsou dočasně nedostupné. Zkuste to prosím později.",
  "error.loading": "Chyba načítání",
  "error.loadInsuranceCarrierBalance": "Chyba při načítání zůstatku zdravotního pojištění",
  "error.loadInsuranceCarriers": "Chyba při načítání fondů zdravotního pojištění",
  "error.loadInvitationResults": "Chyba při načítání uvolněných uživatelských změn",
  "error.loadKpiConfigurations": "Fakta a čísla Konfiguraci nelze načíst",
  "error.loadKpis": "Fakta a čísla se nepodařilo načíst, zkuste to později",
  "error.loadMessages": "Zprávy nelze načíst",
  "error.loadModuleTypeDetails": "Chyba při načítání podrobností modulu",
  "error.loadNews": "Zprávy se nepodařilo načíst, zkuste to později",
  "error.loadNews.noSource": "Zpravodajský kanál je v současné době nedostupný.",
  "error.loadNotifications": "Nastavení oznámení se nepodařilo načíst",
  "error.loadOptionalMenuItems": "Volitelné položky nabídky se nepodařilo načíst",
  "error.loadPayrollAccount": "Mzdový účet se nepodařilo načíst",
  "error.loadPayrollAccountDetails": "Údaje o mzdovém účtu se nepodařilo načíst",
  "error.loadPendingMicrosoftActions": "Informace o pozvánkách a občerstvení se nepodařilo načíst",
  "error.loadPeriod": "Chyba při načítání období",
  "error.loadPeriodData": "Chyba při načítání dat období",
  "error.loadPermissions": "Oprávnění nelze načíst",
  "error.loadPersonnelFile": "Personální soubor se nepodařilo načíst",
  "error.loadPersonnelFile.notStaff": "Uživatel není výdělečně činný",
  "error.loadPowerBIReportEmbedToken": "",
  "error.loadPowerBIReports": "",
  "error.loadPreRegistration": "Předběžný záznam se nepodařilo načíst.",
  "error.loadProfile": "Profil nelze načíst",
  "error.loadProfitAndLoss": "G&V nelze načíst",
  "error.loadProjects": "Projekty se nepodařilo načíst",
  "error.loadProjectsCompanies": "Firmy pro projekty nebylo možné načíst",
  "error.loadPromotions": "Položky nelze načíst, zkuste to později",
  "error.loadPublications": "Publikace nelze načíst, zkuste to později",
  "error.loadRecord": "Dokument se nepodařilo načíst",
  "error.loadRecords": "Účtenky nebylo možné načíst",
  "error.loadRecordTypes": "Chyba při načítání typů rezervací",
  "error.loadReports": "Zprávy nelze načíst",
  "error.loadResponsibleUsers": "Poradci nemohli být načteni",
  "error.loadRolePermissions": "Chyba při načítání oprávnění role",
  "error.loadSubsidiaries": "Chyba při načítání kódů společností",
  "error.loadSubsidiary": "Chyba při načítání kódu společnosti",
  "error.loadTeam": "Členy týmu nelze načíst",
  "error.loadTermsofUse": "Při načítání podmínek používání došlo k chybě",
  "error.loadTicketDetails": "Konzultaci nelze načíst",
  "error.loadTickets": "Dotazy se nepodařilo načíst",
  "error.loadUserCompanySettings": "Chyba při načítání uživatelských nastavení",
  "error.loadUsers": "Uživatele se nepodařilo načíst",
  "error.loadVendorListing": "Seznam prodejců se nepodařilo načíst",
  "error.loadWaitingUsers": "Chyba při načítání uživatelů, kteří mají být propuštěni",
  "error.loginAccountSetup": "Jste tu poprvé? Připravujeme váš uživatelský účet. Zkuste to prosím za několik minut znovu.",
  "error.logout": "Chyba odhlášení",
  "error.markAsDone": "Jako dokončené můžete označit pouze dotazy, které jste vytvořili.",
  "error.markBankAccountTransactionAsPrivate": "Transakci nelze označit jako soukromou",
  "error.maxCharacters": "Maximální povolený počet znaků",
  "error.mfaActivation.codeNotMatched": "Zadaný kód je chybný",
  "error.mfaCodeAlreadySent": "Kód již byl odeslán, před vyžádáním dalšího kódu vyčkejte alespoň 20 sekund.",
  "error.mfaCodeWrong": "Je nám líto, ale zadaný kód je nesprávný. Zkuste to prosím znovu.",
  "error.mfaSettingChange": "Funkci 2FA nelze aktivovat ani deaktivovat, protože již byl vyžádán kód pro aktivaci. Před dalším pokusem vyčkejte alespoň 20 sekund.",
  "error.mfaSettingChange.codeAlreadySent": "Nový kód již byl vyžádán. Před dalším pokusem vyčkejte alespoň 20 sekund.",
  "error.mfaSettingChange.codeNotMatched": "Zadaný kód je chybný",
  "error.microsoftLogin": "Při komunikaci se společností Microsoft došlo k problému. Zkuste se znovu přihlásit.",
  "error.missingDocument": "Chybějící dokument",
  "error.missingEmail": "Chybějící e-mailová adresa",
  "error.missingEmployee": "Chybějící zaměstnanec",
  "error.missingParams": "Chybějící parametry",
  "error.move": "Chyba při přesunu",
  "error.move.alreadyExists.unknown": "Chyba při přesunu: Složka nebo soubor s tímto názvem již existuje",
  "error.msTokenExpired": "Platnost vašeho tokenu MS vypršela. Prosím, obnovte ji.",
  "error.multipleArchives": "Pro tuto společnost je k dispozici několik archivů klientů",
  "error.noArchive": "Pro tuto společnost není k dispozici žádný archiv klientů",
  "error.noCompanySelected": "Žádná vybraná společnost",
  "error.noDocumentSelected": "Vyberte si dokument pro podmínky použití",
  "error.noDotAtEnd": "Poslední znak nesmí být tečka",
  "error.noFinancialAccountancies": "Společnost nemá žádné FIBU",
  "error.noMsAccountAvailable": "Uživatele nelze pozvat. Předpokládá se, že zaměstnanci TPA již mají účet Microsoft.",
  "error.noRecordTypes": "Nebyly nalezeny žádné kategorie dokumentů",
  "error.noSignees": "Přidejte prosím e-mailové adresy",
  "error.noSubsidiaries.accounting": "Nebyly nalezeny žádné kódy společností",
  "error.noSubsidiaries.hr": "Nebyly nalezeny žádné účetní oblasti",
  "error.noSubsidiaryWithEmployeeRead": "Žádný mzdový okruh s právem čtení zaměstnanců.",
  "error.notAdvisor": "Žádná povolení konzultantů",
  "error.notAllowed": "Akce nesmí být provedena",
  "error.notificationCenter.load": "Oznámení se nepodařilo načíst",
  "error.notificationCenter.markAllAsRead": "Oznámení nebylo možné označit jako přečtené",
  "error.notificationCenter.markAsRead": "Oznámení nebylo možné označit jako přečtené",
  "error.oauthApi": "Při ověřování došlo k problému. Zkuste to prosím znovu.",
  "error.oauthCompany": "Žádná vybraná společnost. Vyberte společnost a zkuste to znovu.",
  "error.oauthUrl": "Vyskytl se problém s vyvoláním Fakta a čísla. Zkuste to prosím znovu.",
  "error.overlappingSignature": "Překrývající se podpis. Vyberte prosím novou pozici",
  "error.passwordReset.tokenExpired": "Platnost tohoto odkazu na obnovení hesla vypršela",
  "error.passwordReset.tokenNotFound": "Neplatný odkaz pro obnovení hesla",
  "error.payAccountTransaction": "",
  "error.payTaxAccount": "Chyby při placení daňového dluhu",
  "error.payUnpaidAccount": "Chyba při placení faktury TPA",
  "error.periodClosed": "Již ukončené období",
  "error.permission.corruptRoles": "Neplatné role",
  "error.permission.onlyOneRoleAllowed": "Lze nastavit pouze jeden hod",
  "error.postTicket": "Konzultace nemohla být vytvořena.",
  "error.projectForbidden": "Nemáte přístup k projektům",
  "error.projectIsEmpty": "Projekt je prázdný, a proto jej nelze stáhnout",
  "error.projectPermissions": "Nelze načíst oprávnění k projektu",
  "error.projects.cantMove": "Soubory nebylo možné přesunout. Zkuste to prosím později.",
  "error.projects.cantPaste": "Soubory se nepodařilo vložit. Zkuste to prosím později.",
  "error.projects.openBookmarkUrlFailed": "Adresu URL se nepodařilo otevřít",
  "error.qes.missingSignaturePositions": "Přidejte prosím požadované podpisové pozice",
  "error.recordForbidden": "K voucheru nemáte přístup.",
  "error.recordWithNotAllowedCompany": "Žádné oprávnění pro společnost, které je dokument přidělen.",
  "error.refreshBankConnection": "Bankovní účet nebylo možné obnovit",
  "error.releaseEmployeeDocument": "Chyba při uvolňování",
  "error.releaseReport": "Chyba při uvolňování",
  "error.removeBankAccountTransactionPrivateMark": "Nelze zrušit výběr",
  "error.removeCompanyFromUserFavorites": "Společnost nebylo možné odstranit z oblíbených položek",
  "error.rename.alreadyExists.file": "Chyba při přejmenování: Soubor s tímto názvem již existuje",
  "error.rename.alreadyExists.folder": "Chyba při přejmenování: Složka s tímto názvem již existuje",
  "error.requiredField": "Požadovaný vstup",
  "error.resendTermsOfUseEmail": "Při opětovném odesílání e-mailu došlo k chybě",
  "error.save": "Chyba při ukládání",
  "error.saveKpiConfigurations": "Fakta a čísla Konfigurace nebylo možné uložit",
  "error.saveOptionalMenuItems": "Volitelné položky nabídky se nepodařilo načíst. Zkuste to prosím později",
  "error.saveTermsOfUse": "Při ukládání podmínek používání došlo k chybě. Zkuste to prosím později",
  "error.send": "Chyba při odesílání.",
  "error.setDocumentOrSignees": "Při ukládání dokumentu nebo osob, které mají být podepsány, došlo k chybě.",
  "error.settings": "Chyba při změně nastavení",
  "error.signFileTooLarge": "Soubor je příliš velký a nelze jej elektronicky podepsat.",
  "error.signReadOnlyDocument": "Dokument je určen pouze pro čtení, a proto jej nelze podepsat.",
  "error.signTermsOfUse": "Při přijímání podmínek používání došlo k chybě. Zkuste to prosím později",
  "error.subsidiaryWithoutRecordTypes": "{subsidiary} nemá žádné aktivní symboly dokumentů",
  "error.termsOfUseDuplicateFile": "Stejný soubor s jiným názvem již existuje",
  "error.termsOfUseFolderNotFound": "Zatím nebyla vytvořena žádná složka s podmínkami použití",
  "error.timeout": "Tento proces v současné době trvá o něco déle. Zkuste to prosím později.",
  "error.toggleCompanyFeature": "Chyba při aktivaci/deaktivaci doplňku",
  "error.tokenRefresh": "Chyba ověřování",
  "error.tooManyCharacters": "Maximální počet znaků {maxCharacters} povolený pro jednu zprávu.",
  "error.transfer": "Chyba přenosu",
  "error.transferEmployee": "Zaměstnanec nemohl být převeden",
  "error.transferEmployeeDirect": "Převod do fondu zdravotního pojištění se nezdařil. Obraťte se na svého poradce.",
  "error.transferEmployee.validation.general": "",
  "error.transferEmployee.validation.general.zipcode": "",
  "error.unknown": "Neznámá chyba ({statusCode})",
  "error.unsupportedDocumentType": "Tento typ dokumentu není podporován",
  "error.updateBankAccountAccountNumber": "Účet se nepodařilo uložit.",
  "error.upgradeToMicrosoft": "Aktualizaci nebylo možné provést. Zkontrolujte si prosím své heslo.",
  "error.upload": "Chyba při nahrávání",
  "error.userDeactivated": "Tento uživatel společnosti Microsoft byl deaktivován.",
  "error.userNotRegistered": "Uživatel není registrován",
  "error.userUnknown": "Neznámý uživatel",
  "externalLinkDialog.content": "",
  "externalLinkDialog.title": "",
  "failedUserDialog.amount": "<b>{failedUserCount}</b> z {userCount} uživatelů se nepodařilo vytvořit/změnit:",
  "failedUserDialog.button.text.continue": "Pokračovat nicméně",
  "failedUserDialog.button.text.retry": "Zkuste to znovu",
  "failedUserDialog.error.emailAlreadyExists": "Již používaná e-mailová adresa",
  "failedUserDialog.error.pendingPermissionChanges": "Již čeká na vydání",
  "failedUserDialog.message": "Chyba při vytváření/změně",
  "features.default.prices.from": "",
  "features.default.prices.fromFlat": "",
  "features.default.prices.fromTo": "",
  "features.default.prices.fromToFlat": "",
  "filterDialog.apply": "Použít filtr",
  "filterDialog.category.advanced": "Pokročilé filtry",
  "filterDialog.category.assigned": "Přidělená oblast",
  "filterDialog.header": "Možnosti filtrování",
  "filterDialog.required": "Vyberte hodnotu",
  "filterDialog.reset": "RESET FILTRU",
  "finishProjectDialog.text": "Jste si jisti, že chcete dokončit projekt <b>{jméno projektu}</b>?",
  "finishProjectDialog.title": "Kompletní projekt",
  "footer.copyright": "Všechna práva vyhrazena",
  "footer.disclaimer": "Odmítnutí odpovědnosti",
  "footer.generalBusinessTerms": "AGB",
  "footer.legalInformation": "Otisk",
  "footer.privacy": "Ochrana údajů",
  "helpcenter.ideas": "Nápady nebo přání",
  "helpcenter.problems": "Nahlášení problémů",
  "helpcenter.problems.email": "E-mailová adresa",
  "helpcenter.problems.send": "Nahlásit problém",
  "helpcenter.problems.upload.failed": "Problém se nepodařilo přenést",
  "helpcenter.problems.upload.success": "Problém byl úspěšně odeslán",
  "helpcenter.problems.upload.tooManyRequests": "",
  "helpcenter.problems.whathappened": "Co se stalo místo toho?",
  "helpcenter.problems.whatshouldhappen": "Co se mělo stát?",
  "helpcenter.problems.whatwasdone": "Co se snažili udělat?",
  "helpCenter.title": "Centrum nápovědy",
  "helpcenter.videos": "Videa nápovědy",
  "helpcenter.videos.tpaonly": "Nápověda k videím zaměstnanců TPA",
  "hr.casualEmployment": "Zaměstnává se případ od případu",
  "hr.disclaimer.additionalData": "Pro přímý přenos potřebujeme ještě některé další údaje. Vyplňte je prosím.",
  "hr.disclaimer.employmentDate": "Datum nástupu do zaměstnání",
  "hr.disclaimer.subtitle": "Chcete data zasílat TPA nebo přímo zdravotní pojišťovně?",
  "hr.disclaimer.title": "Převodovka",
  "hr.disclaimer.transfer.direct": "Předávání údajů se provádí přímo rakouské zdravotní pojišťovně.",
  "hr.disclaimer.transfer.direct.subtitle": "Podotýkáme, že TPA bude údaje kontrolovat nejdříve v následujících dnech, kdy bude úřad otevřen.",
  "hr.disclaimer.transfer.tpa": "Přenos dat se provádí do TPA",
  "hr.disclaimer.transfer.tpa.subtitle": "Upozorňujeme, že registrace zaměstnanců u rakouské zdravotní pojišťovny proběhne až po zpracování údajů agenturou TPA.",
  "hr.documents.backLabel": "Personál/spisy",
  "hr.documents.emptyState.header": " zachycení",
  "hr.documents.emptyState.message": "Začněte nahrávat své soubory nyní.",
  "hr.documents.list.backLabel": "Personál/dokumenty",
  "hr.documents.nonWorkingTimes": "Časy, kdy se neprovádí výkon",
  "hr.documents.nonWorkingTimesUpload": "Doba nefunkčnosti nahrávání souborů",
  "hr.documents.upload.navHeader": "{documentType}",
  "hr.documents.upload.navLabel": "Personál/dokumenty",
  "hr.employee.employment": ". Pracovní poměr",
  "hr.employee.employmentRelationship": "Pracovní poměr",
  "hr.employee.jobDescription": "Název práce",
  "hr.employee.payslip.upload.date": "Období",
  "hr.employee.payslip.upload.title": "Nahrát výplatní pásku",
  "hr.employee.payslip.upload.type": "Typ výplatní pásky",
  "hr.employee.payslip.upload.type.monthly": "Měsíční výplatní páska",
  "hr.employee.payslip.upload.type.yearly": "Roční výplatní páska",
  "hr.employee.reasonForLeaving": "Důvod odchodu",
  "hr.employee.workplaceState": "Stav pracoviště",
  "hr.finished.analysis": "Měsíční hodnocení",
  "hr.finished.billing": "Měsíční výpisy",
  "hr.finished.globalAnalysis": "Celopodnikové hodnocení",
  "hr.finished.globalBilling": "Celopodnikové účetnictví",
  "hr.insuranceCarrier": "Pojišťovna",
  "hr.overview.continuousActions": "Spojité funkce",
  "hr.recordTypes.names.benefitsInKindCar": "Věcné dávky automobil",
  "hr.recordTypes.names.contractAdjustmentsAndSpecialPayments": "Úpravy smlouvy a zvláštní platby",
  "hr.recordTypes.names.mealDeductions": "Odpočty za stravu",
  "hr.recordTypes.names.nonWorkingTimes": "Časy, kdy se neprovádí",
  "hr.recordTypes.names.overtimeAndBonusPayments": "Příplatky za přesčasy a prémie",
  "hr.recordTypes.names.supplementaryDocuments": "Doplňkové dokumenty",
  "hr.recordTypes.names.timeRecords": "Časové záznamy",
  "hr.recordTypes.names.travelExpenseReport": "Zpráva o cestovních výdajích",
  "hr.tickets.preRegistration.NavBar": "Dotaz na {preRegistration}",
  "hr.tickets.preRegistration.NavBarLabel": "Zaměstnanci / Zaměstnanci / Dotazy",
  "hr.tickets.preRegistration.toOverview": "DO OSOBNÍHO SPISU",
  "imageCrop.description": "Přetažením přesunete obrázek",
  "imageCrop.title": "Obrázek polohy",
  "imageCrop.zoom": "Zoom",
  "language.german": "Němčina",
  "maritalStatus.divorced": "rozvedený",
  "maritalStatus.livingInPartnership": "Partnerský život",
  "maritalStatus.married": "Manželství/registrované partnerství",
  "maritalStatus.separated": "Oddělené bydlení",
  "maritalStatus.unknown": "neznámý",
  "maritalStatus.unmarried": "jediný",
  "maritalStatus.widowed": "ovdovělý",
  "menu.answer": "Odpověď",
  "menu.assign": "Přiřazení",
  "menu.cancelRelease": "Stažení uvolnění",
  "menu.delete": "Odstranit",
  "menu.details": "Podrobnosti na",
  "menu.download": "Stáhnout",
  "menu.draft": "Žádost o schválení",
  "menu.edit": "Upravit",
  "menu.editRelease": "Znovu požádat o uvolnění",
  "menu.finish": "Zámek",
  "menu.markAsDone": "Označit jako hotové",
  "menu.move": "Přesun",
  "menu.openInBrowser": "Otevřít v prohlížeči",
  "menu.openInSharepoint": "Otevřít v aplikaci Sharepoint",
  "menu.rename": "Přejmenování",
  "menu.requestRelease": "Žádost o uvolnění",
  "menu.showReleases": "Ukázat vydání",
  "menu.view": "Zobrazit",
  "mfa.activation.dialog.code.required": "Zadejte prosím kód",
  "mfa.activation.dialog.description": "Zadejte prosím kód, který jsme vám zaslali na vaše telefonní číslo, a aktivujte dvoufaktorové ověřování.",
  "mfa.activation.dialog.title": "Aktivace funkce 2FA",
  "mfa.activation.success.dialog.title": "2FA byla úspěšně aktivována",
  "mfa.changeSetting.dialog.code.field.label": "Kód",
  "mfa.changeSetting.dialog.resendCode": "Opětovné odeslání kódu",
  "mfa.codeResend.success.dialog.title": "Kód byl znovu odeslán",
  "mfa.deactivation.dialog.description": "Zadejte prosím kód, který jsme vám zaslali na vaše telefonní číslo, abyste deaktivovali dvoufaktorové ověřování.",
  "mfa.deactivation.dialog.title": "Deaktivace funkce 2FA",
  "mfa.deactivation.success.dialog.title": "Služba 2FA byla úspěšně deaktivována",
  "mfa.validation.code.length": "Kód má 6 číslic",
  "mfa.validation.code.required": "Je třeba zadat kód",
  "mfa.validation.code.type": "Povolena jsou pouze čísla",
  "microsoft_oauth.button.login.title": "Přihlášení pomocí služby Microsoft",
  "microsoft_oauth.button.send_code.title": "Odeslat kód",
  "microsoft_oauth.button.upgrade.title": "Proveďte aktualizaci",
  "missingEmailDialog.description": "E-mailová adresa <b>{jméno}</b> není v souboru. Zadejte prosím platnou e-mailovou adresu, aby bylo možné zaslat pozvánku.",
  "missingEmailDialog.title": "Chybějící e-mailová adresa",
  "missingEmail.email.error": "Zadejte prosím správnou e-mailovou adresu",
  "missingEmail.email.label": "Zadejte e-mailovou adresu",
  "module.finished.globalReports": "Celopodnikové zprávy",
  "modules.delete.report.message": "Opravdu chcete smazat zprávu {jméno}?",
  "modules.delete.report.title": "Smazat zprávu",
  "navbar.week": "KW {týden} {rok}",
  "newUsername.checkbox.error": "Potvrďte prosím svou novou e-mailovou adresu",
  "notificationCenter.emptyState": "Žádná oznámení",
  "notificationCenter.filter.accounting": "Účetnictví",
  "notificationCenter.filter.appNotification:maintenance": "Zprávy o údržbě",
  "notificationCenter.filter.appNotification:newFeature": "Nové funkce",
  "notificationCenter.filter.appNotification:update": "",
  "notificationCenter.filter.companySpecific": "Specifické stránky společnosti",
  "notificationCenter.filter.employeeDocument": "Dokumenty zaměstnanců",
  "notificationCenter.filter.file": "Dokumenty",
  "notificationCenter.filter.general": "Obecné",
  "notificationCenter.filter.generalDocument": "Obecné soubory",
  "notificationCenter.filter.hr": "Mzdové účetnictví",
  "notificationCenter.filter.invoice": "Příjmy",
  "notificationCenter.filter.login": "Přihlášení",
  "notificationCenter.filter.module": "Modul",
  "notificationCenter.filter.notificationType": "Typ oznámení",
  "notificationCenter.filter.other": "Další",
  "notificationCenter.filter.payslip": "výplatní páska",
  "notificationCenter.filter.project": "Projekty",
  "notificationCenter.filter.report": "Zprávy",
  "notificationCenter.filter.ticket": "Poptávka",
  "notificationCenter.filter.unread": "Pouze nepřečtené",
  "notificationCenter.markAllAsRead": "Označit vše jako přečtené",
  "notificationCenter.title": "Centrum oznámení",
  "openEmployeeDocumentReleasesDialog.releaseMessage.multi": "Ve vaší osobní složce máte <b>{počet} nových dokumentů</b>, které vyžadují vaše schválení.",
  "openEmployeeDocumentReleasesDialog.releaseMessage.single": "Ve svém osobním spise máte <b>nový dokument</b>, který vyžaduje vaše schválení.",
  "openEmployeeDocumentReleasesDialog.submit": "Zobrazení a sdílení",
  "openEmployeeDocumentReleasesDialog.title": "Sdílet dokumenty",
  "paymentDialog.button.pay": "Platit",
  "paymentDialog.field.amount": "Částka",
  "paymentDialog.field.amount.maxError": "",
  "paymentDialog.field.amount.minError": "",
  "paymentDialog.field.amount.typeError": "Zadejte prosím číslo",
  "paymentDialog.field.iban": "IBAN",
  "paymentDialog.field.iban.invalidError": "Zadejte prosím platný IBAN",
  "paymentDialog.field.purpose": "Zamýšlené použití",
  "paymentDialog.title": "Platit",
  "pdfView.error.caption": "Dokument se nepodařilo načíst, zkuste to prosím znovu.",
  "pendingUserCreationDialog.message": "Vytvoření uživatelů ve službě Sharepoint může trvat až 30 minut. Buďte prosím trpěliví, dokud nebudou oprávnění vytvořena.",
  "pendingUserCreationDialog.title": "Uživatelé jsou vytvořeni",
  "permission.accounting.banking": "Bankovní údaje",
  "permission.accounting.global.reports": "Účetní výkazy pro celou společnost",
  "permission.accounting.global.results": "Celopodnikové účetní hodnocení",
  "permission.accounting.incaseoflaw": "V případě práva",
  "permission.accounting.records": "Oznámení o účetnictví",
  "permission.accounting.reports": "Účetní výkazy",
  "permission.accountTransaction.payment": "",
  "permission.expenses.cockpit": "Zobrazení faktů a údajů v kokpitu",
  "permission.expenses.kpi": "Použití modulu Fakta a čísla",
  "permission.hr.employeePayrollAccount": "Zobrazení mzdového účtu zaměstnance",
  "permission.hr.employerPayrollAccount": "Mzdový účet zaměstnavatele",
  "permission.hr.employerPayrollAccount.details": "Zobrazit podrobnosti o typu mzdy",
  "permission.hr.global.reports": "Mzdové účetní výkazy pro celou společnost",
  "permission.hr.global.results": "Celopodnikové analýzy mzdového účetnictví",
  "permission.hr.personnel": "Zaměstnanci",
  "permission.hr.records": "Oznámení o personálním vyrovnání",
  "permission.hr.reports": "Mzdové účetní výkazy",
  "permission.option.canAccess": "mohou přistupovat",
  "permission.option.canEdit": "může upravovat",
  "permission.option.canEdit.all": "může vše upravit/odemknout",
  "permission.option.canEdit.none": "nelze nic upravit/odemknout",
  "permission.option.canEdit.some": "Lze částečně upravit/uvolnit",
  "permission.option.canPay": "může platit",
  "permission.option.canRead": "vidí",
  "permission.option.canRead.all": "vidí všechno",
  "permission.option.canRead.none": "nic nevidí",
  "permission.option.canRead.some": "částečně vidí",
  "permission.option.canRelease": "může uvolnit",
  "permission.option.canUpdate": "může upravovat",
  "permission.option.canUpload": "může nahrát",
  "permission.option.mixed": "smíšené",
  "permission.option.none": "žádné povolení",
  "permission.projects": "Používání modulu projektů",
  "permission.settings.company": "Zobrazit nastavení společnosti",
  "permission.settings.features": "Úprava doplňků",
  "permission.settings.images": "Změna obrázku na obálce a loga",
  "permission.settings.user": "Navrhnout změny uživatelských práv",
  "permission.taxAccount.payment": "Zaplatit daňový dluh",
  "permission.unpaidAccount.payment": "Zaplatit neuhrazené faktury",
  "project.invitation": "Pozvánka na projekt",
  "projectItemPermission.owner": "Plný přístup",
  "projectItemPermission.owner.description": "Všechna práva k úpravám složek a souborů. Upravte a dokončete projekt a spravujte členy a oprávnění.",
  "projectItemPermission.read": "Přečtěte si",
  "projectItemPermission.read.description": "Zobrazení složek a souborů a stahování souborů",
  "projectItemPermission.readNoDownload": "Pouze pro čtení",
  "projectItemPermission.write": "Upravit",
  "projectItemPermission.write.description": "Zobrazení, vytváření a přejmenování složek. Zobrazování, nahrávání, úpravy a mazání souborů.",
  "projects.activate": "OBNOVIT",
  "projects.active.emptystate.buttonlabel": "Vytvořit projekt",
  "projects.active.emptystate.message": "Pro tuto společnost zatím neexistuje žádný projekt",
  "projects.addCompanies": "Přidat společnost",
  "projects.addCompanies.tooltip": "Vytvoření projektu v několika společnostech",
  "projects.addFolder": "Vytvořit složku",
  "projects.addMembers": "Přidat členy",
  "projects.addMembers.bulk.success.title": "Úspěšně přidaní členové",
  "projects.bulkDeleteDialog.message": "Opravdu chcete odstranit vybrané soubory a složky?",
  "projects.bulkDeleteDialog.title": "Odstranění souborů a složek",
  "projects.cancelRequestedReleaseConfirmationDialog.confirm": "Stáhnout",
  "projects.cancelRequestedReleaseConfirmationDialog.message": "Jste si jisti, že chcete stáhnout otevřený podíl na <b>{jméno souboru}</b>?",
  "projects.cancelRequestedReleaseConfirmationDialog.title": "Stáhnout stávající vydání?",
  "projects.closed.emptystate.message": "Pro tuto společnost zatím nebyl dokončen žádný projekt",
  "projects.companies.emptyState.title": "Žádné společnosti nejsou k dispozici",
  "projects.companies.submit": "Přidat",
  "projects.copySuccessDialog.message": "Vybrané soubory byly zkopírovány do schránky.",
  "projects.copySuccessDialog.title": "Zkopírováno do schránky",
  "projects.create.bulk.backToProjects": "Zpět na projekty",
  "projects.create.bulk.description": "Projekty se vytvářejí. To může trvat několik minut, nezavírejte proto okno prohlížeče.",
  "projects.create.bulk.start": "Spuštění",
  "projects.deleted.emptystate.message": "Pro tuto společnost zatím nebyl smazán žádný projekt",
  "projects.deleteDialog.message.file": "Opravdu chcete odstranit soubor <b>{jméno}</b>?",
  "projects.deleteDialog.message.folder": "Opravdu chcete odstranit složku <b>{jméno}</b>?",
  "projects.deleteDialog.message.project": "Opravdu chcete odstranit projekt <b>{název}</b>?",
  "projects.deleteDialog.title.file": "Smazat soubor",
  "projects.deleteDialog.title.folder": "Smazat složku",
  "projects.deleteDialog.title.project": "Odstranit projekt",
  "projects.emptystate.title": "Žádné projekty nejsou k dispozici",
  "projects.externalId": "Referenční číslo:",
  "projects.finish.successDialog.title": "Úspěšně dokončený projekt",
  "projects.folder.emptystate.message": "Nyní začněte plnit složku.",
  "projects.folder.emptystate.title": "Vytváření a nahrávání obsahu",
  "projects.importUsers.add": "Přidat",
  "projects.importUsers.replace": "Vyměňte stránky",
  "projects.importUsers.switch": "Importovat členy z jiného projektu",
  "projects.international": "Mezinárodní projekt",
  "projects.international.activate": "Chcete-li projekt převést na mezinárodní, musíte tuto funkci povolit.",
  "projects.international.confirmButton": "Převést projekt",
  "projects.international.disabled.refresh": "Platnost vašeho mezinárodního přihlašovacího jména do MS vypršela, obnovte jej prosím. Poté můžete změnit \"{projekt}\" na mezinárodní projekt.",
  "projects.international.disabled.unlock": "Chcete-li projekt převést na mezinárodní, musíte tuto funkci povolit v přehledu projektů.",
  "projects.international.info.message": "Přeměna projektu na mezinárodní projekt umožňuje přizvat k projektu zaměstnance TPA z jiných zemí, aby mohli s klientem spolupracovat přeshraničně. Za tímto účelem se všechny soubory a dokumenty uložené v rámci projektu v aplikaci Microsoft SharePoint přenášejí mezi různými nájemci společnosti Microsoft a v průběhu toho se obnovují všechna oprávnění uživatelů pro práci na projektu. Při tomto procesu může dojít ke ztrátě jemných oprávnění. Proto se doporučuje zkontrolovat možnosti přístupu po převodu na mezinárodní projekt. Tento krok nelze odvolat.",
  "projects.international.info.subtitle": "Vezměte prosím na vědomí následující:",
  "projects.international.invite.message": "Byli jste přidáni do nových mezinárodních projektů. Jednou aktivujte následující země, abyste mohli zobrazit projekty z těchto zemí.",
  "projects.international.invite.title": "Odemknutí nových zemí",
  "projects.international.migrating": "Mezinárodní projekt (probíhající konverze)",
  "projects.international.migrating.message": "V současné době se projekt mění na mezinárodní projekt. Úpravy budou možné až po dokončení převodu.",
  "projects.international.plural": "Mezinárodní projekty",
  "projects.international.switch": "Přeměnit tento projekt na mezinárodní projekt",
  "projects.international.undoWarning.message": "Jste si jisti, že chcete tento projekt převést na mezinárodní projekt? Všechna data projektu budou zkopírována do TPA AT sharepoint. Tuto akci nelze po vytvoření projektu vrátit zpět.",
  "projects.international.undoWarning.message.at": "Jste si jisti, že chcete tento projekt převést na mezinárodní projekt? Tuto akci nelze po vytvoření projektu vrátit zpět.",
  "projects.international.undoWarning.title": "Tuto akci nelze vzít zpět",
  "projects.invite.international": "Byli jste přidáni do mezinárodních projektů. Odemkněte je nyní.",
  "projects.invite.message": "Byli jste přidáni do projektů ze zemí {numCountries}. Odemkněte je nyní.",
  "projects.invite.message.singular": "Byli jste přidáni do projektů z {krajina}. Odemkněte je nyní.",
  "projects.members.assignPermission": "Přiřazení oprávnění",
  "projects.membersIncluded.multi": "Včetně {jmen} a {jména}.",
  "projects.membersIncluded.single": "Včetně {jména}.",
  "projects.moveConfirmationDialog.message": "Přesunuté soubory se z původní složky odstraní!",
  "projects.moveConfirmationDialog.title": "Přesunout soubory?",
  "projects.moveSuccessDialog.message": "Soubory byly úspěšně přesunuty",
  "projects.moveSuccessDialog.title": "Přesun souborů",
  "projects.navbar.activeProjects": "Aktivní",
  "projects.navbar.deletedProjects": "Odstraněno",
  "projects.navbar.fileUpload": "Nahrávání souborů",
  "projects.navbar.finishedProjects": "Dokončeno",
  "projects.navbar.heading": "Projekty",
  "projects.navbar.notConfiguredProjects": "Není nakonfigurováno",
  "projects.navbar.overview": "Přehled{cesta}",
  "projects.navbar.project.fileTree": "Struktura složky",
  "projects.navbar.project.heading": "Projekt: {projectName}",
  "projects.navbar.project.releases": "Uvolňuje",
  "projects.navbar.releases.heading": "Akcie pro {fileName}",
  "projects.navbar.ticket.heading": "Dotaz na {jméno souboru}",
  "projects.new": "Nový projekt",
  "projects.new.createNewProject": "Vytvořit projekt",
  "projects.new.externalId": "Referenční číslo",
  "projects.new.name": "Název projektu",
  "projects.new.permissionContextMenu.remove": "Odstranění stránky",
  "projects.new.permissions.text": "Přidávání nových členů a přidělování uživatelských práv.",
  "projects.new.title": "Název projektu",
  "projects.new.userSearch.placeholder": "Pozvěte člena...",
  "projects.notConfigured.emptystate.message": "Pro tuto společnost zatím neexistuje žádný projekt, který by bylo možné nakonfigurovat.",
  "projects.openReleases.plural": "Otevřené verze",
  "projects.openReleases.singular": "Otevřené uvolnění",
  "projects.pasteProgressDialog.title": "Soubory jsou vloženy",
  "projects.pasteSuccessDialog.message": "Soubory byly úspěšně vloženy",
  "projects.pasteSuccessDialog.title": "Vložené soubory",
  "projects.refreshDialog.message": "Platnost přihlašovacích údajů společnosti Microsoft pro následující země vypršela. Prosím, obnovte je.",
  "projects.refreshDialog.title": "Vypršela platnost přihlašovacích údajů společnosti Microsoft",
  "projects.refresh.message": "Platnost přihlašovacích údajů společnosti Microsoft pro země {numCountries} vypršela. Prosím, obnovte je.",
  "projects.refresh.message.singular": "Platnost vašeho přihlašovacího jména Microsoft pro {kraj} vypršela. Prosím, obnovte ji.",
  "projects.releaseConfirmationDialog.message": "Vezměte prosím na vědomí následující komentář od {name}:",
  "projects.releaseConfirmationDialog.title": "Sdílet dokument",
  "projects.releases.emptystate.message": "V současné době nemáte žádná vydání.",
  "projects.releases.emptystate.title": "Žádná vydání nejsou k dispozici",
  "projects.releases.reject": "Pokles",
  "projects.releases.status.canceled": "staženo",
  "projects.releases.status.canceledFrom": "staženo {jméno}",
  "projects.releases.status.canceled.tooltip": "staženo dne {performedAt} od {jméno}",
  "projects.releases.status.open": "Čekání na vydání",
  "projects.releases.status.openFrom": "čeká na uvolnění {jméno}",
  "projects.releases.status.open.tooltip": "čeká na uvolnění (termín: {dueDate}) z {jméno}",
  "projects.releases.status.rejected": "Odmítnuto",
  "projects.releases.status.rejectedFrom": "odmítnuto {jméno}",
  "projects.releases.status.rejected.tooltip": "zamítnuto na {performedAt} od {jméno}",
  "projects.releases.status.released": "uvolněno",
  "projects.releases.status.releasedFrom": "vydáno {jméno}",
  "projects.releases.status.released.tooltip": "vydáno dne {performedAt} podle {jména}",
  "projects.releaseSuccessDialog.message": "<b>{jméno}</b> se nyní podívá na soubory a bude vás kontaktovat s případnými dotazy.",
  "projects.releaseSuccessDialog.multi.message": "Přidělené osoby nyní projdou soubory a budou vás kontaktovat s případnými dotazy.",
  "projects.releaseSuccessDialog.title": "Úspěšná žádost o uvolnění",
  "projects.requestReleaseConfirmationDialog.confirm": "Stáhnout a znovu vytvořit",
  "projects.requestReleaseConfirmationDialog.message": "Pro <b>{jméno souboru}</b> již existuje otevřená sdílená složka. Chcete-li vytvořit novou akcii, je třeba stávající akcii odebrat.",
  "projects.requestReleaseConfirmationDialog.multi.message": "Ve vašem výběru jsou již otevřená vydání. Chcete-li vytvořit nové akcie, musíte stávající akcie stáhnout.",
  "projects.requestReleaseConfirmationDialog.multi.title": "Stáhnout stávající vydání?",
  "projects.requestReleaseConfirmationDialog.title": "Stáhnout stávající vydání?",
  "projects.restoreDialog.message.project": "Jste si jisti, že chcete aktivovat projekt <b>{název}</b>?",
  "projects.restoreDialog.title.project": "Aktivace projektu",
  "projects.statusClosed": "Dokončeno dne",
  "projects.statusClosed.personalized": "Dokončil {jméno} dne",
  "projects.statusDeleted": "Odstraněno dne",
  "projects.statusDeleted.personalized": "Smazáno uživatelem {jméno} dne",
  "projects.statusInProgress": "Naposledy upraveno dne",
  "projects.statusInProgress.personalized": "Naposledy upravil {jméno} dne",
  "projects.tickets.plural": "Dotazy",
  "projects.tickets.singular": "Konzultace",
  "records.details.upload.header": "Záznam {recordType}",
  "recordType.statusClosed": "Přenášeno na",
  "recordType.statusInProgress": "Upraveno dne",
  "recordType.statusOpen": "Nevydané stránky",
  "renameDialog.button.text.rename": "Přejmenování",
  "renameDialog.label.document": "Označení",
  "renameDialog.label.file": "Název souboru",
  "renameDialog.label.folder": "Název složky",
  "renameDialog.title.document": "Přejmenování dokumentu",
  "renameDialog.title.file": "Přejmenování souboru",
  "renameDialog.title.folder": "Přejmenování složky",
  "renameProjectItemDialog.button.text.rename": "Přejmenování",
  "renameProjectItemDialog.itemName.file": "Název souboru",
  "renameProjectItemDialog.itemName.folder": "Název složky",
  "renameProjectItemDialog.title.file": "Přejmenování souboru",
  "renameProjectItemDialog.title.folder": "Přejmenování složky",
  "renameRecordDialog.button.text.rename": "Přejmenování",
  "renameRecordDialog.name": "Označení",
  "renameRecordDialog.title": "Přejmenování dokumentu",
  "requestReleaseProjectItemDialog.button.text.request": "Dotazy",
  "requestReleaseProjectItemDialog.info": "Kdy potřebuji kvalifikovaný podpis?",
  "requestReleaseProjectItemDialog.label.comment": "Komentář:",
  "requestReleaseProjectItemDialog.label.dueDate": "Termín",
  "requestReleaseProjectItemDialog.label.requiresQes": "Uvolnění elektronickým podpisem (pouze u souborů PDF)",
  "requestReleaseProjectItemDialog.placeholder.searchForUsers": "Zadejte jméno...",
  "requestReleaseProjectItemDialog.qes.collective.subtitle": "Všechny osoby musí uvolnit",
  "requestReleaseProjectItemDialog.qes.collective.title": "Kolektivní uvolnění",
  "requestReleaseProjectItemDialog.qes.single.subtitle": "Uvolnit musí pouze jedna osoba",
  "requestReleaseProjectItemDialog.qes.single.title": "Jednotlivé vydání",
  "requestReleaseProjectItemDialog.searchForUsers.error": "Vyberte jméno ze seznamu",
  "requestReleaseProjectItemDialog.text": "Vyberte osoby, kterým chcete přiřadit uvolnění k uvedeným dokumentům, a zadejte termín.",
  "requestReleaseProjectItemDialog.title.addResponsibleUser": "Přidání další osoby",
  "requestReleaseProjectItemDialog.title.create": "Žádost o uvolnění",
  "requestReleaseProjectItemDialog.title.edit": "Upravit vydání",
  "results.accountSheet.accountDetails.pay": "",
  "results.accountSheet.accountTransaction.lastCompletedPayment": "",
  "results.accountSheet.accountTransaction.lastCompletedPaymentWithAmount": "",
  "results.accountSheet.accountTransaction.lastCompletedPaymentWithAmount.detailed": "",
  "results.accountSheet.backLabel.accountSheet": "List účtu {accountNr} \"{jméno}\"",
  "results.accountSheet.backLabel.results.accountListing": "Bilanční seznam",
  "results.accountSheet.backLabel.results.balanceSheet": "Rozvaha",
  "results.accountSheet.backLabel.results.cashAccounting": "Výkaz příjmů a výdajů",
  "results.accountSheet.backLabel.results.customerListing": "Seznam dlužníků",
  "results.accountSheet.backLabel.results.profitAndLoss": "G&V",
  "results.accountSheet.backLabel.results.vendorListing": "Seznam závazků",
  "results.accountSheet.columns.amount": "Částka",
  "results.accountSheet.columns.documentDate": "Datum dokumentu",
  "results.accountSheet.columns.documentNumber": "Dokument č.",
  "results.accountSheet.columns.documents": "Příjmy",
  "results.accountSheet.columns.offsettingAccounts": "Protiúčty",
  "results.accountSheet.columns.openItemAmount": "Otevřená částka",
  "results.accountSheet.columns.postingSymbol": "BS",
  "results.accountSheet.columns.postingText": "Vysílání textu",
  "results.accountSheet.details.costCenter": "Nákladové středisko",
  "results.accountSheet.details.discount": "Sleva v hotovosti",
  "results.accountSheet.details.externalDocumentNumber": "Externí dokument č.",
  "results.accountSheet.details.foreignCurrency": "Cizí měna / směnný kurz",
  "results.accountSheet.details.period": "Období",
  "results.accountSheet.details.serialNumber": "Časopis č.",
  "results.accountSheet.details.tax": "Daň",
  "results.accountSheet.details.taxCode.Ausfuhrlieferungen": "Vývozní dodávky",
  "results.accountSheet.details.taxCode.BauleistungenMitVSt": "Stavební práce s DPH",
  "results.accountSheet.details.taxCode.BauleistungenOhneVSt": "Stavební práce bez DPH",
  "results.accountSheet.details.taxCode.EUSt": "EUSt",
  "results.accountSheet.details.taxCode.igEMitVSt": "igE s DPH",
  "results.accountSheet.details.taxCode.igEOhneVst": "igE bez DPH",
  "results.accountSheet.details.taxCode.igLieferung": "ig delivery",
  "results.accountSheet.details.taxCode.Kleinunternehmer": "Malé podniky",
  "results.accountSheet.details.taxCode.RCMitVSt": "RC s VSt",
  "results.accountSheet.details.taxCode.RCOhneVSt": "RC bez DPH",
  "results.accountSheet.details.taxCode.sonstigeLeistungenEuZm": "Ostatní služby EU (podléhá ZM)",
  "results.accountSheet.details.taxCode.TeilabzugsfaehigeVSt": "částečně odpočitatelná DPH",
  "results.accountSheet.details.taxCode.Ust": "DPH",
  "results.accountSheet.details.taxCode.VSt": "VSt",
  "results.accountSheet.details.taxPercent": "Procento daně",
  "results.accountSheet.details.vatPeriod": "Období UVA",
  "results.accountSheet.documents.downloadAll": "Stáhnout všechny soubory",
  "results.accountSheet.filters.amount": "Částka",
  "results.accountSheet.filters.documentDate": "Datum dokumentu",
  "results.accountSheet.filters.postingSymbol": "Rezervační symbol",
  "results.accountSheet.noResults.message": "Pro vybraný účet nebyly nalezeny žádné položky",
  "results.accountSheet.noResults.open.message": "Pro vybraný účet nebyly nalezeny žádné otevřené položky.",
  "results.accountSheet.noResults.open.title": "Nebyly nalezeny žádné otevřené položky",
  "results.accountSheet.noResults.title": "Nebyly nalezeny žádné příspěvky",
  "results.accountSheet.offsettingAccounts.more": "{count} více",
  "results.accountSheet.paid": "placené",
  "results.accountSheet.search.count": "Rezervace: <b>{count}</b>",
  "results.accountSheet.search.placeholder": "Hledání textu rezervace...",
  "results.accountSheet.sum.title": "Σ Celkem",
  "results.accountSheet.tabs.all": "Všechny položky",
  "results.accountSheet.tabs.open": "Pouze otevřené položky",
  "results.accountSheet.title": "Účetní výkaz pro účet {accountNr} \"{name}\"",
  "results.columns.changeAbsolute": "Abw.",
  "results.columns.changePercentage": "Dev. (%)",
  "results.columns.credit": "Mějte",
  "results.columns.debit": "Cílová stránka",
  "results.common.accounts": "Účty",
  "results.common.quickExpand.expandAccounts": "Všechny (včetně účtů)",
  "results.common.quickExpand.expandNthDepth": "Úroveň {hloubka}",
  "results.common.quickExpand.expandRoot": "Hlavní úroveň rozdělení",
  "results.common.quickExpand.expandRows": "Rozkládací čáry:",
  "results.disclaimer": "Důležitá poznámka",
  "results.disclaimerMessage": "Pro období, za která ještě nebyla dokončena roční účetní závěrka, jsou předložené analýzy založeny na údajích z aktuálního seznamu zůstatků k příslušnému datu vykazování. Analýzy byly zpracovány z podkladů, které nám byly poskytnuty bez jejich věcného přezkoumání, a poskytují klientovi včas dodatečné informace o aktuálním vývoji hospodaření. Zejména časové rozlišení, změny stavu rezerv, změny stavu zásob, ocenění pohledávek a odpisy byly zohledněny pouze v účetních obdobích, za která ještě nebyla sestavena roční účetní závěrka, a to v rozsahu, který byl s klientem dohodnut. Z toho vyplývají rozdíly mezi předloženými analýzami a konečnou roční účetní závěrkou.\n\nNepřebíráme proto žádnou odpovědnost za správnost a úplnost analýz vůči klientovi ani třetím stranám. Ve všech ostatních ohledech se na naše úkoly a odpovědnost vztahují Všeobecné smluvní podmínky pro účetní profese (AAB), a to i ve vztahu ke třetím stranám.",
  "results.navbar.buttons.accountListing": "Bilanční seznam",
  "results.navbar.buttons.balanceSheet": "Rozvaha",
  "results.navbar.buttons.cashAccounting": "Výkaz příjmů a výdajů",
  "results.navbar.buttons.customerListing": "Seznam dlužníků",
  "results.navbar.buttons.payrollAccount": "Mzdový účet",
  "results.navbar.buttons.profitAndLoss": "G&V",
  "results.navbar.buttons.vendorListing": "Seznam závazků",
  "results.noFinancialAccountancies.message": "V současné době nejsou k dispozici žádná hodnocení.",
  "results.noFinancialAccountancies.title": "Žádná hodnocení",
  "results.payrollAccountSheet.dataTable.month": "měsíc",
  "results.payrollAccountSheet.dataTable.number": "Ne.",
  "results.payrollAccountSheet.dataTable.payrollType": "Typ mzdy",
  "results.payrollAccountSheet.dataTable.total": "Celkem",
  "results.payrollAccountSheet.dataTable.totalPreviousYear": "Předchozí rok celkem",
  "results.payrollAccountSheet.noPermission.message": "Nejste oprávněni nahlížet do mzdového účtu.",
  "results.payrollAccountSheet.noPermission.title": "Žádné povolení",
  "results.payrollAccountSheet.noResults.details": "Tento mzdový účet neobsahuje žádné údaje",
  "results.payrollAccountSheet.noResultsYear.message": "Pro vybraný rok nebyly nalezeny žádné údaje.",
  "results.payrollAccountSheet.noResultsYear.title": "Nebyly nalezeny žádné údaje",
  "results.settings.accountsVisibility.hideAccounts": "Skrýt účty",
  "results.settings.accountsVisibility.showAccounts": "Zobrazit účty",
  "results.settings.orderBy.accountNameAsc": "Název účtu vzestupně",
  "results.settings.orderBy.accountNameDesc": "Název účtu sestupně",
  "results.settings.orderBy.accountNrAsc": "Číslo účtu vzestupně",
  "results.settings.orderBy.accountNrDesc": "Číslo účtu sestupně",
  "results.settings.orderBy.amountAsc": "Částka vzestupně",
  "results.settings.orderBy.amountDesc": "Částka sestupně",
  "results.settings.orderBy.title": "Seřadit podle: {orderBy}",
  "results.settings.previousYearRanges.fullYear": "Zobrazit celkem za předchozí rok",
  "results.settings.previousYearRanges.partialYear": "Uvažujte předchozí rok pouze do {previousYearRangeUntil}.",
  "results.settings.variant.compactView": "Kompaktní zobrazení",
  "results.settings.variant.currentAndPastYear": "Novinky a předchozí rok",
  "results.settings.variant.currentAndPastYearAbsoluteChange": "Současný a předchozí rok s absolutní změnou",
  "results.settings.variant.currentAndPastYearPercentageChange": "Současný a předchozí rok s procentní změnou",
  "results.settings.variant.currentYear": "Aktuální rok",
  "results.settings.variant.detailView": "Podrobný pohled",
  "results.title": "Hodnocení",
  "role.accountingAssistent": "Účetní asistentka",
  "role.accountingAssistent.description": "Přístup k účetnictví bez práv na uvolnění",
  "role.accountingManagement": "Řízení financí",
  "role.accountingManagement.description": "Přístup k účetnictví včetně práv na uvolnění",
  "role.admin": "Administrativa / řízení",
  "role.admin.description": "Všechna přístupová práva do všech oblastí",
  "role.assistent": "Asistence",
  "role.assistent.description": "Přístup k účetnictví a mzdám bez uvolnění práv{br}Může navrhnout změnu uživatelů",
  "role.custom": "Vlastní",
  "role.custom.description": "Přístupová práva definovaná uživatelem",
  "role.custom.jobTitle": "Zaměstnanci",
  "role.custom.jobTitle.female": "Zaměstnanci",
  "role.hrAssistent": "Asistentka mzdové účtárny",
  "role.hrAssistent.description": "Přístup k mzdovému účetnictví bez uvolňovacích práv",
  "role.hrManagement": "Řízení lidských zdrojů",
  "role.hrManagement.description": "Přístup k mzdovému účetnictví včetně práv na uvolnění",
  "role.internationalProjects": "Pracovníci projektu",
  "role.internationalProjects.female": "Pracovníci projektu",
  "role.legalAdvisor": "Právní poradce:v",
  "role.noRole": "žádná část",
  "role.noRole.description": "Žádná přístupová práva do služby TPA Connect",
  "role.staff": "Zaměstnanci",
  "role.staff.description": "Dostává elektronické výkazy příjmů",
  "role.staff.female": "Zaměstnanci",
  "role.superAdmin": "Superadmin",
  "role.superAdmin.description": "Přístup do všech oblastí včetně správy uživatelů",
  "role.tpaAccounting": "Účetnictví",
  "role.tpaAccounting.description": "Přístup konzultanta k účetnictví",
  "role.tpaAdvisor": "Poradce",
  "role.tpaAdvisor.description": "Přístup konzultanta do všech prostor",
  "role.tpaAdvisor.female": "Poradce",
  "role.tpaAnnualAccounts": "Roční účetní závěrka",
  "role.tpaAnnualAccounts.description": "Odpovědnost za roční účetní závěrku",
  "role.tpaAuditor": "Audit",
  "role.tpaBusinessConsultant": "Poradenství v oblasti řízení",
  "role.tpaBusinessConsultant.description": "Odpovědnost za poradenství v oblasti řízení",
  "role.tpaChat": "Kontakt",
  "role.tpaChatSecret": "Kontakt",
  "role.tpaHr": "Mzdy",
  "role.tpaHr.description": "Přístup konzultanta ke mzdovému účetnictví",
  "role.tpaOther": "Pracovníci projektu",
  "role.tpaOther.female": "Zaměstnanec projektu",
  "role.tpaPartner": "Partner",
  "role.tpaPartner.description": "Přístup konzultanta do všech prostor",
  "role.tpaPartner.female": "Partner",
  "role.tpaPayrollAccountant": "Mzdy",
  "role.tpaReporting": "Hlášení",
  "role.tpaReporting.description": "Odpovědnost za podávání zpráv",
  "role.tpaSpecialAdvisor": "Zvláštní konzultace",
  "role.tpaSpecialAdvisor.description": "Odpovědnost za speciální poradenství",
  "role.tpaTaxAccountant": "Daňové poradenství",
  "role.tpaTaxAccountant.description": "Odpovědnost za daňové poradenství",
  "screen.accounting.navbar.bankConnections": "Bankovní údaje",
  "screen.accounting.navbar.heading": "Účetnictví",
  "screen.accounting.navbar.overview": "Přehled",
  "screen.accounting.navbar.records": "Příjmy",
  "screen.accounting.overview.bankConnection": "{salutation}, {br} {br} Jeden nebo více vašich bankovních účtů v tomto účetním období zanikne. Prosím, obnovte je.{br} {br} S pozdravem{br} {podpis}.",
  "screen.accounting.overview.bankConnection.expired": "{salutation}, {br} {br} Platnost jednoho nebo více vašich bankovních údajů vypršela. Prosím, obnovte je.{br} {br} S pozdravem{br} {podpis}",
  "screen.accounting.overview.bankConnection.expired.title": "Bankovní údaje, jejichž platnost vypršela",
  "screen.accounting.overview.bankConnections.refreshBankConnections": "Obnovení bankovních účtů",
  "screen.accounting.overview.bankConnection.title": "Platnost bankovních účtů končí",
  "screen.accounting.overview.card.analysis": "Zprávy o vydání pro",
  "screen.accounting.overview.card.globalAnalysis": "Vydávání celopodnikových zpráv",
  "screen.accounting.overview.card.overview": "Oznámení o účtování",
  "screen.accounting.overview.finished.download": "Stáhnout všechny soubory",
  "screen.accounting.overview.finished.message": "{salutation}, {br} {br}Děkuji vám za kontrolu a schválení účtů. Níže naleznete Vaše zprávy.{br} {br} S pozdravem, {br}{podpis}",
  "screen.accounting.overview.reports": "{salutation}, {br} {br} V příloze naleznete následující dokumenty za {období}. Dokumenty byly připraveny na základě vámi poskytnutých informací a předložených dokumentů. Pokud v dokumentech zjistíte nějaké nepřesnosti nebo neúplnosti, dejte nám prosím vědět, abychom mohli provést příslušné opravy. {br} {br} V případě jakýchkoli dotazů nás neváhejte kontaktovat.{br} {br} S pozdravem, {br}{podpis}",
  "screen.accounting.overview.ticketsWelcome.inProgress": "{salutation}, {br} {br}Děkuji za předání poukázek. V průběhu účetnictví se objevily některé dotazy:",
  "screen.accounting.overview.welcome.closed": "{salutation}, {br} {br} Děkujeme za předložení všech relevantních podkladů. Zpracujeme je podle dohodnutých termínů a ozveme se vám s případnými dotazy.",
  "screen.accounting.overview.welcome.inProgress": "{{br} {br} Žádáme vás o nahrání rezervačních dokladů za {období}{datum splatnosti}. Zašlete nám prosím všechny potřebné dokumenty. {br} {br}S pozdravem, {br}{podpis}",
  "screen.accounting.records.banner.selectedAll": "Jsou vybrány všechny {count} soubory v \"{recordType}\".",
  "screen.accounting.records.banner.selectedAll.transferFailed": "Jsou vybrány všechny {count} neúspěšné soubory v \"{recordType}\".",
  "screen.accounting.records.banner.selectedCount": "Všechny {count} soubory na této stránce jsou vybrány",
  "screen.accounting.records.banner.totalCount": "Vyberte všechny {count} soubory v \"{recordType}\".",
  "screen.accounting.records.banner.totalCount.transferFailed": "Vyberte všechny {count} neúspěšné soubory v \"{recordType}\".",
  "screen.accounting.records.banner.unselect": "Zrušit výběr",
  "screen.accounting.records.documents": "Soubory:",
  "screen.accounting.records.empty": "Soubor je prázdný",
  "screen.accounting.records.filter.transferFailed": "Přenos se nezdařil",
  "screen.accounting.records.format.error": "Zkontrolujte formát",
  "screen.accounting.records.move.dialog.button.text": "Přesun",
  "screen.accounting.records.move.multiple.dialog.title": "Přesun dokumentů",
  "screen.accounting.records.move.single.dialog.title": "Přesun dokumentu",
  "screen.accounting.records.navbar.tab.list": "Příjmy ({počet})",
  "screen.accounting.records.navbar.tab.tickets": "Dotazy ({count})",
  "screen.accounting.records.person.responsible": "Odpovědnost",
  "screen.accounting.records.serverUpload.error": "Soubor se nepodařilo nahrát na server",
  "screen.accounting.records.serverUpload.typeError": "Formát není akceptován",
  "screen.accounting.records.sizeAndFormat.error": "Zkontrolujte velikost a formát souboru",
  "screen.accounting.records.size.error": "Zkontrolujte velikost souboru",
  "screen.accounting.records.tickets": "| Dotazy: {numTickets}",
  "screen.accounting.records.transfer.button.text": "Přenos do TPA",
  "screen.accounting.records.transfer.dialog.text": "Chcete přenést <b>všechny účetní</b> soubory za období {periodStart} - {periodEnd} do TPA k zaúčtování?",
  "screen.accounting.records.transfer.dialog.textRecordType": "Chcete přenést <b>všechny soubory \"{jméno}\"</b> za období {periodStart} - {periodEnd} do TPA k zaúčtování?",
  "screen.accounting.records.transfer.dialog.title": "Odesílání účetnictví",
  "screen.accounting.records.transfer.retryDialog.button.text": "Zkuste to znovu.",
  "screen.accounting.records.transfer.retryDialog.file": "Chyba při přenosu {failedFile}",
  "screen.accounting.records.transfer.retryDialog.text": "Jejda. Něco se tu pokazilo. Zkuste to prosím znovu.",
  "screen.accounting.records.transfer.retryDialog.title": "Převodovka selhala",
  "screen.accounting.records.transfer.successDialog.text": "Váš poradce TPA nyní údaje zkontroluje a bude vás kontaktovat s případnými dotazy.",
  "screen.accounting.records.transfer.successDialog.title": "Úspěšně přenesená data",
  "screen.accounting.records.types.header": "Účetnictví",
  "screen.accounting.records.unknown.error": "Neznámá chyba",
  "screen.accounting.records.upload.button": "Nahrát účtenky",
  "screen.accounting.records.upload.navbar.back": "Účetnictví / Poukázky",
  "screen.accounting.recordTypes.title": "Vyberte typ poukazů",
  "screen.advisor.tabbar.active": "Aktivní",
  "screen.advisor.tabbar.notConfigured": "Není nakonfigurováno",
  "screen.advisor.upload.approve.info": "Kdy potřebuji kvalifikovaný podpis?",
  "screen.advisor.uploads.upload.approve": "Uvolnění pro zákazníky",
  "screen.advisor.uploads.upload.approve.option.click": "Uvolnění na kliknutí",
  "screen.advisor.uploads.upload.approve.option.none": "Bez schválení zákazníkem",
  "screen.advisor.uploads.upload.approve.option.qes": "Uvolnění elektronickým podpisem",
  "screen.advisor.uploads.upload.buttonText": "Vysílání",
  "screen.advisor.uploads.upload.category": "Kategorie",
  "screen.advisor.uploads.upload.category.validation": "Vyberte kategorii",
  "screen.advisor.uploads.upload.comment": "Text přenosu",
  "screen.advisor.uploads.upload.comment.downloadError": "Chyba při načítání komentáře",
  "screen.advisor.uploads.upload.dueDate": "Splatnost dne",
  "screen.advisor.uploads.upload.dueDate.validation": "Zadejte prosím datum splatnosti",
  "screen.advisor.uploads.upload.global": "Celá společnost",
  "screen.advisor.uploads.upload.module": "Oblast",
  "screen.advisor.uploads.upload.module.validation": "Vyberte prosím oblast",
  "screen.advisor.uploads.upload.navBack": "Nahrávání",
  "screen.advisor.uploads.upload.navHeader": "Nahrávání souborů: {kategorie}",
  "screen.advisor.uploads.upload.notifyCustomer": "Upozornit zákazníky",
  "screen.advisor.uploads.upload.subsidiary.validation": "Vyberte prosím kód společnosti",
  "screen.advisor.uploads.upload.visibleToEmployee": "Uvolnění pro MA",
  "screen.auth.forgot_password_confirm.subtitle.section_1": "Pokud jsme našli uživatele s e-mailovou adresou {email}, brzy obdržíte e-mail s odkazem na obnovení hesla.",
  "screen.auth.forgot_password_confirm.subtitle.section_2": "Nedostali jste žádnou poštu? Zkontrolujte složku spamu nebo se obraťte na správce systému.",
  "screen.auth.forgot_password_confirm.title": "Brzy obdržíte e-mail",
  "screen.auth.forgot_password_email.form.button": "Odeslat odkaz na obnovení",
  "screen.auth.forgot_password_email.form.label.email": "ID uživatele / e-mailová adresa",
  "screen.auth.forgot_password_email.subtitle": "Zadejte svou e-mailovou adresu a my vám zašleme e-mail s odkazem na obnovení hesla.",
  "screen.auth.forgot_password_email.title": "Obnovení hesla",
  "screen.auth.forgot_password_email.validation_error": "Zadejte prosím svou e-mailovou adresu",
  "screen.auth.forgot_password_reset.error.password_not_set": "Zadejte prosím heslo",
  "screen.auth.forgot_password_reset.error.passwords_do_not_match": "Hesla se neshodují",
  "screen.auth.forgot_password_reset.error.password_weak": "Vaše heslo je stále příliš slabé.",
  "screen.auth.forgot_password_reset.form.button": "Změna hesla",
  "screen.auth.forgot_password_reset.form.button.mfa": "Odeslat kód",
  "screen.auth.forgot_password_reset.form.button.mfa.resend_code": "Opětovné odeslání kódu",
  "screen.auth.forgot_password_reset.form.mfa": "Kód",
  "screen.auth.forgot_password_reset.form.password": "Heslo",
  "screen.auth.forgot_password_reset.form.repeat_password": "Opakování hesla",
  "screen.auth.forgot_password_reset.subtitle": "Nastavte nové heslo pro účet TPA Connect.",
  "screen.auth.forgot_password_reset.subtitle.mfa": "Zadejte prosím kód, který jsme vám zaslali na vaše telefonní číslo prostřednictvím SMS.",
  "screen.auth.forgot_password_reset.title": "Změna hesla",
  "screen.auth.forgot_password_reset.title.mfa": "Potvrzení změny hesla pomocí dvoufaktorového ověřování",
  "screen.auth.registration.form.button": "Registrace",
  "screen.auth.registration.form.button.mfa": "Odeslat kód",
  "screen.auth.registration.form.button.mfa.resendCode": "Opětovné odeslání kódu",
  "screen.auth.registration.form.label.mfa": "Kód",
  "screen.auth.registration.form.label.password": "Heslo",
  "screen.auth.registration.form.label.repeat_password": "Opakování hesla",
  "screen.auth.registration.form.section_1": "Použijte alespoň 8 znaků.",
  "screen.auth.registration.form.section_2": "Heslo můžete posílit použitím velkých a malých písmen, čísel a speciálních znaků.",
  "screen.auth.registration.form.section_3": "Vymyslete si mnemotechnickou pomůcku, která vám pomůže zapamatovat si heslo.",
  "screen.auth.registration.mfa.subtitle": "Zadejte prosím kód, který jsme vám zaslali na vaše telefonní číslo prostřednictvím SMS.",
  "screen.auth.registration.subtitle": "Nastavte heslo pro svůj účet TPA Connect.",
  "screen.auth.registration.title": "Nastavení a registrace hesla",
  "screen.auth.welcome.subtitle": "Propojte svůj účet TPA Connect s účtem Microsoft, abyste mohli plně využívat všechny funkce. Účet můžete propojit i později.",
  "screen.auth.welcome.subtitle.msOnly": "Propojte svůj účet TPA Connect s účtem Microsoft, abyste mohli plně využívat všechny funkce.",
  "screen.auth.welcome.title": "Vítejte v TPA Connect!",
  "screen.changeUsername.checkbox": "Tímto potvrzuji svou novou e-mailovou adresu",
  "screen.changeUsername.message": "Výpočetní centrum aplikace TPA Connect zjistilo, že se vaše e-mailová adresa změnila na <b>{nové uživatelské jméno}</b>. Abychom si mohli být naprosto jisti, že je tato změna správná, je nutné ji potvrdit jediným kliknutím.",
  "screen.changeUsername.title": "Nová e-mailová adresa",
  "screen.cockpit.allEvents": "Události: Všechny události",
  "screen.cockpit.allNews": "Novinky: Všechny články",
  "screen.cockpit.dueDates.export": "Lhůty pro vývoz",
  "screen.cockpit.dueDates.navbar.heading": "Otevřené termíny",
  "screen.cockpit.dueDates.projects.text": "Vydání pro {název dokumentu}",
  "screen.cockpit.dueDates.records.accounting.text": "Zveřejnění účetních informací pro {název dceřiné společnosti} - {období}",
  "screen.cockpit.dueDates.records.hr.text": "Zveřejnění údajů o mzdách pro {název dceřiné společnosti} - {období}",
  "screen.cockpit.dueDates.reports.global.text": "Vydání zprávy pro \"celopodnikové zprávy\" - {období}",
  "screen.cockpit.dueDates.reports.subsidiary.text": "Vydání zprávy pro {název dceřiné společnosti} - {období}",
  "screen.cockpit.dueDates.reportUpload.bmd.message": "K poskytování zpráv používejte BMD NTCS",
  "screen.cockpit.dueDates.reportUpload.bmd.title": "Nahrání zprávy",
  "screen.cockpit.dueDates.reportUpload.global.text": "Nahrávání zpráv pro \"celopodnikové zprávy\" - {období}",
  "screen.cockpit.dueDates.reportUpload.subsidiary.text": "Nahrání hlášení pro {název dceřiné společnosti} - {období}",
  "screen.cockpit.dueDates.tickets.global.text": "Lhůta pro nahlédnutí do dokumentu {jméno dokumentu}",
  "screen.cockpit.dueDates.tickets.subsidiary.text": "Lhůta pro nahlédnutí do dokumentu {jméno dokumentu} pro {jméno dceřiné společnosti}",
  "screen.cockpit.emptyState.message": "Vraťte se později",
  "screen.cockpit.emptyState.title": "V současné době nejsou žádné novinky",
  "screen.cockpit.eventDetail": "Detail události",
  "screen.cockpit.eventDetails.heading": "Podrobnosti a registrace",
  "screen.cockpit.eventDetails.lecturer.email": "E: {email}",
  "screen.cockpit.eventDetails.lecturers": "Přednášející",
  "screen.cockpit.eventDetails.lecturer.telephone": "T: {telefon}",
  "screen.cockpit.eventDetails.register": "Zaregistrujte se nyní",
  "screen.cockpit.event.moreInformation": "INFORMACE A REGISTRACE",
  "screen.cockpit.kpis.add": "Přidání klíčového údaje",
  "screen.cockpit.kpis.chart.legend": "v 1000 EUR",
  "screen.cockpit.kpis.configurationDialog.insuranceCarrierBalance.label": "Zdravotní pojištění",
  "screen.cockpit.kpis.configurationDialog.metric.label": "KPI",
  "screen.cockpit.kpis.configurationDialog.months.label": "Měsíce",
  "screen.cockpit.kpis.configurationDialog.title": "Konfigurace widgetu KPI",
  "screen.cockpit.kpis.ebit.chart.name": "Vývoj EBIT",
  "screen.cockpit.kpis.ebit.kpi.name": "Dosavadní EBIT",
  "screen.cockpit.kpis.ebit.kpi.tooltip": "Ukazuje hodnotu EBIT za všechny měsíce, které již byly plně zaúčtovány od začátku hospodářského roku.",
  "screen.cockpit.kpis.empty": "Váš kód společnosti \"{jméno}\" není momentálně k dispozici.",
  "screen.cockpit.kpis.insuranceCarrier.balance.kpi.name": "Zůstatek zdravotního pojištění",
  "screen.cockpit.kpis.noneConfigured": "Přidání klíčového údaje",
  "screen.cockpit.kpis.revenue.chart.name": "Trend obratu",
  "screen.cockpit.kpis.revenue.kpi.name": "Dosavadní obrat",
  "screen.cockpit.kpis.revenue.kpi.tooltip": "Ukazuje obrat všech již plně zaúčtovaných měsíců od začátku obchodního roku.",
  "screen.cockpit.kpis.save": "Použít změny",
  "screen.cockpit.kpis.svsAccount.kpi.info": "",
  "screen.cockpit.kpis.svsAccount.kpi.label": "",
  "screen.cockpit.kpis.svsAccount.kpi.name": "",
  "screen.cockpit.kpis.taxAccount.kpi.arrears": "Nevyřízené záležitosti",
  "screen.cockpit.kpis.taxAccount.kpi.credit": "Úvěrová poznámka",
  "screen.cockpit.kpis.taxAccount.kpi.label": "Stav k {datu}",
  "screen.cockpit.kpis.taxAccount.kpi.lastCompletedPayment": "[částka] naposledy vyplacena dne {datum}",
  "screen.cockpit.kpis.taxAccount.kpi.name": "Zůstatek daňového účtu",
  "screen.cockpit.kpis.unpaidAccount.kpi.lastCompletedPayment": "Již zaplaceno dne {date}",
  "screen.cockpit.kpis.unpaidAccounts.kpi.caption": "{invoicesCount} Faktury",
  "screen.cockpit.kpis.unpaidAccounts.kpi.label": "Stav k {datu}",
  "screen.cockpit.kpis.unpaidAccounts.kpi.name": "Neuhrazené faktury u TPA",
  "screen.cockpit.navbar.heading": "Kokpit",
  "screen.cockpit.overview.kpis.title": "Fakta a čísla",
  "screen.cockpit.overview.newestPublications": "Nedávné publikace",
  "screen.cockpit.overview.notifications.dueDates": "Otevřené termíny",
  "screen.cockpit.overview.notifications.messages": "Zprávy",
  "screen.cockpit.overview.notifications.tickets": "Dotazy",
  "screen.cockpit.overview.notifications.title": "Oznámení",
  "screen.cockpit.overview.promotions.title": "Zprávy",
  "screen.cockpit.overview.relevantNews.title": "Relevantní zprávy",
  "screen.cockpit.overview.relevantPublications.description": "Můžete si vyžádat tištěnou publikaci TPA, která vám bude zaslána během několika dní. Nebo: Můžete si stáhnout elektronickou verzi (PDF) požadované publikace TPA.",
  "screen.cockpit.overview.relevantPublications.title": "Příslušné publikace",
  "screen.cockpit.promotion": "Novinky: Článek",
  "screen.cockpit.promotion.additionalNews": "Zprávy",
  "screen.cockpit.relevantNews": "Novinky: Článek",
  "screen.cockpit.relevantNews.additionalNews": "Další novinky",
  "screen.cockpit.tickets.navbar.heading": "Otevřené dotazy",
  "screen.hr.additionaldata.addBonus": "Přidejte další příspěvek",
  "screen.hr.additionaldata.additionaldata": "Doplňující informace",
  "screen.hr.additionaldata.additionalInformation": "Další informace",
  "screen.hr.additionaldata.amount": "Částka v {měně} za měsíc",
  "screen.hr.additionaldata.amount_error": "Zadejte prosím částku",
  "screen.hr.additionaldata.bankDetails": "Bankovní údaje",
  "screen.hr.additionaldata.bic": "BIC",
  "screen.hr.additionaldata.bic.validation": "Neplatný kód BIC",
  "screen.hr.additionaldata.commuterAllowance": "Příspěvek na dojíždění",
  "screen.hr.additionaldata.costCenter": "Nákladové středisko",
  "screen.hr.additionaldata.costCenterNote": "Poznámka k nákladovému středisku",
  "screen.hr.additionaldata.description": "Při nástupu do služby lze případně vyplnit i následující údaje.",
  "screen.hr.additionaldata.designator": "Příspěvek",
  "screen.hr.additionaldata.designator_error": "Uveďte prosím výši příspěvku",
  "screen.hr.additionaldata.emailAddress": "E-mailová adresa",
  "screen.hr.additionaldata.email_error": "Zadejte prosím správnou e-mailovou adresu",
  "screen.hr.additionaldata.familyBonusPlusAmount": "Fabo Plus Částka v EUR",
  "screen.hr.additionaldata.familyBonusPlusAmount_error": "Zadejte prosím alespoň 0",
  "screen.hr.additionaldata.funder": "Nákladová jednotka",
  "screen.hr.additionaldata.gross": "Hrubý",
  "screen.hr.additionaldata.hours_error": "Zadejte prosím číslo mezi {minHours} a {maxHours}.",
  "screen.hr.additionaldata.iban": "IBAN",
  "screen.hr.additionaldata.iban_error": "Neplatný IBAN",
  "screen.hr.additionaldata.jobDescription": "Název práce",
  "screen.hr.additionaldata.kilometersForCommuterEuro": "Kilometry pro dojíždění do práce v eurech",
  "screen.hr.additionaldata.kilometersForCommuterEuro_error": "Zadejte prosím alespoň 0",
  "screen.hr.additionaldata.kilometersForCommuterEuro_typeerror": "Kilometry lze uvádět pouze v celých číslech.",
  "screen.hr.additionaldata.maritalStatus": "Rodinný stav",
  "screen.hr.additionaldata.monthlySalary": "Plat v {měně} za měsíc",
  "screen.hr.additionaldata.monthlySalary_error": "Zadejte prosím alespoň 0",
  "screen.hr.additionaldata.net": "Net",
  "screen.hr.additionaldata.nextPage": "Pokračovat na dokumenty",
  "screen.hr.additionaldata.number_error": "Zadejte prosím číslo",
  "screen.hr.additionaldata.per": "na",
  "screen.hr.additionaldata.personaldata": "Osobní údaje",
  "screen.hr.additionaldata.religiousConfession": "Náboženské vyznání",
  "screen.hr.additionaldata.salarayAndPosition": "Odměna a pozice",
  "screen.hr.additionaldata.saveChanges": "Uložení změn",
  "screen.hr.additionaldata.soleEarnerStatus": "Jediná výdělečně činná osoba",
  "screen.hr.additionaldata.titelAndAcademicDegrees": "Tituly a akademické hodnosti",
  "screen.hr.additionaldata.titlePrefix": "Předpona",
  "screen.hr.additionaldata.titleSuffix": "Níže",
  "screen.hr.additionaldata.wageAgreementClassification": "Klasifikační kolektivní smlouva",
  "screen.hr.additionaldata.weeklyWorkDays": "Počet pracovních dnů v týdnu",
  "screen.hr.additionaldata.weeklyWorkDays_error": "Vyberte prosím platnou hodnotu",
  "screen.hr.additionaldata.workingHours": "Počet hodin",
  "screen.hr.additionaldata.workingHoursInterval": "Období",
  "screen.hr.additionaldata.workingTimes": "Pracovní doba",
  "screen.hr.cancelRegistration.dataSaved": "Pokud zadaná data zrušíte, zůstanou uložena. V registraci lze pokračovat později.",
  "screen.hr.cancelRegistration.header": "Zrušení registrace",
  "screen.hr.cancelRegistration.question1": "Opravdu chcete zrušit proces registrace? Dosud zadané údaje můžete použít k tomu, abyste mohli",
  "screen.hr.cancelRegistration.question2": " a předložte ji svému daňovému poradci TPA k registraci.",
  "screen.hr.deregistration.data.navback": "Zaměstnanci / Zaměstnanci / Registrovaní / {jméno} {příjmení}",
  "screen.hr.deregistration.documents.navback": "Zaměstnanci / Zaměstnanci / Registrovaní / {jméno} {příjmení} / Údaje o zrušení registrace",
  "screen.hr.deregistration.navheader": "{jméno} {jméno} odhlásit se z odběru",
  "screen.hr.deregistration.validation.deregistrationDate": "Zadejte prosím datum odjezdu",
  "screen.hr.deregistration.validation.deregistrationDateMin": "Datum výstupu musí být vyšší než datum vstupu.",
  "screen.hr.deregistration.validation.deregistrationReason": "Uveďte důvod odchodu",
  "screen.hr.deregistration.validation.remainingHolidays": "Uveďte prosím zbývající dovolenou",
  "screen.hr.deregistration.validation.remainingOvertime": "Uveďte, prosím, volné přesčasy",
  "screen.hr.documents.citizenship": "Osvědčení o státním občanství",
  "screen.hr.documents.contract": "Servicecontract",
  "screen.hr.documents.description": "Nahrajte další dokumenty, například pracovní smlouvu, občanský průkaz, doklad o občanství, registrační formulář nebo jiné dokumenty.",
  "screen.hr.documents.emptystate.buttonlabel": "Nahrát dokumenty",
  "screen.hr.documents.emptystate.message": "Pro tohoto zaměstnance nejsou k dispozici žádné dokumenty",
  "screen.hr.documents.emptystate.title": "Žádné dokumenty nejsou k dispozici",
  "screen.hr.documents.enterManually": "Ruční zadávání dat",
  "screen.hr.documents.idcard": "Osobníprůkaz totožnosti",
  "screen.hr.documents.logTime": "Rekordní časy",
  "screen.hr.documents.needsRelease.notReleased": "Čekání na vydání",
  "screen.hr.documents.needsRelease.released": "uvolněno",
  "screen.hr.documents.needsRelease.released.tooltip": "vydáno dne {releasedAt} podle {jména}",
  "screen.hr.documents.nextButton": "Ke shrnutí",
  "screen.hr.documents.nonworkingtimes.emptystate.message": "Začněte nahrávat své soubory nyní nebo zadejte časy, kdy se neprovádí, ručně.",
  "screen.hr.documents.others": "Další",
  "screen.hr.documents.registrationslip": "Zprávalist",
  "screen.hr.documents.uploadDocuments": "Nahrát dokumenty",
  "screen.hr.edit.flow.navback": "Zaměstnanci / Zaměstnanci / TODO: Stav / {jméno} {příjmení}",
  "screen.hr.edit.flow.navheader": "{jméno} {příjmení} editovat",
  "screen.hr.employee.current.all": "Všechny",
  "screen.hr.employee.current.anyDateOfJoining": "Jakékoli datum vstupu",
  "screen.hr.employee.current.anyDateOfJoiningExiting": "Jakékoli datum vstupu/výstupu",
  "screen.hr.employee.current.cancellation": "Zrušení registrace",
  "screen.hr.employee.current.changeUsername": "Nový e-mail",
  "screen.hr.employee.current.changeUsername.successDialog.message": "<b>{jméno uživatele}</b> obdrží žádost o potvrzení nově zadané e-mailové adresy.",
  "screen.hr.employee.current.changeUsername.successDialog.title": "E-mail pro změnu e-mailové adresy byl úspěšně odeslán.",
  "screen.hr.employee.current.changeUsername.tooltip": "Nová e-mailová adresa zaměstnance je {newUsername}. Kliknutím na tlačítko je zaměstnanec vyzván k potvrzení nové e-mailové adresy.",
  "screen.hr.employee.current.dataChange": "Změny hlavních údajů",
  "screen.hr.employee.current.notTransferred": "Nepředává se",
  "screen.hr.employee.current.registration": "Registrace",
  "screen.hr.employee.deregistration.data.date": "Datum odchodu",
  "screen.hr.employee.deregistration.data.header": "Údaje o odhlášení",
  "screen.hr.employee.deregistration.data.reason": "Důvod odchodu",
  "screen.hr.employee.deregistration.data.remainingHolidays": "Zbývající dovolená ve dnech",
  "screen.hr.employee.deregistration.data.remainingOvertime": "Otevřené přesčasy",
  "screen.hr.employee.deregistration.data.subtitle": "Pokud jsou k dispozici, nahrajte výstupní dokumenty ke konečnému vypořádání.",
  "screen.hr.employee.deregistration.documents.submitButton": "Zrušení registrace přenosu",
  "screen.hr.employee.deregistration.stepper.data": "Údaje o odhlášení",
  "screen.hr.employee.deregistration.stepper.documents": "Výstupní dokumenty",
  "screen.hr.employee.details.addFiles": "Přidat dokumenty",
  "screen.hr.employee.details.currentEmployment": "Současný pracovní poměr",
  "screen.hr.employee.details.deregisterEmployee": "Odhlášení zaměstnance",
  "screen.hr.employee.details.employments": "Pracovní vztahy",
  "screen.hr.employee.details.fileProvidedBy": "poskytl {jméno}",
  "screen.hr.employee.details.fileReleased": "Viditelnost pro zaměstnance",
  "screen.hr.employee.details.linkedFiles": "Propojené soubory",
  "screen.hr.employee.details.navBack": "Zaměstnanci / {list}",
  "screen.hr.employee.details.pastEmployments": "Předchozí zaměstnání",
  "screen.hr.employee.details.payrollAccount.navBack": "Personál / Aktuální DN / {plné jméno}",
  "screen.hr.employee.details.personnelFile": "Osobní spis",
  "screen.hr.employee.details.personnelNumber": "Číslo personálu:",
  "screen.hr.employee.details.records": "Doklad o příjmech",
  "screen.hr.employee.details.reregisterEmployee": "Přeregistrace zaměstnance",
  "screen.hr.employee.details.showAll": "Zobrazit všechny",
  "screen.hr.employee.details.showEmployeePayrollAccount": "Zobrazení mzdového účtu",
  "screen.hr.employee.details.status": "Stav",
  "screen.hr.employee.details.weeklyWorkDays": "{weeklyWorkDays} dnů v týdnu",
  "screen.hr.employee.details.workingHours": "{workingHours} hodin za {workingHoursInterval};",
  "screen.hr.employee.documents.all": "Všechny",
  "screen.hr.employee.documents.annualPayslips": "Roční výplatní páska",
  "screen.hr.employee.documents.anyDate": "Jakékoli období",
  "screen.hr.employee.documents.backTargetDescription": "Zaměstnanci / Zaměstnanci / {stav} / {jméno} {příjmení}",
  "screen.hr.employee.documents.certificateOfCitizenship": "Doklad o občanství",
  "screen.hr.employee.documents.certificatesOfRegistration": "Potvrzení o registraci",
  "screen.hr.employee.documents.contracts": "Smlouva o poskytování služeb",
  "screen.hr.employee.documents.deregisterDocument": "Doklady o zrušení registrace",
  "screen.hr.employee.documents.employeeDocuments": "Soubory od {jméno} {příjmení}",
  "screen.hr.employee.documents.employmentPermit": "Pracovní povolení",
  "screen.hr.employee.documents.generalDocuments": "Další",
  "screen.hr.employee.documents.identityCard": "Průkaz totožnosti",
  "screen.hr.employee.documents.payslips": "Doklad o příjmech",
  "screen.hr.employee.emptystate.buttonlabel": "Rekordní zaměstnanci",
  "screen.hr.employee.emptystate.message": "Začněte registrovat nové zaměstnance již nyní",
  "screen.hr.employee.emptystate.title": "Registrace zaměstnanců",
  "screen.hr.employee.former.anyDateOfLeaving": "Jakékoli datum výstupu",
  "screen.hr.employee.registration.stepper.additional": "Doplňující informace",
  "screen.hr.employee.registration.stepper.documents": "Nahrát dokumenty",
  "screen.hr.employee.registration.stepper.personal": "Osobní údaje",
  "screen.hr.employee.registration.stepper.status": "Krok {activeStep} z {totalSteps}",
  "screen.hr.employee.registration.stepper.summary": "Souhrn",
  "screen.hr.employees.current.registerEmployee": "Registrovat zaměstnance",
  "screen.hr.employees.current.uploadPayslip": "Nahrát výplatní pásku",
  "screen.hr.employees.documents.recordType.title": "Soubory nahrané do personálního souboru v {date}",
  "screen.hr.employees.documents.title": "Soubory v osobní složce",
  "screen.hr.navbar.documents": "Soubory",
  "screen.hr.navbar.employees": "Zaměstnanci",
  "screen.hr.navbar.heading": "Zaměstnanci",
  "screen.hr.navbar.overview": "Přehled",
  "screen.hr.navbar.tabbar.current": "Aktuální DN",
  "screen.hr.navbar.tabbar.documents": "Soubory",
  "screen.hr.navbar.tabbar.employees": "Zaměstnanci",
  "screen.hr.navbar.tabbar.former": "DN ve výslužbě",
  "screen.hr.navbar.tabbar.inprogress": "Probíhá",
  "screen.hr.navbar.tabbar.preRegistartionTickets": "Dotazy ({preRegistrationTicketCount})",
  "screen.hr.overview.card.analysis": "Zveřejnění měsíčních hodnocení pro",
  "screen.hr.overview.card.billing": "Zveřejnění měsíčních výkazů pro",
  "screen.hr.overview.card.globalAnalysis": "Zveřejnění celopodnikových hodnocení",
  "screen.hr.overview.card.globalBilling": "Uvolnění celopodnikových vyrovnání",
  "screen.hr.overview.card.overview": "Zveřejnění měsíčních personálních údajů pro",
  "screen.hr.overview.card.registration": "Registrace/odhlášení zaměstnanců",
  "screen.hr.overview.finished.message": "{salutation}, {br} {br}Děkuji za kontrolu a schválení výplatní listiny. Níže naleznete Vaše zprávy.{br} {br} S pozdravem, {br}{podpis}",
  "screen.hr.overview.registration": "{salutation}, {br} {br} prosíme o oznámení případných změn uživatelů služeb, abychom mohli provést registraci a zrušení registrace.{br} {br} S pozdravem,{br} {signature}.",
  "screen.hr.overview.registration.button": "Registrace/odhlášení zaměstnanců",
  "screen.hr.overview.ticketsWelcome.inProgress": "{salutation}, {br} {br}Děkujeme za zaslání osobních údajů. V průběhu zpracování se objevily některé dotazy:",
  "screen.hr.overview.welcome.closed": "{salutation}, {br} {br} Děkujeme za zaslání všech relevantních personálních údajů. Zpracujeme je v souladu s dohodnutými lhůtami a budeme vás kontaktovat v případě jakýchkoli dotazů.",
  "screen.hr.overview.welcome.inProgress": "{plat}, {br} {br} Žádám Vás, abyste mi poskytli všechny potřebné údaje o zaměstnancích pro výplatní termín {období}{datum splatnosti}. {br}{br}S pozdravem,{br}{podpis}",
  "screen.hr.personaldata.address": "Ulice a číslo domu",
  "screen.hr.personaldata.addressAddition": "Přípona adresy",
  "screen.hr.personaldata.addressHead": "Adresa",
  "screen.hr.personaldata.area.validation": "Uveďte prosím místo",
  "screen.hr.personaldata.birthdate.validation": "Zadejte prosím datum narození",
  "screen.hr.personaldata.citizenship": "Občanství",
  "screen.hr.personaldata.citizenship.validation": "Uveďte prosím státní příslušnost",
  "screen.hr.personaldata.city": "Umístění",
  "screen.hr.personaldata.country": "Země",
  "screen.hr.personaldata.country.validation": "Vyberte prosím zemi",
  "screen.hr.personaldata.dateOfBirth": "Datum narození",
  "screen.hr.personaldata.dateOfJoining": "Datum vstupu",
  "screen.hr.personaldata.description": "Před zahájením služby je nutné uvést následující údaje.",
  "screen.hr.personaldata.diverse": "Potápěči",
  "screen.hr.personaldata.employmentInsuranceType": "Pojišťovací skupina",
  "screen.hr.personaldata.employmentRelationship": "Pracovní poměr",
  "screen.hr.personaldata.employmenttypeheader": "Typ zaměstnání",
  "screen.hr.personaldata.employmenttypeinformationbody": "Všechny hrubé měsíční příjmy nad hranicí nízkého příjmu se považují za povinně plně pojištěné.",
  "screen.hr.personaldata.employmenttypeinformationhead": "Plně pojištěné / okrajově pojištěné:",
  "screen.hr.personaldata.employmenttype.validation": "Vyberte prosím pracovní poměr",
  "screen.hr.personaldata.entrydate.invalid": "Neplatné datum zadání",
  "screen.hr.personaldata.entrydate.validation": "Zadejte prosím datum vstupu",
  "screen.hr.personaldata.female": "Samice",
  "screen.hr.personaldata.firstname": "Křestní jméno",
  "screen.hr.personaldata.firstname.validation": "Zadejte prosím křestní jméno",
  "screen.hr.personaldata.fullTime": "Plně pojištěné",
  "screen.hr.personaldata.houseNumber": "Číslo domu",
  "screen.hr.personaldata.houseNumber.validation": "Zadejte prosím číslo domu",
  "screen.hr.personaldata.insurancedata": "Údaje o pojištění",
  "screen.hr.personaldata.insurancedatainformationbody": "Pokud není uvedeno číslo SI, ponechte pole pro číslo národního pojištění prázdné.",
  "screen.hr.personaldata.insurancedatainformationhead": "Nápověda:",
  "screen.hr.personaldata.insurancetype.validation": "Vyberte prosím pojištění",
  "screen.hr.personaldata.lastname": "Příjmení",
  "screen.hr.personaldata.lastname.validation": "Zadejte prosím příjmení",
  "screen.hr.personaldata.male": "Muži",
  "screen.hr.personaldata.marginally": "Drobné",
  "screen.hr.personaldata.navback": "Zaměstnanci / zaměstnanci",
  "screen.hr.personaldata.navheader": "Registrace zaměstnanců",
  "screen.hr.personaldata.nextpage": "Další doplňující informace",
  "screen.hr.personaldata.notSpecified": "Není specifikováno",
  "screen.hr.personaldata.personaldata": "Osobní údaje",
  "screen.hr.personaldata.personalinformation": "Osobní údaje",
  "screen.hr.personaldata.previouslyEmployed": "Byla u vás tato osoba někdy v posledních 12 měsících zaměstnána?",
  "screen.hr.personaldata.socialsecuritynumber.validation": "Uveďte prosím číslo národního pojištění",
  "screen.hr.personaldata.ssn": "Číslo národního pojištění",
  "screen.hr.personaldata.state.validation": "Zadejte prosím federální stát",
  "screen.hr.personaldata.streetnr.validation": "Zadejte prosím adresu",
  "screen.hr.personaldata.workplaceState": "Stav pracoviště",
  "screen.hr.personaldata.zipCode": "POSTCODE",
  "screen.hr.personaldata.zipcode.length.validation": "",
  "screen.hr.personaldata.zipcode.validation": "Zadejte prosím poštovní směrovací číslo",
  "screen.hr.records.move.multiple.dialog.title": "Přesun souborů",
  "screen.hr.records.move.single.dialog.title": "Přesun souboru",
  "screen.hr.records.navbar.tab.list": "Soubory ({count})",
  "screen.hr.records.navbar.tab.tickets": "Dotazy ({count})",
  "screen.hr.records.transfer.dialog.text": "Chcete přenést <b>všechny mzdové soubory a informace</b> za období {periodStart} - {periodEnd} do TPA k zaúčtování?",
  "screen.hr.records.transfer.dialog.textRecordType": "Chcete přenést <b>všechny soubory a informace z \"{jméno}\"</b> za období {periodStart} - {periodEnd} do TPA k zaúčtování?",
  "screen.hr.records.transfer.dialog.title": "Přenos souborů",
  "screen.hr.records.types.header": "Zaměstnanci",
  "screen.hr.records.upload.button": "Nahrávání souborů",
  "screen.hr.records.upload.navbar.back": "Personál / Soubory",
  "screen.hr.recordTypes.title": "Výběr typu dokumentů",
  "screen.hr.registration.flow.navback": "Krok zpět",
  "screen.hr.registration.flow.navheader": "{jméno} {příjmení} přihlásit se",
  "screen.hr.summary.additionalinformation": "Doplňující informace",
  "screen.hr.summary.bonuses": "Příspěvky",
  "screen.hr.summary.changeData": "Přidat do seznamu",
  "screen.hr.summary.completeAdditionalData": "Poskytli jste všechny doplňující informace.",
  "screen.hr.summary.completePersonalData": "Zadali jste všechny povinné údaje pro registraci u regionální zdravotní pojišťovny.",
  "screen.hr.summary.day": "Den",
  "screen.hr.summary.daysPerWeek": "Dny v týdnu",
  "screen.hr.summary.description": "Zkontrolujte, zda jsou poskytnuté informace správné, abyste je mohli v dalším kroku předložit agentuře TPA.",
  "screen.hr.summary.dialogQuestion1": "Chcete",
  "screen.hr.summary.dialogQuestion2": " a poslat ji svému daňovému poradci TPA k registraci?",
  "screen.hr.summary.documents": "Soubory",
  "screen.hr.summary.gender": "Pohlaví",
  "screen.hr.summary.grossSalary": "Hrubá mzda v {měně}",
  "screen.hr.summary.hours": "Hodiny",
  "screen.hr.summary.incompleteAdditionalData": "Ještě jste nezadali všechny doplňující údaje. Vezměte prosím na vědomí, že některé další údaje mohou být vyžadovány až do první výplaty mzdy. Ty můžete kdykoli přidat.",
  "screen.hr.summary.month": "Měsíc",
  "screen.hr.summary.name": "{jméno} {příjmení}",
  "screen.hr.summary.netSalary": "Čistá mzda v {měně}",
  "screen.hr.summary.noPreregistration": "Žádná základní data nebyla změněna. Přenos dat není nutný.",
  "screen.hr.summary.openDialog": "Odeslat žádost",
  "screen.hr.summary.summary": "Souhrn",
  "screen.hr.summary.transferDataChange": "Přenos změny základních údajů",
  "screen.hr.summary.transferEmployeeDialog.success.subtitle": " byla vytvořena a předána daňovému poradci TPA k registraci.",
  "screen.hr.summary.transferEmployeeDialog.success.subtitle.elda": "{jméno} bylo vytvořeno. Protokol ELDA si můžete prohlédnout a stáhnout níže. Případně lze protokol stáhnout z osobního souboru zaměstnance.",
  "screen.hr.summary.week": "Týden",
  "screen.login.button.forgot_password": "ZAPOMENUTÉ HESLO?",
  "screen.login.button.resendCode": "Opětovné odeslání kódu",
  "screen.login.form.divider": "nebo",
  "screen.login.form.multiFactorAuth.label": "Kód",
  "screen.login.form.multiFactorAuth.validation_error": "Zadejte prosím kód.",
  "screen.login.form.password.label": "Heslo",
  "screen.login.form.password.validation_error": "Zadejte prosím heslo",
  "screen.login.form.submit": "Přihlášení",
  "screen.login.form.title": "Přihlášení",
  "screen.login.form.username.label": "ID uživatele / e-mailová adresa",
  "screen.login.form.username.validation_error": "Zadejte prosím své ID uživatele",
  "screen.login.multiFactorAuth": "Zadejte prosím kód, který jsme vám zaslali na vaše telefonní číslo prostřednictvím SMS.",
  "screen.login.welcome": "Vítejte na našich webových stránkách! Zadejte prosím svou e-mailovou adresu.",
  "screen.module.overview.report.empty.message": "Zatím nebyly nahrány žádné zprávy",
  "screen.module.overview.report.empty.title": "Žádné zprávy nejsou k dispozici",
  "screen.module.overview.report.upload": "Nahrát zprávu",
  "screen.moduleReportUpload.comment": "Text přenosu",
  "screen.moduleReportUpload.title": "Nahrávání zpráv",
  "screen.not_found.button.text": "Spuštění z kokpitu",
  "screen.not_found.header": "Chyba 404",
  "screen.not_found.message": "S čísly to opravdu umíme, ale vlastně jsme vás toho chtěli ušetřit.",
  "screen.overview.salutation.female": "Vážená paní {příjmení}",
  "screen.overview.salutation.general": "Vážené dámy a pánové",
  "screen.overview.salutation.male": "Vážený pane {příjmení}",
  "screen.overview.salutation.notSpecified": "Dobrý den {jméno} {příjmení}",
  "screen.records.upload.header": "Nahrávání souborů {recordType}",
  "screen.records.upload.message": "Nyní začněte nahrávat první soubory.",
  "screen.records.upload.title": "Nahrát {recordType}",
  "screen.server_error.button.text": "Do kokpitu",
  "screen.server_error.header": "505 Chyba",
  "screen.server_error.message": "S čísly to opravdu umíme, ale vlastně jsme vás toho chtěli ušetřit.",
  "screen.termsNotAccepted.button": "Zpět na přehled společnosti",
  "screen.termsNotAccepted.buttonAcceptNow": "Přijmout nyní",
  "screen.termsNotAccepted.header": "Souhlas s podmínkami používání do doby, než budou přijaty",
  "screen.termsNotAccepted.message": "Podmínky používání vaší společnosti dosud nebyly přijaty. V případě dotazů se obraťte na svého poradce TPA.",
  "screen.termsOfUse.accept": "Podepsat",
  "screen.termsOfUse.alreadySignedInfo": "Podmínky používání již byly přijaty nebo jejich přijetí již není nutné.",
  "screen.termsOfUse.backToLogin": "Zpět na přihlášení",
  "screen.termsOfUse.checkbox": "Tímto přijímám podmínky používání",
  "screen.termsOfUse.success.sign": "Podmínky používání úspěšně přijaty",
  "screen.termsOfUse.title": "Přijmout podmínky používání",
  "screen.termsOfUseWithCompanyNames.title": "Přijmout podmínky použití pro společnosti [počet]",
  "screen.termsOfUseWithCompanyName.title": "Přijmout podmínky používání",
  "screen.tickets.navbar.tabbar.closed": "Hotovo",
  "screen.tickets.navbar.tabbar.open": "Otevřít",
  "screen.tickets.navbar.tabbar.reported": "Poskytuji já",
  "screen.upgradeToMicrosoft.error.azureUserAlreadyExists": "Tento uživatel společnosti Microsoft je již registrován pod e-mailovou adresou {existingUsername}.",
  "screen.upgradeToMicrosoft.feature.projects.text": "Chcete-li získat přístup k funkci \"Projekty\", aktualizujte svůj účet.",
  "screen.upgradeToMicrosoft.form.code.description": "Zadejte prosím kód, který jsme vám zaslali SMS zprávou, a pokračujte v aktualizaci Microsoft.",
  "screen.upgradeToMicrosoft.form.code.label": "Kód",
  "screen.upgradeToMicrosoft.form.password.label": "Heslo",
  "screen.upgradeToMicrosoft.form.password.validation_error": "Zadejte prosím své heslo",
  "screen.upgradeToMicrosoft.needCompany": "Propojení s účtem MS lze provést pouze v případě, že je vybrána společnost.",
  "screen.upgradeToMicrosoft.resendButton.label": "Opětovné odeslání kódu",
  "screen.upgradeToMicrosoft.title": "Propojení účtu Microsoft",
  "search.caption.numCompanies": "Společnost: <b>{počet}</b>",
  "search.caption.numDespositedPersons": "Počet uložených osob: <b>{count}</b>",
  "search.caption.numDocuments": "Soubory: <b>{count}</b>",
  "search.caption.numPersons": "Počet osob: <b>{count}</b>",
  "search.caption.numProjects": "Projekty: <b>{počet}</b>",
  "search.caption.numResults": "Výsledky: <b>{počet}</b>",
  "search.caption.numSelected": "Vybrané položky: <b>{počet}</b>",
  "search.caption.numTickets": "Dotazy: <b>{count}</b>",
  "search.caption.numUploads": "Nahraná data: <b>{count}</b>",
  "search.caption.numUsers": "Uživatel: <b>{count}</b>",
  "search.placeholder.companies": "Společnosti hledají ...",
  "search.placeholder.projects": "vyhledat projekt ...",
  "search.placeholder.search": "Vyhledávání ...",
  "search.placeholder.searchForActiveUsers": "Vyhledávání aktivních uživatelů podle jména...",
  "search.placeholder.searchForEmployees": "vyhledávání jmen...",
  "search.placeholder.searchForEmployeesPersonalNumber": "vyhledat jména nebo čísla zaměstnanců...",
  "search.placeholder.searchForFileName": "Hledání názvu souboru...",
  "search.placeholder.searchForFiles": "Vyhledávání názvů souborů...",
  "search.placeholder.searchForUsers": "vyhledávání jmen...",
  "search.placeholder.searchForUsersFileName": "Vyhledávání podle názvu nebo názvu souboru...",
  "selectQesPosition.message": "Vyberte pozici pro podpis a klikněte na tlačítko {button}.",
  "selectQesPosition.message.collective": "Pro každého uživatele vyberte pozici pro jeho podpis a klikněte na tlačítko Další.",
  "selectQesPosition.title": "Výběr pozice podpisu",
  "settings.navbar.company": "Společnost",
  "settings.navbar.companyData": "Základní údaje",
  "settings.navbar.features": "Doplňky",
  "settings.navbar.general": "Obecné",
  "settings.navbar.optionalSideBarItems": "Volitelné položky nabídky",
  "settings.navbar.userManagement": "Správa uživatelů",
  "settings.notifications.activateAll": "AKTIVOVAT VŠE",
  "settings.notifications.assignments": "Přidělení prostředků",
  "settings.notifications.deactivateAll": "DEAKTIVOVAT VŠE",
  "settings.notifications.deadlineExceeded": "Zmeškaná lhůta",
  "settings.notifications.deadlineReminder": "Připomenutí termínu",
  "settings.notifications.generalDocumentUpload": "Nahrání obecných souborů",
  "settings.notifications.label": "Upozornění e-mailem, když:",
  "settings.notifications.messages": "Zprávy",
  "settings.notifications.modules.accounting": "Účetnictví",
  "settings.notifications.modules.hr": "Personál",
  "settings.notifications.modules.other": "Ostatní oblasti",
  "settings.notifications.project.items.uploaded": "Nahrávání projektů",
  "settings.notifications.recordProvision": "Nahrávání klienta",
  "settings.notifications.release": "Uvolňuje",
  "settings.notifications.remindAfterHours.label": "Paměť po {hodinách}h",
  "settings.notifications.reportProvision": "Poskytování zpráv",
  "settings.notifications.tickets": "Dotazy",
  "settings.notifications.title": "Oznámení",
  "settings.personalData.email": "E-mailová adresa",
  "settings.personalData.language": "Jazyk",
  "settings.personalData.location": "Umístění",
  "settings.personalData.microsoftAccount": "Účet Microsoft",
  "settings.personalData.microsoftUpgrade.info": "Účet Microsoft:{br}{br}Účet TPA Connect můžete propojit s účtem Microsoft. To vám umožní využívat všechny funkce služby TPA Connect.{br}{br}Po propojení účtu se službou Microsoft se budete muset přihlásit pomocí účtu Microsoft namísto e-mailové adresy.",
  "settings.personalData.microsoftUpgrade.label": "UPGRADE",
  "settings.personalData.phoneNumber": "Telefonní číslo",
  "settings.personalData.role": "Roll",
  "settings.personalData.title": "Osobní údaje",
  "settings.personalData.username": "Uživatelské jméno",
  "settings.userManagement.permissions.existingUser.navbar.back": "Správa uživatelů / Přiřazení role",
  "settings.userManagement.permissions.existingUser.navbar.backToUser": "Správa uživatelů / Přiřazení role / {jméno zaměstnance}",
  "settings.userManagement.permissions.navbar.back": "Správa uživatelů / Výběr kontaktní osoby / Přiřazení role",
  "settings.userManagement.permissions.navbar.backToUser": "Správa uživatelů / Vybrat kontaktní osobu / Přiřadit roli / {jméno zaměstnance}",
  "settings.userManagement.roles.navbar.back": "Správa uživatelů / Výběr kontaktní osoby",
  "sidebar.advisor.employees": "Seznam zaměstnanců",
  "sidebar.advisor.inbox": "PO Box",
  "sidebar.advisor.tickets": "Dotazy",
  "sidebar.advisor.uploads": "Nahrávání",
  "sidebar.customerView": "Pohled zákazníka",
  "sidebar.faceToFace.button": "Kontakt",
  "sidebar.faceToFace.title": "Tváří v tvář",
  "sidebar.list.accounting": "Účetnictví",
  "sidebar.list.analysis": "",
  "sidebar.list.cockpit": "Kokpit",
  "sidebar.list.documents": "Zprávy",
  "sidebar.list.faceToFace": "Tváří v tvář",
  "sidebar.list.hr": "Zaměstnanci",
  "sidebar.list.kpi": "Fakta a čísla",
  "sidebar.list.personnelFile": "Osobní spis",
  "sidebar.list.projects": "Projekty",
  "sidebar.list.results": "Hodnocení",
  "sidebar.list.settings": "Nastavení",
  "sidebar.list.support": "Podpora",
  "sidebar.list.tickets": "Dotazy",
  "signDialog.noQesEnabled.message": "Tímto potvrzuji vydání dokumentu.",
  "signDialog.noQesRequired.button.click": "Uvolnění na kliknutí",
  "signDialog.noQesRequired.button.qes": "Elektronický podpis",
  "signDialog.noQesRequired.message": "Při schvalování si můžete vybrat mezi schválením kliknutím nebo elektronickým podpisem.",
  "signDialog.qesblocked.message": "Podepisování elektronickým podpisem není v současné době možné, protože v tuto chvíli již byla zahájena procedura podepisování jinou osobou. Zkuste to prosím znovu přibližně za 15 minut.",
  "signDialog.qesblocked.title": "Vydání v současné době není možné",
  "signDialog.qesRequired.confirm": "OK, podepište se elektronicky",
  "signDialog.qesRequired.message": "Pro vydání byl požadován elektronický podpis. Nyní budete přesměrováni na elektronický podpis.",
  "signDialog.title": "Vydání",
  "soleEarnerStatus.Alleinerzieher": "Rodič samoživitel",
  "soleEarnerStatus.Alleinverdiener": "Jediná výdělečně činná osoba",
  "soleEarnerStatus.Nein": "Ne",
  "staff.documents.navbar.heading": "Moje soubory",
  "staff.personnelFile.navbar.heading": "Ahoj {jméno}!",
  "stepper.optional": "volitelné",
  "stepper.progress": "Progress",
  "support.admins.title": "Správci",
  "support.filter.option.activeChats": "Pouze aktivní chaty",
  "support.filter.option.allChats": "Všechny chaty",
  "support.filter.option.inactiveChats": "Pouze neaktivní chaty",
  "support.filter.setting.showInactiveColleagues": "Zobrazit neaktivní zaměstnance",
  "support.inactiveEmployee": "Bývalý zaměstnanec",
  "support.partners.title": "Partneři",
  "support.privateChat.dialog.markAsPrivate.confirm": "Označit jako důvěrné",
  "support.privateChat.dialog.markAsPrivate.message": "Pokud je chat označen jako důvěrný, <b>žádná jiná osoba</b> nemůže chat prohlížet a odpovídat na zprávy ve vaší nepřítomnosti.{br}{br}Váš klient <b>není</b> o tomto nastavení informován</b>.",
  "support.privateChat.dialog.markAsPrivate.title": "Označit chat jako důvěrný?",
  "support.privateChat.dialog.markAsPublic.confirm": "Zproštění mlčenlivosti",
  "support.privateChat.dialog.markAsPublic.message": "Pokud chat již není označen jako důvěrný, <b>ostatní lidé</b> mohou chat prohlížet a odpovídat na zprávy ve vaší nepřítomnosti.{br}{br}Váš klient <b>není</b> o tomto nastavení informován</b>.",
  "support.privateChat.dialog.markAsPublic.title": "Už nelze označit chat jako důvěrný?",
  "support.privateChat.info": "Chat je označen jako <b>důvěrný</b> a ostatní jej nemohou zobrazit.",
  "support.privateChat.info.markAsPublic": "LIFT UP",
  "support.privateChat.info.mobile": "Chat je <b>důvěrný</b>.",
  "support.privateChat.placeholder": "Zadejte zprávu ...",
  "support.responsible.title": "Členové týmu",
  "support.search.navbar.title": "Výsledky vyhledávání",
  "support.substitute.author": "{substituteName} (náhrada za {authorName})",
  "support.substitute.back": "ZPĚT K MÉMU CHATU",
  "support.substitute.back.mobile": "MŮJ CHAT",
  "support.substitute.warning": "<b>Pozor:</b> Nacházíte se v chatu <b>{jméno}</b>.",
  "support.substitute.warning.mobile": "Chat podle <b>{jméno}</b>",
  "support.yourContacts": "Vaše kontaktní osoby",
  "svsAccount.transactions.table.contextMenu.download": "",
  "svsAccount.transactions.table.contextMenu.show": "",
  "svsAccount.transactions.table.filters.quarter": "",
  "svsAccount.transactions.table.label.amount": "",
  "svsAccount.transactions.table.label.quarter": "",
  "svsAccount.transactions.table.label.text": "",
  "svsAccount.transactions.table.search.count": "",
  "svsAccount.transactions.table.search.placeholder": "",
  "svsAccount.transactions.title": "",
  "table.button.release": "UVOLNĚNÍ",
  "table.employees.dateOfJoining": "Datum vstupu",
  "table.employees.dateOfJoiningExiting": "Datum vstupu/výstupu",
  "table.employees.dateOfLeaving": "Datum odchodu",
  "table.employees.dateOfTransmitting": "Přenášeno na",
  "table.employees.documents.date": "Datum",
  "table.employees.documents.document": "Soubor",
  "table.employees.documents.documentName": "Označení",
  "table.employees.documents.downloadDocuments": "Soubory ke stažení",
  "table.employees.documents.uploadedBy": "Uploadnul",
  "table.employees.employee": "Zaměstnanci",
  "table.employees.jobDescription": "Název práce",
  "table.employees.missingDetails": "Chybějící údaje",
  "table.employees.personalId": "Číslo personálu",
  "table.employees.reasonForLeaving": "Důvod odchodu",
  "table.employees.status": "Stav",
  "table.employees.status.active": "Aktivní",
  "table.employees.status.inactive": "Neaktivní",
  "table.employees.status.inDataChange": "Změna hlavních údajů",
  "table.employees.status.inDataChangeNotTransferred": "Pozměňovací návrh (návrh)",
  "table.employees.status.inDeregistration": "Při zrušení registrace",
  "table.employees.status.inDeregistrationNotTransferred": "Zrušení registrace (návrh)",
  "table.employees.status.inRegistration": "Při registraci",
  "table.employees.status.inRegistrationNotTransferred": "Žádost (návrh)",
  "table.label.changes": "Změny",
  "table.label.changeUsername": "Odeslání e-mailu pro změnu uživatelských jmen",
  "table.label.comment": "Komentář:",
  "table.label.companyName": "Název společnosti",
  "table.label.contactPerson": "Kontakt",
  "table.label.contactPersonId": "Osobní číslo",
  "table.label.costCenter": "Nákladové středisko",
  "table.label.createdAt": "Datum nahrání",
  "table.label.createdAt.variant": "Vytvořeno dne",
  "table.label.createdBy": "Vytvořil",
  "table.label.date": "Datum",
  "table.label.dateOfJoining": "Datum vstupu",
  "table.label.dateOfJoiningExiting": "Datum vstupu/výstupu",
  "table.label.dateOfLeaving": "Datum odchodu",
  "table.label.dateOfTransmitting": "Přenášeno na",
  "table.label.deleteUser": "Odstranění uživatele",
  "table.label.document": "Soubor",
  "table.label.documentName": "Označení",
  "table.label.documentType": "Soubor",
  "table.label.downloadDocuments": "Soubory ke stažení",
  "table.label.dueDate": "Termín",
  "table.label.dueDateForDraft": "Lhůta pro uvolnění",
  "table.label.email": "E-mailová adresa",
  "table.label.employee": "Zaměstnanci",
  "table.label.file": "Soubor",
  "table.label.fileName": "Označení",
  "table.label.folder": "Složka",
  "table.label.holiday": "Dovolená",
  "table.label.info": "Informace",
  "table.label.jobDescription": "Název práce",
  "table.label.lastAuthenticatedAt": "Poslední přihlášení",
  "table.label.lastAuthenticatedAt.registered.tooltip": "Registrovaný uživatel",
  "table.label.lastAuthenticatedAt.unregistered.tooltip": "Uživatel není registrován",
  "table.label.lastCreated": "Naposledy vytvořený",
  "table.label.members": "Členové",
  "table.label.message": "Zpráva",
  "table.label.messages": "Zprávy",
  "table.label.missingDetails": "Chybějící údaje",
  "table.label.modulePermissions": "Povolení na úrovni oblasti",
  "table.label.modules": "Oblasti",
  "table.label.needsRelease": "Požadované uvolnění",
  "table.label.openReleases": "Otevřené verze",
  "table.label.otherAbsence": "Jiná nepřítomnost",
  "table.label.personalId": "Číslo personálu",
  "table.label.personalNumber": "Číslo personálu",
  "table.label.phoneNumber": "Telefonní číslo",
  "table.label.reasonForLeaving": "Důvod odchodu",
  "table.label.recordedBy": "Zachyceno z",
  "table.label.release": "Uvolňuje",
  "table.label.released": "Přenášeno",
  "table.label.releasedAt": "Přenášeno na",
  "table.label.releaseRequestedBy": "Vydání poskytl",
  "table.label.role": "Roll",
  "table.label.sentBy": "Odesláno z",
  "table.label.sickLeave": "Nemocenská dovolená",
  "table.label.status": "Stav",
  "table.label.teamMessages": "Týmové novinky",
  "table.label.ticket": "Konzultace",
  "table.label.ticketBy": "Otázku položil",
  "table.label.ticketDescription": "Označení",
  "table.label.ticketDueDate": "Termín",
  "table.label.tickets": "Dotazy",
  "table.label.transferStatus": "Přenos",
  "table.label.transmittedAt": "Přenášeno na",
  "table.label.type": "Art",
  "table.label.updatedAt": "Naposledy upraveno",
  "table.label.updatedAt.variant": "Naposledy upraveno",
  "table.label.uploadedBy": "Uploadnul",
  "table.label.username": "Uživatelské jméno",
  "table.noResults.message": "Vaše vyhledávání nevrátilo žádné výsledky.",
  "table.noResults.title": "Žádné výsledky vyhledávání",
  "taxAccount.pay.button": "Zaplatit daňový dluh",
  "taxAccount.transactions.table.filters.date": "Datum",
  "taxAccount.transactions.table.filters.tax": "Daň",
  "taxAccount.transactions.table.label.amount": "Částka",
  "taxAccount.transactions.table.label.date": "Datum",
  "taxAccount.transactions.table.label.due": "Zralost",
  "taxAccount.transactions.table.label.taxPeriod": "Období",
  "taxAccount.transactions.table.label.text": "Označení",
  "taxAccount.transactions.table.search.count": "Transakce: <b>{počet}</b>",
  "taxAccount.transactions.table.search.placeholder": "Vyhledávání transakcí",
  "taxAccount.transactions.title": "Zůstatek na daňovém účtu: [hodnota]",
  "termsOfUse.checkbox.error": "Přijměte prosím podmínky používání",
  "termsOfUse.upcoming.navbar.button": "Přijmout",
  "termsOfUse.upcoming.navbar.message": "Podmínky používání aplikace TPA Connect se změní od {data}.",
  "ticketList.emptystate.title": "Žádné dotazy nejsou k dispozici",
  "unpaidAccounts.invoices.pay": "Platit",
  "unpaidAccounts.invoices.table.filters.date": "Datum dokumentu",
  "unpaidAccounts.invoices.table.label.documentDate": "Datum dokumentu",
  "unpaidAccounts.invoices.table.label.documentNumber": "Číslo dokumentu",
  "unpaidAccounts.invoices.table.label.documents": "",
  "unpaidAccounts.invoices.table.label.documentText": "Text dokumentu",
  "unpaidAccounts.invoices.table.label.netAmount": "Čistá částka",
  "unpaidAccounts.invoices.table.label.opAmount": "Otevřená částka",
  "unpaidAccounts.invoices.table.label.receiptDate": "Datum dokumentu",
  "unpaidAccounts.invoices.table.label.receiptNumber": "Číslo dokumentu",
  "unpaidAccounts.invoices.table.label.receiptText": "Text dokumentu",
  "unpaidAccounts.invoices.table.search.count": "Faktury: <b>{count}</b>",
  "unpaidAccounts.invoices.table.search.placeholder": "Vyhledávání faktur",
  "unpaidAccounts.invoices.title": "Neuhrazené faktury u TPA: [hodnota]",
  "upload.category.analysis": "Zpráva: Měsíční hodnocení",
  "upload.category.annualReports": "Roční účetní závěrka",
  "upload.category.articles": "Vypracování",
  "upload.category.billing": "Zpráva: Měsíční výkaz",
  "upload.category.certificateOfCitizenship": "Doklad o občanství",
  "upload.category.certificateOfRegistration": "Potvrzení registrace",
  "upload.category.contractOfEmployment": "Smlouva",
  "upload.category.contracts": "Smlouvy",
  "upload.category.correspondence": "Korespondence",
  "upload.category.dataChangeConfirmation": "Změna hlavních údajů",
  "upload.category.deregisterDocument": "Doklady o zrušení registrace",
  "upload.category.deregistrationConfirmation": "Zrušení registrace zaměstnanců",
  "upload.category.employmentPermit": "Pracovní povolení",
  "upload.category.identityCard": "Průkaz totožnosti",
  "upload.category.information": "Informace",
  "upload.category.notices": "Oznámení",
  "upload.category.other": "Další",
  "upload.category.payslip": "výplatní páska",
  "upload.category.registrationConfirmation": "Registrace zaměstnanců",
  "upload.category.report": "Zprávy",
  "upload.category.taxOffice": "Finanční úřad",
  "upload.category.taxReturn": "Daňová přiznání",
  "userManagement.successDialog.changeUsername.message": "<b>{jméno uživatele}</b> obdrží žádost o potvrzení nové e-mailové adresy.",
  "userManagement.successDialog.deleteUser.single.message": "<b>{jméno uživatele}</b> byl úspěšně odstraněn.",
  "userManagement.successDialog.deleteUser.title": "Uživatel byl úspěšně odstraněn.",
  "userManagement.successDialog.existingUser.superAdmin.multi.message": "Bylo přijato nastavení pro <b>{počet} uživatelů</b>.",
  "userManagement.successDialog.existingUser.superAdmin.single.message": "Nastavení pro <b>{jméno uživatele}</b> bylo přijato.",
  "userManagement.successDialog.existingUser.superAdmin.title": "Uživatelská nastavení byla úspěšně uložena",
  "userManagement.successDialog.existingUser.title": "Úspěšně navržená uživatelská nastavení",
  "userManagement.successDialog.message": "Váš návrh musí nyní schválit <b>superadmin</b>.",
  "userManagement.successDialog.newUser.superAdmin.multi.message": "<b>{počet} uživatelů</b> obdrží pozvánku e-mailem.",
  "userManagement.successDialog.newUser.superAdmin.single.message": "<b>{jméno uživatele}</b> obdrží pozvánku e-mailem.",
  "userManagement.successDialog.newUser.superAdmin.title": "Uživatel úspěšně pozván",
  "userManagement.successDialog.newUser.title": "Uživatel úspěšně navrhl",
  "userManagement.successDialog.rejectUser.title": "Uživatel úspěšně odmítnut",
  "userManagement.successDialog.releaseExistingUser.title": "Uživatel úspěšně propuštěn",
  "userManagement.successDialog.releaseNewUser.title": "Uživatel úspěšně propuštěn a pozván",
  "userManagement.successDialog.sendUsernameChangeEmail.title": "E-mail pro změnu uživatelského jména byl úspěšně odeslán.",
  "userProfile.createBmdTodos": "Vytváření úloh v BMD pro dotazy",
  "userProfile.deleteAccount.message": "Opravdu chcete svůj účet odstranit? Tuto akci nelze vzít zpět!",
  "userProfile.deleteAccount.title": "Odstranění účtu",
  "userProfile.multiFactorAuth": "Povolení dvoufaktorového ověřování pomocí telefonního čísla",
  "userProfile.navbar.heading": "Profil",
  "userProfile.upgrade.navbar.heading": "Upgrade",
  "userProfile.userInterests.title": "Zájmy",
  "userReleaseInfoAdminDialog.releaseInfo.multi": "Uvolněte prosím nové uživatele a pozvěte je.",
  "userReleaseInfoAdminDialog.releaseInfo.single": "Prosím, schválte nového uživatele a pozvěte ho.",
  "userReleaseInfoAdminDialog.releaseMessage.multi": "{jméno uživatele} odeslal <b>{počet} nových uživatelů</b>.",
  "userReleaseInfoAdminDialog.releaseMessage.single": "{jméno uživatele} odeslal <b>{počet} novému uživateli</b>.",
  "userReleaseInfoAdminDialog.submit": "Zobrazení a sdílení",
  "userReleaseInfoAdminDialog.title": "Sdílení a pozvání uživatelů",
  "userReleaseInfoDialog.cancel": "Pro správu uživatelů",
  "userReleaseInfoDialog.from": "z",
  "userReleaseInfoDialog.message": "Vámi navrhované změny u následujících uživatelů byly schváleny/zamítnuty:",
  "userReleaseInfoDialog.rejected": "Odmítnuto",
  "userReleaseInfoDialog.released": "uvolněno",
  "userReleaseInfoDialog.title": "Změna uživatele",
  "viewer.unsupported.message": "{jméno} nelze v tuto chvíli zobrazit.",
  "webhook.employee.noSearch": "Požadovaný vstup pro vyhledávání",
  "webhook.employee.placeholder": "Jméno zaměstnance",
  "webhook.noCompanies": "Žádná povolení společnosti",
  "webhook.tickets.button": "Vytvořit poptávku",
  "webhook.tickets.info": "Přiřaďte dokument do oblasti a vyberte kategorii.",
  "webhook.tickets.navbar": "Konzultace k {dokumentu}",
  "webhook.tickets.taskTitle": "Úkol pro {jméno}",
  "webhook.tickets.title": "Vytvořit poptávku",
  "webhook.uploads.navbar": "Přenos souborů",
  "welcomeCard.accounting.addMoreRecords.button": "Zadejte další dokumenty",
  "welcomeCard.accounting.addRecords.button": "Zadejte dokumenty",
  "welcomeCard.hr.addMoreRecords.button": "Přenos více souborů",
  "welcomeCard.hr.addRecords.button": "Přenos souborů",
  "texterify_timestamp": "2024-11-21T10:06:30Z"
} as const;

export { czech };
