import { Popover, Tooltip } from "@material-ui/core";
import { useCallback, useState } from "react";
import { useLocation } from "react-router";
import styled from "styled-components";
import { MOBILE_BREAKPOINT } from "../../../config";
import { t, translateOrDefault } from "../../../i18n/util";
import { Account } from "../../../network/APITypes";
import { withParams } from "../../app/router/history";
import { RenderExpanded } from "../../ui/GridTable";
import { DottedLink } from "../../ui/Primitives";
import { customColors } from "../../util/Theme";
import { Currency, Pill, PillVariant } from "../ResultsValue";
import { ResultsRoutes } from "../router/ResultsRoutes";
import { formatAccountNumber } from "../utils";
import { EnhancedAccountTransaction } from "./useAccountTransactions";
import { ResultsAccountSheetNavigationState } from "./useResultsAccountSheetTableRenderCell";

export function useResultsAccountSheetTableRenderExpanded(
    financialAccountancyId: string,
    account: Account,
): RenderExpanded<EnhancedAccountTransaction> {
    return useCallback(
        item => {
            return <ExpandedContent item={item} financialAccountancyId={financialAccountancyId} account={account} />;
        },
        [financialAccountancyId, account],
    );
}

const ExpandedContentRoot = styled.dl`
    align-items: flex-start;
    border-top: 1px solid ${customColors.greyLight};
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
    padding: 8px 13px;

    @media (max-width: ${MOBILE_BREAKPOINT}px) {
        grid-template-columns: repeat(3, 1fr);
    }
`;
const ExpandedContentRow = styled.div`
    display: flex;
    flex-direction: column;
`;
const ExpandedContentLabel = styled.dt`
    align-items: center;
    display: flex;
    font-size: 12px;
    white-space: nowrap;
`;
const ExpandedContentValue = styled.dd`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    font-weight: bold;
    gap: 0 8px;
    hyphens: auto;
    overflow-wrap: break-word;
    word-break: break-word;
`;

const taxCodeToPillVariant: Record<string, PillVariant> = {
    Ust: "red",
    VSt: "green",
    igEOhneVst: "blue",
    igEMitVSt: "cyan",
    RCOhneVSt: "magenta",
    RCMitVSt: "yellow",
    BauleistungenOhneVSt: "orange",
    BauleistungenMitVSt: "purple",
    EUSt: "brown",
    TeilabzugsfaehigeVSt: "grey",
};

const ExpandedContent = ({
    item,
    financialAccountancyId,
    account,
}: {
    item: EnhancedAccountTransaction;
    financialAccountancyId: string;
    account: Account;
}) => {
    const Row = ExpandedContentRow;
    const Label = ExpandedContentLabel;
    const Value = ExpandedContentValue;
    return (
        <ExpandedContentRoot>
            {item.serialNumber != null && (
                <Row>
                    <Value>{item.serialNumber}</Value>
                    <Label>{t("results.accountSheet.details.serialNumber")}</Label>
                </Row>
            )}
            {item.foreignCurrency && item.foreignCurrency !== "EUR" && !!item.foreignCurrencyAmount && (
                <Row>
                    <Value>
                        {item.foreignCurrency}
                        {item.exchangeRate ? ` / ${item.exchangeRate.toNumber()}` : null}
                    </Value>
                    <Label>{t("results.accountSheet.details.foreignCurrency")}</Label>
                </Row>
            )}
            {(!!item.taxAmount || !!item.taxCode) && (
                <Row>
                    <Value>
                        {item.taxAmount ? <Currency value={item.taxAmount} /> : null}
                        {item.taxCode ? (
                            <Pill variant={taxCodeToPillVariant[item.taxCode] ?? "grey"}>
                                {translateOrDefault(
                                    `results.accountSheet.details.taxCode.${item.taxCode}`,
                                    undefined,
                                    `${item.taxCode}${item.taxCodeNumber ? ` (${item.taxCodeNumber})` : ""}`,
                                )}
                            </Pill>
                        ) : null}
                        {!item.taxCode && item.taxCodeNumber ? <Pill variant="grey">{item.taxCodeNumber}</Pill> : null}
                    </Value>
                    <Label>{t("results.accountSheet.details.tax")}</Label>
                </Row>
            )}
            {item.taxPercent ? (
                <Row>
                    <Value>{item.taxPercent ? <span>{item.taxPercent.toFixed(2) + " %"}</span> : null}</Value>
                    <Label>{t("results.accountSheet.details.taxPercent")}</Label>
                </Row>
            ) : null}
            {item.discount && (
                <Row>
                    <Value>{<Currency value={item.discount} />}</Value>
                    <Label>{t("results.accountSheet.details.discount")}</Label>
                </Row>
            )}
            {item.externalDocumentNumber && (
                <Row>
                    <Value>{item.externalDocumentNumber}</Value>
                    <Label>{t("results.accountSheet.details.externalDocumentNumber")}</Label>
                </Row>
            )}
            {item.costCenter && (
                <Row>
                    <Value>{item.costCenter}</Value>
                    <Label>{t("results.accountSheet.details.costCenter")}</Label>
                </Row>
            )}
            {item.period && (
                <Row>
                    <Value>{periodToMonth(item.period)}</Value>
                    <Label>{t("results.accountSheet.details.period")}</Label>
                </Row>
            )}
            {item.vatPeriod && (
                <Row>
                    <Value>{periodToMonth(item.vatPeriod)}</Value>
                    <Label>{t("results.accountSheet.details.vatPeriod")}</Label>
                </Row>
            )}
            {item.offsettingAccounts && (
                <Row>
                    <OffsettingAccounts
                        financialAccountancyId={financialAccountancyId}
                        account={account}
                        accountTransaction={item}
                        offsettingAccounts={item.offsettingAccounts}
                    />
                    <Label>{t("results.accountSheet.columns.offsettingAccounts")}</Label>
                </Row>
            )}
        </ExpandedContentRoot>
    );
};

function periodToMonth(period: number) {
    if (period <= 12) {
        // already month
        return period;
    }
    // the number otherwise looks like YYYYMM, so 202305
    return period - Math.floor(period / 100) * 100;
}

interface OffsettingAccountsProps {
    financialAccountancyId: string;
    account: Account;
    accountTransaction: EnhancedAccountTransaction;
    offsettingAccounts: EnhancedAccountTransaction["offsettingAccounts"];
}

const OffsettingAccounts = ({
    financialAccountancyId,
    account,
    accountTransaction,
    offsettingAccounts,
}: OffsettingAccountsProps) => {
    if (!offsettingAccounts?.length) {
        return null;
    }

    const firstRendered = (
        <OffsettingAccountLink
            financialAccountancyId={financialAccountancyId}
            account={account}
            accountTransaction={accountTransaction}
            offsettingAccount={offsettingAccounts[0]}
        />
    );
    if (offsettingAccounts.length === 1) {
        return firstRendered;
    }
    if (offsettingAccounts.length === 2) {
        return (
            <FlexColumn>
                {firstRendered}
                <OffsettingAccountLink
                    financialAccountancyId={financialAccountancyId}
                    account={account}
                    accountTransaction={accountTransaction}
                    offsettingAccount={offsettingAccounts[1]}
                />
            </FlexColumn>
        );
    }

    return (
        <FlexColumn>
            {firstRendered}
            <MoreOffsettingAccounts
                financialAccountancyId={financialAccountancyId}
                account={account}
                accountTransaction={accountTransaction}
                offsettingAccounts={offsettingAccounts}
            />
        </FlexColumn>
    );
};

const MoreOffsettingAccountsList = styled.ul`
    margin: 8px 16px;
    list-style: none;
    text-align: right;
`;

interface MoreOffsettingAccountsProps extends OffsettingAccountsProps {}

const MoreOffsettingAccounts = ({
    financialAccountancyId,
    account,
    accountTransaction,
    offsettingAccounts,
}: MoreOffsettingAccountsProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement>();

    const handleClick = (ev: React.MouseEvent<HTMLElement>) => {
        ev.preventDefault();
        setAnchorEl(ev.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(undefined);
    };

    const count = offsettingAccounts.length - 1; // the first one is already displayed

    return (
        <div>
            <DottedLink style={{ fontWeight: "bold" }} to="#" onClick={handleClick}>
                {t("results.accountSheet.offsettingAccounts.more", { count })}
            </DottedLink>
            <Popover
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <MoreOffsettingAccountsList>
                    {offsettingAccounts.slice(1).map(offsettingAccount => {
                        return (
                            <li key={offsettingAccount.number}>
                                <OffsettingAccountLink
                                    financialAccountancyId={financialAccountancyId}
                                    account={account}
                                    accountTransaction={accountTransaction}
                                    offsettingAccount={offsettingAccount}
                                />
                            </li>
                        );
                    })}
                </MoreOffsettingAccountsList>
            </Popover>
        </div>
    );
};

interface OffsettingAccountLinkProps {
    financialAccountancyId: string;
    account: Account;
    accountTransaction: EnhancedAccountTransaction;
    offsettingAccount: EnhancedAccountTransaction["offsettingAccounts"][number];
}

const OffsettingAccountLink = ({
    financialAccountancyId,
    account,
    accountTransaction,
    offsettingAccount,
}: OffsettingAccountLinkProps) => {
    const location = useLocation();
    const pathname = withParams(ResultsRoutes.ACCOUNT_SHEET, {
        financialAccountancyId,
        accountNr: offsettingAccount.number,
    });
    const state: ResultsAccountSheetNavigationState = {
        type: "accountSheet",
        location,
        account,
        documentId: accountTransaction.documentId,
        doNotScroll: true, // see App.tsx
    };
    return (
        <Tooltip title={offsettingAccount.name}>
            <DottedLink style={{ fontWeight: "bold" }} to={{ pathname, state }}>
                {formatAccountNumber(offsettingAccount.number)}
            </DottedLink>
        </Tooltip>
    );
};

const FlexColumn = styled.div`
    display: flex;
    flex-direction: column;
`;
