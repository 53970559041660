import flatMap from "lodash/flatMap";
import flatMapDeep from "lodash/flatMapDeep";
import { GLOBAL_FEATURES } from "../features";
import { IMessageIDS } from "../i18n/util";
import { Permission, Role, RoleRequest } from "../network/APITypes";
import { ICompany, Module } from "../types/models";

export type PermissionModule = Module | "settings" | "generalDocuments" | "projects";
export type PermissionCardinality = "all" | "some" | "none";

// How this works:
// UI has permission shorts, BE has permission actions.
// Since mapping single actions would be very tedious in the FE, we map shorts to actions.
// A permission short is a set of actions that are allowed for a certain permission group.
//
// [GOTTFRIED]: I think we should change permission short names to something more descriptive.
// Maybe canRead, canCRUD, canReadRelease, canReadReleaseTicketReadCreate?
// But sometimes we might want the same action set but with different titles (e.g. canAccessProjects has
// a project module specific title)
// The way it is now is not very intuitive but for now I'll leave it as it is until
// we have a better idea of what the permission shorts should be called.
export type PermissionShort =
    | "canRead"
    | "canEdit"
    | "canEditPersonnel"
    | "canUpdate"
    | "canRelease"
    | "canUpload"
    | "canAccessProjects"
    | "canReleaseGeneralDocuments"
    | "canPay";

type IPermissionShortsMap = {
    [permissionShort in PermissionShort]: {
        label: IMessageIDS;
        actions: Permission.ActionsEnum[];
    };
};

// Mapping frontend shorts to backend actions
export const permissionShortsMap: IPermissionShortsMap = {
    canRead: {
        label: "permission.option.canRead",
        actions: ["read"],
    },
    canEdit: {
        label: "permission.option.canEdit",
        actions: ["create", "read", "update", "delete"],
    },
    canEditPersonnel: {
        label: "permission.option.canEdit",
        actions: ["create", "read", "update", "delete", "ticketRead"],
    },
    canUpdate: {
        label: "permission.option.canUpdate",
        actions: ["create", "read", "update"],
    },
    canRelease: {
        label: "permission.option.canRelease",
        actions: ["read", "release", "ticketRead", "ticketCreate"],
    },
    canUpload: {
        label: "permission.option.canUpload",
        actions: ["read", "create", "update", "delete", "release", "ticketRead"],
    },
    canAccessProjects: {
        label: "permission.option.canAccess",
        actions: ["read", "ticketRead", "ticketCreate"],
    },
    canReleaseGeneralDocuments: {
        label: "permission.option.canRelease",
        actions: ["read", "release"],
    },
    canPay: {
        label: "permission.option.canPay",
        actions: ["read", "create"],
    },
};

interface IPermissionGroup {
    type: string;
    // Order in which the groups are shown
    order: Permission.GroupEnum[];
}

interface IPermissionSection {
    title: IMessageIDS;
    module?: PermissionModule;
    groups: IPermissionGroup[];
}

type IPermissionTree = IPermissionSection[];

// Tree of permissions used in Frontend for permission configuration
export const permissionTree: IPermissionTree = [
    {
        title: "common.accounting",
        module: "accounting",
        groups: [
            {
                type: "global",
                order: [
                    "accounting:company:reports",
                    "banking",
                    "accounting:company:results",
                    "expenses:cockpit",
                    "accounting:taxAccount:payment",
                    "accounting:unpaidAccount:payment",
                    "accounting:accountTransaction:payment",
                ],
            },
            {
                type: "subsidiary",
                order: ["accounting:records", "accounting:reports"],
            },
        ],
    },
    {
        title: "common.hr",
        module: "hr",
        groups: [
            {
                type: "global",
                order: ["hr:company:reports", "hr:employerPayrollAccount", "hr:employerPayrollAccount:details"],
            },
            {
                type: "subsidiary",
                order: ["hr:records", "hr:reports", "hr:personnel", "hr:employeePayrollAccount"],
            },
        ],
    },
    {
        title: "common.settings",
        module: "settings",
        groups: [
            {
                type: "global",
                order: ["settings:company", "settings:features", "settings:images", "settings:user"],
            },
        ],
    },
    {
        title: "common.generalDocuments",
        module: "generalDocuments",
        groups: [
            {
                type: "global",
                order: [
                    "generalDocuments:articles",
                    "generalDocuments:notices",
                    "generalDocuments:taxOffice",
                    "generalDocuments:information",
                    "generalDocuments:annualReports",
                    "generalDocuments:correspondence",
                    "generalDocuments:taxReturn",
                    "generalDocuments:contracts",
                    "generalDocuments:other",
                ],
            },
        ],
    },
    {
        title: "common.projects",
        module: "projects",
        groups: [
            {
                type: "global",
                order: ["projects"],
            },
        ],
    },
];

// Returns all permissions configurable in FE
export function getFilteredPermissionTree(company: ICompany): IPermissionTree {
    return flatMap(permissionTree, section => {
        // Filter out disabled sections
        if (
            (section.module === "hr" && !company.hasHR) ||
            (section.module === "accounting" && !company.hasAccounting) ||
            (section.module === "projects" && !GLOBAL_FEATURES.projects) ||
            (section.module === "generalDocuments" && !company.generalDocumentsEnabled)
        ) {
            return [];
        }

        return [
            {
                ...section,
                groups: flatMap(section.groups, group => {
                    const filteredGroup = {
                        ...group,
                        order: group.order.filter(permissionKey => {
                            if (permissionKey === "accounting:company:reports") {
                                return company.hasGlobalAccountingReports;
                            } else if (permissionKey === "hr:company:reports") {
                                return company.hasGlobalHRReports;
                            }
                            return true;
                        }),
                    };

                    return filteredGroup.order.length > 0 ? [filteredGroup] : [];
                }),
            },
        ];
    });
}

// List of all global permission groups that can be configured in the FE.
// BE might send more global permission groups inherited from roles.
export function getGlobalPermissionGroups(company: ICompany) {
    // CEE: Also have to take company into account, because different companies have different
    // active modules.
    const filtered = getFilteredPermissionTree(company);

    return flatMapDeep(filtered, section => section.groups.map(group => (group.type === "global" ? group.order : [])));
}

type IPermissionOptionMap = {
    [permissionKey in Permission.GroupEnum]:
        | undefined
        | {
              label: IMessageIDS;
              options: PermissionShort[]; // selectable options for a group shown in UI
              mostPermissiveOption: PermissionShort;
              permissionModule: PermissionModule;
              dependsOn?: Permission.GroupEnum[];
          };
};

// Options offered in UI for each permission group
export const permissionOptionsMap: IPermissionOptionMap = {
    "accounting:company:reports": {
        label: "permission.accounting.global.reports",
        options: ["canRead", "canRelease"],
        mostPermissiveOption: "canRelease",
        permissionModule: "accounting",
    },
    "accounting:records": {
        label: "permission.accounting.records",
        options: ["canRead", "canUpload"],
        mostPermissiveOption: "canUpload",
        permissionModule: "accounting",
    },
    "accounting:reports": {
        label: "permission.accounting.reports",
        options: ["canRead", "canRelease"],
        mostPermissiveOption: "canRelease",
        permissionModule: "accounting",
    },
    "accounting:company:results": {
        label: "permission.accounting.global.results",
        options: ["canRead"],
        mostPermissiveOption: "canRead",
        permissionModule: "accounting",
    },
    "accounting:taxAccount:payment": {
        label: "permission.taxAccount.payment",
        options: ["canRead", "canPay"],
        mostPermissiveOption: "canPay",
        permissionModule: "accounting",
    },
    "accounting:unpaidAccount:payment": {
        label: "permission.unpaidAccount.payment",
        options: ["canRead", "canPay"],
        mostPermissiveOption: "canPay",
        permissionModule: "accounting",
    },
    "accounting:accountTransaction:payment": {
        label: "permission.accountTransaction.payment",
        options: ["canRead", "canPay"],
        mostPermissiveOption: "canPay",
        permissionModule: "accounting",
    },
    "hr:employerPayrollAccount": {
        label: "permission.hr.employerPayrollAccount",
        options: ["canRead"],
        mostPermissiveOption: "canRead",
        permissionModule: "hr",
    },
    "hr:employeePayrollAccount": {
        label: "permission.hr.employeePayrollAccount",
        options: ["canRead"],
        mostPermissiveOption: "canRead",
        permissionModule: "hr",
        dependsOn: ["hr:personnel"],
    },
    "hr:employerPayrollAccount:details": {
        label: "permission.hr.employerPayrollAccount.details",
        options: ["canRead"],
        mostPermissiveOption: "canRead",
        permissionModule: "hr",
    },
    "hr:company:reports": {
        label: "permission.hr.global.reports",
        options: ["canRead", "canRelease"],
        mostPermissiveOption: "canRelease",
        permissionModule: "hr",
    },
    "hr:records": {
        label: "permission.hr.records",
        options: ["canRead", "canUpload"],
        mostPermissiveOption: "canUpload",
        permissionModule: "hr",
    },
    "hr:reports": {
        label: "permission.hr.reports",
        options: ["canRead", "canRelease"],
        mostPermissiveOption: "canRelease",
        permissionModule: "hr",
    },
    "hr:personnel": {
        label: "permission.hr.personnel",
        options: ["canRead", "canEditPersonnel"],
        mostPermissiveOption: "canEditPersonnel",
        permissionModule: "hr",
    },
    "expenses:cockpit": {
        label: "permission.expenses.cockpit",
        options: ["canRead", "canEdit"],
        mostPermissiveOption: "canEdit",
        permissionModule: "accounting",
    },
    "settings:company": {
        label: "permission.settings.company",
        options: ["canRead"],
        mostPermissiveOption: "canRead",
        permissionModule: "settings",
    },
    "settings:features": {
        label: "permission.settings.features",
        options: ["canUpdate"],
        mostPermissiveOption: "canUpdate",
        permissionModule: "settings",
    },
    "settings:images": {
        label: "permission.settings.images",
        options: ["canEdit"],
        mostPermissiveOption: "canEdit",
        permissionModule: "settings",
    },
    "settings:user": {
        label: "permission.settings.user",
        options: ["canUpdate"],
        mostPermissiveOption: "canUpdate",
        permissionModule: "settings",
    },
    "generalDocuments:articles": {
        label: "upload.category.articles",
        options: ["canRead", "canReleaseGeneralDocuments"],
        mostPermissiveOption: "canReleaseGeneralDocuments",
        permissionModule: "generalDocuments",
    },
    "generalDocuments:notices": {
        label: "upload.category.notices",
        options: ["canRead", "canReleaseGeneralDocuments"],
        mostPermissiveOption: "canReleaseGeneralDocuments",
        permissionModule: "generalDocuments",
    },
    "generalDocuments:taxOffice": {
        label: "upload.category.taxOffice",
        options: ["canRead", "canReleaseGeneralDocuments"],
        mostPermissiveOption: "canReleaseGeneralDocuments",
        permissionModule: "generalDocuments",
    },
    "generalDocuments:information": {
        label: "upload.category.information",
        options: ["canRead", "canReleaseGeneralDocuments"],
        mostPermissiveOption: "canReleaseGeneralDocuments",
        permissionModule: "generalDocuments",
    },
    "generalDocuments:annualReports": {
        label: "upload.category.annualReports",
        options: ["canRead", "canReleaseGeneralDocuments"],
        mostPermissiveOption: "canReleaseGeneralDocuments",
        permissionModule: "generalDocuments",
    },
    "generalDocuments:correspondence": {
        label: "upload.category.correspondence",
        options: ["canRead", "canReleaseGeneralDocuments"],
        mostPermissiveOption: "canReleaseGeneralDocuments",
        permissionModule: "generalDocuments",
    },
    "generalDocuments:taxReturn": {
        label: "upload.category.taxReturn",
        options: ["canRead", "canReleaseGeneralDocuments"],
        mostPermissiveOption: "canReleaseGeneralDocuments",
        permissionModule: "generalDocuments",
    },
    "generalDocuments:contracts": {
        label: "upload.category.contracts",
        options: ["canRead", "canReleaseGeneralDocuments"],
        mostPermissiveOption: "canReleaseGeneralDocuments",
        permissionModule: "generalDocuments",
    },
    "generalDocuments:other": {
        label: "upload.category.other",
        options: ["canRead", "canReleaseGeneralDocuments"],
        mostPermissiveOption: "canReleaseGeneralDocuments",
        permissionModule: "generalDocuments",
    },
    projects: {
        label: "permission.projects",
        options: ["canAccessProjects"],
        mostPermissiveOption: "canAccessProjects",
        permissionModule: "projects",
    },
    banking: {
        label: "permission.accounting.banking",
        options: ["canRead", "canEdit"],
        mostPermissiveOption: "canEdit",
        permissionModule: "accounting",
    },
    other: {
        // Unused, kept here to silence TS compiler
        label: "common.unknown",
        options: [],
        mostPermissiveOption: "canRead",
        permissionModule: "settings",
    },
};

export function getMostPermissiveActionsForPermissionGroup(group: Permission.GroupEnum): Permission.ActionsEnum[] {
    const permissionOption = permissionOptionsMap[group];
    return permissionOption ? permissionShortsMap[permissionOption.mostPermissiveOption].actions : [];
}

export function getDefaultPermissionShortForPermissionGroup(group: Permission.GroupEnum): PermissionShort | undefined {
    const permissionOption = permissionOptionsMap[group];
    return permissionOption?.options[0];
}

export function getDefaultActionsForPermissionGroup(group: Permission.GroupEnum): Permission.ActionsEnum[] {
    const permissionOption = getDefaultPermissionShortForPermissionGroup(group);
    return permissionOption ? permissionShortsMap[permissionOption].actions : [];
}

interface RoleMapping {
    male: IMessageIDS; // male name of role
    female?: IMessageIDS; // female name of role
    jobTitle?: IMessageIDS;
    jobTitleFemale?: IMessageIDS;
}

type RoleMappingWithDescription = RoleMapping & { description: IMessageIDS };

// Roles configurable for client users (need description)
export const customerRoleMapping: Record<RoleRequest, RoleMappingWithDescription> = {
    admin: {
        male: "role.admin",
        description: "role.admin.description",
    },
    "accounting-management": {
        male: "role.accountingManagement",
        description: "role.accountingManagement.description",
    },
    "hr-management": {
        male: "role.hrManagement",
        description: "role.hrManagement.description",
    },
    assistent: {
        male: "role.assistent",
        description: "role.assistent.description",
    },
    "accounting-assistent": {
        male: "role.accountingAssistent",
        description: "role.accountingAssistent.description",
    },
    "hr-assistent": {
        male: "role.hrAssistent",
        description: "role.hrAssistent.description",
    },
    none: {
        male: "role.noRole",
        description: "role.noRole.description",
    },
    custom: {
        male: "role.custom",
        description: "role.custom.description",
        jobTitle: "role.custom.jobTitle",
        jobTitleFemale: "role.custom.jobTitle.female",
    },
};

// Roles not configurable (only title is displayed, no description necessary)
export const roleMapping: Record<Role, RoleMapping> = {
    ...customerRoleMapping,

    staff: {
        male: "role.staff",
        female: "role.staff.female",
    },
    "super-admin": {
        male: "role.superAdmin",
    },
    "tpa-advisor": {
        male: "role.tpaAdvisor",
        female: "role.tpaAdvisor.female",
    },
    "tpa-accounting": {
        male: "role.tpaAccounting",
    },
    "tpa-tax-accountant": {
        male: "role.tpaTaxAccountant",
    },
    "tpa-hr": {
        male: "role.tpaHr",
    },
    "tpa-chat": {
        male: "role.tpaChat",
    },
    "tpa-chat-secret": {
        male: "role.tpaChatSecret",
    },
    "tpa-other": {
        male: "role.tpaOther",
        female: "role.tpaOther.female",
    },
    "international-projects": {
        male: "role.internationalProjects",
        female: "role.internationalProjects.female",
    },
};
