import { Checkbox } from "@material-ui/core";
import flatMap from "lodash/flatMap";
import uniq from "lodash/uniq";
import * as React from "react";
import { t } from "../../i18n/util";
import { Message } from "../../network/APITypes";
import { authStore } from "../../stores/AuthStore";
import { formatDate, formatTime } from "../../util/date";
import { sanitizeHtml } from "../../util/helpers";
import { getFullName } from "../../util/user";
import { DocumentDownloadLine } from "../ui/DocumentLine/DocumentLine";
import { User } from "../ui/User";
import { customColors } from "../util/Theme";

interface IProps {
    message: Message;
    onSelect?: (message: Message) => void;
    isSelected?: boolean;
    onAttachmentView?: (message: Message) => void;
    onAttachmentDownload?: (message: Message) => void;
}

const getLink = (tempString: string, linkIndexEnd: number) => {
    if (linkIndexEnd !== -1) {
        return tempString.slice(0, linkIndexEnd);
    } else if (tempString.endsWith(".")) {
        // Remove "." if its the only character following the link
        return tempString.slice(0, tempString.length - 1);
    } else {
        return tempString;
    }
};

const getLinkRegExp = (link: string) =>
    new RegExp(`<a[^>]*href=["'](?:(?!["']).)*${link}[^>]*>(?:(?!</a>).)*</a>`, "gi");

const generateLinks = (message: string) => {
    const protocols = ["http://", "https://", "www."];

    // Find all links in the message for each protocol
    const links = flatMap(protocols, protocol => {
        let linkIndexStart = message.indexOf(protocol);
        let links: string[] = [];

        while (linkIndexStart !== -1) {
            const tempString = message.slice(linkIndexStart);
            if (
                protocol !== "www." ||
                message.slice(message.indexOf(tempString) - 3, message.indexOf(tempString)) !== "://"
            ) {
                // Match whitespace, <br/>, .<br/>, anything that starts with </
                const linkIndexEnd = tempString.search(/[\s]|(<br\/>)|(\.\s)|(\.<br\/>)|(<\/)/);

                const link = getLink(tempString, linkIndexEnd);

                // Check if the link is not already in an <a> tag in the message
                if (link !== protocol && link !== "www" && link !== "http://www" && link !== "https://www") {
                    const linkRegExp = getLinkRegExp(link);
                    if (!message.match(linkRegExp)) {
                        links = [...links, link];
                    }
                }
            }

            linkIndexStart = message.indexOf(protocol, linkIndexStart + 1);
        }

        return links;
    });

    let newMessage = message;

    // Replace each link in the message with a clickable HTML link
    uniq(links).forEach(link => {
        // Check if the link is already in an <a> tag in the message
        const linkRegExp = getLinkRegExp(link);
        const linkMatches = newMessage.match(linkRegExp);
        if (linkMatches) {
            // Replace existing <a> tags containing the link with just the link text
            linkMatches.forEach(linkMatch => {
                newMessage = newMessage.replace(linkMatch, link);
            });
        } else {
            // Create and insert a new HTML link for the link
            const messageParts = newMessage.split(link);
            newMessage = messageParts.join(
                `<a href="${
                    link.startsWith("www") ? `http://${link}` : link
                }" target="_blank" rel="noopener noreferrer">${link}</a>`,
            );
        }
    });

    return newMessage;
};

const ChatMessageComponent = ({ message, onSelect, onAttachmentView, onAttachmentDownload, isSelected }: IProps) => {
    const substitute = message.substitute;
    const messageText = generateLinks(message.text);
    const selectionStyle: React.CSSProperties = { display: "flex", cursor: "pointer", userSelect: "none" };
    const padding = "32px 32px 0 0";
    const isCurrentUser = authStore.userId === message.author.id;

    return (
        <div className="chat-message" style={{ padding: onSelect ? padding : undefined }}>
            <div
                onClick={
                    onSelect
                        ? () => {
                              onSelect(message);
                          }
                        : undefined
                }
                style={onSelect ? selectionStyle : undefined}
            >
                {onSelect && (
                    <Checkbox
                        color="primary"
                        checked={isSelected}
                        style={{ alignSelf: "flex-start", marginRight: 4 }}
                    />
                )}
                <div
                    style={{
                        padding: !onSelect ? padding : "12px 12px 0 0",
                        width: "100%",
                        textAlign: isCurrentUser ? "right" : "left",
                    }}
                >
                    {!substitute && (
                        <User
                            firstName={message.author.firstName}
                            lastName={message.author.lastName}
                            imageUrl={message.author.profile_picture_url}
                            isTpaStaff={message.author.isTpaEmployee}
                            style={{
                                display: "flex",
                                justifyContent: isCurrentUser ? "flex-end" : "flex-start",
                            }}
                        />
                    )}
                    {substitute && (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: isCurrentUser ? "flex-end" : "flex-start",
                            }}
                        >
                            <User
                                firstName={substitute.firstName}
                                lastName={substitute.lastName}
                                imageUrl={substitute.profile_picture_url}
                                isTpaStaff={substitute.isTpaEmployee}
                                showAvatarOnly={true}
                            />

                            <User
                                firstName={message.author.firstName}
                                lastName={message.author.lastName}
                                caption={t("support.substitute.author", {
                                    substituteName: getFullName(substitute),
                                    authorName: getFullName(message.author),
                                })}
                                isTpaStaff={message.author.isTpaEmployee}
                                imageUrl={message.author.profile_picture_url}
                            />
                        </div>
                    )}
                    {message.attachment && (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: isCurrentUser ? "flex-end" : "flex-start",
                            }}
                        >
                            <DocumentDownloadLine
                                fileName={message.attachment.name}
                                style={{
                                    maxWidth: 368,
                                    padding: "20px 16px",
                                    borderRadius: 4,
                                    border: `1px solid ${customColors.greyLight}`,
                                    marginTop: 8,
                                    backgroundColor: isCurrentUser
                                        ? customColors.chatMessageBackgroundPrimary
                                        : customColors.chatMessageBackgroundSecondary,
                                }}
                                documentId={message.attachment.id}
                                textStyle={{ color: customColors.body1Dark }}
                                disabled={!!onSelect}
                                onView={
                                    onAttachmentView
                                        ? () => {
                                              onAttachmentView?.(message);
                                          }
                                        : undefined
                                }
                                onDownload={
                                    onAttachmentDownload
                                        ? () => {
                                              onAttachmentDownload(message);
                                          }
                                        : undefined
                                }
                            />
                        </div>
                    )}
                    {messageText && (
                        <div
                            style={{
                                marginTop: 8,
                                wordBreak: "break-word",
                                backgroundColor: isCurrentUser
                                    ? customColors.chatMessageBackgroundPrimary
                                    : customColors.chatMessageBackgroundSecondary,
                                borderRadius: 4,
                                padding: "8px",
                            }}
                        >
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: sanitizeHtml(messageText),
                                }}
                            />
                        </div>
                    )}
                    <p className="caption" style={{ marginTop: 8 }}>
                        {t("dueDate.sentAtWithTime", {
                            date: formatDate(message.sentAt),
                            time: formatTime(message.sentAt),
                        })}
                    </p>
                </div>
            </div>
        </div>
    );
};

// Only rerender if message id changes
const areMessagesEqual = (prevProps: IProps, nextProps: IProps) =>
    prevProps.message.id === nextProps.message.id && prevProps.onSelect === nextProps.onSelect;

// Return memoized function for better performance
export const ChatMessage = React.memo(ChatMessageComponent, areMessagesEqual);
