import React from "react";
import styled from "styled-components";
import { ZOOM_IN_THRESHOLD, ZOOM_OUT_THRESHOLD } from "../../config";
import { Header, ZoomDirection } from "./Header";
import { rendererForFile } from "./renderer/renderer";
import { RendererProps, ViewerFile } from "./types";

const Root = styled.div`
    // IMPORTANT: 640px is the minimum width required by the office viewer. Anything below that won't render office correctly...
    min-width: 640px;
    display: flex;
    flex-direction: column;
    height: 100%;
    outline: none; // remove default focus outline
`;

interface InlineViewerProps {
    files: ViewerFile[];
    selectedFileId: ViewerFile["id"];
    onSelect?: (file: ViewerFile) => void;
    onClose?: () => void;
    zoomLevel: number;
    onZoom: (zoomLevel: number) => void;
    align?: RendererProps["align"];
    layout?: "mobile";

    tabIndex?: number; // provided by <Modal />
}

export const InlineViewer = React.forwardRef<HTMLDivElement, InlineViewerProps>(function InlineViewer(props, ref) {
    const { files, selectedFileId, onSelect, onClose, zoomLevel, onZoom, align, layout, tabIndex } = props;
    const file = files.find(f => f.id === selectedFileId) ?? null;

    const handleZoom = (dir: ZoomDirection) => {
        if (dir === "out" && zoomLevel > ZOOM_OUT_THRESHOLD) {
            onZoom(zoomLevel - 0.1);
        } else if (dir === "in" && zoomLevel < ZOOM_IN_THRESHOLD) {
            onZoom(zoomLevel + 0.1);
        }
    };

    return (
        <Root ref={ref} tabIndex={tabIndex}>
            <Header
                files={files}
                file={file}
                onClose={onClose}
                onSelect={onSelect}
                onZoom={handleZoom}
                layout={layout}
            />
            {file ? <Viewer file={file} zoomLevel={zoomLevel} align={align} /> : null}
        </Root>
    );
});

const ViewerRoot = styled.div`
    flex: 1;
    width: 100%;
    overflow-y: scroll;
    overflow-x: auto;
    position: relative;

    &.fullscreen {
        overflow: hidden; // provides its own scroll container (e.g. an iframe)
    }
`;

interface ViewerProps {
    file: ViewerFile;
    zoomLevel: number;
    align?: RendererProps["align"];
    className?: string;
}

export const Viewer = ({ file, zoomLevel, align, className }: ViewerProps) => {
    const renderer = rendererForFile(file);

    className ??= "";
    if (renderer.fullscreen) {
        className += " fullscreen";
    }

    return (
        <ViewerRoot className={className}>
            <renderer.Component key={file.id} file={file} zoomLevel={zoomLevel} align={align} />
        </ViewerRoot>
    );
};
